import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 25px 50px;

  @media (max-width: 1290px) {
    pading: 24px 24px;
  }
`;

export default Container;
