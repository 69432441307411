import create from 'zustand';
import {
  createOrder,
  confirmOrderCode,
  searchOrders,
  sortOrdersData
} from '../../services/requests/orders/Orders.request';

import { OrdersStoreProps } from './Orders.props';

const initialState = {
  files: [],
  id: '',
  name: '',
  type: '',
  created_at: '',
  updated_at: '',
  field: '',
  direction: 'ASC'
};

export const useOrdersStore = create<OrdersStoreProps>((set, get) => ({
  ...initialState,
  id: '',
  errorOrder: '',
  variantInfor: '',
  idOrder: '',
  files: [],
  name: '',

  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  handleSetOrdersId: (filesId: any) => set((state) => ({ ...state, filesId })),
  handleSetOrderId: (id: any) => {
    set((state) => ({ ...state, id }));
  },

  createOrderRequest: async (formData, onComplete) => {
    await createOrder(formData);
    onComplete();
  },

  getAllOrdersSortRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortOrdersData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  confirmOrderCodeRequest: async (code: string): Promise<void> => {
    const { id } = get();
    await confirmOrderCode(id, code);
  },

  searchOrdersRequest: async (
    query: string,
    page: string,
    sortByField: string,
    sortByDirection: string
  ) => {
    try {
      const data = await searchOrders(query, page, sortByField, sortByDirection);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));
