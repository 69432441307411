import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 50px;

  .blockContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 550px;
    height: 288px;
    background: #ffffff;
    border: 1px solid #e0e7ed;
    border-radius: 16px;
  }

  .unitContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 550px;
    height: 288px;
    background: #ffffff;
    border: 1px solid #e0e7ed;
    border-radius: 16px;
  }

  .containerText {
    margin: 2px 0px 17px 0px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #2c2c2c;
  }

  .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
    height: 64px;
    background: #f9f9f9;
  }

  .contentContainer {
    width: 100%;
    height: 76%;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 6px;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #662483;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .labelText {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2c2c2c;
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    color: #8b8b8b;
  }

  @media (max-width: 1470px) {
    gap: 20px;

    .blockContainer {
      width: 440px;
    }

    .unitContainer {
      width: 440px;
    }
  }

  @media (max-width: 1210px) {
    gap: 20px;

    .blockContainer {
      width: 352px;
    }

    .unitContainer {
      width: 352px;
    }
  }

  @media (max-width: 1030px) {
    flex-direction: column;
    .blockContainer {
      width: 100%;
    }

    .unitContainer {
      width: 100%;
    }
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`;

export default Container;
