/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Modal as ModalComponent, Typography, Button, CardMedia } from '@mui/material';
import { InformationModalController } from './InfoModal.controller';
import Background from './InfoModal.style';

const cnpjIcon = require('../../../../assets/images/cnpjIcon.svg');
const enterpriseIcon = require('../../../../assets/images/enterpriseIcon.svg');
const emailIcon = require('../../../../assets/images/emailIcon.svg');
const closeIcon = require('../../../../assets/images/close.svg');
const moneyIcon = require('../../../../assets/images/moneyIcon.svg');
const phoneIcon = require('../../../../assets/images/phoneIcon.svg');

export function InformationModal() {
  const {
    enterprise,
    CNPJ,
    email,
    segment,
    telephone,
    celular,
    signedimagePartnersUrl,
    isOpenInformationModal,
    getImageToPreview,
    handleInformationModalOpen,
    handleClosePartnerModal
  } = InformationModalController();

  return (
    <ModalComponent open={isOpenInformationModal} onClose={handleInformationModalOpen}>
      <Background>
        <Box className="Container">
          <Box className="closeBtnContainer" onClick={handleClosePartnerModal}>
            <closeIcon.ReactComponent className="closeBtn" />
          </Box>
          <Box>
            <Typography className="title">Informação Parceiro</Typography>
          </Box>
          <Box className="iconContainer">
            <enterpriseIcon.ReactComponent />

            <Box className="iconData">
              <Typography className="dataTitle">Empresa</Typography>
              <Box className="dataSubtitle">{enterprise}</Box>
            </Box>
          </Box>
          <Box className="iconContainer">
            <cnpjIcon.ReactComponent />

            <Box className="iconData">
              <Typography className="dataTitle">CNPJ</Typography>
              <Box className="dataSubtitle">{CNPJ}</Box>
            </Box>
          </Box>

          {email && (
            <Box className="iconContainer emailDataIcon">
              <emailIcon.ReactComponent />
              <Box className="iconData">
                <Typography className="dataTitle">E-mail</Typography>
                <Box className="dataSubtitle emailData">{email}</Box>
              </Box>
            </Box>
          )}

          <Box className="iconContainer">
            <moneyIcon.ReactComponent />
            <Box className="iconData">
              <Typography className="dataTitle">Segmento</Typography>
              <Box className="dataSubtitle">{segment}</Box>
            </Box>
          </Box>

          {telephone && (
            <Box className="iconContainer">
              <phoneIcon.ReactComponent />
              <Box className="iconData">
                <Typography className="dataTitle">Telefone Comercial</Typography>
                <Box className="dataSubtitle">
                  <Typography className="dataSubtitle">{telephone}</Typography>
                </Box>
              </Box>
            </Box>
          )}

          {celular && (
            <Box className="iconContainer">
              <phoneIcon.ReactComponent />
              <Box className="iconData">
                <Typography className="dataTitle">Celular Comercial</Typography>
                <Box className="dataSubtitle">
                  <Typography className="dataSubtitle">{celular}</Typography>
                </Box>
              </Box>
            </Box>
          )}

          {signedimagePartnersUrl && (
            <>
              <Box className="iconData" sx={{ width: '83%', textAlign: 'left' }}>
                <Typography className="dataTitle">Logotipo</Typography>
              </Box>
              <Box className="partnerLogo">
                <CardMedia
                  onClick={() => getImageToPreview(signedimagePartnersUrl)}
                  component="img"
                  max-height="140"
                  max-width="370"
                  image={signedimagePartnersUrl}
                  alt="Logo do parceiro"
                />
              </Box>
            </>
          )}

          <Box className="boxContainer">
            <Button
              className="backButton"
              variant="contained"
              disableElevation
              onClick={handleClosePartnerModal}>
              Voltar
            </Button>
          </Box>
        </Box>
      </Background>
    </ModalComponent>
  );
}
