import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;

  .customMobileColorsInput {
    padding: 7px 10px 2px 10px;
    border: 1px solid #8b8b8b;
    border-radius: 8px;
  }

  .previewColor {
    width: 20px;
    height: 20px;
    border: 2px solid #8b8b8b;
    border-radius: 5px;
  }
`;

export default Container;
