/* eslint-disable no-underscore-dangle */
import { instance } from '../../api';

import { IAllOcurrencesDtoOutput, OcurrenceById, SearchOcurrences } from './Ocurrences.dto';

export const getOcurrencesDetailsById = async (
  ocurrencesId: string
): Promise<{ data: OcurrenceById }> => {
  const id = ocurrencesId || window.location.href.split('/').pop();

  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `occurrence/manager/${id}`
    });

    return { data };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortOcurrencesData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IAllOcurrencesDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `occurrence/manager?page=${page}&includeMeta=true&sortDirection=${sortByDirection}&sortBy=${sortByField}&search=${searchString}`
    });

    const formatedData = data.data;

    const totalOcurrences = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalOcurrences, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchOcurrences = async (
  ocurrencesData: SearchOcurrences,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IAllOcurrencesDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `occurrence/manager?search=${ocurrencesData.queryText}&includeMeta=true&page=${page}&sortDirection=${sortByDirection}&sortBy=${sortByField}`
    });

    const formatedData = data.data;

    const totalOcurrences = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalOcurrences, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteOcurrences = async (id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'DELETE',
      url: `occurrence/manager/${id}`
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const answerOcurrences = async (message: string, id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'PATCH',
      url: `occurrence/history/${id}`,
      data: { name: message }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const changeStatusOcurrences = async (status: string, id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'PATCH',
      url: `occurrence/${status}/${id}`
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
