/* eslint-disable no-underscore-dangle */
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { FormRequiredFields } from './EditMaintenance.props';
import {
  useLoadModalStore,
  useMaintenancesStore,
  useInfoModalStore,
  useRolesModalStore,
  useAdminORStore,
  useBackToLastPageModalStore,
  useRefreshChangedPagesStore
} from '../../store';
import { Errors } from '../../constants/Errors';
import { formatData } from '../../helpers/formatData';
import { instance } from '../../services/api';

function useEditMaintenanceController() {
  const navigate = useNavigate();
  const maintenanceDefaultValues = {
    id: '',
    subject: '',
    status: '',
    typeOfMaintenance: '',
    date: '',
    time: '',
    description: '',
    enterprise: ''
  };

  const [unit, setUnit] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [typeOfMaintenance, setTypeOfMaintenance] = useState('');
  const [block, setBlock] = useState('');
  const [selectedBlock, setSelectedBlock] = useState(false);
  const [allEnterprise, setAllEnterprise] = useState([{ label: '', value: '' }]);
  const [selectedEnterpriseOfInput, setSelectedEnterpriseOfInput] = useState({
    value: '',
    label: ''
  });
  const [localId, setLocalId] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [localType, setLocalType] = useState('Enterprise');
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [fileLink, setFileLink] = useState('');
  const [selectedUnit, setSelectedUnit] = useState({ value: '', label: '', block: '' });

  const { enterpriseIdOfManager } = useAdminORStore();

  const { handleBackToLastPageModalOpen, isOpenBackToLastPageModal, sidebarLink } =
    useBackToLastPageModalStore();

  const { setChangedPage } = useRefreshChangedPagesStore();

  const { toogleLoading } = useLoadModalStore();
  const {
    editMaintenanceRequest,
    maintenanceID,
    getMaintenanceDetailsByIdRequest,
    getEnterprisesList,
    getBlocksUnitsList,
    handleSetImagesToRequest,
    setBlocksList,
    setImageName,
    setImageSize,
    setImageType,
    imagesToDelete,
    filesToGetLink,
    removeImageRequest,
    imageName,
    imageSize,
    imageType,
    setUnitList,
    getImageLinkRequest,
    formatedImageToRequest,
    unitsList,
    blocksList
  } = useMaintenancesStore();
  const { isAdmin } = useRolesModalStore();
  const {
    handleModalOpen,
    handleSetText,
    handleSetIsSuccessfully,
    isOpenInfoModal,
    isSuccessfully,
    text
  } = useInfoModalStore();

  const registerMaintenancechema = yup.object().shape({
    subject: yup.string().required('Por favor, preencher campo assunto.'),
    status: yup.string().required('Por favor, preencher campo status.'),
    typeOfMaintenance: yup.string().required('Por favor, preencher campo tipo da manutenção.'),
    date: yup
      .string()
      .test('date', 'Data inválida.', (value) => {
        if (value && value.length > 0) {
          if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value.match(/(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/((1[2-9]|[2-9][0-9])[0-9]{2})/)
              ?.length > 0
          ) {
            return true;
          }
          return false;
        }
        return true;
      })
      .notRequired(),
    time: yup
      .string()
      .test('time', 'Horário inválido.', (value) => {
        if (value && value.length > 0) {
          if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)?.length > 0
          ) {
            return true;
          }
          return false;
        }
        return true;
      })
      .notRequired(),
    description: yup.string().notRequired(),
    enterprise: yup.string().notRequired()
  });

  function handleClickEnterpriseButton() {
    setSelectedBlock(false);
    setLocalId(enterpriseIdOfManager || enterprise);
    setLocalType('Enterprise');
  }

  function handleClickBlockButton() {
    setSelectedBlock(true);
    setLocalType(unit ? 'Unit' : 'Block');
    setLocalId(unit || block);
  }

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors, touchedFields }
  } = useForm<FormRequiredFields>({
    defaultValues: maintenanceDefaultValues,
    resolver: yupResolver(registerMaintenancechema)
  });

  window.onbeforeunload = () => {
    const { date, description, status, subject, time } = getValues();
    if (
      block ||
      date ||
      description ||
      enterprise ||
      status ||
      subject ||
      time ||
      typeOfMaintenance ||
      unit
    ) {
      setChangedPage(true);
      return true;
    }
    setChangedPage(false);
    return null;
  };

  const maintenanceType = [
    { label: 'Preventiva', value: 'Preventive' },
    { label: 'Corretiva', value: 'Corrective' }
  ];

  const status = [
    { label: 'Pendente', value: 'Pendent' },
    { label: 'Em Andamento', value: 'InProgress' },
    { label: 'Concluída', value: 'Completed' }
  ];

  async function getBlocks() {
    toogleLoading();
    try {
      const { enterprise: enterpriseValue } = getValues();
      const data = await getBlocksUnitsList(
        selectedEnterpriseOfInput.value.length === 0
          ? enterpriseValue
          : selectedEnterpriseOfInput.value
      );
      const formatedBlockData = data.map((enterpriseBlocks) => {
        return {
          label: enterpriseBlocks.name,
          value: enterpriseBlocks.id,
          enterpriseId: enterpriseBlocks.enterpriseId
        };
      });
      setBlocksList(formatedBlockData);
    } finally {
      toogleLoading();
    }
  }

  async function getUnityFromInput(selected: string) {
    toogleLoading();
    try {
      const { data } = isAdmin
        ? await instance.get(`block/${selected}?sortDirection=ASC&sortBy=unit_model.name&limit=0`)
        : await instance.get(
            `unit/manager/getAllUnits/${selected}?sortDirection=ASC&sortBy=unit_model.name&limit=0`
          );

      const formatedUnits = isAdmin
        ? data.units.map((unitRequest: { name: string; id: string }) => {
            const formatedUnit = {
              label: unitRequest.name,
              value: unitRequest.id
            };
            return formatedUnit;
          })
        : data.data.map((unitRequest: { name: string; id: string }) => {
            const formatedUnit = {
              label: unitRequest.name,
              value: unitRequest.id
            };
            return formatedUnit;
          });
      setUnitList(formatedUnits);
    } finally {
      setLocalType('Block');
      setBlock(selected);
      setLocalId(selected);
      toogleLoading();
    }
  }

  function getUnitFromInput(selected: string) {
    setValue('unit', selected);
    setLocalType('Unit');
    setUnit(selected);
    setSelectedUnit({ value: selected, label: '', block: '' });
    const found: any = unitsList?.filter((item) => item.value === selected);
    setLocalId(found[0].value);
    if (found) setSelectedUnit(found[0]);
  }

  async function getAllData() {
    toogleLoading();
    try {
      const { data } = await getMaintenanceDetailsByIdRequest(maintenanceID, isAdmin);
      handleSetImagesToRequest(data.files);
      setImageName(data.files[0]._name);
      setImageSize(data.files[0]._size);
      setImageType(data.files[0]._type);
      let formatedDate = '';
      if (data.date && data.date.length > 0) {
        const formatedHours = String(new Date(data.date).getHours() + 3);
        const formatedMinutes = new Date(data.date).getMinutes().toString();
        formatedDate = `${formatedHours.length === 1 ? `0${formatedHours}` : formatedHours}:${
          formatedMinutes.length === 1 ? `0${formatedMinutes}` : formatedMinutes
        }`;
      }

      if (data.block) {
        setBlock(data.block.id);
        setValue('block', data.block.id);
        handleClickBlockButton();
        getBlocks();
        getUnityFromInput(data.block.id);
        setLocalType('Block');
        setLocalId(data.block.id);
      }

      if (data.unit) {
        setValue('unit', data.unit.id);
        setValue('block', data.unit.block.id);
        setUnit(data.unit.id);
        setBlock(data.unit.block.id);
        handleClickBlockButton();
        getUnityFromInput(data.unit.block.id);
        getBlocks();
        setLocalType('Unit');
        setLocalId(data.unit.id);
      }

      if (!data.block && !data.unit) {
        setLocalType('Enterprise');
        setLocalId(data.enterprise.id);
      }

      setFileLink(data.files[0].length < 10 ? '' : data.files[0]);
      setTypeOfMaintenance(data.type);
      setSelectedStatus(data.status);
      setValue('enterprise', data.enterprise.id);
      setValue('date', data.date ? formatData(data.date) : '');
      setValue('description', data.description);
      setValue('status', data.status);
      setValue('subject', data.name);
      setValue('time', formatedDate);
      setEnterprise(data.enterprise.id);
      setValue('typeOfMaintenance', data.type);
    } finally {
      toogleLoading();
    }
  }

  async function getEnterpriseData() {
    toogleLoading();
    try {
      const data = await getEnterprisesList();
      const formatedSelectData = data.map((enterpriseData: { id: string; name: string }) => {
        return {
          value: enterpriseData.id,
          label: enterpriseData.name
        };
      });
      setAllEnterprise(formatedSelectData);
    } finally {
      toogleLoading();
    }
  }

  async function deleteImages() {
    await imagesToDelete.forEach(async (imageLink) => {
      toogleLoading();
      try {
        await removeImageRequest(imageLink ? imageLink.split('maintenance/')[1] : imageLink);
      } finally {
        toogleLoading();
      }
    });
  }

  const formatImage = async () => {
    const imagesFileToRequest = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const file of filesToGetLink) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        // eslint-disable-next-line no-await-in-loop
        const { data } = await getImageLinkRequest(formData, uuidv4());
        handleSetImagesToRequest([...formatedImageToRequest, data]);
        imagesFileToRequest.push(data);
      } catch (err: any) {
        toast.error(err.message);
      }
    }
    return imagesFileToRequest;
  };

  const editMaintenance = async () => {
    toogleLoading();
    try {
      const {
        subject,
        typeOfMaintenance: mtnType,
        status: maintenanceStatus,
        description,
        date,
        time
      } = getValues();

      const unformatedDate = new Date(
        `${date.split('/')[1]}/${date.split('/')[0]}/${date.split('/')[2]}`
      );

      const imagesFileLink: any = await formatImage();

      const formatedDate = new Date(
        unformatedDate.getFullYear(),
        unformatedDate.getMonth(),
        unformatedDate.getDate(),
        Number(time.split(':')[0]) - 3,
        Number(time.split(':')[1])
      );

      const files = imagesFileLink[0]
        ? [
            {
              name: imageName,
              size: imageSize,
              url: imagesFileLink[0].url,
              type: imageType
            }
          ]
        : [];

      const formatedData: any = description
        ? {
            name: subject,
            type: mtnType,
            status: maintenanceStatus,
            local: localType,
            localId: localId.length > 0 ? localId : enterpriseIdOfManager,
            unit: localType === 'Unit' ? unit : '',
            block: localType === 'Enterprise' ? '' : block,
            files,
            enterpriseId: enterprise,
            description,
            date: formatedDate
          }
        : {
            name: subject,
            type: mtnType,
            status: maintenanceStatus,
            local: localType,
            localId: localId.length > 0 ? localId : enterpriseIdOfManager,
            unit: localType === 'Unit' ? unit : '',
            block: localType === 'Enterprise' ? '' : block,
            files,
            enterpriseId: enterprise,
            date: formatedDate
          };

      await editMaintenanceRequest(formatedData, maintenanceID, isAdmin);
      await deleteImages();
      handleSetText('Manutenção editada com sucesso!');
      handleSetIsSuccessfully(true);
      handleModalOpen();
    } catch (err: any) {
      handleSetText(
        (Errors as any)({})[String(err.message).trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  function removeUnity() {
    setValue('unit', '');
    setSelectedUnit({ value: '', label: '', block: '' });
    setUnit('');
  }

  function NavigateToListMaintenance() {
    handleModalOpen();
    navigate('../maintenance', { replace: true });
  }

  const backButtonToBackListMaintenance = () => {
    setIsBackButtonClicked(true);
    const { date, description, time, subject } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    block ||
    date ||
    description ||
    enterprise ||
    subject ||
    status ||
    time ||
    typeOfMaintenance ||
    unit
      ? handleBackToLastPageModalOpen()
      : navigate('/maintenance', { replace: true });
  };

  function isModified() {
    const { date, description, time, subject } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    block ||
    date ||
    description ||
    enterprise ||
    subject ||
    status ||
    time ||
    typeOfMaintenance ||
    unit
      ? setChangedPage(true)
      : setChangedPage(false);
  }

  useEffect(() => {
    isModified();
  }, [touchedFields]);

  useEffect(() => {
    getAllData();
  }, [isAdmin]);

  useEffect(() => {
    getEnterpriseData();
    getBlocks();
  }, [selectedEnterpriseOfInput.value, localId, getValues().enterprise]);

  return {
    control,
    register,
    errors,
    handleClickEnterpriseButton,
    handleClickBlockButton,
    NavigateToListMaintenance,
    maintenanceType,
    getUnitFromInput,
    fileLink,
    unit,
    isAdmin,
    status,
    typeOfMaintenance,
    selectedStatus,
    selectedBlock,
    unitsList,
    enterprise,
    selectedUnit,
    isOpenInfoModal,
    isSuccessfully,
    isOpenBackToLastPageModal,
    isBackButtonClicked,
    backButtonToBackListMaintenance,
    sidebarLink,
    text,
    allEnterprise,
    block,
    removeUnity,
    getUnityFromInput,
    blocksList,
    editMaintenance,
    handleModalOpen,
    handleSubmit,
    setSelectedStatus
  };
}

export default useEditMaintenanceController;
