import { Box, Grid, Typography } from '@mui/material';
import Container from './RegisterEnterprise.style';
import {
  CustomControlledInput,
  ShowErrorsMessage,
  MobilePreview,
  BackToLastPageModal,
  InfoModal
} from '../../components/index';
import useRegisterEnterprisesController from './RegisterEnterprise.controller';
import OwnerEnterpriseOptions from './components/OwnerEnterpriseOptions/OwnerEnterpriseOptions';
import EnterpriseButtons from './components/EnterpriseButtons/EnterpriseButtons';
import Customization from './components/Customization/Customization';

export function RegisterEnterprise() {
  const {
    status,
    choiceType,
    control,
    errors,
    isOpenMobilePreview,
    isOpenBackToLastPageModal,
    enterpriseName,
    isSuccessfully,
    infoText,
    isOpenInfoModal,
    sidebarLink,
    createNewEnterprise,
    handleSubmit,
    register,
    handleSetEnterpriseName,
    NavigateToListEnterprises,
    handleModalOpen
  } = useRegisterEnterprisesController();

  return (
    <>
      <Container>
        <Grid container spacing={1} marginBottom={1}>
          <Grid item xs={12} marginBottom={1}>
            <Typography variant="h5" component="h5" fontWeight={600}>
              Cadastro de Empreendimento
            </Typography>
          </Grid>

          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={4}>
              <CustomControlledInput
                id="enterprise_incorporator"
                control={control}
                label="Incorporadora"
                name="incorporator"
                register={{ ...register('incorporator') }}
                type="text"
                placeholder="Insira a incorporadora"
              />
            </Grid>
            <Grid item xs={8} sm={4}>
              <CustomControlledInput
                id="enterprise_status"
                control={control}
                isSelect
                label="Status"
                name="status"
                register={{ ...register('status') }}
                values={status}
                value="inativo"
                placeholder="Selecione"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <CustomControlledInput
                id="enterprise_unit"
                control={control}
                label="Unidade"
                name="unit"
                register={{ ...register('unit') }}
                type="text"
                placeholder="Insira a unidade"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={7} sm={8}>
              <CustomControlledInput
                control={control}
                id="enterprise_name"
                label="Nome do Empreendimento"
                name="name"
                register={{ ...register('name') }}
                type="text"
                placeholder="Insira o nome do empreendimento"
                setEnterpriseName={handleSetEnterpriseName}
              />
            </Grid>
            <Grid item xs={5} sm={4}>
              <CustomControlledInput
                control={control}
                id="enterprise_type"
                label="Tipo de Imóvel"
                isSelect
                name="propertyType"
                register={{ ...register('propertyType') }}
                type="text"
                values={choiceType}
                value="Residencial"
                placeholder="Selecione"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={4.5}>
              <CustomControlledInput
                control={control}
                id="enterprise_cep"
                isCep
                label="CEP"
                name="CEP"
                register={{ ...register('CEP') }}
                type="text"
                placeholder="___-__"
                field="cep"
              />
            </Grid>
            <Grid item xs={9} sm={5.5}>
              <CustomControlledInput
                control={control}
                label="Cidade"
                name="city"
                register={{ ...register('city') }}
                type="text"
                placeholder="Insira a cidade"
              />
            </Grid>
            <Grid item xs={3} sm={2}>
              <CustomControlledInput
                control={control}
                label="Estado"
                name="state"
                register={{ ...register('state') }}
                type="text"
                placeholder="Insira o estado"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={3}>
              <CustomControlledInput
                control={control}
                label="Rua"
                name="street"
                register={{ ...register('street') }}
                type="text"
                placeholder="Insira a rua"
              />
            </Grid>
            <Grid item xs={8} sm={3}>
              <CustomControlledInput
                control={control}
                label="Bairro"
                name="district"
                register={{ ...register('district') }}
                type="text"
                placeholder="Insira o bairro"
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <CustomControlledInput
                control={control}
                id="enterprise_number"
                label="Número"
                name="number"
                register={{ ...register('number') }}
                type="text"
                placeholder="Número"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomControlledInput
                control={control}
                id="enterprise_managerEmail"
                label="E-mail do Administrador"
                name="adminEmail"
                register={{ ...register('adminEmail') }}
                type="text"
                placeholder="Insira o e-mail"
              />
            </Grid>
          </Grid>
        </Grid>

        <ShowErrorsMessage errors={errors} />

        <Box className="optionsBox">
          <Customization />
          <OwnerEnterpriseOptions />
        </Box>

        <EnterpriseButtons onClickRegister={handleSubmit(createNewEnterprise)} />
      </Container>

      {isOpenMobilePreview && (
        <MobilePreview
          control={control}
          register={{ ...register('name') }}
          enterpriseName={enterpriseName}
          setEnterpriseName={handleSetEnterpriseName}
        />
      )}

      {isOpenBackToLastPageModal && (
        <BackToLastPageModal goToPage={sidebarLink || '/enterprises'} />
      )}

      {isOpenInfoModal && (
        <InfoModal
          isSuccessfully={isSuccessfully}
          onClick={isSuccessfully ? NavigateToListEnterprises : handleModalOpen}
          text={infoText}
        />
      )}
    </>
  );
}
