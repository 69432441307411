import { Box, Typography } from '@mui/material';
import ProfileCardProps from './ProfileCard.props';
import Container from './ProfileCard.style';

const ProfileCard = (props: ProfileCardProps) => {
  const { styleForColors } = props;
  return (
    <Container>
      <Box className="profileIcon" style={{ background: styleForColors(false) }}>
        <Typography className="profileIconText">J</Typography>
      </Box>
      <Box className="userData">
        <Typography className="userName">João</Typography>
        <Typography className="accessProfile">Acessar perfil</Typography>
      </Box>
    </Container>
  );
};

export default ProfileCard;
