import { Navigate, Route, Routes } from 'react-router-dom';

import {
  ForgotPassword,
  Login,
  NewPassword,
  NewPasswordSuccess,
  ChangeWebPassword,
  LandingPage
} from '../../pages';

export default function AuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/newPassword" element={<NewPassword />} />
      <Route path="/newPasswordSuccess" element={<NewPasswordSuccess />} />
      <Route path="/changeWebPassword" element={<ChangeWebPassword />} />
    </Routes>
  );
}
