import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';

import { useAuthStore } from '../../store';

function useAccessdeniedController() {
  const [email, setEmail] = useState('');
  const { logout } = useAuthStore();
  function getUserData() {
    const token = localStorage.getItem('token') || '';
    if (!token) return;
    const decoded: any = jwt_decode(token);
    setEmail(decoded.email);
  }

  useEffect(() => {
    getUserData();
  }, []);

  return {
    email,
    logout
  };
}

export default useAccessdeniedController;
