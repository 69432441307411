import { Box, Button, Typography, Link } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Container from './CustomFileInputManual.style';
import useCustomFileInput from './CustomFileInputManual.controller';
import { CustomFileInputProps } from './CustomFileInputManual.props';
import selectfile from '../../../../assets/images/selectfile.svg';

export function CustomFileInputManual(props: CustomFileInputProps) {
  const { accept, type, hasImageSizeValidation = true, handleFile, isTenSize } = props;
  const { signedManualUrl, manualName, handleChangeFile, handleRemoveFile } = useCustomFileInput(
    type,
    hasImageSizeValidation,
    handleFile,
    isTenSize
  );
  return (
    <Container>
      {manualName ? (
        <>
          <Box className="selectedFile">
            <Typography variant="body2" display="flex" alignItems="center">
              {manualName === 'Visualizar Manual' ? (
                <Link href={signedManualUrl} target="_blank" color="#0069c2" className="link">
                  {manualName}
                </Link>
              ) : (
                manualName
              )}
            </Typography>
          </Box>
          <Button className="closeButton" onClick={handleRemoveFile}>
            <CloseOutlined />
          </Button>
        </>
      ) : (
        <>
          <img src={selectfile} alt="select file icon" className="icon" />
          <Box flexDirection="column">
            <Typography marginBottom={0.5} variant="body2">
              Selecione um arquivo ou arraste aqui
            </Typography>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.5)">
              {isTenSize ? 'Arquivos até 10MB' : 'Arquivos até 100MB'}
            </Typography>
          </Box>
          <input
            id="fileInput"
            name="fileInput"
            type="file"
            accept={accept}
            onChange={handleChangeFile}
          />
        </>
      )}
    </Container>
  );
}
