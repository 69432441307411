/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Button, Modal as ModalComponent, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { CustomButton } from '../../../../components';
import useRegisterBlockModalController from './RegisterBlockModal.controller';
import Background from './RegisterBlockModal.style';

const close = require('../../../../assets/images/close.svg');
const buildingIcon = require('../../../../assets/images/buildingIcon.svg');
const trash = require('../../../../assets/images/trash.svg');

export function RegisterBlockModal() {
  const {
    isOpenRegisterModal,
    openRegisterModal,
    allBlocks,
    handleSetBlock,
    handleRemoveBlock,
    submit,
    register,
    control,
    errors,
    handleSubmit
  } = useRegisterBlockModalController();

  return (
    <ModalComponent open={isOpenRegisterModal} onClose={openRegisterModal}>
      <Background>
        <Box className="container">
          <Box className="closeModal">
            <Button className="closeButton" onClick={openRegisterModal}>
              <close.ReactComponent />
            </Button>
          </Box>
          <Box className="content">
            <Typography className="title">Criação de Blocos</Typography>
            <Typography className="subTitle">Blocos</Typography>
            <Box className="inputsRow">
              <Box>
                <Controller
                  name="block_name"
                  control={control}
                  render={() => (
                    <Box className="blockInputContainer">
                      <Box className="blockContainer">
                        <TextField
                          id="block-name-input"
                          className="timeInput"
                          placeholder="Adicionar Bloco"
                          {...register('block_name')}
                          error={!!errors.block_name}
                        />
                        <buildingIcon.ReactComponent className="buildingIcon" />
                      </Box>

                      <Box className="buttonContainer">
                        <CustomButton
                          id="block-register-add"
                          onClick={handleSubmit(handleSetBlock)}
                          text="Incluir bloco"
                        />
                      </Box>
                    </Box>
                  )}
                />
                <Typography className="errorMessage">{errors.block_name?.message}</Typography>
              </Box>
            </Box>
            <Box className="contentContainer">
              {allBlocks &&
                allBlocks.map((block: { block_name: string }) => (
                  <Box className="inputsRow" key={block.block_name}>
                    <Box className="blockInputContainer">
                      <Box className="blockContainer">
                        <TextField
                          className="timeInput"
                          placeholder="Adicionar Bloco"
                          value={block.block_name}
                          disabled
                        />
                        <buildingIcon.ReactComponent className="buildingIcon" />
                      </Box>

                      <Box
                        className="trashContainer"
                        onClick={() => handleRemoveBlock(block.block_name)}>
                        <trash.ReactComponent className="trashIcon" />
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box className="buttonsContainer">
              <Button
                id="block-register-save"
                disabled={!allBlocks || allBlocks?.length === 0}
                style={{
                  opacity: !allBlocks || allBlocks?.length === 0 ? '0.8' : '1',
                  color: '#fff'
                }}
                className="saveButton"
                onClick={submit}>
                Salvar
              </Button>
              <Button className="backButton" onClick={openRegisterModal}>
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
      </Background>
    </ModalComponent>
  );
}
