import { Grid, Typography } from '@mui/material';
import Background from './Orders.style';

import {
  ActionModal,
  BackToLastPageModal,
  CustomButton,
  CustomSearchInput,
  CustomTable,
  InfoModal
} from '../../components/index';
import { Modal } from './Modal/Modal';

import checkIcon from '../../assets/images/orderCheck.svg';
import infoIcon from '../../assets/images/info.svg';

import useOrdersController from './Orders.controller';
import { GetOrderModal } from './GetOrderModal/GetOrderModal';
import { ModalInfo } from './ModalInfo/ModalInfo';

export function Orders() {
  const {
    isModalRegisterOpen,
    toggleRegisterModal,
    successModal,
    actionModalData,
    variant,
    tableHeaders,
    rowsId,
    totalPages,
    totalOrders,
    handleDrawerOpen,
    changePage,
    isSuccessfully,
    orders,
    isGetOrderModal,
    toggleGetOrderModal,
    isOpenModalInfo,
    toggleInfoModal,
    indexOfRow,
    handleSetOrderId,
    handleSearch,
    allData,
    isOpenBackToLastPageModal,
    isOpenInfoModal,
    handleModalOpen,
    text,
    sortDataFormated
  } = useOrdersController();

  const icons = [<img src={checkIcon} alt="check" />, <img src={infoIcon} alt="info" />];

  return (
    <Background>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Encomendas
          </Typography>
          <CustomSearchInput id="orders_search" handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={3} alignSelf="flex-end">
          <CustomButton
            id="orders_create"
            onClick={toggleRegisterModal}
            text="Registrar encomenda"
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            noFilesFoundText="Não há nenhum registro de encomendas no momento."
            sortFunc={sortDataFormated}
            rowsId={rowsId}
            tableHead={tableHeaders}
            tableData={orders}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalOrders}
            funcToChangePage={changePage}
            containId
            handleSetItemId={(id) => handleSetOrderId(id)}
            renderAction={(row) => {
              // eslint-disable-next-line react/jsx-no-useless-fragment
              return (
                <ActionModal
                  ActionModalData={
                    row.status === 'Confirmado pelo morador'
                      ? actionModalData
                      : [actionModalData[1]]
                  }
                  ButtonsIcon={row.status === 'Confirmado pelo morador' ? icons : [icons[1]]}
                />
              );
            }}
          />
        </Grid>
      </Grid>

      <Modal
        variant={variant}
        isModalOpen={isModalRegisterOpen}
        toggleModal={toggleRegisterModal}
        onComplete={successModal}
      />

      {isOpenModalInfo && (
        <ModalInfo
          indexOfRow={indexOfRow}
          tableOrders={allData}
          isModalOpen={isOpenModalInfo}
          onComplete={successModal}
          toggleModal={toggleInfoModal}
        />
      )}

      <GetOrderModal
        isModalOpen={isGetOrderModal}
        toggleModal={toggleGetOrderModal}
        onComplete={() => {
          successModal();
        }}
      />

      {isOpenBackToLastPageModal && (
        <BackToLastPageModal goToPage="/orders" handleModalClose={toggleRegisterModal} />
      )}

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}
    </Background>
  );
}
