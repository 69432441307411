import styled from 'styled-components';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  flex-wrap: wrap;
  max-width: 572px;
  min-width: 572px;
  display: flex;
  gap: 16px;

  .expandButton {
    background-color: #662483;
    border-radius: 8px;
    padding: 8px;
    font-size: 12px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    height: 31px;

    :hover {
      background-color: #6f3483;
    }
  }

  .tablePermission {
    background-color: #662483;
    border-radius: 8px;
    padding: 8px;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    height: 31px;
  }
`;
