/* eslint-disable no-nested-ternary */
import { Box, Modal as ModalComponent, Typography } from '@mui/material';

import { ModalProps } from './UnitsChoiced.props';
import {
  ModalContent,
  ModalTitle,
  ModalCloseButton,
  ModalCloseButtonIcon
} from './UnitsChoiced.style';

import { useDetailsModalController } from './UnitsChoiced.controller';

export function UnitsChoicedModal({ isModalOpen }: ModalProps) {
  const { unitsChoicedData, blocksChoicedData, blockName, local, closeModal } =
    useDetailsModalController();
  return (
    <ModalComponent open={isModalOpen} onClose={closeModal}>
      <ModalContent>
        <ModalCloseButton
          onClick={closeModal}
          style={{
            position: 'absolute',
            right: 10,
            top: 10
          }}>
          <ModalCloseButtonIcon />
        </ModalCloseButton>

        <ModalTitle>
          {local === 'enterprise'
            ? 'Empreendimento'
            : local !== 'enterprise' && unitsChoicedData.length > 0
              ? `Unidades do Bloco ${blockName}`
              : 'Blocos'}
        </ModalTitle>
        <Box className="container">
          {local !== 'enterprise' && unitsChoicedData.length > 0
            ? unitsChoicedData.map((unit) => (
                <Typography key={unit} className="unitsText">
                  {unit}
                </Typography>
              ))
            : blocksChoicedData.map((block) => (
                <Typography className="unitsText" key={block}>
                  {block}
                </Typography>
              ))}
          {local === 'enterprise' && (
            <Typography className="unitsText" textAlign="center">
              Todas as unidades do empreendimento.
            </Typography>
          )}
        </Box>
      </ModalContent>
    </ModalComponent>
  );
}
