import { FormControlLabel } from '@mui/material';
import useEnterpriseFunctionalityController from './Checkbox.controller';
import { RoleCheckbox, RoleCheckboxArea } from './Checkbox.style';

interface CheckboxProps {
  handleGetFunctionalities: (indexOfPermissions: number) => void;
  functionalitiesSelected: string[];
}

export const Checkbox = ({ handleGetFunctionalities, functionalitiesSelected }: CheckboxProps) => {
  const { getCheckedFunctionalities, functionalities } = useEnterpriseFunctionalityController();

  return (
    <RoleCheckboxArea>
      {functionalities.map((job, index) => (
        <FormControlLabel
          className="checkboxText"
          key={job}
          control={
            <RoleCheckbox
              id={job}
              checked={getCheckedFunctionalities(index, functionalitiesSelected)}
              onChange={() => handleGetFunctionalities(index)}
              style={{ color: '#BEC8D0' }}
            />
          }
          label={job}
        />
      ))}
    </RoleCheckboxArea>
  );
};
