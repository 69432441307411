/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-useless-escape */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import startcase from 'lodash.startcase';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import {
  useBackToLastPageModalStore,
  useInfoModalStore,
  useLoadModalStore,
  useAdminORStore,
  useBlocksStore,
  usePaymentSlipStore
} from '../../../../store';
import { FormRequiredFields } from './Modal.props';
import { Errors } from '../../../../constants/Errors';

export const useModalController = (
  edit: boolean | undefined,
  toggleModal: () => void,
  getAllData: () => void
) => {
  const [isSelectedBlock, setIsSelectedBlock] = useState(false);
  const [block, setBlock] = useState('');
  const [unit, setUnit] = useState('');
  const [unitID, setUnitId] = useState('');

  const [unitValue, setUnitValue] = useState('');
  const [blockValue, setBlockValue] = useState('');
  const [isInputValue, setIsInputValue] = useState('');
  const [isInputBlockValue, setIsInputBlockValue] = useState('');

  const { enterpriseIdOfManager } = useAdminORStore();
  const { toogleLoading } = useLoadModalStore();
  const { handleBackToLastPageModalOpen } = useBackToLastPageModalStore();
  const { handleModalOpen, handleSetIsSuccessfully, handleSetText } = useInfoModalStore();

  const {
    getSimpleBlockListData,
    setEnterpriseID,
    blocksList,
    unitList,
    setUnitListData,
    setBlockList
  } = useBlocksStore();

  const {
    folderId,
    folderList,
    isModalRegisterOpen,
    isModalEditOpen,
    setIsModalEditOpen,
    getPaymentFolderByIdRequest,
    registerPaymentSlipRequest,
    editPaymentSlipRequest
  } = usePaymentSlipStore();

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[ a-zA-Z0-9\u00f1\u00d1]*$/g,
        'Nome inválido para pasta, evite usar caracteres especiais.'
      )
      .required('Por favor, preencher campo nome.')
      .max(50, 'É permitido até 50 caracteres para o nome de uma pasta.')
      .trim(),
    block: edit
      ? yup.string().notRequired()
      : yup.string().required('Por favor, selecione um Bloco.'),
    unit: edit
      ? yup.string().notRequired()
      : yup.string().required('Por favor, selecione uma Unidade.')
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      name: '',
      block: '',
      unit: ''
    },
    resolver: yupResolver(schema)
  });

  function folderNameAlreadExists() {
    if (folderList.length === 0) return;
    const { name } = getValues();
    return folderList.some((folder) => folder.name === name);
  }

  async function getBlockListRequest() {
    if (edit) return;
    const data = await getSimpleBlockListData(enterpriseIdOfManager);
    setBlockList(data);
  }

  const getFolderData = async () => {
    if (!edit) {
      setBlock('');
      setUnit('');
      setValue('block', '');
      setValue('name', '');
      setValue('unit', '');
      return;
    }

    try {
      const data = await getPaymentFolderByIdRequest(folderId);
      setValue('name', data.name);
      setUnitId(data.unitId);
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      handleModalOpen();
      throw new Error('Invalid Credentials');
    }
  };

  const onSubmitRegister = async () => {
    if (folderNameAlreadExists()) {
      const { name } = getValues();
      toast.error(`Já existe uma pasta com este nome: ${name}`);
      return;
    }
    toogleLoading();

    try {
      const folder = getValues();
      const formatedData = {
        name: startcase(folder.name),
        unitId: folder.unit
      };

      await registerPaymentSlipRequest(formatedData);
      toggleModal();
      handleSetIsSuccessfully(true);
      handleSetText('Pasta criada com sucesso!');
      handleModalOpen();
      getAllData();
    } catch (error: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[error.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  function clearFormData() {
    setValue('block', '');
    setBlockValue('');
    setValue('unit', '');
    setUnitValue('');
    setValue('name', '');
  }

  // eslint-disable-next-line func-names
  window.onbeforeunload = function () {
    const { block: inputBlockValue, name, unit: inputUnitValue } = getValues();
    if (inputBlockValue || name || inputUnitValue) {
      return true;
    }
    return null;
  };

  const onSubmitEdit = async () => {
    toogleLoading();
    try {
      const folder = getValues();
      const formatedData = {
        name: startcase(folder.name),
        unitId: unitID
      };

      await editPaymentSlipRequest(formatedData, folderId);
      toggleModal();
      handleSetIsSuccessfully(true);
      handleSetText('Pasta editada com sucesso!');
      setIsModalEditOpen(false);
      handleModalOpen();
      clearFormData();
      getAllData();
    } catch (error: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[error.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  const submit = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    edit ? onSubmitEdit() : onSubmitRegister();
  };

  const closeModal = () => {
    const { block: inputBlockValue, name, unit: inputUnitValue } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (inputBlockValue || inputUnitValue || name) {
      handleBackToLastPageModalOpen();
    } else {
      clearFormData();
      toggleModal();
    }
  };

  useEffect(() => {
    if (!isModalRegisterOpen) return;
    getBlockListRequest();
    if (!isModalEditOpen) clearFormData();
    if (isModalEditOpen) getFolderData();
  }, [isModalRegisterOpen]);

  const handleSelectBlock = async () => {
    await getSimpleBlockListData();
  };

  function handleSearchSelect(e: any) {
    if (!edit) {
      setUnitValue('');
      setValue('unit', '');
    }
    setIsInputValue(e);
  }

  function handleSearchBlockSelect(e: any) {
    setBlockValue('');
    setValue('block', '');
    setIsInputBlockValue(e);
  }

  function cleanUnitValue() {
    setUnitValue('');
    setValue('unit', '');
  }

  useEffect(() => {
    if (!(isModalRegisterOpen || edit)) return;
    setEnterpriseID(enterpriseIdOfManager);
    handleSelectBlock();
  }, [enterpriseIdOfManager]);

  useEffect(() => {
    if (!isModalRegisterOpen) return;
    if (edit) return;
    setIsSelectedBlock(!!blockValue);
    setUnitValue('');
    setValue('unit', '');
  }, [blockValue]);

  return {
    closeModal,
    handleSubmit,
    register,
    isSelectedBlock,
    control,
    errors,
    block,
    unit,
    getValues,
    submit,
    handleBackToLastPageModalOpen,
    blocksList,
    unitList,
    setUnitListData,
    setIsSelectedBlock,
    handleSearchSelect,
    handleSearchBlockSelect,
    isInputValue,
    setIsInputValue,
    isInputBlockValue,
    setIsInputBlockValue,
    unitValue,
    blockValue,
    setUnitValue,
    setBlockValue,
    cleanUnitValue
  };
};
