/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { IFormRequiredFields } from './Modal.props';
import { useInfoModalStore, useLoadModalStore, usePartnersStore } from '../../../../store';
import { Errors } from '../../../../constants/Errors';
import { usePartnersController } from '../../Partners.controller';
import { formatImageUrl } from '../../../../helpers/formatImageUrl';

import { getAWSImageUrl } from '../../../../services/requests/partners/Partners.request';

export const useRegisterModalController = (
  edit: boolean | undefined,
  toogleModal: () => void,
  id: string,
  handleSetText: (text: string) => void,
  handleSuccessfully: (isSuccessfully: boolean) => void
) => {
  const { handleModalOpen } = useInfoModalStore();

  const [sectorData, setSectorData] = useState('');

  const {
    partnerId,
    placeholderFile,
    signedimagePartnersUrl,
    imagePartnersURL,
    setImagePartnersURL,
    registerPartnerRequest,
    getPartnerById,
    editPartnerRequest,
    handleSetPlaceholderFile,
    setSignedImgUrl
  } = usePartnersStore();
  const { getAllData } = usePartnersController();
  const { toogleLoading } = useLoadModalStore();

  const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

  const schema = yup.object().shape({
    name: yup
      .string()
      .required('Insira o nome da empresa.')
      .max(50, 'É permitido até 50 caracteres para o nome da empresa.'),
    CNPJ: yup.string().matches(cnpjRegex, 'Insira um CNPJ válido.').required('Insira o CNPJ.'),
    email: yup
      .string()
      .email('Insira um e-mail válido.')
      .notRequired()
      .max(80, 'É permitido até 80 caracteres para o e-mail da empresa.'),
    cel: yup
      .string()
      .test('cel', 'Número de celular inválido!', (value) => {
        if ((value || '')?.length > 0 && (value?.match(/\d/g) || '').length < 13) return false;
        return true;
      })
      .notRequired(),
    tel: yup
      .string()
      .test('tel', 'Número de telefone inválido!', (value) => {
        if ((value || '')?.length > 0 && (value?.match(/\d/g) || '').length < 12) return false;
        return true;
      })
      .when('cel', {
        is: (val: string) => !val && !val.length,
        then: yup.string().required('Insira o telefone ou celular para contato.'),
        otherwise: yup.string().notRequired()
      }),
    company_segment: yup.string().required('Insira o segmento da empresa!'),
    website: yup.string().notRequired(),
    appleStore: yup.string().notRequired(),
    googlePlay: yup.string().notRequired(),
    sector: yup.string().required('Selecione o setor da empresa!')
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<IFormRequiredFields>({
    defaultValues: {
      name: '',
      CNPJ: '',
      email: '',
      cel: '',
      tel: '',
      company_segment: '',
      website: '',
      appleStore: '',
      googlePlay: ''
    },
    resolver: yupResolver(schema)
  });

  window.onbeforeunload = () => {
    const {
      appleStore,
      cel,
      CNPJ,
      email,
      name,
      googlePlay,
      company_segment,
      sector,
      tel,
      website
    } = getValues();
    if (
      appleStore ||
      cel ||
      CNPJ ||
      email ||
      name ||
      googlePlay ||
      company_segment ||
      tel ||
      website ||
      sector
    ) {
      return true;
    }
    return null;
  };

  const sector = [
    {
      value: 'Comercio',
      label: 'Comércio'
    },
    {
      value: 'Servicos',
      label: 'Serviços'
    },
    {
      value: 'Industria',
      label: 'Indústria'
    }
  ];

  const closePartnersModal = () => {
    toogleModal();
  };

  function formatWebsiteURL(url: string) {
    if (url.includes('https://')) return url;
    return `https://${url}`;
  }

  function formatFormData(formatedData: IFormRequiredFields) {
    const dataToRequest = new FormData();

    dataToRequest.append('name', formatedData.name.trim());
    dataToRequest.append('CNPJ', formatedData.CNPJ.trim());
    if (formatedData.appleStore)
      dataToRequest.append('appleStore', formatWebsiteURL(String(formatedData.appleStore).trim()));
    if (formatedData.googlePlay)
      dataToRequest.append('googlePlay', formatWebsiteURL(String(formatedData.googlePlay).trim()));
    if (formatedData.company_segment)
      dataToRequest.append('company_segment', String(formatedData.company_segment).trim());
    if (formatedData.email) dataToRequest.append('email', String(formatedData.email).trim());
    if (formatedData.sector) dataToRequest.append('sector', String(formatedData.sector).trim());
    if (formatedData.cel) dataToRequest.append('cel', String(formatedData.cel).trim());
    if (formatedData.tel) dataToRequest.append('tel', String(formatedData.tel).trim());
    if (formatedData.website)
      dataToRequest.append('website', formatWebsiteURL(formatedData.website).trim());
    if (formatedData.file) {
      dataToRequest.append('file', formatedData.file);
    } else {
      dataToRequest.append('logo_url', '');
    }

    return dataToRequest;
  }

  function formatFormEditData(formatedData: IFormRequiredFields) {
    const dataToRequest = new FormData();

    dataToRequest.append('name', formatedData.name.trim());
    dataToRequest.append('CNPJ', formatedData.CNPJ.trim());
    dataToRequest.append('appleStore', String(formatedData.appleStore).trim());
    dataToRequest.append('googlePlay', String(formatedData.googlePlay).trim());
    dataToRequest.append('company_segment', String(formatedData.company_segment).trim());
    dataToRequest.append('email', String(formatedData.email).trim());
    dataToRequest.append('sector', String(formatedData.sector).trim());
    dataToRequest.append('cel', String(formatedData.cel).trim());
    dataToRequest.append('tel', String(formatedData.tel).trim());
    dataToRequest.append('website', formatWebsiteURL(formatedData.website || '').trim());

    if (formatedData.file) {
      dataToRequest.append('file', formatedData.file);
    }

    if (signedimagePartnersUrl) {
      dataToRequest.append('logo_url', imagePartnersURL);
    }

    return dataToRequest;
  }

  const getPartnerData = async () => {
    toogleLoading();
    try {
      const data = await getPartnerById();
      if (data.logoUrl) {
        const { url } = await getAWSImageUrl(formatImageUrl(data.logoUrl));
        setSignedImgUrl(url);
      }
      setImagePartnersURL(String(data.logoUrl));
      setValue('CNPJ', String(data.CNPJ));
      setValue('appleStore', String(data.appleStore));
      setValue('cel', String(data.cel));
      setValue('company_segment', String(data.segment));
      setValue('email', String(data.email));
      setValue('googlePlay', String(data.googlePlay));
      setValue('name', String(data.name));
      setValue('sector', String(data.sector));
      setValue('tel', String(data.tel));
      setValue('website', String(data.website));
      setSectorData(String(data.sector));
    } catch (err: any) {
      handleSuccessfully(false);
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  const onSubmitEdit = async () => {
    toogleLoading();
    try {
      const data = getValues();
      const dataWithFile = { ...data, file: placeholderFile };
      const dataToSubmit = placeholderFile ? dataWithFile : data;
      const formatedData = formatFormEditData(dataToSubmit);
      await editPartnerRequest(formatedData, partnerId);

      closePartnersModal();
      handleSuccessfully(true);
      getAllData();
      handleSetText('Parceiro editado com sucesso');
      handleModalOpen();
    } catch (err: any) {
      handleSuccessfully(false);
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  const onSubmitRegister = async () => {
    toogleLoading();
    try {
      const data = getValues();
      const dataWithFile = { ...data, file: placeholderFile };
      const formatedData = formatFormData(dataWithFile);

      await registerPartnerRequest(formatedData);

      closePartnersModal();
      handleSuccessfully(true);
      getAllData();
      handleSetText('Parceiro cadastrado com sucesso');
      handleModalOpen();
    } catch (err: any) {
      handleSuccessfully(false);
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  const submit = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    edit ? onSubmitEdit() : onSubmitRegister();
  };

  useEffect(() => {
    setSignedImgUrl('');
    setImagePartnersURL('');
    handleSetPlaceholderFile(null);
    (async () => {
      if (edit) await getPartnerData();
    })();
  }, []);

  return {
    control,
    errors,
    sector,
    sectorData,
    signedimagePartnersUrl,
    handleSubmit,
    register,
    submit,
    closePartnersModal
  };
};
