/* eslint-disable @typescript-eslint/naming-convention */
interface ResidentAllData {
  id?: string;
  unitId: string;
  block: string;
  cpf: string;
  name: string;
  email: string;
  phone: string;
}

export function formatResidentData(original: ResidentAllData, modified: ResidentAllData) {
  const { unitId, block, cpf, email, name, phone } = original;

  const formatedData = {
    unitId: modified.unitId ? modified.unitId : unitId,
    block: modified.block ? modified.block : block,
    cpf: modified.cpf ? modified.cpf : cpf,
    name: modified.name ? modified.name : name,
    email: modified.email ? modified.email : email,
    phone: modified.phone ? modified.phone : phone
  };

  return formatedData;
}
