import { Box, Typography } from '@mui/material';

import Background from './FormNewPasswordSuccess.style';

import { Logo } from '../../../../components';

export const FormNewPasswordSuccess = () => {
  return (
    <Background>
      <Box className="logoAndLoginContainer">
        <Logo />
        <Box>
          <Typography variant="h4" fontWeight="600" fontSize={24}>
            Senha redefinida
          </Typography>
        </Box>
        <Typography
          className="subtitle"
          variant="body1"
          fontWeight="400"
          color="text.secondary"
          fontSize={16}>
          Você já pode voltar para o login e utilizar a sua <br /> nova senha.
        </Typography>
      </Box>
    </Background>
  );
};
