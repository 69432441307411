/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAreaStore, usePreviewImageModalStore } from '../../../../store';
import { isInvalidFileName } from '../../../../helpers/fileNameValidator';

function useCustomFileInput(type: string) {
  const [files, setFiles]: any = useState([{ src: '', name: '' }]);

  const {
    handleSetImages,
    handleSetImage,
    images,
    filesToGetLink,
    handleSetImagesToRequest,
    formatedImageToRequest,
    handleSetFilesToGetLink
  } = useAreaStore();
  const { handleImagePreviewOpen } = usePreviewImageModalStore();

  function fillContainerWithInput(e: any) {
    const fileInput = document.getElementById('fileInputSelected');
    const fileInputContainer = document.getElementById('fileInputSelectedContainer');
    // @ts-ignore
    fileInputContainer.onclick = () => {
      fileInput?.click();
    };
  }

  const handleRemoveFile = async (name: string) => {
    const newFiles = [...files];
    const newImages = [...images];

    const formatedFiles = newFiles.filter((file: { name: string }) => file.name !== name);
    const formatedImages = newImages.filter((file: { name: string }) => file.name !== name);

    setFiles(formatedFiles);
    handleSetImages(formatedImages);

    const formatedName = name.replaceAll(' ', '').replaceAll('-', '').toLowerCase();

    if (filesToGetLink.length > 0) {
      handleSetFilesToGetLink(filesToGetLink.filter((file) => file.name !== name));
    }

    if (formatedImageToRequest.filter((image) => image.url.includes(formatedName)).length === 0)
      return;

    const newFormatedImagesToRequest = formatedImageToRequest.filter(
      (image) => !image.url.includes(formatedName)
    );
    handleSetImagesToRequest(newFormatedImagesToRequest);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target as any;

    if (event.files) {
      if (files.length > 10) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (event.files.length > 10) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (
        Array.prototype.slice.call(event.files).filter((file: any) => file.name.length >= 50)
          .length > 0
      ) {
        toast.error('O nome do arquivo precisa ter menos de 50 caracteres.');
        return;
      }

      if (
        type === 'image' &&
        Array.prototype.slice
          .call(event.files)
          .filter((file: any) => file.type !== 'image/jpeg' && file.type !== 'image/png').length > 0
      ) {
        toast.error('É permitido apenas arquivos JPEG e PNG');
        return;
      }

      if (
        Array.prototype.slice.call(event.files).filter((file: any) => isInvalidFileName(file.name))
          .length > 0
      ) {
        return;
      }
    }

    const newImages = [...images, ...event.files];

    handleSetImages(newImages);
    if (files[0] && files[0].src === '') files.pop();

    const filesImage = Array.from(event.files);
    filesImage.forEach((img: any) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          if (files.length + event.files.length > 10) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (files.length === 10) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (Number(img.size) > 10000000) {
            toast.error(`O tamanho da imagem ${img.name} excede 10MB`);
            return;
          }

          if (files.filter((file: { name: string }) => file.name === img.name).length > 0) {
            toast.error(`Você já inseriu a imagem: ${img.name}`);
            return;
          }

          setFiles((prev: any) => [...prev, { src: reader.result, name: img.name }]);
          handleSetFilesToGetLink([...filesToGetLink, ...Array.prototype.slice.call(event.files)]);
        }
      };
      reader.readAsDataURL(img);
    });
  };

  function getImageToPreview(file: any) {
    handleSetImage(file);
    handleImagePreviewOpen();
  }

  return {
    handleChangeFile,
    files,
    handleRemoveFile,
    fillContainerWithInput,
    getImageToPreview
  };
}

export default useCustomFileInput;
