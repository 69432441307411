import { Grid, Button, Typography } from '@mui/material';
import { CustomFileInput } from '../../../../components';
import Container from './Customization.style';
import CustomMobilePreview from '../CustomMobilePreview/CustomMobilePreview';
import useCustomizationController from './Customization.controller';

function Customization() {
  const { handleMobilePreviewOpen } = useCustomizationController();

  return (
    <Container>
      <CustomMobilePreview />
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight={600} marginTop={1} marginBottom={1}>
          Logotipo
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <CustomFileInput isTenSize accept="image/png|image/jpeg" type="image" />
        <Typography color="#727272" variant="subtitle2" marginBottom={1} marginTop={1.5}>
          Arquivos permitidos (envio opcional): jpg, png, máximo de 190 x 55 px.
        </Typography>
      </Grid>

      <Grid item xs={12} marginTop={3}>
        <Button className="showCustomization" onClick={handleMobilePreviewOpen}>
          Visualizar customização
        </Button>
      </Grid>
    </Container>
  );
}

export default Customization;
