/* eslint-disable @typescript-eslint/no-var-requires */
import { Grid, Typography, Link } from '@mui/material';
import useAreasController from './Areas.controller';
import {
  ActionModal,
  DeleteModal,
  InfoModal,
  CustomSearchInput,
  CustomTable
} from '../../components';
import Container from './Areas.style';

const enterpriseactionedit = require('../../assets/images/enterpriseactionedit.svg');
const enterpriseactionreport = require('../../assets/images/enterpriseactionreport.svg');
const enterpriseactiontrash = require('../../assets/images/enterpriseactiontrash.svg');

export function Areas() {
  const {
    actionModalData,
    areaDeleteMessage,
    areaTableHead,
    areaId,
    areasId,
    areaList,
    totalPages,
    totalAreas,
    isOpenDeleteModal,
    isOpenInfoModal,
    isSuccessfully,
    infoText,
    handleModalOpen,
    handleDeleteModalOpen,
    handleSearch,
    changePage,
    sortArea,
    handleSetAreaId,
    handleDrawerOpen,
    onSubmitDelete
  } = useAreasController();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Áreas
          </Typography>
          <CustomSearchInput id="area-search" handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12} sm={12} md={2.5} lg={2.5} alignSelf="flex-end">
          <Link id="area-new" href="/area/register" className="registerButton" underline="none">
            Criar área
          </Link>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            rowsId={areasId}
            tableHead={areaTableHead}
            tableData={areaList || []}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalAreas}
            funcToChangePage={changePage}
            handleSetItemId={handleSetAreaId}
            noFilesFoundText="Não há nenhum registro de áreas no momento."
            sortFunc={sortArea}
            containId
            ActionModal={
              <ActionModal
                ActionModalData={actionModalData}
                ButtonsIcon={[
                  <enterpriseactionedit.ReactComponent className="actionIcon" />,
                  <enterpriseactionreport.ReactComponent className="actionIcon" />,
                  <enterpriseactiontrash.ReactComponent className="actionIcon" />
                ]}
              />
            }
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title={areaDeleteMessage.title}
          onClickDelete={() => onSubmitDelete(areaId)}
          handleModalClose={handleDeleteModalOpen}
        />
      )}
      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={infoText} />
      )}
    </Container>
  );
}
