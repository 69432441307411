/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-var-requires */

import { Box, Grid, Typography, Checkbox } from '@mui/material';
import useBlocksAndUnitsController from './BlocksAndUnits.controller';
import { IBlocksAndUnits } from './BlocksAndUnits.props';
import Container from './BlocksAndUnits.style';

export function BlocksAndUnits(props: IBlocksAndUnits) {
  const { blockList, unitList, handleSetBlock, handleSetUnit } = useBlocksAndUnitsController();
  const { isShowUnit } = props;

  return (
    <Container>
      <Box>
        <Typography className="containerText">
          Selecione os blocos que visualizarão o informativo
        </Typography>
        <Box className="blockContainer">
          <Box className="titleContainer">
            <Typography>Blocos</Typography>
          </Box>
          <Box className="contentContainer">
            {blockList.length > 0 ? (
              blockList.map((block) => (
                <Grid container spacing={2} marginBottom={1} paddingX={2}>
                  <Grid item xs={3}>
                    <Checkbox id={block.label} onClick={() => handleSetBlock(block.value)} />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className="labelText">{block.label}</Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Box className="empty">
                <Typography>Selecione um bloco para ver suas unidades.</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {isShowUnit && (
        <Box>
          <Typography className="containerText">
            Selecione os apartamentos que visualizarão o informativo
          </Typography>
          <Box className="unitContainer">
            <Box className="titleContainer">
              <Typography>Apartamentos</Typography>
            </Box>
            <Box className="contentContainer">
              {unitList.length > 0 ? (
                unitList.map((unit) => (
                  <Grid container spacing={2} marginBottom={1} paddingX={2}>
                    <Grid item xs={3}>
                      <Checkbox onClick={() => handleSetUnit(unit.value)} />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography className="labelText">{unit.label}</Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Box className="empty">
                  <Typography>Selecione um bloco para ver suas unidades.</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
}
