import { Grid, Typography } from '@mui/material';
import { useAreaTimeInformationController } from './AreaTimeInformation.controller';
import { Background } from './AreaTimeInformation.style';
import { FreeTimeSchedule } from './components/FreeTimeSchedule/FreeTimeSchedule';
import { WeekList } from './components/WeekList/WeekList';
import NoFilesFound from './components/NoFilesFound/NoFilesFound';
import { IReservationPeriod } from './AreaTimeInformation.props';

export function AreaTimeInformation() {
  const { locationTime, navigation, getDayPosition, areaName, allWeekDay, formatTimeSize } =
    useAreaTimeInformationController();

  return (
    <Background>
      <Grid container spacing={1}>
        <div className="header-container">
          <Typography className="title">{areaName}</Typography>
          <Typography className="subTitle">
            Olá! Você está na tela de horários disponíveis da área.
          </Typography>
        </div>
        <Grid item xs={12}>
          <WeekList />
        </Grid>
        <Grid container rowSpacing={2} columnGap={2} className="date-time">
          {locationTime &&
          locationTime[getDayPosition()] &&
          locationTime[getDayPosition()].length > 0 ? (
            locationTime[getDayPosition()].map((data: IReservationPeriod) => {
              return (
                <Grid item key={JSON.stringify(data)} className="date-time-content">
                  <FreeTimeSchedule
                    final_time={formatTimeSize(data.final_time)}
                    initial_time={formatTimeSize(data.initial_time)}
                  />
                  {data.initial_time > data.final_time && (
                    <Typography className="infoNextDayText">
                      Este horário refere-se ao próximo dia:{' '}
                      {allWeekDay[getDayPosition() + 1] || 'Domingo'}.
                    </Typography>
                  )}
                </Grid>
              );
            })
          ) : (
            <NoFilesFound text="Não há nenhum horário disponível para esse dia." />
          )}
        </Grid>
        <button type="button" className="voltarBtn" onClick={() => navigation('/area')}>
          Voltar
        </button>
      </Grid>
    </Background>
  );
}
