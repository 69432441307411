import { Box, FormControl, IconButton, Modal, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';
import {
  Background,
  ModalBackButton,
  ModalBackButtonText,
  ModalCloseButton,
  ModalContent,
  ModalSubtitle,
  ModalTitle,
  Logo,
  BoxLogo
} from './FormForgotPassword.style';

import checkIcon from '../../../../assets/images/check.svg';
import closeIcon from '../../../../assets/images/close.svg';

import { CustomInput, CustomButton } from '../../../../components';

import { useForgotPasswordController } from './FormForgotPassword.controller';
import arrowIcon from '../../../../assets/images/arrow.svg';

export const FormForgotPassword = () => {
  const {
    submitForm,
    handleSubmit,
    control,
    register,
    errors,
    toggleModal,
    isModalOpen,
    handleBackToLogin
  } = useForgotPasswordController();

  return (
    <Background>
      <Box className="logoAndLoginContainer">
        <BoxLogo>
          <IconButton onClick={handleBackToLogin}>
            <img src={arrowIcon} alt="arrow" className="backIcon" />
          </IconButton>
          <Logo alt="Logo Hayzy by OR" className="logo" />
          <div />
        </BoxLogo>
        <Box>
          <Typography variant="h4" fontWeight="600">
            Recuperar senha
          </Typography>
        </Box>
        <Typography className="subtitle" variant="body1" fontWeight="400" color="text.secondary">
          Enviaremos um link de recuperação da senha para o e-mail fornecido abaixo.
        </Typography>
        <FormControl className="loginContainer">
          <Controller
            name="email"
            control={control}
            render={() => (
              <Box className="inputErrorContainer">
                <CustomInput
                  register={{ ...register('email') }}
                  error={errors.email}
                  label="E-mail"
                  type="text"
                />
                {errors.email && !errors.password && (
                  <Box className="errorMessage">{errors.email.message}</Box>
                )}
              </Box>
            )}
          />
          <CustomButton text="Enviar link" onClick={handleSubmit(submitForm)} />
        </FormControl>
      </Box>

      <Modal open={isModalOpen} onClose={handleBackToLogin}>
        <ModalContent>
          <ModalCloseButton
            style={{
              alignSelf: 'flex-end',
              left: 30
            }}
            onClick={toggleModal}>
            <img
              src={closeIcon}
              alt="check"
              style={{
                width: 16
              }}
            />
          </ModalCloseButton>
          <img
            src={checkIcon}
            alt="check"
            style={{
              width: 50,
              alignSelf: 'center',
              marginBottom: 14
            }}
          />

          <ModalTitle>Recuperação de senha</ModalTitle>

          <ModalSubtitle>
            Enviamos a você um e-mail com um link para <br /> concluir a redefinição de sua senha.
          </ModalSubtitle>

          <ModalBackButton
            variant="contained"
            style={{
              height: 44,
              width: 220,
              backgroundColor: '#662483',
              borderRadius: 14,
              marginTop: 20,
              alignSelf: 'center'
            }}
            onClick={handleBackToLogin}>
            <ModalBackButtonText>Retornar ao login</ModalBackButtonText>
          </ModalBackButton>
        </ModalContent>
      </Modal>
    </Background>
  );
};
