import create from 'zustand';
import { SidebarSubLinkModalProps } from './SidebarSubLink.props';

const initialState = {
  isOpenSidebarSubLinkModal: false,
  sidebarLinkName: null
};

const useSidebarSubLinkModalStore = create<SidebarSubLinkModalProps>((set, get) => ({
  ...initialState,

  handleSetSidebarLinkName: (sidebarLinkName) => {
    set({ sidebarLinkName });
  },

  getSidebarLinkName: () => {
    const { sidebarLinkName } = get();
    return sidebarLinkName;
  }
}));

export { useSidebarSubLinkModalStore };
