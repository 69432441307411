import { SplitScreen, HalfPageLogo } from '../../components';
import { FormNewPasswordSuccess } from './components/FormNewPasswordSuccess/FormNewPasswordSuccess';

import Background from './NewPasswordSuccess.style';

export const NewPasswordSuccess = () => {
  return (
    <Background>
      <SplitScreen
        Left={FormNewPasswordSuccess}
        Right={HalfPageLogo}
        leftWeight={0.5}
        rightWeight={0.5}
      />
    </Background>
  );
};
