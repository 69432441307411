/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Button, Grid, TextField, Typography, IconButton } from '@mui/material';
import { CustomFileInput } from './Components/CustomFileInput/CustomFileInput';
import { ActionModalDetails } from './Components/ActionModal/ActionModal';
import { DeleteModal, InfoModal } from '../../components';
import useOcurrencesDetailsController from './OcurrencesDetails.controller';
import Container from './OcurrencesDetails.style';
import PreviewImage from './Components/PreviewImage/PreviewImage';

const trashOcurrences = require('../../assets/images/trashOcurrences.svg');
const arrowBackIcon = require('../../assets/images/arrowBackIcon.svg');
const arrowrightIcon = require('../../assets/images/arrowRightOcurrence.svg');
const arrowdownIcon = require('../../assets/images/arrowDownOcurrences.svg');

export function OcurrencesDetails() {
  const {
    fileLink,
    name,
    description,
    createdAt,
    unit,
    block,
    local,
    isOpenInfoModal,
    isSuccessfully,
    text,
    isOpenImagePreview,
    isOpenDeleteModal,
    ocurrencesDeleteMessage,
    status,
    isAnswer,
    isOpenActionDetailModal,
    isOpenHistory,
    history,
    isBackToLastPage,
    isAbleToDelete,
    handleClickImage,
    backToOcurrencesPage,
    handleOpenHistory,
    openActionDetailModal,
    handleAnswer,
    setAnswerText,
    handleSetCloseAnswer,
    handleSetOpenAnswer,
    handleDeleteModalOpen,
    onSubmitDelete,
    setIsBackToLastPage,
    handleModalOpen
  } = useOcurrencesDetailsController();

  return (
    <Container>
      <Grid container spacing={2} marginBottom={1}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5" fontWeight={500}>
            Ocorrências
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={1}>
          <Typography className="titleInline">
            Olá! Você está na tela de detalhes da ocorrência.
          </Typography>
        </Grid>
        {name.length > 0 && (
          <>
            <Grid item xs={12} marginBottom={1}>
              <Box className="ocurrencesDetailAndOptions">
                <Typography variant="h5" component="h5" fontWeight={500}>
                  {name}
                </Typography>
                <Box className="optionsContainer">
                  <Button
                    className="actionButton"
                    onClick={openActionDetailModal}
                    disabled={status === 'Concluído'}>
                    <Typography>{status}</Typography>
                    <arrowBackIcon.ReactComponent />
                  </Button>
                  {isOpenActionDetailModal && (
                    <ActionModalDetails setIsBackToLastPage={setIsBackToLastPage} />
                  )}
                  <trashOcurrences.ReactComponent onClick={handleDeleteModalOpen} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} marginBottom={1}>
              <Box className="ocurrencesDetails">
                <Box className="blockAndUnitContainer">
                  <Typography className="descriptionText">por</Typography>
                  <Typography className="blockAndUnit">
                    Bloco {block}, {unit}
                  </Typography>
                </Box>
                <Box className="divisor" />
                <Typography className="descriptionText">
                  Ocorrência feita em: {createdAt}
                </Typography>
                <Box className="divisor" />
                <Typography className="descriptionText">{local}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} marginBottom={1}>
              <Box>
                <Typography className="description">{description}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} marginBottom={1}>
              <Box className="imagesDetailsText">
                <Typography className="totalImagesText">
                  {isAbleToDelete ? 'sem anexos' : `${fileLink.length} anexos` || 'sem anexos'}
                </Typography>
                <Box className="divisor" />
                <Typography className="descriptionText">Anexos enviados pelo morador</Typography>
              </Box>
            </Grid>
            {!isAbleToDelete && (
              <Grid item xs={12} sm={12} md={12}>
                <CustomFileInput
                  handleClickOnImage={handleClickImage}
                  type="image"
                  link={fileLink}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
              {history!.length > 0 && (
                <Box className="historyBar" onClick={handleOpenHistory}>
                  {isOpenHistory ? (
                    <arrowdownIcon.ReactComponent />
                  ) : (
                    <arrowrightIcon.ReactComponent />
                  )}
                  <Typography className="historyText">Histórico</Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {isOpenHistory && (
                <>
                  {history &&
                    history.map((hist) => (
                      <Box className="historyDescription" key={`${hist.name}-${hist.updatedAt}`}>
                        <Box className="historyDetails">
                          {hist.sender === 'reabertura' ? (
                            <Typography className="historyType">Reabertura</Typography>
                          ) : (
                            <Box className="senderNameContainer">
                              <IconButton className="icon-container">
                                <Typography className="userInitialLetter">
                                  {`${
                                    hist.sender.split(' ').length > 1
                                      ? `${hist.sender.split(' ')[0][0]} ${
                                          hist.sender.split(' ')[1][0]
                                        }`
                                      : `${hist.sender.split(' ')[0][0]}`
                                  }`}
                                </Typography>
                              </IconButton>
                              <Typography className="senderName">{hist.sender}</Typography>
                            </Box>
                          )}
                          <Typography className="historyDate">{hist.updatedAt}</Typography>
                        </Box>
                        <Typography>{hist.name}</Typography>
                      </Box>
                    ))}
                </>
              )}
            </Grid>
            {!isAnswer ? (
              <Grid item xs={12} sm={12} md={12}>
                <Box className="answerContainer">
                  <Button className="answer" onClick={handleSetOpenAnswer}>
                    <Typography>Responder</Typography>
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <Box className="answerDescriptionContainer">
                  <TextField
                    placeholder="Digite aqui"
                    className="descriptionInput"
                    multiline
                    rows={8}
                    onChange={(e) => setAnswerText(e.target.value)}
                  />
                  <Box className="answerContainerButtons">
                    <Button className="submit" onClick={handleAnswer}>
                      <Typography>Enviar</Typography>
                    </Button>
                    <Button className="cancel" onClick={handleSetCloseAnswer}>
                      <Typography>Cancelar</Typography>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>

      {isOpenDeleteModal && (
        <DeleteModal
          title={ocurrencesDeleteMessage.title}
          onClickDelete={onSubmitDelete}
          handleModalClose={handleDeleteModalOpen}
          isInfoModalOpen
        />
      )}

      {isOpenInfoModal && (
        <InfoModal
          onClick={handleModalOpen}
          text={text}
          isSuccessfully={isSuccessfully}
          backToLastPage={backToOcurrencesPage}
          isBackToLastPage={isBackToLastPage}
        />
      )}

      {isOpenImagePreview && <PreviewImage />}
    </Container>
  );
}
