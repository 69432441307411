import { Typography } from '@mui/material';
import { ShowErrorsMessageProps } from './ShowErrorsMessage.props';

export function ShowErrorsMessage(props: ShowErrorsMessageProps) {
  const { errors } = props;
  return (
    <Typography variant="h6" color="error">
      {errors &&
        Object.keys(errors).map((key, index) => {
          return (
            <Typography
              key={key}
              variant="body2"
              color="error"
              marginTop={1}
              display={index > 0 ? 'none' : 'block'}>
              {String(errors[key]?.message)}
            </Typography>
          );
        })}
    </Typography>
  );
}
