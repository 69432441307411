export const ocurrencesTableHeader = [
  'Assunto',
  'Bloco',
  'Unidade',
  'Localização',
  'Data da Ocorrência',
  'Status',
  'Ações'
];

export const ocurrencesDeleteMessage = {
  title: 'Tem certeza que deseja excluir esta ocorrência?'
};
