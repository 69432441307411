import { useEffect, useState } from 'react';
import { useEnterpriseStore, usePreviewMobileModalStore } from '../../store';

function useCustomPreviewInputController() {
  const { handleSetColors, colors } = useEnterpriseStore();
  const { isOpenMobilePreview } = usePreviewMobileModalStore();
  const [firstColor, setFirstColor] = useState('');
  const [secondColor, setSecondColor] = useState('');

  function setColors() {
    if (!colors) return;
    setFirstColor(colors.primary);
    setSecondColor(colors.secondary);
  }

  useEffect(() => {
    setColors();
  }, [isOpenMobilePreview, firstColor, secondColor]);

  function validateAndSetColors(newColor: string, color: string) {
    let validatedColor = '';
    validatedColor = newColor === color ? '' : color;
    validatedColor = validatedColor[0] === '#' ? color : `#${color}`;
    return validatedColor;
  }

  function handleSetFirstColor(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const newFirstColor = { ...colors };
    newFirstColor.primary = validateAndSetColors(newFirstColor.primary, e.target.value);

    if (newFirstColor.primary.length > 7) return;

    setFirstColor(target.value);
    handleSetColors(newFirstColor);
  }

  function handleSetSecondColor(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const newSecondColor = { ...colors };
    newSecondColor.secondary = validateAndSetColors(newSecondColor.secondary, e.target.value);

    if (newSecondColor.secondary.length > 7) return;

    setSecondColor(target.value);
    handleSetColors(newSecondColor);
  }

  return {
    firstColor,
    secondColor,
    handleSetFirstColor,
    handleSetSecondColor,
    colors
  };
}

export default useCustomPreviewInputController;
