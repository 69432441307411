import create from 'zustand';
import {
  createRole,
  deleteRole,
  editRoles,
  getRolesData,
  searchRoles,
  sortRolesData
} from '../../services/requests/roles/Roles.request';

import { RolesModalProps } from './Roles.props';
import { IRoleByIdAllDataDtoOutput, IRoleDtoOutput } from '../../services/requests/roles/Roles.dto';

const initialState = {
  roles: [],
  id: '',
  rolesId: [],
  rolesList: [],
  isAdmin: false,
  isManager: false,
  field: '',
  direction: 'ASC'
};

const useRolesModalStore = create<RolesModalProps>((set, get) => ({
  ...initialState,
  handleSetRoles: (roles) => set((state) => ({ ...state, roles })),

  handleSetJobsRoleId: (id) => set((state) => ({ ...state, id })),
  handleSetIsAdmin: (isAdmin) => set((state) => ({ ...state, isAdmin })),
  handleSetIsManager: (isManager) => set((state) => ({ ...state, isManager })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  handleSetJobsRolesId: (rolesId) => set((state) => ({ ...state, rolesId })),
  handleSetJobsRolesList: (rolesList) => {
    set((state) => ({ ...state, rolesList }));
  },

  createRoleRequest: async (roleData) => {
    try {
      const formatedRole = { name: roleData.name.trim(), permissions: roleData.permissions };
      await createRole(formatedRole);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getRolesRequest: async (id: string): Promise<IRoleDtoOutput> => {
    const data = await getRolesData(id);

    return data;
  },

  deleteRoleRequest: async (id) => {
    try {
      await deleteRole(id);
    } catch (error: any) {
      const { message } = error;
      throw new Error(message);
    }
  },

  editRoleRequest: async (rolesData) => {
    try {
      await editRoles(rolesData);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchRolesRequest: async (rolesData, sortByField, sortByDirection, page) => {
    try {
      const data = await searchRoles(rolesData, sortByField, sortByDirection, page);

      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllRolesSortDataRequest: async (
    page,
    sortByField,
    sortByDirection,
    searchString
  ): Promise<IRoleByIdAllDataDtoOutput> => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortRolesData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));

export { useRolesModalStore };
