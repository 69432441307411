/* eslint-disable consistent-return */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import startcase from 'lodash.startcase';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import {
  useBackToLastPageModalStore,
  useLoadModalStore,
  useRolesModalStore,
  usePaginationStore
} from '../../../../store';
import { useEmployeesStore } from '../../../../store/Employees/Employees.store';
import { FormRequiredFields } from '../../Employees.props';
import { getAllRoleData, getRolesData } from '../../../../services/requests/roles/Roles.request';
import { Errors } from '../../../../constants/Errors';

export const useModalController = (
  variant: string,
  edit: boolean,
  onComplete: () => void,
  toggleSuccessModalOpen: () => void,
  setTitle: (title: string) => void,
  setSuccess: (isSuccess: boolean) => void,
  toggleModal: () => void,
  updateListRequest: (page: number) => void
) => {
  const {
    registerEmployeesAdminRequest,
    registerEmployeesManagerRequest,
    editEmployeesAdminRequest,
    editEmployeesManagerRequest,
    getAdminDataRequest,
    getEmployeesDataRequest,
    id,
    isModalRegisterOpen,
    isModalEditOpen
  } = useEmployeesStore();

  const { handleBackToLastPageModalOpen } = useBackToLastPageModalStore();
  const { isAdmin } = useRolesModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { currentPage } = usePaginationStore();

  const [jobs, setJobs] = useState([{ name: 'Selecione a Função', id: '' }]);

  const [employeeById, setEmployeeById] = useState({
    id: '',
    name: '',
    cpf: '',
    phone: '',
    email: '',
    roleID: jobs[0].id || ''
  });

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
        'Nome inválido para funcionário, evite usar caracteres especiais ou números.'
      )
      .required('Por favor, preencher campo nome.'),
    phone: yup
      .string()
      .required('Insira o seu número')
      .matches(/^\(\d{2}\) \d{5}-\d{4}$/, 'Insira o número corretamente.'),
    email: yup
      .string()
      .test('must email', 'Digite um e-mail válido.', (value) => {
        if (String(value).split('.')[1] && String(value).split('.')[1]?.length <= 1) return false;
        return true;
      })
      .email('Digite um e-mail válido.')
      .required('Por favor, preencher campo e-mail.'),
    cpf: yup
      .string()
      .required('Insira o seu CPF')
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'Insira o seu CPF corretamente.'),
    roleID: isAdmin ? yup.string().notRequired() : yup.string().required('Selecione uma função')
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      cpf: '',
      roleID: jobs[0].id || ''
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  });

  const resetEmployeeData = () => {
    setValue('name', '');
    setValue('phone', '');
    setValue('email', '');
    setValue('cpf', '');
    setValue('roleID', '');
    setEmployeeById({
      id: '',
      name: '',
      cpf: '',
      phone: '',
      email: '',
      roleID: ''
    });
  };

  const getRolesDataById = async () => {
    if (isAdmin) return;
    try {
      const { data } = await getAllRoleData(1);
      if (data.data.length > 0) {
        const formatedData = [{ name: 'Selecione a Função', id: '' }, ...data.data];
        setJobs(formatedData);
      }
    } catch {
      throw new Error('Invalid Credentials');
    }
  };

  const getEmployeeDataFromRequest = async () => {
    if (!edit) return;
    toogleLoading();
    try {
      const { data } = isAdmin ? await getAdminDataRequest(id) : await getEmployeesDataRequest(id);
      const { data: roleData }: any = isAdmin ? '' : await getRolesData(data.roleID);
      getRolesDataById();
      setEmployeeById(data);
      setValue('cpf', data.cpf);
      setValue('email', data.email);
      setValue('name', data.name);
      setValue('phone', data.phone);
      setValue('roleID', roleData.data.id);
    } catch (error: any) {
      setTitle(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
    }
  };

  const onSubmitRegister = async () => {
    const { cpf, email, name, phone, roleID } = getValues();

    if (roleID === 'Selecione a Função') {
      return toast.error('Selecione uma função!');
    }

    if (isAdmin) {
      toogleLoading();
      try {
        await registerEmployeesAdminRequest(
          { cpf, email, name: startcase(name), phone, roleID },
          onComplete
        );
        setTitle('Administrador cadastrado com sucesso!');
        setSuccess(true);
        updateListRequest(currentPage);
      } catch (error: any) {
        setTitle(
          (Errors as any)({})[error.message] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        setSuccess(false);
        toggleSuccessModalOpen();
      } finally {
        toogleLoading();
      }
    } else {
      toogleLoading();
      try {
        await registerEmployeesManagerRequest(
          { cpf, email, name: startcase(name), phone, roleID },
          onComplete
        );
        setTitle('Funcionário cadastrado com sucesso!');
        setSuccess(true);
        updateListRequest(currentPage);
      } catch (error: any) {
        setTitle(
          (Errors as any)({})[error.message] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        setSuccess(false);
        toggleSuccessModalOpen();
      } finally {
        toogleLoading();
      }
    }
  };

  const onSubmitEdit = async () => {
    toogleLoading();
    try {
      const { cpf, email, name, phone, roleID } = getValues();
      if (isAdmin) {
        try {
          await editEmployeesAdminRequest(
            { id, cpf, email, name: startcase(name), phone, roleID },
            onComplete
          );
          setTitle('Administrador editado com sucesso!');
          setSuccess(true);
          updateListRequest(currentPage);
        } catch (error: any) {
          setTitle(
            (Errors as any)({})[error.message] ||
              'Um erro estranho ocorreu, tente novamente em alguns instantes.'
          );
          setSuccess(false);
          toggleSuccessModalOpen();
        }
      } else {
        try {
          await editEmployeesManagerRequest(
            { id, cpf, email, name: startcase(name), phone, roleID },
            onComplete
          );
          setTitle('Funcionário editado com sucesso!');
          setSuccess(true);
          updateListRequest(currentPage);
        } catch (error: any) {
          setTitle(
            (Errors as any)({})[error.message] ||
              'Um erro estranho ocorreu, tente novamente em alguns instantes.'
          );
          setSuccess(false);
          toggleSuccessModalOpen();
        }
      }
    } catch (error: any) {
      setTitle(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      setSuccess(false);
    } finally {
      toogleLoading();
    }
  };

  const submit = () => {
    if (variant === 'register') {
      return onSubmitRegister();
    }
    if (variant === 'edit') {
      return onSubmitEdit();
    }

    return null;
  };

  const closeDocumentModal = () => {
    const { cpf, email, name, phone, roleID } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    cpf || email || name || phone || roleID ? handleBackToLastPageModalOpen() : toggleModal();
  };

  useEffect(() => {
    if (!(isModalRegisterOpen || isModalEditOpen)) return;
    if (!isAdmin) getRolesDataById();
    resetEmployeeData();
    if (edit) getEmployeeDataFromRequest();
  }, [id, edit, isModalRegisterOpen, isModalEditOpen]);

  return {
    handleSubmit,
    register,
    reset,
    control,
    errors,
    submit,
    employeeById,
    jobs,
    closeDocumentModal,
    isAdmin
  };
};
