/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { usePartnersStore } from '../../../../store';
import { isInvalidFileName } from '../../../../helpers/fileNameValidator';

function useCustomFileInput(
  type: string,
  hasImageSizeValidation?: boolean,
  handleFile?: (file: File) => void,
  isTenSize?: boolean
) {
  const [fileName, setFileName] = useState('');
  const [link, setLink] = useState('');
  const [image, setImage] = useState<File>();
  const {
    imagePartners,
    imagePartnersURL,
    signedimagePartnersUrl,
    handleSetPlaceholderFile,
    setImagePartnersURL,
    setSignedImgUrl
  } = usePartnersStore();

  const handleRemoveFile = () => {
    setFileName('');
    setSignedImgUrl('');
    setImagePartnersURL('');
    handleSetPlaceholderFile(null);
  };

  function handleSetNameAndLink() {
    if (!hasImageSizeValidation) return;
    if (!imagePartners && !imagePartnersURL) return;

    if (type === 'image' && (imagePartners || imagePartnersURL)) {
      setFileName('imagePartners');
      setLink(imagePartners || imagePartnersURL);
    }
  }

  useEffect(() => {
    handleSetNameAndLink();
  }, []);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target;
    if (event.files) {
      let error = false;
      const CUSTOMURL = window.URL || window.webkitURL;

      if (event.files[0].name.length >= 50) {
        toast.error('O nome do arquivo precisa ter menos de 50 caracteres.');
        error = true;
      }

      if (
        type === 'image' &&
        event.files[0].type !== 'image/jpeg' &&
        event.files[0].type !== 'image/jpg' &&
        event.files[0].type !== 'image/png'
      ) {
        toast.error('É permitido apenas arquivos JPEG e PNG');
        error = true;
      }

      if (isTenSize && event.files[0].size > 10000000) {
        toast.error('A imagem precisa ter no máximo 10MB.');
        error = true;
      }

      if (isInvalidFileName(event.files[0].name)) return;

      handleSetPlaceholderFile(event.files[0]);
      const img = new Image();
      const imagePartnersUrl = CUSTOMURL.createObjectURL(event.files[0]);
      img.src = imagePartnersUrl;

      if (type !== 'pdf') {
        img.onload = function whatIsHeightAndWidth() {
          if (Number(img.sizes) > 10000000) {
            toast.error(`O tamanho da imagem ${img.name} excede 10MB`);
            handleRemoveFile();
            error = true;
          }
        };
      }

      if (error) return;

      const file = event.files[0];
      setImage(file);

      handleFile && handleFile(file);
      setFileName(event.files[0].name);
      handleSetPlaceholderFile(event.files[0]);
    }
  };

  return {
    fileName,
    handleChangeFile,
    handleRemoveFile,
    signedimagePartnersUrl,
    link,
    image
  };
}

export default useCustomFileInput;
