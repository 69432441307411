import { useEffect, useState } from 'react';
import {
  useSidebarModalStore,
  useProfileModalStore,
  useAdminORStore,
  useNotificationStore,
  useRolesModalStore
} from '../../../../store/index';

function useHeaderController() {
  const [widthState, setWidthState] = useState(window.innerWidth);
  const [userInitialLetters, setUserInitialLetters] = useState('');

  const { open, handleDrawerOpen } = useSidebarModalStore();
  const { isAdmin } = useRolesModalStore();
  const { name } = useAdminORStore();
  const { handleProfileModalOpen, isOpenProfileModal } = useProfileModalStore();
  const {
    handleNotificationModalOpen,
    isOpenNotificationModal,
    getAllNotificationsRequest,
    handleSetNotificationsList,
    notificationsList,
    haveNewNotification,
    setHasNextPage,
    setHasPrevPage,
    setNextPage,
    setPrevPage,
    setHaveNewNotification,
    markAsViewedNotificationsRequest
  } = useNotificationStore();

  function formatName() {
    const initialLetters = name.match(/[A-Z]/gm)?.join('');
    setUserInitialLetters(String(initialLetters));
  }

  function checkIfThereIsAnyNewNotification(notifications: any[]) {
    return notifications?.some((notification) => notification.newNotification === true);
  }

  async function getAllNotificationsData() {
    if (isAdmin) return;
    const data = await getAllNotificationsRequest();
    handleSetNotificationsList(data.data || []);
    setHaveNewNotification(checkIfThereIsAnyNewNotification(data.data));
  }

  async function openNotificationsModal() {
    handleNotificationModalOpen(true);
    await markAsViewedNotificationsRequest();
    setHaveNewNotification(false);
  }

  useEffect(() => {
    formatName();
  }, [name]);

  useEffect(() => {
    if (isAdmin) return;
    getAllNotificationsData();
  }, []);

  window.addEventListener('resize', function () {
    setWidthState(window.innerWidth);
  });
  return {
    open,
    isAdmin,
    widthState,
    isOpenProfileModal,
    userInitialLetters,
    notificationsList,
    haveNewNotification,
    handleDrawerOpen,
    handleProfileModalOpen,
    openNotificationsModal,
    isOpenNotificationModal
  };
}

export default useHeaderController;
