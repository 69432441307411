import Background from './HalfPageLogo.style';

export function HalfPageLogo() {
  return (
    <Background>
      <img
        width="100%"
        height="100%"
        src="/loginrightside.png"
        className="logoImage"
        alt="OR empreendimentos logo"
      />
    </Background>
  );
}
