/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-return */

import { useEffect, useState } from 'react';
import { useInformativesStore, useLoadModalStore } from '../../../../store';

export const useDetailsModalController = () => {
  const [unitsChoicedData, setUnitsChoicedData] = useState<string[]>([]);
  const [blocksChoicedData, setBlocksChoicedData] = useState<string[]>([]);
  const [blockName, setBlockName] = useState<string>('');
  const [local, setLocal] = useState<string>('');

  function clearData() {
    setUnitsChoicedData([]);
    setBlocksChoicedData([]);
    setBlockName('');
    setLocal('');
  }

  const {
    handleSetUnitsChoicedoModalOpen,
    informativeId,
    unitsChoicedoModalOpen,
    getUnitsOfInformativeById
  } = useInformativesStore();
  const { toogleLoading, isOpenLoad } = useLoadModalStore();

  async function getAllData() {
    toogleLoading();
    try {
      const data = await getUnitsOfInformativeById(informativeId);
      data.block.length === 0 && data.unit.length === 0
        ? setLocal('enterprise')
        : setLocal('block');
      setBlocksChoicedData(data.block);
      setUnitsChoicedData(data.unit);
      setBlockName(data.blockName);
    } finally {
      toogleLoading();
    }
  }

  function closeModal() {
    handleSetUnitsChoicedoModalOpen(false);
    clearData();
  }

  useEffect(() => {
    if (!unitsChoicedoModalOpen) return;
    getAllData();
  }, [unitsChoicedoModalOpen]);

  return {
    unitsChoicedData,
    blockName,
    blocksChoicedData,
    local,
    isOpenLoad,
    closeModal
  };
};
