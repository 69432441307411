/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { CustomFileInput } from './Components/CustomFileInput/CustomFileInput';
import useEditMaintenanceController from './EditMaintenance.controller';
import Container from './EditMaintenance.style';
import { Option } from './EditMaintenance.props';
import { BackToLastPageModal, InfoModal } from '../../components';

const closeIcon = require('../../assets/images/close.svg');

export function EditMaintenance() {
  const {
    control,
    errors,
    maintenanceType,
    status,
    isAdmin,
    selectedStatus,
    typeOfMaintenance,
    isOpenInfoModal,
    isSuccessfully,
    text,
    isOpenBackToLastPageModal,
    sidebarLink,
    unitsList,
    isBackButtonClicked,
    enterprise,
    unit,
    blocksList,
    block,
    allEnterprise,
    selectedBlock,
    selectedUnit,
    fileLink,
    setSelectedStatus,
    register,
    backButtonToBackListMaintenance,
    removeUnity,
    editMaintenance,
    getUnityFromInput,
    handleSubmit,
    handleClickBlockButton,
    NavigateToListMaintenance,
    handleModalOpen,
    getUnitFromInput,
    handleClickEnterpriseButton
  } = useEditMaintenanceController();

  return (
    <Container>
      {typeOfMaintenance && (
        <Grid container spacing={2} marginBottom={1}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h5" fontWeight={500}>
              Editar Manutenções
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={1}>
            <Typography className="titleInline">
              Olá! Você está na tela de edição das manutenções
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={isAdmin ? 3.9 : 7.8}>
            <Typography className="inputLabel">Assunto</Typography>
            <Controller
              name="subject"
              control={control}
              render={() => (
                <TextField
                  id="maintenance_name"
                  className="valueInput"
                  placeholder="Insira o assunto"
                  {...register('subject')}
                  error={!!errors.subject}
                  sx={{ borderColor: '#A7A7A7', backgroundColor: '#E9E9E9', color: '#7D7D7D' }}
                />
              )}
            />
            <Typography className="errorMessage">{errors.subject?.message}</Typography>
          </Grid>

          {isAdmin && (
            <Grid item xs={12} sm={12} md={isAdmin ? 3.95 : 7.8}>
              <Typography className="inputLabel">Empreendimento</Typography>
              <Box className="selectInputLabelContainer">
                <InputLabel className="selectInputLabel">
                  {enterprise.length > 0 ? '' : 'Selecione'}
                </InputLabel>

                <Controller
                  name="enterprise"
                  control={control}
                  render={() => (
                    <TextField
                      id="selectEnterprise"
                      className="selectInput"
                      placeholder="00:00"
                      defaultValue={enterprise}
                      {...register('enterprise')}
                      error={!!errors.enterprise}
                      disabled
                      select
                      sx={{ borderColor: '#A7A7A7', backgroundColor: '#E9E9E9', color: '#7D7D7D' }}>
                      {allEnterprise &&
                        allEnterprise.map((option: Option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Box>
              <Typography className="errorMessage">{errors.enterprise?.message}</Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={4}>
            <Typography className="inputLabel">Status</Typography>
            <Box className="selectInputLabelContainer">
              <InputLabel className="selectInputLabel">
                {selectedStatus.length > 0 ? '' : 'Selecione'}
              </InputLabel>

              <InputLabel className="selectInputLabel">
                {selectedStatus === 'Late' || selectedStatus === 'Filed' ? 'Atrasada' : ''}
              </InputLabel>

              <Controller
                name="status"
                control={control}
                render={() => (
                  <TextField
                    id="selectStatus"
                    className="selectInput"
                    placeholder="00:00"
                    defaultValue={selectedStatus}
                    {...register('status')}
                    onChange={(e: any) => setSelectedStatus(e.target.value)}
                    error={!!errors.status}
                    select
                    sx={{ borderColor: '#A7A7A7', backgroundColor: '#E9E9E9', color: '#7D7D7D' }}>
                    {status &&
                      status.map((option: Option) => (
                        <MenuItem key={option.value} value={option.value} id={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            </Box>
            <Typography className="errorMessage">{errors.status?.message}</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <Typography className="inputLabel">Tipo de Manutenção</Typography>
            <Box className="selectInputLabelContainer">
              <InputLabel className="selectInputLabel">
                {typeOfMaintenance.length > 0 ? '' : 'Selecione'}
              </InputLabel>

              <Controller
                name="typeOfMaintenance"
                control={control}
                render={() => (
                  <TextField
                    id="selectMaintenance"
                    className="selectInput"
                    placeholder="Selecione"
                    defaultValue={typeOfMaintenance}
                    {...register('typeOfMaintenance')}
                    error={!!errors.typeOfMaintenance}
                    select
                    sx={{
                      borderColor: '#A7A7A7',
                      backgroundColor: '#E9E9E9',
                      color: '#7D7D7D'
                    }}>
                    {maintenanceType &&
                      maintenanceType.map((option: Option) => (
                        <MenuItem key={option.value} value={option.value} id={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            </Box>
            <Typography className="errorMessage">{errors.typeOfMaintenance?.message}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3.4}>
            <Typography className="inputLabel">Data</Typography>
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, value: uhu } }) => (
                <MaskedInput
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  onChange={onChange}
                  value={uhu}
                  guide={false}
                  render={(ref, props) => (
                    <TextField
                      id="maintenance_date"
                      className="valueInput deadlineInput"
                      placeholder="dd/mm/aaaa"
                      {...register('date')}
                      error={!!errors.date}
                      inputRef={ref}
                      {...props}
                    />
                  )}
                />
              )}
            />
            <Typography className="errorMessage">{errors.date?.message}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3.4}>
            <Typography className="inputLabel">Horário</Typography>
            <Controller
              name="time"
              control={control}
              render={({ field: { onChange, value: timeValue } }) => (
                <MaskedInput
                  mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                  onChange={onChange}
                  value={timeValue}
                  guide={false}
                  render={(ref, props) => (
                    <TextField
                      id="maintenance_time"
                      className="valueInput"
                      placeholder="00:00"
                      {...register('time')}
                      error={!!errors.time}
                      inputRef={ref}
                      {...props}
                    />
                  )}
                />
              )}
            />
            <Typography className="errorMessage">{errors.time?.message}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography className="inputLabel">Local de Manutenção</Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={4.8}>
            <FormControl fullWidth>
              <RadioGroup
                className="radioInputContainer"
                defaultValue={block.length === 0 && unit.length === 0 ? 'enterprise' : 'block'}>
                <FormControlLabel
                  value="Empreendimento"
                  control={
                    <Radio
                      id="maintenance_enterpriseButton"
                      onClick={handleClickEnterpriseButton}
                      value="enterprise"
                    />
                  }
                  label="Empreendimento"
                />
                <FormControlLabel
                  value="Bloco"
                  control={
                    <Radio
                      id="maintenance_blockButton"
                      onClick={handleClickBlockButton}
                      value="block"
                    />
                  }
                  label="Bloco"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {selectedBlock && (
            <>
              <Grid item xs={12} sm={12} md={3.5}>
                <Box className="blockUnity">
                  <Typography className="inputLabel">Bloco</Typography>
                  <Box className="selectInputLabelContainer">
                    <InputLabel className="selectInputLabel">
                      {block.length > 0 ? '' : 'Selecione'}
                    </InputLabel>

                    <Controller
                      name="block"
                      control={control}
                      render={() => (
                        <TextField
                          className="selectInput"
                          placeholder="00:00"
                          {...register('block')}
                          error={!!errors.block}
                          onChange={(e) => getUnityFromInput(e.target.value)}
                          select
                          defaultValue={block}
                          sx={{
                            borderColor: '#A7A7A7',
                            backgroundColor: '#E9E9E9',
                            color: '#7D7D7D'
                          }}>
                          {blocksList &&
                            blocksList.map((option: Option) => (
                              <MenuItem key={option.value} value={option.value} id={option.label}>
                                {option.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    />
                  </Box>
                </Box>
                <Typography className="errorMessage">{errors.block?.message}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3.5}>
                <Box className="blockUnity">
                  <Typography className="inputLabel">Unidade</Typography>
                  <Box className="selectInputLabelContainer">
                    {selectedUnit.value.length > 0 ||
                      (unit.length > 0 && (
                        <Button className="closeIconContainer" onClick={removeUnity}>
                          <closeIcon.ReactComponent />
                        </Button>
                      ))}
                    <InputLabel className="selectInputLabelUnit">
                      {unit.length > 0 || selectedUnit.value.length > 0 ? '' : 'Selecione'}
                    </InputLabel>

                    <Controller
                      name="unit"
                      control={control}
                      render={() => (
                        <TextField
                          className="selectInput"
                          placeholder="00:00"
                          {...register('unit')}
                          onChange={(e) => getUnitFromInput(e.target.value)}
                          error={!!errors.unit}
                          select
                          value={unit}
                          sx={{
                            borderColor: '#A7A7A7',
                            backgroundColor: '#E9E9E9',
                            color: '#7D7D7D'
                          }}>
                          {unitsList &&
                            unitsList.map((option: Option) => (
                              <MenuItem key={option.value} value={option.value} id={option.label}>
                                {option.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    />
                  </Box>
                </Box>
                <Typography className="errorMessage">{errors.unit?.message}</Typography>
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} md={12}>
            <Typography className="subTitle">Descrição</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="description"
              control={control}
              render={() => (
                <TextField
                  id="maintenance_description"
                  placeholder="Digite aqui"
                  className="descriptionInput"
                  multiline
                  rows={8}
                  {...register('description')}
                />
              )}
            />
            <Typography className="errorMessage">{errors.description?.message}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography className="subTitle">Arquivos</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <CustomFileInput accept=".jpg, .png" type="image" link={fileLink} />
            <Typography className="fileInputText">
              Arquivos permitidos (envio opcional): jpg, png.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box className="buttonContainer">
              <Box className="backAndSaveContainer">
                <Button
                  onClick={handleSubmit(editMaintenance)}
                  className="saveButton"
                  id="maintenance_edit">
                  Salvar
                </Button>
                <Button className="backButton" onClick={backButtonToBackListMaintenance}>
                  Voltar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      {isOpenInfoModal && (
        <InfoModal
          isSuccessfully={isSuccessfully}
          onClick={isSuccessfully ? NavigateToListMaintenance : handleModalOpen}
          text={text}
        />
      )}
      {isOpenBackToLastPageModal && (
        <BackToLastPageModal
          goToPage={isBackButtonClicked ? '/maintenance' : sidebarLink || '/maintenance'}
        />
      )}
    </Container>
  );
}
