import styled from 'styled-components';

export const Background = styled.div`
  display: flex;
  margin: 120px 24px 0px 20px;
  padding-right: 24px;
  padding-left: 24px;

  .registerEnterpriseButton {
    background: #662483;
    border-radius: 12px;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
  .containerButton {
    justify-content: space-between;
    display: flex;
    width: 100%;

    @media (max-width: 1100px) {
      flex-direction: column;
    }
  }

  .boxButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    @media (max-width: 1100px) {
      align-self: flex-start;
      margin-left: 20px;
      margin-top: 10px;
    }
  }

  .registerEnterpriseButton:hover {
    opacity: 0.95;
  }

  @media (max-width: 1100px) {
    .divider {
      display: none;
    }
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`;

export const ButtonContainer = styled.div`
  background: #662483;
  width: 100%;
  button {
    padding: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 0px;
    text-transform: none;
    min-width: 200px;
  }
  .custombutton {
    background: #662483;
    border-radius: 8px;
    color: #ffffff;
  }

  .custombutton:hover {
    background-color: #662483;
    opacity: 0.9;
  }

  .buttontext {
    margin-top: 3px;
  }
`;

export const ContainerButton = styled.div`
  button {
    padding: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 0px;
    text-transform: none;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custombutton {
    background: #662483;
    border-radius: 8px;
    color: #ffffff;
  }

  .custombutton:hover {
    background-color: #662483;
    opacity: 0.9;
  }

  .buttontext {
    margin-top: 3px;
  }
`;
