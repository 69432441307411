import { IFuncionalities } from '../../../../services/requests/dashboard/Dashboard.dto';

export const useTableData = () => {
  function formatData(data: IFuncionalities) {
    const {
      lostItem,
      area,
      receivable,
      documents,
      informative,
      maintenance,
      occurrence,
      partner,
      visitor,
      voting,
      delivery
    } = data;

    return [
      {
        title: 'achados e perdidos',
        data: [
          { title: 'itens cadastrados', amount: lostItem.lostItems },
          { title: 'achados e perdidos em aberto', amount: lostItem.lostItemOpen },
          { title: 'achados e perdidos concluído', amount: lostItem.lostItemClose }
        ]
      },

      {
        title: 'areas / reservas',
        data: [
          { title: 'areas cadastradas', amount: area.area },
          { title: 'reservas cadastradas', amount: area.reservation },
          { title: '', amount: '' }
        ]
      },

      {
        title: 'boletos',
        data: [
          { title: 'boletos cadastrados', amount: receivable.receivable },
          { title: '', amount: '' },
          { title: '', amount: '' }
        ]
      },

      {
        title: 'documentos',
        data: [
          { title: 'documentos cadastrados', amount: documents.file },
          { title: '', amount: '' },
          { title: '', amount: '' }
        ]
      },

      {
        title: 'encomendas',
        data: [
          { title: 'total geral de encomendas', amount: delivery.delivery },
          { title: 'total de encomendas', amount: delivery.deliveryOrders },
          { title: 'total de deliverys', amount: delivery.deliveryDeliveries }
        ]
      },

      {
        title: 'informativos',
        data: [
          { title: 'informativos cadastrados', amount: informative.info },
          { title: '', amount: '' },
          { title: '', amount: '' }
        ]
      },

      {
        title: 'manutenções',
        data: [
          { title: 'manutenções cadastradas', amount: maintenance.maintenance },
          { title: 'manutenções em aberto', amount: maintenance.maintenanceOpen },
          { title: 'manutenções concluídas', amount: maintenance.maintenanceClose }
        ]
      },

      {
        title: 'ocorrências',
        data: [
          { title: 'ocorrências cadastradas', amount: occurrence.occurrence },
          { title: 'ocorrências em aberto', amount: occurrence.occurrenceOpen },
          { title: 'ocorrências concluídas', amount: occurrence.occurrenceClose }
        ]
      },

      {
        title: 'parceiros',
        data: [
          { title: 'parceiros cadastrados', amount: partner.partner },
          { title: '', amount: '' },
          { title: '', amount: '' }
        ]
      },

      {
        title: 'visitantes',
        data: [
          { title: 'visitantes cadastrados', amount: visitor.visitor },
          { title: '', amount: '' },
          { title: '', amount: '' }
        ]
      },

      {
        title: 'votações',
        data: [
          { title: 'votações cadastradas', amount: voting.voting },
          { title: 'votações em aberto', amount: voting.votingOpen },
          { title: 'votações concluídas', amount: voting.votingClose }
        ]
      }
    ];
  }

  return {
    formatData
  };
};
