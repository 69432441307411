/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

export const useInProgressMaintenanceModal = (
  toogleModal: () => void,
  handleChangeStatus: (date: Date) => Promise<void>
) => {
  const schema = yup.object().shape({
    maintenance_date: yup.string().required('É necessário definir uma data para manutenção')
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: { maintenance_date: '' },
    resolver: yupResolver(schema)
  });

  const closeRenameModal = () => {
    toogleModal();
  };

  const submit = async () => {
    const { maintenance_date } = getValues();
    const biggerThan100Years = Number(maintenance_date.split('-')[0]) >= 2123;

    if (biggerThan100Years) {
      toast.error('Por favor, insira um ano válido.');
      return;
    }

    await handleChangeStatus(new Date(maintenance_date));
    closeRenameModal();
  };

  useEffect(() => {}, []);

  return {
    control,
    errors,
    handleSubmit,
    register,
    submit,
    closeRenameModal
  };
};
