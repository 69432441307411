import { Grid, Typography } from '@mui/material';
import Background from './Visitors.style';

import {
  CustomSearchInput,
  ActionModal,
  ConfirmModal,
  CustomTable,
  InfoModal
} from '../../components/index';

import useVisitorsController from './Visitors.controller';

export function Visitors() {
  const {
    actionModalData,
    tableHeaders,
    rowsId,
    totalPages,
    totalVisitors,
    handleDrawerOpen,
    changePage,
    handleSearch,
    handleSetEnterpriseId,
    visitors,
    handleModalOpen,
    isOpenInfoModal,
    text,
    isSuccessfully,
    isAuthorize,
    isRevoke,
    toggleConfirmAuthorize,
    toggleConfirmRevoke,
    onCompleteGrant,
    onCompleteRevoke,
    sortPage
  } = useVisitorsController();

  return (
    <Background>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Visitantes
          </Typography>
          <CustomSearchInput id="visitors_search" handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            rowsId={rowsId}
            tableHead={tableHeaders}
            tableData={visitors}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalVisitors}
            funcToChangePage={changePage}
            containId
            handleSetItemId={handleSetEnterpriseId}
            ActionModal={<ActionModal ActionModalData={actionModalData} ButtonsIcon={[]} />}
            sortFunc={sortPage}
            noFilesFoundText="Não há nenhum registro de visitantes no momento."
          />
        </Grid>
      </Grid>
      {isAuthorize && (
        <ConfirmModal
          title="Tem certeza que deseja autorizar este visitante?"
          onClickConfirm={() => onCompleteGrant()}
          handleModalClose={toggleConfirmAuthorize}
        />
      )}
      {isRevoke && (
        <ConfirmModal
          title="Tem certeza que deseja revogar este visitante?"
          onClickConfirm={() => onCompleteRevoke()}
          handleModalClose={toggleConfirmRevoke}
        />
      )}

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}
    </Background>
  );
}
