import { Grid, Typography } from '@mui/material';
import { Background, Subtitle } from './VotingResult.style';
import useVotingResultController from './VotingResult.controller';
import { ChartPie } from '../components/Chart/Pie';

import NoFilesFound from '../components/NoFilesFound/NoFilesFound';

export function VotingResult() {
  const { div2PDF, navigation, votingResultDetail, residents } = useVotingResultController();

  return (
    <Background>
      <Grid container spacing={3}>
        <Grid className="containerButton" sx={{ width: '100%' }} display="flex">
          <Grid item xs={12} sm={12} md={8} lg={8.5} sx={{ marginLeft: 3, marginTop: 2 }}>
            <Typography variant="h5" fontWeight={500} marginBottom={1}>
              Votaçōes
            </Typography>
            <Subtitle fontSize={16} fontWeight={400} marginBottom={5}>
              Olá! Você está na tela de resultados.
            </Subtitle>
          </Grid>
          <button type="submit" className="createVoteBtn" onClick={() => navigation('/vote')}>
            Voltar
          </button>
        </Grid>

        <Grid container xs={12}>
          {votingResultDetail.data && votingResultDetail.data.length > 0 ? (
            <div className="firection">
              {votingResultDetail.data.map((a: any, index: number) => (
                <div className="div2PDF">
                  <ChartPie
                    residents={residents}
                    div2PDF={() => div2PDF(index)}
                    votingDetails={a}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="firection">
              <NoFilesFound text="Não existem dados de votações disponíveis no momento!" />
            </div>
          )}
        </Grid>
      </Grid>
    </Background>
  );
}
