import { instance } from '../../api';
import {
  SearchBlock,
  IBlockDataDtoOutput,
  IBlockByIdDataDtoOutput,
  IBlockSimpleListOutput,
  BlockData
} from './Blocks.dto';

export const sortBlocksData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string,
  enterpriseID: string
): Promise<IBlockDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `block/getByEnterprise/${enterpriseID}?page=${page}&includeMeta=true&sortDirection=${
        sortByDirection || 'ASC'
      }&sortBy=${sortByField || 'block_model.name'}${searchString ? `&search=${searchString}` : ''}`
    );

    const formatedData = data.data;

    const totalBlocks = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalBlocks, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getSimpleBlockList = async (
  enterpriseID: string
): Promise<IBlockSimpleListOutput[]> => {
  try {
    const { data } = await instance.get(
      'block/manager/getAllBlocks?sortBy=block_model.name&limit=0'
    );

    const formatedData = data.data.map((block: BlockData) => ({
      value: block.id,
      label: block.name,
      units: block.units.map((unit: { id: string; name: string }) => ({
        value: unit.id,
        label: unit.name
      }))
    }));

    return formatedData;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getBlockByID = async (blockID: string): Promise<IBlockByIdDataDtoOutput> => {
  try {
    const { data } = await instance.get(`block/${blockID}`);
    return { data: { data: data.data } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const registerBlock = async (blocks: string[], enterpriseId: string): Promise<void> => {
  try {
    await instance.post(
      'block',
      { blocks, enterpriseId },
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editBlock = async (name: string, blockId: string): Promise<void> => {
  try {
    await instance.patch(`block/${blockId}`, { name });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteBlock = async (id: string): Promise<void> => {
  try {
    await instance.delete(`block/${id}`);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchBlocks = async (
  blocksData: SearchBlock,
  enterpriseID: string,
  sortByField: string,
  sortByDirection: string,
  page: string
): Promise<IBlockDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `block/getByEnterprise/${enterpriseID}?search=${blocksData.queryText}&includeMeta=true&sortBy=${sortByField}&sortDirection=${sortByDirection}&page=${page}`
    );

    const formatedData = data.data;

    const totalBlocks = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalBlocks, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getPdfToRequest = async (pdfData: FormData, id: string): Promise<any> => {
  try {
    const data = await instance.request({
      method: 'POST',
      url: `block/pdf/${id}`,
      baseURL: process.env.REACT_APP_STORAGE_URL,
      data: pdfData
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const removePdfToRequest = async (id: string): Promise<any> => {
  try {
    const data = await instance.request({
      method: 'DELETE',
      url: `block/delete/${id}`,
      baseURL: process.env.REACT_APP_STORAGE_URL
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
