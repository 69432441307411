export const formatStatus = (status: string) => {
  let statusFormated;
  switch (status) {
    case 'pendente':
      statusFormated = 'Pendente';
      break;
    case 'autorizado':
      statusFormated = 'Autorizado';
      break;
    case 'revogado':
      statusFormated = 'Revogado';
      break;
    default:
      break;
  }
  return statusFormated;
};

export const formatType = (type: string) => {
  let typeFormated;
  switch (type) {
    case 'amigo':
      typeFormated = 'Amigo';
      break;
    case 'familia':
      typeFormated = 'Família';
      break;
    case 'prestador_de_servico':
      typeFormated = 'Prestador de Serviço';
      break;
    case 'outro':
      typeFormated = 'Outro';
      break;
    default:
      break;
  }
  return typeFormated;
};
