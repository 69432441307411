import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useActionModalStore,
  useSortItemsStore,
  useDeleteModalStore,
  useLoadModalStore,
  useInfoModalStore,
  useBackToLastPageModalStore,
  usePaymentSlipStore,
  usePaginationStore
} from '../../store';
import { Errors } from '../../constants/Errors';

function usePaymentSlipController() {
  const navigate = useNavigate();

  const { isOpenBackToLastPageModal } = useBackToLastPageModalStore();
  const { handleSetSortFields } = useSortItemsStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const {
    isOpenInfoModal,
    handleModalOpen,
    handleSetIsSuccessfully,
    handleSetText,
    isSuccessfully,
    text
  } = useInfoModalStore();

  const [searchString, setSearchString] = useState('');

  const {
    deletePaymentSlipRequest,
    folderId,
    searchPaymentFolderRequest: searchPaymentSlipRequest,
    setFolderList,
    setFoldersIds,
    getAllPaymentFoldersRequest,
    setFolderId,
    setIsModalRegisterOpen,
    setIsModalEditOpen,
    folderList,
    foldersIds,
    field,
    direction,
    isModalRegisterOpen,
    isModalEditOpen,
    totalItems,
    setTotalItems,
    totalPages,
    setTotalPages
  } = usePaymentSlipStore();

  function saveFolderNameOnLocalStorage(foldList: any[]) {
    if (foldList.length === 0) return;
    const data = foldList.map((folder) => {
      return {
        id: folder.id,
        name: folder.name
      };
    });
    window.localStorage.setItem('fNames', JSON.stringify(data));
  }

  async function getAllData() {
    toogleLoading();
    try {
      const { data } = await getAllPaymentFoldersRequest(
        '1',
        field === 'name' ? 'unit_folder_model.name' : field,
        direction,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      if (data) {
        setFolderList(formatedData);
        saveFolderNameOnLocalStorage(formatedData);
        setFoldersIds(formatedRowsId);
        setTotalPages(data.totalPages || 1);
        setTotalItems(data.totalItems || 0);
      }
    } finally {
      toogleLoading();
    }
  }

  async function handleSearch(value: string) {
    setSearchString(value);
    const formatedDataForSearch = { queryText: value };
    const { data } = await searchPaymentSlipRequest(formatedDataForSearch, field, direction, '1');
    const formatedData = data.data;
    const formatedRowsId = data.data.map((item: { id: string }) => {
      return item.id;
    });

    if (data) {
      setFolderList(formatedData);
      setFoldersIds(formatedRowsId);
      setTotalPages(data.totalPages || 1);
      setTotalItems(data.totalItems || 0);
    }
  }

  async function handleDelete() {
    toogleLoading();
    try {
      await deletePaymentSlipRequest(folderId);
      handleSetText('Pasta excluída com sucesso!');
      handleSetIsSuccessfully(true);
      getAllData();
    } catch (err: any) {
      handleSetText(
        (Errors as any)({})[err.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
    } finally {
      toogleLoading();
      handleModalOpen();
    }
  }

  async function changePage(page: number) {
    toogleLoading();
    try {
      const { data } = await getAllPaymentFoldersRequest(
        `${page}`,
        field === 'unit_folder_model.name' ? field : 'unit_folder_model.name',
        direction,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      if (data) {
        setFolderList(formatedData);
        setFoldersIds(formatedRowsId);
        setTotalPages(data.totalPages || 1);
        setTotalItems(data.totalItems || 0);
      }
    } finally {
      toogleLoading();
    }
  }

  const actionModalData = [
    {
      ButtonsText: 'Renomear',
      OnClick: () => {
        setIsModalEditOpen(true);
        setIsModalRegisterOpen(true);
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        handleDeleteModalOpen();
        handleDrawerOpen();
      }
    }
  ];

  function handleCloseRegisterModal() {
    setIsModalRegisterOpen(false);
    setIsModalEditOpen(false);
  }

  function handleOpenRegisterModal() {
    setIsModalRegisterOpen(true);
  }

  function setSetSortFields() {
    handleSetSortFields(['unit_folder_model.name', 'unit_model.name', 'block_model.name', 'size']);
  }

  async function sortAllPaymentFolders(page: string, sortByField: string, sortByDirection: string) {
    toogleLoading();
    try {
      const { data } = await getAllPaymentFoldersRequest(
        page,
        sortByField,
        sortByDirection,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      if (data) {
        setFolderList(formatedData);
        setFoldersIds(formatedRowsId);
        setTotalPages(data.totalPages || 1);
        setTotalItems(data.totalItems || 0);
      }
    } catch (err: any) {
      handleSetText(
        (Errors as any)({})[err.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
    } finally {
      toogleLoading();
    }
  }

  function goToFilesPage(newId: string) {
    navigate(`/payments/${newId}`);
  }

  useEffect(() => {
    setSetSortFields();
    getAllData();
  }, []);

  useEffect(() => {
    if (isOpenInfoModal) return;
    handleSetIsSuccessfully(false);
    handleSetText('');
  }, [isOpenInfoModal]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    actionModalData,
    totalItems,
    totalPages,
    isOpenInfoModal,
    isOpenDeleteModal,
    isSuccessfully,
    text,
    isModalEditOpen,
    foldersIds,
    folderList,
    isModalRegisterOpen,
    isOpenBackToLastPageModal,
    goToFilesPage,
    sortAllPaymentFolders,
    handleDrawerOpen,
    handleModalOpen,
    handleCloseRegisterModal,
    handleOpenRegisterModal,
    changePage,
    setFolderId,
    handleDelete,
    getAllData,
    handleSearch,
    handleDeleteModalOpen
  };
}

export default usePaymentSlipController;
