import React, { useState } from 'react';

const useCollapsibleSetController = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return {
    toggleItem,
    openIndex
  };
};

export default useCollapsibleSetController;
