/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { Input, Modal as ModalComponent, Radio } from '@mui/material';

import MaskedInput from 'react-text-mask';
import { Controller } from 'react-hook-form';
import { useModalController } from './Modal.controller';

import { ModalProps } from './Modal.props';
import {
  ModalBackButton,
  ModalButtonArea,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalContent,
  ModalInputArea,
  ModalNextButton,
  ModalTitle,
  Container,
  ContainerTitleIcon,
  ModalInputTitle,
  ModalDescription,
  RadioArea,
  RadioInput,
  DateArea,
  DateLabel,
  DeleteRadioButton,
  RadioContainer,
  RadioAreaScroll,
  RadioFirstContainer,
  ModalInput
} from './Modal.style';

export function Modal({
  isModalOpen,
  toggleModal,
  title,
  variant,
  onComplete,
  toggleSuccessModalOpen,
  setTitle,
  setSuccess
}: ModalProps) {
  const {
    allQuestions,
    disableButton,
    votings,
    setVotings,
    editValue,
    handleEdit,
    setEditValue,
    setIndex,
    isIndex,
    handleEditChangeForIndex,
    handleDelete,
    values,
    setValues,
    handleRadioChange,
    setIsFocused,
    handleSubmit,
    control,
    errors,
    register,
    reset,
    sendRequest,
    closeVotingModal
  } = useModalController(
    variant,
    onComplete,
    toggleModal,
    isModalOpen,
    toggleSuccessModalOpen,
    setTitle,
    setSuccess
  );

  useEffect(() => {
    if (variant === 'criada') {
      reset();
    }
  }, [isModalOpen]);

  return (
    <ModalComponent open={isModalOpen} onClose={closeVotingModal}>
      <Container>
        <ModalContent
          onKeyDown={(event) => {
            if (event.key.includes('Enter')) event.preventDefault();
          }}>
          <ContainerTitleIcon>
            <ModalTitle>{variant === 'visualizada' ? 'Visualizar votação' : title}</ModalTitle>
            <ModalCloseButton onClick={closeVotingModal}>
              <ModalCloseButtonIcon />
            </ModalCloseButton>
          </ContainerTitleIcon>

          <ModalInputArea>
            <ModalInputTitle>Assunto</ModalInputTitle>
            <Controller
              name="subject"
              control={control}
              render={() => (
                <ModalInput
                  id="vote-input-subject"
                  style={{ width: '100%' }}
                  placeholder="Insira o assunto"
                  disabled={variant === 'visualizada'}
                  {...register('subject')}
                  error={!!errors.subject}
                />
              )}
            />
            {errors.subject && <p className="errorMessage">{errors.subject.message}</p>}
          </ModalInputArea>

          <ModalInputArea>
            <ModalInputTitle>Descrição</ModalInputTitle>
            <Controller
              name="description"
              control={control}
              render={() => (
                <ModalDescription
                  id="vote-input-description"
                  style={{ width: '100%' }}
                  placeholder="Insira a descrição"
                  {...register('description')}
                  disabled={variant === 'visualizada'}
                  error={!!errors.description}
                  inputProps={{
                    style: {
                      textTransform: 'capitalize'
                    }
                  }}
                />
              )}
            />

            {errors.description && <p className="errorMessage">{errors.description.message}</p>}
          </ModalInputArea>

          <ModalTitle>Perguntas de múltipla escolha</ModalTitle>
          <RadioAreaScroll>
            {allQuestions.map((item: string, index: number) => (
              <RadioContainer key={item}>
                <RadioArea>
                  <RadioFirstContainer>
                    <Radio
                      checked={votings === item}
                      onChange={() => setVotings(votings === item ? '' : item)}
                      value={allQuestions[index] === item ? editValue : item}
                      style={{
                        color: '#8B8B8B'
                      }}
                    />

                    <RadioInput
                      id={`vote-radio-${index}`}
                      onChange={(e) => handleEdit(e.target.value)}
                      disabled={variant === 'visualizada'}
                      onFocus={() => {
                        setIndex(index);
                        setEditValue(item);
                      }}
                      value={isIndex === index ? editValue : item}
                      onBlur={(e) => {
                        setIndex(1000);
                        handleEditChangeForIndex(index, e.target.value);
                        setEditValue('');
                      }}
                    />
                  </RadioFirstContainer>
                  {!(variant === 'visualizada') && (
                    <DeleteRadioButton onClick={() => handleDelete(index)}>
                      <ModalCloseButtonIcon />
                    </DeleteRadioButton>
                  )}
                </RadioArea>
              </RadioContainer>
            ))}

            <RadioArea>
              <Radio
                checked={false}
                style={{
                  color: '#8B8B8B'
                }}
              />
              <Controller
                name="options"
                control={control}
                render={() => (
                  <RadioInput
                    {...register('options')}
                    error={!!errors.options}
                    placeholder="Adicionar opção"
                    checked={false}
                    disabled={variant === 'visualizada'}
                    value={values}
                    onBlur={(e) => handleRadioChange(e.target.value)}
                    onChange={(e) => setValues(e.target.value)}
                  />
                )}
              />
            </RadioArea>
            {values.length < 1 && errors.options && (
              <p className="errorMessage">{errors.options.message}</p>
            )}

            {values.length > 1 && (
              <RadioArea>
                <Radio
                  checked={false}
                  style={{
                    color: '#8B8B8B'
                  }}
                />
                <RadioInput
                  placeholder="Adicionar opção"
                  onFocus={() => setIsFocused(true)}
                  value="Adicionar opção"
                  onChange={() => {}}
                />
              </RadioArea>
            )}
          </RadioAreaScroll>

          <DateArea>
            <DateLabel>Prazo</DateLabel>
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MaskedInput
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  placeholder="dd/mm/aaaa"
                  {...register('date')}
                  onChange={onChange}
                  value={value}
                  render={(ref, props) => (
                    <Input
                      id="vote-input-deadline"
                      disableUnderline
                      {...register('date')}
                      disabled={variant === 'visualizada'}
                      inputRef={ref}
                      {...props}
                      inputMode="numeric"
                      error={!!errors.date}
                      style={{ width: '100%', border: 'none' }}
                    />
                  )}
                />
              )}
            />

            {errors.date && <p className="errorMessage date">{errors.date.message}</p>}
          </DateArea>

          <ModalButtonArea>
            <ModalNextButton
              id="vote-save"
              style={
                variant === 'visualizada'
                  ? {
                      display: 'none'
                    }
                  : {
                      backgroundColor: '#662483',
                      color: 'white',
                      width: '100%',
                      height: 50,
                      fontFamily: 'Inter',
                      fontSize: 16,
                      fontWeight: 500,
                      textTransform: 'capitalize',
                      borderRadius: '8.71px'
                    }
              }
              variant="contained"
              disabled={variant === 'visualizada' || disableButton}
              disableElevation
              onClick={handleSubmit(sendRequest)}>
              Salvar
            </ModalNextButton>

            <ModalBackButton
              variant="contained"
              style={{
                backgroundColor: 'transparent',
                width: '100%',
                fontFamily: 'Inter',
                fontSize: 16,
                fontWeight: 500,
                textTransform: 'capitalize',
                marginTop: '16px'
              }}
              disableElevation
              onClick={closeVotingModal}>
              Voltar
            </ModalBackButton>
          </ModalButtonArea>
        </ModalContent>
      </Container>
    </ModalComponent>
  );
}
