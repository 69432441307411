import { useEffect } from 'react';
import { useLoadModalStore, useWelcomeScreenStore } from '../../store';

export const useWelcomeController = () => {
  const { toogleLoading } = useLoadModalStore();
  const { getOwnerManualRequest, ownerManualURL } = useWelcomeScreenStore();

  async function getOwnerManual() {
    toogleLoading();
    try {
      await getOwnerManualRequest();
    } finally {
      toogleLoading();
    }
  }

  useEffect(() => {
    getOwnerManual();
  }, []);

  return {
    ownerManualURL
  };
};
