import { Box, Grid, Typography } from '@mui/material';
import {
  CustomControlledInput,
  ShowErrorsMessage,
  MobilePreview,
  BackToLastPageModal,
  InfoModal
} from '../../components';
import Background from './EditEnterprise.style';
import useEditEnterpriseController from './EditEnterprise.controller';
import EnterpriseButtons from './components/EnterpriseButtons/EnterpriseButtons';
import Customization from './components/Customization/Customization';
import OwnerEnterpriseOptions from './components/OwnerEnterpriseOptions/OwnerEnterpriseOptions';

export function EditEnterprise() {
  const {
    register,
    handleSubmit,
    handleEditEnterprise,
    getStatus,
    handleSetEnterpriseName,
    NavigateToListEnterprises,
    handleModalOpen,
    control,
    status,
    choiceType,
    errors,
    enterpriseOfId,
    isOpenMobilePreview,
    isOpenBackToLastPageModal,
    enterpriseName,
    infoText,
    isOpenInfoModal,
    isSuccessfully
  } = useEditEnterpriseController();

  return (
    <Background>
      {enterpriseOfId && enterpriseOfId.name && (
        <Box>
          <Grid container spacing={0.8}>
            <Grid item xs={12} marginBottom={1}>
              <Typography variant="h5" component="h5" fontWeight={600}>
                Editar Empreendimento
              </Typography>
            </Grid>

            <Grid container spacing={2} marginBottom={2}>
              <Grid item xs={12} sm={4}>
                <CustomControlledInput
                  id="enterprise_incorporator"
                  control={control}
                  label="Incorporadora"
                  name="incorporator"
                  register={{ ...register('incorporator') }}
                  type="text"
                />
              </Grid>
              <Grid item xs={8} sm={4}>
                <CustomControlledInput
                  control={control}
                  isSelect
                  label="Status"
                  name="status"
                  register={{ ...register('status') }}
                  values={status}
                  value={status[getStatus(enterpriseOfId.status)].value}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <CustomControlledInput
                  id="enterprise_unit"
                  control={control}
                  label="Unidade"
                  name="unit"
                  register={{ ...register('unit') }}
                  type="number"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} marginBottom={2}>
              <Grid item xs={7} sm={8}>
                <CustomControlledInput
                  id="enterprise_name"
                  control={control}
                  label="Nome do Empreendimento"
                  name="name"
                  register={{ ...register('name') }}
                  type="text"
                />
              </Grid>
              <Grid item xs={5} sm={4}>
                <CustomControlledInput
                  control={control}
                  label="Tipo de Imóvel"
                  id="enterprise_type"
                  isSelect
                  name="propertyType"
                  register={{ ...register('propertyType') }}
                  type="text"
                  values={choiceType}
                  value={enterpriseOfId.propertyType}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} marginBottom={2}>
              <Grid item xs={12} sm={4.5}>
                <CustomControlledInput
                  id="enterprise_cep"
                  control={control}
                  isCep
                  label="CEP"
                  name="CEP"
                  register={{ ...register('CEP') }}
                  type="string"
                  field="cep"
                />
              </Grid>
              <Grid item xs={9} sm={5.5}>
                <CustomControlledInput
                  control={control}
                  label="Cidade"
                  name="city"
                  register={{ ...register('city') }}
                  type="text"
                />
              </Grid>
              <Grid item xs={3} sm={2}>
                <CustomControlledInput
                  control={control}
                  label="Estado"
                  name="state"
                  register={{ ...register('state') }}
                  type="text"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} marginBottom={2}>
              <Grid item xs={12} sm={3}>
                <CustomControlledInput
                  control={control}
                  label="Rua"
                  name="street"
                  register={{ ...register('street') }}
                  type="text"
                />
              </Grid>
              <Grid item xs={8} sm={3}>
                <CustomControlledInput
                  control={control}
                  label="Bairro"
                  name="district"
                  register={{ ...register('district') }}
                  type="text"
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <CustomControlledInput
                  id="enterprise_number"
                  control={control}
                  label="Número"
                  name="number"
                  register={{ ...register('number') }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomControlledInput
                  control={control}
                  id="enterprise_managerEmail"
                  label="Email do administrador"
                  name="adminEmail"
                  register={{ ...register('adminEmail') }}
                  type="text"
                />
              </Grid>
            </Grid>
          </Grid>

          <ShowErrorsMessage errors={errors} />

          <Box className="optionsBox">
            <Customization />
            <OwnerEnterpriseOptions />
          </Box>

          <EnterpriseButtons onClickRegister={handleSubmit(handleEditEnterprise)} />

          {isOpenMobilePreview && (
            <MobilePreview
              control={control}
              register={{ ...register('name') }}
              enterpriseName={enterpriseName}
              setEnterpriseName={handleSetEnterpriseName}
            />
          )}
          {isOpenBackToLastPageModal && <BackToLastPageModal goToPage="/enterprises" />}
          {isOpenInfoModal && enterpriseOfId.name && (
            <InfoModal
              isSuccessfully={isSuccessfully}
              onClick={isSuccessfully ? NavigateToListEnterprises : handleModalOpen}
              text={infoText}
            />
          )}
        </Box>
      )}
    </Background>
  );
}
