import styled from 'styled-components';

import { SplitScreenWeight } from './SplitScreen.props';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const Panel = styled.div`
  flex: ${(props: SplitScreenWeight) => props.weight};
  height: 100%;
`;

export default Container;
