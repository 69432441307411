import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  margin-bottom: -150px;
  margin-left: -47px;
  width: 228px;
  height: 84.67px;
  background: #ffffff;
  border: 1.11675px solid #dddddd;
  box-shadow: -2.2335px 4.46701px 4.46701px rgba(0, 0, 0, 0.11);
  border-radius: 13.401px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .optionContainer {
    width: 100%;
    height: 51.3%;
    cursor: pointer;
    padding: 8px 10px;

    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;
