/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Button, Typography } from '@mui/material';
import { Clear, Dehaze, Notifications } from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import useMobilePreviewController from './MobilePreview.controller';
import InfoCard from './components/InfoCard/InfoCard';
import TabsNavigator from './components/TabsNavigator/TabsNavigator';
import Background from './MobilePreview.style';
import Customization from './components/Customization/Customization';
import AreaCard from './components/AreaCard/AreaCard';
import { MobilePreviewProps } from './MobilePreview.props';
import ProfileCard from './components/ProfileCard/ProfileCard';

import hauzyLogo from '../../assets/images/hauzyLogoWhite.png';

export function MobilePreview(props: MobilePreviewProps) {
  const { handleMobilePreviewOpen, styleForColors, signedLogoUrl, logo } =
    useMobilePreviewController();
  const { control, register, setEnterpriseName } = props;
  return (
    <Background>
      <Box className="mobilePreviewBackground">
        <Button className="closeButton" disableRipple onClick={handleMobilePreviewOpen}>
          <Typography variant="h6" color="#fff">
            <Clear className="closeIcon" />
          </Typography>
        </Button>
        <Box className="appCustomization">
          <Box className="mobilePreviewContainer">
            <Box className="pageHeader" style={{ background: styleForColors(true) }}>
              <Box className="header">
                <Dehaze sx={{ fontSize: 20, color: '#fff' }} />
                <Box className="logo">
                  {logo || signedLogoUrl ? (
                    <img
                      src={logo || signedLogoUrl}
                      alt="logo do empreeendimento"
                      className="enterpriseLogo"
                    />
                  ) : (
                    <img src={hauzyLogo} width="100px" alt="logo" />
                  )}
                </Box>
                <Notifications sx={{ fontSize: 20, color: '#fff' }} />
              </Box>
              <ProfileCard styleForColors={styleForColors} />
              <Box className="InfoCards">
                <InfoCard />
                <InfoCard />
                <InfoCard />
              </Box>
            </Box>
            <AreaCard styleForColors={styleForColors} />
            <Box className="pageContent">
              <Box className="divisor">
                <p>HOJE</p>
              </Box>
              <Box className="notificationCard">
                <InfoOutlinedIcon
                  className="alert"
                  style={{ color: styleForColors(false) }}
                  sx={{ fontSize: 7, color: '#099AD7' }}
                />
                <p>
                  Uma <strong>ocorrência</strong> foi respondida.
                </p>
                <ArrowForwardIosIcon
                  style={{ color: styleForColors(false) }}
                  sx={{ fontSize: 7, color: '#444' }}
                />
              </Box>
              <Box className="pageBaseboard">
                <TabsNavigator />
              </Box>
            </Box>
          </Box>
          <Box className="customizationFieldsComponent">
            <Customization
              control={control}
              register={register}
              setEnterpriseName={setEnterpriseName}
            />
          </Box>
        </Box>
      </Box>
    </Background>
  );
}
