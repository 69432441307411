import { instance } from '../../api';
import { formatData } from '../../../helpers/formatData';
import {
  IOrdersByIdAllDataDtoOutput,
  ISearchOrdersDtoOutput,
  OrdersRequestData
} from './Orders.dto';

export const createOrder = async (formData: FormData): Promise<void> => {
  return instance.post('delivery/add-delivery', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    maxBodyLength: Infinity,
    maxContentLength: Infinity
  });
};

export const sortOrdersData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IOrdersByIdAllDataDtoOutput> => {
  try {
    const data = await instance.get(
      `delivery/list-all-deliveries?page=${page}&includeMeta=true&filterFields=id,receivedBy,block_model.name,unit_model.name,sender,recipient,status,createdAt&sortDirection=${sortByDirection}&sortBy=${sortByField}&search=${searchString}`
    );

    const orders = data.data.data;
    const formatedData = orders.map((order: OrdersRequestData) => {
      return {
        id: order.id,
        recipient: order.recipient,
        sender: order.sender,
        unit: order.unit.name,
        block: order.unit.block.name,
        created_at: formatData(order.createdAt),
        updated_at: formatData(order.updatedAt),
        status: order.status,
        receivedBy: order.receivedBy
      };
    });

    const totalOrders = data.data.metaData ? data.data.metaData.count : 0;
    const totalPages = data.data.metaData ? data.data.metaData.pages : 0;

    return { data: { data: formatedData, totalOrders, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
export const confirmOrderCode = async (
  id: string,
  code: string
): Promise<ISearchOrdersDtoOutput> => {
  return instance.put(`delivery/employee-confirms-delivery/${id}`, {
    code
  });
};

export const searchOrders = async (
  query: string,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IOrdersByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get('delivery/list-all-deliveries', {
      params: {
        includeMeta: true,
        search: query,
        page,
        sortBy: sortByField,
        sortDirection: sortByDirection
      }
    });

    const totalOrders = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    const formatedData = data.data.map((order: OrdersRequestData) => {
      return {
        id: order.id,
        recipient: order.recipient,
        sender: order.sender,
        unit: order.unit.name,
        block: order.unit.block.name,
        created_at: formatData(order.createdAt),
        updated_at: formatData(order.updatedAt),
        status: order.status,
        receivedBy: order.receivedBy
      };
    });

    return { data: { data: formatedData, totalOrders, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
