import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useLoadModalStore, useVisitorsStore } from '../../store';

const initialState = {
  name: '',
  document: '',
  phone: '',
  block: '',
  unity: '',
  visitType: '',
  startedAt: '',
  endedAt: '',
  status: ''
};

function useVisitorInfosController() {
  const [visitor, setVisitor] = useState(initialState);
  const { toogleLoading } = useLoadModalStore();

  const { getVisitorInfosByIdRequest } = useVisitorsStore();

  const { id } = useParams();

  const requestVisitorData = async (visitorId: string) => {
    toogleLoading();

    try {
      const { data } = await getVisitorInfosByIdRequest(visitorId);

      if (data) {
        setVisitor(data);
      }
    } finally {
      toogleLoading();
    }
  };

  useEffect(() => {
    if (id) {
      requestVisitorData(id);
    } else {
      setVisitor(initialState);
    }
  }, [id]);

  return { ...visitor };
}

export default useVisitorInfosController;
