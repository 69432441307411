import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useInfoModalStore, useLoadModalStore, useReserveStore } from '../../../../store';
import { IJustificationFormRequiredFields } from './JustificationModal.props';

export const useJustificationModalController = () => {
  const {
    handleJustificationModalOpen,
    isOpenJustificationModal,
    reserveId,
    changeStatusReserves,
    handleSetStatus
  } = useReserveStore();

  const { handleModalOpen, handleSetIsSuccessfully, handleSetText } = useInfoModalStore();

  const { toogleLoading } = useLoadModalStore();

  const justificationSchema = yup.object().shape({
    justification: yup.string().required('Por favor, preencher campo área')
  });

  const { handleSubmit, register, control, getValues, setValue } =
    useForm<IJustificationFormRequiredFields>({
      defaultValues: { justification: '' },
      mode: 'onChange',
      resolver: yupResolver(justificationSchema)
    });

  function handleCloseJustificationModal() {
    handleJustificationModalOpen();
  }

  async function submitJustification() {
    toogleLoading();
    try {
      const { justification } = getValues();
      await changeStatusReserves(reserveId, 'REVOKED', justification);
      handleSetStatus('Revogado');
      setValue('justification', '');
      handleJustificationModalOpen();
      handleSetIsSuccessfully(true);
      handleSetText('Reserva revogada com sucesso!');
    } catch {
      handleSetIsSuccessfully(false);
      handleSetText('Não foi possível revogar a reserva!');
    } finally {
      handleModalOpen();
      toogleLoading();
    }
  }

  useEffect(() => {
    setValue('justification', '');
  }, [isOpenJustificationModal]);

  return {
    handleCloseJustificationModal,
    submitJustification,
    handleJustificationModalOpen,
    handleSubmit,
    register,
    control,
    isOpenJustificationModal
  };
};
