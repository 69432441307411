import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { areaTableHead, areaDeleteMessage } from '../../constants';
import {
  useActionModalStore,
  useAreaStore,
  useSortItemsStore,
  useDeleteModalStore,
  useLoadModalStore,
  useInfoModalStore,
  useRefreshChangedPagesStore,
  usePaginationStore
} from '../../store';
import { Errors } from '../../constants/Errors';

function useAreasController() {
  const navigate = useNavigate();

  const { handleSetSortFields } = useSortItemsStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { isOpenInfoModal, handleModalOpen } = useInfoModalStore();
  const { setChangedPage, isPageChanged } = useRefreshChangedPagesStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();

  const [totalAreas, setTotalAreas] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isSuccessfully, setIsSuccessfully] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [searchString, setSearchString] = useState('');

  const {
    direction,
    field,
    areasId,
    areaId,
    areaList,
    handleSetAreaList,
    getAllSortAreasDataRequest,
    handleSetAreaId,
    handleSetAreasId,
    deleteAreaRequest,
    handleRemoveArea,
    searchAreasRequest
  } = useAreaStore();

  async function getAllData() {
    const data = await getAllSortAreasDataRequest('1', field, direction, searchString);
    const formatedRowsId = data.data.data.map((item) => item.id);
    handleSetAreasId(formatedRowsId);
    handleSetAreaList(data.data.data);
    setTotalPages(data.data.totalPages || 0);
    setTotalAreas(data.data.totalAreas || 0);
  }

  async function onSubmitDelete(id: string) {
    toogleLoading();
    try {
      await deleteAreaRequest(id);
      handleRemoveArea(id);
      setInfoText('Área excluída com sucesso!');
      setIsSuccessfully(true);
      handleModalOpen();
    } catch (error: any) {
      setInfoText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      setIsSuccessfully(false);
      handleModalOpen();
    } finally {
      getAllData();
      toogleLoading();
    }
  }

  const actionModalData = [
    {
      ButtonsText: 'Editar área',
      OnClick: () => {
        navigate(`/area/edit/${areaId}`);
      }
    },
    {
      ButtonsText: 'Ver horários e dias',
      OnClick: () => {
        navigate(`/area/times/${areaId}`);
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        handleDeleteModalOpen();
        handleDrawerOpen();
        setIsSuccessfully(true);
        setInfoText('Área excluída com sucesso');
      }
    }
  ];

  async function changePage(page: number) {
    toogleLoading();
    try {
      const { data } = await getAllSortAreasDataRequest(`${page}`, field, direction, searchString);

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });

      if (data) {
        handleSetAreaList(formatedData);
        handleSetAreasId(formatedRowsId);
      }
    } finally {
      toogleLoading();
    }
  }

  async function handleSearch(value: string) {
    setSearchString(value);
    const formatedDataForSearch = { queryText: value };
    const { data } = await searchAreasRequest(
      formatedDataForSearch,
      field,
      direction,
      String(currentPage)
    );
    const formatedData = data.data;
    const formatedRowsId = data.data.map((item) => {
      return item.id;
    });

    if (data) {
      handleSetAreaList(formatedData);
      handleSetAreasId(formatedRowsId);
      setTotalPages(data.totalPages || 1);
      setTotalAreas(data.totalAreas || 1);
    }
  }

  function setSetSortFields() {
    handleSetSortFields([
      'area_model.area_name',
      'area_model.max_capacity',
      'area_model.cancellation_deadline',
      'area_model.location_value_cents',
      'area_model.is_available'
    ]);
  }

  async function sortArea(page: string, sortByField: string, sortByDirection: string) {
    const data = await getAllSortAreasDataRequest(page, sortByField, sortByDirection, searchString);
    return data;
  }

  useEffect(() => {
    if (areaList.length > 0) return;
    if (isPageChanged) setChangedPage(false);
    getAllData();
    setSetSortFields();
  }, [isOpenInfoModal]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    actionModalData,
    areaTableHead,
    areaDeleteMessage,
    areaId,
    areasId,
    areaList,
    totalPages,
    totalAreas,
    isOpenInfoModal,
    isOpenDeleteModal,
    isSuccessfully,
    infoText,
    handleDrawerOpen,
    sortArea,
    handleSetAreaId,
    onSubmitDelete,
    handleModalOpen,
    changePage,
    handleSearch,
    handleDeleteModalOpen
  };
}

export default useAreasController;
