import { Checkbox } from '@mui/material';
import styled from 'styled-components';

export const RoleCheckboxArea = styled.div`
  margin-bottom: 30px;

  .checkboxText {
    span {
      font-family: 'Inter';
      font-size: 14px;
      line-height: 17px;
      color: #7d7d7d;
    }
  }
`;

export const RoleCheckbox = styled(Checkbox)``;
