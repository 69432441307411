/* eslint-disable @typescript-eslint/no-var-requires */
import { List, Typography, ListItem, ListItemButton, ListItemText, Box } from '@mui/material';
import getCurrentSidebarLink from '../../helpers/getCurrentSidebarLink';
import { ListIconsProps } from './ListIcons.props';
import hasTheFunctionality from '../../helpers/hasTheFunctionality';
import useListIconsController from './ListIcons.controller';

import Container from './ListIcons.style';

const sidebarlinkopen = require('../../assets/images/sidebarlinkopen.svg');
const sidebarlinkclose = require('../../assets/images/sidebarlinkclose.svg');

export function ListIcon(props: ListIconsProps) {
  const { image, iconsName, titleName, linkName, roles } = props;
  const {
    formatedIconsName,
    sidebarOpen,
    onClick,
    getSidebarLinkName,
    translateLinkName,
    moveToRouteSublink
  } = useListIconsController(iconsName);

  return (
    <Container>
      {formatedIconsName &&
        formatedIconsName.map((link, index) => (
          <List
            className={hasTheFunctionality(roles, image[index].name) ? '' : 'noHasFunc'}
            key={link.name}>
            {titleName && (
              <Typography variant="body2" color="gray" sx={{ marginLeft: '16px' }}>
                {titleName}
              </Typography>
            )}
            <ListItem
              disablePadding
              className={
                getCurrentSidebarLink(link.name)
                  ? 'selectedSidebarLink buttonsContainer'
                  : 'buttonsContainer'
              }>
              <ListItemButton
                id={linkName[index]}
                onClick={() => onClick(`${linkName[index]}`, link.name, link)}
                className="listLinks">
                <Box className="sidebarLink" id={linkName[index]}>
                  {image[index].component}
                  <ListItemText primary={link.name} />
                  {link.subLink.length > 1 &&
                    (sidebarOpen && `/${linkName[index]}` === window.location.pathname ? (
                      <sidebarlinkopen.ReactComponent />
                    ) : (
                      <sidebarlinkclose.ReactComponent />
                    ))}
                </Box>
              </ListItemButton>
              {getSidebarLinkName() === link.name &&
                link.subLink.length > 1 &&
                link.subLink.map(
                  (name: string) =>
                    name && (
                      <ListItemButton key={name} id={linkName[index]} className="listLinks">
                        <Box
                          id={name}
                          className="sidebarSubLink"
                          onClick={() =>
                            moveToRouteSublink(
                              `/${linkName[index]}/${translateLinkName(link.name)}`
                            )
                          }>
                          <ListItemText primary={name} />
                        </Box>
                      </ListItemButton>
                    )
                )}
            </ListItem>
          </List>
        ))}
    </Container>
  );
}
