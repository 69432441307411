import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin: 90px 24px 0px 20px;
  padding-right: 24px;
  padding-left: 24px;

  .ocurrencesDetailAndOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .optionsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;

    svg {
      cursor: pointer;
    }
  }

  .selectInput {
    flex: 1;
    width: 100%;
    background-color: transparent;

    input {
      height: 15px;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }
  }

  .historyBar {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 12px;
    gap: 11px;
    width: 100%;
    height: 48px;
    background: #f4f4ff;
    border-radius: 12px;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
      margin-top: 2px;
      margin-right: -3px;
    }

    :hover {
      opacity: 0.9;
    }
  }

  .historyText {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    color: #2c2c2c;
  }

  .historyDescription {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    text-align: justify;
    color: #2c2c2c;
    background: #f4f4ff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 11px;
    border-bottom: 1px solid #d9d9d9;
  }

  .historyDate {
    color: #8b8b8b;
  }

  .historyType {
    color: #8b8b8b;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
  }

  .historyDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .ocurrencesDetails {
    display: flex;
    gap: 15px;
  }

  .blockAndUnitContainer {
    display: flex;
    gap: 4px;
  }

  .senderNameContainer {
    display: flex;
    gap: 10px;
  }

  .icon-container {
    margin-bottom: 5px;
    background: #2c2c2c;
    border-radius: 3.10811px;

    .userInitialLetter {
      font-family: 'Inter';
      font-style: normal;
      font-size: 17px;
      line-height: 20px;
      font-weight: 700;
      color: #ffffff;
    }

    :hover {
      background: #2c2c2c;
    }
  }

  .senderName {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #2c2c2c;
  }

  .descriptionText {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #8b8b8b;
  }

  .blockAndUnit {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #1565d8;
  }

  .divisor {
    margin-top: 9px;
    width: 6px;
    height: 6px;
    background: #b3bac5;
    border-radius: 100%;
  }

  .description {
    font-family: 'Inter';
    font-size: 16px;
    line-height: 28px;
    text-align: justify;
    color: #2c2c2c;
    min-height: 320px;
  }

  .totalImagesText {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #2c2c2c;
  }

  .imagesDetailsText {
    display: flex;
    gap: 15px;
  }

  .answer {
    width: 200px;
    height: 50px;
    background: #f0f1f5;
    text-transform: none;
    border-radius: 8px;
  }

  .descriptionInput {
    border: 1px solid #8b8b8b;
    border-radius: 8px;
    width: 100%;
    position: relative;
  }

  .answerDescriptionContainer {
    position: relative;
  }

  .answerContainerButtons {
    position: absolute;
    bottom: 55px;
    left: 15px;
  }

  .submit {
    padding: 18px 0px;
    gap: 12px;
    position: absolute;
    width: 170px;
    height: 40px;
    background: #662483;
    border-radius: 37px;
    color: #fff;
    text-transform: none;

    :hover {
      background-color: #662483;
      color: #fff;
    }
  }

  .cancel {
    padding: 18px 0px;
    gap: 12px;

    position: absolute;
    width: 170px;
    height: 40px;
    background: #d9d9d9;
    border-radius: 37px;
    left: 185px;
    text-transform: none;

    :hover {
      background: #d9d9d9;
    }
  }

  .actionButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px 11px 12px;
    gap: 8px;
    width: 228px;
    height: 43px;
    border: 1px solid #8b8b8b;
    border-radius: 8px;
    color: #7d7d7d;
    text-transform: none;
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`;

export default Container;
