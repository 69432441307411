/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import {
  useActionModalStore,
  useInformativesStore,
  usePaginationStore,
  useSortItemsStore
} from '../../../../store';
import { useDocumentsStore } from '../../../../store/Documents/Documents.store';

export function useCustomTable(tableData: any[], rowsId: string[]) {
  const { isOpenActionModal, handleIsLastItemOfTable, handleDrawerOpen } = useActionModalStore();
  const {
    changeSortDownColor,
    changeSortUpColor,
    handleSetSortItemId,
    sortDown,
    sortUp,
    sortItemId,
    sortFields
  } = useSortItemsStore();
  const [indexOfRow, setIndexOfRow] = useState(-1);
  const [formatedTableData, setFormatedTableData] = useState(tableData);
  const [formateRowsId, setFormateRowsId] = useState(rowsId);
  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const { setIdFolder, setFolderName } = useDocumentsStore();

  const { handleSetUnitsChoicedoModalOpen } = useInformativesStore();

  function handleClickOnActionBtn(
    e: React.MouseEvent<HTMLElement>,
    func: () => void,
    index: number,
    handleSetItemId: (id: string) => void,
    data: any
  ) {
    index === 9 ? handleIsLastItemOfTable(true) : handleIsLastItemOfTable(false);

    const componentSelected = e.target as HTMLElement;

    if (!componentSelected.id) {
      handleSetItemId(componentSelected.parentElement?.id || '');
      setIndexOfRow(index);
      func();
      return;
    }

    setFolderName(data.name);
    setIdFolder(componentSelected.id);
    handleSetItemId(componentSelected.id);
    setIndexOfRow(index);
    func();
  }

  const handleClickSortButton = async (
    changeColor: () => void,
    id: string,
    sortFunc: (page: string, sortByField: string, sortByDirection: string) => any,
    sortDirection: string,
    position: number
  ) => {
    try {
      handleSetSortItemId(id);
      changeColor();
      const { data } = await sortFunc(String(currentPage), sortFields[position], sortDirection);

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      setFormateRowsId(formatedRowsId);
      setFormatedTableData(data.data);
    } catch {}
  };

  function closeActionModalOnClickAway() {
    if (isOpenActionModal) handleDrawerOpen();
  }

  function openUnitsChoicedModal(
    e: React.MouseEvent<HTMLElement>,
    handleSetItemId: (id: string) => void
  ) {
    handleSetUnitsChoicedoModalOpen(true);

    const componentSelected: any = e.target as HTMLElement;

    componentSelected.parentNode.nextElementSibling !== null
      ? handleSetItemId(componentSelected.parentNode.nextElementSibling.id)
      : handleSetItemId(componentSelected.parentNode.lastChild.id);
  }

  useEffect(() => {
    setFormateRowsId(rowsId);
    setFormatedTableData(tableData);
  }, [tableData, rowsId]);

  useEffect(() => {
    handleSetCurrentPage(1);
  }, [window.location.pathname]);

  return {
    isOpenActionModal,
    indexOfRow,
    handleClickOnActionBtn,
    sortItemId,
    sortDown,
    sortUp,
    formatedTableData,
    formateRowsId,
    changeSortDownColor,
    changeSortUpColor,
    openUnitsChoicedModal,
    handleClickSortButton,
    closeActionModalOnClickAway
  };
}
