import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { successColor, errorColor } from './assets/colors/toastColor';

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body{
    font-family: 'Public Sans', sans-serif !important;
  }

  html, body, #root {
    height: 100%;
  }

  body .Toastify .Toastify__toast-container .Toastify__toast--success {
    background: ${successColor};
  }

  body .Toastify .Toastify__toast-container .Toastify__toast--error {
    background: ${errorColor};
  }

`;

export default GlobalStyle;
