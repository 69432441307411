/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useInformativesStore } from '../../../../store';
import { isInvalidFileName } from '../../../../helpers/fileNameValidator';

function useCustomFileInput() {
  const [files, setFiles]: any = useState([{ src: '', name: '' }]);
  const [isPdf, setIsPdf]: any = useState([]);

  const {
    handleSetImagesToRequest,
    formatedImageToRequest,
    images,
    imageType,
    imageName,
    imageSize,
    handleSetImages,
    setImageName,
    setImageType,
    setImageSize,
    filesToGetLink,
    handleSetFilesToGetLink
  } = useInformativesStore();

  function fillContainerWithInput() {
    const fileInput = document.getElementById('fileInputSelected');
    const fileInputContainer = document.getElementById('fileInputSelectedContainer');
    // @ts-ignore
    fileInputContainer.onclick = () => {
      fileInput?.click();
    };
  }

  const handleRemoveFile = async (position: number, name: string) => {
    const newFiles = [...files];
    const newImages = [...images];
    const newIsPdf = isPdf.filter((_: any, index: number) => index !== position);
    const newImageName = imageName.filter((_: any, index: number) => index !== position);
    const newImageSize = imageSize.filter((_: any, index: number) => index !== position);

    const formatedFiles = newFiles.filter((file: { name: string }) => file.name !== name);
    const formatedImages = newImages.filter((file: { name: string }) => file.name !== name);

    setFiles(formatedFiles);
    handleSetImages(formatedImages);

    const formatedName = name.replaceAll(' ', '').replaceAll('-', '').toLowerCase();

    if (filesToGetLink.length > 0) {
      handleSetFilesToGetLink(filesToGetLink.filter((file) => file.name !== name));
    }

    const newFormatedImagesToRequest = formatedImageToRequest.filter(
      (image) => !image.url.includes(formatedName)
    );

    setIsPdf(newIsPdf);
    setImageName(newImageName);
    setImageSize(newImageSize);
    handleSetImagesToRequest(newFormatedImagesToRequest);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target as any;

    setImageName([
      ...imageName,
      ...Array.prototype.slice.call(event.files).map((file) => file.name)
    ]);

    setImageSize([
      ...imageSize,
      ...Array.prototype.slice.call(event.files).map((file) => file.size)
    ]);

    if (event.files) {
      if (files.length > 10) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (event.files.length > 10) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (
        Array.prototype.slice.call(event.files).filter((file: any) => file.name.length >= 50)
          .length > 0
      ) {
        toast.error('O nome do arquivo precisa ter menos de 50 caracteres.');
        return;
      }

      if (isInvalidFileName(event.files[0].name)) return;

      const formatedImageType: any = [];
      const formatedIsPdf: any = [];
      Array.prototype.slice.call(event.files).forEach((file) => {
        if (file.type.includes('image')) {
          formatedImageType.push(String(file.type).split('image/')[1]);
          formatedIsPdf.push(false);
        } else {
          formatedImageType.push('pdf');
          formatedIsPdf.push(true);
        }
      });
      setImageType([...imageType, ...formatedImageType]);
      setIsPdf([...isPdf, ...formatedIsPdf]);
    }

    const newImages = [...images, ...event.files];

    handleSetImages(newImages);
    if (files[0] && files[0].src === '') files.pop();

    const filesImage = Array.from(event.files);
    filesImage.forEach((img: any) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          if (files.length + event.files.length > 10) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (files.length === 10) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (Number(img.size) > 30000000) {
            toast.error(`O tamanho da imagem ${img.name} excede 30MB`);
            return;
          }

          setFiles((prev: any) => [...prev, { src: reader.result, name: img.name }]);
          handleSetFilesToGetLink([...filesToGetLink, ...Array.prototype.slice.call(event.files)]);
        }
      };

      reader.readAsDataURL(img);
    });
  };

  return {
    handleChangeFile,
    files,
    isPdf,
    handleRemoveFile,
    fillContainerWithInput
  };
}

export default useCustomFileInput;
