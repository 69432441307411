import { useEnterpriseStore, usePreviewMobileModalStore } from '../../store/index';

function useMobilePreviewController() {
  const { handleMobilePreviewOpen } = usePreviewMobileModalStore();
  const { colors, logo, logoUrl, signedLogoUrl } = useEnterpriseStore();
  function styleForColors(primary?: boolean) {
    const style = colors.primary && primary ? `${colors.primary}` : `${colors.secondary}`;
    return style;
  }

  return {
    logo,
    logoUrl,
    signedLogoUrl,
    handleMobilePreviewOpen,
    styleForColors
  };
}

export default useMobilePreviewController;
