/* eslint-disable no-underscore-dangle */
import { instance } from '../../api';

import { IAllReservesDtoOutput, IReserveProps, SearchReserve } from './Reserves.dto';
import { formatData } from '../../../helpers/formatData';

function formatStatus(status: string) {
  let formatedStatus = '';
  switch (status) {
    case 'AUTHORIZED':
      formatedStatus = 'Autorizado';
      break;
    case 'CANCELED':
      formatedStatus = 'Cancelado';
      break;
    case 'EXPIRED':
      formatedStatus = 'Expirado';
      break;
    case 'PENDENT':
      formatedStatus = 'Pendente';
      break;
    case 'REVOKED':
      formatedStatus = 'Revogado';
      break;
    default:
      break;
  }

  return formatedStatus;
}

function formatStatusToRequest(status: string) {
  let formatedStatusToRequest = '';
  switch (status) {
    case 'AUTHORIZED':
      formatedStatusToRequest = 'authorize';
      break;
    case 'REVOKED':
      formatedStatusToRequest = 'revoke';
      break;
    default:
      break;
  }

  return formatedStatusToRequest;
}

export const sortReservesData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IAllReservesDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `reservation/manager/list?includeMeta=true&page=${page}&sortDirection=${sortByDirection}&sort=${sortByField}&search=${searchString}`,
      baseURL: process.env.REACT_APP_MS_AREA
    });

    const formatedData = data.data
      ? data.data.map((reserve: IReserveProps) => {
          return {
            id: reserve.id,
            resident_name: reserve.resident.name,
            unitId: reserve.resident.unit.name,
            block: reserve.resident.unit.block.name,
            reserve_date: formatData(reserve.date),
            usageHours: `${reserve.schedule.initial_time.slice(
              0,
              5
            )} - ${reserve.schedule.final_time.slice(0, 5)}`,
            created_at: formatData(reserve.created_at),
            area: reserve.area.area_name,
            status: formatStatus(reserve.status)
          };
        })
      : [];

    const totalReserves = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalReserves, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const findOneReserve = async (id: string): Promise<{ data: IReserveProps }> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `reservation/manager/details/${id}?includeMeta=true`,
      baseURL: process.env.REACT_APP_MS_AREA
    });

    const formatedData = data
      ? {
          id: data.id,
          user_name: data.resident.name,
          unitId: data.resident.unit.name,
          block: data.resident.unit.block.name,
          reserve_date: formatData(data.date),
          usageHours: `${data.schedule.initial_time.slice(0, 5)} - ${data.schedule.final_time.slice(
            0,
            5
          )}`,
          created_at: formatData(data.created_at),
          area: data.area.area_name,
          status: formatStatus(data.status),
          value:
            data.area.location_value_cents === 0 ? 'Gratuito' : data.area.location_value_cents / 100
        }
      : [];

    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: formatedData
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteReserve = async (id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'DELETE',
      url: `reservation/manager/delete/${id}`,
      baseURL: process.env.REACT_APP_MS_AREA
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const changeStatusReserve = async (
  id: string,
  status: string,
  justification: string
): Promise<void> => {
  try {
    const statusToChange = formatStatusToRequest(status);
    await instance.request({
      method: 'PATCH',
      url: `reservation/manager/${statusToChange}/${id}`,
      baseURL: process.env.REACT_APP_MS_AREA,
      data: {
        justification
      }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchReserve = async (
  reserveData: SearchReserve,
  sortByField: string,
  sortByDirection: string,
  page: string
): Promise<IAllReservesDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `reservation/manager/list?search=${reserveData.queryText}&includeMeta=true&page=${page}&sortDirection=${sortByDirection}&sort=${sortByField}`,
      baseURL: process.env.REACT_APP_MS_AREA
    });

    const formatedData = data.data
      ? data.data.map((reserve: IReserveProps) => {
          return {
            id: reserve.id,
            resident_name: reserve.resident.name,
            unitId: reserve.resident.unit.name,
            block: reserve.resident.unit.block.name,
            reserve_date: formatData(reserve.date),
            usageHours: `${reserve.schedule.initial_time.slice(
              0,
              5
            )} - ${reserve.schedule.final_time.slice(0, 5)}`,
            created_at: formatData(reserve.created_at),
            area: reserve.area.area_name,
            status: formatStatus(reserve.status)
          };
        })
      : [];

    const totalReserves = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalReserves, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
