import { useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFilesStore } from '../../../store/Files/Files.store';
import { FormRequiredFields } from '../DocumentsFiles.props';
import { useBackToLastPageModalStore, useLoadModalStore, useInfoModalStore } from '../../../store';

export const useModalController = (
  isModalOpen: boolean,
  onComplete: () => void,
  getAllFiles: () => void,
  errorModal: () => void,
  toggleModal: () => void,
  foldId: string
) => {
  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[ a-zA-ZÀ-ÿ0-9\u00f1\u00d1]*$/g,
        'Não é permitido usar caracteres especiais em nome de arquivo.'
      )
      .required('Por favor, preencher nome da pasta.')
      .trim()
  });

  const { handleModalOpen } = useInfoModalStore();

  const { handleBackToLastPageModalOpen } = useBackToLastPageModalStore();

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  });

  const { renameFileRequest, getFileName, idFile, getFileNameById } = useFilesStore();
  const { toogleLoading } = useLoadModalStore();

  const sendRequest = async () => {
    try {
      const { name } = getValues();

      await renameFileRequest(foldId, name, idFile, onComplete);
      getAllFiles();
      setValue('name', '');
      onComplete();
      toggleModal();
      handleModalOpen();
    } catch (error) {
      errorModal();
    }
  };

  async function getFolderData() {
    toogleLoading();
    try {
      const data = await getFileNameById(idFile);
      setValue('name', data.split('.')[0]);
    } finally {
      toogleLoading();
    }
  }

  useEffect(() => {
    const nameFolder = getFileName();
    setValue('name', nameFolder.split('.')[0]);
    getFolderData();
  }, [isModalOpen]);

  const closeDocumentModal = () => {
    const { name } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    name ? handleBackToLastPageModalOpen() : toggleModal();
  };

  return {
    register,
    control,
    errors,
    sendRequest,
    handleSubmit,
    closeDocumentModal
  };
};
