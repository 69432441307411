import styled, { css } from 'styled-components';
import { CloseRounded } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

export const ModalContent = styled.div`
  background: #fff;

  width: 499px;
  padding: 24px 0;

  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  justify-content: center;
  align-items: center;
  z-index: 999999999999;

  @media (max-width: 525px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0px;
  }
`;

export const ModalCloseButton = styled(IconButton)`
  @media (max-width: 525px) {
    display: none !important;
  }
`;

export const ModalCloseButtonIcon = styled(CloseRounded)``;

export const ModalTitle = styled.p`
  font-family: 'Inter';
  text-align: center;
  margin-top: 30px;

  font-size: 24px;
  font-weight: 600;
`;

export const ModalInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 74px;
  margin-top: 25px;

  img {
    width: 46px;
    height: 46px;
  }

  @media (max-width: 525px) {
    margin-left: 0px;
  }
`;

export const ModalInfoAreaRow = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 12px;
`;

export const ModalInfoTitle = styled.p`
  color: #2c2c2c;

  font-size: 18px;
  font-weight: 500;
  font-family: 'Inter';
`;

interface IModalInfoSubtitleProps {
  pendingStatus?: boolean;
}

export const ModalInfoSubtitle = styled.p<IModalInfoSubtitleProps>`
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Inter';

  ${({ pendingStatus }) =>
    pendingStatus &&
    css`
      background: #fff6a7;
      color: #c96000;
      padding: 6.5px 24px;
      font-size: 14px;
      border-radius: 6px;
    `}
`;

export const ModalButtonArea = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`;

export const ModalBackButton = styled(Button)``;
