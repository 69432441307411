import { instance } from '../../api';
import { ILpMessageData } from './LandingPage.dto';

export const saveMessageContact = async (lpMessageData: ILpMessageData): Promise<void> => {
  try {
    await instance.post('landingPage', { lpMessage: lpMessageData });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
