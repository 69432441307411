/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAreaStore, usePreviewImageModalStore } from '../../../../store';
import { isInvalidFileName } from '../../../../helpers/fileNameValidator';

function useCustomFileInput(type: string, imagesSrc: string[]) {
  const [files, setFiles]: any[] = useState([{ src: '', name: '' }]);

  const {
    locationImageToRequest,
    formatedImageToRequest,
    handleSetImagesToRequest,
    handleSetImagesToDelete,
    imagesToDelete,
    filesToGetLink,
    handleSetFilesToGetLink
  } = useAreaStore();

  function formateRequestGetImages() {
    const formatedFiles = imagesSrc.map((link: string) => {
      return {
        src: link,
        name: link.split('?')[0].split('area/')[1].slice(32)
      };
    });

    setFiles(formatedFiles);
  }

  const { handleSetImages, handleSetImage, handleSetLocationImagesToRequest, images } =
    useAreaStore();
  const { handleImagePreviewOpen } = usePreviewImageModalStore();

  const handleRemoveFile = async (position: number, name: string) => {
    let newFilesToRequest = [...locationImageToRequest];
    const newImages = [...images];
    const newFiles = [...files];

    newFilesToRequest.splice(position, 1);
    newFilesToRequest = newFilesToRequest.filter((file: string) => file.includes('https://'));

    const teste = newFiles.filter((file: { name: string }) => file.name !== name);
    const testeImagens = newImages.filter((file: { name: string }) => file.name !== name);

    setFiles(teste);
    handleSetImages(testeImagens);
    handleSetLocationImagesToRequest(newFilesToRequest);

    const formatedAllLinks = [
      ...imagesSrc.map((link) => link.split('area/')[1].split('?')[0]),
      ...formatedImageToRequest.map((file) => file.id)
    ];

    const formatedName = name.replaceAll(' ', '').replaceAll('-', '').toLowerCase();

    if (formatedAllLinks[position]) {
      handleSetImagesToDelete([
        ...imagesToDelete,
        ...formatedAllLinks.filter((link) => link.includes(formatedName))
      ]);
    }

    if (filesToGetLink.length > 0) {
      handleSetFilesToGetLink(filesToGetLink.filter((file) => file.name !== name));
    }

    if (formatedImageToRequest.length === 0) return;

    const id = formatedImageToRequest
      .filter((image) => image.url.includes(formatedName))[0]
      .url.split(`${process.env.REACT_APP_AWS}area/`);
    const newFormatedImagesToRequest = formatedImageToRequest.filter(
      (image) => !image.url.includes(formatedName)
    );

    handleSetImagesToRequest(newFormatedImagesToRequest);
    handleSetImagesToDelete([...imagesToDelete, id]);
  };

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target as any;

    if (event.files) {
      if (files.length > 10) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (event.files.length > 10) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (
        Array.prototype.slice
          .call(event.files)
          .filter((file: { name: string }) => file.name.length >= 50).length > 0
      ) {
        toast.error('O nome do arquivo precisa ter menos de 50 caracteres.');
        return;
      }

      if (
        type === 'image' &&
        Array.prototype.slice
          .call(event.files)
          .filter(
            (file: { type: string }) => file.type !== 'image/jpeg' && file.type !== 'image/png'
          ).length > 0
      ) {
        toast.error('É permitido apenas arquivos JPEG e PNG');
        return;
      }
      if (
        Array.prototype.slice
          .call(event.files)
          .filter((file: { name: string }) => isInvalidFileName(file.name)).length > 0
      ) {
        return;
      }
    }
    handleSetImages(event.files);

    const newImages = [...images, ...event.files];

    handleSetImages(newImages);

    if (files[0] && files[0].src === '') files.pop();

    const filesImage = Array.from(event.files);
    filesImage.forEach(async (img: any) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          if (files.length + event.files.length > 10) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (files.length === 10) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (Number(img.size) > 10000000) {
            toast.error(`O tamanho da imagem ${img.name} excede 10MB`);
            return;
          }

          if (files.filter((file: { name: string }) => file.name === img.name).length > 0) {
            toast.error(`Você já inseriu a imagem: ${img.name}`);
            return;
          }

          if (
            files.filter(
              (file: { name: string }) =>
                file.name === img.name.replaceAll(' ', '').replaceAll('-', '').toLowerCase()
            ).length > 0
          ) {
            toast.error(`Você já inseriu a imagem: ${img.name}`);
            return;
          }

          if (
            imagesSrc.length > 0 &&
            imagesSrc.filter((file: string) =>
              file.includes(img.name.toLowerCase().replace(' ', ''))
            ).length > 0
          ) {
            toast.error(`Você já inseriu a imagem: ${img.name}`);
            return;
          }

          setFiles((prev: any) => [...prev, { src: reader.result, name: img.name }]);
          handleSetFilesToGetLink([...filesToGetLink, ...Array.prototype.slice.call(event.files)]);
        }
      };
      reader.readAsDataURL(img);
    });
  };

  function getImageToPreview(file: any) {
    handleSetImage(file);
    handleImagePreviewOpen();
  }

  useEffect(() => {
    formateRequestGetImages();
  }, []);

  return {
    handleChangeFile,
    files,
    handleRemoveFile,
    getImageToPreview
  };
}

export default useCustomFileInput;
