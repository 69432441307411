import styled from 'styled-components';
import { CloseRounded } from '@mui/icons-material';
import { Button, IconButton, Select, TextField, Typography } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;

  .selectInput {
    width: 227px;
  }

  .selectInputLabelContainer {
    position: relative;
  }

  .selectInputLabel {
    position: absolute;
    top: 16px;
    left: 15px;
  }
`;

interface ModalProps {
  isDelivery: boolean;
}

export const ModalContent = styled.div<ModalProps>`
  background: #fff;

  display: flex;
  flex-direction: column;
  flex: 1;
  top: 50%;
  left: 50%;

  padding: 24px;
  border-radius: 16px;

  max-width: 526px;
`;

export const ModalTitle = styled.p`
  font-family: 'Inter';

  font-size: 24px;
  font-weight: 600;
`;

export const ContainerTitleIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const ModalInputTitle = styled(Typography).attrs({
  fontFamily: 'Inter',
  marginBottom: '8px'
})``;

export const ModalInput = styled(TextField).attrs({
  mode: 'outlined'
})`
  width: 28rem;
`;

export const ModalInputAreaRow = styled.div`
  display: flex;
`;

export const ModalInputArea = styled.div`
  margin-bottom: 24px;
  width: 100%;

  .inputMask {
    width: 28rem;
  }
`;

export const ModalSelectButton = styled(Select)`
  width: 28rem;
`;

export const ModalCloseButton = styled(IconButton)`
  align-self: flex-end;
`;

export const ModalCloseButtonIcon = styled(CloseRounded)``;

export const ModalButtonArea = styled.div<ModalProps>`
  margin-top: ${({ isDelivery }) => (isDelivery ? '0' : '32')}px;
`;

export const ModalNextButton = styled(Button)``;

export const ModalBackButton = styled(Button)``;
