/* eslint-disable @typescript-eslint/no-var-requires */
import { Box } from '@mui/material';
import OutsideClickHandler from 'react-outside-click-handler';
import useActionModalController from './ActionModal.controller';
import { Container } from './ActionModal.style';
import { IActionModalProps } from './ActionModal.props';

export function ActionModalDetails(props: IActionModalProps) {
  const { setIsBackToLastPage } = props;

  const { closeModal, handleSetInProgressOcurrence, handleSetFinishOcurrence } =
    useActionModalController(setIsBackToLastPage);

  return (
    <Container>
      <OutsideClickHandler onOutsideClick={closeModal}>
        <Box className="optionContainer" onClick={handleSetInProgressOcurrence}>
          Em Andamento
        </Box>
        <Box className="optionContainer" onClick={handleSetFinishOcurrence}>
          Concluído
        </Box>
      </OutsideClickHandler>
    </Container>
  );
}
