import { useState, useEffect } from 'react';

const useDebouncer = (value: string, timeout = 500) => {
  const [debouncerValues, setDebouncerValues] = useState('');

  useEffect(() => {
    const time = setTimeout(() => {
      setDebouncerValues(value);
    }, timeout);

    return () => {
      clearTimeout(time);
    };
  }, [value, timeout]);

  return debouncerValues;
};

export default useDebouncer;
