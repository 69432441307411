/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

function useCustomFileInput(type: string, link: any) {
  const [files, setFiles]: any = useState([{ src: link._url || '', name: link._name || '' }]);
  const [isPdf, setIsPdf] = useState(false);
  const [isImage, setIsImage] = useState<boolean[]>([]);

  function fillContainerWithInput() {
    const fileInput = document.getElementById('fileInputSelected');
    const fileInputContainer = document.getElementById('fileInputSelectedContainer');
    // @ts-ignore
    fileInputContainer.onclick = () => {
      fileInput?.click();
    };
  }

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target as any;

    if (event.files) {
      if (files.length > 1) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (event.files.length > 10) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (event.files[0].name.length >= 50) {
        toast.error('O nome do arquivo precisa ter menos de 50 caracteres.');
        return;
      }

      if (
        type === 'image' &&
        event.files[0].type !== 'image/jpeg' &&
        event.files[0].type !== 'image/png' &&
        event.files[0].type !== 'application/pdf'
      ) {
        toast.error('É permitido apenas arquivos JPEG, PNG e PDF.');
        return;
      }

      if (event.files[0].type === 'application/pdf') setIsPdf(true);
    }

    if (files[0] && files[0].src === '') files.pop();

    const filesImage = Array.from(event.files);
    filesImage.forEach((img: any) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          if (files.length + event.files.length > 1) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (files.length === 1) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (Number(img.size) > 10000000) {
            toast.error(`O tamanho da imagem ${img.name} excede 10MB`);
            return;
          }

          setFiles((prev: any) => [...prev, { src: reader.result, name: img.name }]);
        }
      };
      reader.readAsDataURL(img);
    });
  };

  function handleSetImage() {
    link.forEach((file: { name: string }) => {
      file.name.includes('jpg') || file.name.includes('jpeg') || file.name.includes('png')
        ? setIsImage((prev) => [...prev, true])
        : setIsImage((prev) => [...prev, false]);
    });
  }

  useEffect(() => {
    handleSetImage();
  }, []);

  return {
    handleChangeFile,
    files,
    isImage,
    isPdf,
    fillContainerWithInput
  };
}

export default useCustomFileInput;
