import { Typography, Box } from '@mui/material';
import Background from './Accessdenied.styled';
import useAccessdeniedController from './Accessdenied.controller';
import { CustomButton } from '../../components';

export function Accessdenied() {
  const { email, logout } = useAccessdeniedController();
  return (
    <Background>
      {email && (
        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
          <Typography>
            O usuário com o email: {email} não está vinculado a nenhum emprendimento.
          </Typography>
          <Box width="200px">
            <CustomButton onClick={logout} text="Sair" />
          </Box>
        </Box>
      )}
    </Background>
  );
}
