import { useNavigate } from 'react-router-dom';
import { useNotificationStore, useMaintenancesStore } from '../../../../../../store';

function useNotificationModalController() {
  const navigate = useNavigate();
  const { setIsInfoModalOpen, setMaintenanceID } = useMaintenancesStore();
  const {
    handleNotificationModalOpen,
    isOpenNotificationModal,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    handleSetNotificationsList,
    changeNotificationPageRequest
  } = useNotificationStore();

  function closeActionModalOnClickAway() {
    if (!isOpenNotificationModal) return;
    if (isOpenNotificationModal) {
      handleNotificationModalOpen(false);
    }
  }

  async function changeToNextPage() {
    if (!(hasNextPage && nextPage)) return;
    const data = await changeNotificationPageRequest(nextPage);
    handleSetNotificationsList(data.data || []);
  }

  async function changeToPrevPage() {
    if (!(hasPrevPage && prevPage)) return;
    const data = await changeNotificationPageRequest(prevPage);
    handleSetNotificationsList(data.data || []);
  }

  function navigateToPage(data: { type: 'maintenance' | 'occurrence'; contextId: string }) {
    switch (data.type) {
      case 'maintenance':
        handleNotificationModalOpen(false);
        navigate('maintenance');
        setMaintenanceID(data.contextId);
        setIsInfoModalOpen(true);
        break;
      case 'occurrence':
        handleNotificationModalOpen(false);
        navigate(`occurrences/detail/${data.contextId}`);
        break;
      default:
        break;
    }
  }

  return {
    closeActionModalOnClickAway,
    changeToNextPage,
    changeToPrevPage,
    navigateToPage,
    hasNextPage,
    hasPrevPage
  };
}

export default useNotificationModalController;
