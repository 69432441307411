/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { useBlocksStore, useLoadModalStore, useInformativesStore } from '../../../../store';

function useBlocksAndUnitsController() {
  const { getSimpleBlockListData, blocksList } = useBlocksStore();
  const { toogleLoading } = useLoadModalStore();
  const {
    setBlocksIDsToRequest,
    setUnitsIDsToRequest,
    blocksIDsToRequest,
    unitsIDsToRequest,
    getAllUnitsOfBlockRequest
  } = useInformativesStore();

  const [blockList, setBlockList] = useState<{ label: string; value: string }[]>([]);
  const [unitList, setUnitList] = useState<{ label: string; value: string }[]>([]);

  async function getAllBlocks() {
    toogleLoading();
    try {
      await getSimpleBlockListData();
    } finally {
      toogleLoading();
    }
  }

  function formatBlockList() {
    setBlockList(blocksList);
    setUnitsIDsToRequest([]);
    setBlocksIDsToRequest([]);
  }

  async function getAllUnitsOfBlock(blockId: string) {
    toogleLoading();
    try {
      const data = await getAllUnitsOfBlockRequest(blockId);
      setUnitList(data);
    } finally {
      toogleLoading();
    }
  }

  function handleSetBlock(blockId: string) {
    const formatedBlocksId = blocksIDsToRequest.includes(blockId)
      ? blocksIDsToRequest.filter((id) => id !== blockId)
      : [...blocksIDsToRequest, blockId];
    setBlocksIDsToRequest(formatedBlocksId);
    setUnitsIDsToRequest([]);

    if (formatedBlocksId.length === 1) getAllUnitsOfBlock(formatedBlocksId[0]);
  }

  function handleSetUnit(unitId: string) {
    const formatedUnitsId = unitsIDsToRequest.includes(unitId)
      ? unitsIDsToRequest.filter((id) => id !== unitId)
      : [...unitsIDsToRequest, unitId];
    setUnitsIDsToRequest(formatedUnitsId);
  }

  useEffect(() => {
    getAllBlocks();
    formatBlockList();
  }, [blocksList.length]);

  return { blockList, unitList, handleSetBlock, handleSetUnit };
}

export default useBlocksAndUnitsController;
