import styled from 'styled-components';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .input {
    width: 100%;
    input {
      height: 18px;
      width: 100%;
    }
  }

  .selectInput {
    width: 100%;
    border-radius: 10px;
    height: 51px;
    div {
      display: flex;
      justify-content: left;
      align-items: center;
      height: 100%;
      border-radius: 10px;
    }
  }

  .modalTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #121212;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  .companySectorContainer {
    position: relative;
    border-radius: 12px;
    height: 51px;
  }

  .sectorContainer {
    position: relative;
  }

  .inputLabel {
    position: absolute;
    top: 13px;
    left: 15px;
  }

  .buttonArea {
    display: flex;
    justify-content: space-between;

    margin-top: 32px;
  }

  .closeButton {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .inputTitle {
    font-family: 'Public Sans';
    margin-bottom: 8px;
  }

  .errorMessage {
    height: 32px;
    color: red;
    font-size: 13px;
    margin-top: -8px;
  }

  .errorSector {
    padding-top: 5px;
  }
`;
export default Background;

export const ModalContent = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 850px;
  max-height: 98%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #662483;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  padding: 24px 32px;
  border-radius: 6px;
  height: min-content;
  position: relative;

  .container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 15px;
  }

  .inputContainer {
    div {
      border-radius: 10px;
      margin-bottom: 5px;
    }
  }

  .inputFileDescription {
    font-size: 12px;
    color: #8b8b8b;
    margin-top: 0.5rem;
  }

  @media (max-width: 820px) {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    position: relative;

    .closeButton {
      position: absolute;
      top: 24px;
      right: 24px;
    }

    .errorMessage {
      height: 32px;
    }
  }

  @media (max-width: 720px) {
    .errorMessage {
      height: 40px;
    }
  }
`;
