/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useActionModalStore,
  useLoadModalStore,
  useRolesModalStore,
  useInfoModalStore,
  usePaginationStore,
  useSortItemsStore
} from '../../store';
import { roleValidator } from '../../helpers/roleValidator';
import { formatRoles } from '../../helpers/formatRoles';
import { FormRequiredFields } from './Roles.props';
import useCustomPaginationController from '../../components/CustomTable/components/CustomPagination/CustomPagination.controller';
import { Errors } from '../../constants/Errors';
import { IRoleByIdAllDataDtoOutput } from '../../services/requests/roles/Roles.dto';

export const useRoleController = () => {
  const {
    id,
    handleSetJobsRoleId,
    handleSetJobsRolesId,
    direction,
    field,
    // requests
    createRoleRequest,
    deleteRoleRequest,
    handleSetJobsRolesList,
    editRoleRequest,
    getRolesRequest,
    searchRolesRequest,
    getAllRolesSortDataRequest
  } = useRolesModalStore();
  const {
    changeSortDownColor,
    changeSortUpColor,
    handleSetSortItemId,
    handleSetSortFields,
    sortDown,
    sortUp,
    sortItemId,
    sortFields
  } = useSortItemsStore();
  const { pageNumbers } = useCustomPaginationController();
  const { isOpenActionModal, handleIsLastItemOfTable, handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const {
    handleSetText,
    text,
    isOpenInfoModal,
    handleModalOpen,
    handleSetIsSuccessfully,
    isSuccessfully
  } = useInfoModalStore();
  const [functionalities, setFunctionalities] = useState(['']);
  const { roles: unformatedRoles } = useRolesModalStore();
  const [indexOfRow, setIndexOfRow] = useState(-1);
  const [searchString, setSearchString] = useState('');
  const [rowsId, setRowsId] = useState(['']);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRoles, setTotalRoles] = useState(0);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [functionalitiesId, setFunctionalitiesId] = useState<Array<string>>([]);
  const [tableData, setTableData] = useState([
    {
      name: '',
      permissions: ['']
    }
  ]);

  const schema = yup.object().shape({
    name: yup.string().required('Por favor, preencher campo cargo.')
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  });

  function setRolesFormated() {
    const allRolesOfUser = unformatedRoles.map((role) => roleValidator(role));
    const formatedRoles = allRolesOfUser.filter((role) => role);
    setFunctionalities(formatedRoles);
  }

  const toggleConfirmEditModal = () => {
    setTitleModal('Tem certeza que deseja editar esse cargo?');
    setIsModalEditOpen((prev) => !prev);
  };

  const toggleDeleteModal = () => {
    if (isModalEditOpen) return;
    setTitleModal('Tem certeza que deseja apagar esse cargo?');
    setIsModalDeleteOpen((prev) => !prev);
  };

  const onSubmit = async () => {
    const { name } = getValues();
    const permissions = functionalitiesId;
    const nameBiggerThan50 = name.length >= 50;

    const formatedPermission = permissions.filter((permission: string) => permission);

    toogleLoading();

    if (isEdit) {
      try {
        if (!name.replace(' ', '')) {
          handleSetText('Campo "Cargo" não pode ser vazio!');
          return;
        }
        if (nameBiggerThan50) {
          handleSetText(
            `Campo "Cargo" não pode possuir mais que 50 caracteres, atualmente possuí ${name.length}.`
          );
          return;
        }
        await editRoleRequest({
          id,
          name: name.trim(),
          permissions: [...formatedPermission, 'blocos/unidades']
        });
        handleSetIsSuccessfully(true);
        handleSetText('Cargo editado com sucesso!');
      } catch (error: any) {
        handleSetIsSuccessfully(false);
        handleSetText(
          (Errors as any)({})[error.message] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
      } finally {
        handleModalOpen();
        toggleConfirmEditModal();
        toogleLoading();
      }
    } else {
      try {
        if (!name.trim()) {
          handleSetText('Campo "Cargo" não pode ser vazio!');
          return;
        }
        if (nameBiggerThan50) {
          handleSetText(
            `Campo "Cargo" não pode possuir mais que 50 caracteres, atualmente possuí ${name.length}.`
          );
          return;
        }
        await createRoleRequest({
          name: name.trim(),
          permissions: [...formatedPermission, 'blocos/unidades']
        });
        handleSetIsSuccessfully(true);
        handleSetText('Cargo cadastrado com sucesso!');
      } catch (error: any) {
        handleSetIsSuccessfully(false);
        handleSetText(
          (Errors as any)({})[error.message] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
      } finally {
        handleModalOpen();
        toogleLoading();
      }
    }
    setIsModalEditOpen(false);
    setIsEdit(false);
    setValue('name', '');
    setFunctionalitiesId(['']);
  };

  const getRequestRolesDataById = async (idOfRow: string) => {
    const data = await getRolesRequest(idOfRow);

    setFunctionalitiesId(data.data.data.permissions);
  };

  const handleEditRole = async () => {
    toogleLoading();
    try {
      setIsEdit(true);
      await getRequestRolesDataById(id);
      const roleToEdit = tableData[indexOfRow];
      setValue('name', roleToEdit.name);
    } finally {
      toogleLoading();
    }
  };

  function handleClickOnActionBtn(
    e: React.MouseEvent<HTMLElement>,
    func: () => void,
    index: number,
    handleSetItemId: (id: string) => void
  ) {
    const tableLastItemm = index === 9;

    tableLastItemm ? handleIsLastItemOfTable(true) : handleIsLastItemOfTable(false);

    const componentSelected = e.target as HTMLElement;
    if (!componentSelected.id) {
      handleSetItemId(componentSelected.parentElement?.id || '');
      setIndexOfRow(index);
      func();
      return;
    }
    handleSetItemId(componentSelected.id);
    setIndexOfRow(index);
    func();
  }

  const handleShowMorePermissions = (index: number) => {
    if (isShowMore && index === indexOfRow) {
      setIsShowMore(false);
    } else {
      setIsShowMore(true);
    }
    setIndexOfRow(index);
  };

  const formatData = (data: IRoleByIdAllDataDtoOutput) => {
    return data.data.data.map((item) => {
      const permissions = item.permissions.filter((permission) => {
        return formatRoles(permission) !== 'blocos/unidades';
      });

      return {
        name: item.name,
        permissions
      };
    });
  };

  const formatIdRows = (data: IRoleByIdAllDataDtoOutput) => {
    return data.data.data.map((item) => {
      return item.id;
    });
  };

  const setPageAndCountItems = (data: IRoleByIdAllDataDtoOutput) => {
    const { totalPages: pages } = data.data;
    const { totalRoles: roles } = data.data;
    setTotalPages(pages);
    setTotalRoles(roles);
  };

  const getRequestRolesData = async () => {
    toogleLoading();
    try {
      const data = await getAllRolesSortDataRequest(
        String(currentPage),
        field,
        direction,
        searchString
      );

      if (data) {
        const formatedData = formatData(data);
        const formatedRowsId = formatIdRows(data);

        setTableData(formatedData);
        setRowsId(formatedRowsId);
        handleSetJobsRolesId(formatedRowsId);
        setPageAndCountItems(data);
      }
    } finally {
      toogleLoading();
    }
  };

  async function handleSearch(value: string) {
    toogleLoading();
    try {
      const formatedDataForSearch = { queryText: value };

      const data = await searchRolesRequest(
        formatedDataForSearch,
        field,
        direction,
        String(currentPage)
      );

      if (data) {
        const formatedData = formatData(data);
        const formatedRowsId = formatIdRows(data);

        setTableData(formatedData);
        setRowsId(formatedRowsId);
        handleSetJobsRolesId(formatedRowsId);
        setPageAndCountItems(data);
      }
    } finally {
      toogleLoading();
    }
  }

  async function sortRoles(page: string, sortByField: string, sortByDirection: string) {
    const data = await getAllRolesSortDataRequest(page, sortByField, sortByDirection, searchString);
    return data;
  }

  const changePage = async (page: number) => {
    toogleLoading();
    try {
      const data = await getAllRolesSortDataRequest(String(page), field, direction, searchString);

      if (data) {
        const formatedData = formatData(data);
        const formatedRowsId = formatIdRows(data);

        setTableData(formatedData);
        setRowsId(formatedRowsId);
        handleSetJobsRolesId(formatedRowsId);
        setPageAndCountItems(data);
      }
    } finally {
      toogleLoading();
    }
  };

  const onSubmitDelete = async () => {
    toogleLoading();
    try {
      await deleteRoleRequest(id);
      handleSetText('Cargo excluído com sucesso!');
      setIsModalDeleteOpen(false);
      setIsModalEditOpen(false);
      handleSetIsSuccessfully(true);
      handleModalOpen();
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      setIsModalDeleteOpen(false);
      setIsModalEditOpen(false);
      handleSetIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  const handleSetFunctionalitiesId = (indexOfPermissions: number) => {
    let isRepeated = false;

    const newFunctionalitiesId = [...functionalitiesId];

    newFunctionalitiesId.forEach((functionality, index) => {
      if (functionality === functionalities[indexOfPermissions]) {
        newFunctionalitiesId.splice(index, 1);
        isRepeated = true;
        handleSetJobsRolesList(newFunctionalitiesId);
        setFunctionalitiesId(newFunctionalitiesId);
      }
    });

    if (isRepeated) return;

    newFunctionalitiesId.push(functionalities[indexOfPermissions]);
    handleSetJobsRolesList(newFunctionalitiesId);
    setFunctionalitiesId(newFunctionalitiesId);
  };

  const handleClickSortButton = async (
    changeColor: () => void,
    idOfLine: string,
    sortFunc: (page: string, sortByField: string, sortByDirection: string) => any,
    sortDirection: string,
    position: number
  ) => {
    handleSetSortItemId(idOfLine);
    changeColor();

    const data = await sortFunc(String(pageNumbers[0]), sortFields[position], sortDirection);

    if (data) {
      const formatedData = formatData(data);
      const formatedRowsId = formatIdRows(data);

      setTableData(formatedData);
      setRowsId(formatedRowsId);
      handleSetJobsRolesId(formatedRowsId);
      setPageAndCountItems(data);
    }
  };

  const actionModalData = [
    {
      ButtonsText: 'Editar',
      OnClick: () => {
        handleEditRole();
        handleDrawerOpen();
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        toggleDeleteModal();
        handleDrawerOpen();
      }
    }
  ];

  function setSetSortFields() {
    handleSetSortFields(['name']);
  }

  useEffect(() => {
    getRequestRolesData();
    setSetSortFields();
  }, [isOpenInfoModal]);

  useEffect(() => {
    setRolesFormated();
    setSetSortFields();
  }, [unformatedRoles]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    isOpenActionModal,
    indexOfRow,
    handleClickOnActionBtn,
    handleSubmit,
    register,
    isSuccessfully,
    control,
    errors,
    onSubmit,
    tableData,
    rowsId,
    handleShowMorePermissions,
    actionModalData,
    handleDrawerOpen,
    handleSetJobsRoleId,
    toggleConfirmEditModal,
    onSubmitDelete,
    isEdit,
    totalPages,
    totalRoles,
    changePage,
    functionalitiesId,
    handleSetFunctionalitiesId,
    handleSearch,
    isShowMore,
    text,
    isOpenInfoModal,
    handleModalOpen,
    handleClickSortButton,
    sortDown,
    sortUp,
    titleModal,
    sortItemId,
    changeSortDownColor,
    changeSortUpColor,
    sortRoles,
    isModalDeleteOpen,
    toggleDeleteModal,
    isModalEditOpen
  };
};
