/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Modal as ModalComponent, Typography } from '@mui/material';

import { ModalProps } from './detailsModal.props';
import {
  ModalContent,
  ModalTitle,
  ModalButtonArea,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalBackButton,
  ModalInfoArea,
  ModalInfoTitle,
  ModalInfoSubtitle,
  ModalInfoAreaRow
} from './detailsModal.style';

import { useDetailsModalController } from './detailsModal.controller';

import enterpriseIcon from '../../../../assets/images/enterpriseIcon.svg';
import maintenanceTypeIcon from '../../../../assets/images/warningIcon.svg';
import createdByIcon from '../../../../assets/images/infoCreatedBy.svg';
import infoData from '../../../../assets/images/infoData.svg';
import statusIcon from '../../../../assets/images/infoBell.svg';
import descriptionIcon from '../../../../assets/images/infoPerson.svg';

const downloadIcon = require('../../../../assets/images/download.svg');
const pdfIcon = require('../../../../assets/images/pdfIcon.svg');

export function DetailsModal({ isModalOpen, toggleModal, finishMaintenance }: ModalProps) {
  const {
    handleClickConfirm,
    handleClickBackButton,
    handleClickImage,
    enterprise,
    type,
    createdBy,
    file,
    date,
    fileType,
    status,
    description
  } = useDetailsModalController(toggleModal, finishMaintenance);

  const isDone = status.toLowerCase() === 'concluído';

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <ModalContent statusColor={status.toUpperCase()}>
        <ModalCloseButton
          onClick={toggleModal}
          style={{
            position: 'absolute',
            right: 10,
            top: 10
          }}>
          <ModalCloseButtonIcon />
        </ModalCloseButton>

        <ModalTitle id="maintenance-detail-title">Informações da Manutenção</ModalTitle>

        <ModalInfoArea>
          <Box display="flex" marginBottom="16px">
            <img src={enterpriseIcon} alt="infoPerson" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Empreendimento</ModalInfoTitle>
              <ModalInfoSubtitle>{enterprise}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={maintenanceTypeIcon} alt="infoLocation" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Tipo de Manutenção</ModalInfoTitle>
              <ModalInfoSubtitle>{type}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={createdByIcon} alt="infoData" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Criada por:</ModalInfoTitle>
              <ModalInfoSubtitle>{createdBy.name ? createdBy.name : createdBy}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={infoData} alt="infoBox" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Data</ModalInfoTitle>
              <ModalInfoSubtitle>{date}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={statusIcon} alt="infoBox" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Status</ModalInfoTitle>
              <ModalInfoSubtitle>
                <Box className="statusBox">
                  <Typography className="statusText">
                    {status && `${status[0]}${status.substring(1, status.length).toLowerCase()}`}
                  </Typography>
                </Box>
              </ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={descriptionIcon} alt="infoBox" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Descrição</ModalInfoTitle>
              <ModalInfoSubtitle>
                <Box className="descriptionContainer">{description}</Box>
              </ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          {file.length > 0 && (
            <>
              <Box>
                <ModalInfoTitle>Arquivos</ModalInfoTitle>
                <Box className="fileContainer">
                  {fileType === 'image' ? (
                    <>
                      <img src={file} alt="arquivo" onClick={handleClickImage} />
                      <Box className="imageDescription">Imagem</Box>
                    </>
                  ) : (
                    <Box className="pdfContainer">
                      <pdfIcon.ReactComponent />
                      <Box className="imageDescription">
                        Pdf
                        <a target="_blank" href={file} rel="noreferrer">
                          <downloadIcon.ReactComponent />
                        </a>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box className="itemImage" />
            </>
          )}
        </ModalInfoArea>

        <ModalButtonArea>
          <ModalBackButton
            style={{
              backgroundColor: '#662483',
              color: '#fff',
              width: '100%',
              height: 44,
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: 500,
              marginBottom: '10px',
              textTransform: 'capitalize',
              opacity: isDone ? '70%' : '100%'
            }}
            disabled={isDone}
            onClick={handleClickConfirm}>
            Finalizar
          </ModalBackButton>
          <ModalBackButton
            id="maintenance_backButton"
            style={{
              backgroundColor: '#F0F1F5',
              color: '#2C2C2C',
              width: '100%',
              height: 44,
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: 500,
              textTransform: 'capitalize'
            }}
            variant="contained"
            disableElevation
            onClick={handleClickBackButton}>
            Voltar
          </ModalBackButton>
        </ModalButtonArea>
      </ModalContent>
    </ModalComponent>
  );
}
