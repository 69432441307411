import { usePreviewMobileModalStore } from '../../../../store';

function useCustomizationController() {
  const { handleMobilePreviewOpen } = usePreviewMobileModalStore();
  return {
    handleMobilePreviewOpen
  };
}

export default useCustomizationController;
