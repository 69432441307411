import { Box, Typography, Button } from '@mui/material';

import useBackToLastPageModalController from './BackToLastPageModal.controller';
import { BackToLastPageModalProps } from './BackToLastPageModal.props';

import alert from '../../assets/images/alert.svg';
import * as S from './BackToLastPageModal.style';

export function BackToLastPageModal(props: BackToLastPageModalProps) {
  const { goToPage, handleModalClose } = props;
  const { handleBackToLastPageModalOpen, goBackToEnterprisePage } =
    useBackToLastPageModalController(goToPage, handleModalClose);

  return (
    <S.Background>
      <Box className="container">
        <img src={alert} alt="alert" />
        <Typography color="#2C2C2C" fontWeight="700" textAlign="center" fontSize="22px">
          Deseja sair desta tela?
        </Typography>
        <Typography
          color="#8B8B8B"
          align="center"
          fontSize="18px"
          marginTop="-5px"
          marginBottom={2}>
          É possível que as alterações feitas não sejam salvas.
        </Typography>
        <Box className="buttonsContainer">
          <Button className="button exitButton" onClick={goBackToEnterprisePage}>
            Sair
          </Button>
          <Button className="button cancelButton" onClick={handleBackToLastPageModalOpen}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </S.Background>
  );
}
