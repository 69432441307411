/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, TextField } from '@mui/material';
import { AutoCompleteProps } from './Autocomplete.props';
import Container from './Autocomplete.style';

export function AutocompleteInput({
  options,
  isDisabled = false,
  setSelectedItem,
  handleSearch,
  className,
  id
}: AutoCompleteProps) {
  return (
    <Container>
      <Autocomplete
        disablePortal
        className={className}
        disabled={isDisabled}
        id={`${id} combo-box-demo`}
        options={options}
        sx={{ width: '100%', background: !isDisabled ? 'transparent' : 'rgba(122,122,122,0.3)' }}
        noOptionsText="nenhum resultado encontrado"
        getOptionLabel={(option: { label: string; value: string }) => option.label}
        onInputChange={(_, value) => handleSearch(value)}
        onChange={(_, newValue: any) => {
          setSelectedItem(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Container>
  );
}
