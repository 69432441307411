/* eslint-disable no-underscore-dangle */
import { instance } from '../../api';

import {
  IOutputAllResidentsData,
  IAllResidentDtoOutput,
  IResidentDtoOutput,
  SearchResident,
  RequestResident
} from './Residents.dto';
import { formatData } from '../../../helpers/formatData';

export const registerResident = async (residentData: RequestResident): Promise<void> => {
  try {
    await instance.post('resident', residentData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editResident = async (residentData: RequestResident, id: string): Promise<void> => {
  try {
    await instance.put(`resident/${id}`, residentData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getAllResidentsData = async (page: number): Promise<IAllResidentDtoOutput> => {
  try {
    const data = await instance.get(
      `resident?includeMeta=true&page=${page}&sortDirection=DESC&sortBy=resident.created_at&filterFields=resident.name,resident.id,resident.phone,resident.email,resident.created_at,resident.push_token,unit.name,unit.id,block.name,block.id`
    );
    const residents = data.data.data;

    const formatedData = residents.map((resident: IOutputAllResidentsData) => {
      return {
        id: resident.id,
        name: resident.name,
        phone: resident.phone,
        email: resident.email,
        created_at: formatData(resident.created_at),
        unitId: resident.unit.name,
        block: resident.unit.block.name
      };
    });

    const totalResidents = data.data.metaData ? data.data.metaData.count : 0;
    const totalPages = data.data.metaData ? data.data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalResidents, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortResidentsData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IAllResidentDtoOutput> => {
  try {
    const data = await instance.get(
      `resident?sortDirection=${sortByDirection || 'DESC'}&sortBy=${
        sortByField || 'resident.created_at'
      }${
        searchString ? `&search=${searchString}` : ''
      }&includeMeta=true&page=${page}&filterFields=resident.name,resident.id,resident.phone,resident.email,resident.created_at,resident.push_token,unit.name,unit.id,block.name,block.id`
    );
    const residents = data.data.data;

    const formatedData = residents.map((resident: IOutputAllResidentsData) => {
      return {
        id: resident.id,
        name: resident.name,
        phone: resident.phone,
        email: resident.email,
        created_at: formatData(resident.created_at),
        unitId: resident.unit.name,
        block: resident.unit.block.name
      };
    });

    const totalResidents = data.data.metaData ? data.data.metaData.count : 0;
    const totalPages = data.data.metaData ? data.data.metaData.pages : 0;
    return {
      data: { data: formatedData, totalResidents, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getResidentData = async (id: string): Promise<IResidentDtoOutput> => {
  try {
    const { data } = await instance.get(`resident/${id}`);
    const resident = data.data;

    const formatedData = {
      id: resident.id,
      name: resident.name,
      phone: resident.phone,
      email: resident.email,
      cpf: resident.cpf,
      block: resident.unit.block.id,
      unitId: resident.unit.id,
      unit: resident.unit
    };

    return {
      data: formatedData
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteResident = async (id: string): Promise<void> => {
  try {
    await instance.delete(`resident/${id}`, {
      headers: {
        'Content-type': 'application/json;charset=utf-8'
      }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchResidents = async (
  residentsData: SearchResident,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IAllResidentDtoOutput> => {
  try {
    const { data } = await instance.get(
      `resident/?search=${residentsData.queryText}&sortDirection=${
        sortByDirection || 'DESC'
      }&sortBy=${sortByField}&includeMeta=true&page=${page}&filterFields=resident.name,resident.id,resident.phone,resident.email,resident.created_at,resident.push_token,unit.name,unit.id,block.name,block.id`
    );

    const residents = data.data;

    const formatedData = residents.map((resident: IOutputAllResidentsData) => {
      return {
        id: resident.id,
        name: resident.name,
        phone: resident.phone,
        email: resident.email,
        created_at: formatData(resident.created_at),
        unitId: resident.unit.name,
        block: resident.unit.block.name
      };
    });

    const totalResidents = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalResidents, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const resendResidentEmail = async (email: string): Promise<void> => {
  try {
    await instance.post('confirmAccount', { email });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
