/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import { roleValidator } from '../../../../helpers/roleValidator';
import { formatRoles } from '../../../../helpers/formatRoles';

import { useRolesModalStore } from '../../../../store/Roles/Roles.store';

function useEnterpriseFunctionalityController() {
  const [functionalities, setFunctionalities] = useState(['']);
  const [functionalitiesToRequest, setFunctionalitiesToRequest] = useState(['']);
  const { roles } = useRolesModalStore();

  function setRolesFormated() {
    const allRolesOfUser = roles.map((role) => formatRoles(role));
    const formatedRoles = allRolesOfUser.filter((role) => role);
    setFunctionalities(formatedRoles);
  }

  function setRolesFormatedToRequest() {
    const allRolesOfUser = roles.map((role) => roleValidator(role));
    const formatedRoles = allRolesOfUser.filter((role) => role);
    setFunctionalitiesToRequest(formatedRoles);
  }

  function getCheckedFunctionalities(functionalityId: number, functionalitiesChecked: string[]) {
    const functionalitiesCheckedId: number[] = [];

    functionalitiesToRequest.forEach((functionality, index) => {
      functionalitiesChecked.forEach((functionalityChecked: string) => {
        if (functionalityChecked === functionality) {
          functionalitiesCheckedId.push(index);
        }
      });
    });

    return functionalitiesCheckedId.indexOf(functionalityId) !== -1;
  }

  useEffect(() => {
    setRolesFormated();
    setRolesFormatedToRequest();
  }, [roles]);

  return {
    getCheckedFunctionalities,
    functionalities
  };
}

export default useEnterpriseFunctionalityController;
