import styled from 'styled-components';

const Background = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 10000000;
  top: 0;
  bottom: 0;

  .loader {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;

    &-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    div {
      position: absolute;
      border: 4px solid #662483;
      opacity: 1;
      animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      border-radius: 50%;

      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }

  @keyframes loader {
    0% {
      top: 30px;
      left: 30px;
      width: 0px;
      height: 0px;
      opacity: 1;
    }

    100% {
      top: -30px;
      left: -30px;
      width: 118px;
      height: 118px;
      opacity: 0;
    }
  }
`;

export default Background;
