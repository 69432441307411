import ReactDOM from 'react-dom/client';

import App from './App';

import GlobalStyle from './style';

import './ReactotronConfig';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <GlobalStyle />
    <App />
  </>
);
