import { instance } from '../../api';
import { AllVisitorsData, IVisitorsByIdAllDataDtoOutput, SearchVisitors } from './Visitors.dto';
import { formatType, formatStatus } from '../../../helpers/formatVisitorData';
import { formatData } from '../../../helpers/formatData';

export const createVisitor = async (name: string): Promise<void> => {
  try {
    return await instance.post('visitor', { name });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getVisitorInfosById = async (visitorId: string): Promise<any> => {
  try {
    const { data } = await instance.get(`visitor/manager/${visitorId}`);

    const status =
      data.data.final_date < new Date().toISOString() ? 'Expirado' : formatStatus(data.data.status);

    const formatedData = {
      id: data.data.id,
      name: data.data.name,
      unity: data.data.unit.name,
      block: data.data.unit.block.name,
      document: data.data.document,
      phone: data.data.cell_phone,
      startedAt: formatData(data.data.inicial_date),
      endedAt: formatData(data.data.final_date),
      visitType: formatType(data.data.type),
      status
    };

    return { data: formatedData };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteVisitor = async (id: string): Promise<void> => {
  try {
    await instance.delete(`visitor/${id}`);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortVisitorsData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IVisitorsByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `visitor/enterprise?includeMeta=true&page=${page}&sortDirection=${sortByDirection}&sortBy=${sortByField}&search=${searchString}`
    );

    const formatedData = data.data.map((item: AllVisitorsData) => {
      return {
        id: item.id,
        name: item.name,
        unity: item.unit.name,
        block: item.unit.block.name,
        document: item.document,
        phone: item.cell_phone,
        visitDate: formatData(item.inicial_date),
        visitType: formatType(item.type),
        status: formatStatus(item.status)
      };
    });

    const totalVisitors = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: formatedData, totalVisitors, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchVisitors = async (
  visitorsData: SearchVisitors,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IVisitorsByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `visitor/enterprise?search=${visitorsData.queryText}&includeMeta=true&page=${page}&sortDirection=${sortByDirection}&sortBy=${sortByField}`
    );

    const formatedData = data.data.map((item: AllVisitorsData) => {
      return {
        id: item.id,
        name: item.name,
        unity: item.unit.name,
        block: item.unit.block.name,
        document: item.document,
        phone: item.cell_phone,
        visitDate: formatData(item.inicial_date),
        visitType: formatType(item.type),
        status: formatStatus(item.status)
      };
    });

    const totalVisitors = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: formatedData, totalVisitors, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const changeStatusVisitors = async (id: string, status: string): Promise<void> => {
  try {
    await instance.post(`visitor/changeStatus/${id}`, { status });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
