import { useEffect, useState } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import {
  useActionModalStore,
  useBackToLastPageModalStore,
  useDeleteModalStore,
  useInfoModalStore,
  useLoadModalStore,
  usePaginationStore,
  useSortItemsStore
} from '../../store';
import { useFilesStore } from '../../store/Files/Files.store';
import { SearchDocuments } from '@/services/requests/documents/Documents.dto';
import { Errors } from '../../constants/Errors';

function useDocumentsController() {
  const [rowsId, setRowsId] = useState<string[]>([]);
  const [infoText, setInfoText] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false);
  const [, setIsModalConfirmOpen] = useState(false);
  const [folders, setFolders] = useState([] as any);
  const [folderName, setFolderName] = useState('');
  const [folderId, setFolderId] = useState('');
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const { isOpenBackToLastPageModal } = useBackToLastPageModalStore();
  const [, setIsModalInforOpen] = useState(false);
  const { handleSetSortFields } = useSortItemsStore();
  const {
    isOpenInfoModal,
    handleSetIsSuccessfully,
    handleSetText,
    handleModalOpen,
    isSuccessfully,
    text
  } = useInfoModalStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { toogleLoading } = useLoadModalStore();
  const {
    handleSetFilesId,
    getIdFile,
    deleteFileRequest,
    searchFileRequest,
    getAllFilesSortRequest,
    getFileById,
    setIdFile,
    field,
    direction
  } = useFilesStore();

  const foldId = useParams();

  const navigation = useNavigate();

  const toggleRegisterModal = () => {
    setIsModalRegisterOpen((prev) => !prev);
    handleSetText('Arquivo adicionado com sucesso!');
  };

  const toggleEditModal = () => {
    setIsModalEditOpen((prev) => !prev);
    handleSetText('Arquivo renomeado com sucesso!');
  };

  const toggleInforModal = () => {
    setIsModalInforOpen((prev) => !prev);
  };

  async function downloadDocument() {
    toogleLoading();
    try {
      const id = await getIdFile();
      const data = await getFileById(id);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.open(data, '_blank').focus();
    } finally {
      toogleLoading();
    }
  }

  const actionModalData = [
    {
      ButtonsText: 'Renomear',
      OnClick: () => {
        toggleEditModal();
        handleSetIsSuccessfully(true);
        handleSetText('Arquivo renomeado com sucesso!');
      }
    },
    {
      ButtonsText: 'Fazer Download',
      OnClick: () => {
        downloadDocument();
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        handleDeleteModalOpen();
        handleDrawerOpen();
        handleSetIsSuccessfully(true);
        handleSetText('Arquivo excluído com sucesso!');
      }
    }
  ];

  const getAllFiles = async () => {
    toogleLoading();
    try {
      const { data, folderName: name } = await getAllFilesSortRequest(
        String(currentPage),
        field,
        direction,
        foldId,
        searchString
      );

      setFolderName(name);

      const formatedData = data.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          size: `${item.size}`,
          type: item.type === 'folder' ? 'Pasta' : `${item.type}`
        };
      });
      const formatedRowsId = data.data.map((item) => item.id);

      if (data) {
        setRowsId(formatedRowsId);
        handleSetFilesId(formatedRowsId);
        setFolders(formatedData);
        setTotalPages(data.totalPages || 0);
        setTotalFiles(data.totalFiles || 0);
      }
    } finally {
      toogleLoading();
    }
  };

  const goBack = () => {
    navigation('/docs');
  };

  const changePage = async (page: number) => {
    toogleLoading();
    try {
      const { data, folderName: name } = await getAllFilesSortRequest(
        String(page),
        field,
        direction,
        foldId,
        searchString
      );

      setFolderName(name);

      const formatedData = data.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          size: `${item.size}`,
          type: item.type === 'folder' ? 'Pasta' : `${item.type}`
        };
      });
      const formatedRowsId = data.data.map((item) => item.id);

      if (data) {
        setRowsId(formatedRowsId);
        handleSetFilesId(formatedRowsId);
        setFolders(formatedData);
        setTotalPages(data.totalPages || 0);
        setTotalFiles(data.totalFiles || 0);
      }
    } finally {
      toogleLoading();
    }
  };

  const handleSearch = async (value: string) => {
    setSearchString(value);
    const formatedDataForSearch: SearchDocuments = { queryText: value };

    const data: any = await searchFileRequest(
      formatedDataForSearch,
      foldId,
      field,
      direction,
      String(currentPage)
    );

    const formatedData = data.data.map(
      (item: { id: string; name: string; size: string; type: string }) => {
        return {
          id: item.id,
          name: item.name,
          size: `${item.size}`,
          type: item.type === 'folder' ? 'Pasta' : `${item.type}`
        };
      }
    );
    const formatedRowsId = data.data.map((item: { id: string }) => item.id);

    if (data) {
      setRowsId(formatedRowsId);
      handleSetFilesId(formatedRowsId);
      setTotalPages(data.totalPages || 0);
      setTotalFiles(data.totalFiles || 0);
    }
    setFolders(formatedData);
  };

  async function sortFiles(
    page: string,
    sortByField: string,
    sortByDirection: string,
    newFolderId: any
  ) {
    const data = await getAllFilesSortRequest(
      page,
      sortByField,
      sortByDirection,
      newFolderId,
      searchString
    );
    return data;
  }

  const successModal = () => {
    handleModalOpen();
    setIsModalRegisterOpen(false);
    setIsModalConfirmOpen(false);
    getAllFiles();
  };

  const deleteFileOfRow = async () => {
    try {
      const id = await getIdFile();
      await deleteFileRequest(foldId, id, successModal);
      handleSetIsSuccessfully(true);
    } catch (error: any) {
      handleSetIsSuccessfully(false);
      setInfoText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    }
  };

  const setSortFields = () => {
    handleSetSortFields(['name', 'size', 'type']);
  };

  useEffect(() => {
    getAllFiles();
    setSortFields();
  }, []);

  const tableHeaders = ['Nome', 'Tamanho', 'Tipo', 'Ações'];

  function closeModal() {
    if (isModalRegisterOpen) {
      toggleRegisterModal();
    }

    if (isModalEditOpen) {
      toggleEditModal();
    }
  }

  useEffect(() => {
    setFolderId(String(foldId.id));
  }, []);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    tableHeaders,
    folderId,
    actionModalData,
    isOpenDeleteModal,
    isModalEditOpen,
    rowsId,
    isOpenInfoModal,
    sortFiles,
    isSuccessfully,
    totalPages,
    totalFiles,
    handleDrawerOpen,
    handleDeleteModalOpen,
    isModalRegisterOpen,
    setIdFile,
    toggleRegisterModal,
    successModal,
    folders,
    deleteFileOfRow,
    goBack,
    getAllFiles,
    toggleInforModal,
    toggleEditModal,
    setIsModalRegisterOpen,
    folderName,
    changePage,
    handleSearch,
    handleSetIsSuccessfully,
    handleSetText,
    handleModalOpen,
    text,
    isOpenBackToLastPageModal,
    closeModal
  };
}

export default useDocumentsController;
