import styled from 'styled-components';

export const Container = styled.button`
  background: #d9d9d9;

  width: 200px;
  height: 48px;

  border-radius: 8px;
  border: none;

  align-items: center;
  display: flex;

  padding: 0 3px;

  justify-content: space-between;
`;

interface SlideProps {
  isDelivery: boolean;
}

export const Slide = styled.div<SlideProps>`
  background: #fff;

  width: 97px;
  height: 40px;

  border-radius: 6.93px;
  border: 0.5px solid #4e4b59;

  position: absolute;

  transform: translateX(${(props) => (props.isDelivery ? '0px' : '97px')});
`;
