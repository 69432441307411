/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Drawer, IconButton, Box } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

import Header from './components/Header/Header';
import { ListIcon } from '../ListIcons/ListIcons';

import useSidebarController from './Sidebar.controller';
import { DrawerHeader } from './Sidebar.style';

const dashboardicon = require('../../assets/images/dashboardicon.svg');
const eterprisesicon = require('../../assets/images/enterprisesicon.svg');
const maintenanceicon = require('../../assets/images/maintenanceicon.svg');
const employeesicon = require('../../assets/images/employeesicon.svg');
const informativesweb = require('../../assets/images/informativesweb.svg');
const lostandfoundicon = require('../../assets/images/lostandfoundicon.svg');
const partners = require('../../assets/images/partners.svg');
const vote = require('../../assets/images/vote.svg');
const docs = require('../../assets/images/docs.svg');
const reserves = require('../../assets/images/reserves.svg');
const visitors = require('../../assets/images/visitors.svg');
const occurrences = require('../../assets/images/occurrences.svg');
const residents = require('../../assets/images/residents.svg');
const ordersiconweb = require('../../assets/images/ordersiconweb.svg');
const paymentsIcon = require('../../assets/images/payments.svg');

export function Sidebar() {
  const { drawerWidth, open, roles, widthState, iconsNameFormated, linkName, handleDrawerClose } =
    useSidebarController();
  return (
    <>
      {iconsNameFormated && (
        <>
          <Header />
          <Drawer
            sx={{
              '& .MuiDrawer-paper': {
                marginTop: Number(widthState) > 700 ? '65px' : '0',
                width: drawerWidth,
                paddingTop: '10px'
              }
            }}
            variant={Number(widthState) > 700 ? 'persistent' : 'temporary'}
            anchor="left"
            open={Number(widthState) > 700 ? true : open}>
            <DrawerHeader>
              {Number(widthState) < 700 ? (
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeft />
                </IconButton>
              ) : (
                <Box />
              )}
            </DrawerHeader>
            <ListIcon
              roles={roles}
              iconsName={iconsNameFormated}
              linkName={linkName}
              image={[
                { component: <dashboardicon.ReactComponent />, name: 'inicio' },
                { component: <eterprisesicon.ReactComponent />, name: 'empreendimentos' },
                { component: <maintenanceicon.ReactComponent />, name: 'manutencoes' },
                { component: <ordersiconweb.ReactComponent />, name: 'encomendas' },
                { component: <employeesicon.ReactComponent />, name: 'funcionarios' },
                { component: <residents.ReactComponent />, name: 'moradores' },
                { component: <occurrences.ReactComponent />, name: 'ocorrencias' },
                { component: <visitors.ReactComponent />, name: 'visitantes' },
                { component: <informativesweb.ReactComponent />, name: 'informativos' },
                { component: <partners.ReactComponent />, name: 'parceiros' },
                { component: <vote.ReactComponent />, name: 'votacoes' },
                { component: <docs.ReactComponent />, name: 'documentos' },
                { component: <lostandfoundicon.ReactComponent />, name: 'achados/perdidos' },
                { component: <reserves.ReactComponent />, name: 'areas/reservas' },
                { component: <paymentsIcon.ReactComponent />, name: 'boletos' }
              ]}
            />
          </Drawer>
        </>
      )}
    </>
  );
}
