import { Grid, Typography } from '@mui/material';
import { CustomFileInputManual } from '../CustomFileInputManual/CustomFileInputManual';
import Container from './OwnerEnterpriseOptions.style';
import EnterpriseFunctionality from '../EnterpriseFunctionality/EnterpriseFunctionality';
import useOwnerEnterpriseOptionsController from './OwnerEnterpriseOptions.controller';

function OwnerEnterpriseOptions() {
  const {
    functionalities,
    handleSetFunctionalitiesId,
    functionalitiesDisabled,
    functionalitiesId
  } = useOwnerEnterpriseOptionsController();
  return (
    <Container>
      <Grid item xs={12} />
      <EnterpriseFunctionality
        functionalities={functionalities}
        handleGetFunctionalities={handleSetFunctionalitiesId}
        functionalitiesSelected={functionalitiesId}
        functionalitiesDisabled={functionalitiesDisabled}
      />
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight={600} marginTop={1} marginBottom={1}>
          Manual do Proprietário
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomFileInputManual isTenSize accept="image|application/pdf" type="image" />
        <Typography color="#727272" variant="subtitle2" marginY={1}>
          Arquivos permitidos (envio opcional): jpg, png e pdf.
        </Typography>
      </Grid>
    </Container>
  );
}

export default OwnerEnterpriseOptions;
