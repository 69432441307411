import styled from 'styled-components';

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    padding: 20px 12px;
    position: relative;
    width: 880px;
    max-height: 750px;
    background: #ffffff;
    border-radius: 6px;
  }

  .closeModal {
    height: min-content;
    width: 100%;
    display: flex;
    justify-content: right;
  }

  .content {
    padding: 0px 50px;
    height: 100%;
    width: 100%;
    position: relative;
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #2c2c2c;
    margin-bottom: 25px;
  }

  .subTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #2c2c2c;
    margin-bottom: 8px;
  }

  .timeContainer {
    position: relative;
  }

  .timeInput {
    flex: 1;
    min-width: 100%;

    input {
      height: 11px;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }
  }

  .blockContainer {
    position: relative;
    width: 540px;
  }

  .buildingIcon {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .blockInputContainer {
    display: flex;
    gap: 20px;
  }

  .buttonContainer {
    width: 25%;
  }

  .trashContainer {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 25%;
    cursor: pointer;
  }

  .inputsRow {
    margin-bottom: 20px;
  }

  .contentContainer {
    max-height: 320px;
    overflow-y: scroll;
  }

  .trashIcon {
    height: 100%;
    width: 22px;
    svg,
    path {
      cursor: pointer;
      stroke: #662483;
      z-index: 100;
    }
  }

  .buttonsContainer {
    display: flex;
    gap: 20px;
    margin-top: 2rem;
  }

  .saveButton {
    padding: 18px 0px;
    gap: 12px;
    width: 250px;
    height: 50px;
    background: #662483;
    color: #ffffff;
    border-radius: 12px;
    text-transform: none;
    font-size: 16px;
  }

  .saveButton:hover {
    background: #662483;
    color: #ffffff;
    opacity: 0.9;
  }

  .backButton {
    padding: 18px 0px;
    gap: 12px;
    width: 250px;
    height: 50px;
    background: #f0f1f5;
    color: #2c2c2c;
    border-radius: 12px;
    text-transform: none;
    font-size: 16px;
  }

  .errorMessage {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
`;

export default Background;
