import { useEffect, useState } from 'react';
import useDebouncer from '../../helpers/debouncer';

function useCustomSearchInputController(handleSearch: (searchString: string) => void) {
  const [searchValue, setSearchValue] = useState('');

  const debouncedValue = useDebouncer(searchValue);

  function useSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }

  useEffect(() => {
    handleSearch(debouncedValue);
  }, [debouncedValue]);

  return {
    setSearchValue,
    search: useSearch
  };
}

export default useCustomSearchInputController;
