/* eslint-disable react/jsx-props-no-spreading */
import { Modal as ModalComponent, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import { useModalController } from './GetOrderModal.controller';

import { ModalProps } from './GetOrderModal.props';
import {
  ModalBackButton,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalContent,
  ModalInputArea,
  ModalNextButton,
  ModalTitle,
  Container,
  ContainerTitleIcon,
  ModalInputTitle,
  ModalButtonArea
} from './GetOrderModal.style';

export function GetOrderModal({ isModalOpen, toggleModal, onComplete }: ModalProps) {
  const { control, errors, handleConfirmOrderCode, handleSubmit, register, closeVotingModal } =
    useModalController(onComplete, toggleModal);

  return (
    <ModalComponent open={isModalOpen} onClose={closeVotingModal}>
      <Container>
        <ModalContent>
          <ContainerTitleIcon>
            <ModalTitle>Entrega de encomenda</ModalTitle>
            <ModalCloseButton onClick={closeVotingModal}>
              <ModalCloseButtonIcon />
            </ModalCloseButton>
          </ContainerTitleIcon>

          <ModalInputArea>
            <ModalInputTitle>Código de retirada</ModalInputTitle>
            <Controller
              name="code"
              control={control}
              render={() => (
                <TextField
                  placeholder="Informe o código"
                  color="primary"
                  sx={{ width: '100%' }}
                  style={{ borderRadius: '100px' }}
                  {...register('code')}
                  error={!!errors.code}
                />
              )}
            />
            {errors.code && (
              <p style={{ fontFamily: 'Inter', color: '#ff0000' }}>{errors.code.message}</p>
            )}
          </ModalInputArea>

          <ModalButtonArea>
            <ModalNextButton
              style={{
                backgroundColor: '#662483',
                color: 'white',
                width: '100%',
                height: 50,
                fontFamily: 'Inter',
                fontSize: 16,
                fontWeight: 500,
                textTransform: 'capitalize'
              }}
              variant="contained"
              disableElevation
              onClick={handleSubmit(handleConfirmOrderCode)}>
              Registrar
            </ModalNextButton>

            <ModalBackButton
              variant="contained"
              style={{
                backgroundColor: 'transparent',
                width: '100%',
                fontFamily: 'Inter',
                fontSize: 16,
                fontWeight: 500,
                textTransform: 'capitalize',
                marginTop: '16px'
              }}
              disableElevation
              onClick={closeVotingModal}>
              Voltar
            </ModalBackButton>
          </ModalButtonArea>
        </ModalContent>
      </Container>
    </ModalComponent>
  );
}
