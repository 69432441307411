/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Button, Typography, IconButton, TextField } from '@mui/material';
import MaskedInput from 'react-text-mask';
import { Controller } from 'react-hook-form';

import useReservationPeriodController from './ReservationPeriod.controller';
import { IReservationPeriodProps } from './ReservationPeriod.props';

import * as S from './ReservationPeriod.styled';

const trash = require('../../../../assets/images/trash2.svg');
const clock = require('../../../../assets/images/clockicon.svg');

export function ReservationPeriod(reservationProps: IReservationPeriodProps) {
  const { weekDay, allWeekDays } = reservationProps;
  const { allTimes, control, errors, getWeekDay, register, addNewTime, handleSubmit, removeTime } =
    useReservationPeriodController(weekDay);

  return (
    <S.Container>
      <S.Header>
        <Typography className="midTitle">Períodos para locar o ambiente</Typography>
        <Typography className="subTitle">{weekDay}</Typography>
      </S.Header>

      <S.AreaTimesWrapper>
        <S.TimeContainer>
          <Box className="inputsRow">
            <Typography className="labelText">Início</Typography>
            <Box>
              <Controller
                name="initial_time"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaskedInput
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    onChange={onChange}
                    value={value}
                    className="timeInput"
                    render={(ref, props) => (
                      <Box className="timeContainer">
                        <TextField
                          id="area-start-input"
                          className="timeInput"
                          placeholder="09:00"
                          {...register('initial_time')}
                          error={!!errors.initial_time}
                          inputRef={ref}
                          {...props}
                        />
                        <clock.ReactComponent className="clockIcon" />
                      </Box>
                    )}
                  />
                )}
              />
              <S.ErrorMessage>{errors.initial_time?.message}</S.ErrorMessage>
            </Box>
          </Box>

          <Box className="inputsRow">
            <Typography className="labelText">Término</Typography>
            <Box>
              <Controller
                name="final_time"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaskedInput
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    onChange={onChange}
                    value={value}
                    className="timeInput"
                    render={(ref, props) => (
                      <Box className="timeContainer">
                        <S.TimeInput
                          id="area-end-input"
                          className="timeInput"
                          placeholder="21:00"
                          {...register('final_time')}
                          error={!!errors.final_time}
                          inputRef={ref}
                          {...props}
                        />
                        <clock.ReactComponent className="clockIcon" />
                      </Box>
                    )}
                  />
                )}
              />
              <S.ErrorMessage>{errors.final_time?.message}</S.ErrorMessage>
            </Box>
          </Box>

          <Button id="area-include-btn" onClick={handleSubmit(addNewTime)} className="timeButton">
            Incluir horários
          </Button>
        </S.TimeContainer>

        {allTimes.length > 0 &&
          allTimes[getWeekDay()] &&
          allTimes[getWeekDay()].map(
            ({ initial_time, final_time }: { initial_time: string; final_time: string }) => (
              <S.TimeContainer key={`${initial_time}:${final_time}`}>
                <Box className="inputsRow">
                  <Typography className="labelText">Início</Typography>
                  <MaskedInput
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    className="timeInput"
                    render={(ref, props) => (
                      <Box className="timeContainer">
                        <TextField
                          disabled
                          value={initial_time}
                          className="timeInput"
                          placeholder={initial_time}
                          inputRef={ref}
                          {...props}
                        />
                        <clock.ReactComponent className="clockIcon" />
                      </Box>
                    )}
                  />
                </Box>
                <Box className="inputsRow">
                  <Typography className="labelText">Término</Typography>
                  <MaskedInput
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    className="timeInput"
                    render={(ref, props) => (
                      <Box>
                        <Box className="timeContainer">
                          <TextField
                            disabled
                            value={final_time}
                            className="timeInput"
                            placeholder={final_time}
                            inputRef={ref}
                            {...props}
                          />
                          <clock.ReactComponent className="clockIcon" />
                        </Box>
                        {initial_time > final_time && (
                          <Typography className="infoNextDayText">
                            Este horário refere-se ao próximo dia:{' '}
                            {allWeekDays[getWeekDay() + 1] || 'Domingo'}.
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Box>
                <IconButton
                  onClick={() => removeTime(initial_time, final_time)}
                  className="trashButton">
                  <trash.ReactComponent />
                </IconButton>
              </S.TimeContainer>
            )
          )}
      </S.AreaTimesWrapper>
    </S.Container>
  );
}
