/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useInfoModalStore, useLoadModalStore, useUnitsStore } from '../../../../store';
import { Errors } from '../../../../constants/Errors';

import { useUnitsController } from '../../Units.controller';

export const useRegisterModalController = (
  toogleModal: () => void,
  handleSetText: (text: string) => void,
  handleSuccessfully: (isSuccessfully: boolean) => void
) => {
  const { handleModalOpen } = useInfoModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { renameUnitRequest, unitName, unitID, unitsList } = useUnitsStore();

  const { getAllData } = useUnitsController();

  const schema = yup.object().shape({
    unit_name: yup
      .string()
      .matches(
        /^[\d]+([A-Za-z]+)?$/g,
        'É permitido apenas letras e números, sendo obrigatório começar com um número e sem caracteres especiais para nome da Unidade.'
      )
      .max(5, 'São permitidos apenas até 5 caracteres.')
      .required('Por favor, preencha o campo de unidade.')
      .trim()
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: { unit_name: unitName },
    resolver: yupResolver(schema)
  });

  window.onbeforeunload = () => {
    setValue('unit_name', unitName);
    return null;
  };

  const closeRenameModal = () => {
    toogleModal();
  };

  function unitNameAlreadyExists(unit_name: string) {
    let error = false;

    if (unitsList) {
      error = unitsList.some((unit) => unit.name.toLowerCase() === unit_name.toLowerCase());
    }

    if (error) toast.error('Já existe uma Unidade com esse nome cadastrada!');

    return error;
  }

  const onSubmitRegister = async () => {
    const { unit_name } = getValues();
    if (unitNameAlreadyExists(unit_name)) return;

    toogleLoading();
    try {
      await renameUnitRequest(unitID, unit_name.toUpperCase());

      handleSuccessfully(true);
      handleSetText('Unidade renomeada com sucesso');
      closeRenameModal();
      getAllData();
    } catch (err: any) {
      handleSuccessfully(false);
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
      handleModalOpen();
    }
  };

  const submit = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onSubmitRegister();
  };

  return {
    control,
    errors,
    handleSubmit,
    register,
    submit,
    closeRenameModal
  };
};
