import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { FormRequiredFields } from './LandingPage.props';
import { saveMessageContact } from '../../services/requests/landingPage/LandingPage.request';

const words: string[] = ['Simplifique', 'Administre', 'Conecte'];

const useLandingPageController = () => {
  const [currentWord, setCurrentWord] = useState(words[0]);
  const schema = yup.object().shape({
    name: yup.string().required('Por favor, informe seu nome.').trim(),
    email: yup.string().required('Por favor, informe seu email'),
    message: yup.string().required('Por favor, insira uma menssagem')
  });

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      name: '',
      email: '',
      message: ''
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  });

  const handleClick = async () => {
    const { email, message, name } = getValues();
    try {
      await saveMessageContact({
        email,
        message,
        name
      });

      setValue('email', '');
      setValue('name', '');
      setValue('message', '');

      toast.success('Menssagem enviada com sussesso');
    } catch (error: any) {
      toast.error('Messagem não enviada');
      throw new Error(error.message);
    }
  };

  function scrollToElement(idElement: string) {
    document.getElementById(idElement)?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (words.indexOf(currentWord) + 1) % words.length;
      setCurrentWord(words[nextIndex]);
    }, 2000);

    return () => clearInterval(interval);
  }, [currentWord]);

  return {
    register,
    control,
    getValues,
    handleSubmit,
    errors,
    handleClick,
    scrollToElement,
    currentWord
  };
};

export default useLandingPageController;
