import { Grid, Typography } from '@mui/material';
import { EditOutlined, ChromeReaderModeOutlined, DeleteOutline } from '@mui/icons-material';
import { Background } from './DocumentsFiles.style';

import {
  CustomSearchInput,
  ActionModal,
  DeleteModal,
  BackToLastPageModal,
  CustomButton,
  CustomTable,
  InfoModal
} from '../../components/index';
import { Modal } from './Modal/Modal';

import useDocumentsController from './DocumentsFiles.controller';
import { ModalName } from './ModalName/Modal';

export function DocumentsFiles() {
  const {
    isModalRegisterOpen,
    toggleRegisterModal,
    successModal,
    actionModalData,
    isOpenDeleteModal,
    toggleInforModal,
    toggleEditModal,
    rowsId,
    setIsModalRegisterOpen,
    tableHeaders,
    isModalEditOpen,
    totalPages,
    totalFiles,
    setIdFile,
    handleDrawerOpen,
    handleDeleteModalOpen,
    deleteFileOfRow,
    getAllFiles,
    folders,
    goBack,
    handleModalOpen,
    isSuccessfully,
    text,
    folderId,
    handleSetText,
    closeModal,
    folderName,
    sortFiles,
    changePage,
    handleSearch,
    isOpenInfoModal,
    handleSetIsSuccessfully,
    isOpenBackToLastPageModal
  } = useDocumentsController();

  return (
    <Background>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={500}>
            {folderName.toUpperCase() || 'Arquivos'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} />

        <Grid item xs={12} sm={12} md={5} lg={7}>
          <CustomSearchInput id="doc-file-search" handleSearch={handleSearch} />
        </Grid>
        <Grid item xs={12} sm={12} md={3.5} lg={2.5}>
          <CustomButton id="doc-file-send" onClick={toggleRegisterModal} text="Enviar arquivo" />
        </Grid>
        <Grid item xs={12} sm={12} md={3.5} lg={2.5}>
          <CustomButton onClick={goBack} text="Voltar para pastas" />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            sortFunc={sortFiles}
            rowsId={rowsId}
            tableHead={tableHeaders}
            tableData={folders}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalFiles}
            containId
            funcToChangePage={changePage}
            handleSetItemId={setIdFile}
            ActionModal={
              <ActionModal
                ActionModalData={actionModalData}
                ButtonsIcon={[<EditOutlined />, <ChromeReaderModeOutlined />, <DeleteOutline />]}
              />
            }
            noFilesFoundText="Não há nenhum registro de arquivos no momento."
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Tem certeza que deseja excluir este arquivo?"
          onClickDelete={() => deleteFileOfRow()}
          handleModalClose={handleDeleteModalOpen}
          isInfoModalOpen
        />
      )}

      <Modal
        foldId={{ id: folderId }}
        variant="register"
        isModalOpen={isModalRegisterOpen}
        setIsModalRegisterOpen={setIsModalRegisterOpen}
        toggleModal={toggleRegisterModal}
        backButton={toggleRegisterModal}
        getAllFiles={getAllFiles}
        onComplete={successModal}
        setText={handleSetText}
        setSuccess={handleSetIsSuccessfully}
        toggleSuccessModal={handleModalOpen}
      />

      <ModalName
        isModalOpen={isModalEditOpen}
        toggleModal={toggleEditModal}
        backButton={toggleEditModal}
        errorModal={toggleInforModal}
        foldId={folderId}
        getAllFiles={getAllFiles}
        title="Renomear arquivo"
        onComplete={successModal}
      />

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}

      {isOpenBackToLastPageModal && (
        <BackToLastPageModal
          goToPage={`/docs/listdocs/${folderId}`}
          handleModalClose={closeModal}
        />
      )}
    </Background>
  );
}
