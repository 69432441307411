import { Params } from 'react-router-dom';
import create from 'zustand';
import {
  deleteFile,
  renameFile,
  searchFiles,
  verifyFileName,
  sortFilesData,
  getFileById
} from '../../services/requests/files/Files.request';

import { FilesStoreProps, FilesRequestData } from './Files.props';

const initialState = {
  files: [],
  field: '',
  direction: 'DESC'
};

export const useFilesStore = create<FilesStoreProps>((set, get) => ({
  ...initialState,
  files: [],
  idFile: '',
  filesId: [],
  name: '',
  FileFinalizedUpload: [],

  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),

  getFile: async (file) => {
    const { files } = get();
    set({ files: [...files, file] });
  },

  setFileFinalized: (status: string, nameFile: string) => {
    const { FileFinalizedUpload } = get();
    set({ FileFinalizedUpload: [...FileFinalizedUpload, { status, nameFile }] });
  },

  getFileFinalized: () => {
    const { FileFinalizedUpload } = get();
    return FileFinalizedUpload;
  },

  getFiles: async () => {
    const { files } = get();
    return files;
  },

  setFiles: async () => {
    set({ files: [] });
  },

  setFileName: async (name: string) => {
    set({ name });
  },

  getFileName: () => {
    const { name } = get();
    return name;
  },

  setIdFile: async (id: string) => {
    set({ idFile: id });
  },

  handleSetFilesId: (filesId: string) => set((state) => ({ ...state, filesId })),

  getIdFile: () => {
    const { idFile } = get();
    return idFile;
  },

  deleteFileRequest: async (
    foldId: Readonly<Params<string>>,
    id: string,
    onComplete: () => void
  ): Promise<FilesRequestData> => {
    try {
      const data = await deleteFile(foldId, id);
      onComplete();
      return data as unknown as FilesRequestData;
    } catch (error: any) {
      throw new Error('error.message');
    }
  },

  renameFileRequest: async (id: string, name: string, idFile: string, onComplete: () => void) => {
    await renameFile(id, name, idFile);
    onComplete();
  },

  verifyFileNameRequest: async (id: string, name: string): Promise<boolean> => {
    const data = await verifyFileName(id, name);
    return data;
  },

  searchFileRequest: async (
    search,
    foldId,
    sortByField,
    sortByDirection,
    page
  ): Promise<FilesRequestData[]> => {
    const { data } = await searchFiles(search, foldId, page, sortByField, sortByDirection);
    return data as unknown as FilesRequestData[];
  },

  getFileById: async (id) => {
    try {
      const { data } = await getFileById(id);
      return data.data.path;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getFileNameById: async (id) => {
    try {
      const { data } = await getFileById(id);
      return data.data.name;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllFilesSortRequest: async (page, sortByField, sortByDirection, foldId, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortFilesData(page, sortByField, sortByDirection, foldId, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));
