import styled from 'styled-components';

const Container = styled.div`
  margin: 0 7px;
  height: 100vh;
  overflow: auto;
  padding-bottom: 70px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }

  ::-webkit-scrollbar-thumb {
    background: #662483;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  svg {
    margin-top: 5px;
  }

  .buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-block: -8px;
  }

  .listLinks {
    display: flex;
    flex-wrap: wrap;
  }

  .sidebarLink {
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: #61646b;
    max-width: 200px;

    span {
      padding-left: 10px;
      padding-right: 40px;
      padding-top: 5px;
    }
  }

  .sidebarSubLink {
    text-decoration: none;
    width: 100%;
    height: 100%;
  }

  .selectedSidebarLink {
    border-left: 2px solid #662483;
    background: #f7f5ff;

    span {
      color: #662483;
    }

    path {
      stroke: #662483;
    }
  }

  .noHasFunc {
    display: none;
  }
`;

export default Container;
