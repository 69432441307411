/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  InputLabel,
  TextField,
  Typography,
  Modal as ModalComponent,
  Button
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { useRegisterModalController } from './RenameUnitModal.controller';
import { ModalProps } from './RenameUnitModal.props';
import Background, { ModalContent } from './RenameUnitModal.style';

export function RenameUnitModal({
  isModalOpen,
  toggleModal,
  handleSetText,
  handleSuccessfully
}: ModalProps) {
  const { errors, register, submit, handleSubmit, closeRenameModal } = useRegisterModalController(
    toggleModal,
    handleSetText,
    handleSuccessfully
  );

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <Background>
        <Box>
          <ModalContent>
            <Box className="closeButton" onClick={closeRenameModal}>
              <CloseRounded />
            </Box>

            <Typography className="modalTitle">Renomear Unidade</Typography>

            <Box>
              <InputLabel className="inputTitle">Unidade</InputLabel>
              <Box className="inputContainer">
                <TextField
                  className="input"
                  type="text"
                  placeholder="Insira o novo nome da unidade"
                  {...register('unit_name')}
                  error={!!errors.unit_name}
                />
                {errors.unit_name && (
                  <Typography className="errorMessage">{errors.unit_name?.message}</Typography>
                )}
              </Box>
            </Box>

            <Box className="buttonArea">
              <Button
                id="unit-edit-save"
                style={{
                  backgroundColor: '#662483',
                  color: 'white',
                  width: '100%',
                  height: 48,
                  fontSize: '16px',
                  textTransform: 'capitalize'
                }}
                variant="contained"
                disableElevation
                onClick={handleSubmit(submit)}>
                Salvar
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#F0F1F5',
                  width: '100%',
                  height: 48,
                  fontSize: '16px',
                  textTransform: 'capitalize'
                }}
                disableElevation
                onClick={closeRenameModal}>
                Voltar
              </Button>
            </Box>
          </ModalContent>
        </Box>
      </Background>
    </ModalComponent>
  );
}
