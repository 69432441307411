export const status = [
  {
    value: 'inativo',
    label: 'Inativo'
  },
  {
    value: 'em_construcao',
    label: 'Em construção'
  },
  {
    value: 'concluido',
    label: 'Concluído'
  }
];

export const choiceType = [
  {
    value: 'Residencial',
    label: 'Residencial'
  },
  {
    value: 'Comercial',
    label: 'Comercial'
  }
];

export const tableHeaders = [
  'Nome do Empreendimento',
  'Localização',
  'Endereço',
  'Tipo de Imóvel',
  'Status',
  'Unidade',
  'Ações'
];

export const deleteData = {
  title: 'Tem certeza que deseja excluir o empreendimento?'
};

export const enterpriseDefaultValues = {
  id: '',
  incorporator: '',
  status: 'inativo',
  unit: '',
  name: '',
  propertyType: 'Residencial',
  number: '',
  complement: '',
  CEP: '',
  street: '',
  city: '',
  state: '',
  district: '',
  country: '',
  functionalitiesId: [{ name: '' }],
  colors: { primary: '', secondary: '' }
};

export const enterpriseWithoutValues = {
  id: '',
  incorporator: '',
  status: '',
  unit: '',
  name: '',
  adminEmail: '',
  propertyType: '',
  number: '',
  complement: '',
  CEP: '',
  street: '',
  city: '',
  state: '',
  district: '',
  country: '',
  functionalitiesId: [{ name: '' }],
  colors: { primary: '', secondary: '' },
  logo: '',
  manual: ''
};

export const functionalities = [
  'Entregas',
  'Informativos',
  'Ocorrências',
  'Parceiros',
  'Manutenções',
  'Visitantes',
  'Áreas/Reservas',
  'Votações',
  'Achados e Perdidos',
  'Boletos',
  'Documentos'
];

export const functionalitiesToRequest = [
  'encomendas',
  'informativos',
  'ocorrencias',
  'parceiros',
  'manutencoes',
  'visitantes',
  'areas/reservas',
  'votacoes',
  'achados/perdidos',
  'boletos',
  'documentos'
];

export const initialTableData = [
  {
    name: '',
    location: '',
    address: '',
    propertyType: '',
    status: '',
    unit: ''
  }
];
