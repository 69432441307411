/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, TextField, Typography, Modal as ModalComponent, Button } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import { useRegisterModalController } from './RegisterUnitModal.controller';
import { ModalProps } from './RegisterUnitModal.props';
import Background, { ModalContent } from './RegisterUnitModal.style';

const trashIcon = require('../../../../assets/images/trash2.svg');
const buildingIcon = require('../../../../assets/images/enterprisesicon.svg');

export function RegisterUnitModal({
  isModalOpen,
  toggleModal,
  handleSetText,
  handleSuccessfully
}: ModalProps) {
  const {
    control,
    errors,
    allUnits,
    handleSetUnit,
    handleRemoveUnit,
    handleSubmit,
    register,
    submit,
    openCloseRegisterModal,
    closePartnersModal
  } = useRegisterModalController(toggleModal, handleSetText, handleSuccessfully);

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <Background>
        <ModalContent>
          <Box className="closeButton" onClick={openCloseRegisterModal}>
            <CloseRounded />
          </Box>

          <Typography className="modalTitle">Criação de Unidades</Typography>
          <Typography className="inputTitle">Unidade</Typography>

          <Box className="inputsRow">
            <Box>
              <Controller
                name="unit_name"
                control={control}
                render={() => (
                  <Box className="unitInputContainer">
                    <Box className="unitContainer">
                      <TextField
                        id="unit-input-add"
                        className="unitInput"
                        placeholder="Adicionar Unidade"
                        {...register('unit_name')}
                        error={!!errors.unit_name}
                      />
                      <buildingIcon.ReactComponent className="buildingIcon" />
                    </Box>

                    <Box className="buttonContainer">
                      <Button
                        id="unit-button-add"
                        style={{
                          backgroundColor: '#662483',
                          color: 'white',
                          width: 220,
                          height: 44,
                          fontSize: '16px',
                          marginBottom: '10px',
                          textTransform: 'capitalize'
                        }}
                        variant="contained"
                        disableElevation
                        onClick={handleSubmit(handleSetUnit)}>
                        Incluir Unidade
                      </Button>
                    </Box>
                  </Box>
                )}
              />
              {errors.unit_name && (
                <Typography className="errorMessage">{errors.unit_name?.message}</Typography>
              )}
            </Box>
          </Box>
          <Box className="contentContainer">
            {allUnits &&
              allUnits.map((unit: { unit_name: string }) => (
                <Box className="inputsRow" key={unit.unit_name}>
                  <Box>
                    <Box className="unitInputContainer">
                      <Box className="unitContainer">
                        <TextField
                          className="unitInput"
                          placeholder="Adicionar Bloco"
                          value={unit.unit_name}
                          disabled
                        />
                        <buildingIcon.ReactComponent className="buildingIcon" />
                      </Box>

                      <Box
                        className="trashContainer"
                        onClick={() => handleRemoveUnit(unit.unit_name)}>
                        <trashIcon.ReactComponent className="trashIcon" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>

          <Box className="buttonArea">
            <Button
              id="unit-button-save"
              style={{
                backgroundColor: '#662483',
                color: 'white',
                width: '100%',
                height: 55,
                fontSize: '16px',
                marginBottom: '10px',
                textTransform: 'capitalize',
                opacity: !allUnits || allUnits?.length === 0 ? '0.8' : '1'
              }}
              variant="contained"
              disableElevation
              disabled={!allUnits || allUnits?.length === 0}
              onClick={submit}>
              Salvar
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#F0F1F5',
                width: '100%',
                fontSize: '16px',
                height: 55,
                textTransform: 'capitalize'
              }}
              disableElevation
              onClick={closePartnersModal}>
              Voltar
            </Button>
          </Box>
        </ModalContent>
      </Background>
    </ModalComponent>
  );
}
