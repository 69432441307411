import create from 'zustand';
import { PaymentsSlipProps } from './PaymentSlip.props';

import {
  deletePaymentFolder,
  editPaymentFolder,
  getPaymentFolderById,
  sortPaymentFolderData,
  searchPaymentFolder,
  registerPaymentFolder,
  sortPaymentFileData,
  registerPaymentFile,
  editPaymentFile,
  deletePaymentFile,
  deletePaymentFileFromStore,
  confirmPayment,
  unconfirmPayment,
  getPaymentFileById,
  searchPaymentFile
} from '../../services/requests/paymentSlip/paymentSlip.request';

const initialState = {
  folderId: '',
  foldersIds: [],
  folderList: [],
  folderName: undefined,

  fileId: undefined,
  isPaidStatus: false,
  filesIDs: undefined,
  file: undefined,
  fileList: undefined,
  fileUrlIDList: undefined,

  field: '',
  direction: '',
  isModalRegisterOpen: false,
  isModalEditOpen: false,
  totalItems: 0,
  totalPages: 1
};

const usePaymentSlipStore = create<PaymentsSlipProps>((set, get) => ({
  ...initialState,

  setFolderId: (folderId) => set((state) => ({ ...state, folderId })),
  setFoldersIds: (foldersIds) => set((state) => ({ ...state, foldersIds })),
  setFolderList: (folderList) => set((state) => ({ ...state, folderList })),
  setFolderName: (folderName) => set((state) => ({ ...state, folderName })),

  setFileId: (fileId) => set((state) => ({ ...state, fileId })),
  setIsPaidStatus: (isPaidStatus) => set((state) => ({ ...state, isPaidStatus })),
  setFilesIDs: (filesIDs) => set((state) => ({ ...state, filesIDs })),
  setFile: (file) => set((state) => ({ ...state, file })),
  setFileList: (fileList) => set((state) => ({ ...state, fileList })),
  setFileUrlIdList: (fileUrlIDList) => set((state) => ({ ...state, fileUrlIDList })),

  setIsModalRegisterOpen: (isModalRegisterOpen) =>
    set((state) => ({ ...state, isModalRegisterOpen })),
  setIsModalEditOpen: (isModalEditOpen) => set((state) => ({ ...state, isModalEditOpen })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  setTotalItems: (totalItems) => set((state) => ({ ...state, totalItems })),
  setTotalPages: (totalPages) => set((state) => ({ ...state, totalPages })),

  // Folder requests
  registerPaymentSlipRequest: async (paymentSlipData) => {
    try {
      await registerPaymentFolder(paymentSlipData);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  deletePaymentSlipRequest: async (id) => {
    try {
      await deletePaymentFolder(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  editPaymentSlipRequest: async (paymentSlipData, id) => {
    try {
      await editPaymentFolder(paymentSlipData, id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getPaymentFolderByIdRequest: async (paymentSlipId) => {
    try {
      const data = await getPaymentFolderById(paymentSlipId);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllPaymentFoldersRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortPaymentFolderData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message || 'Erro desconhecido');
    }
  },

  searchPaymentFolderRequest: async (paymentSlipData, sortByField, sortByDirection, page) => {
    try {
      const data = await searchPaymentFolder(paymentSlipData, page, sortByField, sortByDirection);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  // Files requests
  registerPaymentSlipFileRequest: async (paymentSlipData) => {
    try {
      await registerPaymentFile(paymentSlipData);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  deletePaymentSlipFileRequest: async (id) => {
    try {
      await deletePaymentFile(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  deleteFileFromStoreRequest: async (id) => {
    try {
      await deletePaymentFileFromStore(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  confirmPaymentRequest: async (id) => {
    try {
      await confirmPayment(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  unconfirmPaymentRequest: async (id) => {
    try {
      await unconfirmPayment(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  editPaymentSlipFileRequest: async (paymentSlipData, id) => {
    try {
      await editPaymentFile(paymentSlipData, id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getPaymentSlipFileById: async (id) => {
    try {
      const data = await getPaymentFileById(id);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllPaymentSlipRequest: async (page, sortByField, sortByDirection, searchString, folderId?) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortPaymentFileData(
        page,
        sortByField,
        sortByDirection,
        searchString,
        folderId
      );
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchPaymentSlipRequest: async (paymentSlipData) => {
    try {
      const data = await searchPaymentFile(paymentSlipData);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));

export { usePaymentSlipStore };
