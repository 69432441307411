/* eslint-disable @typescript-eslint/no-var-requires */
import { Box } from '@mui/material';
import usePreviewImageController from './PreviewImage.controller';
import Background from './PreviewImage.styled';

const closeIcon = require('../../../../assets/images/close.svg');

const PreviewImage = () => {
  const { image, handleImagePreviewOpen } = usePreviewImageController();
  return (
    <Background>
      <Box className="Container">
        <Box className="imageContainer">
          <Box className="closeButton" onClick={handleImagePreviewOpen}>
            <closeIcon.ReactComponent />
          </Box>
          <img
            src={image}
            alt="preview"
            width="auto"
            height="auto"
            style={{
              borderRadius: '16px',
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          />
        </Box>
      </Box>
    </Background>
  );
};

export default PreviewImage;
