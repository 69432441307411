import { useEffect, useState } from 'react';
import { useAuthStore, useInfoModalStore } from '../store';

function useRoutesController() {
  const storagedToken = localStorage.getItem('token');

  const { handleModalOpen, isOpenInfoModal } = useInfoModalStore();
  const { accessToken } = useAuthStore();

  const [isAuthenticated, setIsAuthenticated] = useState(!!accessToken || !!storagedToken);
  const [isOffline, setIsOffline] = useState(false);

  window.addEventListener('offline', () => {
    setIsOffline(true);
  });

  function showInternetErrorMessage() {
    if (isOffline) handleModalOpen();
  }

  useEffect(() => {
    setIsAuthenticated(!!accessToken || !!storagedToken);
  }, [storagedToken, accessToken]);

  useEffect(() => {
    showInternetErrorMessage();
  }, [isOffline]);

  return { isAuthenticated, isOpenInfoModal, handleModalOpen, isOffline };
}

export default useRoutesController;
