import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatData } from '../../helpers/formatData';
import {
  useActionModalStore,
  useSortItemsStore,
  useDeleteModalStore,
  useLoadModalStore,
  useBlocksStore,
  useInfoModalStore,
  useEnterpriseStore,
  usePaginationStore
} from '../../store';

import { Errors } from '../../constants/Errors';

import { blocksTableHead } from '../../constants/BlocksData';
import { BlockFormRequiredFields, RowProps } from './Blocks.props';

export function useBlocksController() {
  const { handleSetSortFields } = useSortItemsStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { isOpenInfoModal, handleModalOpen } = useInfoModalStore();
  const { currentPage } = usePaginationStore();
  const { getEnterpriseNameIdListRequest } = useEnterpriseStore();

  const [allEnterpriseData, setAllEnterpriseData] = useState([{ value: '', label: '' }]);
  const [blockDataToRename, setBlockDataToRename] = useState({ id: '', name: '' });
  const [searchString, setSearchString] = useState('');
  const [inputSearchValue, setInputSearchValue] = useState('');
  const navigate = useNavigate();

  const {
    field,
    direction,
    // enterprise
    enterpriseID,
    selectedEnterprise,
    setSelectedEnterprise,
    setEnterpriseID,
    setEnterpriseName,
    // block
    blockID,
    blocksIDs,
    blocksList,
    setBlockName,
    setBlockID,
    setBlocksIDs,
    setBlocksList,
    // page and counts
    totalBlocks,
    totalPages,
    setTotalBlocks,
    setTotalPages,
    // modal and messages
    isSuccessfully,
    successMessage,
    isOpenRegisterModal,
    isOpenRenameModal,
    setIsSuccessfully,
    setSuccessMessage,
    setOpenRegisterModal,
    setOpenRenameModal,
    // requests
    searchBlockRequest,
    deleteBlockRequest,
    getAllSortBlocksDataRequest
  } = useBlocksStore();

  const blocksSchema = yup.object().shape({
    enterprise: yup.string().notRequired()
  });

  useForm<BlockFormRequiredFields>({
    defaultValues: { enterprise: 'Selecione' },
    resolver: yupResolver(blocksSchema)
  });

  function handleEdit(row: { id: string; blockName: string }) {
    setBlockDataToRename({ id: row.id, name: row.blockName });
    setOpenRenameModal(true);
  }

  function handleNavigateToUnits(row: { id: string; blockName: string }) {
    const entName = allEnterpriseData.filter((enterprise) => enterprise.value === enterpriseID)[0]
      .label;
    setEnterpriseName(entName);
    setBlockName(row.blockName);
    navigate(`${row.id}`);
  }

  function handleActionModal(row: RowProps) {
    return [
      {
        ButtonsText: 'Ir para Unidades',
        OnClick: () => {
          handleNavigateToUnits(row);
        }
      },
      {
        ButtonsText: 'Renomear',
        OnClick: () => {
          handleEdit(row);
        }
      },
      {
        ButtonsText: 'Excluir Bloco',
        OnClick: () => {
          handleDeleteModalOpen();
          handleDrawerOpen();
        }
      }
    ];
  }

  // helper functions for requests
  function formatRequestData(data: typeof blocksList) {
    const formatedRowsId = data.map((item: { id: string }) => {
      return item.id;
    });

    const formatedData = data.map(
      (item: { id: string; name: string; units: string[]; createdAt: string }) => {
        return {
          id: item.id,
          blockName: item.name,
          unitsAmount: `${item.units.length} unidades`,
          createdAt: formatData(item.createdAt)
        };
      }
    );

    setBlocksList(formatedData);
    setBlocksIDs(formatedRowsId);

    return formatedData;
  }

  function setBlockAndPageData(data: { totalPages: number; totalBlocks: number }) {
    setTotalPages(data.totalPages || 1);
    setTotalBlocks(data.totalBlocks || 0);
  }

  // requests
  async function handleSearch(value: string) {
    setSearchString(value);
    const formatedDataForSearch = { queryText: value };
    const { data } = await searchBlockRequest(
      formatedDataForSearch,
      field,
      direction,
      String(currentPage)
    );

    if (data) {
      formatRequestData(data.data);
      setBlockAndPageData(data);
    }
  }

  async function getAllData(enterpriseId: string) {
    const data = await getAllSortBlocksDataRequest(
      String(currentPage),
      field,
      direction,
      enterpriseId,
      searchString
    );
    setEnterpriseID(enterpriseId);

    if (data) {
      formatRequestData(data.data.data);
      setBlockAndPageData(data.data);
    }
  }

  async function sortDataRequest(sortPage: string, sortByField: string, sortByDirection: string) {
    const data = await getAllSortBlocksDataRequest(
      sortPage,
      sortByField,
      sortByDirection,
      enterpriseID,
      searchString
    );

    setBlockAndPageData(data.data);
    const formatedData = formatRequestData(data.data.data);
    const { totalBlocks: totalData, totalPages: pages } = data.data;

    return { data: { data: formatedData, totalData, pages } };
  }

  async function changePage(pageNumber: number) {
    toogleLoading();
    try {
      const { data } = await getAllSortBlocksDataRequest(
        `${pageNumber}`,
        field,
        direction,
        enterpriseID,
        searchString
      );

      if (data) {
        formatRequestData(data.data);
        setBlockAndPageData(data);
      }
    } catch (err: any) {
      setSuccessMessage(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
    }
  }

  async function getAllEnterpriseData() {
    toogleLoading();
    try {
      const data = await getEnterpriseNameIdListRequest();
      setAllEnterpriseData(data);
    } finally {
      toogleLoading();
    }
  }

  async function onSubmitDelete(id: string) {
    toogleLoading();
    try {
      await deleteBlockRequest(id);
      setIsSuccessfully(true);
      setSuccessMessage('Bloco excluído com sucesso');
      await getAllData(enterpriseID);
    } catch (error: any) {
      setSuccessMessage(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      setIsSuccessfully(false);
    } finally {
      toogleLoading();
      handleModalOpen();
    }
  }

  function openRegisterBlockModal() {
    setOpenRegisterModal(true);
  }

  function setSetSortFields() {
    handleSetSortFields(['block_model.name', 'block_model.units', 'block_model.createdAt']);
  }

  function handleSearchInput(value: string) {
    setInputSearchValue(value);
  }

  useEffect(() => {
    setSetSortFields();
    getAllEnterpriseData();
  }, []);

  useEffect(() => {
    if (isOpenRegisterModal || isOpenRenameModal) return;
    if (selectedEnterprise?.value) getAllData(selectedEnterprise.value);
  }, [selectedEnterprise]);

  function closeInfoModal() {
    handleModalOpen();
    setIsSuccessfully(false);
    setSuccessMessage('');
  }

  // useEffect(() => {
  //   handleSetCurrentPage(currentPage);
  //   changePage(currentPage);
  // }, [currentPage]);

  return {
    // page
    blocksTableHead,
    // enterprise
    selectedEnterprise,
    allEnterpriseData,
    setSelectedEnterprise,
    inputSearchValue,
    handleSearchInput,
    // block
    blocksList,
    blockDataToRename,
    blockID,
    blocksIDs,
    setBlockID,
    // page and counts
    totalBlocks,
    totalPages,
    // modal and messages
    isOpenDeleteModal,
    isOpenInfoModal,
    isOpenRegisterModal,
    isOpenRenameModal,
    isSuccessfully,
    successMessage,
    handleDeleteModalOpen,
    openRegisterBlockModal,
    handleDrawerOpen,
    closeInfoModal,
    handleActionModal,
    // requests
    handleSearch,
    getAllData,
    sortDataRequest,
    onSubmitDelete,
    changePage
  };
}

export default useBlocksController;
