import { BrowserRouter } from 'react-router-dom';

import useRoutesController from './Routes.controller';

import AuthRoutes from './AuthRoutes/AuthRoutes';
import HomeRoutes from './HomeRoutes/HomeRoutes';

import { InfoModal, LoadingScreen } from '../components';
import { useLoadModalStore } from '../store';

export default function Routes() {
  const { isAuthenticated, isOpenInfoModal, handleModalOpen, isOffline } = useRoutesController();
  const { isOpenLoad } = useLoadModalStore();
  return (
    <BrowserRouter>
      {isOpenLoad && <LoadingScreen />}

      {isAuthenticated ? <HomeRoutes /> : <AuthRoutes />}

      {isOpenInfoModal && isOffline && (
        <InfoModal
          isSuccessfully={false}
          onClick={handleModalOpen}
          text="Oops! Sua conexão caiu. Tente novamente!"
        />
      )}
    </BrowserRouter>
  );
}
