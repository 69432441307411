import { useNavigate } from 'react-router-dom';
import { useBackToLastPageModalStore, useRefreshChangedPagesStore } from '../../store';

function useBackToLastPageModalController(goToPage: string, handleModalClose?: () => void) {
  const { handleBackToLastPageModalOpen } = useBackToLastPageModalStore();
  const { setChangedPage } = useRefreshChangedPagesStore();
  const navigate = useNavigate();

  function goBackToEnterprisePage() {
    handleBackToLastPageModalOpen();
    if (handleModalClose) handleModalClose();
    setChangedPage(false);
    navigate(goToPage, { replace: true });
  }

  return {
    handleBackToLastPageModalOpen,
    goBackToEnterprisePage
  };
}

export default useBackToLastPageModalController;
