import create from 'zustand';

import { AdminORProps } from './AdminOR.props';

const initialState = {
  name: 'Fulano de tal',
  occupation: 'Funcionário',
  email: '',
  enterpriseIdOfManager: '',
  id: ''
};

const useAdminORStore = create<AdminORProps>((set) => ({
  ...initialState,

  handleSetName: (name) => set((state) => ({ ...state, name })),
  handleSetOccupation: (occupation) => set((state) => ({ ...state, occupation })),
  handleSetEmail: (email) => set((state) => ({ ...state, email })),
  handleSetEnterpriseIdOfManager: (id) => set((state) => ({ ...state, enterpriseIdOfManager: id })),
  handleSetId: (id) => set((state) => ({ ...state, id }))
}));

export { useAdminORStore };
