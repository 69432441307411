import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAdminORStore, useBackToLastPageModalStore, useEnterpriseStore } from '../../../store';
import { useVotingStore } from '../../../store/Voting/Voting.store';
import { FormRequiredFields, ModalControllerProps } from './Modal.props';
import { formatData } from '../../../helpers/formatData';
import { Errors } from '../../../constants/Errors';

export const useModalController = (
  variant: string,
  onComplete: () => void,
  toggleModal: () => void,
  isModalOpen: boolean,
  toggleSuccessModalOpen: () => void,
  setTitle: (title: string) => void,
  setSuccess: (isSuccess: boolean) => void
): ModalControllerProps => {
  const [values, setValues] = useState('');
  const [votings, setVotings] = useState('');
  const [, setIsFocused] = useState(false);
  const { email } = useAdminORStore();
  const [allQuestions, setAllQuestions] = useState<string[]>([]);
  const [editValue, setEditValue] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [isIndex, setIndex] = useState(100000);

  const { id } = useEnterpriseStore();
  const { handleBackToLastPageModalOpen } = useBackToLastPageModalStore();
  const { createVoteRequest, editVoteRequest, getVotingDataByIdRequest } = useVotingStore();

  const schema = yup.object().shape({
    subject: yup
      .string()
      .required('Por favor, preencher campo assunto.')
      .max(50, 'É permitido até 50 caracteres para o assunto da votação.'),
    description: yup.string().required('Por favor, preencher campo descrição.'),
    date: yup.string().required('Por favor, preencher campo data.'),
    options: yup
      .string()
      .test('Por favor, preencher o campo opção.', 'Por favor, preencher o campo opção.', () => {
        if (allQuestions.length > 1) {
          return true;
        }
        if (allQuestions.length === 0) {
          return false;
        }
        return true;
      })
  });

  const {
    handleSubmit,
    control,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      subject: '',
      description: '',
      date: '',
      options: ''
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const resetVotingData = () => {
    setValue('date', '');
    setValue('description', '');
    setValue('subject', '');
    setValue('options', '');
    setAllQuestions([]);
  };

  const sendRequest = async () => {
    const { subject, description, date } = getValues();

    if (
      !date.match(/^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/-]\d{4}$/) ||
      new Date() > new Date(date)
    ) {
      setTitle('Data inválida');
      setSuccess(false);
      toggleSuccessModalOpen();
      return;
    }

    if (variant === 'criada') {
      try {
        setDisableButton(true);
        await createVoteRequest({
          subject,
          description,
          options: allQuestions,
          endDate: date,
          email
        });
        setSuccess(true);
        setTitle('Votação criada com sucesso!');
        resetVotingData();
        toggleModal();
      } catch (error: any) {
        setTitle(
          (Errors as any)({})[String(error.message).trim()] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        setSuccess(false);
      } finally {
        setDisableButton(false);
        toggleSuccessModalOpen();
        onComplete();
      }
    }

    if (variant === 'editada') {
      try {
        setDisableButton(true);
        await editVoteRequest(id, {
          subject,
          description,
          options: allQuestions,
          endDate: date,
          email
        });
        setSuccess(true);
        setTitle('Votação editada com sucesso!');
        onComplete();
        resetVotingData();
        toggleModal();
      } catch (error: any) {
        setTitle(
          (Errors as any)({})[String(error.message).trim()] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        setSuccess(false);
      } finally {
        setDisableButton(false);
        toggleSuccessModalOpen();
        onComplete();
      }
    }
  };

  const getAllDataOfVoting = async () => {
    try {
      if (!variant || variant === 'criada') {
        resetVotingData();
        return;
      }
      const { data } = await getVotingDataByIdRequest(id);
      setValue('date', formatData(data.endDate));
      setValue('description', data.description);
      setAllQuestions(data.options);
      setValue('subject', data.subject);
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleRadioChange = (a: string) => {
    if (a.trim() !== '') {
      setAllQuestions((prev: string[]) => [...prev, a]);

      setValues('');
    } else {
      setValues('');
    }
  };

  const handleDelete = (index: number) => {
    setAllQuestions(allQuestions.filter((item: string) => item !== allQuestions[index]));
  };

  const handleEdit = (question: string) => {
    setEditValue(question);
    setAllQuestions(allQuestions.filter((item: string) => item !== allQuestions[Number(question)]));
  };

  const handleEditChangeForIndex = (index: number, question: string) => {
    if (question.trim() !== '') {
      const newArray = [...allQuestions];
      newArray[index] = question;
      setAllQuestions(newArray);
    } else {
      setAllQuestions(allQuestions.filter((item: string) => item !== allQuestions[index]));
    }
  };

  const closeVotingModal = () => {
    if (variant === 'visualizada') {
      toggleModal();
      return;
    }

    const { date, description, options, subject } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    date || description || options || subject ? handleBackToLastPageModalOpen() : toggleModal();
  };

  useEffect(() => {
    getAllDataOfVoting();
  }, [isModalOpen]);

  return {
    allQuestions,
    votings,
    setVotings,
    editValue,
    handleEdit,
    setEditValue,
    setIndex,
    isIndex,
    handleEditChangeForIndex,
    handleDelete,
    values,
    setValues,
    handleRadioChange,
    setIsFocused,
    disableButton,
    errors,
    control,
    handleSubmit,
    sendRequest,
    register,
    reset,
    closeVotingModal
  };
};
