import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: 90px;
  padding-left: 32px;
  padding-right: 44px;
  padding-bottom: 44px;

  .inputLabel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #2c2c2c;
    margin-bottom: 8px;
  }

  .titleInline {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8b8b8b;
  }

  .closeIconContainer {
    position: absolute;
    bottom: 10px;
    right: 25px;
    cursor: pointer;

    svg,
    path {
      width: 13px;
      stroke: #8c8c8c;
      fill: #8c8c8c;
      z-index: 100;
    }
  }

  .radioContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    input {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  .errorMessage {
    color: red;
    font-size: 12px;
  }

  .radioInputContainer {
    display: flex;
    flex-direction: row;
    gap: 20%;
  }

  .blockUnity {
    margin-top: -20px;
  }

  .radioInputLabel {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #2c2c2c;
  }

  .valueInput {
    flex: 1;
    width: 100%;
    background-color: transparent;

    input {
      height: 16px;
      margin-top: 1px;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }
  }

  .selectInput {
    width: 100%;
    background-color: transparent;

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }

    div {
      display: flex;
      align-items: center;
      height: 49px !important;
    }
  }

  .selectInputLabel {
    position: absolute;
    top: 13px;
    left: 15px;
  }

  .selectInputLabelUnit {
    position: absolute;
    top: 12px;
    left: 15px;
  }

  .selectInputLabelContainer {
    position: relative;
  }

  .subTitle {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20.8px;
    line-height: 26px;
    letter-spacing: 0.13px;
    color: #2c2c2c;
  }

  .descriptionInput {
    width: 100%;

    fieldset {
      border-radius: 12px;
      border: 1px solid #8b8b8b;
    }
  }

  .fileInputText {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #8b8b8b;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .saveButton {
    padding: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 0px;
    text-transform: none;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #662483;
    border-radius: 8px;
    color: #fff;
  }

  .saveButton:hover {
    opacity: 0.9;
    color: #fff;
    background: #662483;
  }

  .backButton {
    padding: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 0px;
    text-transform: none;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f1f5;
    border-radius: 8px;
    color: #2c2c2c;
  }

  .backButton:hover {
    opacity: 0.9;
  }

  .backAndSaveContainer {
    display: flex;
    gap: 20px;
    width: 500px;
  }

  .unitsContainer {
    display: flex;
    gap: 10px;
  }

  .unitContainer {
    width: 66px;
    height: 32px;
    background: #f3f3f3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    svg {
      width: 12px;
      path {
        cursor: pointer;
        fill: #8b8b8b;
      }
    }
  }
`;

export default Container;
