/* eslint-disable no-nested-ternary */
import create from 'zustand';
import { IMaintenanceStoreProps } from './Maintenance.props';
import {
  sortMaintenanceData,
  registerMaintenance,
  searchMaintenances,
  deleteMaintenance,
  changeStatusMaintenance,
  editMaintenance,
  getMaintenanceDetailsById,
  getAllEnterprises,
  getBlocksUnitsList,
  getImageToRequest,
  removeImageToRequest,
  getIdForToken
} from '../../services/requests/maintenance/Maintenance.request';
import { formatData } from '../../helpers/formatData';

function formatType(type: string) {
  let formatedType = '';
  switch (type) {
    case 'Corrective':
      formatedType = 'Corretiva';
      break;
    case 'Preventive':
      formatedType = 'Preventiva';
      break;
    default:
      break;
  }
  return formatedType;
}

function formatStatus(status: string) {
  let formatedStatus = '';
  switch (status) {
    case 'Pendent':
      formatedStatus = 'PENDENTE';
      break;
    case 'InProgress':
      formatedStatus = 'EM ANDAMENTO';
      break;
    case 'Late':
      formatedStatus = 'ATRASADO';
      break;
    case 'Filed':
      formatedStatus = 'ATRASADO';
      break;
    case 'Completed':
      formatedStatus = 'CONCLUÍDO';
      break;

    default:
      break;
  }
  return formatedStatus;
}

const initialState = {
  maintenanceID: '',
  maintenancesIDs: [],
  maintenancesList: [],
  enterpriseList: undefined,
  blocksList: undefined,
  unitsList: undefined,
  isInfoModalOpen: false,
  isFinishModalOpen: false,
  isMaintenanceInProgressModalOpen: false,
  page: '1',
  field: 'maintenance_model.status',
  direction: 'ASC',
  images: [],
  formatedImageToRequest: [],
  imageToPreview: '',
  imageName: '',
  imageSize: '',
  imageType: '',
  imagesToDelete: [],
  filesToGetLink: []
};

export const useMaintenancesStore = create<IMaintenanceStoreProps>((set, get) => ({
  ...initialState,

  setImageName: (imageName) => set((state) => ({ ...state, imageName })),
  setImageSize: (imageSize) => set((state) => ({ ...state, imageSize })),
  setImageType: (imageType) => set((state) => ({ ...state, imageType })),
  handleSetImagesToDelete: (imagesToDelete) => set((state) => ({ ...state, imagesToDelete })),
  setMaintenanceID: (maintenanceID) => set((state) => ({ ...state, maintenanceID })),
  setMaintenancesIDs: (maintenancesIDs) => set((state) => ({ ...state, maintenancesIDs })),
  setMaintenancesList: (maintenancesList) => set((state) => ({ ...state, maintenancesList })),
  setEnterpriseList: (enterpriseList) => set((state) => ({ ...state, enterpriseList })),
  setBlocksList: (blocksList) => set((state) => ({ ...state, blocksList })),
  handleSetFilesToGetLink: (filesToGetLink) => set((state) => ({ ...state, filesToGetLink })),
  setUnitList: (unitsList) => set((state) => ({ ...state, unitsList })),
  setIsInfoModalOpen: (isInfoModalOpen) => set((state) => ({ ...state, isInfoModalOpen })),
  setIsFinishModalOpen: (isFinishModalOpen) => set((state) => ({ ...state, isFinishModalOpen })),
  setIsMaintenanceInProgressModalOpen: (isMaintenanceInProgressModalOpen) =>
    set((state) => ({ ...state, isMaintenanceInProgressModalOpen })),
  handleSetPage: (page) => set((state) => ({ ...state, page })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  getImageLinkRequest: async (pictureData, id) => {
    try {
      const { data } = await getImageToRequest(pictureData, id);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  setImageToPreview: (imageToPreview) => set((state) => ({ ...state, imageToPreview })),
  handleSetImages: (images) => set((state) => ({ ...state, images })),
  handleSetImagesToRequest: (formatedImageToRequest) =>
    set((state) => ({
      ...state,
      formatedImageToRequest
    })),
  getAllSortMaintenanceDataRequest: async (
    page,
    sortByField,
    sortByDirection,
    isAdmin,
    searchString
  ) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortMaintenanceData(
        page,
        sortByField,
        sortByDirection,
        isAdmin,
        searchString
      );
      const formatedData = isAdmin
        ? data.data.data.map((item) => {
            return {
              id: item.id,
              enterprise: item.enterprise.name,
              type: formatType(item.type),
              block: item.unit ? item.unit.block.name : item.block ? item.block.name : '-',
              name: item.name,
              unit: item.unit ? item.unit.name : '-',
              date: item.date ? formatData(item.date) : '-',
              status: formatStatus(item.status)
            };
          })
        : data.data.data.map((item) => {
            return {
              id: item.id,
              type: formatType(item.type),
              block: item.unit ? item.unit.block.name : item.block ? item.block.name : '-',
              name: item.name,
              unit: item.unit ? item.unit.name : '-',
              date: item.date ? formatData(item.date) : '-',
              status: formatStatus(item.status)
            };
          });

      const { totalMaintenances, totalPages } = data.data;

      return { data: { data: formatedData, totalMaintenances, totalPages } };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  changeStatusMaintenance: async (id, status, isAdmin, data): Promise<void> => {
    await changeStatusMaintenance(id, status, isAdmin, data);
  },

  handleRemoveMaintenance: async (maintenanceId) =>
    set((state) => ({
      ...state,
      maintenancesList: state.maintenancesList.filter(
        (maintenance: { id: string }) => maintenance.id !== maintenanceId
      ),
      maintenancesIDs: state.maintenancesIDs.filter((id: string) => id !== maintenanceId)
    })),

  registerMaintenanceRequest: async (maintenanceData, isAdmin) => {
    try {
      await registerMaintenance(maintenanceData, isAdmin);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchMaintenanceRequest: async (
    maintenanceData,
    isAdmin,
    sortByField,
    sortByDirection,
    page
  ) => {
    try {
      const data = await searchMaintenances(
        maintenanceData,
        isAdmin,
        sortByField,
        sortByDirection,
        page
      );

      const formatedData = isAdmin
        ? data.data.data.map((item) => {
            return {
              id: item.id,
              enterprise: item.enterprise.name,
              type: formatType(item.type),
              block: item.unit ? item.unit.block.name : item.block ? item.block.name : '-',
              name: item.name,
              unit: item.unit ? item.unit.name : '-',
              date: item.date ? formatData(item.date) : '-',
              status: formatStatus(item.status)
            };
          })
        : data.data.data.map((item) => {
            return {
              id: item.id,
              type: formatType(item.type),
              block: item.unit ? item.unit.block.name : item.block ? item.block.name : '-',
              name: item.name,
              unit: item.unit ? item.unit.name : '-',
              date: item.date ? formatData(item.date) : '-',
              status: formatStatus(item.status)
            };
          });

      const { totalMaintenances, totalPages } = data.data;

      return { data: { data: formatedData, totalMaintenances, totalPages } };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  deleteMaintenanceRequest: async (id, isAdmin) => {
    try {
      await deleteMaintenance(id, isAdmin);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  editMaintenanceRequest: async (areaData, id, isAdmin) => {
    try {
      await editMaintenance(areaData, id, isAdmin);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getIdForTokenRequest: async (token) => {
    try {
      const data = await getIdForToken(token);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getMaintenanceDetailsByIdRequest: async (maintenanceId, isAdmin) => {
    try {
      const data = await getMaintenanceDetailsById(maintenanceId, isAdmin);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getEnterprisesList: async () => {
    try {
      const data = await getAllEnterprises();
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getBlocksUnitsList: async (enterpriseId) => {
    try {
      const data = await getBlocksUnitsList(enterpriseId);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  removeImageRequest: async (id) => {
    try {
      await removeImageToRequest(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  handleSetImage: (image) => set((state) => ({ ...state, image }))
}));
