import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  .introImageContainer {
    width: 100%;
    grid-row: 1;
    position: relative;
    height: 36.1875rem;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }

    .introItems {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content;
      padding-top: 2.625rem;
      padding-left: 10.375rem;
      padding-right: 10.375rem;
      padding-bottom: 9.6875rem;
      color: white;

      .logo {
        grid-column: 1;
        height: auto;
        width: 21.25rem;

        img {
          width: 100%;
          height: auto;
          position: sticky;
        }
      }
      .cta {
        grid-column: 1;

        grid-template-columns: 1fr;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5rem;
        row-gap: 1rem;

        h1 {
          color: var(--base-light-L1, #fcfcfc);
          font-family: Inter;
          text-align: center;
          align-self: flex-start;
          font-size: 2.75rem;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 44px */
          padding: 0;
        }
        p {
          color: #fff;
          font-family: Inter;
          font-size: 1.5625rem;
          font-style: normal;
          text-align: center;

          line-height: 150%;

          strong {
            font-weight: 900;
          }
        }

        a {
          text-decoration: none;
        }

        .btnCta {
          display: flex;
          width: 22.5421rem;
          height: 2.9408rem;
          padding: 0.625rem 2rem;
          justify-content: center;
          align-items: center;
          gap: 0.625rem;
          flex-shrink: 0;
          font-weight: 900;
          font-size: 1.125rem;
          text-decoration: none;
          text-transform: none;

          border-radius: 6.25rem;
          background: #fcfcfc;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          color: #6f0d7e;
        }
      }
      .menu {
        grid-column: 2;
        grid-row: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        .link {
          text-decoration: none;
          color: white;
          padding-left: 2.0319rem;
          color: #fff;
          font-family: Inter;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
      }
    }
  }
  .about {
    width: 100%;
    margin-top: 5.125rem;
    padding: 6.3125rem 12.15%;
    padding-top: 0;
    gap: 1.5rem;
    grid-row: 2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
      width: 50%;
    }

    .textContent {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        width: fit-content;
        margin-bottom: 5.9375rem;
        h2 {
          color: #662483;
          text-align: center;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-family: Inter;
          font-size: 3rem;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
        hr {
          background-color: #662483;
          height: 0.1875rem;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          border: 0;
        }
      }

      p {
        color: #000;

        text-align: justify;
        font-family: Inter;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 30px */
      }
    }
  }
  .advantagesContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: auto;

    h1 {
      text-align: start;
      font-family: Inter;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 160%; /* 38.4px */
      padding-left: 12.15%;
      color: #662483;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    hr {
      width: 37.6875rem;
      height: 3px;
      flex-shrink: 0;
      margin-left: 12.15%;

      border-radius: 1.3125rem;
      background: #662483;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border: none;
    }

    .advantagesItemsContainer {
      display: flex;
      align-items: center;
      justify-content: start;

      width: 100%;
      height: auto;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 2rem;

      img {
        align-self: start;
        justify-self: start;
      }

      .advantages {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 12.5%;

        .boxAdvantage {
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: 2.875rem 1fr;
          align-items: start;
          justify-content: center;
          padding: 1.5rem 0.5rem;

          width: 31.69%;
          height: 391px;

          border-radius: 25px;
          border: 1px solid rgba(6, 4, 97, 0.3);

          background: #fff;

          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

          .headerBox {
            width: 100%;
            h2 {
              color: #662483;

              text-align: center;
              font-family: Inter;
              font-size: 14px;
              font-style: italic;
              font-weight: 500;
              line-height: 160%; /* 22.4px */
            }
            hr {
              width: 100%;
              margin: 0 auto;
            }
          }

          .bulletPoints {
            display: flex;
            flex-direction: column;
            row-gap: 2rem;

            p {
              display: flex;
              align-items: center;

              .iconsAdvantages {
                width: 48px;
                height: 48px;
              }
            }
          }
        }
      }
    }
  }
  .ctaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 0 5%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    .ctaContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h2 {
        color: #000;

        text-align: center;
        font-family: Inter;
        font-size: 4.375rem;
        font-style: italic;
        font-weight: 400;
        line-height: 130px; /* 185.714% */
      }
      h1 {
        color: #000;
        font-family: Inter;
        font-size: 70px;
        font-style: normal;
        text-align: center;
        font-weight: 900;
        line-height: 130px;
      }
      a {
        text-decoration: none;
      }

      p {
        color: #000;

        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-style: italic;
        font-weight: 500;
        line-height: 130px; /* 650% */
      }

      .ctaBtn {
        width: 26rem;
        height: 2.9408rem;
        padding: 0.625rem 2rem;
        font-family: Inter;
        cursor: pointer;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        color: #fff;
        border: 0;
        border-radius: 6.25rem;
        background: #662483;
        text-decoration: none;

        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .doubtContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 5.6875rem 12.5%;
    background: #a607c0;
    height: auto;
    h1 {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;

      margin-bottom: 1.5rem; /* 48px */
    }
    .doubtsBox {
      width: 43.4063rem;
      height: 34.375rem;
      flex-shrink: 0;
      border-radius: 1.25rem;
      background: #fff;
      padding: 3.75rem;
      font-family: Inter;
    }
  }

  .footerContainer {
    display: flex;
    flex-direction: row;
    padding: 0 12.5%;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.9375rem;
    padding-bottom: 13.9375rem;

    h1 {
      color: #662483;

      font-family: Inter;
      font-size: 4.5rem;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 86.4px */
      margin-bottom: 2.8125rem;
    }
    .formContainer {
      .inputContainer {
        display: flex;
        flex-direction: column;

        label {
          color: #662483;

          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 21.6px */
        }
        input {
          width: 26rem;
          height: 3.0625rem;
          margin-bottom: 1rem;
          outline: none;
          font-family: Inter;

          border-radius: 0.625rem;
          background: #d9d9d9;
          border: none;
          border: 0;
          padding: 1rem;

          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
        textarea {
          width: 26rem;
          height: 7.5625rem;
          margin-bottom: 1rem;
          outline: none;
          font-family: Inter;
          padding: 1rem;

          border-radius: 10px;
          background: #d9d9d9;
          border: none;
          border: 0;

          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          resize: none;
        }
      }
      .formBtn {
        width: 15rem;
        height: 3.75rem;
        flex-shrink: 0;
        border-radius: 20px;
        background: #662483;
        color: #fff;
        border: 0;
        font-family: Inter;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        margin-top: 0.875rem;
        cursor: pointer;
      }
    }
    .contactContainer {
      border-left: 1px solid #662483;
      height: 17.375rem;
      .contact {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        padding-left: 1rem;
        padding-bottom: 0.5rem;

        a {
          color: #000;
          font-family: Inter;
        }

        h3 {
          color: #000;

          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 21.6px */
        }
        p {
          color: #000;

          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
      }
      hr {
        background: #662483;
        border-color: #662483;
      }
      .openingHour {
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        h3 {
          margin-top: 1.375rem;
          margin-bottom: 0.6875rem;
          color: #000;

          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 21.6px */
        }
        p {
          color: #000;

          font-family: Inter;
          font-size: 0.875rem; //;;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
      }
    }
  }

  .barFooter {
    background: #662483;
    margin: 0;
    padding: 0;

    width: 100%;
    height: 1.375rem;
    flex-shrink: 0;
  }

  @media only screen and (min-width: 320px) and (max-width: 576px) {
    .introImageContainer {
      width: 100%;
      padding: 0;
      height: auto;

      img {
        width: 100%;
        position: absolute;
      }

      .introItems {
        position: relative;
        padding-top: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        color: white;

        .logo {
          width: 6.25rem;
          height: auto;

          img {
            width: 100%;
          }
        }
        .cta {
          margin-top: 0.5rem;
          row-gap: 0.5rem;

          h1 {
            font-size: 0.8rem;
          }

          p {
            font-size: 0.5rem;
            text-align: start;
          }

          .btnCta {
            text-decoration: none;
            text-transform: none;
            width: 8rem;
            height: 1.25rem;
            padding: 0.5rem 1rem;
            font-size: 0.6rem;
          }
        }
        .menu {
          grid-column: 2;
          grid-row: 1;
          display: flex;
          align-items: flex-end;
          justify-content: end;

          .link {
            text-decoration: none;
            color: white;
            padding-right: 0.5rem;
            color: #fff;
            font-family: Inter;
            font-size: 0.5rem;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
          }
        }
      }
    }
    .about {
      width: 100%;
      margin-top: 2rem;
      padding: 1rem;
      padding-top: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
      h2 {
        color: #a607c0;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Inter;
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      p {
        text-align: justify;
        font-family: Inter;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 30px */
      }
    }
    .advantagesContainer {
      display: block;
      align-items: center;
      position: sticky;

      h1 {
        text-align: center;
        padding-left: 0;
        width: 90%;
        margin: 0 auto;
      }
      hr {
        width: 90%;
        margin: 0 auto;
      }

      .advantagesItemsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;

        img {
          width: 100%;
        }

        .advantages {
          flex-direction: column;
          align-items: center;

          padding: 0;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;

          .boxAdvantage {
            width: 90%;
            height: auto;
            grid-template-rows: auto;
            grid-template-columns: 100%;
            padding: 1rem;
            margin-bottom: 1rem;

            .bulletPoints {
              display: flex;
              flex-direction: column;
              max-width: 90%;
              row-gap: 3rem;

              p {
                font-size: 0.875rem;
              }
            }
          }
        }
      }
    }
    .ctaContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 2rem;

      img {
        width: 90%;
      }
      .ctaContent {
        .ctaBtn {
          width: 90%;
          padding: 0.625rem 1rem;
        }
      }
    }
    .doubtContainer {
      padding: 0;
      padding: 2rem 0;

      h1 {
        padding: 1rem 0;
      }
      .doubtsBox {
        width: 90%;
        height: auto;
        padding: 1rem;
      }
    }
    .footerContainer {
      flex-direction: column;
      padding: 0;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
      padding-bottom: 2rem;

      .formContainer {
        .inputContainer {
          input {
            width: 90%;
          }
          textarea {
            width: 90%;
          }
        }
        .formBtn {
          width: 90%;
        }
      }
      .contactContainer {
        margin-top: 2rem;
      }
    }
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    .introImageContainer {
      width: 100%;
      padding: 0;
      height: auto;

      img {
        width: 100%;
        position: absolute;
      }

      .introItems {
        position: relative;
        display: grid;
        padding-top: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        color: white;

        .logo {
          width: 12.5rem;
          height: auto;

          img {
            width: 100%;
          }
        }
        .cta {
          margin-top: 2rem;
          row-gap: 1rem;
          justify-content: start;
          align-items: start;
          h1 {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 100%; /* 44px */
            text-align: start;
          }
          p {
            font-size: 1rem;
            text-align: start;

            strong {
              font-weight: 900;
            }
          }

          .btnCta {
            width: 12rem;
            height: 2rem;
            padding: 0.625rem 2rem;
            font-size: 0.8rem;
          }
        }
        .menu {
          grid-column: 2;
          grid-row: 1;
          display: flex;
          align-items: flex-end;
          justify-content: end;

          .link {
            text-decoration: none;
            color: white;
            padding-right: 0.5rem;
            color: #fff;
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
          }
        }
      }
    }
    .about {
      width: 100%;
      margin-top: 2rem;
      padding: 1rem;
      padding-top: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
      h2 {
        color: #a607c0;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Inter;
        font-size: 3rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      p {
        text-align: justify;
        font-family: Inter;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 30px */
      }
    }
    .advantagesContainer {
      align-items: center;

      h1 {
        text-align: center;
        padding-left: 0;
        width: 90%;
        margin: 0 auto;
      }
      hr {
        width: 90%;
        margin: 0 auto;
      }

      .advantagesItemsContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
        }

        .advantages {
          flex-direction: column;
          align-items: center;

          .boxAdvantage {
            grid-template-rows: auto;
            width: 90%;
            height: auto;
            margin-bottom: 1rem;
            padding: 1rem;
          }
        }
      }
    }
    .ctaContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 2rem;

      img {
        width: 90%;
      }
      .ctaContent {
        .ctaBtn {
          width: 90%;
          padding: 0.625rem 1rem;
        }
      }
    }
    .doubtContainer {
      padding: 0;
      padding: 2rem 0;

      h1 {
        padding: 1rem 0;
      }
      .doubtsBox {
        width: 90%;
        height: auto;
        padding: 1rem;
      }
    }
    .footerContainer {
      flex-direction: column;
      padding: 0;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
      padding-bottom: 2rem;

      .formContainer {
        width: 90%;
        .inputContainer {
          input {
            width: 90%;
          }
          textarea {
            width: 90%;
          }
        }
        .formBtn {
          width: 90%;
        }
      }
      .contactContainer {
        margin-top: 2rem;
      }
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 992px) {
    .introImageContainer {
      width: 100%;
      padding: 0;
      height: auto;

      img {
        width: 100%;
        position: absolute;
      }

      .introItems {
        position: relative;
        display: grid;
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        color: white;

        .logo {
          width: 15rem;
          height: auto;

          img {
            width: 100%;
          }
        }
        .cta {
          margin-top: 2rem;
          row-gap: 1.25rem;
          justify-content: start;
          align-items: start;
          h1 {
            font-size: 2rem;
            font-weight: 700;
            line-height: 100%; /* 44px */
            text-align: start;
          }
          p {
            font-size: 1.25rem;
            text-align: start;
          }

          .btnCta {
            width: 15rem;
            height: 2.5;
            padding: 1rem 2rem;
            font-size: 1rem;
          }
        }
        .menu {
          grid-column: 2;
          grid-row: 1;
          display: flex;
          align-items: flex-end;
          justify-content: end;

          .link {
            padding-right: 0.5rem;
            color: #fff;
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
          }
        }
      }
    }
    .about {
      width: 100%;
      margin-top: 3rem;
      padding: 1rem;
      padding-top: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
      h2 {
        color: #a607c0;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Inter;
        font-size: 3rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      p {
        text-align: justify;
        font-family: Inter;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 30px */
      }
    }
    .advantagesContainer {
      align-items: center;

      h1 {
        text-align: center;
        padding-left: 0;
        width: 90%;
        margin: 0 auto;
      }
      hr {
        width: 90%;
        margin: 0 auto;
      }

      .advantagesItemsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        img {
          width: 100%;
        }

        .advantages {
          flex-direction: column;
          align-items: center;

          padding: 0;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;

          .boxAdvantage {
            width: 90%;
            height: auto;
            margin-bottom: 1rem;
            grid-template-rows: auto;
            padding: 1rem;
          }
        }
      }
    }
    .ctaContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 2rem;

      img {
        width: 90%;
      }
      .ctaContent {
        .ctaBtn {
          width: 90%;
          padding: 0.625rem 1rem;
        }
      }
    }
    .footerContainer {
      flex-direction: column;
      padding: 0;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
      padding-bottom: 2rem;

      .formContainer {
        width: 90%;
        .inputContainer {
          input {
            width: 90%;
          }
          textarea {
            width: 90%;
          }
        }
        .formBtn {
          width: 90%;
        }
      }
      .contactContainer {
        margin-top: 2rem;
      }
    }
  }

  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    .introImageContainer {
      width: 100%;
      height: auto;

      .introItems {
        position: relative;
        display: grid;
        padding-top: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        color: white;

        .logo {
          font-size: 1rem;
        }
        .cta {
          margin-top: 3rem;
          row-gap: 2rem;
          justify-content: center;
          align-items: center;
          h1 {
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 100%; /* 44px */
            text-align: center;
          }
          p {
            font-size: 1.5rem;
            text-align: center;
          }
        }
        .menu {
          grid-column: 2;
          grid-row: 1;
          display: flex;
          align-items: flex-end;
          justify-content: end;

          .link {
            padding-right: 0.5rem;
            color: #fff;
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
          }
        }
      }
    }
    .about {
      width: 100%;
      margin-top: 5.125rem;
      padding: 1.5rem;
      gap: 1.5rem;
      padding-top: 0;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      img {
        width: 50%;
      }
      h2 {
        color: #a607c0;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Inter;
        font-size: 3rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      p {
        text-align: justify;
        font-family: Inter;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 30px */
      }
    }
    .advantagesContainer {
      .advantagesItemsContainer {
        .advantages {
          padding: 0 8%;
        }
      }
    }
    .ctaContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      padding: 2rem;
      margin-top: 2rem;
      margin-bottom: 2rem;

      img {
        width: 50%;
      }

      .ctaContent {
        .ctaBtn {
          width: 90%;
          padding: 0.625rem 1rem;
        }
      }
    }
  }

  @media only screen and (min-width: 1367px) {
    .about {
      .textContent {
        p {
          font-size: 20px;
        }
      }
    }
    .advantagesContainer {
      .advantagesItemsContainer {
        .advantages {
          .boxAdvantage {
            .headerBox {
              width: 100%;
              h2 {
                font-size: 1.125rem;
              }
              hr {
                width: 100%;
                margin: 0 auto;
              }
            }

            .bulletPoints {
              display: flex;
              flex-direction: column;
              height: 100%;
              row-gap: 2rem;
              padding: 1rem;
              p {
                color: #000;

                text-align: start;
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 14px */
              }
            }
          }
        }
      }
    }
    .ctaContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      padding: 0 5%;
      margin-top: 2rem;
      margin-bottom: 2rem;
      .ctaContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2 {
          color: #000;

          text-align: center;
          font-family: Inter;
          font-size: 4.375rem;
          font-style: italic;
          font-weight: 400;
          line-height: 8.125rem; /* 185.714% */
        }
        h1 {
          color: #000;
          font-family: Inter;
          font-size: 4.375rem;
          font-style: normal;
          text-align: center;
          font-weight: 900;
          line-height: 8.125rem;
        }

        p {
          color: #000;

          text-align: center;
          font-family: Inter;
          font-size: 1.25rem;
          font-style: italic;
          font-weight: 500;
          line-height: 8.125rem; /* 650% */
        }

        .ctaBtn {
          width: 26rem;
          height: 2.9408rem;
          padding: 0.625rem 2rem;
          cursor: pointer;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 900;
          line-height: 100%;
          color: #fff;
          border: 0;
          border-radius: 6.25rem;
          background: #662483;

          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }

    .doubtContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      padding: 5.6875rem 12.5%;
      background: #a607c0;
      height: auto;
      h1 {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;

        margin-bottom: 1.5rem; /* 48px */
      }
      .doubtsBox {
        width: 43.4063rem;
        height: 34.375rem;
        flex-shrink: 0;
        border-radius: 20px;
        background: #fff;
        padding: 3.75rem;
      }
    }

    .footerContainer {
      display: flex;
      flex-direction: row;
      padding: 0 12.5%;
      align-items: center;
      justify-content: space-between;
      margin-top: 4.9375rem;
      padding-bottom: 13.9375rem;

      h1 {
        color: #662483;

        font-family: Inter;
        font-size: 4.5rem;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 86.4px */
        margin-bottom: 2.8125rem;
      }
      .formContainer {
        .inputContainer {
          display: flex;
          flex-direction: column;

          label {
            color: #662483;

            font-family: Inter;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 21.6px */
          }
          input {
            width: 26rem;
            height: 3.0625rem;
            margin-bottom: 1rem;
            outline: none;
            font-family: Inter;

            border-radius: 10px;
            background: #d9d9d9;
            border: none;
            border: 0;
            padding: 1rem;

            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          }
          textarea {
            width: 26rem;
            height: 7.5625rem;
            margin-bottom: 1rem;
            outline: none;
            font-family: Inter;
            padding: 1rem;

            border-radius: 10px;
            background: #d9d9d9;
            border: none;
            border: 0;

            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            resize: none;
          }
        }
        .formBtn {
          width: 15rem;
          height: 3.75rem;
          flex-shrink: 0;
          border-radius: 20px;
          background: #662483;
          color: #fff;
          border: 0;
          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 900;
          line-height: 120%;
          margin-top: 0.875rem;
          cursor: pointer;
        }
      }
      .contactContainer {
        border-left: 1px solid #662483;
        height: 17.375rem;
        .contact {
          display: flex;
          flex-direction: column;
          row-gap: 0.5rem;
          padding-left: 1rem;
          padding-bottom: 0.5rem;

          a {
            color: #000;
          }

          h3 {
            color: #000;

            font-family: Inter;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 21.6px */
          }
          p {
            color: #000;

            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
        hr {
          background: #662483;
          border-color: #662483;
        }
        .openingHour {
          display: flex;
          flex-direction: column;
          padding-left: 1rem;
          h3 {
            margin-top: 1.375rem;
            margin-bottom: 0.6875rem;
            color: #000;

            font-family: Inter;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 21.6px */
          }
          p {
            color: #000;

            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
      }
    }

    .barFooter {
      background: #662483;
      margin: 0;
      padding: 0;

      width: 100%;
      height: 1.375rem;
      flex-shrink: 0;
    }
  }
`;
