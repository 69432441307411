import styled, { css } from 'styled-components';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .Container {
    background-color: #fff;
    width: 500px;
    height: auto;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 22px;
    gap: 10px;
    isolation: isolate;
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: #2c2c2c;
    margin-bottom: 10px;
  }

  .closeBtnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .closeBtn {
    cursor: pointer;
  }

  .iconContainer {
    display: flex;
    margin-bottom: 6px;
    width: 100%;
    padding-left: 40px;

    svg {
      width: 43px;
      height: 43px;
      margin-top: 3px;
    }
  }

  .iconData {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }

  .emailDataIcon {
    height: fit-content;
    svg {
      width: 43px;
      min-width: 43px;
    }
  }

  .dataTitle {
    color: #2c2c2c;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter';
  }

  .dataSubtitle {
    color: #8b8b8b;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Inter';

    ${({ pendingStatus }: { pendingStatus?: boolean }) =>
      pendingStatus &&
      css`
        background: #fff6a7;
        color: #c96000;
        padding: 6.5px 24px;
        font-size: 14px;
        border-radius: 6px;
      `}
  }

  .emailData {
    word-wrap: break-word;
    word-break: break-all;
    width: 100%;
  }

  .boxContainer {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .partnerLogo {
    cursor: pointer;
    img {
      max-width: 360px;
      max-height: 140px;
    }
  }

  .backButton {
    color: #fff;
    background: #662483;
    width: 100%;
    font-family: 'Inter';
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 12px;
  }

  .backButton:hover {
    color: #fff;
    background: #662483;
    opacity: 0.9;
  }
`;
export default Background;
