import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { informativesTableHeader, informativesDeleteMessage } from '../../constants';
import {
  useActionModalStore,
  useInformativesStore,
  useSortItemsStore,
  useDeleteModalStore,
  useLoadModalStore,
  useInfoModalStore,
  useRefreshChangedPagesStore,
  usePreviewImageModalStore,
  usePaginationStore
} from '../../store';
import { Errors } from '../../constants/Errors';

function useInformativesController() {
  const navigate = useNavigate();

  const { handleSetSortFields } = useSortItemsStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { isOpenInfoModal, handleModalOpen } = useInfoModalStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const { setChangedPage, isPageChanged } = useRefreshChangedPagesStore();

  const [totalInformatives, setTotalInformatives] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [isSuccessfully, setIsSuccessfully] = useState(false);
  const [infoText, setInfoText] = useState('');

  const {
    informativeId,
    informativesId,
    informativesList,
    page,
    field,
    direction,
    isInfoModalOpen,
    unitsChoicedoModalOpen,
    setIsInfoModalOpen,
    getAllSortInformativessDataRequest,
    handleSetInformativeId,
    handleSetInformativesId,
    handleSetInformativesList,
    deleteInformativesRequest,
    handleRemoveInformative,
    searchInformativessRequest
  } = useInformativesStore();

  const { isOpenImagePreview } = usePreviewImageModalStore();

  async function onSubmitDelete(id: string) {
    toogleLoading();
    try {
      await deleteInformativesRequest(id);
      handleRemoveInformative(id);
      setIsSuccessfully(true);
      setInfoText('Informativo excluído com sucesso');
      handleModalOpen();
    } catch (error: any) {
      setIsSuccessfully(false);
      setInfoText(
        (Errors as any)({})[String(error.message).trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  function closeInfoModal() {
    setIsInfoModalOpen(!isInfoModalOpen);
  }

  function navigateToRegisterPage() {
    navigate('/informatives/register');
  }

  const actionModalData = [
    {
      ButtonsText: 'Visualizar',
      OnClick: () => {
        closeInfoModal();
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        handleDeleteModalOpen();
      }
    }
  ];

  async function sortInformativesData(
    newPage: string,
    sortByField: string,
    sortByDirection: string
  ) {
    const data = await getAllSortInformativessDataRequest(
      newPage,
      sortByField,
      sortByDirection,
      searchString
    );

    return data;
  }

  async function changePage(newPage: number) {
    toogleLoading();
    try {
      const { data } = await getAllSortInformativessDataRequest(
        `${newPage}`,
        field,
        direction,
        searchString
      );
      const formatedData = data.data;
      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });

      if (data) {
        setTotalPages(data.totalPages || 1);
        setTotalInformatives(data.totalInformatives || 0);
        handleSetInformativesList(formatedData);
        handleSetInformativesId(formatedRowsId);
      }
    } finally {
      toogleLoading();
    }
  }

  async function handleSearch(value: string) {
    toogleLoading();
    try {
      setSearchString(value);
      const formatedDataForSearch = { queryText: value };
      const { data } = await searchInformativessRequest(
        formatedDataForSearch,
        field,
        direction,
        String(currentPage)
      );
      const formatedData = data.data;
      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });

      handleSetInformativesList(formatedData);
      handleSetInformativesId(formatedRowsId);
      setTotalPages(data.totalPages || 1);
      setTotalInformatives(data.totalInformatives || 0);
    } finally {
      toogleLoading();
    }
  }

  async function getAllData() {
    toogleLoading();
    try {
      const { data } = await getAllSortInformativessDataRequest(
        page,
        field,
        direction,
        searchString
      );
      const formatedData = data.data;
      const formatedRowsId = formatedData.map((item) => item.id);
      handleSetInformativesId(formatedRowsId);
      handleSetInformativesList(formatedData);
      setTotalPages(data.totalPages || 1);
      setTotalInformatives(data.totalInformatives || 0);
    } finally {
      toogleLoading();
    }
  }

  function setSetSortFields() {
    handleSetSortFields([
      'info_model.createdAt',
      'info_model.subject',
      'manager.name',
      'block_model.name',
      'block_model.name'
    ]);
  }

  function setInformativesIfExist() {
    if (informativesList.length <= 0) return;
    handleSetInformativesId(informativesId);
  }

  useEffect(() => {
    setInformativesIfExist();
    if (isPageChanged) setChangedPage(false);
    getAllData();
    setSetSortFields();
  }, [isOpenInfoModal]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    actionModalData,
    informativesTableHeader,
    informativesDeleteMessage,
    informativeId,
    informativesId,
    informativesList,
    totalPages,
    unitsChoicedoModalOpen,
    totalInformatives,
    isOpenInfoModal,
    isOpenDeleteModal,
    isSuccessfully,
    infoText,
    isInfoModalOpen,
    isOpenImagePreview,
    closeInfoModal,
    handleDrawerOpen,
    sortInformativesData,
    handleSetInformativeId,
    onSubmitDelete,
    navigateToRegisterPage,
    handleModalOpen,
    changePage,
    handleSearch,
    handleDeleteModalOpen
  };
}

export default useInformativesController;
