/* eslint-disable react/no-array-index-key */
import { Box, Button, Typography } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { ProgressBar as ProgressBarComponent } from '../ProgressBar/ProgressBar';
import Container from './CustomFileInput.style';
import useCustomFileInput from './CustomFileInput.controller';
import { CustomFileInputProps } from './CustomFileInput.props';
import selectfile from '../../../assets/images/selectfile.svg';
import { formatFileSize } from '../../../helpers/formatFileSize';

export function CustomFileInput(props: CustomFileInputProps) {
  const { accept, isProgress } = props;
  const { handleChangeFile, handleRemoveFile, file, getFileFinalized } = useCustomFileInput();

  return (
    <Container>
      {file && (
        <Box className="title">
          <Box className="selectedFile">
            <Box>
              <p className="fileName">
                {file.name}({formatFileSize(file.size)})
              </p>
            </Box>
            <Button className="closeButton" onClick={handleRemoveFile}>
              <CloseOutlined />
            </Button>
          </Box>
          {isProgress !== '100' && (
            <div className="containerBar">
              <ProgressBarComponent isProgress={isProgress} />
            </div>
          )}
          {getFileFinalized() && file.name === getFileFinalized().fileName && (
            <div className="containerBar">
              <ProgressBarComponent isProgress="100" />
            </div>
          )}
        </Box>
      )}

      <Box flexDirection="column" className="fileBox" display={file ? 'none' : 'flex'}>
        <Box className="fileImageIcon">
          <img src={selectfile} alt="select file icon" className="icon" />
        </Box>
        <>
          <Typography marginBottom={0.5} variant="body2">
            Selecione um arquivo ou arraste aqui
          </Typography>
          <Typography variant="body2" color="rgba(0, 0, 0, 0.5)">
            Arquivos até 100MB
          </Typography>
        </>
      </Box>
      <input
        id="fileInput"
        name="fileInput"
        type="file"
        accept={accept}
        onChange={handleChangeFile}
      />
    </Container>
  );
}
