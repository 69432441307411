/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/jsx-props-no-spreading */
import { TextField, InputAdornment, Box, MenuItem } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CheckCircle, Cancel } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import useCustomInputController from './CustomInput.controller';
import CustomInputProps, { Option } from './CustomInput.props';
import InputContainer from './CustomInput.style';

const openselectinput = require('../../assets/images/openselectinput.svg');

export function CustomInput(props: CustomInputProps) {
  const {
    label,
    type,
    register,
    error,
    emailValidation,
    select,
    values,
    value,
    isCep,
    isAuth,
    id,
    isShowAndLess,
    field,
    setEnterpriseName
  } = props;
  const { showPassword, handleClick, handleSetInputConfigs, getMaxLength } =
    useCustomInputController(isAuth);
  return (
    <InputContainer error={error} emailValidation={emailValidation || ''}>
      <TextField
        onChangeCapture={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleSetInputConfigs(e, !!isCep, setEnterpriseName)
        }
        id={id}
        className={isShowAndLess ? 'showOnCenter' : 'customInput'}
        placeholder={value || label}
        defaultValue={value}
        select={select}
        type={showPassword ? 'text' : type}
        error={!!error}
        SelectProps={{ IconComponent: openselectinput.ReactComponent }}
        {...register}
        inputProps={{
          maxLength: getMaxLength(String(field))
        }}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                {type === 'emailLogin' && (
                  <Box className="showEmailbutton">
                    {!error ? <CheckCircle /> : <Cancel sx={{ color: 'red' }} />}
                  </Box>
                )}
              </InputAdornment>
              <InputAdornment position="end">
                {type === 'password' && (
                  <Box className="showpassbutton" onClick={handleClick}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </Box>
                )}
              </InputAdornment>
            </>
          )
        }}>
        {values &&
          values.map((option: Option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
    </InputContainer>
  );
}
