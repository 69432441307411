import { Typography, Box, Button } from '@mui/material';
import { CustomControlledInput } from '../../../CustomEnterprisesControlledInput/CustomEnterprisesControlledInput';
import { CustomFileInput } from '../../../CustomFileInput/CustomFileInput';
import { CustomPreviewInput } from '../../../CustomPreviewInput/CustomPreviewInput';
import Containter from './Customization.style';
import useCustomizationController from './Customization.controller';
import useMobilePreviewController from '../../MobilePreview.controller';
import { CustomizationProps } from './Customization.props';

function Customization(props: CustomizationProps) {
  const { control, register, setEnterpriseName } = props;
  const { handleMobilePreviewOpen } = useCustomizationController();
  const { handleMobilePreviewOpen: handleCancel } = useMobilePreviewController();
  return (
    <Containter>
      <Box className="titleBox">
        <Typography variant="h5" className="customizationTitle">
          Visualização da Customização
        </Typography>
        <Typography variant="h6" fontWeight="600" fontSize={20.8}>
          Customização
        </Typography>
        <Typography variant="caption" color="#727272" fontSize={14}>
          CORES DO SISTEMA
        </Typography>
      </Box>
      <Box className="inputContainer">
        <CustomPreviewInput />
      </Box>
      <Box className="bottomBox">
        <Typography variant="caption" color="#727272" marginBottom={1}>
          INFORMAÇÕES GERAIS
        </Typography>
        <Typography variant="subtitle2" fontWeight={600}>
          Nome do Empreendimento
        </Typography>
        <CustomControlledInput
          name="name"
          control={control}
          register={register}
          placeholder="Insira o nome do empreendimento"
          setEnterpriseName={setEnterpriseName}
        />
        <Typography variant="subtitle2" marginBottom={1} marginTop={1}>
          Logotipo
        </Typography>
        <CustomFileInput accept="image/jpeg|image/png" type="image" />
        <Typography className="fileInputDescription">
          Arquivos permitidos (envio opcional): jpg, png, máximo de 190 x 55 px.
        </Typography>
        <Box className="buttonContainer">
          <Button onClick={handleCancel} className="cancel">
            Cancelar
          </Button>
          <Button onClick={handleMobilePreviewOpen}>Salvar</Button>
        </Box>
      </Box>
    </Containter>
  );
}

export default Customization;
