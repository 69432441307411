export const useFinishMaintenanceModal = (
  toogleModal: () => void,
  handleChangeStatus: () => Promise<void>
) => {
  const closeRenameModal = () => {
    toogleModal();
  };

  const submit = async () => {
    await handleChangeStatus();
    closeRenameModal();
  };

  return {
    submit,
    closeRenameModal
  };
};
