import { Box, Button, Typography, Link } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Container from './CustomFileInput.style';
import useCustomFileInput from './CustomFileInput.controller';
import { CustomFileInputProps } from './CustomFileInput.props';
import selectfile from '../../assets/images/selectfile.svg';

export function CustomFileInput(props: CustomFileInputProps) {
  const { accept, type, hasImageSizeValidation = true, handleFile, isTenSize } = props;
  const {
    fileName,
    imageName,
    handleChangeFile,
    handleRemoveFile,
    adjustImageNameSize,
    signedLogoUrl
  } = useCustomFileInput(type, hasImageSizeValidation, handleFile, isTenSize);

  return (
    <Container>
      {fileName ? (
        <>
          <Box className="selectedFile">
            <Typography variant="body2" display="flex" alignItems="center">
              {imageName === 'Ver Imagem' ? (
                <Link href={signedLogoUrl} target="_blank" color="#0069c2" className="link">
                  {adjustImageNameSize(imageName)}
                </Link>
              ) : (
                adjustImageNameSize(imageName)
              )}
            </Typography>
          </Box>
          <Button className="closeButton" onClick={handleRemoveFile}>
            <CloseOutlined />
          </Button>
        </>
      ) : (
        <>
          <img src={selectfile} alt="select file icon" className="icon" />
          <Box flexDirection="column">
            <Typography marginBottom={0.5} variant="body2">
              Selecione um arquivo ou arraste aqui
            </Typography>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.5)">
              {isTenSize ? 'Arquivos até 10MB' : 'Arquivos até 100MB'}
            </Typography>
          </Box>
          <input
            id="fileInput"
            name="fileInput"
            type="file"
            accept={accept}
            onChange={handleChangeFile}
          />
        </>
      )}
    </Container>
  );
}
