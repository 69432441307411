/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Button } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Container } from './LandingPage.style';
import { CollapsibleSet } from './components/CollapsibleSet/CollapsibleSet';
import useLandingPageController from './LandingPage.controller';

import backGroundLp from '../../assets/images/backgroundlp.svg';
import purpleEllipse from '../../assets/images/purpleEllipse.svg';
import girlWithPhone from '../../assets/images/girlWithPhone.svg';
import CellPhone from '../../assets/images/cellPhoneAppHauzy.svg';
import LogoHauzy from '../../assets/images/logohauzy.svg';
import Block1First from '../../assets/images/Bloco 1.png';
import Block1Second from '../../assets/images/Bloco 1 (2).png';
import Block1Third from '../../assets/images/Bloco 1 (3).png';
import Block1Fourth from '../../assets/images/Bloco 1 (4).png';
import Block2First from '../../assets/images/Bloco 2.png';
import Block2Second from '../../assets/images/Bloco 2 (2).png';
import Block2Third from '../../assets/images/Bloco 2 (3).png';
import Block2Fourth from '../../assets/images/Bloco 2 (4).png';
import Block3First from '../../assets/images/Bloco 3.png';
import Block3Second from '../../assets/images/Bloco 3 (2).png';
import Block3Third from '../../assets/images/Bloco 3 (3).png';
import Block3Fourth from '../../assets/images/Bloco 3 (4).png';

export function LandingPage() {
  const { control, errors, register, handleClick, scrollToElement, currentWord } =
    useLandingPageController();

  return (
    <Container>
      <Box className="introImageContainer">
        <img src={backGroundLp} alt="background" />
        <Box className="introItems">
          <div className="logo">
            <img src={LogoHauzy} alt="logo" />
          </div>

          <Box className="cta">
            <h1>Sua gestão na palma da sua mão</h1>
            <p>
              Experimente o Hauzy hoje mesmo{' '}
              <strong>
                <span id="animateWords">{currentWord}</span>
              </strong>
            </p>
            <a href=" https://wa.me/557196329153" className="link" target="blank">
              <Button className="btnCta">Solicite seu orçamento</Button>
            </a>
          </Box>

          <Box className="menu">
            <a
              className="link"
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                scrollToElement('faq');
              }}>
              FAQ
            </a>
            <a
              className="link"
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                scrollToElement('contato');
              }}>
              Contato
            </a>
            <a className="link" href="/login">
              Sou Cliente
            </a>
          </Box>
        </Box>
      </Box>

      <Box className="about" id="about">
        <img src={girlWithPhone} alt="girl with phone" />

        <Box className="textContent">
          <Box className="title">
            <h2>Sobre Nós</h2>
            <hr />
          </Box>
          <p>
            A Hauzy quer transformar a interação do seu condomínio, oferecendo tecnologia e
            eficiência para simplificar o dia a dia de moradores, síndicos e administradores. Tenha
            o controle da sua morada através de um aplicativo de gestão condominial integrado,
            tornando sua experiência moderna e intuitiva.
          </p>
          <p>
            Nosso propósito é trazer mais conforto aos moradores, facilitando a rotina de
            funcionários e prestadores de serviço com funcionalidades que apoiam desde a
            administração e segurança, até as manutenções preventivas para preservação e longevidade
            do condomínio. Queremos tornar seu lar mais inteligente e o seu condomínio mais
            eficiente!
          </p>
        </Box>
      </Box>

      <Box className="advantagesContainer">
        <h1>A NOVA FORMA DE GERENCIAR SEU CONDOMÍNIO</h1>
        <hr />

        <Box
          className="advantagesItemsContainer"
          style={{ backgroundImage: `url(${purpleEllipse})` }}>
          <Box className="advantages">
            <Box className="boxAdvantage">
              <Box className="headerBox">
                <h2>Gerencie as funções do seu condomínio</h2>
                <hr />
              </Box>
              <Box className="bulletPoints">
                <p>
                  <img className="iconsAdvantages" src={Block1First} alt="" />
                  Painel de reserva de áreas comuns
                </p>
                <p>
                  <img className="iconsAdvantages" src={Block1Second} alt="" />
                  Controle de manutenções
                </p>
                <p>
                  <img className="iconsAdvantages" src={Block1Third} alt="" />
                  Controle de documentos
                </p>
                <p>
                  <img className="iconsAdvantages" src={Block1Fourth} alt="" />
                  Indicação de parceiros e serviços
                </p>
              </Box>
            </Box>
            <Box className="boxAdvantage">
              <Box className="headerBox">
                <h2>Otimize a comunicação entre as pontas</h2>
                <hr />
              </Box>
              <Box className="bulletPoints">
                <p>
                  <img className="iconsAdvantages" src={Block2First} alt="" />
                  Divulgação de informativos
                </p>
                <p>
                  {' '}
                  <img className="iconsAdvantages" src={Block2Second} alt="" />
                  Envio de boletos
                </p>
                <p>
                  {' '}
                  <img className="iconsAdvantages" src={Block2Third} alt="" />
                  Votações virtuais para assembleias do condomínio
                </p>
                <p>
                  {' '}
                  <img className="iconsAdvantages" src={Block2Fourth} alt="" />
                  Achados e perdidos digital
                </p>
              </Box>
            </Box>
            <Box className="boxAdvantage">
              <Box className="headerBox">
                <h2>Comodidade para moradores e visitantes</h2>
                <hr />
              </Box>
              <Box className="bulletPoints">
                <p>
                  <img className="iconsAdvantages" src={Block3First} alt="" />
                  Gerenciamento de moradores
                </p>
                <p>
                  <img className="iconsAdvantages" src={Block3Second} alt="" />
                  Controle de ocorrências
                </p>
                <p>
                  <img className="iconsAdvantages" src={Block3Third} alt="" />
                  Controle de chegadas e retiradas de encomendas
                </p>
                <p>
                  <img className="iconsAdvantages" src={Block3Fourth} alt="" />
                  Controle de acesso de visitantes
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="ctaContainer">
        <img src={CellPhone} alt="cellphone with app" />
        <Box className="ctaContent">
          <h2>Planos a Partir de</h2>
          <h1>R$ 89,00</h1>
          <p>cancele a qualquer momento</p>
          <a href=" https://wa.me/557196329153" className="link" target="blank">
            <button className="ctaBtn" type="button">
              Solicite seu orçamento
            </button>
          </a>
        </Box>
      </Box>

      <Box className="doubtContainer" id="faq">
        <h1>PERGUNTAS FREQUENTES</h1>
        <Box className="doubtsBox">
          <CollapsibleSet />
        </Box>
      </Box>

      <Box className="footerContainer" id="contato">
        <Box className="formContainer">
          <h1>Contato</h1>
          <Controller
            name="name"
            control={control}
            render={() => (
              <>
                <Box className="inputContainer">
                  <label htmlFor="name">Nome</label>
                  <input type="text" {...register('name')} />
                </Box>
                {errors.name ? <p>{errors.name.message}</p> : null}
              </>
            )}
          />
          <Controller
            name="email"
            control={control}
            render={() => (
              <>
                <Box className="inputContainer">
                  <label htmlFor="name">E-mail</label>
                  <input type="text" {...register('email')} />
                </Box>
                {errors.email ? <p>{errors.email.message}</p> : null}
              </>
            )}
          />
          <Controller
            name="message"
            control={control}
            render={() => (
              <>
                <Box className="inputContainer">
                  <label htmlFor="message">Mensagem</label>
                  <textarea {...register('message')} draggable={false} />
                </Box>
                {errors.message ? <p>{errors.message.message}</p> : null}
              </>
            )}
          />

          <button type="button" className="formBtn" onClick={handleClick}>
            Enviar Menssagem
          </button>
        </Box>
        <Box className="contactContainer">
          <Box className="contact">
            <h3>Email & telefone</h3>
            <a href="mailto: someone@example.com">contato@hauzyapp.com.br</a>
            <p>+55 (71) 99632-9153</p>
          </Box>
          <hr />
          <Box className="openingHour">
            <h3>Horário</h3>
            <p>De segunda à sexta</p>
            <p>Das 8:00 às 18:00</p>
          </Box>
        </Box>
      </Box>
      <hr className="barFooter" />
    </Container>
  );
}
