/* eslint-disable import/no-cycle */
import { Ifiles } from '../../../store/Maintenance/Maintenance.props';
import { instance } from '../../api';
import {
  IAllMaintenancesDtoOutput,
  SearchMaintenance,
  IEnterpriseBasicList,
  IBlocksList,
  IMaintenanceDetailsData
} from './Maintenance.dto';

export const sortMaintenanceData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  isAdmin: boolean,
  searchString: string
): Promise<IAllMaintenancesDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `/maintenance${isAdmin ? '' : '/manager'}?page=${page}&sortDirection=${
        sortByDirection || 'ASC'
      }&sortBy=${sortByField || 'maintenance_model.status'}&search=${searchString}`
    });

    const formatedData = data.data;

    const totalMaintenances = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalMaintenances, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const registerMaintenance = async (
  maintenanceData: FormData,
  isAdmin: boolean
): Promise<void> => {
  try {
    await instance.request({
      method: 'POST',
      url: `/maintenance${isAdmin ? '' : '/manager'}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: maintenanceData
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editMaintenance = async (
  maintenanceData: FormData,
  id: string,
  isAdmin: boolean
): Promise<void> => {
  const maintenanceId = id || window.location.href.split('/').pop();

  try {
    await instance.request({
      method: 'PUT',
      url: `maintenance${isAdmin ? '' : '/manager'}/${maintenanceId}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: maintenanceData
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteMaintenance = async (id: string, isAdmin: boolean): Promise<void> => {
  try {
    await instance.request({
      method: 'DELETE',
      url: `/maintenance${isAdmin ? '' : '/manager'}/${id}`
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const changeStatusMaintenance = async (
  id: string,
  status: string,
  isAdmin: boolean,
  data: { files: Ifiles[] } | { date: string }
): Promise<void> => {
  try {
    await instance.request({
      method: 'PATCH',
      url: `/maintenance${isAdmin ? '' : '/manager'}/${status}/${id}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchMaintenances = async (
  maintenancesData: SearchMaintenance,
  isAdmin: boolean,
  sortByField: string,
  sortByDirection: string,
  page: string
): Promise<IAllMaintenancesDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `/maintenance${isAdmin ? '' : '/manager'}?search=${
        maintenancesData.queryText
      }&sortDirection=${sortByDirection || 'ASC'}&includeMeta=true&sortBy=${
        sortByField || 'maintenance_model.status'
      }&page=${page}`
    });

    const formatedData = data.data;

    const totalMaintenances = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalMaintenances, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getMaintenanceDetailsById = async (
  maintenanceId: string,
  isAdmin: boolean
): Promise<{ data: IMaintenanceDetailsData }> => {
  const id = maintenanceId || window.location.href.split('/').pop();

  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `maintenance${isAdmin ? '/admin' : '/manager'}/${id}`
    });

    return { data };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getImageToRequest = async (pictureData: FormData, id: string): Promise<any> => {
  try {
    const data = await instance.request({
      method: 'POST',
      url: `maintenance/picture/${id}`,
      baseURL: process.env.REACT_APP_STORAGE_URL,
      data: pictureData
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getAllEnterprises = async (): Promise<IEnterpriseBasicList[]> => {
  try {
    const { data } = await instance.get(
      '/enterprise/?filterFields=id,name&sortDirection=ASC&limit=0'
    );
    const { enterprises } = data;
    const formatedData = enterprises.data.map((enterprise: IEnterpriseBasicList) => {
      return {
        id: enterprise.id,
        name: enterprise.name
      };
    });

    return formatedData;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getBlocksUnitsList = async (enterpriseID: string): Promise<IBlocksList[]> => {
  try {
    const { data } =
      enterpriseID.length > 0
        ? await instance.get(
            `/block/getByEnterprise/${enterpriseID}?sortBy=block_model.name&limit=0`
          )
        : await instance.get('block/manager/getAllBlocks?sortBy=block_model.name&limit=0');

    const formatedData: IBlocksList[] = data.data.map(
      (blockData: { id: string; name: string; units: string[]; enterpriseId: string }) => {
        return {
          id: blockData.id,
          name: blockData.name,
          units: blockData.units,
          enterpriseId: blockData.enterpriseId
        };
      }
    );

    return formatedData;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const removeImageToRequest = async (id: string): Promise<any> => {
  try {
    const data = await instance.request({
      method: 'DELETE',
      url: `maintenance/delete/${id}`,
      baseURL: process.env.REACT_APP_STORAGE_URL
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getIdForToken = async (token: string): Promise<any> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `maintenance/maintenance-by-token?token=${token}`,
      baseURL: process.env.REACT_APP_BASE_URL
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
