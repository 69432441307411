import create from 'zustand';
import {
  deletePartner,
  editPartners,
  sortPartnersData,
  searchPartners,
  registerPartners,
  findOnePartner
} from '../../services/requests/partners/Partners.request';

import { IPartnersStoreProps } from './Partners.props';

const initialState = {
  isOpenModal: false,
  partnersList: [],
  partnersId: [],
  partnerByID: [],
  isOpenInformationModal: false,
  partnerId: '',
  page: '1',
  field: 'name',
  direction: 'ASC',
  imagePartners: '',
  placeholderFile: null,
  imagePartnersURL: '',
  signedimagePartnersUrl: '',
  image: ''
};

export const usePartnersStore = create<IPartnersStoreProps>((set, get) => ({
  ...initialState,

  handleSetPlaceholderFile: (placeholderFile) => set((state) => ({ ...state, placeholderFile })),
  setOpenModal: (isOpenModal) => set((state) => ({ ...state, isOpenModal })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  handleSetPartnerList: (partnersList) => set((state) => ({ ...state, partnersList })),
  handleSetPartnersId: (partnersId) => set((state) => ({ ...state, partnersId })),
  handleSetPartnerId: (partnerId) => set((state) => ({ ...state, partnerId })),
  handleSetPage: (page) => set((state) => ({ ...state, page })),
  setPartnerByID: (partnerByID) => set((state) => ({ ...state, partnerByID })),
  setSignedImgUrl: (signedimagePartnersUrl: string) =>
    set((state) => ({ ...state, signedimagePartnersUrl })),
  setImagePartnersURL: (imagePartnersURL: string) =>
    set((state) => ({ ...state, imagePartnersURL })),
  handleSetImage: (image) => set((state) => ({ ...state, image })),

  // requests
  deletePartnerRequest: async (id) => {
    const { partnersList, partnersId, handleSetPartnerList, handleSetPartnersId } = get();

    try {
      await deletePartner(id);
      const newPartnersList = partnersList.filter((partner) => partner.id !== id);
      const newPartnersId = partnersId.filter((partnerId) => partnerId !== id);
      handleSetPartnerList(newPartnersList);
      handleSetPartnersId(newPartnersId);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getPartnerById: async () => {
    try {
      const { partnerId, setPartnerByID, setImagePartnersURL } = get();
      if (partnerId.length === 0) return [];
      const data = await findOnePartner(partnerId);
      setPartnerByID(data);
      setImagePartnersURL(data.logoUrl || '');
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  editPartnerRequest: async (partnerData, id) => {
    try {
      await editPartners(partnerData, id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllSortPartnersDataRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortPartnersData(page, sortByField, sortByDirection, searchString);

      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  searchPartnersRequest: async (partnersData, page, sortByField, sortByDirection) => {
    try {
      const data = await searchPartners(partnersData, sortByField, sortByDirection, page);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  registerPartnerRequest: async (partnerData: FormData) => {
    try {
      await registerPartners(partnerData);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  handleInformationModalOpen: () =>
    set((state) => ({ isOpenInformationModal: !state.isOpenInformationModal }))
}));
