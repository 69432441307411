import { useEffect, useState } from 'react';
import { formatData } from '../../../helpers/formatData';
import { useLostItemsStore, useLoadModalStore } from '../../../store';
import { IAllItemLostDataById } from '../../../services/requests/lostItems/LostItems.dto';

export const useModalInfoController = (
  onClickConfirm: () => void,
  handleAprove: () => Promise<void>,
  handleReprove: () => Promise<void>,
  toggleModal: () => void
) => {
  const [resident, setResident] = useState('');
  const [owner, setOwner] = useState('');
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [phone, setPhone] = useState('');
  const [itemStatus, setItemStatus] = useState('');
  const [changeConfirmStatus, setChangeConfirmStatus] = useState('');
  const [changeBackStatus, setChangeBackStatus] = useState('');

  const { toogleLoading } = useLoadModalStore();
  const { getLostItemsDetailsByIdRequest, id, signedimageLostItemsUrl, setSignedImgUrl } =
    useLostItemsStore();

  function setValuesFromRequest(data: IAllItemLostDataById) {
    setSignedImgUrl(data.picture);
    setResident(data.resident);
    setName(data.name);
    setDate(formatData(data.date));
    setDescription(data.description);
    setPhone(data.phone);
    setItemStatus(data.status);

    if (data.owner) setOwner(data.owner);

    if (itemStatus === 'PENDENT') setChangeConfirmStatus('Aprovar');
    if (itemStatus === 'PENDENT') setChangeBackStatus('Não autorizar');
    if (itemStatus === 'WAITING') setChangeConfirmStatus('Retirado pelo dono');
    if (itemStatus === 'WAITING') setChangeBackStatus('Voltar');
  }

  async function getLostItemByID() {
    toogleLoading();
    try {
      const data = await getLostItemsDetailsByIdRequest(id);
      setValuesFromRequest(data);
    } finally {
      toogleLoading();
    }
  }

  function handleClickConfirm() {
    if (itemStatus === 'PENDENT') {
      handleAprove();
      return;
    }
    onClickConfirm();
  }

  function handleClickBackButton() {
    if (itemStatus === 'PENDENT') {
      handleReprove();
      return;
    }
    toggleModal();
  }

  useEffect(() => {
    getLostItemByID();
  }, [itemStatus]);

  return {
    resident,
    owner,
    date,
    name,
    description,
    phone,
    changeConfirmStatus,
    changeBackStatus,
    itemStatus,
    handleClickConfirm,
    handleClickBackButton,
    signedimageLostItemsUrl
  };
};
