/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatData } from '../../helpers/formatData';
import {
  useActionModalStore,
  useLoadModalStore,
  useSortItemsStore,
  useRolesModalStore,
  usePaginationStore,
  useBackToLastPageModalStore,
  useInfoModalStore
} from '../../store';
import { useEmployeesStore } from '../../store/Employees/Employees.store';

import { Errors } from '../../constants/Errors';

export const useEmployeesController = () => {
  const [totalPages, setTotalPages] = useState(0);
  const [rowsId, setRowsId] = useState(['']);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [variant, setVariant] = useState('');
  const [searchString, setSearchString] = useState('');
  const [tableData, setTableData] = useState<Array<object>>([]);

  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const { isOpenBackToLastPageModal } = useBackToLastPageModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { handleSetSortFields } = useSortItemsStore();
  const { isAdmin, isManager, roles } = useRolesModalStore();
  const navigate = useNavigate();
  const {
    id,
    direction,
    field,
    isModalRegisterOpen,
    isModalEditOpen,
    setIsModalEditOpen,
    setIsModalRegisterOpen,
    handleSetEmployeesId,
    handleSetEmployeeId,
    handleSetEmployeesList,
    deleteEmployeesRequest,
    deleteAdminRequest,
    searchManagerRequest,
    searchEmployeesRequest,
    getAllEmployeeSortDataRequest,
    getAllAdminSortDataRequest
  } = useEmployeesStore();
  const { handleDrawerOpen } = useActionModalStore();

  const {
    handleSetText,
    text,
    isOpenInfoModal,
    isSuccessfully,
    handleModalOpen,
    handleSetIsSuccessfully
  } = useInfoModalStore();

  const handleNavigateRoles = () => {
    navigate('/employees/roles');
  };

  const toggleRegisterModal = () => {
    setIsEdit(false);
    setIsModalRegisterOpen(!isModalRegisterOpen);
    setVariant('register');
  };

  const toggleEditModal = () => {
    setIsModalEditOpen(!isModalEditOpen);
    setVariant('edit');
  };

  const toggleConfirmModal = () => {
    setIsModalConfirmOpen((prev) => !prev);
    setVariant('delete');
  };

  const successModal = () => {
    handleModalOpen();
    setIsModalEditOpen(false);
    setIsModalRegisterOpen(false);
    setIsModalConfirmOpen(false);
  };

  const getRequestEmployeesData = async (page: number) => {
    toogleLoading();
    try {
      const data = isAdmin
        ? await getAllAdminSortDataRequest(`${page}`, field, direction, searchString)
        : await getAllEmployeeSortDataRequest(`${page}`, field, direction, searchString);

      const formatedData = data.data.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          phone: item.phone,
          email: item.email,
          date: String(item.created_at),
          function: item.job
        };
      });

      const formatedRowsId = data.data.data.map((item) => {
        return item.id;
      });

      const { totalPages: pages } = data.data;
      const { totalEmployees: employees } = data.data;

      if (data) {
        setTableData(formatedData);
        setRowsId(formatedRowsId);
        handleSetEmployeesId(formatedRowsId);
        handleSetEmployeesList(formatedData);
        setTotalPages(pages);
        setTotalEmployees(employees);
      }
    } finally {
      toogleLoading();
    }
  };

  async function changePage(page: number) {
    toogleLoading();
    try {
      const { data } = isAdmin
        ? await getAllAdminSortDataRequest(`${page}`, field, direction, searchString)
        : await getAllEmployeeSortDataRequest(`${page}`, field, direction, searchString);

      const formatedData = data.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          phone: item.phone,
          email: item.email,
          date: String(item.created_at),
          function: item.job
        };
      });

      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });

      if (data) {
        setTableData(formatedData);
        setRowsId(formatedRowsId);
        handleSetEmployeesId(formatedRowsId);
      }
    } finally {
      toogleLoading();
    }
  }

  async function handleSearch(value: string) {
    toogleLoading();
    try {
      setSearchString(value);
      const formatedDataForSearch = { queryText: value };
      const { data }: any = isAdmin
        ? await searchManagerRequest(formatedDataForSearch, field, direction, String(currentPage))
        : await searchEmployeesRequest(
            formatedDataForSearch,
            field,
            direction,
            String(currentPage)
          );

      const formatedData = data.data.map((employee: any) => {
        return {
          id: employee.id,
          name: employee.name,
          phone: employee.phone,
          email: employee.email,
          created_at: formatData(employee.created_at),
          job: employee.role
        };
      });

      const formatedRowsId = data.data.map((employee: any) => {
        return employee.id;
      });

      const { totalPages: pages } = data;
      const { totalEmployees: employees } = data;
      if (data) {
        handleSetEmployeesList(formatedData);
        handleSetEmployeesId(formatedRowsId);
        setTableData(formatedData);
        setRowsId(formatedRowsId);
        setTotalPages(pages);
        setTotalEmployees(employees);
      }
    } finally {
      toogleLoading();
    }
  }

  const actionModalData = [
    {
      ButtonsText: 'Editar',
      OnClick: () => {
        setIsEdit(true);
        toggleEditModal();
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        toggleConfirmModal();
      }
    }
  ];

  function setSortFields() {
    handleSetSortFields(['name', 'phone', 'email', 'created_at', 'role']);
  }

  const tableHeaders = isAdmin
    ? ['Nome do administrador', 'Número', 'E-mail', 'Data de cadastro', 'Função', 'Ações']
    : ['Nome do funcionário', 'Número', 'E-mail', 'Data de cadastro', 'Função', 'Ações'];

  async function sortFuncEmployee(page: string, sortByField: string, sortByDirection: string) {
    const data: any = isAdmin
      ? await getAllAdminSortDataRequest(page, sortByField, sortByDirection, searchString)
      : await getAllEmployeeSortDataRequest(page, sortByField, sortByDirection, searchString);

    return data;
  }

  const onSubmitDelete = async () => {
    if (!isAdmin && !isManager && !roles.includes('funcionarios')) {
      toggleConfirmModal();
      handleSetText('O usuário não possuí a funcionalidade de excluir funcionários!');
      handleSetIsSuccessfully(false);
      handleModalOpen();
      return;
    }

    if (isAdmin) {
      toogleLoading();
      try {
        await deleteAdminRequest(id);
        handleSetText('Administrador excluído com sucesso!');
        handleSetIsSuccessfully(true);
        getRequestEmployeesData(currentPage);
      } catch (error: any) {
        handleSetText(
          (Errors as any)({})[error.message] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        handleSetIsSuccessfully(false);
      } finally {
        toogleLoading();
      }
    } else {
      toogleLoading();
      try {
        await deleteEmployeesRequest(id);
        handleSetText('Funcionário excluído com sucesso!');
        handleSetIsSuccessfully(true);
        getRequestEmployeesData(currentPage);
      } catch (error: any) {
        handleSetText(
          (Errors as any)({})[error.message] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        handleSetIsSuccessfully(false);
      } finally {
        toogleLoading();
      }
    }
    successModal();
  };

  function closeModal() {
    if (isModalRegisterOpen) {
      toggleRegisterModal();
    }

    if (isModalEditOpen) {
      toggleEditModal();
    }
  }

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (isModalRegisterOpen || isModalEditOpen || isAdmin) return;
    getRequestEmployeesData(currentPage);
    setSortFields();
  }, [isOpenInfoModal, isAdmin]);

  return {
    isEdit,
    rowsId,
    isAdmin,
    variant,
    tableData,
    totalPages,
    changePage,
    text,
    isOpenInfoModal,
    isSuccessfully,
    handleModalOpen,
    tableHeaders,
    successModal,
    onSubmitDelete,
    totalEmployees,
    toggleEditModal,
    isModalEditOpen,
    handleSetText,
    handleSetIsSuccessfully,
    actionModalData,
    handleDrawerOpen,
    toggleConfirmModal,
    isModalConfirmOpen,
    handleSetEmployeeId,
    sortFuncEmployee,
    handleNavigateRoles,
    isModalRegisterOpen,
    toggleRegisterModal,
    getRequestEmployeesData,
    handleSearch,
    closeModal,
    isOpenBackToLastPageModal
  };
};
