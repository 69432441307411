export const iconsName = [
  'Início',
  'Empreendimentos',
  'Manutenções',
  // 'Relatórios',
  'Encomendas',
  'Funcionários',
  'Moradores',
  'Ocorrências',
  'Visitantes',
  'Informativos',
  'Parceiros',
  'Votações',
  'Documentos',
  'Achados e perdidos',
  'Áreas/Reservas',
  'Boletos'
];

export const linkName = [
  'home',
  'enterprises',
  'maintenance',
  // 'reports',
  'orders',
  'employees',
  'residents',
  'occurrences',
  'visitors',
  'informatives',
  'partners',
  'vote',
  'docs',
  'lostitems',
  'area',
  'payments'
];
