/* eslint-disable @typescript-eslint/no-var-requires */
import { Grid, Typography, Link } from '@mui/material';
import Background from './Enterprises.style';
import {
  CustomSearchInput,
  CustomTable,
  ActionModal,
  DeleteModal,
  InfoModal
} from '../../components/index';
import useEnterprisesController from './Enterprises.controller';

const enterpriseactionedit = require('../../assets/images/enterpriseactionedit.svg');
const enterpriseactionreport = require('../../assets/images/enterpriseactionreport.svg');
const enterpriseactiontrash = require('../../assets/images/enterpriseactiontrash.svg');

export function Enterprises() {
  const {
    tableHeaders,
    tableData,
    actionModalData,
    isOpenDeleteModal,
    deleteData,
    id,
    rowsId,
    isOpenInfoModal,
    isSuccessfully,
    infoText,
    totalPages,
    totalEnterprises,
    sortEnterpriseData,
    handleDrawerOpen,
    deleteEnterpriseOfRow,
    handleModalOpen,
    handleDeleteModalOpen,
    changePage,
    handleSearch,
    handleSetEnterpriseId
  } = useEnterprisesController();

  return (
    <Background>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Empreendimentos Cadastrados
          </Typography>
          <CustomSearchInput id="enterprise_search" handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={3} alignSelf="flex-end">
          <Link
            href="/enterprises/registerenterprise"
            className="registerEnterpriseButton"
            id="registerEnterprise"
            underline="none">
            Cadastrar Empreendimento
          </Link>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            rowsId={rowsId}
            tableHead={tableHeaders}
            tableData={tableData}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalEnterprises}
            funcToChangePage={changePage}
            handleSetItemId={handleSetEnterpriseId}
            noFilesFoundText="Não há nenhum registro de empreendimentos no momento."
            sortFunc={sortEnterpriseData}
            containId
            ActionModal={
              <ActionModal
                ActionModalData={actionModalData}
                ButtonsIcon={[
                  <enterpriseactionedit.ReactComponent className="actionIcon" />,
                  <enterpriseactionreport.ReactComponent className="actionIcon" />,
                  <enterpriseactiontrash.ReactComponent className="actionIcon" />
                ]}
              />
            }
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title={deleteData.title}
          onClickDelete={() => deleteEnterpriseOfRow(id)}
          handleModalClose={handleDeleteModalOpen}
        />
      )}
      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={infoText} />
      )}
    </Background>
  );
}
