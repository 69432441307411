/* eslint-disable @typescript-eslint/no-unused-expressions */
import create from 'zustand';
import { LoadModalProps } from './LoadModal.props';

const initialState = {
  isOpenLoad: false
};

const useLoadModalStore = create<LoadModalProps>((set) => ({
  ...initialState,

  toogleLoading: (isEdit?: boolean, value?: boolean) => {
    isEdit
      ? set((state) => ({ isOpenLoad: value ? !state.isOpenLoad : value }))
      : set((state) => ({ isOpenLoad: !state.isOpenLoad }));
  }
}));

export { useLoadModalStore };
