import create from 'zustand';
import {
  deleteUnit,
  searchUnits,
  sortUnitsData,
  updateUnitName,
  getUnitById,
  registerUnit,
  getSimpleUnitList
} from '../../services/requests/Units/Units.request';
import { IUnitsStoreProps } from './Units.props';

const initialState = {
  blockID: '',
  unitID: '',
  unitName: '',
  unitsIDs: [],
  unitsList: undefined,
  unitByID: undefined,
  page: '1',
  isOpenRegisterModal: false,
  isOpenRenameModal: false,
  field: 'unit_model.name',
  direction: 'ASC',
  totalUnits: 0,
  totalPages: 1
};

export const useUnitsStore = create<IUnitsStoreProps>((set, get) => ({
  ...initialState,

  setBlockID: (blockID) => set((state) => ({ ...state, blockID })),
  setUnitID: (unitID) => set((state) => ({ ...state, unitID })),
  setUnitName: (unitName) => set((state) => ({ ...state, unitName })),
  setUnitsIDs: (unitsIDs) => set((state) => ({ ...state, unitsIDs })),
  setUnitsList: (unitsList) => set((state) => ({ ...state, unitsList })),
  setTotalUnits: (totalUnits) => set((state) => ({ ...state, totalUnits })),
  setTotalPages: (totalPages) => set((state) => ({ ...state, totalPages })),
  setUnitByID: (unitByID) => set((state) => ({ ...state, unitByID })),
  setOpenRegisterModal: (isOpenRegisterModal) =>
    set((state) => ({ ...state, isOpenRegisterModal })),
  setOpenRenameModal: (isOpenRenameModal) => set((state) => ({ ...state, isOpenRenameModal })),
  handleSetPage: (page) => set((state) => ({ ...state, page })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),

  getAllSortedUnitsData: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortUnitsData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getSimpleUnitListData: async (blockID) => {
    try {
      // eslint-disable-next-line @typescript-eslint/return-await
      return await getSimpleUnitList(blockID);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getUnitByIDRequest: async (blockID) => {
    try {
      const data = await getUnitById(blockID);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  deleteUnitRequest: async (blockID) => {
    try {
      await deleteUnit(blockID);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchUnitRequest: async (params, sortByField, sortByDirection, page) => {
    try {
      const data = await searchUnits(params, page, sortByField, sortByDirection);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  renameUnitRequest: async (unitID, newName) => {
    try {
      const data = await updateUnitName(unitID, newName);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  registerUnitRequest: async (units, blockId) => {
    try {
      await registerUnit(units, blockId);
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));
