import styled, { css } from 'styled-components';

const Container = styled.div`
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 16px 10px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .icon {
    color: rgba(0, 0, 0, 0.5);
  }
  .title {
    scroll-behavior: auto;
    flex-direction: column;
    display: flex;
    width: 100%;
  }

  #fileInput {
    cursor: pointer;
    color: transparent;
    position: absolute;
    z-index: 100;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    scroll-behavior: smooth;
    overflow: hidden;
  }
  #fileInput::file-selector-button {
    display: none;
  }

  .closeButton {
    color: #646464;
    z-index: 999999999;
  }

  .closeButton:hover {
    background-color: transparent;
    opacity: 0.9;
  }

  .fileName {
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer;
    font-size: 14px;
  }

  .fileName:hover {
    color: red;
  }

  .containerBar {
    width: 100%;
    margin-bottom: 10px;

    background-color: #eee;
    border-radius: 6px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }

  .selectedFile {
    display: flex;
    align-items: center;

    justify-content: space-between;
    flex-direction: row;

    width: 100%;
    background-color: #f5f5f5;
    margin-bottom: 8px;
    border-radius: 8px;
  }

  .fileBox {
    flex-direction: column;
    gap: 8px;
  }

  .fileImageIcon {
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

interface IProgressBar {
  progress: string;
}

export const ProgressBar = styled.div<IProgressBar>`
  ${({ progress }) => css`
    width: ${progress}%;
    height: 20px;
    border-radius: 6px;
    background-color: #662483;
  `}
`;

export default Container;
