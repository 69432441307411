import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
  color: #2c2c2c;

  .featContainer {
    padding: 2rem;
    margin-top: 1rem;
  }

  .featHeader {
    margin-bottom: 2rem;
  }

  .tableTitle {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .tableText {
    font-size: 14px;
  }
`;
