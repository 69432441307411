import styled from 'styled-components';
import { Box, Typography, TextField } from '@mui/material';

export const Container = styled.div`
  width: 100%;

  .subTitle {
    margin-top: 21px;
    font-size: 20px;
    color: #2c2c2c;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
  }

  .timeButton {
    min-width: 220px;
    height: 48px;
    padding: 14px 56px;
    background: #662483;
    border-radius: 6px;
    color: #fff;

    &:hover {
      background: #662483;
      opacity: 0.9;
    }
  }

  .timeContainer {
    position: relative;
  }

  .clockIcon {
    position: absolute;
    right: 12px;
  }

  .infoNextDayText {
    font-size: 12px;
    margin-top: 2px;
    color: #662483;
  }

  .trashButton {
    &:hover {
      background-color: transparent;
      opacity: 0.9;
    }
  }
`;

export const Header = styled(Box).attrs({
  marginTop: '2rem'
})``;

export const ErrorMessage = styled(Typography).attrs({
  color: 'red',
  fontSize: '12px',
  marginTop: '4px',
  maxHeight: '2rem'
})``;

export const AreaTimesWrapper = styled(Box).attrs({})`
  margin-top: 1rem;
`;

export const TimeContainer = styled(Box).attrs({})`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 1rem;

  .inputsRow {
    display: flex;
    align-items: flex-start;
  }

  .labelText {
    margin-right: 20px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 15px;
    color: #2c2c2c;
    margin-top: 8px;
  }

  .timeInput {
    flex: 1;
    min-width: 297px;

    input {
      height: 16px;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }
  }

  @media (max-width: 1387px) {
    .timeInput {
      min-width: 210px;
    }

    .timeContainer {
      input,
      div {
        width: 210px;
      }
    }
  }

  @media (max-width: 1212px) {
    .inputsRow {
      display: flex;
      flex-direction: column;
    }

    .timeButton {
      margin-top: 31px;
      min-width: 0px;
      width: 140px;
    }
  }

  @media (max-width: 972px) {
    flex-direction: column;
    .timeContainer {
      input,
      div {
        width: 100%;
      }
    }

    .timeButton {
      margin-top: 0px;
      width: 100%;
    }
  }
`;

export const TimeInput = styled(TextField).attrs({
  maxWidth: '320px',
  display: 'flex'
})``;
