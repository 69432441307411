/* eslint-disable react/jsx-props-no-spreading */
import { Modal as ModalComponent } from '@mui/material';
import { CustomFileInput } from '../CustomFileInput/CustomFileInput';
import { useModalController } from './Modal.controller';

import { ModalProps } from './Modal.props';
import {
  ModalBackButton,
  ModalButtonArea,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalContent,
  ModalNextButton,
  ModalTitle,
  Container
} from './Modal.style';

export function Modal({
  isModalOpen,
  toggleModal,
  backButton,
  foldId,
  setSuccess,
  setIsModalRegisterOpen,
  getAllFiles,
  onComplete,
  setText,
  toggleSuccessModal
}: ModalProps) {
  const { sendRequest, isProgress, isNameFile, isDisable } = useModalController(
    foldId,
    getAllFiles,
    setIsModalRegisterOpen,
    onComplete,
    setText,
    setSuccess
  );

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <Container>
        <ModalContent>
          <ModalCloseButton onClick={toggleModal}>
            <ModalCloseButtonIcon />
          </ModalCloseButton>

          <ModalTitle>Enviar arquivos</ModalTitle>

          <CustomFileInput
            sendRequest={sendRequest}
            accept="*"
            type="pdf"
            foldId={foldId}
            isProgress={isProgress}
            isNameFile={isNameFile}
            setText={setText}
            setSuccess={setSuccess}
            toggleSuccessModal={toggleSuccessModal}
          />

          <ModalButtonArea>
            <ModalNextButton
              id="doc-file-save-btn"
              style={{
                backgroundColor: '#662483',
                color: 'white',
                width: '48.5%',
                height: 44,
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 500,
                textTransform: 'capitalize',
                opacity: isDisable ? 0.7 : 1
              }}
              disabled={isDisable}
              variant="contained"
              disableElevation
              onClick={() => sendRequest()}>
              Salvar
            </ModalNextButton>

            <ModalBackButton
              variant="contained"
              style={{
                backgroundColor: '#F0F1F5',
                width: '48.5%',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 500,
                height: 44,
                textTransform: 'capitalize'
              }}
              disableElevation
              onClick={backButton}>
              Cancelar
            </ModalBackButton>
          </ModalButtonArea>
        </ModalContent>
      </Container>
    </ModalComponent>
  );
}
