/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, TextField } from '@mui/material';
import { AutoCompleteProps } from './Autocomplete.props';
import Container from './Autocomplete.style';

export function AutocompleteInput({
  options,
  isDisabled = false,
  setSelectedItem,
  handleSearch
}: AutoCompleteProps) {
  return (
    <Container>
      <Autocomplete
        disablePortal
        disabled={isDisabled}
        id="combo-box-demo"
        options={options}
        sx={{ width: '100%', background: !isDisabled ? 'transparent' : 'rgba(122,122,122,0.3)' }}
        noOptionsText="nenhum resultado encontrado"
        getOptionLabel={(option: { name: string; id: string }) => option.name}
        onInputChange={(e, value) => handleSearch(value)}
        onChange={(event, newValue) => {
          setSelectedItem(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Container>
  );
}
