import create from 'zustand';
import { AreaProps } from './Areas.props';

import {
  registerArea,
  getAreaDetailsById,
  sortAreasData,
  deleteArea,
  editArea,
  searchAreas,
  getImageToRequest,
  removeImageToRequest
} from '../../services/requests/areas/Areas.request';

const initialState = {
  areaId: '',
  areasId: [],
  areaList: [],
  field: 'area_model.is_available',
  direction: 'ASC',
  buttonSelected: 'Domingo',
  availableDay: 'Domingo',
  locationImageToRequest: [],
  image: '',
  reservationPeriodAllWeek: [[], [], [], [], [], [], []],
  images: [],
  formatedImageToRequest: [],
  imagesToDelete: [],
  filesToGetLink: []
};

const useAreaStore = create<AreaProps>((set, get) => ({
  ...initialState,
  handleReservationPeriodAllWeek: (reservationPeriodAllWeek) => {
    reservationPeriodAllWeek.forEach((periodOfDay: []) => {
      periodOfDay.forEach((dayHours: { initial_time: string; final_time: string }) => {
        if (dayHours.initial_time.length === 5)
          dayHours.initial_time = `${dayHours.initial_time}:00`;
        if (dayHours.final_time.length === 5) dayHours.final_time = `${dayHours.final_time}:00`;
      });
    });

    set((state) => ({ ...state, reservationPeriodAllWeek }));
  },
  handleSetFilesToGetLink: (filesToGetLink) => set((state) => ({ ...state, filesToGetLink })),
  handleSetImagesToDelete: (imagesToDelete) => set((state) => ({ ...state, imagesToDelete })),
  handleSetButtonSelected: (weekName) => set((state) => ({ ...state, buttonSelected: weekName })),
  handleSetAvailableDay: (availableDay) => set((state) => ({ ...state, availableDay })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  handleSetAreaId: (areaId) => set((state) => ({ ...state, areaId })),
  handleSetAreasId: (areasId) => set((state) => ({ ...state, areasId })),
  handleSetImagesToRequest: (formatedImageToRequest) =>
    set((state) => ({
      ...state,
      formatedImageToRequest
    })),
  handleSetImages: (images) => set((state) => ({ ...state, images })),
  handleSetImage: (image) => set((state) => ({ ...state, image })),
  handleSetLocationImagesToRequest: (locationImageToRequest) =>
    set((state) => ({ ...state, locationImageToRequest })),
  handleSetAreaList: (areaList) => set((state) => ({ ...state, areaList })),
  handleRemoveArea: (areaId) =>
    set((state) => ({
      ...state,
      areaList: state.areaList.filter((area) => area.id !== areaId),
      areasId: state.areasId.filter((id) => id !== areaId)
    })),

  registerAreaRequest: async (areaData) => {
    try {
      await registerArea(areaData);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAreaDetailsByIdRequest: async (areaId) => {
    try {
      const data = await getAreaDetailsById(areaId);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllSortAreasDataRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortAreasData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  deleteAreaRequest: async (id) => {
    try {
      await deleteArea(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  editAreaRequest: async (areaData, id) => {
    try {
      await editArea(areaData, id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchAreasRequest: async (areasData, sortByField, sortByDirection, page) => {
    try {
      const data = await searchAreas(areasData, page, sortByField, sortByDirection);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getImageLinkRequest: async (pictureData, id) => {
    try {
      const { data } = await getImageToRequest(pictureData, id);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  removeImageRequest: async (id) => {
    try {
      await removeImageToRequest(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));

export { useAreaStore };
