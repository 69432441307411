import { Button, Grid, Typography } from '@mui/material';
import useWeekListController from './WeekList.controller';
import Container from './WeekList.style';

export function WeekList() {
  const { handleSetButtonSelected, buttonSelected } = useWeekListController();

  return (
    <Container container spacing={1}>
      <Grid item>
        <Button
          className={buttonSelected === 'Domingo' ? 'selectedWeekButton' : 'weekButton'}
          onClick={() => handleSetButtonSelected('Domingo')}>
          <Typography className={buttonSelected === 'Domingo' ? 'isSelected' : 'buttonText'}>
            Domingo
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={buttonSelected === 'Segunda' ? 'selectedWeekButton' : 'weekButton'}
          onClick={() => handleSetButtonSelected('Segunda')}>
          <Typography className={buttonSelected === 'Segunda' ? 'isSelected' : 'buttonText'}>
            Segunda
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={buttonSelected === 'Terça' ? 'selectedWeekButton' : 'weekButton'}
          onClick={() => handleSetButtonSelected('Terça')}>
          <Typography className={buttonSelected === 'Terça' ? 'isSelected' : 'buttonText'}>
            Terça
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={buttonSelected === 'Quarta' ? 'selectedWeekButton' : 'weekButton'}
          onClick={() => handleSetButtonSelected('Quarta')}>
          <Typography className={buttonSelected === 'Quarta' ? 'isSelected' : 'buttonText'}>
            Quarta
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={buttonSelected === 'Quinta' ? 'selectedWeekButton' : 'weekButton'}
          onClick={() => handleSetButtonSelected('Quinta')}>
          <Typography className={buttonSelected === 'Quinta' ? 'isSelected' : 'buttonText'}>
            Quinta
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={buttonSelected === 'Sexta' ? 'selectedWeekButton' : 'weekButton'}
          onClick={() => handleSetButtonSelected('Sexta')}>
          <Typography className={buttonSelected === 'Sexta' ? 'isSelected' : 'buttonText'}>
            Sexta
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={buttonSelected === 'Sábado' ? 'selectedWeekButton' : 'weekButton'}
          onClick={() => handleSetButtonSelected('Sábado')}>
          <Typography className={buttonSelected === 'Sábado' ? 'isSelected' : 'buttonText'}>
            Sábado
          </Typography>
        </Button>
      </Grid>
    </Container>
  );
}
