import styled from 'styled-components';

const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #662483 -0.25%, #2c264c 100.7%);
  height: 100%;

  .logoImage {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

export default Background;
