import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useActionModalStore,
  useDeleteModalStore,
  useInfoModalStore,
  useLoadModalStore,
  useResidentStore,
  useResidentModalStore,
  useBackToLastPageModalStore,
  useSortItemsStore,
  usePaginationStore
} from '../../store';
import { deleteResidentsData, residentsTableHeaders } from '../../constants';
import { Errors } from '../../constants/Errors';

function useResidentsController() {
  const [rowsId, setRowsId] = useState(['']);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResidents, setTotalResidents] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [searchString, setSearchString] = useState('');

  const { handleDrawerOpen } = useActionModalStore();
  const { handleResidentModalOpen, isOpenResidentModal } = useResidentModalStore();
  const { isOpenBackToLastPageModal } = useBackToLastPageModalStore();
  const {
    isOpenInfoModal,
    handleModalOpen,
    handleSetIsSuccessfully,
    handleSetText,
    isSuccessfully,
    text
  } = useInfoModalStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { handleSetSortFields } = useSortItemsStore();
  const { toogleLoading } = useLoadModalStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const {
    handleSetResidentsList,
    handleRemoveOneResident,
    handleSetResidentsId,
    handleSetResidentId,
    sortResidentsDataRequest,
    searchResidentsRequest,
    deleteResidentRequest,
    getAllResidentsDataRequest,
    resendResidentEmailRequest,
    getResidentDataRequest,
    field,
    direction,
    residentId,
    residentsId,
    residentsList
  } = useResidentStore();

  const handleRegisterResidentModal = () => {
    setIsEdit(false);
    handleResidentModalOpen();
  };

  async function sortResidentsData(page: string, sortByField: string, sortByDirection: string) {
    toogleLoading();

    try {
      const { data } = await sortResidentsDataRequest(
        page,
        sortByField,
        sortByDirection,
        searchString
      );

      const formatedRowsId = data.data.map((resident) => {
        return resident.id;
      });

      setTotalPages(data.totalPages || 0);
      setTotalResidents(data.totalResidents || 0);

      if (data) {
        setRowsId(formatedRowsId);
        handleSetResidentsList(data.data);
        handleSetResidentsId(formatedRowsId);
      }
    } finally {
      toogleLoading();
    }
  }

  function formatRequestData(data: typeof residentsList) {
    return data.map((resident) => {
      return {
        id: resident.id,
        name: resident.name,
        phone: resident.phone,
        email: resident.email,
        created_at: resident.created_at,
        unitId: resident.unitId,
        block: resident.block
      };
    });
  }

  const getResidentsData = async (page: number) => {
    toogleLoading();

    try {
      const { data } = await getAllResidentsDataRequest(page);
      const formatedData = formatRequestData(data.data);

      const formatedRowsId = data.data.map((resident) => {
        return resident.id;
      });

      setTotalPages(data.totalPages || 0);
      setTotalResidents(data.totalResidents || 0);

      if (data) {
        setRowsId(formatedRowsId);
        handleSetResidentsList(formatedData);
        handleSetResidentsId(formatedRowsId);
      }
    } finally {
      toogleLoading();
    }
  };

  async function handleSearch(value: string) {
    const formatedDataForSearch = { queryText: value };
    setSearchString(value);

    const { data } = await searchResidentsRequest(
      formatedDataForSearch,
      field,
      direction,
      String(currentPage)
    );
    const formatedData = formatRequestData(data.data);

    const formatedRowsId = data.data.map((resident) => {
      return resident.id;
    });

    setTotalPages(data.totalPages || 0);
    setTotalResidents(data.totalResidents || 0);

    if (data) {
      handleSetResidentsList(formatedData);
      handleSetResidentsId(formatedRowsId);
    }
  }

  const resendEmail = async () => {
    toogleLoading();
    try {
      const { data } = await getResidentDataRequest(residentId);
      await resendResidentEmailRequest(data.email);
    } catch {
      toast.error('Não foi possível reenviar o email!');
    } finally {
      toogleLoading();
    }
  };

  const actionModalData = [
    {
      ButtonsText: 'Editar',
      OnClick: () => {
        setIsEdit(true);
        handleResidentModalOpen();
        handleDrawerOpen();
      }
    },

    {
      ButtonsText: 'Reenviar link',
      OnClick: () => {
        resendEmail();
      }
    },

    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        handleDeleteModalOpen();
        handleDrawerOpen();
      }
    }
  ];

  function deleteResidentOfRow(idOfResident: string) {
    toogleLoading();
    try {
      deleteResidentRequest(idOfResident);
      handleRemoveOneResident(idOfResident);
      handleSetIsSuccessfully(true);
      handleSetText('Morador excluído com sucesso');
    } catch (error: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
      handleModalOpen();
    }
  }

  function setResidentsIfExist() {
    if (residentsList.length <= 0) return;
    setRowsId(residentsId);
  }

  async function changePage(page: number) {
    toogleLoading();
    try {
      const { data } = await getAllResidentsDataRequest(page);
      const formatedData = formatRequestData(data.data);

      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });

      if (data) {
        handleSetResidentsList(formatedData);
        handleSetResidentsId(formatedRowsId);
      }
    } finally {
      toogleLoading();
    }
  }

  function setSetSortFields() {
    handleSetSortFields([
      'resident.name',
      'resident.phone',
      'resident.email',
      'resident.created_at',
      'unit.name',
      'block.name'
    ]);
  }

  useEffect(() => {
    getResidentsData(currentPage);
    setSetSortFields();
  }, [isOpenInfoModal]);

  useEffect(() => {
    setResidentsIfExist();
    if (!isOpenInfoModal) {
      handleSetIsSuccessfully(false);
      handleSetText('');
    }
  }, [isOpenInfoModal]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    tableData: residentsList,
    tableHeaders: residentsTableHeaders,
    isEdit,
    rowsId,
    residentsId,
    infoText: text,
    residentId,
    deleteResidentsData,
    totalPages,
    totalResidents,
    isOpenInfoModal,
    isOpenDeleteModal,
    isSuccessfully,
    actionModalData,
    isOpenResidentModal,
    isOpenBackToLastPageModal,
    sortResidentsData,
    handleSearch,
    handleModalOpen,
    handleDeleteModalOpen,
    handleSetResidentId,
    deleteResidentOfRow,
    handleDrawerOpen,
    handleRegisterResidentModal,
    changePage,
    getResidentsData,
    handleResidentModalOpen
  };
}

export default useResidentsController;
