/* eslint-disable @typescript-eslint/no-var-requires */
import { Typography, Button, Box } from '@mui/material';
import { useEnterpriseStore } from '../../../../store';
import Container, { HomeButtonContainer } from './TabsNavigator.style';

import informatives from '../../../../assets/images/informatives.svg';
import orders from '../../../../assets/images/orders.svg';
import profile from '../../../../assets/images/profile.svg';
import financial from '../../../../assets/images/financial.svg';

const homeSvg = require('../../../../assets/images/home.svg');

function TabsNavigator() {
  const { colors } = useEnterpriseStore();
  return (
    <Container>
      <Button className="tabButton">
        <Box height="18px">
          <img src={informatives} alt="informatives" height="100%" />
        </Box>
        <Typography fontSize="7.5px" color="A9A9A9">
          Informativos
        </Typography>
      </Button>
      <Button className="tabButton">
        <Box height="18px">
          <img src={orders} alt="orders" height="100%" />
        </Box>
        <Typography fontSize="7.5px" color="A9A9A9">
          Encomendas
        </Typography>
      </Button>
      <Button className="tabButton">
        <HomeButtonContainer primaryColor={colors.primary}>
          <homeSvg.ReactComponent className="homeSvg" />
        </HomeButtonContainer>
        <Typography
          fontSize="7.5px"
          color={colors.primary || 'A9A9A9'}
          paddingTop={0.95}
          paddingBottom={0.95}>
          Início
        </Typography>
      </Button>
      <Button className="tabButton">
        <Box height="18px">
          <img src={profile} alt="profile" height="100%" />
        </Box>
        <Typography fontSize="7.5px" color="A9A9A9">
          Perfil
        </Typography>
      </Button>
      <Button className="tabButton">
        <Box height="18px">
          <img src={financial} alt="financial" height="100%" />
        </Box>
        <Typography fontSize="7.5px" color="A9A9A9">
          Financeiro
        </Typography>
      </Button>
    </Container>
  );
}

export default TabsNavigator;
