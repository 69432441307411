export const informativesTableHeader = [
  'Enviado',
  'Assunto',
  'Por',
  'Bloco Receptor',
  'Unidades Receptoras',
  'Ações'
];

export const informativesDeleteMessage = {
  title: 'Tem certeza que deseja excluir este informativo?'
};
