import create from 'zustand';
import { InformativesProps } from './Informatives.props';
import {
  deleteInformative,
  searchInformative,
  registerInformative,
  editInformative,
  sortInformativesData,
  getInformativeDetailsById,
  getImageToRequest,
  removeImageToRequest,
  getUnitsOfInformativeById,
  getAllUnitsOfBlock
} from '../../services/requests/informatives/Informatives.request';

const initialState = {
  informativeId: '',
  informativesId: [],
  informativesList: [],
  field: 'info_model.createdAt',
  isInfoModalOpen: false,
  direction: 'DESC',
  page: '1',
  block: '',
  unit: '',
  blocksIDsToRequest: [],
  unitsIDsToRequest: [],
  formatedImageToRequest: [],
  locationImageToRequest: [],
  unitsChoicedoModalOpen: false,
  images: [],
  imageName: [],
  imageSize: [],
  imageToPreview: '',
  imageType: [],
  image: '',
  imagesToDelete: [],
  filesToGetLink: []
};

const useInformativesStore = create<InformativesProps>((set, get) => ({
  ...initialState,

  handleSetFilesToGetLink: (filesToGetLink) => set((state) => ({ ...state, filesToGetLink })),
  handleSetImagesToDelete: (imagesToDelete) => set((state) => ({ ...state, imagesToDelete })),
  handleSetInformativeId: (informativeId) => set((state) => ({ ...state, informativeId })),
  setUnitsIDsToRequest: (unitsIDsToRequest) => set((state) => ({ ...state, unitsIDsToRequest })),
  setBlocksIDsToRequest: (blocksIDsToRequest) => set((state) => ({ ...state, blocksIDsToRequest })),
  handleSetUnitsChoicedoModalOpen: (unitsChoicedoModalOpen) =>
    set((state) => ({ ...state, unitsChoicedoModalOpen })),
  setImageName: (imageName) => set((state) => ({ ...state, imageName })),
  setImageSize: (imageSize) => set((state) => ({ ...state, imageSize })),
  setImageType: (imageType) => set((state) => ({ ...state, imageType })),
  setImageToPreview: (imageToPreview) => set((state) => ({ ...state, imageToPreview })),
  handleSetBlock: (block) => set((state) => ({ ...state, block })),
  handleSetUnit: (unit) => set((state) => ({ ...state, unit })),
  getImageLinkRequest: async (pictureData, id) => {
    try {
      const { data } = await getImageToRequest(pictureData, id);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  removeImageRequest: async (id) => {
    try {
      await removeImageToRequest(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  handleSetImage: (image) => set((state) => ({ ...state, image })),

  handleSetLocationImagesToRequest: (locationImageToRequest) =>
    set((state) => ({ ...state, locationImageToRequest })),
  setIsInfoModalOpen: (isInfoModalOpen) => set((state) => ({ ...state, isInfoModalOpen })),
  handleSetInformativesId: (informativesId) => set((state) => ({ ...state, informativesId })),
  handleSetInformativesList: (informativesList) => set((state) => ({ ...state, informativesList })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  handleRemoveInformative: async (informativeId) =>
    set((state) => ({
      ...state,
      informativesList: state.informativesList.filter(
        (informative: { id: string }) => informative.id !== informativeId
      ),
      informativesId: state.informativesId.filter((id: string) => id !== informativeId)
    })),
  handleSetImages: (images) => set((state) => ({ ...state, images })),
  handleSetImagesToRequest: (formatedImageToRequest) =>
    set((state) => ({
      ...state,
      formatedImageToRequest
    })),
  getInformativesDetailsByIdRequest: async (id) => {
    try {
      const data = await getInformativeDetailsById(id);
      set({ unitsIDsToRequest: data.data.unitsId });
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  getAllUnitsOfBlockRequest: async (blockId) => {
    try {
      const data = await getAllUnitsOfBlock(blockId);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllSortInformativessDataRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortInformativesData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchInformativessRequest: async (informativesData, sortByField, sortByDirection, page) => {
    try {
      const data = await searchInformative(informativesData, sortByField, sortByDirection, page);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  registerInformativesRequest: async (informativeData) => {
    try {
      await registerInformative(informativeData);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  editInformativesRequest: async (informativeData, id) => {
    try {
      await editInformative(informativeData, id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  deleteInformativesRequest: async (id) => {
    try {
      await deleteInformative(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getUnitsOfInformativeById: async (blockId) => {
    try {
      const data = await getUnitsOfInformativeById(blockId);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));

export { useInformativesStore };
