import {
  AllVotingData,
  CreateVotingStates,
  IVotingByIdAllDataDtoOutput,
  SearchVoting,
  VotingDataByID
} from './Voting.dto';
import { formatData } from '../../../helpers/formatData';

import { instance } from '../../api';

export const createVoting = async (data: CreateVotingStates): Promise<void> => {
  try {
    return await instance.request({
      method: 'POST',
      url: 'voting',
      data
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortVotingData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IVotingByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `voting/Voting-manager/list?page=${page}&sortDirection=${sortByDirection}&sort=${sortByField}&includeMeta=true&search=${searchString}`
    });

    const formatedData = data.data.map((item: AllVotingData) => {
      return {
        id: item.id,
        sent: formatData(String(item.createdAt)),
        subject: item.subject,
        per: item.createdBy,
        views: item.views,
        votes: item.votes,
        endDate: formatData(item.endDate),
        status: item.status === 'Nao Iniciada' ? 'Não Iniciada' : item.status
      };
    });

    const totalVoting = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: formatedData, totalVoting, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getVotingDataById = async (votingId: string): Promise<{ data: VotingDataByID }> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `voting/Voting-manager/details/${votingId}`
    });

    const formatedData: VotingDataByID = {
      subject: data.subject,
      description: data.description,
      options: data.options,
      endDate: data.endDate
    };

    return { data: formatedData };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteVoting = async (id: string): Promise<void> => {
  try {
    return await instance.request({
      method: 'PATCH',
      url: `voting/voting-manager/delete/${id}`
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const renameVoting = async (id: string | undefined, name: string): Promise<void> => {
  try {
    return await instance.request({
      method: 'PUT',
      url: `documents/rename/${id}`,
      data: { name }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchVoting = async (
  VotingData: SearchVoting,
  sortByField: string,
  sortByDirection: string,
  page: string
): Promise<IVotingByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `Voting/voting-manager/list?search=${VotingData.queryText}&includeMeta=true&page=${page}&sortDirection=${sortByDirection}&sort=${sortByField}`
    });

    const formatedData = data.data.map((item: AllVotingData) => {
      return {
        id: item.id,
        sent: formatData(String(item.createdAt)),
        subject: item.subject,
        per: item.createdBy,
        views: item.views,
        votes: item.votes,
        endDate: formatData(item.endDate),
        status: item.status === 'Nao Iniciada' ? 'Não Iniciada' : item.status
      };
    });

    const totalVoting = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: formatedData, totalVoting, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const openVoting = async (id: string): Promise<void> => {
  try {
    const { data } = await instance.request({
      method: 'PATCH',
      url: `voting/Voting-manager/open/${id}`
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const closeVoting = async (id: string): Promise<void> => {
  try {
    const { data } = await instance.request({
      method: 'PATCH',
      url: `voting/Voting-manager/close/${id}`
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editVoting = async (id: string, requestData: CreateVotingStates): Promise<void> => {
  try {
    const { data } = await instance.request({
      method: 'PUT',
      url: `voting/Voting-manager/${id}`,
      data: requestData
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getVotingResult = async (): Promise<any> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: 'voting/voting-manager/results?limit=0'
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
