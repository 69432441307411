/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Modal as ModalComponent, Typography, Button } from '@mui/material';
import { usReserveInformationModalController } from './ReserveInformation.controller';
import Background from './ReserveInformation.styled';

const infoLocation = require('../../../../assets/images/infoLocation.svg');
const infoPerson = require('../../../../assets/images/infoPerson.svg');
const infoData = require('../../../../assets/images/infoData.svg');
const closeIcon = require('../../../../assets/images/close.svg');
const infoClock = require('../../../../assets/images/infoClock.svg');
const infoBell = require('../../../../assets/images/infoBell.svg');
const infoMoney = require('../../../../assets/images/infoMoney.svg');

export function ReserveInformationModal() {
  const {
    area,
    dateOfUse,
    createdAt,
    resident,
    statusValue,
    usageHours,
    apartmentNumber,
    block,
    value,
    isOpenReserveInformationModal,
    handleReserveInformationModalOpen,
    revokeReserve,
    changeStatus,
    handleCloseReserveModal
  } = usReserveInformationModalController();

  return (
    <ModalComponent
      open={isOpenReserveInformationModal}
      onClose={handleReserveInformationModalOpen}>
      <Background>
        <Box className="Container">
          <Box className="closeBtnContainer" onClick={handleCloseReserveModal}>
            <closeIcon.ReactComponent className="closeBtn" />
          </Box>
          <Box>
            <Typography className="title">Informações da Reserva</Typography>
          </Box>
          <Box className="iconContainer">
            <infoPerson.ReactComponent />

            <Box className="iconData">
              <Typography className="dataTitle">Morador</Typography>
              <Box className="dataSubtitle">{`${resident}, APTO ${apartmentNumber}, Bloco ${block}`}</Box>
            </Box>
          </Box>
          <Box className="iconContainer">
            <infoLocation.ReactComponent />

            <Box className="iconData">
              <Typography className="dataTitle">Área</Typography>
              <Box className="dataSubtitle">{area}</Box>
            </Box>
          </Box>
          <Box className="iconContainer">
            <infoData.ReactComponent />

            <Box className="iconData">
              <Typography className="dataTitle">Data de Utilização</Typography>
              <Box className="dataSubtitle">{dateOfUse}</Box>
            </Box>
          </Box>
          <Box className="iconContainer">
            <infoClock.ReactComponent />

            <Box className="iconData">
              <Typography className="dataTitle">Horários de Utilização</Typography>
              <Box className="dataSubtitle">{usageHours}</Box>
            </Box>
          </Box>
          <Box className="iconContainer">
            <infoData.ReactComponent />

            <Box className="iconData">
              <Typography className="dataTitle">Data de Solicitação</Typography>
              <Box className="dataSubtitle">{createdAt}</Box>
            </Box>
          </Box>
          <Box className="iconContainer">
            <infoBell.ReactComponent />

            <Box className="iconData">
              <Typography className="dataTitle">Status</Typography>
              <Box className={`dataSubtitle statusButton ${statusValue}`}>
                <Typography className="statusText">{statusValue}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className="iconContainer">
            <infoMoney.ReactComponent />

            <Box className="iconData">
              <Typography className="dataTitle">Valor</Typography>
              <Box className="dataSubtitle">{value}</Box>
            </Box>
          </Box>
          <Box className="boxContainer">
            {statusValue === 'Autorizado' ||
              statusValue === 'Revogado' ||
              (statusValue === 'CANCELADO' && (
                <Box className="buttonContainer">
                  <Button
                    style={{
                      backgroundColor: '#662483',
                      color: '#fff',
                      width: '50%',
                      height: 44,
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: 500,
                      textTransform: 'capitalize'
                    }}
                    variant="contained"
                    disableElevation
                    onClick={async () => changeStatus('AUTHORIZED')}>
                    Autorizar
                  </Button>
                  <Button
                    style={{
                      backgroundColor: '#F0F1F5',
                      color: '#2C2C2C',
                      width: '50%',
                      height: 44,
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: 500,
                      textTransform: 'capitalize'
                    }}
                    variant="contained"
                    disableElevation
                    onClick={revokeReserve}>
                    Revogar
                  </Button>
                </Box>
              ))}
            <Button
              style={{
                backgroundColor: '#F0F1F5',
                color: '#2C2C2C',
                width: '100%',
                height: 44,
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 500,
                textTransform: 'capitalize',
                marginTop: '12px'
              }}
              variant="contained"
              disableElevation
              onClick={handleCloseReserveModal}>
              Voltar
            </Button>
          </Box>
        </Box>
      </Background>
    </ModalComponent>
  );
}
