/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Button, Modal as ModalComponent, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import useRenameBlockModalController from './RenameBlockModal.controller';
import Background from './RenameBlockModal.style';

const close = require('../../../../assets/images/close.svg');

export function RenameBlockModal({ blockData }: { blockData: { id: string; name: string } }) {
  const { id, name } = blockData;

  const { isOpenRenameModal, closeModal, renameBlock, register, control, errors, handleSubmit } =
    useRenameBlockModalController(name, id);
  return (
    <ModalComponent open={isOpenRenameModal} onClose={closeModal}>
      <Background>
        <Box className="container">
          <Box className="closeModal">
            <Button className="closeButton" onClick={closeModal}>
              <close.ReactComponent />
            </Button>
          </Box>
          <Box className="content">
            <Typography className="title">Renomear Bloco</Typography>
            <Typography className="subTitle">Bloco</Typography>
            <Box className="inputsRow">
              <Box>
                <Box className="blockInputContainer">
                  <Box className="blockContainer">
                    <Controller
                      name="block_name"
                      control={control}
                      render={() => (
                        <Box className="blockInputContainer">
                          <Box className="blockContainer">
                            <TextField
                              className="timeInput"
                              placeholder="Renomar Bloco"
                              {...register('block_name')}
                              error={!!errors.block_name}
                            />
                          </Box>
                        </Box>
                      )}
                    />
                    <Typography className="errorMessage">{errors.block_name?.message}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="buttonsContainer">
              <Button className="saveButton" onClick={handleSubmit(renameBlock)}>
                Salvar
              </Button>
              <Button className="backButton" onClick={closeModal}>
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
      </Background>
    </ModalComponent>
  );
}
