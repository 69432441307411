/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Grid, Typography, Button } from '@mui/material';
import { useUnitsController } from './Units.controller';
import {
  ActionModal,
  DeleteModal,
  CustomSearchInput,
  CustomTable,
  InfoModal
} from '../../components';
import { RegisterUnitModal } from './Components/RegisterUnitModal/RegisterUnitModal';
import { RenameUnitModal } from './Components/RenameUnitModal/RenameUnitModal';
import Container from './Units.style';

const editIcon = require('../../assets/images/enterpriseactionedit.svg');
const trashIcon = require('../../assets/images/enterpriseactiontrash.svg');

export function Units() {
  const {
    blockName,
    enterpriseName,
    unitID,
    unitsIDs,
    unitsList,
    unitsTableHead,
    totalUnits,
    totalPages,
    isOpenRegisterModal,
    isOpenRenameModal,
    isOpenDeleteModal,
    isSuccessfully,
    infoText,
    isOpenInfoModal,
    setUnitID,
    handleActionModalData,
    handleDeleteModalOpen,
    handleOpenRegisterModal,
    handleOpenRenameModal,
    handleModalOpen,
    setIsSuccessfully,
    setInfoText,
    onSubmitDelete,
    handleSearch,
    sortDataRequest,
    changePage,
    backToBlocksPage,
    handleDrawerOpen
  } = useUnitsController();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6.5} lg={8}>
          <Typography variant="h5" fontWeight={500} marginBottom={1}>
            {`Unidades do Bloco ${blockName || 'X'}`}
          </Typography>
          <Typography variant="h6" fontWeight={500} marginBottom={4}>
            {enterpriseName || 'Empreendimento'}
          </Typography>
          <CustomSearchInput handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={4} sm={4} md={2} lg={1.5} alignSelf="flex-end">
          <Button
            id="units-back-to-blocks"
            style={{ backgroundColor: '#8B8B8B' }}
            onClick={backToBlocksPage}
            className="registerButton"
            fullWidth>
            Voltar
          </Button>
        </Grid>

        <Grid item xs={8} sm={8} md={3.5} lg={2.5} alignSelf="flex-end">
          <Button
            id="units-register"
            onClick={handleOpenRegisterModal}
            className="registerButton"
            fullWidth>
            Cadastrar Unidades
          </Button>
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            rowsId={unitsIDs}
            tableHead={unitsTableHead}
            tableData={unitsList || []}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalUnits}
            funcToChangePage={changePage}
            handleSetItemId={setUnitID}
            noFilesFoundText="Não há nenhum registro de unidade no momento."
            sortFunc={sortDataRequest}
            containId
            renderAction={(row: any) => {
              return (
                <ActionModal
                  ActionModalData={handleActionModalData(row.name)}
                  ButtonsIcon={[
                    <editIcon.ReactComponent className="actionIcon" />,
                    <trashIcon.ReactComponent className="actionIcon" />
                  ]}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Tem certeza que deseja excluir esta unidade?"
          onClickDelete={() => onSubmitDelete(unitID)}
          handleModalClose={handleDeleteModalOpen}
        />
      )}

      {isOpenRegisterModal && (
        <RegisterUnitModal
          handleSetText={setInfoText}
          handleSuccessfully={setIsSuccessfully}
          isModalOpen={isOpenRegisterModal}
          toggleModal={handleOpenRegisterModal}
        />
      )}

      {isOpenRenameModal && (
        <RenameUnitModal
          handleSetText={setInfoText}
          handleSuccessfully={setIsSuccessfully}
          isModalOpen={isOpenRenameModal}
          toggleModal={handleOpenRenameModal}
        />
      )}

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={infoText} />
      )}
    </Container>
  );
}
