/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-return */
import { useEffect, useState } from 'react';
import { useInformativesStore, usePreviewImageModalStore } from '../../../../store';

export const useDetailsModalController = () => {
  const { informativeId, getInformativesDetailsByIdRequest, handleSetImage } =
    useInformativesStore();
  const { handleImagePreviewOpen } = usePreviewImageModalStore();

  const [name, setName] = useState('');
  const [createdBy, setCreatedBy]: any = useState('');
  const [sendBy, setSendBy] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState([]);
  const [fileType, setFileType] = useState<string[]>([]);

  async function getDetailsOfInformative() {
    const { data } = await getInformativesDetailsByIdRequest(informativeId);
    setName(data.subject);
    setDescription(data.description);
    setDate(data.createdAt);
    setCreatedBy(data.createdBy);
    setSendBy(data.local);
    setFile(data.files ? data.files : []);

    const formatedFileType = data.files.map((element: { type: string }) => element.type);
    setFileType(formatedFileType);
  }

  function getImageToPreview(fileLink: any) {
    handleSetImage(fileLink);
    handleImagePreviewOpen();
  }

  useEffect(() => {
    getDetailsOfInformative();
  }, [informativeId]);

  return {
    name,
    fileType,
    sendBy,
    file,
    createdBy,
    date,
    description,
    getImageToPreview
  };
};
