/* eslint-disable @typescript-eslint/no-unused-expressions */
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

import {
  useBackToLastPageModalStore,
  useInfoModalStore,
  useInformativesStore,
  useLoadModalStore,
  useRefreshChangedPagesStore
} from '../../store';
import { IRegisterInformativeSchema } from './RegisterInformatives.props';
import { Errors } from '../../constants/Errors';

function useRegisterInformativesController() {
  const navigate = useNavigate();

  const {
    registerInformativesRequest,
    setBlocksIDsToRequest,
    setUnitsIDsToRequest,
    blocksIDsToRequest,
    unitsIDsToRequest,
    filesToGetLink,
    unit,
    formatedImageToRequest,
    imageName,
    imagesToDelete,
    imageSize,
    imageType,
    getImageLinkRequest,
    removeImageRequest,
    handleSetFilesToGetLink,
    handleSetImagesToRequest,
    handleSetImages,
    setImageName,
    setImageType,
    setImageSize
  } = useInformativesStore();

  const { toogleLoading } = useLoadModalStore();

  const { handleBackToLastPageModalOpen, isOpenBackToLastPageModal, sidebarLink } =
    useBackToLastPageModalStore();

  const { setChangedPage } = useRefreshChangedPagesStore();

  const {
    handleModalOpen,
    handleSetText,
    handleSetIsSuccessfully,
    isOpenInfoModal,
    isSuccessfully,
    text
  } = useInfoModalStore();

  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [isShowUnit, setIsShowUnit] = useState(false);
  const [isEnterpriseLocal, setIsEnterpriseLocal] = useState(true);

  function handleSetInformativeLocal(local: boolean) {
    if (local === true) {
      setBlocksIDsToRequest([]);
      setUnitsIDsToRequest([]);
    }
    setIsEnterpriseLocal(local);
  }

  async function deleteImages() {
    await imagesToDelete.forEach(async (imageLink) => {
      toogleLoading();
      try {
        await removeImageRequest(imageLink ? imageLink.split('maintenance/')[1] : imageLink);
      } finally {
        toogleLoading();
      }
    });
  }

  const informativeDefaultValues = {
    name: '',
    description: ''
  };

  const registerInformativeSchema = yup.object().shape({
    name: yup
      .string()
      .max(100, 'Assunto precisa ter até 100 caracteres.')
      .required('Por favor, preencher campo assunto.'),
    description: yup
      .string()
      .max(1000, 'Descrição precisa ter até 1000 caracteres.')
      .required('Por favor, preencher campo descrição.')
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors, touchedFields }
  } = useForm<IRegisterInformativeSchema>({
    defaultValues: informativeDefaultValues,
    resolver: yupResolver(registerInformativeSchema)
  });

  window.onbeforeunload = () => {
    const { name, description } = getValues();
    if (blocksIDsToRequest.length > 0 || name || description || unit) {
      setChangedPage(true);
      return true;
    }
    setChangedPage(false);
    return null;
  };

  function clearImagesToRegister() {
    handleSetImagesToRequest([]);
    handleSetImages('');
    setImageName([]);
    setImageType([]);
    setImageSize([]);
  }

  function selectBlocksToRequest(blocks: string[]) {
    let blocksToRegister: string[];

    (unitsIDsToRequest && unitsIDsToRequest.length > 0) || isEnterpriseLocal
      ? (blocksToRegister = [])
      : (blocksToRegister = blocks);

    return blocksToRegister;
  }

  function selectUnitssToRequest(units: string[]) {
    let unitsToRegister: string[];
    if (!units) unitsToRegister = [];

    blocksIDsToRequest.length > 1 || isEnterpriseLocal
      ? (unitsToRegister = [])
      : (unitsToRegister = units);

    return unitsToRegister;
  }

  function hasErrorOnBlockRegister() {
    const isInfoOnBlock = !isEnterpriseLocal;
    const hasNoSelectedBlock = blocksIDsToRequest.length === 0;

    return isInfoOnBlock && hasNoSelectedBlock;
  }

  const formatImage = async () => {
    const imagesFileToRequest = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const file of filesToGetLink) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        // eslint-disable-next-line no-await-in-loop
        const { data } = await getImageLinkRequest(formData, uuidv4());
        handleSetImagesToRequest([...formatedImageToRequest, data]);
        imagesFileToRequest.push(data);
      } catch (err: any) {
        toast.error(err.message);
      }
    }
    return imagesFileToRequest;
  };

  async function createNewInformatives() {
    if (hasErrorOnBlockRegister()) {
      toast.error('Selecione ao menos um bloco para registar um informativo direcionado a bloco.');
      return;
    }

    toogleLoading();

    try {
      const { description, name } = getValues();

      await deleteImages();
      const imagesFileLink = await formatImage();

      const files = imagesFileLink[0]
        ? imagesFileLink.map((item, index) => ({
            name: imageName[index],
            size: imageSize[index],
            url: item.url,
            type: imageType[index]
          }))
        : [];

      const formatedData: any = {
        blocksId: selectBlocksToRequest(blocksIDsToRequest) || [],
        description,
        subject: name,
        unitsId: selectUnitssToRequest(unitsIDsToRequest) || [],
        files
      };

      await registerInformativesRequest(formatedData);
      handleSetText('Informativo criado com sucesso!');
      handleSetIsSuccessfully(true);
      handleSetFilesToGetLink([]);
      handleModalOpen();
      clearImagesToRegister();
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[String(error.message).trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  function NavigateToListInformatives() {
    handleModalOpen();
    navigate('../informatives', { replace: true });
  }

  const backButtonToBackListInformative = () => {
    setIsBackButtonClicked(true);
    const { description, name } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    blocksIDsToRequest.length > 0 || name || description || unit
      ? handleBackToLastPageModalOpen()
      : navigate('/informatives', { replace: true });
  };

  function isModified() {
    const { name, description } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    blocksIDsToRequest.length > 0 || name || description || unit
      ? setChangedPage(true)
      : setChangedPage(false);
  }

  function handleShowUnit() {
    blocksIDsToRequest.length === 1 ? setIsShowUnit(true) : setIsShowUnit(false);
  }

  useEffect(() => {
    isModified();
  }, [touchedFields]);

  useEffect(() => {
    handleShowUnit();
  }, [blocksIDsToRequest]);

  useEffect(() => {
    clearImagesToRegister();
  }, []);

  return {
    control,
    register,
    errors,
    handleSubmit,
    createNewInformatives,
    handleModalOpen,
    NavigateToListInformatives,
    backButtonToBackListInformative,
    handleSetInformativeLocal,
    isEnterpriseLocal,
    isBackButtonClicked,
    isShowUnit,
    isOpenInfoModal,
    isSuccessfully,
    text,
    isOpenBackToLastPageModal,
    sidebarLink
  };
}

export default useRegisterInformativesController;
