import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useActionModalStore,
  useBackToLastPageModalStore,
  useDeleteModalStore,
  useInfoModalStore,
  useLoadModalStore,
  usePaginationStore,
  useSortItemsStore
} from '../../store';
import { useDocumentsStore } from '../../store/Documents/Documents.store';
import { SearchDocuments } from '@/services/requests/documents/Documents.dto';
import { Errors } from '../../constants/Errors';
import { FoldersProps } from './Documents.props';

function useDocumentsController() {
  const navigate = useNavigate();

  const [rowsId, setRowsId] = useState(['']);
  const [variant, setVariant] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalFolders, setTotalFolders] = useState(0);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false);
  const [folders, setFolders] = useState<Array<FoldersProps>>([]);
  const [searchString, setSearchString] = useState('');

  const { handleSetSortFields } = useSortItemsStore();
  const { isOpenBackToLastPageModal } = useBackToLastPageModalStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { toogleLoading } = useLoadModalStore();
  const {
    text,
    isOpenInfoModal,
    handleModalOpen,
    handleSetIsSuccessfully,
    handleSetText,
    isSuccessfully
  } = useInfoModalStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();

  const {
    variantInfor,
    handleSetFoldersId,
    handleSetFolderId,
    searchDocumentsRequest,
    deleteFolderRequest,
    getAllDocumentSortDataRequest,
    direction,
    field,
    id
  } = useDocumentsStore();

  const toggleRegisterModal = () => {
    setVariant('create');

    setIsModalRegisterOpen((prev) => !prev);
  };

  const toggleEditModal = () => {
    setVariant('edit');

    setIsModalEditOpen((prev) => !prev);
  };

  const getAllFolders = async (page: number) => {
    toogleLoading();

    try {
      const { data } = await getAllDocumentSortDataRequest(
        `${page}`,
        field,
        direction,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      if (data) {
        setFolders(formatedData);
        setRowsId(formatedRowsId);
        handleSetFoldersId(formatedRowsId);
        setTotalPages(data.totalPages || 0);
        setTotalFolders(data.totalFolders || 0);
      }
    } finally {
      toogleLoading();
    }
  };

  async function changePage(page: number) {
    toogleLoading();

    try {
      const { data } = await getAllDocumentSortDataRequest(
        `${page}`,
        field,
        direction,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      if (data) {
        setFolders(formatedData);
        setRowsId(formatedRowsId);
        handleSetFoldersId(formatedRowsId);
        setTotalPages(data.totalPages || 0);
        setTotalFolders(data.totalFolders || 0);
      }
    } finally {
      toogleLoading();
    }
  }

  const actionModalData = [
    {
      ButtonsText: 'Renomear',
      OnClick: () => toggleEditModal()
    },

    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        handleDeleteModalOpen();
      }
    }
  ];

  function goToFilesPage(newId: string) {
    navigate(`/docs/listdocs/${newId}`);
  }

  async function handleSearch(value: string) {
    setSearchString(value);
    const formatedDataForSearch: SearchDocuments = { queryText: value };

    const data = await searchDocumentsRequest(
      formatedDataForSearch,
      field,
      direction,
      String(currentPage)
    );

    const formatedData = data.data;

    const formatedRowsId = data.data.map((item: { id: string }) => {
      return item.id;
    });

    if (data) {
      setTotalPages(Number(data.totalPages) || 0);
      setTotalFolders(Number(data.totalFolders) || 0);
      setRowsId(formatedRowsId);
      handleSetFoldersId(formatedRowsId);
      setFolders(formatedData);
    }
  }

  const successModal = () => {
    setIsModalRegisterOpen(false);
    setIsModalEditOpen(false);
  };

  const deleteFolderOfRow = async () => {
    toogleLoading();
    try {
      await deleteFolderRequest(id);
      handleSetIsSuccessfully(true);
      handleSetText('Pasta excluída com sucesso!');
      successModal();
    } catch (err: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[String(err.message).trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      handleModalOpen();
      toogleLoading();
    }
  };

  const setSortFields = () => {
    handleSetSortFields(['name', 'created_at', 'size', 'type']);
  };

  const tableHeaders = ['Nome', 'Data de Modificação', 'Tamanho', 'Tipo', 'Ações'];

  function closeModal() {
    if (isModalRegisterOpen) {
      toggleRegisterModal();
    }

    if (isModalEditOpen) {
      toggleEditModal();
    }
  }

  async function sortDocumentsData(page: string, sortByField: string, sortByDirection: string) {
    const data = await getAllDocumentSortDataRequest(
      page,
      sortByField,
      sortByDirection,
      searchString
    );
    return data;
  }

  useEffect(() => {
    getAllFolders(currentPage);
    setSortFields();
  }, [variantInfor, isOpenInfoModal]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    tableHeaders,
    actionModalData,
    isOpenDeleteModal,
    rowsId,
    totalPages,
    sortDocumentsData,
    totalFolders,
    handleDrawerOpen,
    deleteFolderOfRow,
    handleDeleteModalOpen,
    changePage,
    handleSearch,
    isModalRegisterOpen,
    isSuccessfully,
    toggleRegisterModal,
    successModal,
    folders,
    isModalEditOpen,
    toggleEditModal,
    variant,
    handleSetFolderId,
    isOpenBackToLastPageModal,
    closeModal,
    text,
    isOpenInfoModal,
    handleModalOpen,
    goToFilesPage
  };
}

export default useDocumentsController;
