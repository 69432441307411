/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useActionModalStore,
  useBackToLastPageModalStore,
  useDeleteModalStore,
  useInfoModalStore,
  useLoadModalStore,
  usePaginationStore,
  useSortItemsStore,
  usePaymentSlipStore
} from '../../store';
import { SearchDocuments } from '@/services/requests/documents/Documents.dto';
import { Errors } from '../../constants/Errors';
import { IAllPaymentFiles } from '../../services/requests/paymentSlip/paymentSlip.dto';

function usePaymentSlipFilesController() {
  const {
    folderId,
    setFolderId,
    folderName,
    setFolderName,
    fileId,
    setFileId,
    isPaidStatus,
    setIsPaidStatus,
    filesIDs,
    setFilesIDs,
    fileList,
    setFileList,
    fileUrlIDList,
    setFileUrlIdList,
    isModalRegisterOpen,
    setIsModalRegisterOpen,
    isModalEditOpen,
    setIsModalEditOpen,
    field,
    direction,
    totalItems,
    setTotalItems,
    totalPages,
    setTotalPages,
    getAllPaymentSlipRequest,
    searchPaymentSlipRequest,
    deletePaymentSlipFileRequest,
    deleteFileFromStoreRequest,
    confirmPaymentRequest,
    unconfirmPaymentRequest
  } = usePaymentSlipStore();

  const {
    isOpenInfoModal,
    handleModalOpen,
    handleSetIsSuccessfully,
    isSuccessfully,
    handleSetText,
    text
  } = useInfoModalStore();

  const { isOpenBackToLastPageModal } = useBackToLastPageModalStore();
  const { handleSetSortFields } = useSortItemsStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { currentPage } = usePaginationStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { toogleLoading } = useLoadModalStore();
  const [, setIsModalInforOpen] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [searchString, setSearchString] = useState('');

  const foldId = useParams();

  const navigation = useNavigate();

  const toggleSuccessModal = () => {
    handleModalOpen();
  };

  const toggleRegisterModal = () => {
    setIsModalRegisterOpen(!isModalRegisterOpen);
    handleSetText('Arquivo adicionado com sucesso!');
  };

  const toggleEditModal = () => {
    setIsModalEditOpen(!isModalEditOpen);
    handleSetText('Arquivo renomeado com sucesso!');
  };

  const toggleInforModal = () => {
    setIsModalInforOpen((prev) => !prev);
  };

  const toggleConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  function translateStatus(status: string) {
    const paid = 'Boleto Pago';
    const pending = 'Boleto Pendente';
    return status.toLowerCase() === 'pendent' ? pending : paid;
  }

  function formatRequestData(data: { data: IAllPaymentFiles[] }) {
    const formatedData = data.data.map((item) => {
      return {
        id: item.id,
        name: item.fileName,
        date: item.date,
        size: item.size,
        type: 'Arquivo PDF',
        status: translateStatus(item.status)
      };
    });
    return formatedData;
  }

  const getAllFiles = async () => {
    toogleLoading();
    try {
      const { data } = await getAllPaymentSlipRequest(
        String(currentPage),
        field === 'unit_folder_model.name' ? 'name' : field,
        direction,
        searchString,
        foldId.id
      );

      const formatedData = formatRequestData(data);
      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });
      const formatedUrlId = data.data.map((item) => {
        return {
          id: item.id,
          url: item.url
        };
      });

      if (data) {
        setFilesIDs(formatedRowsId);
        setFileList(formatedData);
        setFileUrlIdList(formatedUrlId);
        setTotalPages(data.totalPages || 1);
        setTotalItems(data.totalItems || 0);
      }
    } finally {
      toogleLoading();
    }
  };

  const confirmPayment = async () => {
    if (!fileId) return;
    toggleConfirmModal();
    try {
      await confirmPaymentRequest(fileId);
      handleSetIsSuccessfully(true);
      handleSetText('Pagamento confirmado com sucesso!');
    } catch (error: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[error.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      getAllFiles();
      handleModalOpen();
    }
  };

  const unconfirmPayment = async () => {
    if (!fileId) return;
    toggleConfirmModal();
    try {
      await unconfirmPaymentRequest(fileId);
      handleSetIsSuccessfully(true);
      handleSetText('O status do boleto foi alterado para Pendente!');
    } catch (error: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[error.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      getAllFiles();
      handleModalOpen();
    }
  };

  const handleConfirmPaymentRequest = () => {
    if (isPaidStatus) {
      unconfirmPayment();
    } else {
      confirmPayment();
    }
  };

  const setConfirmMessage = (isPaid: boolean) => {
    let message = '';
    isPaid
      ? (message = 'Deseja marcar este boleto como Não Pago?')
      : (message = 'Deseja confirmar o pagamento deste boleto?');

    handleSetText(message);
  };

  function handleActionModal(row: any) {
    const { status } = row;
    const isPaid = status === 'Boleto Pago';
    const actionModalData = [
      {
        ButtonsText: isPaid ? 'Marcar como Pendente' : 'Confirmar Pagamento',
        OnClick: () => {
          setIsPaidStatus(isPaid);
          setConfirmMessage(isPaid);
          setIsOpenConfirmModal(true);
        }
      },
      {
        ButtonsText: 'Renomear',
        OnClick: () => {
          toggleEditModal();
        }
      },
      {
        ButtonsText: 'Visualizar Boleto',
        OnClick: () => {
          const found = fileUrlIDList?.find((item) => item.id === row.id);
          const foundUrl = found?.url;
          if (foundUrl) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.open(foundUrl!, '_blank').focus();
          }
        }
      },
      {
        ButtonsText: 'Excluir',
        OnClick: () => {
          handleDeleteModalOpen();
          handleDrawerOpen();
        }
      }
    ];
    return actionModalData;
  }

  const sortRequest = async (page: string, sortByDirection: string, sortByField: string) => {
    if (!(sortByField && sortByDirection)) return;
    toogleLoading();

    if (sortByField === 'unit_folder_model.name') {
      sortByField = 'name';
    }

    try {
      const { data } = await getAllPaymentSlipRequest(
        page,
        sortByDirection,
        sortByField || 'date',
        searchString
      );
      const fName = data.data[0].folderName;
      setFolderName(fName);
      const formatedData = formatRequestData(data);
      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });
      const formatedUrlId = data.data.map((item) => {
        return {
          id: item.id,
          url: item.url
        };
      });
      if (data) {
        setFilesIDs(formatedRowsId);
        setFileList(formatedData);
        setFileUrlIdList(formatedUrlId);
        setTotalPages(data.totalPages || 1);
        setTotalItems(data.totalItems || 0);
      }
    } finally {
      toogleLoading();
    }
  };

  const goBack = () => {
    navigation('/payments');
  };

  const changePage = async (page: number) => {
    toogleLoading();
    try {
      const { data } = await getAllPaymentSlipRequest(
        String(page),
        field === 'unit_folder_model.name' ? 'name' : field,
        direction,
        searchString,
        foldId.id
      );

      const fName = data.data[0].folderName;
      setFolderName(fName);
      const formatedData = formatRequestData(data);
      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });
      const formatedUrlId = data.data.map((item) => {
        return {
          id: item.id,
          url: item.url
        };
      });

      if (data) {
        setFilesIDs(formatedRowsId);
        setFileList(formatedData);
        setFileUrlIdList(formatedUrlId);
        setTotalPages(data.totalPages || 1);
        setTotalItems(data.totalItems || 0);
      }
    } finally {
      toogleLoading();
    }
  };

  const handleSearch = async (value: string) => {
    setSearchString(value);
    const formatedDataForSearch: SearchDocuments = { queryText: value };
    const { data } = await searchPaymentSlipRequest(
      formatedDataForSearch,
      field === 'unit_folder_model.name' ? 'name' : field,
      direction,
      String(currentPage)
    );
    const formatedData = formatRequestData(data);
    const formatedRowsId = data.data.map((item) => {
      return item.id;
    });
    const formatedUrlId = data.data.map((item) => {
      return {
        id: item.id,
        url: item.url
      };
    });

    if (data) {
      setFilesIDs(formatedRowsId);
      setFileList(formatedData);
      setFileUrlIdList(formatedUrlId);
      setTotalPages(data.totalPages || 1);
      setTotalItems(data.totalItems || 0);
    }
  };

  const successModal = () => {
    handleModalOpen();
    handleSetIsSuccessfully(true);
    setIsModalRegisterOpen(false);
    getAllFiles();
  };

  function extractFilenameFromUrl(url: string | undefined) {
    const filename = url?.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.pdf') + 4);
    return filename;
  }

  const deleteFile = async () => {
    const fileToBeDeleted = fileUrlIDList?.find((item) => item.id === fileId);
    const fileIdOnStore = extractFilenameFromUrl(fileToBeDeleted?.url);
    if (fileToBeDeleted && fileIdOnStore) {
      toogleLoading();
      try {
        await deletePaymentSlipFileRequest(fileToBeDeleted.id);
        await deleteFileFromStoreRequest(fileIdOnStore);
        handleSetIsSuccessfully(true);
        handleSetText('Boleto excluído com sucesso!');
      } catch (error: any) {
        handleSetIsSuccessfully(false);
        handleSetText(
          (Errors as any)({})[error.message.trim()] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
      } finally {
        toogleLoading();
        handleModalOpen();
      }
      getAllFiles();
    }
  };

  const setSortFields = () => {
    handleSetSortFields(['name', 'date', 'size', '', 'status']);
  };

  function getFolderNameOnLocalStorage() {
    const restoredData = window.localStorage.getItem('fNames');
    if (!restoredData) return;

    const parsedData = JSON.parse(restoredData);
    const foundName = parsedData.find((folder: any) => folder.id === foldId.id);
    setFolderName(foundName?.name || '');
  }

  const tableHeaders = ['Nome do Arquivo', 'Vencimento', 'Tamanho', 'Tipo', 'Status', 'Ações'];

  function closeModal() {
    if (isModalRegisterOpen) {
      toggleRegisterModal();
    }

    if (isModalEditOpen) {
      toggleEditModal();
    }
  }

  useEffect(() => {
    getAllFiles();
    setSortFields();
    getFolderNameOnLocalStorage();
  }, []);

  useEffect(() => {
    setFolderId(String(foldId.id));
  }, []);

  return {
    tableHeaders,
    folderId,
    handleActionModal,
    isOpenDeleteModal,
    isModalEditOpen,
    filesIDs,
    isOpenInfoModal,
    isSuccessfully,
    text,
    totalPages,
    totalItems,
    handleDrawerOpen,
    handleModalOpen,
    handleDeleteModalOpen,
    isModalRegisterOpen,
    isOpenConfirmModal,
    toggleConfirmModal,
    toggleRegisterModal,
    successModal,
    fileList,
    setFileId,
    deleteFileOfRow: deleteFile,
    goBack,
    getAllFiles,
    toggleInforModal,
    toggleEditModal,
    setIsModalRegisterOpen,
    folderName,
    sortRequest,
    handleConfirmPaymentRequest,
    changePage,
    handleSearch,
    handleSetText,
    handleSetIsSuccessfully,
    toggleSuccessModal,
    isOpenBackToLastPageModal,
    closeModal
  };
}

export default usePaymentSlipFilesController;
