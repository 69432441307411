/* eslint-disable react/jsx-props-no-spreading */
import { Modal as ModalComponent, TextField, Box, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import AutocompleteInput from '../../../components/Autocomplete/Autocomplete';
import { CustomFileInput, SlideButton } from '../../../components';

import { useModalController } from './Modal.controller';

import { ModalProps } from './Modal.props';
import {
  ModalBackButton,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalContent,
  ModalInputArea,
  ModalNextButton,
  ModalTitle,
  Container,
  ContainerTitleIcon,
  ModalInputTitle,
  ModalInputAreaRow,
  ModalButtonArea
} from './Modal.style';

export function Modal({ isModalOpen, toggleModal, variant, onComplete }: ModalProps) {
  const {
    handleSearchSelect,
    handleSearchBlockSelect,
    unitValue,
    blockValue,
    setUnitValue,
    setBlockValue,
    isInputValue,
    setIsInputValue,
    isInputBlockValue,
    setIsInputBlockValue,
    handleSlideButton,
    isDelivery,
    control,
    errors,
    handleSubmit,
    register,
    handleCreateOrder,
    isSelectedBlock,
    setIsSelectedBlock,
    setImage,
    closeVotingModal,
    isButtonClickOnce,
    blocksList,
    setUnitListData,
    unitList
  } = useModalController(variant, onComplete, toggleModal, isModalOpen);

  return (
    <ModalComponent open={isModalOpen} onClose={closeVotingModal}>
      <Container>
        <ModalContent isDelivery={isDelivery}>
          <ContainerTitleIcon>
            <ModalTitle>Registrar encomenda</ModalTitle>
            <ModalCloseButton onClick={closeVotingModal}>
              <ModalCloseButtonIcon />
            </ModalCloseButton>
          </ContainerTitleIcon>

          <form>
            <ModalInputArea>
              <ModalInputTitle>Destinatário</ModalInputTitle>
              <Controller
                name="recipient"
                control={control}
                render={() => (
                  <TextField
                    id="orders_recipient"
                    placeholder="Informe o destinatário"
                    color="primary"
                    sx={{ width: '100%' }}
                    style={{ borderRadius: '100px', outline: 'none' }}
                    {...register('recipient')}
                    error={!!errors.recipient}
                    name="recipient"
                  />
                )}
              />
              {errors.recipient && (
                <p style={{ fontFamily: 'Inter', color: '#ff0000' }}>{errors.recipient.message}</p>
              )}
            </ModalInputArea>

            <ModalInputArea>
              <ModalInputTitle>Remetente</ModalInputTitle>
              <Controller
                name="sender"
                control={control}
                render={() => (
                  <TextField
                    id="orders_sender"
                    placeholder="Informe o remetente"
                    color="primary"
                    sx={{ width: '100%' }}
                    style={{ borderRadius: '100px', outline: 'none' }}
                    {...register('sender')}
                    error={!!errors.sender}
                    name="sender"
                  />
                )}
              />
              {errors.sender && (
                <p style={{ fontFamily: 'Inter', color: '#ff0000' }}>{errors.sender.message}</p>
              )}
            </ModalInputArea>

            <ModalInputAreaRow>
              <ModalInputArea>
                <ModalInputTitle>Bloco</ModalInputTitle>
                <Box className="selectInputLabelContainer">
                  <Controller
                    name="block"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <InputLabel className="selectInputLabel">
                          {value || isInputBlockValue ? '' : 'Selecione'}
                        </InputLabel>
                        <AutocompleteInput
                          id="orders_blocks"
                          options={blocksList}
                          value={value}
                          getValue={blockValue}
                          setValue={setBlockValue}
                          isInputValue={isInputBlockValue}
                          setIsInputValue={setIsInputBlockValue}
                          handleSearch={handleSearchBlockSelect}
                          onChange={onChange}
                          setIsSelectedBlock={setIsSelectedBlock}
                          setUnitListData={setUnitListData}
                        />
                      </>
                    )}
                  />
                </Box>
                {errors.block && (
                  <p style={{ fontFamily: 'Inter', color: '#ff0000' }}>{errors.block.message}</p>
                )}
              </ModalInputArea>
              <div style={{ width: 22 }} />
              <ModalInputArea>
                <ModalInputTitle>Unidade</ModalInputTitle>
                <Box className="selectInputLabelContainer">
                  <Controller
                    name="unit"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <InputLabel className="selectInputLabel">
                          {value || isInputValue ? '' : 'Selecione'}
                        </InputLabel>
                        <AutocompleteInput
                          id="orders_units"
                          options={unitList}
                          value={value}
                          getValue={unitValue}
                          setValue={setUnitValue}
                          isInputValue={isInputValue}
                          setIsInputValue={setIsInputValue}
                          handleSearch={handleSearchSelect}
                          isDisabled={!isSelectedBlock}
                          onChange={onChange}
                          setIsSelectedBlock={setIsSelectedBlock}
                          setUnitListData={setUnitListData}
                        />
                      </>
                    )}
                  />
                </Box>
                {errors.unit && (
                  <p style={{ fontFamily: 'Inter', color: '#ff0000' }}>{errors.unit.message}</p>
                )}
              </ModalInputArea>
            </ModalInputAreaRow>

            <ModalInputArea>
              <ModalInputTitle>Delivery?</ModalInputTitle>
              <SlideButton onClick={handleSlideButton} isDelivery={isDelivery} />
            </ModalInputArea>

            {!isDelivery && (
              <CustomFileInput
                accept=".jpg, .png"
                type="image"
                hasImageSizeValidation={false}
                handleFile={(file) => setImage(file)}
                isTenSize
              />
            )}

            <ModalButtonArea isDelivery={isDelivery}>
              <ModalNextButton
                style={{
                  backgroundColor: '#662483',
                  color: 'white',
                  width: '100%',
                  height: 50,
                  fontFamily: 'Inter',
                  fontSize: 16,
                  fontWeight: 500,
                  textTransform: 'none'
                }}
                id="orders_registerButton"
                variant="contained"
                disableElevation
                disabled={isButtonClickOnce}
                onClick={handleSubmit(handleCreateOrder)}>
                Registrar encomenda
              </ModalNextButton>

              <ModalBackButton
                variant="contained"
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  fontFamily: 'Inter',
                  fontSize: 16,
                  fontWeight: 500,
                  textTransform: 'capitalize',
                  marginTop: '16px'
                }}
                disableElevation
                onClick={closeVotingModal}>
                Voltar
              </ModalBackButton>
            </ModalButtonArea>
          </form>
        </ModalContent>
      </Container>
    </ModalComponent>
  );
}
