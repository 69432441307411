import styled from 'styled-components';

const Background = styled.div`
  display: flex;
  margin: 90px 24px 0px 20px;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 700px) {
    padding-right: 0px;
    padding-left: 0px;
  }

  @media (max-width: 380px) {
    margin-left: 10px !important;
  }
`;

export default Background;
