import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FormRequiredFields, FormatedData } from './RegisterEnterprise.props';
import {
  usePreviewMobileModalStore,
  useEnterpriseStore,
  useBackToLastPageModalStore,
  useInfoModalStore,
  useLoadModalStore
} from '../../store';
import formatCEP from '../../helpers/formatCEP';
import { registerEnterpriseSchema } from '../../helpers/enterprisesHelpFunctions';
import { choiceType, status, enterpriseDefaultValues } from '../../constants';
import { Errors } from '../../constants/Errors';

function useRegisterEnterprisesController() {
  const navigate = useNavigate();

  const [infoText, setInfoText] = useState('');
  const [enterpriseName, setEnterpriseName] = useState('');
  const [isSuccessfully, setIsSuccessfully] = useState(false);

  const { isOpenBackToLastPageModal, sidebarLink } = useBackToLastPageModalStore();
  const { isOpenMobilePreview } = usePreviewMobileModalStore();
  const { isOpenInfoModal, handleModalOpen } = useInfoModalStore();
  const {
    colors,
    functionalities,
    city,
    district,
    state,
    street,
    cep,
    logo,
    manual,
    imageFile,
    logoUrl,
    placeholderManual,
    manualUrl,
    registerEnterpriseRequest
  } = useEnterpriseStore();
  const { toogleLoading } = useLoadModalStore();

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: enterpriseDefaultValues,
    resolver: yupResolver(registerEnterpriseSchema)
  });

  function setAddressValues() {
    setValue('city', city);
    setValue('street', street);
    setValue('state', state);
    setValue('district', district);
    setValue('CEP', formatCEP(cep));
  }

  function handleSetEnterpriseName(name: string) {
    setEnterpriseName(name);
  }

  function NavigateToListEnterprises() {
    handleModalOpen();
    navigate('../enterprises', { replace: true });
  }

  function formatFormData(formatedData: FormatedData) {
    const address = {
      street: formatedData.street,
      number: formatedData.number,
      complement: formatedData.complement,
      city: formatedData.city,
      state: formatedData.state,
      country: formatedData.country,
      CEP: formatedData.CEP,
      neighborhood: formatedData.district
    };

    const dataToRequest = new FormData();
    dataToRequest.append('name', formatedData.name);
    dataToRequest.append('incorporator', formatedData.incorporator);
    dataToRequest.append('status', formatedData.status);
    dataToRequest.append('unit', formatedData.unit);
    dataToRequest.append('type', formatedData.propertyType);
    dataToRequest.append('email', formatedData.adminEmail);
    dataToRequest.append('address', JSON.stringify(address));
    dataToRequest.append('colors', JSON.stringify(colors));
    dataToRequest.append('functionalities', JSON.stringify(functionalities));
    if (logo) {
      dataToRequest.append('logo', imageFile || formatedData.logo);
    } else {
      dataToRequest.append('logoUrl', logoUrl);
    }
    dataToRequest.append('manual', manual);

    return dataToRequest;
  }

  const createNewEnterprise = async () => {
    toogleLoading();
    try {
      if (!colors.primary || !colors.secondary) {
        setInfoText('É necessário preencher todos os campos de cores do sistema');
        setIsSuccessfully(false);
        handleModalOpen();
        return;
      }
      const values = getValues();
      const formatedCep = cep.replace('-', '');

      const formatedData = {
        ...values,
        CEP: formatedCep,
        logo,
        manual: manual || placeholderManual || manualUrl
      };
      const dataToRequest = formatFormData(formatedData);

      await registerEnterpriseRequest(dataToRequest);
      setInfoText('Empreendimento cadastrado com sucesso!');
      setIsSuccessfully(true);
      handleModalOpen();
    } catch (err: any) {
      setInfoText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      setIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  useEffect(() => {
    setAddressValues();
  }, [city, street, state, district, cep]);

  return {
    status,
    choiceType,
    createNewEnterprise,
    handleSubmit,
    register,
    handleSetEnterpriseName,
    control,
    errors,
    isOpenMobilePreview,
    isOpenBackToLastPageModal,
    enterpriseName,
    isOpenInfoModal,
    isSuccessfully,
    NavigateToListEnterprises,
    infoText,
    handleModalOpen,
    sidebarLink
  };
}

export default useRegisterEnterprisesController;
