import { useEffect, useState } from 'react';
import { ocurrencesTableHeader } from '../../constants';
import {
  useActionModalStore,
  useSortItemsStore,
  useLoadModalStore,
  useRefreshChangedPagesStore,
  useOcurrencesStore,
  usePaginationStore
} from '../../store';
import { IOcurrencesData, IOcurrencesDataAndMetadata } from './Ocurrences.props';

function useInformativesController() {
  const { handleSetSortFields } = useSortItemsStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { setChangedPage, isPageChanged } = useRefreshChangedPagesStore();

  const {
    searchOcurrencesRequest,
    handleSetOcurrencesList,
    handleSetOcurrencesId,
    getAllSortOcurrencessDataRequest,
    handleSetOcurrenceId,
    ocurrencesId,
    page,
    ocurrencesList,
    field,
    direction
  } = useOcurrencesStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();

  const [totalOcurrences, setTotalOcurrences] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchString, setSearchString] = useState('');

  function setSetSortFields() {
    handleSetSortFields([
      'occurrence_model.name',
      'block_of_unit_model.name',
      'unit_model.name',
      'occurrence_model.local',
      'occurrence_model.createdAt',
      'occurrence_model.status'
    ]);
  }

  async function handleSearch(value: string) {
    toogleLoading();
    try {
      setSearchString(value);
      const formatedDataForSearch = { queryText: value };
      const data: IOcurrencesDataAndMetadata = await searchOcurrencesRequest(
        formatedDataForSearch,
        '1',
        field,
        direction
      );
      const formatedData: IOcurrencesData[] = data.data;
      const formatedRowsId = formatedData.map((item: { id: string }) => {
        return item.id;
      });

      handleSetOcurrencesList(formatedData);
      handleSetOcurrencesId(formatedRowsId);
      setTotalPages(data.totalPages || 1);
      setTotalOcurrences(data.totalOcurrences || 0);
    } finally {
      toogleLoading();
    }
  }

  async function changePage(newPage: number) {
    toogleLoading();
    try {
      const { data }: IOcurrencesDataAndMetadata = await getAllSortOcurrencessDataRequest(
        `${newPage}`,
        field,
        direction,
        searchString
      );
      const formatedData: IOcurrencesData[] = data;
      const formatedRowsId = formatedData.map((item: { id: string }) => {
        return item.id;
      });
      if (data) {
        handleSetOcurrencesList(formatedData);
        handleSetOcurrencesId(formatedRowsId);
      }
    } finally {
      toogleLoading();
    }
  }

  async function getAllData() {
    toogleLoading();
    try {
      const data: IOcurrencesDataAndMetadata = await getAllSortOcurrencessDataRequest(
        page,
        field,
        direction,
        searchString
      );
      const formatedData: IOcurrencesData[] = data.data;
      const formatedRowsId = formatedData.map((item: { id: string }) => item.id);
      handleSetOcurrencesId(formatedRowsId);
      handleSetOcurrencesList(formatedData);
      setTotalPages(data.totalPages || 1);
      setTotalOcurrences(data.totalOcurrences || 0);
    } finally {
      toogleLoading();
    }
  }

  async function sortAllData(selectedPage: string, sortByField: string, sortByDirection: string) {
    toogleLoading();
    try {
      const data: IOcurrencesDataAndMetadata = await getAllSortOcurrencessDataRequest(
        selectedPage,
        sortByField,
        sortByDirection,
        searchString
      );

      const formatedData: IOcurrencesData[] = data.data;
      const formatedRowsId = formatedData.map((item: { id: string }) => item.id);
      handleSetOcurrencesId(formatedRowsId);
      handleSetOcurrencesList(formatedData);
      setTotalPages(data.totalPages || 1);
      setTotalOcurrences(data.totalOcurrences || 0);
    } finally {
      toogleLoading();
    }
  }

  function setOcurrencesIfExist() {
    if (ocurrencesList.length <= 0) return;
    handleSetOcurrencesId(ocurrencesId);
  }

  useEffect(() => {
    setOcurrencesIfExist();
    if (isPageChanged) setChangedPage(false);
    getAllData();
    setSetSortFields();
  }, []);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    ocurrencesTableHeader,
    totalPages,
    totalOcurrences,
    ocurrencesId,
    ocurrencesList,
    handleDrawerOpen,
    sortAllData,
    handleSetOcurrenceId,
    changePage,
    handleSearch
  };
}

export default useInformativesController;
