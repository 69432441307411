/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable consistent-return */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { convertResponse } from '../helpers/convertResponse';
import Reactotron from '../ReactotronConfig';
import { IAxiosRequestConfig, IAxiosResponse } from './api.props';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json'
  }
});

instance.interceptors.request.use(
  (config: IAxiosRequestConfig) => {
    if (process.env.NODE_ENV === 'development') config.metadata = { startTime: new Date() };
    const token = localStorage.getItem('token');
    if (token) {
      config.headers = {
        ...config?.headers,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: IAxiosResponse) => {
    if (process.env.NODE_ENV === 'development') {
      response.config.metadata = {
        endTime: new Date()
      };
      const [request, responseInfo, duration] = convertResponse(response);
      Reactotron.apiResponse?.(request, responseInfo, duration);
    }
    return response;
  },
  async (error) => {
    if (process.env.NODE_ENV === 'development') {
      if (error.message === 'Network Error') {
        return Promise.reject(error);
      }
      error.response.config.metadata = {
        endTime: new Date()
      };
      const [request, responseInfo, duration] = convertResponse(error.response);
      Reactotron.apiResponse?.(request, responseInfo, duration);
    }

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: IAxiosResponse) => {
    return response;
  },
  async (error) => {
    return new Promise(async (resolve, reject) => {
      const originalReq = error.config;
      const token = localStorage.getItem('refreshToken') || '';
      let decoded: { exp: number } = { exp: 0 };

      try {
        decoded = await jwt_decode(token);
      } catch {
        await localStorage.removeItem('token');
        await localStorage.removeItem('refreshToken');
      }

      if (new Date() > new Date(Number(`${decoded.exp}000`))) {
        await localStorage.removeItem('token');
      }

      if (error.response.status === 401 && !error.config._retry) {
        originalReq._retry = true;

        const res = await instance.post('/loginRefresh', { token }).then(async (response) => {
          localStorage.setItem('token', response.data.access_token);
          originalReq.headers.Authorization = `Bearer ${response.data.access_token}`;
          return await axios(originalReq);
        });
        resolve(res);
      } else {
        if (
          (error?.response?.status === 401 || error?.response?.status === 400) &&
          originalReq.url === '/loginRefresh'
        ) {
          await localStorage.removeItem('token');
          await localStorage.removeItem('refreshToken');

          return reject(error);
        }
        return reject(error);
      }
    });
  }
);
