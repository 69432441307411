/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  useLoadModalStore,
  usePreviewImageModalStore,
  useMaintenancesStore
} from '../../../../../../store';
import { isInvalidFileName } from '../../../../../../helpers/fileNameValidator';

function useCustomFileInput(type: string) {
  const [files, setFiles]: any = useState([{ src: '', name: '' }]);
  const [isPdf, setIsPdf] = useState(false);

  const { handleImagePreviewOpen } = usePreviewImageModalStore();
  const { toogleLoading } = useLoadModalStore();
  const {
    getImageLinkRequest,
    handleSetImagesToRequest,
    handleSetImages,
    handleSetImage,
    removeImageRequest,
    images,
    formatedImageToRequest
  } = useMaintenancesStore();

  function fillContainerWithInput() {
    const fileInput = document.getElementById('fileInputSelected');
    const fileInputContainer = document.getElementById('fileInputSelectedContainer');
    // @ts-ignore
    fileInputContainer.onclick = () => {
      fileInput?.click();
    };
  }

  const handleRemoveFile = async (name: string) => {
    const newFiles = [...files];
    const newImages = [...images];

    const formatedFiles = newFiles.filter((file: { name: string }) => file.name !== name);
    const formatedImages = newImages.filter((file: { name: string }) => file.name !== name);

    setFiles(formatedFiles);
    setIsPdf(false);
    handleSetImages(formatedImages);

    const { id } = formatedImageToRequest[0];
    handleSetImagesToRequest([]);

    toogleLoading();
    try {
      await removeImageRequest(id);
    } finally {
      toogleLoading();
    }
  };

  const formatImage = async (file: any) => {
    toogleLoading();
    try {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await getImageLinkRequest(formData, uuidv4());
      handleSetImagesToRequest([...formatedImageToRequest, data]);
    } catch (err: any) {
      if (err.message !== '') toast.error(err.message);
    } finally {
      toogleLoading();
    }
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target as any;

    if (event.files) {
      if (files.length > 1) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (event.files.length > 10) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (
        type === 'image' &&
        event.files[0].type !== 'image/jpeg' &&
        event.files[0].type !== 'image/png' &&
        event.files[0].type !== 'application/pdf'
      ) {
        toast.error('É permitido apenas arquivos JPEG, PNG e PDF.');
        return;
      }

      if (isInvalidFileName(event.files[0].name)) return;

      if (event.files[0].type !== 'application/pdf') setIsPdf(true);
    }

    const newImages = [...images, ...event.files];

    handleSetImages(newImages);
    if (files[0] && files[0].src === '') files.pop();

    const filesImage = Array.from(event.files);
    filesImage.forEach((img: any) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          if (files.length + event.files.length > 1) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (files.length === 1) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (Number(img.size) > 10000000) {
            toast.error(`O tamanho da imagem ${img.name} excede 10MB`);
            return;
          }

          setFiles((prev: any) => [...prev, { src: reader.result, name: img.name }]);
          formatImage(event.files[0]);
        }
      };

      reader.readAsDataURL(img);
    });
  };

  function getImageToPreview(file: any) {
    handleSetImage(file);
    handleImagePreviewOpen();
  }

  return {
    handleChangeFile,
    files,
    isPdf,
    handleRemoveFile,
    fillContainerWithInput,
    getImageToPreview
  };
}

export default useCustomFileInput;
