import { LinearProgress, linearProgressClasses } from '@mui/material';

interface Props {
  isProgress: string;
}

export function ProgressBar({ isProgress }: Props) {
  const progress = Number(isProgress);

  return (
    <LinearProgress
      sx={{
        height: 20,
        borderRadius: 1,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: '#eee'
        },
        [`& .${linearProgressClasses.bar}`]: {
          backgroundColor: '#662483'
        }
      }}
      variant="determinate"
      value={progress}
    />
  );
}
