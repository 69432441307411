import { Typography } from '@mui/material';
import Container from './NoFilesFound.style';
import nofiles from '../../../../assets/images/nofiles.png';

export interface NoFilesFoundProps {
  text: string;
}

function NoFilesFound(props: NoFilesFoundProps) {
  const { text } = props;
  return (
    <Container>
      <img src={nofiles} alt="no files found" />
      <Typography variant="body1" fontWeight="600">
        {text}
      </Typography>
    </Container>
  );
}

export default NoFilesFound;
