/* eslint-disable @typescript-eslint/no-var-requires */
import { Grid, Typography } from '@mui/material';
import startcase from 'lodash.startcase';
import { Background } from './PaymentSlipFiles.style';

import {
  CustomSearchInput,
  ActionModal,
  InfoModal,
  DeleteModal,
  BackToLastPageModal,
  CustomTable,
  CustomButton,
  ConfirmModal
} from '../../components/index';
import { Modal } from './Modal/Modal';

import usePaymentSlipFilesController from './PaymentSlipFiles.controller';
import { ModalName } from './ModalName/Modal';

const checkIcon = require('../../assets/images/checkIcon.svg');
const errorIcon = require('../../assets/images/block.svg');
const editIcon = require('../../assets/images/enterpriseactionedit.svg');
const downloadIcon = require('../../assets/images/details.svg');
const trashIcon = require('../../assets/images/enterpriseactiontrash.svg');

export function PaymentSlipFiles() {
  const {
    isModalRegisterOpen,
    toggleRegisterModal,
    successModal,
    isOpenConfirmModal,
    toggleConfirmModal,
    handleActionModal,
    isOpenDeleteModal,
    toggleInforModal,
    toggleEditModal,
    filesIDs,
    setIsModalRegisterOpen,
    tableHeaders,
    isModalEditOpen,
    totalPages,
    totalItems,
    handleDrawerOpen,
    handleDeleteModalOpen,
    deleteFileOfRow,
    text,
    handleSetText,
    handleModalOpen,
    getAllFiles,
    fileList,
    goBack,
    setFileId,
    handleSetIsSuccessfully,
    folderId,
    closeModal,
    folderName,
    changePage,
    handleSearch,
    isOpenInfoModal,
    toggleSuccessModal,
    isOpenBackToLastPageModal,
    isSuccessfully,
    handleConfirmPaymentRequest,
    sortRequest
  } = usePaymentSlipFilesController();

  return (
    <Background>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={500}>
            {startcase(folderName) || 'Pasta'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} />

        <Grid item xs={12} sm={12} md={5} lg={7}>
          <CustomSearchInput id="pay-file-search" handleSearch={handleSearch} />
        </Grid>
        <Grid item xs={12} sm={12} md={3.5} lg={2.5}>
          <CustomButton id="pay-file-send" onClick={toggleRegisterModal} text="Enviar arquivo" />
        </Grid>
        <Grid item xs={12} sm={12} md={3.5} lg={2.5}>
          <CustomButton id="pay-file-back" onClick={goBack} text="Voltar para pastas" />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            sortFunc={sortRequest}
            rowsId={filesIDs || []}
            tableHead={tableHeaders}
            tableData={fileList || []}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalItems}
            containId
            noFilesFoundText="Não há nenhum registro de boletos no momento"
            funcToChangePage={changePage}
            handleSetItemId={setFileId}
            renderAction={(row) => {
              const isPaid = row.status === 'Boleto Pago';
              return (
                <ActionModal
                  ActionModalData={handleActionModal(row)}
                  ButtonsIcon={[
                    isPaid ? (
                      <errorIcon.ReactComponent className="actionIcon" />
                    ) : (
                      <checkIcon.ReactComponent className="actionIcon" />
                    ),
                    <editIcon.ReactComponent className="actionIcon" />,
                    <downloadIcon.ReactComponent className="actionIcon" />,
                    <trashIcon.ReactComponent className="actionIcon" />
                  ]}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Tem certeza que deseja excluir este arquivo?"
          onClickDelete={() => deleteFileOfRow()}
          handleModalClose={handleDeleteModalOpen}
          isInfoModalOpen
        />
      )}

      <Modal
        foldId={{ id: folderId }}
        variant="register"
        isModalOpen={isModalRegisterOpen}
        setIsModalRegisterOpen={setIsModalRegisterOpen}
        toggleModal={toggleRegisterModal}
        backButton={toggleRegisterModal}
        getAllFiles={getAllFiles}
        onComplete={successModal}
        setText={handleSetText}
        setSuccess={handleSetIsSuccessfully}
        toggleSuccessModal={toggleSuccessModal}
      />

      <ModalName
        isModalOpen={isModalEditOpen}
        toggleModal={toggleEditModal}
        backButton={toggleEditModal}
        errorModal={toggleInforModal}
        getAllFiles={getAllFiles}
        title="Renomear arquivo"
        onComplete={successModal}
      />

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}

      {isOpenConfirmModal && (
        <ConfirmModal
          handleModalClose={toggleConfirmModal}
          onClickConfirm={handleConfirmPaymentRequest}
          title={text}
        />
      )}

      {isOpenBackToLastPageModal && (
        <BackToLastPageModal goToPage={`/payments/${folderId}`} handleModalClose={closeModal} />
      )}
    </Background>
  );
}
