import React from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import useEnterpriseFunctionalityController from './EnterpriseFunctionality.controller';
import { EnterpriseFunctionalityProps } from './EnterpriseFunctionality.props';
import FunctionalitiesContainer from './EnterpriseFunctionality.style';

function EnterpriseFunctionality(props: EnterpriseFunctionalityProps) {
  const {
    functionalities,
    functionalitiesDisabled,
    functionalitiesSelected,
    handleGetFunctionalities
  } = props;
  const { getCheckedFunctionalities } = useEnterpriseFunctionalityController();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight={600} marginTop={1} marginBottom={1}>
          Funções Disponíveis:
        </Typography>
      </Grid>
      {functionalities.map((functionality, index) => (
        <React.Fragment key={functionality}>
          <Grid item xs={6} sm={6} lg={4} xl={3}>
            <FunctionalitiesContainer>
              <FormControlLabel
                className={
                  functionalitiesDisabled.includes(functionality)
                    ? 'on functionalitiesBox'
                    : 'off functionalitiesBox'
                }
                control={
                  <Checkbox
                    disabled={functionalitiesDisabled.includes(functionality)}
                    checked={getCheckedFunctionalities(index, functionalitiesSelected)}
                    onChange={() => handleGetFunctionalities(index)}
                  />
                }
                label={functionality}
              />
            </FunctionalitiesContainer>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}

export default EnterpriseFunctionality;
