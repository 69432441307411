export const disponibility = [
  {
    value: true,
    label: 'DISPONÍVEL'
  },
  {
    value: false,
    label: 'NÃO DISPONÍVEL'
  }
];

export const areaTableHead = [
  'Área',
  'Capacidade',
  'Cancelamento',
  'Valor',
  'Disponibilidade',
  'Ações'
];

export const areaDeleteMessage = {
  title: 'Tem certeza que deseja excluir esta área?'
};
