import { usePartnersStore, usePreviewImageModalStore } from '../../../../store';

function usePreviewImageController() {
  const { image } = usePartnersStore();
  const { handleImagePreviewOpen } = usePreviewImageModalStore();

  return { image, handleImagePreviewOpen };
}

export default usePreviewImageController;
