import create from 'zustand';
import { ProfileModalProps } from './ProfileModal.props';

const initialState = {
  isOpenProfileModal: false
};

const useProfileModalStore = create<ProfileModalProps>((set) => ({
  ...initialState,

  handleProfileModalOpen: () => set((state) => ({ isOpenProfileModal: !state.isOpenProfileModal }))
}));

export { useProfileModalStore };
