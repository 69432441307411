/* eslint-disable no-void */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useActionModalStore,
  useEnterpriseStore,
  useDeleteModalStore,
  useInfoModalStore,
  useLoadModalStore,
  useSortItemsStore,
  usePaginationStore,
  useRefreshChangedPagesStore
} from '../../store';
import { tableHeaders, deleteData } from '../../constants';
import { SearchEnterprises } from '../../services/requests/enterprise/Enterprise.dto';
import { formatStatus } from '../../helpers/enterprisesHelpFunctions';
import { Errors } from '../../constants/Errors';

function useEnterprisesController() {
  const [rowsId, setRowsId] = useState(['']);
  const [isSuccessfully, setIsSuccessfully] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalEnterprises, setTotalEnterprises] = useState(0);

  const { setChangedPage, isPageChanged } = useRefreshChangedPagesStore();
  const { isOpenInfoModal, handleModalOpen } = useInfoModalStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { handleSetSortFields } = useSortItemsStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { currentPage } = usePaginationStore();
  const { toogleLoading } = useLoadModalStore();
  const {
    id,
    handleSetEnterpriseList,
    handleSetEnterprisesId,
    enterprisesId,
    enterpriseList,
    field,
    direction,
    handleRemoveOneEnterprise,
    handleSetEnterpriseId,
    searchEnterprisesRequest,
    deleteEnterpriseRequest,
    getAllSortEnterpriseDataRequest
  } = useEnterpriseStore();

  const navigation = useNavigate();

  const getRequestEnterpriseData = async (page: number) => {
    toogleLoading();

    try {
      if (enterpriseList.length > 0) {
        setRowsId(enterprisesId);
        const { data } = await getAllSortEnterpriseDataRequest('1', field, direction);
        setTotalPages(data.totalPages || 1);
        setTotalEnterprises(data.totalEnterprises || 1);
        return;
      }

      const { data } = await getAllSortEnterpriseDataRequest(`${page}`, field, direction);
      const formatedData = data.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          location: item.location,
          address: item.address,
          propertyType: item.propertyType,
          status: formatStatus(item.status),
          unit: item.unit
        };
      });

      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });

      setTotalPages(data.totalPages || 1);
      setTotalEnterprises(data.totalEnterprises || 1);
      if (data) {
        handleSetEnterpriseList(formatedData);
        handleSetEnterprisesId(formatedRowsId);
      }
    } finally {
      toogleLoading();
    }
  };

  async function sortEnterpriseData(page: string, sortByField: string, sortByDirection: string) {
    const { data } = await getAllSortEnterpriseDataRequest(`${page}`, sortByField, sortByDirection);
    const formatedData = data.data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        location: item.location,
        address: item.address,
        propertyType: item.propertyType,
        status: formatStatus(item.status),
        unit: item.unit
      };
    });

    return {
      data: {
        data: formatedData,
        totalPages: data.totalPages || 1,
        totalEnterprises: data.totalEnterprises || 1
      }
    };
  }

  async function changePage(page: number) {
    toogleLoading();
    try {
      const { data } = await getAllSortEnterpriseDataRequest(`${page}`, field, direction);

      const formatedData = data.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          location: item.location,
          address: item.address,
          propertyType: item.propertyType,
          status: formatStatus(item.status),
          unit: item.unit
        };
      });

      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });

      if (data) {
        setRowsId(formatedRowsId);
        handleSetEnterpriseList(formatedData);
        handleSetEnterprisesId(formatedRowsId);
      }
    } finally {
      toogleLoading();
    }
  }

  const actionModalData = [
    {
      ButtonsText: 'Editar',
      OnClick: () => {
        navigation(`/enterprises/editenterprise/${id}`);
        handleDrawerOpen();
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        handleDeleteModalOpen();
        handleDrawerOpen();
      }
    }
  ];

  async function deleteEnterpriseOfRow(idOfEnterprise: string) {
    toogleLoading();
    try {
      setInfoText('Empreendimento excluído com sucesso');
      await deleteEnterpriseRequest(idOfEnterprise);
      handleRemoveOneEnterprise(idOfEnterprise);
      await getRequestEnterpriseData(currentPage);
      setIsSuccessfully(true);
    } catch (error: any) {
      setIsSuccessfully(false);
      setInfoText(
        (Errors as any)({})[String(error.message).trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
      handleModalOpen();
    }
  }

  async function handleSearch(value: string) {
    const formatedDataForSearch: SearchEnterprises = { queryText: value };
    const { data } = await searchEnterprisesRequest(formatedDataForSearch);
    const formatedData = data.data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        location: item.location,
        address: item.address,
        propertyType: item.propertyType,
        status: formatStatus(item.status),
        unit: item.unit
      };
    });
    const formatedRowsId = data.data.map((item) => {
      return item.id;
    });

    if (data) {
      handleSetEnterpriseList(formatedData);
      handleSetEnterprisesId(formatedRowsId);
      setTotalPages(data.totalPages || 1);
      setTotalEnterprises(data.totalEnterprises || 1);
    }
  }

  function setSetSortFields() {
    handleSetSortFields(['name', 'city', 'street', 'type', 'status', 'unit']);
  }

  function setEnterprisesIfExist() {
    if (isPageChanged) setChangedPage(false);
    if (enterpriseList.length <= 0) return;
    setRowsId(enterprisesId);
  }

  useEffect(() => {
    getRequestEnterpriseData(currentPage);
  }, [isOpenInfoModal, rowsId]);

  useEffect(() => {
    setSetSortFields();
  }, []);

  useEffect(() => {
    setEnterprisesIfExist();
  }, [enterpriseList, isOpenDeleteModal]);

  return {
    tableHeaders,
    tableData: enterpriseList,
    actionModalData,
    isOpenDeleteModal,
    deleteData,
    id,
    rowsId,
    isOpenInfoModal,
    isSuccessfully,
    infoText,
    totalPages,
    totalEnterprises,
    sortEnterpriseData,
    handleDrawerOpen,
    handleModalOpen,
    deleteEnterpriseOfRow,
    handleDeleteModalOpen,
    changePage,
    handleSearch,
    handleSetEnterpriseId
  };
}

export default useEnterprisesController;
