/* eslint-disable no-underscore-dangle */
import { instance } from '../../api';

import convertMoney from '../../../helpers/convertMoneyToPTBR';
import convertSecondsToHours from '../../../helpers/convertSecondsToHours';
import convertCentsToReal from '../../../helpers/convertCentsToReal';

import { AreaDetailsById, IAllAreasDtoOutput, SearchArea, SortAreaProps } from './Areas.dto';

function getStatus(data: boolean) {
  if (data) return 'DISPONÍVEL';
  return 'NÃO DISPONÍVEL';
}

function formatCancellation(status: string) {
  let formatedStatus = 'Uma semana';
  switch (status) {
    case '1W':
      formatedStatus = 'Uma semana';
      break;
    case '1M':
      formatedStatus = 'Um mês';
      break;
    case '1D':
      formatedStatus = 'Um dia';
      break;
    case '3D':
      formatedStatus = 'Três dias';
      break;
    case 'any':
      formatedStatus = 'Qualquer momento';
      break;
    default:
      break;
  }
  return formatedStatus;
}

export const registerArea = async (areaData: FormData): Promise<void> => {
  try {
    await instance.request({
      method: 'POST',
      url: 'area/area-create',
      headers: { 'Content-Type': 'multipart/form-data' },
      baseURL: process.env.REACT_APP_MS_AREA,
      data: areaData
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editArea = async (areaData: FormData, id: string): Promise<void> => {
  const areaId = id || window.location.href.split('/').pop();

  try {
    await instance.request({
      method: 'PUT',
      url: `area/area-edit/${areaId}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      baseURL: process.env.REACT_APP_MS_AREA,
      data: areaData
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getAreaDetailsById = async (areaId: string): Promise<{ data: AreaDetailsById }> => {
  const id = areaId || window.location.href.split('/').pop();

  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `area/area-details/${id}`,
      baseURL: process.env.REACT_APP_MS_AREA
    });

    const formatedData = {
      id: data.id,
      areaName: data.area_name,
      price: convertCentsToReal(data.location_value_cents),
      paymentDeadline: convertSecondsToHours(data.payment_deadline),
      cancellationDeadline: data.cancellation_deadline,
      maxCapacity: data.max_capacity,
      simultaneousReservations: data.simultaneous_reservations,
      disponibility: data.is_available,
      instantaneousApproval: data.instantaneous_approval,
      availableSchedule: data.available_schedule,
      hasDayLimit: data.has_reservation_limit_by_day,
      reservationLimit: data.reservation_limit,
      reservationLimitByUser: data.reservation_limit_per_user,
      hasReservationLimitPerUser: data.has_reservation_limit_per_user,
      locationPictures: data.location_pictures,
      locationPicturesUrls: data.location_pictures_urls
    };

    return { data: formatedData };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortAreasData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IAllAreasDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `area/area-list?page=${page}&includeMeta=true&sortDirection=${sortByDirection}&sort=${sortByField}&search=${searchString}`,
      baseURL: process.env.REACT_APP_MS_AREA
    });

    const formatedData = data.data.map((area: SortAreaProps) => {
      return {
        id: area.id,
        area_name: area.area_name,
        capacity: area.max_capacity,
        cancellation_deadline: formatCancellation(area.cancellation_deadline),
        price: `R$ ${convertMoney(area.location_value_cents / 100)}`,
        disponibility: getStatus(area.is_available)
      };
    });

    const totalAreas = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalAreas, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteArea = async (id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'DELETE',
      url: `area/area-remove/${id}`,
      baseURL: process.env.REACT_APP_MS_AREA
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchAreas = async (
  areasData: SearchArea,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IAllAreasDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `area/area-list?search=${areasData.queryText}&includeMeta=true&sortDirection=${sortByDirection}&sort=${sortByField}&page=${page}`,
      baseURL: process.env.REACT_APP_MS_AREA
    });

    const formatedData = data.data.map((area: SortAreaProps) => {
      return {
        id: area.id,
        area_name: area.area_name,
        capacity: area.max_capacity,
        cancellation_deadline: formatCancellation(area.cancellation_deadline),
        price: `R$ ${convertMoney(area.location_value_cents / 100)}`,
        disponibility: getStatus(area.is_available)
      };
    });

    const totalAreas = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalAreas, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getImageToRequest = async (pictureData: FormData, id: string): Promise<any> => {
  try {
    const data = await instance.request({
      method: 'POST',
      url: `area/picture/${id}`,
      baseURL: process.env.REACT_APP_STORAGE_URL,
      data: pictureData
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const removeImageToRequest = async (id: string): Promise<any> => {
  try {
    const data = await instance.request({
      method: 'DELETE',
      url: `area/delete/${id}`,
      baseURL: process.env.REACT_APP_STORAGE_URL
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
