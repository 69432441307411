import create from 'zustand';
import { ResidentModalProps } from './ResidentModal.props';

const initialState = {
  isOpenResidentModal: false
};

const useResidentModalStore = create<ResidentModalProps>((set) => ({
  ...initialState,

  handleResidentModalOpen: () =>
    set((state) => ({ isOpenResidentModal: !state.isOpenResidentModal }))
}));

export { useResidentModalStore };
