/* eslint-disable react/jsx-props-no-spreading */
import { Modal as ModalComponent, TextField } from '@mui/material';
import IntlCurrencyInput from 'react-intl-currency-input';
import { Controller } from 'react-hook-form';
import { CustomFileInput } from '../CustomFileInput/CustomFileInput';
import { useModalController } from './Modal.controller';

import { ModalProps } from './Modal.props';
import {
  ModalBackButton,
  ModalButtonArea,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalContent,
  ModalNextButton,
  ModalTitle,
  Container,
  ModalInput,
  ModalInputTitle,
  ModalInputArea
} from './Modal.style';

export function Modal({
  isModalOpen,
  toggleModal,
  foldId,
  setSuccess,
  setIsModalRegisterOpen,
  getAllFiles,
  onComplete,
  setText,
  toggleSuccessModal
}: ModalProps) {
  const {
    sendRequest,
    fileErrorMsg,
    currencyConfig,
    isProgress,
    isDisable,
    control,
    register,
    errors,
    handleSubmit,
    closeModal
  } = useModalController(
    foldId,
    getAllFiles,
    setIsModalRegisterOpen,
    onComplete,
    setText,
    setSuccess,
    toggleModal
  );

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <Container>
        <ModalContent>
          <ModalCloseButton onClick={closeModal}>
            <ModalCloseButtonIcon />
          </ModalCloseButton>

          <ModalTitle>Enviar arquivos</ModalTitle>

          <form className="formContainer">
            <div className="inputContainer">
              <ModalInputArea>
                <ModalInputTitle>Data de Vencimento</ModalInputTitle>
                <Controller
                  name="date"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ModalInput
                      id="pay-file-input-date"
                      style={{ width: '100%' }}
                      placeholder="dd/mm/yyyy"
                      type="date"
                      {...register('date')}
                      error={!!errors.date}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors.date && <p className="errorMessage">{errors.date.message}</p>}
              </ModalInputArea>

              <ModalInputArea>
                <ModalInputTitle>Valor do Boleto</ModalInputTitle>
                <Controller
                  name="paymentValue"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <IntlCurrencyInput
                      id="pay-file-input-value"
                      className="valueInput deadlineInput priceInput"
                      onChange={onChange}
                      value={value}
                      currency="BRL"
                      limit={10}
                      config={currencyConfig}
                      render={(ref: any, props: any) => (
                        <TextField
                          style={{ width: '100%' }}
                          placeholder="R$000,00"
                          {...register('paymentValue')}
                          error={!!errors.paymentValue}
                          inputRef={ref}
                          {...props}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  )}
                />
                {errors.paymentValue && (
                  <p className="errorMessage">{errors.paymentValue.message}</p>
                )}
              </ModalInputArea>
            </div>

            <CustomFileInput
              sendRequest={sendRequest}
              accept="application/pdf"
              type="pdf"
              foldId={foldId}
              isProgress={isProgress}
              setText={setText}
              setSuccess={setSuccess}
              toggleSuccessModal={toggleSuccessModal}
            />
            {fileErrorMsg && <p className="errorMessage">{fileErrorMsg}</p>}

            <ModalButtonArea>
              <ModalNextButton
                id="pay-file-save-btn"
                style={{
                  backgroundColor: '#662483',
                  color: 'white',
                  width: '48.5%',
                  height: 44,
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 500,
                  textTransform: 'capitalize',
                  opacity: isDisable ? 0.7 : 1
                }}
                disabled={isDisable}
                variant="contained"
                disableElevation
                onClick={handleSubmit(sendRequest)}>
                Salvar
              </ModalNextButton>

              <ModalBackButton
                variant="contained"
                style={{
                  backgroundColor: '#F0F1F5',
                  width: '48.5%',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 500,
                  height: 44,
                  textTransform: 'capitalize'
                }}
                disableElevation
                onClick={closeModal}>
                Cancelar
              </ModalBackButton>
            </ModalButtonArea>
          </form>
        </ModalContent>
      </Container>
    </ModalComponent>
  );
}
