import styled from 'styled-components';
import { CloseRounded } from '@mui/icons-material';
import { Button, Checkbox, IconButton, Select, TextField, Typography } from '@mui/material';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  background: #fff;

  display: flex;
  flex-direction: column;
  width: 685px;

  padding: 24px 32px 48px;
  border-radius: 16px;

  @media (max-width: 768px) {
    width: 685px;

    border-radius: 0;
  }
`;

export const ModalTitle = styled.p`
  font-family: 'Public Sans';
  margin-bottom: 32px;
  text-align: left;

  font-size: 22px;
  font-weight: 600;
`;

export const ModalInputTitle = styled(Typography).attrs({
  fontFamily: 'Inter',
  marginBottom: '8px'
})``;

export const ModalInput = styled(TextField).attrs({
  mode: 'outlined'
})`
  width: 28rem;

  & + p {
    font-family: 'Inter';
    color: #ff0000;
  }
`;

export const ModalInputArea = styled.form`
  margin-bottom: 24px;

  .inputMask {
    width: 28rem;
  }
`;

export const ModalSelectButton = styled(Select)`
  width: 28rem;
`;

export const ModalCloseButton = styled(IconButton)`
  align-self: flex-end;
`;

export const ModalCloseButtonIcon = styled(CloseRounded)``;

export const ModalCheckbox = styled(Checkbox)``;

export const ModalCheckboxLabel = styled(Typography).attrs({})``;

export const ModalCheckboxArea = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalButtonArea = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 32px;
`;

export const ModalNextButton = styled(Button)``;

export const ModalBackButton = styled(Button)``;
