/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useEnterpriseStore } from '../../../../store';
import { isInvalidFileName } from '../../../../helpers/fileNameValidator';

function useCustomFileInput(
  type: string,
  hasImageSizeValidation?: boolean,
  handleFile?: (file: File) => void,
  isTenSize?: boolean
) {
  const [link, setLink] = useState('');
  const {
    handleSetPlaceholderManual,
    handleSetManual,
    handleSetManualName,
    handleSetManualUrl,
    manual,
    manualUrl,
    signedManualUrl,
    placeholderManual,
    manualName
  } = useEnterpriseStore();

  const handleRemoveFile = () => {
    handleSetManualName('');
    handleSetManual('');
    handleSetManualUrl('');
    handleSetPlaceholderManual(null);
  };

  function handleSetNameAndLink() {
    if (!manual && !manualUrl) return;

    if (type === 'image' && (manual || manualUrl)) {
      handleSetManualName('Visualizar Manual');
      setLink(manual || manualUrl);
    }

    if (type === 'pdf' && (manual || manualUrl)) {
      handleSetManualName('Visualizar Manual');
      setLink(manual || manualUrl);
    }
  }

  useEffect(() => {
    handleSetNameAndLink();
  }, []);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target;
    const isImageOrPDF =
      event.files![0].type === 'image/jpeg' ||
      event.files![0].type === 'image/png' ||
      event.files![0].type === 'image/jpg' ||
      event.files![0].type === 'application/pdf';

    if (event.files) {
      let error = false;

      if (isInvalidFileName(event.files[0].name)) return;

      if (!isImageOrPDF) {
        toast.error('É permitido apenas arquivos JPEG, PNG e PDF');
        error = true;
      }

      if (isTenSize && event.files[0].size > 10000000) {
        toast.error('O arquivo precisa ter no máximo 10MB.');
        error = true;
      }

      const manualFile = event.files[0];
      const img = new Image();

      img.onload = function whatIsHeightAndWidth() {
        if (Number(manualFile.size) > 10000000) {
          toast.error(`O tamanho do arquivo ${manualFile.name} excede 10MB`);
          handleRemoveFile();
          error = true;
        }
      };

      if (error) return;

      const file = event.files[0];

      handleFile && handleFile(file);
      handleSetPlaceholderManual(event.files[0]);
      handleSetManualName(event.files[0].name);
      handleSetManual(file);
    }
  };

  return {
    signedManualUrl,
    manualName,
    handleChangeFile,
    handleRemoveFile
  };
}

export default useCustomFileInput;
