import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FormRequiredFields } from './FormForgotPassword.props';
import { useAuthStore } from '../../../../store';

export const useForgotPasswordController = () => {
  const { resetPassword } = useAuthStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Digite um email válido.')
      .required('Por favor, preencher campo e-mail.')
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const submitForm = () => {
    const { email } = getValues();

    resetPassword(email, toggleModal);
  };

  return {
    submitForm,
    handleSubmit,
    register,
    errors,
    control,
    toggleModal,
    isModalOpen,
    handleBackToLogin
  };
};
