import minilogo from '../../assets/images/hauzy-logo-1800.png';
import { ILogoProps } from './Logo.props';

import Container from './Logo.style';

export function Logo(props: ILogoProps) {
  const { heightContainer, widthContainer, heightImg, widthImg } = props;
  return (
    <Container
      heightContainer={heightContainer}
      widthContainer={widthContainer}
      heightImg={heightImg}
      widthImg={widthImg}>
      <img src={minilogo} alt="Logo Hayzy by OR" />
    </Container>
  );
}
