import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 16px 40px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;

  .icon {
    color: rgba(0, 0, 0, 0.5);
  }

  #fileInput {
    cursor: pointer;
    color: transparent;
    position: absolute;
    z-index: 100;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  #fileInput::file-selector-button {
    display: none;
  }

  #fileInputSelected {
    display: none;
  }

  .closeButton {
    color: #646464;
  }

  .closeButton:hover {
    background-color: transparent;
    opacity: 0.9;
  }

  .link {
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: none;
  }

  .previewImage {
    width: 140px;
    height: 65px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .previewImageInput {
    display: flex;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: left;
  }

  .imageContainer {
    display: flex;
    flex-direction: column;
  }

  .imageText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 8px;
    display: flex;
    align-items: center;
    color: #777777;
    max-width: 70px;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .infoImage {
    background: #f5f5f5;
    border: 0.312526px solid #c4c4c4;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
  }

  .imageIcon {
    height: 14px;
    width: 14px;
  }

  .trashContainer {
    cursor: pointer;
    margin-top: 3px;
  }
`;

export default Container;
