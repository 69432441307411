/* eslint-disable @typescript-eslint/no-var-requires */
import { Toolbar, IconButton, Box, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import MuiAppBar from '@mui/material/AppBar';

import Container from './Header.style';
import useHeaderController from './Header.controller';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import { ProfileModal } from './components/ProfileModal/ProfileModal';
import { NotificationsModal } from './components/NotificationsModal/NotificationsModal';

const notificationIcon = require('../../../../assets/images/notification.svg');
const newNotificationIcon = require('../../../../assets/images/newNotification.svg');

export default function Header() {
  const {
    open,
    isAdmin,
    widthState,
    isOpenProfileModal,
    userInitialLetters,
    notificationsList,
    isOpenNotificationModal,
    haveNewNotification,
    openNotificationsModal,
    handleDrawerOpen,
    handleProfileModalOpen
  } = useHeaderController();

  const isNotAdmin = !isAdmin;

  return (
    <Container>
      <MuiAppBar className="appbar">
        <Toolbar className="toolbar">
          <IconButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ ...((open || Number(widthState) > 700) && { display: 'none' }) }}>
            <Menu />
          </IconButton>
          <Box className="header-options-container">
            <Box>
              <HeaderLogo />
            </Box>
            <Box className="header-icons">
              {isNotAdmin && (
                <IconButton onClick={openNotificationsModal}>
                  {haveNewNotification ? (
                    <newNotificationIcon.ReactComponent />
                  ) : (
                    <notificationIcon.ReactComponent />
                  )}
                </IconButton>
              )}
              <IconButton
                className="icon-container"
                id="profileOptionsButton"
                onClick={handleProfileModalOpen}>
                <Typography className="userInitialLetter">{userInitialLetters}</Typography>
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
        {isOpenProfileModal && <ProfileModal />}
      </MuiAppBar>
      {isOpenNotificationModal && isNotAdmin && <NotificationsModal data={notificationsList} />}
    </Container>
  );
}
