import create from 'zustand';

import {
  searchLostItems,
  sortOrdersData,
  changeStatus,
  getLostItemsDetailsById,
  aproveOrReproveRequest
} from '../../services/requests/lostItems/LostItems.request';

import { LostItemsRequestData } from '../../services/requests/lostItems/LostItems.dto';

import { LostItemsStoreProps } from './LostItems.props';

const initialState = {
  id: '',
  lostItems: [],
  lostItemByID: null,
  lostItemIDs: [],
  totalLostItems: 0,
  field: 'status',
  direction: '',
  confirmModalOpen: false,
  signedimageLostItemsUrl: ''
};

export const useLostItemsStore = create<LostItemsStoreProps>((set, get) => ({
  ...initialState,

  handleSetId: (id) => set((state) => ({ ...state, id })),
  handleSetConfirmModalOpen: (confirmModalOpen) => set((state) => ({ ...state, confirmModalOpen })),

  handleSetLostItemIDs: (lostItemIDs) => set((state) => ({ ...state, lostItemIDs })),

  setSignedImgUrl: (signedimageLostItemsUrl) =>
    set((state) => ({ ...state, signedimageLostItemsUrl })),

  handleSetTotalLostItems: (totalItems: number) => set((state) => ({ ...state, totalItems })),

  handleSetLostItemByID: (lostItemByID: LostItemsRequestData) =>
    set((state) => ({ ...state, lostItemByID })),

  handleSetSortByField: (field) => set((state) => ({ ...state, field })),

  handleSortByDirection: (direction) => set((state) => ({ ...state, direction })),

  getAllLostItemsSortRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSortByDirection(sortByDirection);

      const data = await sortOrdersData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  serchLostItemsRequest: async (query, page, sortByField, sortByDirection) => {
    try {
      const data = await searchLostItems(query, page, sortByField, sortByDirection);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  handleSetLostItems: (lostItems) => set((state) => ({ ...state, lostItems })),

  changeStatusLostItems: async (status, id) => {
    try {
      await changeStatus(status, id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  handleAproveOrReproveRequest: async (status: 'aprove' | 'reprove', id: string) => {
    try {
      await aproveOrReproveRequest(status, id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getLostItemsDetailsByIdRequest: async (id: string) => {
    try {
      const data = await getLostItemsDetailsById(id);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));
