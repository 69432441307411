import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 22px 30px;
    background: #ffffff;
    border-radius: 6px;
    width: 480px;
    height: 294px;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: 10px;
  }

  .button {
    padding: 12px;
    width: 50%;
  }

  .exitButton {
    background-color: #662483;
    color: #ffffff;

    &:hover {
      background-color: #662483;
      opacity: 0.9;
    }
  }

  .cancelButton {
    background-color: #f0f1f5;

    &:hover {
      background-color: #e9e9e9;
    }
  }

  @media (max-width: 500px) {
    .container {
      width: 345px;
      height: 240px;
      padding: 15px;
    }
  }
`;

export default Background;
