import { Box, FormControl, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';
import Background from './FormNewPassword.style';

import { CustomInput, CustomButton, Logo } from '../../../../components';

import { useForgotPasswordController } from './FormNewPassword.controller';

export const FormNewPassword = () => {
  const { submitForm, handleSubmit, control, register, errors } = useForgotPasswordController();
  return (
    <Background>
      <Box className="logoAndLoginContainer">
        <Logo />
        <Box>
          <Typography variant="h4" fontWeight="600">
            Recuperar senha
          </Typography>
        </Box>
        <Typography className="subtitle" variant="body1" fontWeight="400" color="text.secondary">
          Sua senha deve possuir o mínimo de 6 caracteres, com <br /> letras e números não
          sequenciais e conter pelo menos <br /> uma letra maiúscula e um símbolo.
        </Typography>
        <FormControl className="loginContainer">
          <Controller
            name="password"
            control={control}
            render={() => (
              <Box className="inputErrorContainer">
                <CustomInput
                  register={{ ...register('password') }}
                  error={errors.password}
                  label="Senha"
                  type="password"
                />
                {errors.password && !errors.confirmPassword && (
                  <Box className="errorMessage">{errors.password.message}</Box>
                )}
              </Box>
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={() => (
              <Box className="inputErrorContainer">
                <CustomInput
                  register={{ ...register('confirmPassword') }}
                  error={errors.confirmPassword}
                  label="Confirmar senha"
                  type="password"
                />
                {errors.confirmPassword && !errors.password && (
                  <Box className="errorMessage">{errors.confirmPassword.message}</Box>
                )}
              </Box>
            )}
          />
          <CustomButton text="Confirmar" onClick={handleSubmit(submitForm)} />
        </FormControl>
      </Box>
    </Background>
  );
};
