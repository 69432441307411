import styled from 'styled-components';

const CollapsibleSetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  .collapsibleItem {
    display: flex;
    flex-direction: column;

    .collapsibleHeader {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.8rem 0;
    }

    .collapsibleContent {
      color: #000;

      text-align: justify;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%; /* 19.2px */
    }
  }
`;

export default CollapsibleSetContainer;
