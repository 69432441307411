/* eslint-disable @typescript-eslint/no-var-requires */
import { Grid, Typography } from '@mui/material';
import usePaymentSlipController from './PaymentSlip.controller';
import {
  ActionModal,
  DeleteModal,
  InfoModal,
  CustomButton,
  CustomSearchInput,
  BackToLastPageModal,
  CustomTable
} from '../../components';
import Container from './PaymentSlip.style';
import { paymentSlipTableHead } from '../../constants/PaymentSlip';
import { Modal } from './components/Modal/Modal';

const enterpriseactionedit = require('../../assets/images/enterpriseactionedit.svg');
const enterpriseactiontrash = require('../../assets/images/enterpriseactiontrash.svg');

export function PaymentSlip() {
  const {
    isOpenBackToLastPageModal,
    actionModalData,
    totalPages,
    totalItems,
    isModalRegisterOpen,
    isOpenDeleteModal,
    isOpenInfoModal,
    foldersIds,
    folderList,
    isSuccessfully,
    text,
    isModalEditOpen,
    goToFilesPage,
    handleDelete,
    changePage,
    handleCloseRegisterModal,
    handleOpenRegisterModal,
    sortAllPaymentFolders,
    getAllData,
    handleModalOpen,
    setFolderId,
    handleSearch,
    handleDeleteModalOpen,
    handleDrawerOpen
  } = usePaymentSlipController();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Boletos
          </Typography>
          <CustomSearchInput id="pay-search" handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12} sm={12} md={2.5} lg={2.5} alignSelf="flex-end">
          <CustomButton id="pay-new-folder" onClick={handleOpenRegisterModal} text="Nova Pasta" />
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            rowsId={foldersIds}
            tableHead={paymentSlipTableHead}
            tableData={folderList || []}
            onClick={handleDrawerOpen}
            totalPages={totalPages}
            totalItems={totalItems}
            funcToChangePage={changePage}
            handleSetItemId={setFolderId}
            noFilesFoundText="Não há nenhum registro de pastas no momento."
            sortFunc={sortAllPaymentFolders}
            containId
            funcToClickLine={goToFilesPage}
            isClickLine
            ActionModal={
              <ActionModal
                ActionModalData={actionModalData}
                ButtonsIcon={[
                  <enterpriseactionedit.ReactComponent className="actionIcon" />,
                  <enterpriseactiontrash.ReactComponent className="actionIcon" />
                ]}
              />
            }
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Tem certeza que deseja excluir esta página?"
          onClickDelete={handleDelete}
          handleModalClose={handleDeleteModalOpen}
        />
      )}
      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}

      <Modal
        title="Digite o nome da pasta"
        getAllData={getAllData}
        edit={isModalEditOpen}
        isModalOpen={isModalRegisterOpen}
        toggleModal={handleCloseRegisterModal}
      />

      {isOpenBackToLastPageModal && (
        <BackToLastPageModal goToPage="/payments" handleModalClose={handleCloseRegisterModal} />
      )}
    </Container>
  );
}
