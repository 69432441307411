import { instance } from '../../api';
import {
  SearchUnit,
  IUnitByIdDtoOutput,
  IUnitsDataDtoOutput,
  IUnitSimpleListOutput
} from './Units.dto';

export const sortUnitsData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IUnitsDataDtoOutput> => {
  try {
    const blockURLID = window.location.href.split('/').pop();
    const { data } = await instance.get(
      `unit/getByBlock/${blockURLID}?page=${page}&includeMeta=true&sortDirection=${sortByDirection}&sortBy=${
        sortByField || 'unit_model.name'
      }&search=${searchString}`
    );

    const formatedData = data.data;

    const totalUnits = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalUnits, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getSimpleUnitList = async (blockID: string): Promise<IUnitSimpleListOutput[]> => {
  try {
    const { data } = await instance.get(
      `unit/getByBlock/${blockID}?&sortDirection=ASC&sortBy=unit_model.name`
    );

    const formatedData = data.data.map((unit: { id: string; name: string }) => ({
      value: unit.id,
      label: unit.name
    }));

    return formatedData;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteUnit = async (id: string): Promise<void> => {
  try {
    await instance.delete(`unit/${id}`);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchUnits = async (
  unitsData: SearchUnit,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IUnitsDataDtoOutput> => {
  const blockID = window.location.href.split('/').pop();

  try {
    const { data } = await instance.get(
      `unit/getByBlock/${blockID}?search=${unitsData.queryText}&includeMeta=true&sortBy=${sortByField}&sortDirection=${sortByDirection}&page=${page}`
    );

    const formatedData = data.data;

    const totalUnits = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalUnits, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const updateUnitName = async (unitID: string, newName: string): Promise<void> => {
  try {
    const payload = { name: newName };
    await instance.patch(`unit/${unitID}`, payload);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getUnitById = async (unitID: string): Promise<IUnitByIdDtoOutput> => {
  try {
    const { data } = await instance.get(`unit/${unitID}`);

    return { data: { data: data.data } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const registerUnit = async (units: string[], blockId: string): Promise<void> => {
  try {
    await instance.post('unit', { units, blockId });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
