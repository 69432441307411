/* eslint-disable no-underscore-dangle */
import { Params } from 'react-router-dom';
import { instance } from '../../api';
import { SearchDocuments } from '../documents/Documents.dto';
import { IFilesByIdAllDataDtoOutput } from './Files.dto';

export const deleteFile = async (foldId: Readonly<Params<string>>, id: string): Promise<void> => {
  try {
    const { id: teste } = foldId;
    await instance.delete(`documents/deletefile/${teste}`, {
      data: {
        fileId: id
      }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const verifyFileName = async (id: string, name: string): Promise<boolean> => {
  try {
    const { data } = await instance.put(`documents/verifyname/${id}`, {
      name
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const renameFile = async (id: string, name: string, idFile: string): Promise<void> => {
  try {
    return await instance.put(`documents/renamefile/${id}`, {
      name,
      fileId: idFile
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortFilesData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  foldId: Readonly<Params<string>>,
  searchString: string
): Promise<IFilesByIdAllDataDtoOutput> => {
  const { id } = foldId;

  try {
    const { data } = await instance.get(
      `documents/files/${id}?includeMeta=true&page=${page}&sortDirection=${
        sortByDirection || 'DESC'
      }&sortBy=${
        sortByField || 'created_at'
      }&filterFields=folderId,name,id,path,size,type,created_at&search=${searchString}`
    );

    const convertBytesToMegaBytes = (bytes: number) => {
      return (bytes / 1024 / 1024).toFixed(2);
    };

    const totalFiles = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    const formatedData = data.data.map(
      (item: { id: string; name: string; type: string; size: number }) => {
        return {
          id: item.id,
          name: item.name,
          size: `${convertBytesToMegaBytes(item.size)}MB`,
          type: item.type === 'folder' ? 'Pasta' : `Arquivo ${item.type.split('/')[1]}`
        };
      }
    );

    return { data: { data: formatedData, totalFiles, totalPages }, folderName: data.name };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchFiles = async (
  filesData: SearchDocuments,
  foldId: Readonly<Params<string>>,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IFilesByIdAllDataDtoOutput> => {
  const { id } = foldId;

  try {
    const { data } = await instance.get(
      `documents/files/${id}?search=${filesData.queryText}&filterFields=folderId,name,id,path,size,type,created_at&includeMeta=true&sortDirection=${sortByDirection}&sortBy=${sortByField}&page=${page}`
    );

    const convertBytesToMegaBytes = (bytes: number) => {
      return (bytes / 1024 / 1024).toFixed(2);
    };

    const formatedData = data.data.map(
      (item: { id: string; name: string; type: string; size: number }) => {
        return {
          id: item.id,
          name: item.name,
          size: `${convertBytesToMegaBytes(item.size)}MB`,
          type: item.type === 'folder' ? 'Pasta' : `Arquivo ${item.type.split('/')[1]}`
        };
      }
    );

    const totalFiles = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: formatedData, totalFiles, totalPages }, folderName: data.name };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getFileById = async (id: string): Promise<any> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `folder/file/${id}`,
      baseURL: process.env.REACT_APP_STORAGE_URL
    });

    return { data };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
