import styled from 'styled-components';

const Background = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 116px;
  .subtitle {
    margin-top: 8px;
  }
  .loginContainer {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 400px;
  }
  .logoAndLoginContainer {
    box-shadow: 3px 5px 11px rgba(67, 89, 113, 0.3);
    border-radius: 20px;
    padding: 30px;
  }
  .linkContainer {
    margin-bottom: 80px;
  }
  .inputErrorContainer {
    margin-bottom: 20px;
  }
  .errorMessage {
    margin-top: 5px;
    font-size: 15px;
    color: red;
  }
  @media (max-width: 850px) {
    width: 100vw;
  }
  @media (max-width: 500px) {
    .loginContainer {
      width: 300px;
    }
  }
`;

export default Background;
