import { HalfPageLogo, SplitScreen } from '../../components';
import Background from './ChangeWebPassword.styled';
import { FormChangePassword } from './components/FormChangePassword/FormChangePassword';

export const ChangeWebPassword = () => {
  return (
    <Background>
      <SplitScreen
        Left={FormChangePassword}
        Right={HalfPageLogo}
        leftWeight={0.5}
        rightWeight={0.5}
      />
    </Background>
  );
};
