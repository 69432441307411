import styled from 'styled-components';
import { ILogoProps } from './Logo.props';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props: ILogoProps) => props?.heightContainer ?? '60px'};
  height: ${(props: ILogoProps) => props?.widthImg ?? '60px'};
  margin-bottom: 60px;
  img {
    height: ${(props: ILogoProps) => props?.heightImg ?? '100%'};
    height: ${(props: ILogoProps) => props?.widthImg ?? '100%'};
  }
`;

export default Container;
