import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  width: 448px;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
  top: -25px;
  right: 55px;
  box-shadow: 0px 2px 6px rgba(67, 89, 113, 0.2);
  background-color: #fff;
  z-index: 9999;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }

  ::-webkit-scrollbar-thumb {
    background: #662483;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .notificationData {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-bottom: 1px solid #f0f0f0;
    height: max-content;
    color: #6d6d6d;
    font-size: 18px;
    cursor: pointer;
  }

  .noData {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newNotification {
    background-color: #fff;
  }

  .oldNotification {
    background-color: #f0f0f0;
  }

  .title {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .showMore {
    width: 100%;
    color: #662483;
  }

  @media (max-width: 520px) {
    width: 95%;
    margin-inline: 1rem;
    right: 0;
  }
`;

export default Container;
