/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Typography } from '@mui/material';
import Container from './CustomFileInput.style';
import useCustomFileInput from './CustomFileInput.controller';
import { CustomFileInputProps } from './CustomFileInput.props';
import selectfile from '../../../../assets/images/selectfile.svg';

const imageIcon = require('../../../../assets/images/imageicon.svg');
const filesTrash = require('../../../../assets/images/filesTrash.svg');

export function CustomFileInput(props: CustomFileInputProps) {
  const { accept, type, imagesSrc } = props;
  const { handleChangeFile, files, handleRemoveFile, getImageToPreview } = useCustomFileInput(
    type,
    imagesSrc || ['']
  );

  return (
    <Container>
      <input
        id="fileInput"
        name="fileInput"
        multiple
        type="file"
        accept={accept}
        onChange={handleChangeFile}
      />
      {files.length > 0 ? (
        <Box className="previewImageInput">
          {files &&
            files.length > 0 &&
            files.map((imageFile: any, index: number) => (
              <Box className="imageContainer" style={{ zIndex: 100 }} key={String(imageFile.src)}>
                <Box className="previewImage" onClick={() => getImageToPreview(imageFile.src)}>
                  <img src={imageFile.src} alt="delete" />
                </Box>
                <Box className="infoImage">
                  <imageIcon.ReactComponent className="imageIcon" />
                  <Typography className="imageText">{`${imageFile.name.slice(
                    0,
                    12
                  )}...`}</Typography>
                  <Box
                    className="trashContainer"
                    onClick={() => handleRemoveFile(index, imageFile.name)}>
                    <filesTrash.ReactComponent className="imageIcon" />
                  </Box>
                </Box>
              </Box>
            ))}
          <input
            id="fileInputSelected"
            name="fileInput"
            multiple
            type="file"
            accept={accept}
            onChange={handleChangeFile}
          />
        </Box>
      ) : (
        <>
          <img src={selectfile} alt="select file icon" className="icon" />
          <Box flexDirection="column">
            <Typography marginBottom={0.5} variant="body2">
              Selecione um arquivo ou arraste aqui
            </Typography>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.5)">
              Arquivos até 10MB
            </Typography>
          </Box>
          <input
            id="fileInput"
            name="fileInput"
            multiple
            type="file"
            accept={accept}
            onChange={handleChangeFile}
          />
        </>
      )}
    </Container>
  );
}
