import React from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import FunctionalitiesContainer from './EnterpriseFunctionality.style';
import { EnterpriseFunctionalityProps } from './EnterpriseFunctionality.props';

function EnterpriseFunctionality(props: EnterpriseFunctionalityProps) {
  const { functionalities, functionalitiesDisabled, handleGetFunctionalities } = props;
  return (
    <Grid container columns={12}>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight={600} marginTop={1} marginBottom={1}>
          Funções Disponíveis:
        </Typography>
      </Grid>

      {functionalities.map((functionality, index) => (
        <React.Fragment key={functionality}>
          <Grid item xs={6} sm={6} lg={4} xl={3}>
            <FunctionalitiesContainer>
              <FormControlLabel
                className={
                  functionalitiesDisabled.includes(functionality)
                    ? 'on functionalitiesBox'
                    : 'off functionalitiesBox'
                }
                control={
                  <Checkbox
                    id={functionality}
                    disabled={functionalitiesDisabled.includes(functionality)}
                    onChange={() => handleGetFunctionalities(index)}
                  />
                }
                label={functionality}
              />
            </FunctionalitiesContainer>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}

export default EnterpriseFunctionality;
