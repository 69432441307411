import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 90px 24px 0px 20px;
  padding-right: 24px;
  padding-left: 24px;

  td {
    border-radius: 0px;
  }

  .title {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    margin-bottom: 12px;
  }

  .subTitle {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8b8b8b;
  }

  .rolePlaceholder {
    font-family: 'Inter';
    font-size: 15px;
    color: #2c2c2c;
  }

  .availableRoles {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    color: #2c2c2c;
    margin-top: 25px;
    margin-bottom: 5px;
  }

  .rolesList {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    margin-bottom: 25px;
  }

  .header {
    font-weight: 500;
    font-size: 14px;
    color: #2c2c2c;
  }

  .optionsContainer {
    position: relative;
    background-color: transparent;
    display: block;
  }

  .headerColumn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sortIcons {
    display: flex;
    flex-direction: column;
  }

  .errorMessage {
    margin-top: 3px;
    color: red;
  }

  .tableContainer {
    margin-top: 22px;
    background-color: #ffffff;
    border: 1px solid #e0e7ed;
    border-radius: 16px;
    min-height: 232px;
    max-height: 332px;
    overflow-y: auto;
    position: relative;
  }

  .roleInput {
    flex: 1;
    width: 100%;

    input {
      height: 13px;
      margin-top: 1px;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }
  }
`;

export const RoleInputArea = styled.div`
  margin-top: 9px;
  display: flex;
  gap: 15px;

  .createRoleBtn {
    cursor: pointer;
    background-color: #662483;
    color: #fff;
    height: 45px;
    width: 100%;
    max-width: 200px;
    border-radius: 8px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #662483;
  }

  .createRoleBtn:hover {
    background-color: #662483;
    color: #fff;
    opacity: 0.9;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    .createRoleBtn {
      width: 100%;
      max-width: 1000px;
    }
  }
`;

export const CustomIconButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  path {
    stroke: ${(props: { primaryColor: string }) => props.primaryColor};
    fill: ${(props: { primaryColor: string }) => props.primaryColor};
  }
`;
