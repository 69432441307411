import { useOcurrencesStore, usePreviewImageModalStore } from '../../../../store';

function usePreviewImageController() {
  const { imagePreview } = useOcurrencesStore();
  const { handleImagePreviewOpen } = usePreviewImageModalStore();

  return { image: imagePreview, handleImagePreviewOpen };
}

export default usePreviewImageController;
