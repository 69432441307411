import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useRefreshChangedPagesStore,
  useRolesModalStore,
  useSidebarSubLinkModalStore,
  useBackToLastPageModalStore,
  usePaginationStore
} from '../../store';

function useListIconsController(iconsName: string[]) {
  const navigation = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { handleSetCurrentPage } = usePaginationStore();
  const { isPageChanged } = useRefreshChangedPagesStore();
  const { getSidebarLinkName, handleSetSidebarLinkName } = useSidebarSubLinkModalStore();
  const { handleBackToLastPageModalOpen, handleSetSidebarLink } = useBackToLastPageModalStore();
  const { isAdmin } = useRolesModalStore();

  const [formatedIconsName, setFormatedIconsName] =
    useState<{ name: string; subLink: string[] }[]>();

  const formatIconsName = () => {
    const formated = iconsName.map((name) => {
      const linkWithSublink = { name, subLink: [''] };

      if (name === 'Funcionários' && !isAdmin) linkWithSublink.subLink.push('Cadastrar Cargos');
      if (name === 'Empreendimentos' && isAdmin) linkWithSublink.subLink.push('Blocos e Unidades');
      if (name === 'Áreas/Reservas' && !isAdmin) linkWithSublink.subLink.push('Reservas');

      return {
        name: name === 'Funcionários' && isAdmin ? 'Administradores' : linkWithSublink.name,
        subLink: linkWithSublink.subLink
      };
    });

    setFormatedIconsName(formated);
  };

  function handleSetSidebarOpen(isOpen: boolean) {
    setSidebarOpen(isOpen);
  }

  const moveToRoute = (link: string, linkName: string) => {
    if (isPageChanged) {
      handleSetSidebarLink(link);
      handleBackToLastPageModalOpen();
      return;
    }
    handleSetCurrentPage(1);

    navigation(link);

    handleSetSidebarLinkName(getSidebarLinkName() === linkName ? null : linkName);
  };

  const translateLinkName = (linkName: string) => {
    let linkTranslated;
    switch (linkName) {
      case 'Funcionários':
        linkTranslated = 'roles';
        break;
      case 'Áreas/Reservas':
        linkTranslated = 'reserve';
        break;
      case 'Empreendimentos':
        linkTranslated = 'blocks';
        break;
      default:
        break;
    }

    return linkTranslated;
  };

  const moveToRouteSublink = (link: string) => {
    if (isPageChanged) {
      handleSetSidebarLink(link);
      handleBackToLastPageModalOpen();
      return;
    }

    navigation(link);
  };

  function onClick(linkName: string, name: string, link: { name: string; subLink: string[] }) {
    if (link.subLink.length > 1 && !sidebarOpen) handleSetSidebarOpen(true);
    if (link.subLink.length === 1 && sidebarOpen) handleSetSidebarOpen(false);
    moveToRoute(`/${linkName}`, name);
  }

  useEffect(() => {
    formatIconsName();
  }, [iconsName]);

  return {
    formatedIconsName,
    sidebarOpen,
    handleSetSidebarOpen,
    getSidebarLinkName,
    translateLinkName,
    moveToRouteSublink,
    onClick
  };
}

export default useListIconsController;
