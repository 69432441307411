/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useMaintenancesStore } from '../../../../store';
import { isInvalidFileName } from '../../../../helpers/fileNameValidator';

function useCustomFileInput(type: string) {
  const [files, setFiles]: any = useState([{ src: '', name: '' }]);
  const [isPdf, setIsPdf] = useState(false);

  const {
    handleSetImagesToRequest,
    handleSetFilesToGetLink,
    handleSetImages,
    filesToGetLink,
    images,
    formatedImageToRequest,
    setImageName,
    setImageType,
    setImageSize
  } = useMaintenancesStore();

  function fillContainerWithInput() {
    const fileInput = document.getElementById('fileInputSelected');
    const fileInputContainer = document.getElementById('fileInputSelectedContainer');
    // @ts-ignore
    fileInputContainer.onclick = () => {
      fileInput?.click();
    };
  }

  const handleRemoveFile = async (name: string) => {
    setFiles([]);
    setIsPdf(false);
    handleSetImages([]);

    const formatedName = name
      .replace(' ', '')
      .replace('jpg', 'png')
      .replace('jpeg', 'png')
      .toLowerCase();

    if (filesToGetLink.length > 0) {
      handleSetFilesToGetLink(filesToGetLink.filter((file) => file.name !== name));
    }

    const newFormatedImagesToRequest = formatedImageToRequest.filter(
      (image) => !image.url.includes(formatedName)
    );

    handleSetImagesToRequest(newFormatedImagesToRequest);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target as any;

    setImageName(event.files[0].name);
    setImageSize(event.files[0].size);

    if (event.files) {
      if (files.length > 1) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (event.files.length > 10) {
        toast.error('Limite de imagens excedido.');
        return;
      }

      if (event.files[0].name.length >= 50) {
        toast.error('O nome do arquivo precisa ter menos de 50 caracteres.');
        return;
      }

      if (
        type === 'image' &&
        event.files[0].type !== 'image/jpeg' &&
        event.files[0].type !== 'image/png' &&
        event.files[0].type !== 'application/pdf'
      ) {
        toast.error('É permitido apenas arquivos JPEG, PNG e PDF.');
        return;
      }

      if (event.files[0].type !== 'application/pdf') {
        setIsPdf(true);
        setImageType('pdf');
      } else {
        setImageType('image');
      }

      if (isInvalidFileName(event.files[0].name)) return;
    }

    const newImages = [...images, ...event.files];

    handleSetImages(newImages);
    if (files[0] && files[0].src === '') files.pop();

    const filesImage = Array.from(event.files);
    filesImage.forEach((img: any) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          if (files.length + event.files.length > 1) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (files.length === 1) {
            toast.error('Limite de imagens excedido.');
            return;
          }

          if (Number(img.size) > 10000000) {
            toast.error(`O tamanho da imagem ${img.name} excede 10MB`);
            return;
          }

          setFiles((prev: any) => [...prev, { src: reader.result, name: img.name }]);
          handleSetFilesToGetLink([event.files[0]]);
        }
      };

      reader.readAsDataURL(img);
    });
  };

  return {
    handleChangeFile,
    files,
    isPdf,
    handleRemoveFile,
    fillContainerWithInput
  };
}

export default useCustomFileInput;
