import create from 'zustand';

import { SidebarModalProps } from './SidebarModal.props';

const initialState = {
  open: false,
  drawerWidth: 240
};

const useSidebarModalStore = create<SidebarModalProps>((set) => ({
  ...initialState,

  handleDrawerOpen: () => set((state) => ({ ...state, open: true })),
  handleDrawerClose: () => set((state) => ({ ...state, open: false })),
  setScreenWidth: (screenWidth: number) => set((state) => ({ ...state, screenWidth }))
}));

export { useSidebarModalStore };
