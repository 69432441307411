/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-var-requires */

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { BlocksAndUnits } from './Components/BlocksAndUnits/BlocksAndUnits';
import { BackToLastPageModal, InfoModal } from '../../components';
import { CustomFileInput } from './Components/CustomFileInput/CustomFileInput';
import useRegisterInformativesController from './RegisterInformatives.controller';
import Container from './RegisterInformatives.style';

export function RegisterInformatives() {
  const {
    control,
    register,
    errors,
    createNewInformatives,
    handleSubmit,
    handleModalOpen,
    NavigateToListInformatives,
    backButtonToBackListInformative,
    handleSetInformativeLocal,
    isOpenInfoModal,
    isBackButtonClicked,
    isShowUnit,
    isOpenBackToLastPageModal,
    sidebarLink,
    isEnterpriseLocal,
    isSuccessfully,
    text
  } = useRegisterInformativesController();

  return (
    <Container>
      <Grid container spacing={2} marginBottom={1}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5" fontWeight={500}>
            Criação de Informativos
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={1}>
          <Typography className="titleInline">
            Olá! Você está na tela de cadastro de informativos do condomínio.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="inputLabel">Assunto</Typography>
          <Controller
            name="name"
            control={control}
            render={() => (
              <TextField
                id="informatives_name"
                className="valueInput"
                placeholder="Insira o assunto"
                {...register('name')}
                error={!!errors.name}
                sx={{ borderColor: '#A7A7A7', backgroundColor: '#E9E9E9', color: '#7D7D7D' }}
              />
            )}
          />
          <Typography className="errorMessage">{errors.name?.message}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className="subTitle">Descrição</Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            render={() => (
              <TextField
                id="informatives_description"
                placeholder="Digite aqui"
                className="descriptionInput"
                multiline
                rows={8}
                {...register('description')}
              />
            )}
          />
          <Typography className="errorMessage">{errors.description?.message}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Typography className="subTitle">Envio de informativos</Typography>
            <Box>
              <FormControl>
                <RadioGroup row defaultValue="Empreendimento">
                  <FormControlLabel
                    id="informatives_selectEnterprise"
                    value="Empreendimento"
                    control={<Radio />}
                    label="Empreendimento"
                    onClick={() => handleSetInformativeLocal(true)}
                  />
                  <FormControlLabel
                    id="informatives_selectBlock"
                    value="Bloco"
                    control={<Radio />}
                    label="Bloco"
                    onClick={() => handleSetInformativeLocal(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Grid>

        {!isEnterpriseLocal && (
          <Grid item xs={12}>
            <BlocksAndUnits isShowUnit={isShowUnit} />
          </Grid>
        )}

        <Grid item xs={12}>
          <CustomFileInput accept="" type="" />
        </Grid>

        <Grid item xs={12}>
          <Box className="buttonContainer">
            <Box className="backAndSaveContainer">
              <Button
                id="informatives_registerButton"
                onClick={handleSubmit(createNewInformatives)}
                className="saveButton">
                Salvar
              </Button>
              <Button className="backButton" onClick={backButtonToBackListInformative}>
                Voltar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isOpenInfoModal && (
        <InfoModal
          isSuccessfully={isSuccessfully}
          onClick={isSuccessfully ? NavigateToListInformatives : handleModalOpen}
          text={text}
        />
      )}
      {isOpenBackToLastPageModal && (
        <BackToLastPageModal
          goToPage={isBackButtonClicked ? '/informatives' : sidebarLink || '/informatives'}
        />
      )}
    </Container>
  );
}
