import { instance } from '../../api';
import { formatData } from '../../../helpers/formatData';
import {
  IEmployeesDtoOutput,
  IEmployeesByIdAllDataDtoOutput,
  EmployeesRequestData,
  SearchEmployee
} from './Employees.dto';

export const registerEmployeesAdmin = async (
  employeesData: Partial<EmployeesRequestData>
): Promise<void> => {
  try {
    await instance.post('manager', {
      name: employeesData.name,
      email: employeesData.email,
      phone: employeesData.phone,
      cpf: employeesData.cpf
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const registerEmployeesManager = async (
  employeesData: Partial<EmployeesRequestData>
): Promise<void> => {
  try {
    await instance.post('manager/employee', {
      name: employeesData.name,
      email: employeesData.email,
      phone: employeesData.phone,
      cpf: employeesData.cpf,
      roleID: employeesData.roleID
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortEmployeesData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IEmployeesByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `manager/list-employees?filterFields=id,name,phone,email,created_at,role&includeMeta=true&page=${page}&sortDirection=${
        sortByDirection || 'DESC'
      }&sortBy=${sortByField || 'created_at'}&search=${searchString}`
    );

    const totalEmployees = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    const formatedData = data.data.map(
      (employee: {
        created_at: string;
        name: string;
        phone: string;
        email: string;
        role: string;
        id: string;
      }) => {
        return {
          id: employee.id,
          name: employee.name,
          phone: employee.phone,
          email: employee.email,
          created_at: formatData(employee.created_at),
          job: employee.role
        };
      }
    );

    return { data: { data: formatedData, totalEmployees, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortManagerData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IEmployeesByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `manager/admin?filterFields=id,name,phone,email,created_at,role&includeMeta=true&page=${page}&sortDirection=${
        sortByDirection || 'DESC'
      }&sortBy=${sortByField || 'created_at'}&search=${searchString}`
    );

    const totalEmployees = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    const formatedData = data.data.map(
      (employee: {
        created_at: string;
        name: string;
        phone: string;
        email: string;
        role: string;
        id: string;
      }) => {
        return {
          id: employee.id,
          name: employee.name,
          phone: employee.phone,
          email: employee.email,
          created_at: formatData(employee.created_at),
          job: employee.role
        };
      }
    );

    return { data: { data: formatedData, totalEmployees, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getEmployeesData = async (id: string): Promise<IEmployeesDtoOutput> => {
  try {
    const { data } = await instance.get(`manager/${id}`);
    return {
      data: data.data
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getAdminData = async (id: string): Promise<IEmployeesDtoOutput> => {
  try {
    const { data } = await instance.get(`manager/admin/${id}`);
    return {
      data: data.data
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getEnterpriseIdOfManagerRequest = async (id: string): Promise<IEmployeesDtoOutput> => {
  try {
    const { data } = await instance.get(`manager/${id}?filterFields=enterpriseId`);
    return {
      data: data.data
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editEmployeesAdmin = async (
  employeesData: Partial<EmployeesRequestData>
): Promise<void> => {
  try {
    const formatedData = {
      cpf: employeesData.cpf,
      email: employeesData.email,
      id: employeesData.id,
      name: employeesData.name,
      phone: employeesData.phone
    };
    await instance.put(`manager/${employeesData.id}`, formatedData);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editEmployeesManager = async (
  employeesData: Partial<EmployeesRequestData>
): Promise<void> => {
  try {
    await instance.put(`manager/employee/${employeesData.id}`, employeesData);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteEmployees = async (id: string): Promise<void> => {
  try {
    await instance.delete(`manager/${id}`);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteAdmin = async (id: string): Promise<void> => {
  try {
    await instance.delete(`manager/admin/${id}`);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchEmployees = async (
  employeesData: SearchEmployee,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IEmployeesByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `manager/list-employees?search=${employeesData.queryText}&filterFields=id,name,phone,email,created_at,cpf,role&includeMeta=true&sortDirection=${sortByDirection}&sortBy=${sortByField}&page=${page}`
    );

    const totalEmployees = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: data.data, totalEmployees, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchManager = async (
  employeesData: SearchEmployee,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IEmployeesByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `manager/admin?search=${employeesData.queryText}&filterFields=id,name,phone,email,created_at,cpf,role&includeMeta=true&sortDirection=${sortByDirection}&sortBy=${sortByField}&page=${page}`
    );

    const totalEmployees = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: data.data, totalEmployees, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
