/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */

import { Box, Button } from '@mui/material';
import { ExpandMoreRounded, ExpandLessRounded } from '@mui/icons-material';
import { IPermissionsProps } from './Permissions.props';
import { Container } from './Permissions.styles';
import { formatRoles } from '../../../../helpers/formatRoles';

export function Permissions(props: IPermissionsProps) {
  const { data, index, indexOfRow, isShowMore, handleShowMorePermissions } = props;
  const permissionsAmount = data.permissions.length;

  return (
    <Container>
      {data.permissions.sort().map((permission, indexPermission) => {
        if (permissionsAmount <= 4) {
          return (
            <Box className="tablePermission" key={permission}>
              {formatRoles(permission)}
            </Box>
          );
        }
        if (permissionsAmount > 4) {
          return (
            <>
              {isShowMore && index === indexOfRow ? (
                <Box className="tablePermission" key={indexPermission}>
                  {formatRoles(permission)}
                </Box>
              ) : (
                <>
                  {indexPermission <= 3 && (
                    <Box className="tablePermission" key={indexPermission}>
                      {formatRoles(permission)}
                    </Box>
                  )}
                </>
              )}
              {permissionsAmount > 4 && indexPermission === 3 && (
                <Button
                  className="expandButton"
                  key={data.permissions[indexPermission]}
                  endIcon={
                    isShowMore && index === indexOfRow ? (
                      <ExpandLessRounded />
                    ) : (
                      <ExpandMoreRounded />
                    )
                  }
                  onClick={() => handleShowMorePermissions(index)}>
                  {isShowMore && index === indexOfRow ? <p>Menos</p> : <p>Mais</p>}
                </Button>
              )}
            </>
          );
        }
        if (isShowMore && index === indexOfRow) {
          return (
            <Box
              key={permission + indexOfRow + index}
              className="tablePermission"
              style={{
                marginTop: isShowMore && index === indexOfRow ? 10 : 0
              }}>
              {formatRoles(permission)}
            </Box>
          );
        }
        return null;
      })}
    </Container>
  );
}
