import { Box, Typography } from '@mui/material';
import Container from './Card.style';
import { CardProps } from './Card.props';

export function Card(props: CardProps) {
  const { amount, title, color } = props;
  const colorSelector = {
    purple: '#662483',
    blue: '#099AD7',
    green: '#00740C'
  };
  return (
    <Container color={color}>
      <Box className="cardContainer">
        <Typography color="#2C2C2C" fontSize="14px" fontWeight={600} textTransform="uppercase">
          {title}
        </Typography>
        <Typography color={colorSelector[color] || '#662483'} fontSize="24px" fontWeight={700}>
          {amount}
        </Typography>

        <Box className="colorBar" />
      </Box>
    </Container>
  );
}
