export const deleteResidentsData = {
  title: 'Tem certeza que deseja excluir o morador?'
};

export const initialResidentsTableData = [
  {
    name: '',
    phone: '',
    email: '',
    created_at: '',
    unitId: ''
  }
];

export const residentsTableHeaders = [
  'Nome do morador',
  'Número',
  'E-mail',
  'Data de cadastro',
  'Unidade',
  'Bloco',
  'Ações'
];
