/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import formatDate from '../../helpers/formatDate';
import {
  useOcurrencesStore,
  useLoadModalStore,
  useInfoModalStore,
  useDeleteModalStore,
  usePreviewImageModalStore
} from '../../store';
import { Errors } from '../../constants/Errors';
import { ocurrencesDeleteMessage } from '../../constants/OcurrencesData';

function useOcurrencesDetailsController() {
  const navigate = useNavigate();

  const [fileLink, setFileLink] = useState<{ src: string }[]>([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [isAbleToDelete, setIsAbleToDelete] = useState(false);
  const [block, setBlock] = useState('');
  const [unit, setUnit] = useState('');
  const [status, setStatus] = useState('');
  const [local, setLocal] = useState('');
  const [isAnswer, setIsAnswer] = useState(false);
  const [answerText, setAnswerText] = useState('');
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [history, setHistory] = useState<{ name: string; updatedAt: string; sender: string }[]>();
  const [isBackToLastPage, setIsBackToLastPage] = useState(false);

  const {
    handleModalOpen,
    handleSetIsSuccessfully,
    handleSetText,
    isOpenInfoModal,
    isSuccessfully,
    text
  } = useInfoModalStore();
  const { handleImagePreviewOpen, isOpenImagePreview } = usePreviewImageModalStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const {
    getOcurrencesDetailsByIdRequest,
    ocurrenceId,
    deleteOcurrencesRequest,
    answerOcurrencesRequest,
    isOpenActionDetailModal,
    handleOpenActionDetailsModal,
    handleSetImagePreview
  } = useOcurrencesStore();
  const { toogleLoading } = useLoadModalStore();

  function handleOpenHistory() {
    setIsOpenHistory(!isOpenHistory);
  }

  async function getAllData() {
    toogleLoading();
    try {
      const data = await getOcurrencesDetailsByIdRequest(ocurrenceId);
      setStatus(data.status);
      setName(data.subject);
      setBlock(data.block);
      setLocal(data.local);
      setUnit(data.unit);
      setHistory(data.history);
      setDescription(data.description);
      setIsAbleToDelete(
        data.status === 'Concluído' &&
          new Date().getTime() >
            new Date(
              new Date(data.updatedAt.setDate(data.updatedAt.getDate() + 7)).setHours(1)
            ).getTime()
      );

      setCreatedAt(formatDate(new Date(data.date)));
      setFileLink(
        data.files.map((file: { _url: string; _name: string }) => ({
          src: file._url,
          name: file._name
        }))
      );
    } finally {
      toogleLoading();
    }
  }

  async function onSubmitDelete() {
    toogleLoading();
    try {
      if (!isAbleToDelete && status === 'Concluído') {
        handleSetText(
          'Não é possível excluir uma Ocorrência antes de 7 dias após ela ser concluída.'
        );
        handleSetIsSuccessfully(false);
        setIsBackToLastPage(false);
        handleModalOpen();
        return;
      }

      await deleteOcurrencesRequest(ocurrenceId || window.location.pathname.split('detail/')[1]);
      handleSetText('Ocorrência excluída com sucesso!');
      handleSetIsSuccessfully(true);
      setIsBackToLastPage(true);
      handleModalOpen();
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      setIsBackToLastPage(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  function handleSetOpenAnswer() {
    setIsAnswer(true);
  }

  async function handleSetCloseAnswer() {
    setIsAnswer(false);
  }

  async function handleAnswer() {
    toogleLoading();
    try {
      await answerOcurrencesRequest(answerText, window.location.pathname.split('detail/')[1]);
      handleSetText('Mensagem enviada com sucesso!');
      handleSetIsSuccessfully(true);
      handleModalOpen();
      handleSetCloseAnswer();
      getAllData();
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  function backToOcurrencesPage() {
    navigate('/occurrences');
  }

  function openActionDetailModal() {
    handleOpenActionDetailsModal(!isOpenActionDetailModal);
  }

  function handleClickImage(imageUrl: string) {
    handleSetImagePreview(imageUrl);
    handleImagePreviewOpen();
  }

  useEffect(() => {
    getAllData();
  }, []);

  return {
    fileLink,
    name,
    unit,
    local,
    block,
    setAnswerText,
    isAnswer,
    description,
    isOpenDeleteModal,
    handleDeleteModalOpen,
    handleSetCloseAnswer,
    onSubmitDelete,
    createdAt,
    handleModalOpen,
    isOpenImagePreview,
    handleAnswer,
    ocurrencesDeleteMessage,
    isOpenInfoModal,
    isSuccessfully,
    text,
    isAbleToDelete,
    status,
    isBackToLastPage,
    handleOpenHistory,
    isOpenHistory,
    history,
    handleClickImage,
    handleSetOpenAnswer,
    openActionDetailModal,
    backToOcurrencesPage,
    isOpenActionDetailModal,
    setIsBackToLastPage
  };
}

export default useOcurrencesDetailsController;
