/* eslint-disable no-useless-return */
import { useState } from 'react';
import { useEnterpriseStore, useRefreshChangedPagesStore } from '../../store';
import { getLocationByCEP } from '../../services/requests/CEP/CEP.request';

function useCustomInputController(isAuth?: boolean) {
  const [showPassword, setShowPassword] = useState(false);
  const { handleSetAddress, handleSetCep } = useEnterpriseStore();
  const { setChangedPage } = useRefreshChangedPagesStore();

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  const handleSetInputConfigs = (
    e: React.ChangeEvent<HTMLInputElement>,
    isCep: boolean,
    handleSetName?: (name: string) => void
  ) => {
    if (handleSetName) handleSetName(e.target.value);
    if (!isAuth) setChangedPage(true);
    if (!isCep) return;
    const target = e.target as HTMLInputElement;
    const formatedCepToSearch = target.value.replace('-', '');

    handleSetCep(target.value);

    if (formatedCepToSearch.length !== 8) return;

    async function setCep() {
      const data = await getLocationByCEP({ cep: Number(formatedCepToSearch) });
      handleSetAddress({
        city: data.city,
        district: data.district,
        state: data.state,
        street: data.street
      });
    }

    setCep();
  };

  function getMaxLength(field: string) {
    let length;

    switch (field) {
      case 'cep':
        length = 9;
        break;
      case 'maxCapacity':
        length = 4;
        break;
      case 'deadline':
        length = 3;
        break;

      default:
        length = 100;
        break;
    }

    return length;
  }

  return {
    handleClick,
    showPassword,
    getMaxLength,
    handleSetInputConfigs
  };
}

export default useCustomInputController;
