import { useEffect, useState } from 'react';
import {
  useInfoModalStore,
  useLoadModalStore,
  usePartnersStore,
  usePreviewImageModalStore
} from '../../../../store';
import { formatImageUrl } from '../../../../helpers/formatImageUrl';
import { getAWSImageUrl } from '../../../../services/requests/partners/Partners.request';
import { Errors } from '../../../../constants/Errors';

export const InformationModalController = () => {
  const {
    isOpenInformationModal,
    signedimagePartnersUrl,
    handleInformationModalOpen,
    getPartnerById,
    handleSetImage,
    setSignedImgUrl
  } = usePartnersStore();

  const { handleModalOpen, handleSetIsSuccessfully, handleSetText } = useInfoModalStore();
  const { handleImagePreviewOpen } = usePreviewImageModalStore();

  const [enterprise, setEnterprise] = useState('');
  const [CNPJ, setCNPJ] = useState('');
  const [email, setEmail] = useState('');
  const [segment, setSegment] = useState('');
  const [telephone, setTelephone] = useState('');
  const [celular, setCelular] = useState('');

  const { toogleLoading } = useLoadModalStore();

  async function getPartnerData() {
    toogleLoading();
    try {
      const data = await getPartnerById();
      if (data.logoUrl) {
        const { url } = await getAWSImageUrl(formatImageUrl(data.logoUrl));
        setSignedImgUrl(url);
      }
      if (!data) return;
      setEnterprise(String(data.name));
      setCNPJ(String(data.CNPJ));
      setEmail(String(data.email));
      setSegment(String(data.segment));
      setTelephone(String(data.tel));
      setCelular(String(data.cel));
    } catch (err: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  function resetValues() {
    setEnterprise('');
    setCNPJ('');
    setEmail('');
    setSegment('');
    setTelephone('');
    setSignedImgUrl('');
  }

  function handleClosePartnerModal() {
    handleInformationModalOpen();
    resetValues();
  }

  function getImageToPreview(file: string) {
    handleSetImage(file);
    handleImagePreviewOpen();
  }

  useEffect(() => {
    resetValues();
    if (isOpenInformationModal) getPartnerData();
  }, [isOpenInformationModal]);

  return {
    isOpenInformationModal,
    signedimagePartnersUrl,
    enterprise,
    CNPJ,
    email,
    segment,
    telephone,
    celular,
    handleInformationModalOpen,
    handleClosePartnerModal,
    getImageToPreview
  };
};
