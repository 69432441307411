import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAreaStore } from '../../store';
import { IReservationPeriod } from './AreaTimeInformation.props';

export const useAreaTimeInformationController = () => {
  const navigation = useNavigate();

  const [areaName, setAreaName] = useState('Nome da Área');
  const { areaId, getAreaDetailsByIdRequest, buttonSelected } = useAreaStore();

  const [locationTime, setLocationTime] = useState<IReservationPeriod[][]>([]);

  const requestAreaData = async () => {
    try {
      const { data } = await getAreaDetailsByIdRequest(areaId);
      setAreaName(data.areaName);
      setLocationTime(data.availableSchedule.schedule);
    } catch {
      navigation('/area');
      toast.error('Não foi possível obter os dados da área.');
    }
  };

  function formatTimeSize(time: string) {
    return time.slice(0, 5);
  }

  function getDayPosition() {
    let position = 0;
    switch (buttonSelected) {
      case 'Domingo':
        position = 0;
        break;
      case 'Segunda':
        position = 1;
        break;
      case 'Terça':
        position = 2;
        break;
      case 'Quarta':
        position = 3;
        break;
      case 'Quinta':
        position = 4;
        break;
      case 'Sexta':
        position = 5;
        break;
      case 'Sábado':
        position = 6;
        break;
      default:
        position = 0;
        break;
    }

    return position;
  }

  const allWeekDay = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

  useEffect(() => {
    requestAreaData();
  }, [areaId]);

  return { locationTime, navigation, getDayPosition, areaName, formatTimeSize, allWeekDay };
};
