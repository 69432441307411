import { Box } from '@mui/material';
import Background from './LoadingScreen.style';

export function LoadingScreen() {
  return (
    <Background>
      <Box className="loader">
        <Box />
        <Box />
      </Box>
    </Background>
  );
}
