import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin: 90px 24px 0px 20px;
  padding-right: 24px;
  padding-left: 24px;

  .registerButton {
    background: #662483;
    border-radius: 12px;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    text-transform: none;
  }

  .registerButton:hover {
    background: #662483;
    color: #fff;
    opacity: 0.95;
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`;

export default Container;
