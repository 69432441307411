import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useEffect, useState } from 'react';
import { FormRequiredFields } from './FormLogin.props';
import { useAuthStore, useLoadModalStore } from '../../../../store';

const handleLogin = () => {
  const { login } = useAuthStore();
  const { toogleLoading } = useLoadModalStore();
  const [emailValidation, setEmailValidation] = useState('');
  const [isDisable, setIsDisable] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Digite um e-mail válido.')
      .required('Por favor, preencher campo e-mail.'),
    password: yup.string().required('Por favor, preencher campo senha.')
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  useEffect(() => {
    const { email } = watch();
    setEmailValidation(email);
  }, [watch('email')]);

  const submitForm = async () => {
    toogleLoading();
    try {
      setIsDisable(true);
      const { email, password } = getValues();
      await login(email.trim(), password);
    } finally {
      setIsDisable(false);
      toogleLoading();
    }
  };

  return {
    submitForm,
    handleSubmit,
    register,
    errors,
    control,
    emailValidation,
    isDisable
  };
};

export default handleLogin;
