import create from 'zustand';
import {
  createFolder,
  deleteFolder,
  renameFolder,
  searchDocuments,
  sortDocumentData,
  getDocumentDataById
} from '../../services/requests/documents/Documents.request';
import { Errors } from '../../constants/Errors';

import { DocumentsStoreProps } from './Documents.props';

const initialState = {
  files: [],
  id: '',
  name: '',
  type: '',
  created_at: '',
  updated_at: '',
  field: 'created_at',
  direction: 'DESC'
};

export const useDocumentsStore = create<DocumentsStoreProps>((set, get) => ({
  ...initialState,
  errorFolder: '',
  variantInfor: '',
  idFolder: '',
  files: [],
  name: '',
  foldersId: [],
  fileId: '',
  filesId: [],

  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),

  setIdFolder: async (id: string) => {
    set({ idFolder: id });
  },

  setFolderName: async (name: string) => {
    set({ name });
  },

  getFolderName: () => {
    const { name } = get();
    return name;
  },

  handleSetFolderId: (id) => set((state) => ({ ...state, id })),

  handleSetFoldersId: (foldersId) => set((state) => ({ ...state, foldersId })),

  getIdFolder: () => {
    const { idFolder } = get();
    return idFolder;
  },

  createFolderRequest: async (name, onComplete) => {
    try {
      await createFolder(name);
      set({ errorFolder: 'Pasta criada com sucesso!', variantInfor: 'success' });
      onComplete();
    } catch (error: any) {
      const customErrorMessage =
        'Ocorreu um erro inesperado, por favor tente novamente mais tarde.';
      const originalErrorMessage = error.message;
      const message =
        originalErrorMessage === 'Cannot POST /document'
          ? customErrorMessage
          : originalErrorMessage;
      set({ errorFolder: message, variantInfor: 'error' });
      throw new Error(message);
    }
  },

  deleteFolderRequest: async (id: string) => {
    try {
      await deleteFolder(id);
    } catch (error: any) {
      const { message } = error;
      set({
        errorFolder: (Errors as any)({})[message] || 'Não foi possível excluir a pasta.',
        variantInfor: 'error'
      });
      throw new Error(error.message);
    }
  },

  getDocumentById: async (id) => {
    try {
      if (!id) return '';
      const { data } = await getDocumentDataById(id);
      return { data: data.data.name };
    } catch (error: any) {
      throw new Error('error.message');
    }
  },

  renameFolderRequest: async (id: string | undefined, name: string, onComplete) => {
    try {
      await renameFolder(id, name);
      set({ errorFolder: 'Pasta renomeada com sucesso!', variantInfor: 'success' });
      onComplete();
    } catch (error: any) {
      const { message } = error;
      set({ errorFolder: message, variantInfor: 'error' });
      throw new Error(error.message);
    }
  },

  searchDocumentsRequest: async (search, sortByField, sortByDirection, page) => {
    const { data } = await searchDocuments(search, sortByField, sortByDirection, page);
    return data;
  },

  getAllDocumentSortDataRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortDocumentData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      const { message } = error;
      set({ errorFolder: message, variantInfor: 'error' });
      throw new Error(error.message);
    }
  }
}));
