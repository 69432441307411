import { Box } from '@mui/material';
import { DayButtonProps } from './DayButton.props';
import Container from './DayButton.styled';
import useDayButtonController from './DayButton.controller';

export function DayButton(props: DayButtonProps) {
  const { name, id } = props;
  const { availableDay, handleSetAvailableDay } = useDayButtonController();
  return (
    <Container>
      <Box
        id={id}
        className={availableDay === name ? 'dayButtonSelected' : 'dayButtonContainer'}
        onClick={() => handleSetAvailableDay(name)}>
        <p>{name}</p>
      </Box>
    </Container>
  );
}
