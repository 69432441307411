import create from 'zustand';
import { MobilePreviewModalProps } from './ModalPreviewMobile.props';

const initialState = {
  isOpenMobilePreview: false
};

const usePreviewMobileModalStore = create<MobilePreviewModalProps>((set) => ({
  ...initialState,

  handleMobilePreviewOpen: () =>
    set((state) => ({ isOpenMobilePreview: !state.isOpenMobilePreview }))
}));

export { usePreviewMobileModalStore };
