/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-plusplus */
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Params } from 'react-router-dom';
import { Errors } from '../../../constants/Errors';
import { useFilesStore } from '../../../store/Files/Files.store';
import { isInvalidFileName } from '../../../helpers/fileNameValidator';

function useCustomFileInput(
  foldId: Readonly<Params<string>>,
  setSuccess: (isSuccess: boolean) => void,
  setText: (text: string) => void,
  toggleSuccessModal: () => void
) {
  const [files, setFiles] = useState([] as File[]);
  const { getFile, verifyFileNameRequest, getFileFinalized } = useFilesStore();

  const handleRemoveFile = (name: string) => {
    const result = files.filter((file) => file.name !== name);
    setFiles(result);
  };

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target as any;

    if (event.files && event.files.length > 0) {
      const { id } = foldId as unknown as { id: string };

      files.forEach((file) => {
        if (file.name === event.files[0].name) {
          setSuccess(false);
          setText('Arquivo já adicionado');
        }
      });

      for (let i = 0; i < event.files.length; i++) {
        if (event.files[i].name.length >= 50) {
          toast.error('O nome do arquivo precisa ter menos de 50 caracteres.');
          return;
        }
      }

      for (let i = 0; i < event.files.length; i++) {
        if (isInvalidFileName(event.files[i].name)) return;
      }

      try {
        const newfile = await verifyFileNameRequest(id, event.files[0].name);
        if (newfile) {
          const fileArr = Array.from(event.files);
          fileArr.forEach((file: any) => {
            getFile(file);
            setFiles((prev) => [...prev, file]);
          });
        }
      } catch (error: any) {
        setSuccess(false);
        setText(
          (Errors as any)({})[error.message] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        toggleSuccessModal();
      }
    }
  };

  return {
    handleChangeFile,
    handleRemoveFile,
    getFileFinalized,
    files
  };
}

export default useCustomFileInput;
