/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-array-index-key */
import { Table, TableHead, TableRow, TableBody, Button, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import CustomTableProps from './CustomTable.props';
import { useCustomTable } from './CustomTable.controller';
import getLengthObj from '../../helpers/getLengthObj';
import NoFilesFound from './components/NoFilesFound/NoFilesFound';
import CustomPagination from './components/CustomPagination/CustomPagination';

import Container, {
  StyledTableCell,
  StyledTableRow,
  CustomIconButton,
  ButtonColor
} from './CustomTable.style';

const sortupicon = require('../../assets/images/sortupicon.svg');
const sortdownicon = require('../../assets/images/sortdownicon.svg');

export function CustomTable(props: CustomTableProps) {
  const {
    tableHead,
    tableData,
    ActionModal,
    rowsId,
    containId,
    totalPages,
    totalItems,
    noFilesFoundText,
    funcToClickLine,
    isClickLine,
    onClick,
    funcToChangePage,
    handleSetItemId,
    sortFunc,
    renderAction
  } = props;
  const {
    isOpenActionModal,
    sortUp,
    sortDown,
    indexOfRow,
    sortItemId,
    handleClickOnActionBtn,
    handleClickOnLine,
    closeActionModalOnClickAway,
    changeSortDownColor,
    changeSortUpColor,
    handleClickSortButton,
    formatedTableData,
    formateRowsId,
    getCurrentPage
  } = useCustomTable(tableData, rowsId);

  return (
    <OutsideClickHandler onOutsideClick={closeActionModalOnClickAway}>
      <Container onClick={closeActionModalOnClickAway}>
        <Table stickyHeader sx={{ minWidth: 860 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableHead.map((head, index) => (
                <StyledTableCell key={head}>
                  <Box className="headerColumn">
                    <p>{head}</p>
                    <Box
                      display={head === 'Ações' ? 'none !important' : 'flex'}
                      className="sortIcons">
                      <CustomIconButton
                        primaryColor={sortUp && head === sortItemId ? '#333333' : '#D8D3D3'}
                        onClick={() =>
                          handleClickSortButton(changeSortUpColor, head, sortFunc, 'ASC', index)
                        }>
                        <sortupicon.ReactComponent style={{ stroke: 'red', fill: 'red' }} />
                      </CustomIconButton>
                      <CustomIconButton
                        primaryColor={sortDown && head === sortItemId ? '#333333' : '#D8D3D3'}
                        onClick={() =>
                          handleClickSortButton(changeSortDownColor, head, sortFunc, 'DESC', index)
                        }>
                        <sortdownicon.ReactComponent />
                      </CustomIconButton>
                    </Box>
                  </Box>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {tableData[0] && (
            <TableBody>
              {formatedTableData.map((data, index) => (
                <StyledTableRow
                  key={JSON.stringify(data)}
                  className={`tableRow ${isClickLine ? 'isClickLine' : ''}`}>
                  {Object.values(data).map((value: string, indexValue) => (
                    <React.Fragment key={indexValue}>
                      {!containId && (
                        <StyledTableCell id={formateRowsId[index]} className="tableCellRow">
                          <ButtonColor
                            id={formateRowsId[index]}
                            column={data.status === value ? 'statusColumn' : 'visitorType'}
                            typeCell={data.visitType === value ? 'typeCell' : 'visitorType'}
                            primaryColor={value}
                            page={getCurrentPage()}>
                            {value}
                          </ButtonColor>
                        </StyledTableCell>
                      )}

                      {containId && indexValue !== 0 && (
                        <StyledTableCell
                          id={formateRowsId[index]}
                          className={indexValue === 1 ? 'firstItem tableCellRow' : ''}
                          onClick={(e) => handleClickOnLine(e, funcToClickLine, handleSetItemId)}>
                          <ButtonColor
                            id={formateRowsId[index]}
                            primaryColor={value}
                            column={data.status === value ? 'statusColumn' : 'visitorType'}
                            typeCell={data.visitType === value ? 'typeCell' : 'visitorType'}
                            page={getCurrentPage()}>
                            {value}
                          </ButtonColor>
                        </StyledTableCell>
                      )}

                      {indexValue === getLengthObj(data) - 1 && (
                        <StyledTableCell
                          id={formateRowsId[index]}
                          className="optionsContainer tableCellRow">
                          <Button
                            id={formateRowsId[index]}
                            onClick={(e: React.MouseEvent<HTMLElement>) =>
                              handleClickOnActionBtn(
                                e,
                                onClick,
                                index,
                                handleSetItemId,
                                data.status
                              )
                            }>
                            <MoreVertIcon id={formateRowsId[index]} style={{ color: '#6d6d6d' }} />
                          </Button>
                          {isOpenActionModal && index === indexOfRow && ActionModal}
                          {isOpenActionModal &&
                            renderAction &&
                            index === indexOfRow &&
                            renderAction(formatedTableData[index])}
                        </StyledTableCell>
                      )}
                    </React.Fragment>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>

        {!formatedTableData[0] && <NoFilesFound text={noFilesFoundText} />}
      </Container>

      <CustomPagination
        totalPages={totalPages}
        totalItems={totalItems}
        funcToChangePage={funcToChangePage}
      />
    </OutsideClickHandler>
  );
}
