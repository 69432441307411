import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin-top: -36px;
  padding-right: 15px;
  display: flex;
  justify-content: right;
  gap: 5px;
  position: relative;
  z-index: 1001;

  .boxContainer {
    background: linear-gradient(144.9deg, #099ad7 0%, #0072a2 103.6%);
    box-shadow: 0px 2px 6px rgba(67, 89, 113, 0.3);
    border-radius: 5px;
    padding: 10px 10px 8px;
    gap: 5px;
    width: 67px;
    height: 72px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .cardText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8.8px;
    line-height: 15px;
    color: #ffffff;
    margin: -5px;
  }

  .iconContainer {
    margin-top: -5px;
  }
`;

export default Container;
