/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  InputLabel,
  TextField,
  Typography,
  Modal as ModalComponent,
  Button,
  MenuItem
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { useRegisterModalController } from './Modal.controller';
import { ModalProps, Option } from './Modal.props';
import Background, { ModalContent } from './Modal.style';
import { CustomFileInputPartners } from '../CustomFileInput/CustomFileInput';

export function Modal({
  isModalOpen,
  toggleModal,
  title,
  edit,
  partnersId,
  handleSetText,
  handleSuccessfully
}: ModalProps) {
  const {
    control,
    errors,
    sector,
    sectorData,
    register,
    submit,
    handleSubmit,
    closePartnersModal
  } = useRegisterModalController(edit, toggleModal, partnersId, handleSetText, handleSuccessfully);

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <Background>
        <ModalContent>
          <Box className="closeButton" onClick={closePartnersModal}>
            <CloseRounded />
          </Box>

          <Typography className="modalTitle">{title}</Typography>

          <Box className="container">
            <Box className="inputContainer" style={{ width: '70%' }}>
              <Typography className="inputTitle">Empresa</Typography>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="partners_name"
                    className="input"
                    style={{ width: '100%' }}
                    placeholder="Insira a empresa"
                    {...register('name')}
                    error={!!errors.name}
                    onChange={onChange}
                    value={
                      value ? value[0].toUpperCase() + value.substring(1).toLowerCase() : value
                    }
                  />
                )}
              />

              {(errors.name || errors.CNPJ) && (
                <p className="errorMessage">{errors.name?.message}</p>
              )}
            </Box>
            <Box className="inputContainer">
              <Typography className="inputTitle">CNPJ</Typography>
              <Controller
                name="CNPJ"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaskedInput
                    mask={[
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '/',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/
                    ]}
                    onChange={onChange}
                    style={{ width: '100%' }}
                    value={value}
                    render={(ref, props) => (
                      <TextField
                        id="partners_cnpj"
                        className="input"
                        placeholder="__.___.___/____-__"
                        {...register('CNPJ')}
                        error={!!errors.CNPJ}
                        inputRef={ref}
                        {...props}
                      />
                    )}
                  />
                )}
              />

              {(errors.name || errors.CNPJ) && (
                <p className="errorMessage">{errors.CNPJ?.message}</p>
              )}
            </Box>
          </Box>

          <Box className="inputContainer">
            <Typography className="inputTitle">E-mail</Typography>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="partners_email"
                  className="input"
                  style={{ width: '100%' }}
                  placeholder="Insira o e-mail"
                  {...register('email')}
                  error={!!errors.email}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.email && <p className="errorMessage">{errors.email.message}</p>}
          </Box>
          <Box className="container">
            <Box className="inputContainer" style={{ width: '70%' }}>
              <Typography className="inputTitle">Celular</Typography>
              <Controller
                name="cel"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaskedInput
                    mask={[
                      '+',
                      '5',
                      '5',
                      ' ',
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/
                    ]}
                    onChange={onChange}
                    style={{ width: '100%' }}
                    value={value}
                    render={(ref, props) => (
                      <TextField
                        id="partners_cel"
                        className="input"
                        placeholder="+55 99 99999-9999"
                        {...register('cel')}
                        error={!!errors.cel}
                        inputRef={ref}
                        {...props}
                      />
                    )}
                  />
                )}
              />

              {(errors.tel || errors.cel) && (
                <p className="errorMessage">
                  {!errors.cel?.message &&
                  errors.tel?.message === 'Insira o telefone ou celular para contato'
                    ? errors.tel?.message
                    : errors.cel?.message}
                </p>
              )}
            </Box>
            <Box className="inputContainer">
              <Typography className="inputTitle">Telefone</Typography>
              <Controller
                name="tel"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaskedInput
                    mask={[
                      '+',
                      '5',
                      '5',
                      ' ',
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/
                    ]}
                    onChange={onChange}
                    style={{ width: '100%' }}
                    value={value}
                    render={(ref, props) => (
                      <TextField
                        id="partners_tel"
                        className="input"
                        placeholder="+55 99 9999-9999"
                        {...register('tel')}
                        error={!!errors.tel}
                        inputRef={ref}
                        {...props}
                      />
                    )}
                  />
                )}
              />

              {(errors.tel || errors.cel) && <p className="errorMessage">{errors.tel?.message}</p>}
            </Box>
          </Box>
          <Box className="container">
            <Box className="inputContainer" style={{ width: '70%' }}>
              <Typography className="inputTitle">Setor da Empresa</Typography>
              <Controller
                name="sector"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Box className="sectorContainer">
                    <>
                      <InputLabel className="inputLabel">{value ? '' : 'Selecione'}</InputLabel>
                      <TextField
                        id="partners_sector"
                        className="selectInput"
                        {...register('sector')}
                        onChange={onChange}
                        select
                        value={value || sectorData}>
                        {sector &&
                          sector.map((option: Option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </TextField>
                      {(errors.sector || errors.company_segment) && (
                        <p className="errorMessage errorSector">{errors.sector?.message}</p>
                      )}
                    </>
                  </Box>
                )}
              />
            </Box>

            <Box className="inputContainer">
              <Typography className="inputTitle">Segmento</Typography>
              <Controller
                name="company_segment"
                control={control}
                render={() => (
                  <TextField
                    id="partners_segment"
                    className="input"
                    placeholder="Insira o segmento"
                    style={{ width: '100%' }}
                    {...register('company_segment')}
                    error={!!errors.company_segment}
                  />
                )}
              />
              {(errors.sector || errors.company_segment) && (
                <p className="errorMessage">{errors.company_segment?.message}</p>
              )}
            </Box>
          </Box>

          <Box className="container">
            <Box className="inputContainer">
              <Typography className="inputTitle">Website</Typography>
              <Controller
                name="website"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="partners_website"
                    className="input"
                    style={{ width: '100%' }}
                    placeholder="www.site.com.br"
                    {...register('website')}
                    error={!!errors.website}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors.website && <p className="errorMessage">{errors.website.message}</p>}
            </Box>
            <Box className="inputContainer">
              <Typography className="inputTitle">App Store</Typography>
              <Controller
                name="appleStore"
                control={control}
                render={() => (
                  <TextField
                    id="partners_applestore"
                    className="input"
                    placeholder="Insira um link"
                    style={{ width: '100%' }}
                    {...register('appleStore')}
                    error={!!errors.appleStore}
                  />
                )}
              />
              {errors.appleStore && <p className="errorMessage">{errors.appleStore.message}</p>}
            </Box>
            <Box className="inputContainer">
              <Typography className="inputTitle">Google Play</Typography>
              <Controller
                name="googlePlay"
                control={control}
                render={() => (
                  <TextField
                    id="partners_googleplay"
                    className="input"
                    placeholder="Insira um link"
                    style={{ width: '100%' }}
                    {...register('googlePlay')}
                    error={!!errors.googlePlay}
                  />
                )}
              />
              {errors.googlePlay && <p className="errorMessage">{errors.googlePlay.message}</p>}
            </Box>
          </Box>
          <Box>
            <Typography className="modalTitle">Logotipo</Typography>

            <CustomFileInputPartners accept=".jpg, .jpeg, .png" type="image" isTenSize />
            <Typography className="inputFileDescription">
              Arquivos permitidos (envio opcional): jpg, png.
            </Typography>
          </Box>

          <Box className="buttonArea">
            <Box display="flex" flexDirection="column" width="100%">
              <Button
                id="partners_registerButton"
                style={{
                  backgroundColor: '#662483',
                  color: 'white',
                  width: '100%',
                  height: 55,
                  fontSize: '16px',
                  marginBottom: '10px',
                  textTransform: 'capitalize'
                }}
                variant="contained"
                disableElevation
                onClick={handleSubmit(submit)}>
                {edit ? 'Editar' : 'Registrar'}
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  fontSize: '16px',
                  height: 55,
                  textTransform: 'capitalize'
                }}
                disableElevation
                onClick={closePartnersModal}>
                Voltar
              </Button>
            </Box>
          </Box>
        </ModalContent>
      </Background>
    </ModalComponent>
  );
}
