import { SplitScreen, HalfPageLogo } from '../../components';
import { FormForgotPassword } from './components/FormForgotPassword/FormForgotPassword';

import Background from './ForgotPassword.style';

export const ForgotPassword = () => {
  return (
    <Background>
      <SplitScreen
        Left={FormForgotPassword}
        Right={HalfPageLogo}
        leftWeight={0.5}
        rightWeight={0.5}
      />
    </Background>
  );
};
