/* eslint-disable jsx-a11y/label-has-associated-control */
import { Controller } from 'react-hook-form';
import { CustomInput } from '../CustomInput/CustomInput';
import { CustomControlledInputProps } from './CustomEnterprisesControlledInput.props';

export function CustomControlledInput(props: CustomControlledInputProps) {
  const {
    name,
    control,
    label,
    type,
    isSelect,
    register,
    values,
    value,
    placeholder,
    isCep,
    id,
    field,
    setEnterpriseName
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <>
          <label>{label}</label>
          <CustomInput
            isCep={isCep}
            id={id}
            register={register}
            label={placeholder || ''}
            type={type || ''}
            select={isSelect}
            values={values}
            value={value || ''}
            setEnterpriseName={setEnterpriseName}
            field={field}
          />
        </>
      )}
    />
  );
}
