import { functionalitiesToRequest } from '../../../../constants';

function useEnterpriseFunctionalityController() {
  function getCheckedFunctionalities(
    functionalityId: number,
    functionalitiesChecked: { name: string }[]
  ) {
    const functionalitiesCheckedId: number[] = [];

    functionalitiesToRequest.forEach((functionality, index) => {
      functionalitiesChecked.forEach((functionalityChecked) => {
        if (functionalityChecked.name === functionality) {
          functionalitiesCheckedId.push(index);
        }
      });
    });

    return functionalitiesCheckedId.indexOf(functionalityId) !== -1;
  }

  return {
    getCheckedFunctionalities
  };
}

export default useEnterpriseFunctionalityController;
