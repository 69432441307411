/* eslint-disable react/jsx-props-no-spreading */
import { Modal as ModalComponent, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useModalController } from './Modal.controller';

import { ModalProps } from './Modal.props';
import {
  ModalBackButton,
  ModalButtonArea,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalContent,
  ModalInputArea,
  ModalNextButton,
  ModalTitle,
  Container,
  ContainerTitleIcon
} from './Modal.style';

export function ModalName({
  isModalOpen,
  toggleModal,
  title,
  errorModal,
  onComplete,
  getAllFiles
}: ModalProps) {
  const { sendRequest, control, errors, register, handleSubmit, closeDocumentModal } =
    useModalController(isModalOpen, onComplete, getAllFiles, errorModal, toggleModal);

  return (
    <ModalComponent open={isModalOpen} onClose={closeDocumentModal}>
      <Container>
        <ModalContent>
          <ContainerTitleIcon>
            <ModalTitle>{title}</ModalTitle>
            <ModalCloseButton onClick={closeDocumentModal}>
              <ModalCloseButtonIcon />
            </ModalCloseButton>
          </ContainerTitleIcon>

          <ModalInputArea>
            <Controller
              name="name"
              control={control}
              render={() => (
                <TextField
                  id="pay-file-rename-input"
                  placeholder="Digite aqui"
                  color="primary"
                  className="nameInput"
                  style={{ borderRadius: '100px' }}
                  {...register('name')}
                  error={!!errors.name}
                  onKeyDown={(event) => {
                    if (event.key.includes('Enter')) event.preventDefault();
                  }}
                />
              )}
            />
            {errors.name && <p className="errorMessage">{errors.name.message}</p>}
          </ModalInputArea>

          <ModalButtonArea>
            <ModalNextButton
              id="pay-file-rename-btn"
              style={{
                backgroundColor: '#662483',
                color: 'white',
                width: '48.5%',
                height: 44,
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 500,
                textTransform: 'capitalize'
              }}
              variant="contained"
              disableElevation
              onClick={handleSubmit(sendRequest)}>
              Salvar
            </ModalNextButton>

            <ModalBackButton
              variant="contained"
              style={{
                backgroundColor: '#F0F1F5',
                width: '48.5%',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 500,
                height: 44,
                textTransform: 'capitalize'
              }}
              disableElevation
              onClick={closeDocumentModal}>
              Cancelar
            </ModalBackButton>
          </ModalButtonArea>
        </ModalContent>
      </Container>
    </ModalComponent>
  );
}
