import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(155, 155, 155, 0.1);
  padding-top: 5px;

  .tabButton {
    display: flex;
    flex-direction: column;
    color: #a9a9a9;
    text-transform: none;
    justify-content: center;
    height: 48px;
    gap: 7px;
    min-width: 1px;
    width: 100%;
  }
`;

export const HomeButtonContainer = styled.div`
  width: 18px;
  height: 18px;
  .homeSvg {
    padding-top: 5px;
    path {
      stroke: ${(props: { primaryColor: string }) => props.primaryColor};
    }
  }
`;

export default Container;
