import create from 'zustand';
import { toast } from 'react-toastify';
import {
  IAllResidentDtoOutput,
  IResidentDtoOutput
} from '../../services/requests/residents/Residents.dto';
import { ResidentStoreProps } from './Resident.props';
import {
  sortResidentsData,
  searchResidents,
  deleteResident,
  getAllResidentsData,
  registerResident,
  getResidentData,
  editResident,
  resendResidentEmail
} from '../../services/requests/residents/Residents.request';

const initialState = {
  residentsList: [],
  residentsId: [],
  residentId: '',
  direction: 'ASC',
  field: 'resident.name',
  blockList: undefined,
  unitList: undefined
};

const useResidentStore = create<ResidentStoreProps>((set, get) => ({
  ...initialState,

  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  handleSetResidentId: (residentId) => set((state) => ({ ...state, residentId })),
  handleSetResidentsId: (residentsId) => set((state) => ({ ...state, residentsId })),
  handleSetResidentsList: (residentsList) => set((state) => ({ ...state, residentsList })),
  handleRemoveOneResident: (residentId) =>
    set((state) => ({
      ...state,
      residentsList: state.residentsList.filter((resident) => resident.id !== residentId)
    })),
  setBlockList: (blockList) => set((state) => ({ ...state, blockList })),
  setUnitList: (unitList) => set((state) => ({ ...state, unitList })),
  sortResidentsDataRequest: async (
    page,
    sortByField,
    sortByDirection,
    searchString
  ): Promise<IAllResidentDtoOutput> => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortResidentsData(page, sortByField, sortByDirection, searchString);

      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  searchResidentsRequest: async (
    residentsData,
    sortByField,
    sortByDirection,
    page
  ): Promise<IAllResidentDtoOutput> => {
    try {
      const data = await searchResidents(residentsData, page, sortByField, sortByDirection);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  deleteResidentRequest: async (id): Promise<void> => {
    try {
      await deleteResident(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllResidentsDataRequest: async (page): Promise<IAllResidentDtoOutput> => {
    try {
      const data = await getAllResidentsData(page);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  registerResidentRequest: async (residentData): Promise<void> => {
    try {
      await registerResident(residentData);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getResidentDataRequest: async (id): Promise<IResidentDtoOutput> => {
    try {
      const data = await getResidentData(id);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  editResidentRequest: async (residentData, id): Promise<void> => {
    try {
      await editResident(residentData, id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  resendResidentEmailRequest: async (email) => {
    try {
      await resendResidentEmail(email);
      toast.success('Email reenviado com sucesso!');
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));

export { useResidentStore };
