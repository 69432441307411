/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import {
  useActionModalStore,
  useSortItemsStore,
  useAdminORStore,
  usePaginationStore
} from '../../store';
import { useVotingStore } from '../../store/Voting/Voting.store';

export function useCustomTable(
  tableData: Array<{ status: string; visitType?: string }>,
  rowsId: string[]
) {
  const { isOpenActionModal, handleIsLastItemOfTable, handleDrawerOpen } = useActionModalStore();
  const { enterpriseIdOfManager } = useAdminORStore();
  const { setStatus } = useVotingStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const {
    changeSortDownColor,
    changeSortUpColor,
    handleSetSortItemId,
    sortDown,
    sortUp,
    sortItemId,
    sortFields
  } = useSortItemsStore();

  const [indexOfRow, setIndexOfRow] = useState(-1);
  const [formatedTableData, setFormatedTableData] = useState(tableData);
  const [formateRowsId, setFormateRowsId] = useState(rowsId);

  function handleClickOnActionBtn(
    e: React.MouseEvent<HTMLElement>,
    func: () => void,
    index: number,
    handleSetItemId: (id: string) => void,
    status?: string
  ) {
    index === 9 ? handleIsLastItemOfTable(true) : handleIsLastItemOfTable(false);

    setStatus(String(status));
    const componentSelected = e.target as HTMLElement;

    if (!componentSelected.id) {
      handleSetItemId(componentSelected.parentElement?.id || '');
      setIndexOfRow(index);
      func();
      return;
    }
    handleSetItemId(componentSelected.id);
    setIndexOfRow(index);
    func();
  }

  function handleClickOnLine(
    e: React.MouseEvent<HTMLElement>,
    func: (id: string) => void,
    handleSetItemId: (id: string) => void
  ) {
    const componentSelected = e.target as HTMLElement;

    handleSetItemId(componentSelected.id);
    if (func) func(componentSelected.id);
  }

  function closeActionModalOnClickAway() {
    if (isOpenActionModal) handleDrawerOpen();
  }

  const handleClickSortButton = async (
    changeColor: () => void,
    id: string,
    sortFunc: (
      page: string,
      sortByField: string,
      sortByDirection: string,
      enterpriseId?: string
    ) => any,
    sortDirection: string,
    position: number
  ) => {
    handleSetSortItemId(id);
    changeColor();

    const { data } = await sortFunc(
      String(currentPage),
      sortFields[position],
      sortDirection,
      enterpriseIdOfManager
    );

    const formatedRowsId = data.data.map((item: { id: string }) => {
      return item.id;
    });

    setFormateRowsId(formatedRowsId);
    setFormatedTableData(data.data);
  };

  function getCurrentPage() {
    const urlSplited = String(window.location).split('/');
    const result = urlSplited.filter(
      (value) => value === 'area' || value === 'maintenance' || value === 'visitors'
    );
    return result[0];
  }

  useEffect(() => {
    setFormateRowsId(rowsId);
    setFormatedTableData(tableData);
  }, [tableData, rowsId]);

  useEffect(() => {
    handleSetCurrentPage(1);
  }, [window.location.pathname]);

  return {
    isOpenActionModal,
    indexOfRow,
    sortItemId,
    sortDown,
    sortUp,
    handleClickOnActionBtn,
    handleClickOnLine,
    closeActionModalOnClickAway,
    changeSortDownColor,
    changeSortUpColor,
    handleSetSortItemId,
    handleClickSortButton,
    formatedTableData,
    formateRowsId,
    getCurrentPage
  };
}
