import { Grid, Typography } from '@mui/material';
import { CustomPreviewInput } from '../../../../components';

function CustomMobilePreview() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight={600} marginTop={1} fontSize={22}>
          Customização
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="#8B8B8B" fontSize="13px" marginBottom={1.5}>
          CORES DO SISTEMA
        </Typography>
      </Grid>
      <CustomPreviewInput />
    </Grid>
  );
}

export default CustomMobilePreview;
