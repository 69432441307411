/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import {
  useSidebarModalStore,
  useRolesModalStore,
  useAdminORStore,
  useAuthStore
} from '../../store/index';
import { iconsName, linkName } from '../../constants';

import { getEnterpriseIdOfManagerRequest } from '../../services/requests/employees/Employees.request';

function useSidebarController() {
  const { open, drawerWidth, handleDrawerClose } = useSidebarModalStore();
  const { handleSetRoles, handleSetIsAdmin, handleSetIsManager, isAdmin } = useRolesModalStore();
  const { logout } = useAuthStore();
  const {
    handleSetName,
    handleSetOccupation,
    handleSetEmail,
    handleSetId,
    id,
    handleSetEnterpriseIdOfManager
  } = useAdminORStore();

  const [widthState, setWidthState] = useState(window.innerWidth);
  const [userRoles, setUserRoles]: any = useState([]);
  const [iconsNameFormated, setIconsNameFormated] = useState(['']);

  function getRoles() {
    const token = localStorage.getItem('token') || '';
    if (!token) return;
    const decoded: any = jwt_decode(token);

    const { roles } = decoded.realm_access;
    if (roles[0] === 'temporary') {
      logout();
      toast.error('Usuário não possui empreendimento vinculado!');
    }

    if (roles && roles.includes('app-resident')) {
      logout();
      toast.error('Logue pelo aplicativo!');
    }

    if (decoded.realm_access.roles) {
      if (decoded.realm_access.roles.includes('app-admin')) {
        setUserRoles([...roles, 'funcionarios', 'inicio']);
        handleSetIsAdmin(true);
      } else {
        setUserRoles([...roles, 'inicio']);
      }
    }

    if (decoded.realm_access.roles) {
      if (decoded.realm_access.roles.includes('app-manager')) {
        setUserRoles([...roles, 'moradores', 'funcionarios', 'roles', 'inicio']);
        handleSetIsManager(true);
      }
    }

    handleSetEmail(decoded.email);
    handleSetName(isAdmin ? 'Admin' : decoded.given_name);
    if (!isAdmin) handleSetId(decoded.userId);
    if (decoded.realm_access.roles.includes('app-admin')) handleSetOccupation('admin');

    if (decoded.realm_access.roles.includes('app-manager')) handleSetOccupation('manager');

    handleSetEnterpriseIdOfManager(decoded.enterpriseId);
  }
  function formatIconsName() {
    if (isAdmin) {
      const index = iconsName.indexOf('Funcionários');
      iconsName[index] = 'Administradores';
      setIconsNameFormated(iconsName);
    }
    setIconsNameFormated(iconsName);
  }

  async function getEnterpriseIdOfManager() {
    if (!id) return;
    if (isAdmin) return;
    const { data }: any = await getEnterpriseIdOfManagerRequest(id);
    const { enterpriseId } = data;
    handleSetEnterpriseIdOfManager(enterpriseId);
  }

  useEffect(() => {
    getRoles();
    formatIconsName();
  }, []);

  useEffect(() => {
    handleSetRoles(userRoles);
    formatIconsName();
  }, [userRoles]);

  useEffect(() => {
    getEnterpriseIdOfManager();
  }, [id]);

  window.addEventListener('resize', function () {
    setWidthState(window.innerWidth);
  });

  return {
    open,
    drawerWidth,
    widthState,
    iconsNameFormated,
    linkName,
    handleDrawerClose,
    roles: userRoles
  };
}

export default useSidebarController;
