import styled from 'styled-components';
import { CloseRounded } from '@mui/icons-material';
import { Button, Checkbox, IconButton, Select, TextField, Typography } from '@mui/material';

export const ModalContent = styled.div`
  background: #fff;

  display: flex;
  flex-direction: column;

  top: 50%;
  left: 50%;
  position: relative;
  transform: translate(-50%, -50%);

  padding: 24px 32px;
  border-radius: 6px;
  height: min-content;
  width: 550px;

  .selectInput {
    width: 100%;
  }

  .selectInputLabelContainer {
    position: relative;
    width: 100%;
  }

  .selectInputLabel {
    position: absolute;
    top: 16px;
    left: 15px;
  }

  .blockAndUnityContainer {
    width: 100%;
    display: flex;
    gap: 24px;
    justify-content: space-between;
  }

  @media (max-width: 630px) {
    width: 450px;
  }

  @media (max-width: 530px) {
    width: 350px;
  }

  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const ModalTitle = styled.h2`
  font-family: 'Public Sans';
  margin-bottom: 24px;
  margin-top: -32px;
  text-align: left;

  font-size: 24px;
  font-weight: 600;

  @media (max-width: 430px) {
    font-size: 26px;
  }
`;

export const ModalInputTitle = styled(Typography).attrs({
  fontFamily: 'Public Sans',
  marginBottom: '12px',
  marginTop: '8px',
  fontWeight: 'bold'
})``;

export const ModalInput = styled(TextField).attrs({
  mode: 'outlined'
})`
  width: 28rem;
`;

export const ModalInputArea = styled.section`
  margin-bottom: 12px;
  width: 100%;
  div {
    border-radius: 10px;
  }

  .errorMessage {
    color: red;
    font-size: 13px;
  }
`;

export const ModalSelectButton = styled(Select)`
  width: 28rem;
`;

export const ModalCloseButton = styled(IconButton)`
  align-self: flex-end;
`;

export const ModalCloseButtonIcon = styled(CloseRounded)``;

export const ModalCheckbox = styled(Checkbox)``;

export const ModalCheckboxLabel = styled(Typography).attrs({})``;

export const ModalCheckboxArea = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalButtonArea = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;

  margin-top: 32px;
`;

export const ModalNextButton = styled(Button)``;

export const ModalBackButton = styled(Button)``;
