/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Modal as ModalComponent, Box, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import AutocompleteInput from '../../../../components/Autocomplete/Autocomplete';
import { useModalController } from './Modal.controller';
import { ModalProps } from './Modal.props';
import {
  ModalBackButton,
  ModalButtonArea,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalContent,
  ModalInput,
  ModalInputArea,
  ModalInputTitle,
  ModalNextButton,
  ModalTitle
} from './Modal.style';

export function Modal({ isModalOpen, toggleModal, title, edit, residentId, refresh }: ModalProps) {
  const {
    control,
    errors,
    residentById,
    blocksList,
    unitList,
    register,
    submit,
    handleSubmit,
    setUnitListData,
    closeResidentModal,
    cleanUnitValue,
    setIsSelectedBlock,
    isSelectedBlock,
    handleSearchSelect,
    handleSearchBlockSelect,
    isInputValue,
    setIsInputValue,
    isInputBlockValue,
    setIsInputBlockValue,
    unitValue,
    blockValue,
    setUnitValue,
    setBlockValue
  } = useModalController(edit, toggleModal, residentId, isModalOpen, refresh);

  return (
    <ModalComponent open={isModalOpen} onClose={closeResidentModal}>
      <ModalContent>
        <ModalCloseButton onClick={closeResidentModal}>
          <ModalCloseButtonIcon />
        </ModalCloseButton>

        <ModalTitle>{title}</ModalTitle>

        <ModalInputArea>
          <ModalInputTitle>Nome</ModalInputTitle>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ModalInput
                id="resident-input-name"
                style={{ width: '100%' }}
                placeholder="Insira o nome completo"
                {...register('name')}
                error={!!errors.name}
                onChange={onChange}
                value={value ? value[0].toUpperCase() + value.substring(1) : value}
              />
            )}
          />
          {errors.name && <p className="errorMessage">{errors.name.message}</p>}
        </ModalInputArea>
        <ModalInputArea>
          <ModalInputTitle>CPF</ModalInputTitle>
          <Controller
            name="cpf"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/
                ]}
                placeholder={residentById.cpf || '___.___.___-__'}
                onChange={onChange}
                style={{ width: '100%' }}
                value={value}
                render={(ref, props) => (
                  <ModalInput
                    id="resident-input-cpf"
                    {...register('cpf')}
                    error={!!errors.cpf}
                    inputRef={ref}
                    {...props}
                  />
                )}
              />
            )}
          />
          {errors.cpf && <p className="errorMessage">{errors.cpf.message}</p>}
        </ModalInputArea>
        <Box className="blockAndUnityContainer">
          <ModalInputArea>
            <ModalInputTitle>Bloco</ModalInputTitle>
            <Box className="selectInputLabelContainer">
              <Controller
                name="block"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <InputLabel className="selectInputLabel">
                      {value || isInputBlockValue ? '' : 'Selecione'}
                    </InputLabel>
                    <AutocompleteInput
                      id="resident-select-block"
                      options={blocksList}
                      value={value}
                      getValue={blockValue}
                      setValue={setBlockValue}
                      isInputValue={isInputBlockValue}
                      setIsInputValue={setIsInputBlockValue}
                      handleSearch={handleSearchBlockSelect}
                      onChange={onChange}
                      isBlock
                      cleanUnitValue={cleanUnitValue}
                      setIsSelectedBlock={setIsSelectedBlock}
                      setUnitListData={setUnitListData}
                    />
                  </>
                )}
              />
            </Box>
            {errors.block && <p className="errorMessage">{errors.block.message}</p>}
          </ModalInputArea>
          <ModalInputArea>
            <ModalInputTitle>Unidade</ModalInputTitle>
            <Box className="selectInputLabelContainer">
              <Controller
                name="unitId"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <InputLabel className="selectInputLabel">
                      {value || isInputValue ? '' : 'Selecione'}
                    </InputLabel>
                    <AutocompleteInput
                      id="resident-select-unit"
                      options={unitList}
                      value={value}
                      getValue={unitValue}
                      setValue={setUnitValue}
                      isInputValue={isInputValue}
                      setIsInputValue={setIsInputValue}
                      handleSearch={handleSearchSelect}
                      isDisabled={!(edit || isSelectedBlock)}
                      onChange={onChange}
                      setIsSelectedBlock={setIsSelectedBlock}
                      setUnitListData={setUnitListData}
                    />
                  </>
                )}
              />
            </Box>
            {errors.unitId && <p className="errorMessage">{errors.unitId.message}</p>}
          </ModalInputArea>
        </Box>
        <ModalInputArea>
          <ModalInputTitle>Celular</ModalInputTitle>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MaskedInput
                mask={[
                  '(',
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
                placeholder={residentById.phone || '(__) _____-____'}
                style={{ width: '100%' }}
                onChange={onChange}
                value={value}
                render={(ref, props) => (
                  <ModalInput
                    id="resident-input-phone"
                    {...register('phone')}
                    error={!!errors.phone}
                    inputRef={ref}
                    {...props}
                  />
                )}
              />
            )}
          />
          {errors.phone && <p className="errorMessage">{errors.phone.message}</p>}
        </ModalInputArea>
        <ModalInputArea>
          <ModalInputTitle>E-mail</ModalInputTitle>
          <Controller
            name="email"
            control={control}
            render={() => (
              <ModalInput
                id="resident-input-email"
                style={{
                  width: '100%',
                  background: edit ? 'rgba(122,122,122,0.3)' : 'transparent'
                }}
                placeholder={residentById.email || 'Insira o e-mail'}
                {...register('email')}
                error={!!errors.email}
                disabled={edit}
              />
            )}
          />
          {errors.email && <p className="errorMessage">{errors.email.message}</p>}
        </ModalInputArea>

        <ModalButtonArea>
          <Box display="flex" flexDirection="column" width="100%">
            <ModalNextButton
              id="resident-edit-save-btn"
              style={{
                backgroundColor: '#662483',
                color: 'white',
                width: '100%',
                height: 55,
                fontSize: '16px',
                marginBottom: '10px',
                textTransform: 'capitalize'
              }}
              variant="contained"
              disableElevation
              onClick={handleSubmit(submit)}>
              {edit ? 'Editar' : 'Registrar'}
            </ModalNextButton>
            <ModalBackButton
              variant="contained"
              style={{
                backgroundColor: 'transparent',
                width: '100%',
                fontSize: '16px',
                height: 55,
                textTransform: 'capitalize'
              }}
              disableElevation
              onClick={closeResidentModal}>
              Voltar
            </ModalBackButton>
          </Box>
        </ModalButtonArea>
      </ModalContent>
    </ModalComponent>
  );
}
