import { useEffect, useState } from 'react';
import { useInfoModalStore, useLoadModalStore, useReserveStore } from '../../../../store';
import convertMoney from '../../../../helpers/convertMoneyToPTBR';

export const usReserveInformationModalController = () => {
  const {
    isOpenReserveInformationModal,
    reserveId,
    handleReserveInformationModalOpen,
    getReserveById,
    changeStatusReserves,
    handleSetStatus,
    handleJustificationModalOpen
  } = useReserveStore();

  const { handleModalOpen, handleSetIsSuccessfully, handleSetText } = useInfoModalStore();

  const [resident, setResident] = useState('');
  const [area, setArea] = useState('');
  const [dateOfUse, setDateOfUse] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [usageHours, setUsageHours] = useState('');
  const [value, setValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [block, setBlock] = useState('');
  const [apartmentNumber, setApartmentNumber] = useState('');

  const { toogleLoading } = useLoadModalStore();

  async function getReserveData() {
    const data = await getReserveById();
    if (!data) return;
    setBlock(data.block);
    setApartmentNumber(data.unitId);
    setResident(data.user_name);
    setArea(String(data.area));
    handleSetStatus(data.status);
    setStatusValue(data.status);
    setDateOfUse(data.reserve_date);
    setCreatedAt(data.created_at);
    setValue(
      String(data.value) === 'Gratuito' ? 'Gratuito' : `R$ ${convertMoney(Number(data.value))}`
    );
    setUsageHours(data.usageHours);
  }

  function resetValues() {
    setResident('');
    setArea('');
    handleSetStatus('');
    setDateOfUse('');
    setCreatedAt('');
    setValue('');
    setUsageHours('');
  }

  function handleCloseReserveModal() {
    handleReserveInformationModalOpen();
    resetValues();
  }

  async function authorizeOrRevoke(newStatus: string) {
    toogleLoading();
    try {
      await changeStatusReserves(reserveId, newStatus);
    } finally {
      toogleLoading();
    }
  }

  function revokeReserve() {
    handleJustificationModalOpen();
    handleReserveInformationModalOpen();
  }

  async function changeStatus(changedStatus: string) {
    toogleLoading();
    try {
      await authorizeOrRevoke(changedStatus);
      handleReserveInformationModalOpen();
      handleSetIsSuccessfully(true);
      handleSetText('Reserva autorizada com sucesso!');
      handleSetStatus('Autorizada');
    } catch {
      handleSetIsSuccessfully(false);
      handleSetText('Não foi possível autorizar a reserva!');
    } finally {
      handleModalOpen();
      handleSetStatus('');
      toogleLoading();
    }
  }

  useEffect(() => {
    if (isOpenReserveInformationModal) getReserveData();
  }, [isOpenReserveInformationModal]);

  return {
    isOpenReserveInformationModal,
    resident,
    area,
    block,
    apartmentNumber,
    dateOfUse,
    createdAt,
    usageHours,
    statusValue,
    value,
    handleReserveInformationModalOpen,
    handleCloseReserveModal,
    revokeReserve,
    changeStatus
  };
};
