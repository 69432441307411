import { CardMedia, Box, Typography, Card, IconButton } from '@mui/material';
import { Container, Background, Content } from './Welcome.style';

import { useWelcomeController } from './Welcome.controller';

import backgroundImage from '../../assets/images/welcomeBackground.png';
import hauzyLogo from '../../assets/images/hauzy-logo-tag.png';
import downloadIcon from '../../assets/images/fi_download.svg';
import pdfIcon from '../../assets/images/PDF.png';

export function Welcome() {
  const { ownerManualURL } = useWelcomeController();
  const hasManual = ownerManualURL && ownerManualURL.length > 0;

  return (
    <Container>
      <Background>
        <CardMedia
          component="img"
          image={backgroundImage}
          height="100%"
          alt="imagem de um prédio em tom de roxo"
        />
      </Background>

      <Content>
        <Box className="cardHeader">
          <CardMedia
            sx={{ width: '200px', marginInline: 'auto' }}
            component="img"
            image={hauzyLogo}
            alt="logo hauzy by or"
          />
        </Box>

        <Box className="cardBody">
          <Typography className="cardTitle">Bem vindo(a)!</Typography>
          <Typography className="cardText">
            {`Selecione as funcionalidades do sistema no menu lateral. ${
              hasManual
                ? ' Acesse o Manual do Proprietário do condomínio atual, faça o download no arquivo abaixo.'
                : ''
            }`}
          </Typography>
        </Box>

        {hasManual && (
          <Card className="cardFooter">
            <Box display="flex" gap={2} alignItems="center">
              <img src={pdfIcon} height="100%" alt="icone de um pdf" />
              <Box>
                <Typography fontWeight={600}>Manual do Proprietário</Typography>
                <Typography color="#8B8B8B" fontSize={12}>
                  Arquivo PDF
                </Typography>
              </Box>
            </Box>
            <IconButton LinkComponent="a" href={ownerManualURL} target="_blank">
              <img src={downloadIcon} alt="download icon" />
            </IconButton>
          </Card>
        )}
      </Content>
    </Container>
  );
}
