/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Typography } from '@mui/material';
import AreaCardProps from './AreaCard.props';
import Container from './AreaCard.style';

const visitorsImage = require('../../../../assets/images/visitorsappicon.svg');
const lostAndFoundImage = require('../../../../assets/images/lostandfoundappicon.svg');
const homeImage = require('../../../../assets/images/homeappicon.svg');

const AreaCard = (props: AreaCardProps) => {
  const { styleForColors } = props;
  return (
    <Container>
      <Box className="boxContainer" style={{ background: styleForColors(false) }}>
        <Box className="iconContainer">
          <visitorsImage.ReactComponent />
        </Box>
        <Typography className="cardText">Visitantes</Typography>
      </Box>
      <Box className="boxContainer" style={{ background: styleForColors(false) }}>
        <Box className="iconContainer">
          <lostAndFoundImage.ReactComponent />
        </Box>
        <Typography className="cardText">Áreas e reservas</Typography>
      </Box>
      <Box className="boxContainer" style={{ background: styleForColors(false) }}>
        <Box className="iconContainer">
          <homeImage.ReactComponent />
        </Box>
        <Typography className="cardText">Achados e perdidos</Typography>
      </Box>
    </Container>
  );
};

export default AreaCard;
