import { Grid, Box, Card, Typography, TableContainer } from '@mui/material';
import { Card as CardComponent } from '../Card/Card';
import { TableData } from '../TableData/TableData';
import { IDashboardProps } from './Dashboard.props';
import { Container } from './Dashboard.style';

export function Dashboard({ cardCountData, functionalitiesData }: IDashboardProps) {
  return (
    <Container>
      {cardCountData && (
        <Grid container className="cardsContainer" spacing={2}>
          <Grid item xs={12} sm={4}>
            <CardComponent title="Funcionários" amount={cardCountData.employees} color="purple" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardComponent title="Moradores" amount={cardCountData.residents} color="blue" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardComponent title="unidades" amount={cardCountData.units} color="green" />
          </Grid>
        </Grid>
      )}

      <Card className="featContainer" sx={{ boxShadow: '0px 2px 6px rgba(67, 89, 113, 0.2)' }}>
        <Box className="featHeader">
          <Typography className="tableTitle">Funcionalidades</Typography>
          <Typography className="tableText">
            Principais funcionalidades do empreendimento.
          </Typography>
          <TableContainer sx={{ overflowY: 'auto' }}>
            {functionalitiesData && <TableData functionalitiesData={functionalitiesData} />}
          </TableContainer>
        </Box>
      </Card>
    </Container>
  );
}
