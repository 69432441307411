import { instance } from '../../api';
import { IEnterpriseData, IDashboardRequestData, IDashboardDataOutput } from './Dashboard.dto';

const baseURLCore = process.env.REACT_APP_BASE_URL;
const genericError = 'Ocorreu um erro inesperado, tente novamente mais tarde';

export const getEnterpriseList = async (): Promise<IEnterpriseData[]> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      url: 'enterprise/?filterFields=id,name&sortDirection=ASC&sortBy=name&limit=0'
    });
    const { enterprises } = data;
    return enterprises.data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode || genericError);
  }
};

export const getDashboardGeneralData = async (): Promise<IDashboardDataOutput> => {
  try {
    const { data }: IDashboardRequestData = await instance.request({
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      url: 'dashboard/count-items',
      baseURL: baseURLCore
    });
    return data.data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode || genericError);
  }
};

export const getDashboardByEnterprise = async (id: string): Promise<IDashboardDataOutput> => {
  try {
    const { data }: IDashboardRequestData = await instance.request({
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      url: `dashboard/count-items/${id}`,
      baseURL: baseURLCore
    });
    return data.data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode || genericError);
  }
};
