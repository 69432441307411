import create from 'zustand';

import { RefreshChangedPagesStoreProps } from './RefreshChangedPages.props';

const initialState = {
  isPageChanged: false
};

export const useRefreshChangedPagesStore = create<RefreshChangedPagesStoreProps>((set, get) => ({
  ...initialState,

  setChangedPage: (isPageChanged) => set((state) => ({ ...state, isPageChanged }))
}));
