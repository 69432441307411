import styled from 'styled-components';
import { Grid } from '@mui/material';

const Container = styled(Grid)`
  width: 100%;
  height: fit-content;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f3f7;
  border-radius: 6px;

  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 114px;
    height: 50px;
    background: transparent;
    border-radius: 6px;
  }

  .weekButton,
  .selectedWeekButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 12px;
    border-radius: 6px;
    background: transparent;
  }

  .selectedWeekButton {
    background-color: #fff;
  }

  .buttonText,
  .isSelected {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: clamp(14px, 5px + 1vw, 20px);
    text-transform: none;
    color: #2c2c2c;
  }

  .isSelected {
    color: #662483;
  }

  @media screen and (max-width: 1250px) {
    padding: 12px 6px;
  }
`;

export default Container;
