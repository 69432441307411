import { Grid, Button } from '@mui/material';
import { CustomButton } from '../../../../components/index';

import Container from './EnterpriseButtons.style';
import useEnterpriseButtonsController from './EnterpriseButtons.controller';
import { EnterpriseButtonsProps } from './EnterpriseButtons.props';

function EnterpriseButtons(props: EnterpriseButtonsProps) {
  const { handleBackToLastPageModalOpen } = useEnterpriseButtonsController();
  const { onClickRegister } = props;
  return (
    <Container>
      <Grid container spacing={1} justifyContent="right" width="100%">
        <Grid item xs={5} sm={5} md={4} lg={2.5} marginRight={2}>
          <CustomButton id="enterprise_register" text="Cadastrar" onClick={onClickRegister} />
        </Grid>
        <Grid item xs={5} sm={5} md={4} lg={2.5}>
          <Button className="goBackEnterprises" onClick={handleBackToLastPageModalOpen}>
            Voltar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EnterpriseButtons;
