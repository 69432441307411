import jwt_decode from 'jwt-decode';

function hasTheFunctionality(roles: string[], role: string) {
  const token = localStorage.getItem('token') || '';
  if (!token) return false;

  const decoded: any = jwt_decode(token);
  const { roles: userRoles } = decoded.realm_access;

  let hasFunc = roles.includes(role);

  userRoles.forEach((userRole: string) => {
    if (
      userRole === 'app-admin' &&
      (role === 'dashboard' ||
        role === 'empreendimentos' ||
        role === 'manutencoes' ||
        role === 'relatorio')
    )
      hasFunc = true;
  });

  return hasFunc;
}

export default hasTheFunctionality;
