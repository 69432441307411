/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AreaFormRequiredFields, IRequestAreaData } from './EditArea.props';
import convertMoney from '../../helpers/convertMoneyToPTBR';
import { Errors } from '../../constants/Errors';
import { convertHourToSeconds } from '../../helpers/convertHourToSeconds';

import {
  useLoadModalStore,
  useAreaStore,
  usePreviewImageModalStore,
  useInfoModalStore,
  useBackToLastPageModalStore
} from '../../store';
import { instance } from '../../services/api';

const areaDefaultValues = {
  id: '',
  areaName: '',
  price: 0,
  paymentDeadline: '',
  cancellationDeadline: '',
  maxCapacity: '',
  simultaneousReservations: '',
  availableSchedule: { schedule: '' },
  reservationLimit: '',
  reservationLimitByUnit: ''
};

function formatAreaData(original: any, modified: any) {
  const {
    id,
    areaName,
    price,
    paymentDeadline,
    cancellationDeadline,
    maxCapacity,
    simultaneousReservations,
    availableSchedule,
    scheduleTotalByUnit,
    isAvailableArea,
    instantaneousApproval,
    reservationLimit,
    reservationLimitByUser,
    hasLimitByUnit,
    disponibility,
    hasDayLimit
  } = original;

  const formatedData = {
    id,
    areaName: modified.areaName ? modified.areaName : areaName,
    price: modified.price ? modified.price : price,
    paymentDeadline: modified.paymentDeadline ? modified.paymentDeadline : paymentDeadline,
    cancellationDeadline: modified.cancellationDeadline
      ? modified.cancellationDeadline
      : cancellationDeadline,
    maxCapacity: modified.maxCapacity ? modified.maxCapacity : maxCapacity,
    simultaneousReservations: modified.simultaneousReservations
      ? modified.simultaneousReservations
      : simultaneousReservations,
    availableSchedule: modified.availableSchedule ? modified.availableSchedule : availableSchedule,
    scheduleTotalByUnit: modified.scheduleTotalByUnit
      ? modified.scheduleTotalByUnit
      : scheduleTotalByUnit,
    isAvailableArea: modified.isAvailableArea ? modified.isAvailableArea : isAvailableArea,
    hasDayLimit: modified.hasDayLimit ? modified.hasDayLimit : hasDayLimit,
    disponibility: modified.disponibility ? modified.disponibility : disponibility,
    instantaneousApproval: modified.instantaneousApproval
      ? modified.instantaneousApproval
      : instantaneousApproval,
    reservationLimit: modified.reservationLimit ? modified.reservationLimit : reservationLimit,
    hasLimitByUnit: modified.hasLimitByUnit ? modified.hasLimitByUnit : hasLimitByUnit,
    reservationLimitByUser: modified.reservationLimitByUser
      ? modified.reservationLimitByUser
      : reservationLimitByUser
  };

  return formatedData;
}

export const useEditAreaController = () => {
  const navigate = useNavigate();
  const [isAvailableArea, setIsAvailableArea] = useState(true);
  const [isImmediateApproval, setIsImmediateApproval] = useState(false);
  const [isReservationLimitation, setIsReservationLimitation] = useState(false);
  const [hasReservationLimitationByUnit, setHasReservationLimitationByUnit] = useState(false);
  const [isSuccessfully, setIsSuccessfully] = useState(false);
  const [locationPictures, setLocationPictures] = useState(['']);
  const [areaOfId, setAreaOfId] = useState(areaDefaultValues);
  const [infoText, setInfoText] = useState('');
  const [popover1, setPopover1] = useState<HTMLElement | null>(null);
  const [popover2, setPopover2] = useState<HTMLElement | null>(null);
  const [popover3, setPopover3] = useState<HTMLElement | null>(null);
  const [popover4, setPopover4] = useState<HTMLElement | null>(null);

  const isOpenPop1 = Boolean(popover1);
  const isOpenPop2 = Boolean(popover2);
  const isOpenPop3 = Boolean(popover3);
  const isOpenPop4 = Boolean(popover4);

  const { isOpenInfoModal, handleModalOpen } = useInfoModalStore();

  const { toogleLoading } = useLoadModalStore();
  const {
    availableDay,
    reservationPeriodAllWeek,
    areaId,
    formatedImageToRequest,
    locationImageToRequest,
    imagesToDelete,
    filesToGetLink,
    handleSetFilesToGetLink,
    handleSetImagesToRequest,
    getAreaDetailsByIdRequest,
    removeImageRequest,
    editAreaRequest,
    getImageLinkRequest,
    handleSetLocationImagesToRequest,
    handleSetImages,
    handleSetImagesToDelete
  } = useAreaStore();
  const { isOpenImagePreview } = usePreviewImageModalStore();

  const { handleBackToLastPageModalOpen, isOpenBackToLastPageModal, sidebarLink } =
    useBackToLastPageModalStore();

  const DaysOfTheWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

  const cancellationType = [
    {
      value: '1M',
      label: '1 mês antes'
    },
    {
      value: '1W',
      label: 'Uma semana antes'
    },
    {
      value: '3D',
      label: 'Três dias antes'
    },
    {
      value: '1D',
      label: 'Um dia antes'
    },
    {
      value: 'any',
      label: 'A qualquer momento'
    }
  ];

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    }
  };

  const editAreaSchema = yup.object().shape({
    areaName: yup
      .string()
      .test('name', 'Por favor, preencher campo área.', (value) => {
        if (value?.trim() === '') {
          return false;
        }
        return true;
      })
      .max(70, 'O nome da área precisa ter até 70 caracteres.')
      .notRequired(),
    price: yup
      .string()
      .notRequired()
      .test('isPrice2', 'Preencha o valor.', (value) => {
        if (isImmediateApproval) {
          return true;
        }
        if (Number(value) === 0 && !isImmediateApproval) {
          return false;
        }
        return true;
      })
      .test('isPrice', 'O valor precisa ser menor ou igual a R$ 10.000,00.', (value) => {
        if (isImmediateApproval) {
          return true;
        }
        if (value) {
          const numbers = value.replace(/[^\d]/g, '');
          if (Number(numbers) > 1000000) {
            return false;
          }
          if (Number(numbers) <= 1000000) {
            return true;
          }
          return true;
        }
        return true;
      }),
    deadline: yup.string().notRequired(),
    cancellationDeadline: yup.string().notRequired(),
    maximumCapacity: yup.string().notRequired(),
    simultaneousScheduling: yup.string().notRequired(),
    scheduleTotal: yup.string().notRequired().nullable(),
    scheduleTotalByUnit: yup.string().notRequired().nullable()
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<AreaFormRequiredFields>({
    defaultValues: areaDefaultValues,
    mode: 'onChange',
    resolver: yupResolver(editAreaSchema)
  });

  async function deleteImages(imagesLinkToDelete?: string[]) {
    if (imagesLinkToDelete) {
      await imagesLinkToDelete.forEach(async (imageLink) => {
        toogleLoading();
        try {
          const formatedLink =
            imageLink.length === 1
              ? imageLink[0].includes('http')
                ? imageLink[0].split('area/')[1]
                : imageLink[0]
              : imageLink.includes('http')
                ? imageLink.split('area/')[1]
                : imageLink;
          await removeImageRequest(formatedLink);
        } finally {
          toogleLoading();
        }
      });

      return;
    }
    await imagesToDelete.forEach(async (imageLink) => {
      toogleLoading();
      try {
        const formatedLink =
          imageLink.length === 1
            ? imageLink[0].includes('http')
              ? imageLink[0].split('area/')[1]
              : imageLink[0]
            : imageLink.includes('http')
              ? imageLink.split('area/')[1]
              : imageLink;
        await removeImageRequest(formatedLink);
      } finally {
        toogleLoading();
      }
    });
  }

  window.onbeforeunload = () => {
    const {
      areaName,
      cancellationDeadline,
      deadline,
      maximumCapacity,
      price,
      scheduleTotal,
      scheduleTotalByUnit,
      simultaneousScheduling
    } = getValues();
    if (
      areaName ||
      cancellationDeadline ||
      deadline ||
      maximumCapacity ||
      price !== 0 ||
      scheduleTotal ||
      scheduleTotalByUnit ||
      simultaneousScheduling
    ) {
      return true;
    }
    return null;
  };

  function handleChangeAvailableArea() {
    setIsAvailableArea(!isAvailableArea);
  }

  function handleChangeImmediateApproval() {
    setIsImmediateApproval(!isImmediateApproval);
  }

  function handleChangeReservationLimitation() {
    setIsReservationLimitation(!isReservationLimitation);
  }

  function handleChangeReservationLimitationByUnit() {
    setHasReservationLimitationByUnit(!hasReservationLimitationByUnit);
  }

  const handleReservationCapacity = () => {
    const { maximumCapacity, simultaneousScheduling } = getValues();
    let partialResult = Number(maximumCapacity) / Number(simultaneousScheduling);
    if (simultaneousScheduling === '0') partialResult = Number(maximumCapacity) / 1;
    const roundedResult = Math.floor(partialResult);
    let finalResult = 'Ilimitado';

    if (roundedResult === 0 && maximumCapacity.length > 0) finalResult = '1 pessoa';
    if (String(roundedResult) === 'Infinity') finalResult = 'Ilimitado';
    if (roundedResult < 0) finalResult = '0 pessoas';
    if (roundedResult === 1) finalResult = `${roundedResult} pessoa`;
    if (roundedResult > 0 && roundedResult !== 1 && String(roundedResult) !== 'Infinity')
      finalResult = `${roundedResult} pessoas`;
    if (simultaneousScheduling === '0') finalResult = 'Ilimitado';

    setValue('capacityOfReservation', finalResult);
  };

  function handleAddMoreOne(field: 'deadline' | 'maximumCapacity' | 'simultaneousScheduling') {
    const fields = getValues();
    const { maximumCapacity, simultaneousScheduling } = fields;
    const simulLocationBiggerThanMaxCap =
      field === 'simultaneousScheduling' &&
      Number(simultaneousScheduling) >= Number(maximumCapacity);

    if (simulLocationBiggerThanMaxCap) {
      toast.error('Locações Simultâneas não pode ser maior que Capacidade Máxima.');
    } else {
      setValue(field, `${Number(fields[field]) + 1}`);
      handleReservationCapacity();
    }
  }

  function handleLessOne(field: 'deadline' | 'maximumCapacity' | 'simultaneousScheduling') {
    const fields = getValues();
    const { maximumCapacity, simultaneousScheduling } = fields;
    const simulLocationBiggerThanMaxCap =
      field === 'maximumCapacity' && Number(simultaneousScheduling) >= Number(maximumCapacity);

    if (Number(fields[field]) - 1 <= 0) {
      setValue(field, '0');
      return;
    }

    if (simulLocationBiggerThanMaxCap) {
      toast.error('Capacidade Máxima não pode ser menor que Locações Simultâneas.');
    } else {
      setValue(field, `${Number(fields[field]) - 1}`);
      handleReservationCapacity();
    }
  }

  function showErrors() {
    let haveError = false;
    const data = getValues();

    if (
      !isImmediateApproval &&
      Number(String(data.price).replace(',', '').replaceAll('_', '')) === 0
    ) {
      setInfoText(Errors({}).AR19);
      haveError = true;
      setIsSuccessfully(false);
      handleModalOpen();
    }

    if (Number(data.maximumCapacity) <= 0) {
      setInfoText(Errors({}).AR3);
      haveError = true;
      setIsSuccessfully(false);
      handleModalOpen();
    }

    if (Number(data.maximumCapacity) < Number(data.simultaneousScheduling)) {
      setInfoText(Errors({}).AR5);
      haveError = true;
      setIsSuccessfully(false);
      handleModalOpen();
    }

    return haveError;
  }

  function setAreaData(data: IRequestAreaData) {
    setValue('areaName', data.areaName);
    setValue('deadline', data.paymentDeadline);
    setValue('maximumCapacity', data.maxCapacity);
    setValue('price', Number(data.price));
    setValue('scheduleTotal', data.reservationLimit);
    setValue('scheduleTotalByUnit', data.reservationLimitByUnit);
    setValue('simultaneousScheduling', data.simultaneousReservations);
    setValue('scheduleTotalByUnit', String(data.reservationLimitByUser));
    handleReservationCapacity();
  }

  function formatImagesToGetLink(pictures: string[]) {
    const newLocationPictures = [...pictures].map((imageUrl: string) => {
      return `/getImage/${imageUrl.split('/').splice(3, 5).join('/')}`;
    });

    return newLocationPictures;
  }

  function formatImageDataRequest(urlData: any[]) {
    const formatedLinks = urlData.map((requestData) => requestData.data.url);
    setLocationPictures(formatedLinks);
  }

  const requestAreaData = async () => {
    toogleLoading();
    try {
      const { data } = await getAreaDetailsByIdRequest(areaId);
      const urlData = await Promise.all(
        formatImagesToGetLink(data.locationPictures).map((url: string) =>
          instance.request({ method: 'GET', baseURL: process.env.REACT_APP_STORAGE_URL, url })
        )
      );

      setHasReservationLimitationByUnit(data.hasReservationLimitPerUser);

      formatImageDataRequest(urlData);
      handleSetLocationImagesToRequest(data.locationPictures);

      setIsAvailableArea(data.disponibility);
      setIsImmediateApproval(data.instantaneousApproval);
      setIsReservationLimitation(data.hasDayLimit);
      setAreaOfId({
        ...data,
        paymentDeadline: data.paymentDeadline / 3600,
        price: convertMoney(data.price)
      });
      setAreaData(data);
    } catch {
      navigate('/area');
      toast.error('Não foi possível obter os dados da área.');
    } finally {
      toogleLoading();
    }
  };

  function capitalize(name: string) {
    return `${name[0].toUpperCase()}${name.slice(1, name.length).toLowerCase()}`;
  }

  function formatFormData(formatedData: any, imagesFileLink: any[]) {
    const formatedImages = imagesFileLink.map((image) => image.url);
    const { deadline } = getValues();
    const dataToRequest = new FormData();

    dataToRequest.append('area_name', capitalize(formatedData.areaName));
    dataToRequest.append(
      'location_value_cents',
      isImmediateApproval ? '0' : String(formatedData.price)
    );
    dataToRequest.append(
      'payment_deadline',
      isImmediateApproval ? '0' : convertHourToSeconds(deadline)
    );
    dataToRequest.append('cancellation_deadline', formatedData.cancellationDeadline);
    dataToRequest.append('max_capacity', formatedData.maxCapacity);
    dataToRequest.append('simultaneous_reservations', formatedData.simultaneousReservations || '0');
    dataToRequest.append('is_available', String(isAvailableArea));
    dataToRequest.append('instantaneous_approval', String(isImmediateApproval));
    dataToRequest.append('available_schedule', JSON.stringify(reservationPeriodAllWeek));
    dataToRequest.append('has_reservation_limit_by_day', String(isReservationLimitation));
    if (isReservationLimitation)
      dataToRequest.append('reservation_limit', formatedData.reservationLimit);

    dataToRequest.append('has_reservation_limit_per_user', String(hasReservationLimitationByUnit));
    if (hasReservationLimitationByUnit)
      dataToRequest.append(
        'reservation_limit_per_user',
        formatedData.reservationLimitByUser || '1'
      );

    dataToRequest.append(
      'location_pictures',
      JSON.stringify(
        [...locationImageToRequest, ...formatedImages].filter(
          (image) =>
            !imagesToDelete
              .map((fileLink) => (typeof fileLink === 'string' ? fileLink : fileLink[0]))
              .includes(image)
        )
      )
    );

    return dataToRequest;
  }

  const formatImage = async () => {
    const imagesFileToRequest = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const file of filesToGetLink) {
      try {
        const formData = new FormData();
        formData.append('picture', file);
        // eslint-disable-next-line no-await-in-loop
        const { data } = await getImageLinkRequest(formData, uuidv4());
        handleSetImagesToRequest([...formatedImageToRequest, data]);
        imagesFileToRequest.push(data);
      } catch (err: any) {
        toast.error(err.message);
      }
    }
    return imagesFileToRequest;
  };

  const formatPrice = (price: number | string) => {
    return typeof price === 'number' ? Math.floor(price * 100) : price.match(/\d+/g)?.join('');
  };

  const createNewArea = async () => {
    toogleLoading();

    try {
      if (showErrors()) return;

      await deleteImages();

      const imagesFileLink = await formatImage();
      const {
        areaName,
        cancellationDeadline,
        deadline,
        id,
        maximumCapacity,
        price,
        scheduleTotal,
        scheduleTotalByUnit,
        simultaneousScheduling
      }: any = getValues();

      const dataToFormat = {
        id,
        areaName: areaName.trim(),
        price: formatPrice(price),
        paymentDeadline: deadline,
        cancellationDeadline,
        maxCapacity: maximumCapacity,
        simultaneousReservations: simultaneousScheduling,
        isAvailableArea,
        instantaneousApproval: isImmediateApproval,
        reservationLimit: scheduleTotal,
        reservationLimitByUser: Number(scheduleTotalByUnit),
        disponibility: isAvailableArea,
        hasDayLimit: isReservationLimitation,
        hasLimitByUnit: hasReservationLimitationByUnit
      };

      const formatedData = formatAreaData(areaOfId, dataToFormat);
      const dataToRequest = formatFormData(formatedData, imagesFileLink);

      // Atualiza o estado antes de fazer a requisição
      handleSetImages([]);
      handleSetImagesToRequest([]);
      handleSetImagesToDelete([]);
      setInfoText('Área editada com sucesso!');
      setIsSuccessfully(true);
      handleSetFilesToGetLink([]);
      handleModalOpen();

      try {
        await editAreaRequest(dataToRequest, areaId);
      } catch (err: any) {
        setInfoText(
          (Errors as any)({})[err.message] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        setIsSuccessfully(false);
        handleModalOpen();
        return;
      }
    } finally {
      toogleLoading();
    }
  };

  function NavigateToListArea() {
    handleModalOpen();
    navigate('../area', { replace: true });
  }

  const backToAreaPage = () => {
    const {
      areaName,
      cancellationDeadline,
      deadline,
      maximumCapacity,
      price,
      scheduleTotal,
      scheduleTotalByUnit,
      simultaneousScheduling
    } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    areaName ||
    cancellationDeadline ||
    deadline ||
    maximumCapacity ||
    price !== 0 ||
    scheduleTotal ||
    simultaneousScheduling ||
    scheduleTotalByUnit ||
    formatedImageToRequest
      ? handleBackToLastPageModalOpen()
      : navigate('/area', { replace: true });

    handleSetImages([]);
  };

  useEffect(() => {
    requestAreaData();
  }, [areaId]);

  return {
    DaysOfTheWeek,
    errors,
    control,
    isAvailableArea,
    isReservationLimitation,
    hasReservationLimitationByUnit,
    cancellationType,
    availableDay,
    isImmediateApproval,
    isOpenImagePreview,
    areaOfId,
    infoText,
    isOpenInfoModal,
    locationPictures,
    sidebarLink,
    isSuccessfully,
    isOpenBackToLastPageModal,
    currencyConfig,
    popover1,
    popover2,
    popover3,
    popover4,
    isOpenPop1,
    isOpenPop2,
    isOpenPop3,
    isOpenPop4,
    setPopover1,
    setPopover2,
    setPopover3,
    setPopover4,
    register,
    handleChangeAvailableArea,
    handleChangeImmediateApproval,
    handleChangeReservationLimitation,
    handleChangeReservationLimitationByUnit,
    handleReservationCapacity,
    createNewArea,
    handleModalOpen,
    handleSubmit,
    NavigateToListArea,
    handleAddMoreOne,
    handleLessOne,
    backToAreaPage
  };
};
