/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  MenuItem,
  Modal as ModalComponent,
  TextField,
  Typography,
  FormControl
} from '@mui/material';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { useModalController } from './Modal.controller';
import { ModalProps } from './Modal.props';
import { ModalCloseButtonIcon, ModalContent, ModalInput } from './Modal.style';

export function Modal({
  isModalOpen,
  toggleModal,
  title,
  variant,
  onComplete,
  edit,
  setSuccess,
  setTitle,
  toggleSuccessModalOpen,
  updateListRequest
}: ModalProps) {
  const {
    control,
    jobs,
    register,
    reset,
    errors,
    submit,
    handleSubmit,
    employeeById,
    closeDocumentModal,
    isAdmin
  } = useModalController(
    variant,
    edit,
    onComplete,
    toggleSuccessModalOpen,
    setTitle,
    setSuccess,
    toggleModal,
    updateListRequest
  );

  useEffect(() => {
    if (variant !== 'edit') {
      reset();
    }
  }, [isModalOpen]);

  return (
    <ModalComponent open={isModalOpen} onClose={closeDocumentModal}>
      <ModalContent
        onKeyDown={(event) => {
          if (event.key.includes('Enter')) event.preventDefault();
        }}>
        <Button className="closeIconContainer" onClick={closeDocumentModal}>
          <ModalCloseButtonIcon />
        </Button>

        <Box className="modalTitle">{title}</Box>

        <FormControl className="inputArea">
          <Typography className="inputTitle">Nome completo</Typography>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ModalInput
                id="admin-register-input-name"
                placeholder="Insira o nome completo"
                {...register('name')}
                error={!!errors.name}
                onChange={onChange}
                value={value ? value[0].toUpperCase() + value.substring(1) : value}
              />
            )}
          />
          {errors.name && <Typography className="errorMessage">{errors.name.message}</Typography>}
        </FormControl>
        <FormControl className="inputArea">
          <Typography className="inputTitle">CPF</Typography>
          <Controller
            name="cpf"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MaskedInput
                id="admin-register-input-cpf"
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/
                ]}
                placeholder="___.___.___-__"
                onChange={onChange}
                value={value}
                render={(ref, props) => (
                  <ModalInput {...register('cpf')} error={!!errors.cpf} inputRef={ref} {...props} />
                )}
              />
            )}
          />
          {errors.cpf && <Typography className="errorMessage">{errors.cpf.message}</Typography>}
        </FormControl>
        <FormControl className="inputArea">
          <Typography className="inputTitle">Celular</Typography>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MaskedInput
                id="admin-register-input-phone"
                mask={[
                  '(',
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
                placeholder="(__) _____-____"
                onChange={onChange}
                type="phone"
                value={value}
                render={(ref, props) => (
                  <ModalInput
                    {...register('phone')}
                    error={!!errors.phone}
                    inputRef={ref}
                    {...props}
                  />
                )}
              />
            )}
          />
          {errors.phone && <Typography className="errorMessage">{errors.phone.message}</Typography>}
        </FormControl>
        <FormControl className="inputArea">
          <Typography className="inputTitle">E-mail</Typography>
          <Controller
            name="email"
            control={control}
            render={() => (
              <ModalInput
                id="admin-register-input-email"
                placeholder="Insira o e-mail"
                {...register('email')}
                error={!!errors.email}
              />
            )}
          />
          {errors.email && <Typography className="errorMessage">{errors.email.message}</Typography>}
        </FormControl>

        {jobs && !isAdmin && (
          <FormControl className="inputArea">
            <Typography className="inputTitle">Função</Typography>
            {variant === 'edit' ? (
              <Controller
                name="roleID"
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    id="employees_function"
                    {...register('roleID')}
                    value={value || employeeById.roleID}
                    select
                    fullWidth
                    error={!!errors.roleID}>
                    {jobs.length > 0 &&
                      jobs.map((job: { id: string; name: string }) => (
                        <MenuItem key={job.id} value={job.id} id={job.name}>
                          {job.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            ) : (
              <TextField
                id="employees_function"
                {...register('roleID')}
                defaultValue="Selecione a Função"
                select
                fullWidth
                error={!!errors.roleID}>
                {jobs.length > 0 &&
                  jobs.map((job: { id: string; name: string }) => (
                    <MenuItem id={job.name} key={job.id} value={job.id || 'Selecione a Função'}>
                      {job.name || 'Selecione a Função'}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            {errors.roleID && (
              <Typography className="errorMessage">{errors.roleID.message}</Typography>
            )}
          </FormControl>
        )}

        <Box className="buttonsContainer">
          <Button
            id="admin-register-save"
            style={{
              backgroundColor: '#662483',
              color: 'white',
              width: '48.5%',
              height: 44,
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: 500,
              textTransform: 'capitalize'
            }}
            variant="contained"
            disableElevation
            onClick={handleSubmit(submit)}>
            Salvar
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: '#F0F1F5',
              width: '48.5%',
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: 500,
              height: 44,
              textTransform: 'capitalize'
            }}
            disableElevation
            onClick={closeDocumentModal}>
            Voltar
          </Button>
        </Box>
      </ModalContent>
    </ModalComponent>
  );
}
