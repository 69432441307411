import styled from 'styled-components';

const Container = styled.header`
  position: relative;
  .header-options-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .appbar {
    box-shadow: none;
    border-bottom: 1px solid #ccc;
  }

  .toolbar {
    background-color: #fff;
  }

  .header-icons {
    display: flex;
    gap: 10px;
    button {
      color: #000;
    }
  }

  .icon-container {
    margin-bottom: 5px;
    background: #2c2c2c;
    border-radius: 3.10811px;

    .userInitialLetter {
      font-family: 'Inter';
      font-style: normal;
      font-size: 17px;
      line-height: 20px;
      font-weight: 700;
      color: #ffffff;
    }
  }

  .icon-container:hover {
    background: #2c2c2c;
    opacity: 0.9;
  }
`;

export default Container;
