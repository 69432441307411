import { instance } from '../../api';
import { IDocumentsByIdAllDataDtoOutput, SearchDocuments } from './Documents.dto';
import { formatFileSize } from '../../../helpers/formatFileSize';
import { formatData } from '../../../helpers/formatData';

export const createFolder = async (name: string): Promise<void> => {
  try {
    return await instance.post('documents', { name });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteFolder = async (id: string): Promise<void> => {
  try {
    await instance.delete(`documents/${id}`);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const renameFolder = async (id: string | undefined, name: string): Promise<void> => {
  try {
    return await instance.put(`documents/rename/${id}`, { name });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchDocuments = async (
  documentsData: SearchDocuments,
  sortByField: string,
  sortByDirection: string,
  page: string
): Promise<IDocumentsByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `documents/?search=${documentsData.queryText}&filterFields=name,type,id,size,created_at&includeMeta=true&page=${page}&sortBy=${sortByField}&sortDirection=${sortByDirection}`
    );

    const formatedData = data.data.map(
      (item: { id: string; name: string; created_at: string; size: string; type: string }) => {
        return {
          id: item.id,
          name: item.name,
          created_at: formatData(item.created_at),
          size: `${formatFileSize(Number(item.size))}`,
          type: item.type === 'folder' ? 'Pasta' : 'Arquivo'
        };
      }
    );
    const totalFolders = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: formatedData, totalFolders, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortDocumentData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IDocumentsByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `documents?filterFields=name,type,id,size,created_at&includeMeta=true&page=${page}&sortDirection=${
        sortByDirection || 'DESC'
      }&sortBy=${sortByField || 'created_at'}&search=${searchString}`
    );

    const formatedData = data.data.map(
      (item: { id: string; name: string; created_at: string; size: string; type: string }) => {
        return {
          id: item.id,
          name: item.name,
          created_at: formatData(item.created_at),
          size: `${formatFileSize(Number(item.size))}`,
          type: item.type === 'folder' ? 'Pasta' : 'Arquivo'
        };
      }
    );

    const totalFolders = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: formatedData, totalFolders, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getDocumentDataById = async (id: string): Promise<any> => {
  try {
    const { data } = await instance.get(`documents/${id}`);

    return { data };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
