import styled from 'styled-components';
import { CloseRounded } from '@mui/icons-material';
import { Button, Checkbox, IconButton, Select, TextField, Typography } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .errorMessage {
    width: 100%;
    margin-top: 5px;
    font-size: 15px;
    color: red;
  }

  .date {
    margin-top: 12px;
    margin-left: -18px;
  }
`;

export const ModalContent = styled.div`
  background: #fff;

  display: flex;
  flex-direction: column;
  flex: 1;
  top: 50%;
  left: 50%;

  padding: 24px;
  border-radius: 16px;
  overflow: auto;
  max-width: 526px;
  max-height: 830px;
  height: 100%;
  width: 100%;

  @media (max-width: 600px) {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }
`;

export const ModalTitle = styled.p`
  font-family: 'Inter';

  font-size: 24px;
  font-weight: 600;
`;

export const ContainerTitleIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const ModalInputTitle = styled(Typography).attrs({
  fontFamily: 'Inter',
  marginBottom: '8px'
})``;

export const ModalInput = styled(TextField).attrs({
  mode: 'outlined'
})`
  width: 28rem;

  & + p {
    font-family: 'Inter';
    color: #ff0000;
  }
`;

export const ModalInputDate = styled(TextField).attrs({})`
  margin-bottom: 24px;
`;

export const ModalDescription = styled.textarea`
  border-radius: 8px;

  width: 100%;
  height: 163px;
  resize: none;

  outline: none;

  padding: 14px 12px;

  font-size: 15px;
  font-family: 'Inter';
  font-weight: 400;

  border: 1px solid rgba(0, 0, 0, 0.23);
`;

export const ModalInputArea = styled.form`
  margin-bottom: 24px;

  .inputMask {
    width: 28rem;
  }
`;

export const ModalSelectButton = styled(Select)`
  width: 28rem;
`;

export const ModalCloseButton = styled(IconButton)`
  align-self: flex-end;
`;

export const ModalCloseButtonIcon = styled(CloseRounded)``;

export const ModalCheckbox = styled(Checkbox)``;

export const ModalCheckboxLabel = styled(Typography).attrs({})``;

export const ModalCheckboxArea = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalButtonArea = styled.div`
  margin-top: 32px;
`;

export const ModalNextButton = styled(Button)``;

export const ModalBackButton = styled(Button)``;

export const RadioArea = styled.div`
  display: flex;

  align-items: center;
`;

interface RadioProps {
  value: string;
}

export const RadioInput = styled.input<RadioProps>`
  border: 0;
  border-bottom: ${({ value }) => (value ? '0' : '1px solid rgba(0, 0, 0, 0.23)')};

  font-size: 14px;
  font-family: 'Inter';
  font-weight: 400;
  width: 80%;
  outline: none;
`;

export const DateArea = styled.div`
  background: rgba(33, 33, 33, 0.08);

  border-bottom: 1px solid #662483;

  height: 77px;

  align-items: center;
  justify-content: center;

  padding: 15px 20px;

  border-radius: 6px 6px 0 0;

  margin-top: 10px;
`;

export const DateLabel = styled.p`
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;

  color: #662483;
`;

export const DeleteRadioButton = styled.button`
  background: transparent;
  border: 0;
  height: 30px;
  width: 30px;
`;

export const RadioContainer = styled.div``;

export const RadioAreaScroll = styled.div`
  overflow: auto;
  max-height: 200px;
  min-height: 100px;
  width: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }

  ::-webkit-scrollbar-thumb {
    background: #662483;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const RadioFirstContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
