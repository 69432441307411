/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Grid, Typography, Button } from '@mui/material';
import { usePartnersController } from './Partners.controller';
import {
  ActionModal,
  DeleteModal,
  InfoModal,
  CustomSearchInput,
  CustomTable
} from '../../components';
import Container from './Partners.style';
import { Modal } from './Components/Modal/Modal';
import { InformationModal } from './Components/InfoModal/InfoModal';
import PreviewImage from './Components/PreviewImage/PreviewImage';

const editIcon = require('../../assets/images/enterpriseactionedit.svg');
const detailsIcon = require('../../assets/images/details.svg');
const trashIcon = require('../../assets/images/enterpriseactiontrash.svg');

export function Partners() {
  const {
    actionModalData,
    isOpenDeleteModal,
    isOpenInfoModal,
    partnersTableHead,
    isSuccessfully,
    partnersId,
    isEdit,
    infoText,
    isOpenImagePreview,
    partnerId,
    totalPages,
    partnersList,
    totalPartners,
    isOpenModal,
    handleModalOpen,
    changePage,
    handleDeleteModalOpen,
    handleSetPartnerId,
    handleSearch,
    setInfoText,
    setIsSuccessfully,
    sortPartnersData,
    handleRegisterPartnersModal,
    onSubmitDelete,
    handleDrawerOpen
  } = usePartnersController();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9.5} lg={9.8}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Parceiros
          </Typography>
          <CustomSearchInput id="partners_search" handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12} sm={12} md={2.5} lg={2.2} alignSelf="flex-end">
          <Button
            id="partners_register"
            onClick={handleRegisterPartnersModal}
            className="registerButton"
            fullWidth>
            Cadastrar parceiro
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            rowsId={partnersId}
            tableHead={partnersTableHead}
            tableData={partnersList}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalPartners}
            funcToChangePage={changePage}
            handleSetItemId={handleSetPartnerId}
            noFilesFoundText="Não há nenhum registro de parceiros no momento."
            sortFunc={sortPartnersData}
            containId
            ActionModal={
              <ActionModal
                ActionModalData={actionModalData}
                ButtonsIcon={[
                  <detailsIcon.ReactComponent className="actionIcon" />,
                  <editIcon.ReactComponent className="actionIcon" />,
                  <trashIcon.ReactComponent className="actionIcon" />
                ]}
              />
            }
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Tem certeza que deseja excluir este parceiro?"
          onClickDelete={() => onSubmitDelete(partnerId)}
          handleModalClose={handleDeleteModalOpen}
        />
      )}
      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={infoText} />
      )}

      {isOpenModal && (
        <Modal
          handleSetText={setInfoText}
          handleSuccessfully={setIsSuccessfully}
          title={isEdit ? 'Editar parceiros' : 'Cadastrar parceiros'}
          isModalOpen
          toggleModal={handleRegisterPartnersModal}
          partnersId={isEdit ? partnerId : ''}
          edit={isEdit ? true : undefined}
        />
      )}

      {isOpenImagePreview && <PreviewImage />}

      <InformationModal />
    </Container>
  );
}
