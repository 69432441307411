import create from 'zustand';
import { ImagePreviewModalProps } from './PreviewImageModal.props';

const initialState = {
  isOpenImagePreview: false
};

const usePreviewImageModalStore = create<ImagePreviewModalProps>((set) => ({
  ...initialState,

  handleImagePreviewOpen: () => set((state) => ({ isOpenImagePreview: !state.isOpenImagePreview }))
}));

export { usePreviewImageModalStore };
