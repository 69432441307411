import create from 'zustand';
import {
  getEnterpriseList,
  getDashboardGeneralData,
  getDashboardByEnterprise
} from '../../services/requests/dashboard/Dashboard.request';
import { DashboardProps } from './Dashboard.props';

const initialState = {
  // enterprise states
  enterpriseID: undefined,
  enterpriseName: undefined,
  selectedEnterprise: null,
  enterpriseList: undefined,

  // dashboard states
  dashboardData: null
};

export const useDashboardStore = create<DashboardProps>((set) => ({
  ...initialState,

  // enterprise setstates
  setEnterpriseID: (enterpriseID) => set((state) => ({ ...state, enterpriseID })),
  setEnterpriseName: (enterpriseName) => set((state) => ({ ...state, enterpriseName })),
  setSelectedEnterprise: (selectedEnterprise) => set((state) => ({ ...state, selectedEnterprise })),
  setEnterpriseList: (enterpriseList) => set((state) => ({ ...state, enterpriseList })),

  // requests
  getEnterpriseListRequest: async () => {
    try {
      const data = await getEnterpriseList();
      data.unshift({ name: 'Geral', id: '' });
      set({ enterpriseList: data });
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getGeneralDashboardDataRequest: async () => {
    try {
      const data = await getDashboardGeneralData();
      set({ dashboardData: data });
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getDashboardDataByEnterpriseRequest: async (id: string) => {
    try {
      const data = await getDashboardByEnterprise(id);
      set({ dashboardData: data });
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));
