/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import hasTheFunctionality from '../../helpers/hasTheFunctionality';
import { convertLinkToPt, convertLinkToEn } from '../../helpers/translateLinks';

import { useAuthStore, useSidebarModalStore, useRolesModalStore } from '../../store';

function useHomeRoutesController() {
  const navigate = useNavigate();
  const storagedToken = localStorage.getItem('token');

  const [widthState, setWidthState] = useState(window.innerWidth);
  const { roles } = useRolesModalStore();
  const { accessToken } = useAuthStore();
  const { drawerWidth } = useSidebarModalStore();
  const [pathname, setPathName] = useState(window.location.pathname);

  const permission = [
    'paneladminor',
    'encomendas',
    'funcionarios',
    'moradores',
    'ocorrencias',
    'manutencoes',
    'visitantes',
    'informativos',
    'parceiros',
    'votacoes',
    'documentos',
    'achados/perdidos',
    'areas/reservas'
  ];

  window.addEventListener('resize', function () {
    setWidthState(window.innerWidth);
  });

  window.onbeforeunload = function () {
    if (pathname === '/enterprises/registerenterprise') return true;
    return null;
  };

  function moveToHomeIfAuthenticated() {
    if ((!!accessToken || !!storagedToken) && window.location.pathname === '/login')
      navigate('/home');
  }

  function moveToHomeIfDontHaveAccess() {
    if (roles.length === 0) return;
    if (roles.includes('app-admin')) {
      if (
        !window.location.pathname.match(/enterprises/gm) &&
        !window.location.pathname.match(/paneladminor/gm) &&
        !window.location.pathname.match(/maintenance/gm) &&
        !window.location.pathname.match(/reports/gm) &&
        !window.location.pathname.match(/employees/gm)
      )
        navigate(`/${convertLinkToEn(roles[0], true)}`);
      return;
    }
    const pathName = convertLinkToPt(
      window.location.pathname.substring(1, window.location.pathname.length)
    );
    if (!hasTheFunctionality(roles, pathName)) {
      for (let link of permission) {
        for (let role of roles) {
          if (role === link) {
            navigate(`/${convertLinkToEn(role)}`);
            return;
          }
        }
      }
    }
  }

  function logOutIfRecoverPassword() {
    if (window.location.pathname === '/newPassword') {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.reload();
    }

    if (window.location.pathname === '/changeWebPassword') {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.reload();
    }
  }

  useEffect(() => {
    setPathName(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    moveToHomeIfAuthenticated();
    logOutIfRecoverPassword();
  }, []);

  useEffect(() => {
    moveToHomeIfDontHaveAccess();
  }, [roles]);

  return {
    widthState,
    drawerWidth
  };
}

export default useHomeRoutesController;
