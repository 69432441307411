import styled, { css } from 'styled-components';
import { CloseRounded } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

interface StyleProps {
  statusColor: string;
}

export const ModalContent = styled.div<StyleProps>`
  background: #fff;
  border: none;
  outline: none;
  width: 499px;
  padding: 24px 56px;
  max-height: 830px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #662483;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  justify-content: center;
  align-items: center;
  z-index: 999999999999;

  .statusBox {
    border-radius: 4px;
    padding: 4px 10px;

    ${({ statusColor }) => css`
      background-color: ${statusColor === 'PENDENTE' && '#FFF6A7'};
      color: ${statusColor === 'PENDENTE' && '#C96000'};
      background-color: ${statusColor === 'EM ANDAMENTO' && '#D3FFFC'};
      color: ${statusColor === 'EM ANDAMENTO' && '#006D74'};
      background-color: ${statusColor === 'CONCLUÍDO' && '#d4ffd3'};
      color: ${statusColor === 'CONCLUÍDO' && '#00740C'};
      background-color: ${statusColor === 'ATRASADO' && '#FFC5C5'};
      color: ${statusColor === 'ATRASADO' && '#CF0606'};
    `}
  }

  .descriptionContainer {
    width: 100%;
    word-wrap: break-word;
    max-height: 300px;
    overflow-y: auto;
  }

  .statusText {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 15.2616px;
    line-height: 18px;
    text-transform: none;
  }

  .fileContainer {
    margin-top: 15px;
    padding: 20px 40px;
    border: 1px dashed rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      height: 80px;
      width: 140px;
      cursor: pointer;
    }
  }

  .pdfContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9e9e9;
    cursor: pointer;
    width: 140px;
    height: 65px;
    cursor: pointer;

    svg {
      width: 55px;
      height: 35px;
    }
  }

  .imageDescription {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 11px;
    font-size: 12px;
    color: #777777;
    font-weight: 600;
    background: #f5f5f5;
    width: 140px;
    padding: 1px 3px;
    border: 0.312526px solid #c4c4c4;
    border-top: none;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .itemImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 83%;
    border-radius: 13px;
    margin: 10px 0px 20px;
    img {
      border-radius: 13px;
      height: 140px;
      width: 330px;
    }
  }

  @media (max-height: 899px) {
    max-height: 730px;
  }

  @media (max-height: 740px) {
    max-height: 600px;
  }

  @media (max-height: 640px) {
    max-height: 500px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;
  }
`;

export const ModalCloseButton = styled(IconButton)``;

export const ModalCloseButtonIcon = styled(CloseRounded)``;

export const ModalTitle = styled.p`
  font-family: 'Inter';
  text-align: center;
  margin-top: 30px;
  margin-bottom: 2rem;

  font-size: 24px;
  font-weight: 600;
`;

export const ModalInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 24px;

  img {
    width: 46px;
    height: 46px;
  }
`;

export const ModalInfoAreaRow = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 12px;
`;

export const ModalInfoTitle = styled.p`
  color: #2c2c2c;

  font-size: 18px;
  font-weight: 500;
  font-family: 'Inter';
`;

interface IModalInfoSubtitleProps {
  pendingStatus?: boolean;
}

export const ModalInfoSubtitle = styled.p<IModalInfoSubtitleProps>`
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Inter';
`;

export const ModalButtonArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding-inline: 24px;
`;

export const ModalBackButton = styled(Button)``;
