import { SplitScreen, HalfPageLogo } from '../../components';
import FormLogin from './components/FormLogin/FormLogin';

import Background from './Login.style';

export function Login() {
  return (
    <Background>
      <SplitScreen Left={FormLogin} Right={HalfPageLogo} leftWeight={0.5} rightWeight={0.5} />
    </Background>
  );
}
