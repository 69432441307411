import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  width: 400px;
  height: 213px;
  bottom: -213px;
  right: 33px;
  box-shadow: 0px 2px 6px rgba(67, 89, 113, 0.3);
  background-color: #fff;

  .profileData {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: right;
    padding: 19px 20px;
    border-bottom: 1px solid #ccc;
    height: 90px;
  }

  .profileButtons {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: right;

    a {
      padding: 19px 20px;
      width: 100%;
      color: #6d6d6d;
      text-decoration: none;
    }

    a:hover {
      background: #f0f0f0;
    }

    button {
      padding: 19px 20px;
      color: #6d6d6d;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: right;
      text-transform: none;
      font-size: 16px;
    }

    button:hover {
      background: #f0f0f0;
    }

    button:disabled {
      background: #f0f0f0;
      opacity: 0.5;
    }
  }

  @media (max-width: 1100px) {
    width: 350px;
  }

  @media (max-width: 500px) {
    width: 300px;
  }
`;

export default Container;
