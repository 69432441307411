/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Box, Button, Grid, TextField, Typography, Popover } from '@mui/material';
import MaskedInput from 'react-text-mask';
import IntlCurrencyInput from 'react-intl-currency-input';
import { Controller } from 'react-hook-form';

import { BackToLastPageModal, CustomButton, CustomInput, InfoModal } from '../../components';
import { DayButton } from './components/DayButton/DayButton';
import { ReservationPeriod } from './components/ReservationPeriod/ReservationPeriod';
import { CustomFileInput } from './components/CustomFileInput/CustomFileInput';

import { useEditAreaController } from './EditArea.controller';
import PreviewImage from './components/PreviewImage/PreviewImage';

import {
  Container,
  AreaInfoFields,
  AvailableDays,
  DaysOfWeekButtonsContainer,
  ErrorMessage,
  Footer,
  Header,
  InputsRow,
  LocalPhotos,
  SmallInputsContainer,
  ToggleContainer
} from './EditArea.styled';

const plus = require('../../assets/images/plusareainput.svg');
const less = require('../../assets/images/lessareainput.svg');

export function EditArea() {
  const {
    DaysOfTheWeek,
    control,
    availableDay,
    errors,
    isAvailableArea,
    isReservationLimitation,
    hasReservationLimitationByUnit,
    areaOfId,
    sidebarLink,
    isImmediateApproval,
    cancellationType,
    currencyConfig,
    isOpenInfoModal,
    locationPictures,
    isOpenImagePreview,
    isOpenBackToLastPageModal,
    infoText,
    isSuccessfully,
    popover1,
    popover2,
    popover3,
    popover4,
    isOpenPop1,
    isOpenPop2,
    isOpenPop3,
    isOpenPop4,
    setPopover1,
    setPopover2,
    setPopover3,
    setPopover4,
    NavigateToListArea,
    handleModalOpen,
    register,
    handleChangeAvailableArea,
    handleChangeImmediateApproval,
    handleChangeReservationLimitation,
    handleChangeReservationLimitationByUnit,
    createNewArea,
    handleSubmit,
    handleReservationCapacity,
    handleAddMoreOne,
    handleLessOne,
    backToAreaPage
  } = useEditAreaController();

  return (
    <Container isImmediateApproval={isImmediateApproval}>
      <Header>
        <Typography component="h1" className="title">
          Editar área
        </Typography>
        <Typography component="h2" className="subtitle">
          Olá! Você está na tela de edição de áreas.
        </Typography>
      </Header>

      {areaOfId.areaName && (
        <>
          <AreaInfoFields>
            <InputsRow>
              <Box className="bigInput">
                <Typography className="inputLabel">Área</Typography>
                <Controller
                  name="areaName"
                  control={control}
                  render={() => (
                    <CustomInput
                      id="area-name-input"
                      error={errors.areaName}
                      register={{ ...register('areaName') }}
                      label={areaOfId.areaName}
                      type="text"
                    />
                  )}
                />
                <ErrorMessage>{errors.areaName?.message}</ErrorMessage>
              </Box>
              <SmallInputsContainer isImmediateApproval={isImmediateApproval}>
                <Box className="smallInput">
                  <Typography className="inputLabel" marginBottom={0.5}>
                    Valor da Locação
                  </Typography>
                  <Box className="priceContainer">
                    <Controller
                      name="price"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <IntlCurrencyInput
                          id="area-value-input"
                          className="valueInput deadlineInput priceInput"
                          onChange={onChange}
                          value={isImmediateApproval ? 0 : value}
                          currency="BRL"
                          limit={10}
                          disabled={isImmediateApproval}
                          config={currencyConfig}
                          render={(ref: any, props: any) => (
                            <TextField
                              {...register('price')}
                              inputRef={ref}
                              {...props}
                              value={isImmediateApproval ? 0 : value}
                              disabled={isImmediateApproval}
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                  <ErrorMessage>{isImmediateApproval ? '' : errors.price?.message}</ErrorMessage>
                </Box>
                <Box className="smallInput">
                  <Typography
                    className="inputLabel breakLineFirst"
                    marginBottom={0.5}
                    marginTop="-22px">
                    Prazo para o pagamento (em horas)
                  </Typography>
                  <Box className="inputCountContainer">
                    <plus.ReactComponent
                      className="plus"
                      onClick={() => !isImmediateApproval && handleAddMoreOne('deadline')}
                    />
                    <less.ReactComponent
                      className="less"
                      onClick={() => !isImmediateApproval && handleLessOne('deadline')}
                    />
                    <Controller
                      name="deadline"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <MaskedInput
                          mask={[/\d/, /\d/, /\d/]}
                          onChange={onChange}
                          value={value}
                          guide={false}
                          render={(ref, props) => (
                            <TextField
                              id="area-deadline-input"
                              className="valueInput deadlineInput"
                              {...register('deadline')}
                              error={!!errors.deadline}
                              inputRef={ref}
                              {...props}
                              disabled={isImmediateApproval}
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                  <ErrorMessage>{errors.deadline?.message}</ErrorMessage>
                </Box>
              </SmallInputsContainer>
            </InputsRow>
            <InputsRow>
              <Box className="bigInput">
                <Typography className="inputLabel" marginBottom={0.5}>
                  Cancelamento
                </Typography>
                <Controller
                  name="cancellationDeadline"
                  control={control}
                  render={() => (
                    <Box className="cancellationContainer">
                      <CustomInput
                        id="area-cancel-select"
                        error={errors.cancellationDeadline}
                        register={{ ...register('cancellationDeadline') }}
                        select
                        values={cancellationType}
                        value={areaOfId.cancellationDeadline}
                        type="text"
                      />
                    </Box>
                  )}
                />

                <ErrorMessage>{errors.cancellationDeadline?.message}</ErrorMessage>
              </Box>
              <SmallInputsContainer className="cancelingRow">
                <Box className="smallInput">
                  <Box className="popoverContainer">
                    <Typography className="inputLabel">Capacidade Máxima</Typography>
                    <Typography
                      className="inputLabel infoPopover"
                      aria-owns={popover1 ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(event) => setPopover1(event.currentTarget)}
                      onMouseLeave={() => setPopover1(null)}>
                      i
                    </Typography>
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: 'none'
                      }}
                      open={isOpenPop1}
                      anchorEl={popover1}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      onClose={() => setPopover1(null)}
                      disableRestoreFocus>
                      <Typography
                        sx={{
                          p: 1,
                          maxWidth: '250px',
                          padding: 2,
                          textAlign: 'center',
                          borderRadius: '8px'
                        }}>
                        Refere-se à capacidade máxima de ocupação desta área.
                      </Typography>
                    </Popover>
                  </Box>
                  <Box className="inputCountContainer">
                    <plus.ReactComponent
                      className="plus"
                      onClick={() => handleAddMoreOne('maximumCapacity')}
                    />
                    <less.ReactComponent
                      className="less"
                      onClick={() => handleLessOne('maximumCapacity')}
                    />

                    <Controller
                      name="maximumCapacity"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <MaskedInput
                          mask={[/\d/, /\d/, /\d/, /\d/]}
                          onChange={onChange}
                          value={value}
                          guide={false}
                          onMouseLeave={handleReservationCapacity}
                          render={(ref, props) => (
                            <TextField
                              id="area-capacity-input"
                              className="valueInput deadlineInput"
                              {...register('maximumCapacity')}
                              error={!!errors.maximumCapacity}
                              inputRef={ref}
                              {...props}
                            />
                          )}
                        />
                      )}
                    />
                  </Box>

                  <ErrorMessage>{errors.maximumCapacity?.message}</ErrorMessage>
                </Box>
                <Box className="smallInput">
                  <Box className="popoverContainer">
                    <Typography className="inputLabel breakLine">Locações Simultâneas</Typography>
                    <Typography
                      className="inputLabel infoPopover"
                      aria-owns={isOpenPop2 ? 'locSimul' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(event) => setPopover2(event.currentTarget)}
                      onMouseLeave={() => setPopover2(null)}>
                      i
                    </Typography>
                    <Popover
                      id="locSimul"
                      sx={{
                        pointerEvents: 'none'
                      }}
                      open={isOpenPop2}
                      anchorEl={popover2}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      onClose={() => setPopover2(null)}
                      disableRestoreFocus>
                      <Typography
                        sx={{
                          p: 1,
                          maxWidth: '250px',
                          padding: 2,
                          textAlign: 'center',
                          borderRadius: '8px'
                        }}>
                        Refere-se ao número de unidades que podem alugar esta área simultaneamente.
                      </Typography>
                    </Popover>
                  </Box>
                  <Box className="inputCountContainer">
                    <plus.ReactComponent
                      className="plus"
                      onClick={() => handleAddMoreOne('simultaneousScheduling')}
                    />
                    <less.ReactComponent
                      className="less"
                      onClick={() => handleLessOne('simultaneousScheduling')}
                    />

                    <Controller
                      name="simultaneousScheduling"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <MaskedInput
                          mask={[/\d/, /\d/, /\d/, /\d/]}
                          onChange={onChange}
                          value={value}
                          guide={false}
                          onMouseLeave={handleReservationCapacity}
                          render={(ref, props) => (
                            <TextField
                              id="area-locations-input"
                              className="valueInput deadlineInput"
                              {...register('simultaneousScheduling')}
                              error={!!errors.maximumCapacity}
                              inputRef={ref}
                              {...props}
                            />
                          )}
                        />
                      )}
                    />
                  </Box>

                  <ErrorMessage>{errors.simultaneousScheduling?.message}</ErrorMessage>
                </Box>
                <Box className="smallInput">
                  <Box className="popoverContainer">
                    <Typography className="inputLabel">Capacidade de Reserva</Typography>
                    <Typography
                      className="inputLabel infoPopover"
                      aria-owns={popover3 ? 'reservation-capacity' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(event) => setPopover3(event.currentTarget)}
                      onMouseLeave={() => setPopover3(null)}>
                      i
                    </Typography>
                    <Popover
                      id="reservation-capacity"
                      sx={{
                        pointerEvents: 'none'
                      }}
                      open={isOpenPop3}
                      anchorEl={popover3}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      onClose={() => setPopover1(null)}
                      disableRestoreFocus>
                      <Typography
                        sx={{
                          p: 1,
                          maxWidth: '300px',
                          padding: 1,
                          textAlign: 'center',
                          borderRadius: '12px'
                        }}>
                        Refere-se ao resultado da capacidade máxima de pessoas em relação a área em
                        uma reserva.
                      </Typography>
                    </Popover>
                  </Box>
                  <Box className="inputCountContainer">
                    <Controller
                      name="capacityOfReservation"
                      control={control}
                      render={() => (
                        <TextField
                          className="valueInput deadlineInput"
                          placeholder="0 pessoas"
                          {...register('capacityOfReservation')}
                          error={!!errors.capacityOfReservation}
                          disabled
                          sx={{
                            borderColor: '#A7A7A7',
                            backgroundColor: '#E9E9E9',
                            color: '#7D7D7D'
                          }}
                        />
                      )}
                    />
                  </Box>
                  <ErrorMessage />
                </Box>
              </SmallInputsContainer>
            </InputsRow>
          </AreaInfoFields>

          <ToggleContainer>
            <Box className="toggleBox">
              <div className="onoff">
                <input
                  type="checkbox"
                  checked={!isAvailableArea}
                  id="availableArea"
                  onClick={handleChangeAvailableArea}
                />
                <label htmlFor="availableArea" />
              </div>
              <Typography className="selectLabel">Área não disponível</Typography>
            </Box>
            <Box className="toogleBox">
              <div className="onoff">
                <input
                  type="checkbox"
                  id="ImmediateApproval"
                  checked={isImmediateApproval}
                  onClick={handleChangeImmediateApproval}
                />
                <label htmlFor="ImmediateApproval" />
              </div>
              <Typography className="selectLabel">Área com aprovação Imediata</Typography>
            </Box>
          </ToggleContainer>

          <AvailableDays className={isAvailableArea ? '' : 'areaNotAvailable'}>
            <Typography className="midTitle">Dias disponíveis</Typography>
            <DaysOfWeekButtonsContainer className={isAvailableArea ? '' : 'areaNotAvailable'}>
              {DaysOfTheWeek.map((dayName) => {
                return (
                  <React.Fragment key={dayName}>
                    <DayButton id={dayName} name={dayName} />
                  </React.Fragment>
                );
              })}
            </DaysOfWeekButtonsContainer>
          </AvailableDays>

          <Box className={isAvailableArea ? '' : 'areaNotAvailable'}>
            <ReservationPeriod
              allWeekDays={DaysOfTheWeek}
              weekDay={availableDay}
              allTimeOfDays={areaOfId.availableSchedule.schedule}
            />
          </Box>

          <Box className="toogleContainer">
            <Box>
              <ToggleContainer className={isAvailableArea ? '' : 'areaNotAvailable'}>
                <Box className="toogleBox">
                  <div className="onoff">
                    <input
                      type="checkbox"
                      className="toogleInput"
                      id="reservationLimit"
                      checked={isReservationLimitation}
                      onClick={handleChangeReservationLimitation}
                    />
                    <label htmlFor="reservationLimit" />
                  </div>
                  <Typography className="selectLabel">
                    Haverá limitação de reservas por dia
                  </Typography>
                </Box>
              </ToggleContainer>

              <Box
                className={
                  isAvailableArea && isReservationLimitation
                    ? 'limitReservation'
                    : 'areaNotAvailable'
                }>
                <Typography className="labelText">Reservas</Typography>
                <Controller
                  name="scheduleTotal"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MaskedInput
                      mask={[/\d/, /\d/, /\d/]}
                      onChange={onChange}
                      value={Number(value) >= 100 ? '100' : value}
                      guide={false}
                      render={(ref, props) => (
                        <TextField
                          className="valueInput"
                          placeholder="Total de reservas da área"
                          {...register('scheduleTotal')}
                          inputRef={ref}
                          {...props}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box>
              <ToggleContainer className={isAvailableArea ? '' : 'areaNotAvailable'}>
                <Box className="toogleBox">
                  <div className="onoff">
                    <input
                      type="checkbox"
                      className="toogleInput"
                      id="reservationLimitByUnit"
                      checked={hasReservationLimitationByUnit}
                      onClick={handleChangeReservationLimitationByUnit}
                    />
                    <label htmlFor="reservationLimitByUnit" />
                  </div>
                  <Typography className="selectLabel">
                    Haverá limitação de reservas ativas por usuário
                  </Typography>

                  <Box className="smallInput limitByUnitPopover">
                    <Box className="popoverContainer">
                      <Typography
                        className="inputLabel infoPopover"
                        aria-owns={popover4 ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={(event) => setPopover4(event.currentTarget)}
                        onMouseLeave={() => setPopover4(null)}>
                        i
                      </Typography>
                      <Popover
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: 'none'
                        }}
                        open={isOpenPop4}
                        anchorEl={popover4}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center'
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center'
                        }}
                        onClose={() => setPopover4(null)}
                        disableRestoreFocus>
                        <Typography
                          sx={{
                            p: 1,
                            maxWidth: '300px',
                            padding: 1,
                            textAlign: 'center',
                            borderRadius: '12px'
                          }}>
                          Refere-se à quantidade de reservas que um usuário pode agendar para essa
                          área.
                        </Typography>
                      </Popover>
                    </Box>
                  </Box>
                </Box>
              </ToggleContainer>

              <Box
                className={
                  isAvailableArea && hasReservationLimitationByUnit
                    ? 'limitReservation'
                    : 'areaNotAvailable'
                }>
                <Typography className="labelText">Limite</Typography>
                <Controller
                  name="scheduleTotalByUnit"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MaskedInput
                      mask={[/\d/, /\d/, /\d/]}
                      onChange={onChange}
                      value={Number(value) >= 100 ? '100' : value}
                      guide={false}
                      render={(ref, props) => (
                        <TextField
                          className="valueInput"
                          placeholder="Total de reservas permitidas por unidade"
                          {...register('scheduleTotalByUnit')}
                          inputRef={ref}
                          {...props}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>

          <LocalPhotos>
            <Typography className="midTitle">Fotos do Local</Typography>
            <Grid item xs={12} sm={12} md={9.86}>
              <CustomFileInput accept=".jpg, .png" type="image" imagesSrc={locationPictures} />
              <Typography className="fileInputDescription">
                Arquivos permitidos (envio opcional): jpg, png.
              </Typography>
            </Grid>
          </LocalPhotos>

          <Footer>
            <Box className="buttonsContainer">
              <Box className="buttonContainer">
                <Button className="backButton" onClick={backToAreaPage}>
                  <Typography variant="body1" className="buttontext" fontWeight="600">
                    Voltar
                  </Typography>
                </Button>
              </Box>
              <Box className="buttonContainer">
                <CustomButton
                  id="area-edit-btn"
                  onClick={handleSubmit(createNewArea)}
                  text="Salvar"
                />
              </Box>
            </Box>
          </Footer>
        </>
      )}
      {isOpenImagePreview && <PreviewImage />}
      {isOpenInfoModal && (
        <InfoModal
          isSuccessfully={isSuccessfully}
          onClick={isSuccessfully ? NavigateToListArea : handleModalOpen}
          text={infoText}
        />
      )}
      {isOpenBackToLastPageModal && <BackToLastPageModal goToPage={sidebarLink || '/area'} />}
    </Container>
  );
}
