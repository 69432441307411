/* eslint-disable no-underscore-dangle */
import create from 'zustand';
import { OcurrencesProps } from './Ocurrences.props';

import {
  getOcurrencesDetailsById,
  searchOcurrences,
  sortOcurrencesData,
  deleteOcurrences,
  answerOcurrences,
  changeStatusOcurrences
} from '../../services/requests/ocurrences/Ocurrences.request';
import formatDate from '../../helpers/formatDate';

function formatLocalName(localName: string) {
  switch (localName) {
    case 'Common':
      return 'Área Comum';
    case 'Private':
      return 'Área Privada';
    default:
      return '';
  }
}

function formatStatus(statusName: string) {
  switch (statusName) {
    case 'Pendent':
      return 'Pendente';
    case 'InProgress':
      return 'Em Andamento';
    case 'Completed':
      return 'Concluído';
    case 'Reopened':
      return 'Reaberta';
    case 'Expired':
      return 'Concluído';
    default:
      return '';
  }
}

const initialState = {
  ocurrenceId: '',
  ocurrencesId: [],
  ocurrencesList: [],
  field: 'occurrence_model.status',
  direction: 'ASC',
  page: '1',
  isOpenActionDetailModal: false,
  imagePreview: ''
};

const useOcurrencesStore = create<OcurrencesProps>((set, get) => ({
  ...initialState,

  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  handleSetOcurrenceId: (ocurrenceId) => set((state) => ({ ...state, ocurrenceId })),
  handleSetOcurrencesId: (ocurrencesId) => set((state) => ({ ...state, ocurrencesId })),
  handleSetOcurrencesList: (ocurrencesList) => set((state) => ({ ...state, ocurrencesList })),
  handleSetImagePreview: (imagePreview) => set((state) => ({ ...state, imagePreview })),

  changeStatusOcurrencesRequest: async (status, id) => {
    try {
      await changeStatusOcurrences(status, id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  getOcurrencesDetailsByIdRequest: async (ocurrencesId) => {
    try {
      const { data } = await getOcurrencesDetailsById(ocurrencesId);

      const updatedData = data.history.map((hist: { _updatedAt: string; _sender: string }) =>
        new Date(new Date().getTime() - new Date(hist._updatedAt).getTime()).getDate()
      );

      const formatedData = {
        id: data.id,
        subject: data.name,
        block: data.unit.block.name,
        unit: data.unit.name,
        local: formatLocalName(data.local),
        createdAt: new Date(new Date(data.createdAt).getTime() + 90000000),
        date: new Date(new Date(data.createdAt).getTime() + 90000000).getTime(),
        updatedAt: new Date(new Date(data.updatedAt).getTime() + 90000000),
        status: formatStatus(data.status),
        description: data.description,
        files: data.files,
        history: data.history.map(
          (hist: { _name: string; _updatedAt: string; _sender: string }, index: number) => ({
            name: hist._name,
            updatedAt: `${formatDate(
              new Date(new Date(hist._updatedAt).getTime() + 90000000)
            )} (há ${
              updatedData[index] - 1 !== 1
                ? `${updatedData[index] - 1} dias`
                : `${updatedData[index] - 1} dia`
            })`,
            sender: hist._sender
          })
        )
      };
      return formatedData;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllSortOcurrencessDataRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const { data } = await sortOcurrencesData(page, sortByField, sortByDirection, searchString);
      const { totalOcurrences, totalPages } = data;

      const formatedData = data.data.map(
        (ocurrenceData: {
          id: string;
          name: string;
          local: string;
          status: string;
          createdAt: string;
          unit: { name: string; block: { name: string } };
        }) => ({
          id: ocurrenceData.id,
          subject: ocurrenceData.name,
          block: ocurrenceData.unit.block.name,
          unit: ocurrenceData.unit.name,
          local: formatLocalName(ocurrenceData.local),
          date: new Date(
            new Date(ocurrenceData.createdAt).getTime() + 90000000
          ).toLocaleDateString(),
          status: formatStatus(ocurrenceData.status)
        })
      );

      return { data: formatedData, totalOcurrences, totalPages };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  handleOpenActionDetailsModal: (isOpenActionDetailModal) =>
    set((state) => ({ ...state, isOpenActionDetailModal })),

  searchOcurrencesRequest: async (ocurrencesData, page, sortByField, sortByDirection) => {
    try {
      const { data } = await searchOcurrences(ocurrencesData, page, sortByField, sortByDirection);
      const formatedData = data.data.map(
        (ocurrenceData: {
          id: string;
          name: string;
          local: string;
          status: string;
          createdAt: string;
          unit: { name: string; block: { name: string } };
        }) => ({
          id: ocurrenceData.id,
          subject: ocurrenceData.name,
          block: ocurrenceData.unit.block.name,
          unit: ocurrenceData.unit.name,
          local: formatLocalName(ocurrenceData.local),
          date: new Date(
            new Date(ocurrenceData.createdAt).getTime() + 90000000
          ).toLocaleDateString(),
          status: formatStatus(ocurrenceData.status)
        })
      );

      return {
        data: formatedData,
        totalOcurrences: data.totalOcurrences,
        totalPages: data.totalPages
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  deleteOcurrencesRequest: async (id) => {
    try {
      await deleteOcurrences(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  answerOcurrencesRequest: async (message, id) => {
    try {
      await answerOcurrences(message, id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));

export { useOcurrencesStore };
