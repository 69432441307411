import apppreviewpic from '../../../../assets/images/apppreviewpic.png';

function useInfoCardControler() {
  const imageLink = apppreviewpic;

  return {
    imageLink
  };
}

export default useInfoCardControler;
