/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/jsx-props-no-spreading */
import { Grid, Typography, Button, Box, InputLabel } from '@mui/material';
import { useBlocksController } from './Blocks.controller';
import {
  ActionModal,
  DeleteModal,
  CustomSearchInput,
  CustomTable,
  InfoModal
} from '../../components';
import Container from './Blocks.style';
import { RegisterBlockModal } from './Components/RegisterBlockModal/RegisterBlockModal';
import { RenameBlockModal } from './Components/RenameBlockModal/RenameBlockModal';
import { AutocompleteInput } from './Components/Autocomplete/Autocomplete';
import { RowProps } from './Blocks.props';

const editIcon = require('../../assets/images/enterpriseactionedit.svg');
const detailsIcon = require('../../assets/images/details.svg');
const trashIcon = require('../../assets/images/enterpriseactiontrash.svg');

export function Blocks() {
  const {
    // page
    blocksTableHead,
    // enterprise
    selectedEnterprise,
    allEnterpriseData,
    setSelectedEnterprise,
    inputSearchValue,
    handleSearchInput,
    // block
    blocksList,
    blockDataToRename,
    blockID,
    blocksIDs,
    setBlockID,
    // page and counts
    totalBlocks,
    totalPages,
    // modal and messages
    isOpenDeleteModal,
    isOpenInfoModal,
    isOpenRegisterModal,
    isOpenRenameModal,
    isSuccessfully,
    successMessage,
    handleDeleteModalOpen,
    openRegisterBlockModal,
    handleDrawerOpen,
    closeInfoModal,
    handleActionModal,
    // requests
    handleSearch,
    sortDataRequest,
    onSubmitDelete,
    changePage
  } = useBlocksController();

  const hasSelectedEnterprise = selectedEnterprise?.label || inputSearchValue.length > 0;
  const inputLabelText = inputSearchValue.length > 0 ? '' : selectedEnterprise?.label;

  return (
    <Container>
      {allEnterpriseData[0].label !== '' && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Typography variant="h5" fontWeight={500} marginBottom={5}>
              Blocos e Unidades
            </Typography>
            <Grid />
            <Box className="searchOptions">
              <Grid item xs={12} sm={6} md={7} lg={7.5}>
                <CustomSearchInput id="block-search" handleSearch={handleSearch} />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4.5}>
                <Box className="container">
                  <>
                    <InputLabel className="selectInputLabel">
                      {hasSelectedEnterprise ? inputLabelText : 'Selecione'}
                    </InputLabel>
                    <AutocompleteInput
                      id="block-select"
                      className="selectInput"
                      options={allEnterpriseData || []}
                      handleSearch={handleSearchInput}
                      setSelectedItem={setSelectedEnterprise}
                    />
                  </>
                </Box>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} alignSelf="flex-end">
            <Button
              id="block-register-block"
              onClick={openRegisterBlockModal}
              className="registerButton"
              disabled={!selectedEnterprise}
              style={{
                opacity: !selectedEnterprise ? '0.8' : '1',
                color: '#fff'
              }}
              fullWidth>
              Cadastrar Blocos
            </Button>
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              rowsId={blocksIDs}
              tableHead={blocksTableHead}
              tableData={blocksList || []}
              onClick={() => handleDrawerOpen()}
              totalPages={totalPages}
              totalItems={totalBlocks}
              funcToChangePage={changePage}
              handleSetItemId={setBlockID}
              noFilesFoundText="Não há nenhum registro de bloco no momento."
              sortFunc={sortDataRequest}
              containId
              renderAction={(row: any) => {
                return (
                  <ActionModal
                    ActionModalData={handleActionModal(row)}
                    ButtonsIcon={[
                      <detailsIcon.ReactComponent className="actionIcon" />,
                      <editIcon.ReactComponent className="actionIcon" />,
                      <trashIcon.ReactComponent className="actionIcon" />
                    ]}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          title="Tem certeza que deseja excluir este bloco?"
          onClickDelete={() => onSubmitDelete(blockID)}
          handleModalClose={handleDeleteModalOpen}
        />
      )}

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={closeInfoModal} text={successMessage} />
      )}

      {isOpenRegisterModal && <RegisterBlockModal />}

      {isOpenRenameModal && <RenameBlockModal blockData={blockDataToRename} />}
    </Container>
  );
}
