/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, Typography } from '@mui/material';
import {
  DeleteOutline,
  DoNotDisturb,
  KeyboardDoubleArrowRight,
  ModeEditOutlineOutlined,
  VisibilityOutlined
} from '@mui/icons-material';
import { Background } from './Voting.style';

import {
  CustomSearchInput,
  ActionModal,
  ConfirmModal,
  CustomTable,
  BackToLastPageModal,
  CustomButton,
  InfoModal
} from '../../components/index';
import useVotingController from './Voting.controller';

import { Modal } from './Modal/Modal';

const iconsForOpenedVoting = [<VisibilityOutlined />, <DoNotDisturb />];
const iconsForFinishedVoting = [<VisibilityOutlined />, <DeleteOutline />];
const iconsForNotStartedVoting = [
  <ModeEditOutlineOutlined />,
  <KeyboardDoubleArrowRight />,
  <DeleteOutline />
];

function handleActionModalIcons(rowStatus: string) {
  const isOpen = rowStatus === 'Aberta';
  const isFinished = rowStatus === 'Encerrada';

  if (isOpen) return iconsForOpenedVoting;
  if (isFinished) return iconsForFinishedVoting;
  return iconsForNotStartedVoting;
}

export function Voting() {
  const {
    tableHeaders,
    rowsId,
    totalPages,
    totalVoting,
    variant,
    voting,
    isInitiateModalOpen,
    isDeleteModalOpen,
    isModalRegisterOpen,
    isFinalizeModalOpen,
    handleModalOpen,
    isOpenInfoModal,
    isSuccessfully,
    text,
    isModalEditOpen,
    isOpenBackToLastPageModal,
    handleSetIsSuccessfully,
    handleSetText,
    sortVoting,
    handleSetEnterpriseId,
    toggleEditModal,
    toggleFinalizeModal,
    changePage,
    toggleRegisterModal,
    toggleInitiateModal,
    toggleDeleteModal,
    onComplete,
    handleDrawerOpen,
    handleSearch,
    closeModal,
    handleActionModal
  } = useVotingController();
  return (
    <Background>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} lg={9.5}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Votaçōes
          </Typography>
          <CustomSearchInput id="vote-search" handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={2.5} alignSelf="flex-end">
          <CustomButton id="vote-new" onClick={toggleRegisterModal} text="Novo" />
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            handleSetItemId={handleSetEnterpriseId}
            totalPages={totalPages}
            totalItems={totalVoting}
            funcToChangePage={changePage}
            tableHead={tableHeaders}
            tableData={voting}
            onClick={handleDrawerOpen}
            rowsId={rowsId}
            noFilesFoundText="Não há nenhum registro de votaçōes no momento."
            sortFunc={sortVoting}
            containId
            renderAction={(row) => {
              return (
                <ActionModal
                  ActionModalData={handleActionModal(row.status)}
                  ButtonsIcon={handleActionModalIcons(row.status)}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {isInitiateModalOpen && (
        <ConfirmModal
          title="Tem certeza que deseja iniciar esta votação?"
          onClickConfirm={() => onComplete()}
          handleModalClose={toggleInitiateModal}
        />
      )}
      {isFinalizeModalOpen && (
        <ConfirmModal
          title="Tem certeza que deseja encerrar esta votação?"
          onClickConfirm={() => onComplete()}
          handleModalClose={toggleFinalizeModal}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmModal
          title="Tem certeza que deseja excluir esta votação?"
          onClickConfirm={() => onComplete()}
          handleModalClose={toggleDeleteModal}
        />
      )}

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}

      <Modal
        variant={variant}
        isModalOpen={isModalEditOpen}
        toggleModal={toggleEditModal}
        title="Editar votação"
        onComplete={onComplete}
        toggleSuccessModalOpen={handleModalOpen}
        setTitle={handleSetText}
        setSuccess={handleSetIsSuccessfully}
      />

      <Modal
        variant={variant}
        isModalOpen={isModalRegisterOpen}
        toggleModal={toggleRegisterModal}
        title="Cadastrar votação"
        onComplete={onComplete}
        toggleSuccessModalOpen={handleModalOpen}
        setTitle={handleSetText}
        setSuccess={handleSetIsSuccessfully}
      />

      {isOpenBackToLastPageModal && (
        <BackToLastPageModal goToPage="/vote" handleModalClose={closeModal} />
      )}
    </Background>
  );
}
