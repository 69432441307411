import { useEffect, useState } from 'react';
import { useOrdersStore } from '../../../store';
import { TableOrdersProps } from './ModalInfo.props';

export const useModalInfoController = (tableOrders: TableOrdersProps[], indexOfRow: number) => {
  const [orders, setOrders] = useState<TableOrdersProps>(tableOrders[indexOfRow]);
  const { id } = useOrdersStore();

  useEffect(() => {
    setOrders(tableOrders[indexOfRow]);
  }, [id]);

  return {
    orders
  };
};
