import styled, { css } from 'styled-components';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .Container {
    background-color: #fff;
    width: 500px;
    height: 650px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px;
    gap: 10px;
    isolation: isolate;
  }

  .statusButton {
    padding: 6px 6px;
    border-radius: 5px;
  }

  .Cancelado {
    background: #d9d9d9;
    p {
      color: #414141;
    }
  }

  .Expirado {
    background: #fff6a7;
    p {
      color: #c96000;
    }
  }

  .Pendente {
    background: #d3fffc;
    p {
      color: #006d74;
    }
  }

  .Revogado {
    background: #ffc5c5;
    p {
      color: #cf0606;
    }
  }

  .Autorizado {
    background: #d4ffd3;
    p {
      color: #00740c;
    }
  }

  .statusText {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    padding: 0 5px;
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: #2c2c2c;
    margin-bottom: 10px;
  }

  .closeBtnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .closeBtn {
    cursor: pointer;
  }

  .iconContainer {
    display: flex;
    margin-bottom: 6px;
    width: 100%;
    padding-left: 40px;

    svg {
      width: 43px;
      height: 43px;
      margin-top: 3px;
    }
  }

  .iconData {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }

  .dataTitle {
    color: #2c2c2c;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter';
  }

  .dataSubtitle {
    color: #8b8b8b;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Inter';

    ${({ pendingStatus }: { pendingStatus?: boolean }) =>
      pendingStatus &&
      css`
        background: #fff6a7;
        color: #c96000;
        padding: 6.5px 24px;
        font-size: 14px;
        border-radius: 6px;
      `}
  }

  .boxContainer {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    gap: 15px;
  }
`;
export default Background;
