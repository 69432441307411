import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useDocumentsStore } from '../../../store/Documents/Documents.store';
import { FormRequiredFields } from '../Documents.props';
import { useBackToLastPageModalStore, useInfoModalStore, useLoadModalStore } from '../../../store';
import { Errors } from '../../../constants/Errors';

export const useModalController = (
  variant: string,
  onComplete: () => void,
  errorModal: () => void,
  toggleModal: () => void,
  isModalOpen: boolean
) => {
  const { createFolderRequest, renameFolderRequest, id, getFolderName, getDocumentById } =
    useDocumentsStore();
  const { handleBackToLastPageModalOpen } = useBackToLastPageModalStore();
  const { handleModalOpen, handleSetIsSuccessfully, handleSetText } = useInfoModalStore();
  const { toogleLoading } = useLoadModalStore();

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[ a-zA-ZÀ-ÿ0-9\u00f1\u00d1]*$/g,
        'Nome inválido para pasta, evite usar caracteres especiais.'
      )
      .max(50, 'O nome deve conter até 50 caracteres.')
      .required('Por favor, preencher nome da pasta.')
      .trim()
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  });

  function resolveErrorMessage(message: string) {
    if (message.includes('Error: ')) {
      return message.split(': ')[1];
    }
    return message;
  }

  async function getFolderData() {
    if (variant !== 'edit') return;
    toogleLoading();
    try {
      const { data } = await getDocumentById(id);
      setValue('name', data);
    } finally {
      toogleLoading();
    }
  }

  const sendRequest = async () => {
    const { name } = getValues();

    toogleLoading();
    if (variant === 'create') {
      try {
        await createFolderRequest(name, onComplete);
        setValue('name', '');
        handleSetText('Pasta criada com sucesso!');
        handleSetIsSuccessfully(true);
      } catch (error: any) {
        handleSetText(
          (Errors as any)({})[resolveErrorMessage(error.message)] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        handleSetIsSuccessfully(false);
        toggleModal();
      } finally {
        toogleLoading();
        handleModalOpen();
      }
    }

    if (variant === 'edit') {
      try {
        await renameFolderRequest(id, name, onComplete);
        setValue('name', '');
        handleSetText('Pasta editada com sucesso!');
        handleSetIsSuccessfully(true);
      } catch (error: any) {
        handleSetText(
          (Errors as any)({})[resolveErrorMessage(error.message)] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        handleSetIsSuccessfully(false);
        toggleModal();
      } finally {
        toogleLoading();
        handleModalOpen();
      }
    }
  };

  const closeDocumentModal = () => {
    const { name } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    name ? handleBackToLastPageModalOpen() : toggleModal();
  };

  useEffect(() => {
    getFolderData();
  }, [isModalOpen]);

  return {
    sendRequest,
    getFolderName,
    handleSubmit,
    register,
    reset,
    control,
    errors,
    setValue,
    closeDocumentModal
  };
};
