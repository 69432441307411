import axios from 'axios';
import { IDtoRequestData, IDtoResponseData } from './CEP.dto';

export const getLocationByCEP = async (CEP: IDtoRequestData): Promise<IDtoResponseData> => {
  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${CEP.cep}/json/`);
    const formatedData = {
      city: data.localidade,
      state: data.uf,
      cep: data.cep,
      street: data.logradouro,
      district: data.bairro
    };
    return formatedData;
  } catch (error) {
    throw new Error('Invalid Credentials');
  }
};
