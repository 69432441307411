import { instance } from '../../api';
import { ManualDataRequest } from './welcomeScreen.dto';

const baseURLCore = process.env.REACT_APP_BASE_URL;
const genericError = 'Ocorreu um erro inesperado, tente novamente mais tarde';

export const getOwnerManual = async (): Promise<ManualDataRequest> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      url: 'enterprise/manual/get-enterprise-manual',
      baseURL: baseURLCore
    });

    return data.data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode || genericError);
  }
};
