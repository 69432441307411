import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Background = styled.div`
  display: flex;
  margin: 90px 24px 0px 20px;
  padding-inline: 24px;
  padding-bottom: 3rem;

  .imageContainer {
    width: 100%;
    height: 100%;
    background-color: red;
  }

  .firection {
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    gap: 32px;
  }

  .div2PDF {
    border: 1px solid #dddddd;
    border-radius: 8px;
    width: fit-content;
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }

  .registerEnterpriseButton {
    background: #662483;
    border-radius: 12px;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }

  .containerButton {
    justify-content: space-between;
    display: flex;
    width: 100%;

    @media (max-width: 1100px) {
      flex-direction: column;
    }
  }

  .boxButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    @media (max-width: 1100px) {
      align-self: flex-start;
      margin-left: 20px;
      margin-top: 10px;
    }
  }

  .registerEnterpriseButton:hover {
    opacity: 0.95;
  }

  .actionIcon {
    height: 15px;
    width: 15px;
  }

  .backButton {
    width: 100%;
  }

  .createVoteBtn {
    cursor: pointer;
    background-color: #f0f1f5;
    color: #000000;
    height: 45px;
    width: 100%;
    max-width: 200px;
    border-radius: 8px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #f0f1f5;
    margin-top: 30px;
    margin-right: 70px;

    @media (max-width: 800px) {
      margin-top: -20px;
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .createVoteBtn:hover {
    background-color: #f0f0f2;
    color: #c6c6c6;
    opacity: 0.9;
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`;

export const ContainerButton = styled.div`
  button {
    padding: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 0px;
    text-transform: none;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custombutton {
    background: #662483;
    border-radius: 8px;
    color: #ffffff;
  }

  .custombutton:hover {
    background-color: #662483;
    opacity: 0.9;
  }

  .delete {
    background: #f0f1f5;
    color: #000000;
  }

  .delete:hover {
    background-color: #f0f1f5;
    opacity: 0.9;
  }

  .buttontext {
    margin-top: 3px;
  }
`;

export const Subtitle = styled(Typography)``;
