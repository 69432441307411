import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useActionModalStore,
  useEnterpriseStore,
  useLoadModalStore,
  useSortItemsStore,
  usePaginationStore,
  useBackToLastPageModalStore,
  useInfoModalStore
} from '../../store';
import { useVotingStore } from '../../store/Voting/Voting.store';
import { SearchVoting } from '../../services/requests/voting/Voting.dto';
import { Errors } from '../../constants/Errors';
import AllVotingData from './Voting.props';

function useVotingController() {
  const [rowsId, setRowsId] = useState(['']);
  const [variant, setVariant] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [voting, setVoting] = useState<AllVotingData[]>([
    { endDate: '', id: '', per: '', sent: '', status: '', subject: '', views: '', votes: '' }
  ]);
  const [totalVoting, setTotalVoting] = useState(0);
  const [isInitiateModalOpen, setIsInitiateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  const { isOpenBackToLastPageModal } = useBackToLastPageModalStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { id, handleSetEnterpriseId } = useEnterpriseStore();
  const { handleSetSortFields } = useSortItemsStore();
  const {
    handleSetIsSuccessfully,
    handleSetText,
    handleModalOpen,
    isOpenInfoModal,
    isSuccessfully,
    text
  } = useInfoModalStore();

  const {
    getAllSortVotingDataRequest,
    handleSetVotingId,
    searchVotingRequest,
    deleteVotingRequest,
    closeVotingRequest,
    openVotingRequest,
    direction,
    field
  } = useVotingStore();
  const navigation = useNavigate();

  async function changePage(page: number) {
    toogleLoading();
    try {
      const { data } = await getAllSortVotingDataRequest(`${page}`, field, direction, searchString);

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      if (data) {
        setRowsId(formatedRowsId);
        handleSetVotingId(formatedRowsId);
        setVoting(formatedData);
      }
    } finally {
      toogleLoading();
    }
  }

  const getAllVoting = async () => {
    toogleLoading();

    try {
      const { data } = await getAllSortVotingDataRequest(
        String(currentPage),
        field,
        direction,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      setTotalPages(data.totalPages);
      setTotalVoting(data.totalVoting);
      if (data) {
        setRowsId(formatedRowsId);
        handleSetVotingId(formatedRowsId);
        setVoting(formatedData);
      }
    } finally {
      toogleLoading();
    }
  };

  const sortVoting = async (page: string, sortByField: string, sortByDirection: string) => {
    const data = await getAllSortVotingDataRequest(
      page,
      sortByField,
      sortByDirection,
      searchString
    );

    return data;
  };

  const successModal = () => {
    setIsDeleteModalOpen(false);
    setIsInitiateModalOpen(false);
    setIsFinalizeModalOpen(false);
    setIsModalRegisterOpen(false);
    setIsModalEditOpen(false);
  };

  const toggleInitiateModal = () => {
    setVariant('iniciada');
    setIsInitiateModalOpen((prev) => !prev);
  };

  const toggleDeleteModal = () => {
    setVariant('excluída');
    setIsDeleteModalOpen((prev) => !prev);
  };

  const toggleFinalizeModal = () => {
    setVariant('encerrada');
    setIsFinalizeModalOpen((prev) => !prev);
  };

  const toggleRegisterModal = () => {
    setVariant('criada');
    setIsModalRegisterOpen((prev) => !prev);
  };

  const toggleEditModal = () => {
    setIsModalEditOpen((prev) => !prev);
  };

  const onComplete = async () => {
    if (variant === 'iniciada') {
      try {
        await openVotingRequest(id);
        handleSetText('Votação iniciada com sucesso!');
        handleSetIsSuccessfully(true);
      } catch (error: any) {
        handleSetText(
          (Errors as any)({})[String(error.message).trim()] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        handleSetIsSuccessfully(false);
      } finally {
        handleModalOpen();
        successModal();
        getAllVoting();
      }
    }

    if (variant === 'encerrada') {
      try {
        await closeVotingRequest(id);
        handleSetText('Votação encerrada com sucesso!');
        handleSetIsSuccessfully(true);
      } catch (error: any) {
        handleSetText(
          (Errors as any)({})[String(error.message).trim()] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        handleSetIsSuccessfully(false);
      } finally {
        handleModalOpen();
        successModal();
        getAllVoting();
      }
    }

    if (variant === 'excluída') {
      try {
        await deleteVotingRequest(id);
        handleSetText('Votação excluída com sucesso!');
        handleSetIsSuccessfully(true);
      } catch (error: any) {
        handleSetText(
          (Errors as any)({})[String(error.message).trim()] ||
            'Um erro estranho ocorreu, tente novamente em alguns instantes.'
        );
        handleSetIsSuccessfully(false);
      } finally {
        handleModalOpen();
        successModal();
        getAllVoting();
      }
    }
  };

  const actionModalForFinishedVoting = [
    {
      ButtonsText: 'Ver resultados',
      OnClick: () => {
        navigation('/vote/result');
        handleDrawerOpen();
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        toggleDeleteModal();
      }
    }
  ];

  const actionModalForOpenedVoting = [
    {
      ButtonsText: 'Visualizar votação',
      OnClick: () => {
        setVariant('visualizada');
        toggleEditModal();
      }
    },
    {
      ButtonsText: 'Encerrar votação',
      OnClick: () => {
        toggleFinalizeModal();
      }
    }
  ];

  const actionModalForNotStartedVoting = [
    {
      ButtonsText: 'Editar',
      OnClick: () => {
        setVariant('editada');
        toggleEditModal();
      }
    },
    {
      ButtonsText: 'Iniciar votação',
      OnClick: () => {
        toggleInitiateModal();
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        toggleDeleteModal();
      }
    }
  ];

  const handleActionModal = (rowStatus: string) => {
    const isOpen = rowStatus === 'Aberta';
    const isFinished = rowStatus === 'Encerrada';

    if (isOpen) return actionModalForOpenedVoting;
    if (isFinished) return actionModalForFinishedVoting;
    return actionModalForNotStartedVoting;
  };

  async function handleSearch(value: string) {
    setSearchString(value);
    const formatedDataForSearch: SearchVoting = { queryText: value };
    const { data } = await searchVotingRequest(
      formatedDataForSearch,
      String(currentPage),
      field,
      direction
    );

    const formatedData = data.data.map((item: AllVotingData) => {
      return {
        id: item.id,
        sent: item.sent,
        subject: item.subject,
        per: item.per,
        views: item.views,
        votes: item.votes,
        endDate: item.endDate,
        status: item.status
      };
    });

    const formatedRowsId = data.data.map((item: { id: string }) => {
      return item.id;
    });

    setTotalPages(data.totalPages);
    setTotalVoting(data.totalVoting);
    if (data) {
      setRowsId(formatedRowsId);
      handleSetVotingId(formatedRowsId);
      setVoting(formatedData);
    }
  }

  function setSetSortFields() {
    handleSetSortFields([
      'created_at',
      'subject',
      'created_by',
      'resident_view_count',
      'votes',
      'end_date',
      'status'
    ]);
  }

  function closeModal() {
    if (isModalRegisterOpen) {
      toggleRegisterModal();
    }

    if (isModalEditOpen) {
      setVariant('editada');
      toggleEditModal();
    }
  }

  useEffect(() => {
    if (isModalRegisterOpen || isModalEditOpen) return;
    getAllVoting();
    setSetSortFields();
  }, [isModalRegisterOpen, isModalEditOpen]);

  const tableHeaders = [
    'Enviado',
    'Assunto',
    'Por',
    'Visualizações',
    'Qtd. Votos',
    'Prazo',
    'Status',
    'Ações'
  ];

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    tableHeaders,
    rowsId,
    totalPages,
    totalVoting,
    sortVoting,
    handleDrawerOpen,
    changePage,
    handleSearch,
    handleSetEnterpriseId,
    isModalRegisterOpen,
    toggleRegisterModal,
    voting,
    isModalEditOpen,
    toggleEditModal,
    variant,
    toggleInitiateModal,
    toggleFinalizeModal,
    toggleDeleteModal,
    isInitiateModalOpen,
    isFinalizeModalOpen,
    isDeleteModalOpen,
    handleModalOpen,
    isOpenInfoModal,
    isSuccessfully,
    text,
    onComplete,
    handleSetText,
    handleSetIsSuccessfully,
    isOpenBackToLastPageModal,
    closeModal,
    handleActionModal
  };
}

export default useVotingController;
