import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin: 90px 24px 0px 20px;
  padding-right: 24px;
  padding-left: 24px;

  .registerButton {
    background: #662483;
    border-radius: 12px;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    text-transform: none;
  }

  .registerButton:hover {
    background: #662483;
    color: #fff;
    opacity: 0.95;
  }

  .searchOptions {
    display: flex;
    gap: 20px;
  }

  .selectInputLabel {
    position: absolute;
    top: 11px;
    left: 15px;
  }

  .searchEnterprise {
    width: 100%;
    border-radius: 12px;
    text-align: center;

    div {
      border-radius: 12px;
      height: 43.5px;
      div {
        height: 0;
      }
    }

    fieldset {
      border: none;
    }

    :hover {
      outline: none;
      border: none;
    }
  }

  .selectInput {
    flex: 1;
    width: 100%;
    height: 45px;
    background-color: transparent;
    input {
      height: 10px !important;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }

    div {
      display: flex;
      align-items: center;
    }
  }

  .container {
    position: relative;
  }

  @media (max-width: 700px) {
    padding: 0;

    .searchOptions {
      flex-direction: column;
    }
  }
`;

export default Container;
