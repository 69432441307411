/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, FormControl, Typography } from '@mui/material';

import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import Background from './FormLogin.style';

import { CustomInput, CustomButton, Logo } from '../../../../components';

import handleLogin from './FormLogin.controller';

function FormLogin() {
  const { submitForm, handleSubmit, control, register, errors, isDisable, emailValidation } =
    handleLogin();

  return (
    <Background>
      <Box className="backBtn">
        <a href="/">
          <ArrowBack className="backIcon" />
        </a>
      </Box>
      <Box className="logoAndLoginContainer">
        <Logo />
        <Box>
          <Typography variant="h4" fontWeight="600">
            Entrar
          </Typography>
        </Box>
        <FormControl className="loginContainer">
          <label>E-mail</label>
          <Controller
            name="email"
            control={control}
            render={() => (
              <Box className="inputErrorContainer">
                <CustomInput
                  register={{ ...register('email') }}
                  error={errors.email}
                  emailValidation={emailValidation}
                  label="Informe seu e-mail"
                  type="emailLogin"
                  isAuth
                  id="email"
                />
                {errors.email && <Box className="errorMessage">{errors.email.message}</Box>}
              </Box>
            )}
          />
          <label>Senha</label>
          <Controller
            name="password"
            control={control}
            render={() => (
              <Box className="inputErrorContainer">
                <CustomInput
                  register={{ ...register('password') }}
                  error={errors.password}
                  label="Informe sua senha"
                  type="password"
                  isAuth
                  id="password"
                />
                {errors.password && <Box className="errorMessage">{errors.password.message}</Box>}
              </Box>
            )}
          />
          <CustomButton
            isDisable={isDisable}
            id="loginButton"
            text="Entrar"
            onClick={handleSubmit(submitForm)}
          />
        </FormControl>
        <Box>
          <Link to="/forgotPassword" color="#662483">
            Esqueceu sua senha?
          </Link>
        </Box>
      </Box>
    </Background>
  );
}

export default FormLogin;
