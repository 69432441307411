import { useMaintenancesStore, usePreviewImageModalStore } from '../../../../store';

function usePreviewImageController() {
  const { imageToPreview } = useMaintenancesStore();
  const { handleImagePreviewOpen } = usePreviewImageModalStore();

  return { image: imageToPreview, handleImagePreviewOpen };
}

export default usePreviewImageController;
