import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  justify-content: center;
  height: 35px;
  img {
    height: 100%;
    margin-right: 10px;
    margin-top: 2px;
  }
`;

export default Container;
