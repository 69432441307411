import { useEffect, useState } from 'react';
import {
  useActionModalStore,
  useBackToLastPageModalStore,
  useInfoModalStore,
  useLoadModalStore,
  useOrdersStore,
  usePaginationStore,
  useSortItemsStore
} from '../../store';

function useOrdersController() {
  const [rowsId, setRowsId] = useState(['']);
  const [variant, setVariant] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false);

  const { isOpenBackToLastPageModal } = useBackToLastPageModalStore();
  const [orders, setOrders] = useState([] as any);
  const [allData, setAllData] = useState([] as any);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const [totalOrders, setTotalOrders] = useState(0);
  const [isGetOrderModal, setIsGetOrderModal] = useState(false);
  const {
    variantInfor,
    handleSetOrdersId,
    id,
    field,
    direction,
    handleSetOrderId,
    searchOrdersRequest,
    getAllOrdersSortRequest
  } = useOrdersStore();
  const { handleSetSortFields } = useSortItemsStore();
  const [indexOfRow, setIndexOfRow] = useState(0);
  const [searchString, setSearchString] = useState('');
  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const { isOpenInfoModal, handleModalOpen, text, isSuccessfully } = useInfoModalStore();

  const toggleInfoModal = () => {
    rowsId.map((item, index) => {
      if (item === id) {
        setIndexOfRow(index);
      }
      return index;
    });

    setIsOpenModalInfo((prev) => !prev);
  };

  const getAllOrders = async (page?: number) => {
    toogleLoading();
    try {
      const { data } = await getAllOrdersSortRequest(
        String(page) !== 'undefined' ? String(page) : String(currentPage),
        field,
        direction,
        searchString
      );

      const { totalOrders: totalData, totalPages: pages } = data;

      const formatedData = data.data.map((item: any) => {
        return {
          id: item.id,
          recipient: item.recipient,
          sender: item.sender,

          unit: item.unit,
          block: item.block,
          createdAt: item.created_at,
          status: item.status
        };
      });

      const formatedAllData = data.data.map((item: any) => {
        return {
          recipient: item.recipient,
          sender: item.sender,
          unit: item.unit,
          block: item.block,
          createdAt: item.created_at,
          status: item.status,
          receivedBy: item.receivedBy,
          updatedAt: item.updated_at
        };
      });

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      setTotalPages(pages);
      setTotalOrders(totalData);

      if (data) {
        setRowsId(formatedRowsId);
        handleSetOrdersId(formatedRowsId);
        setOrders(formatedData);
        setAllData(formatedAllData);
      }
    } finally {
      toogleLoading();
    }
  };

  async function changePage(page: number) {
    try {
      toogleLoading();
      await getAllOrders(page);
    } finally {
      toogleLoading();
    }
  }

  const toggleGetOrderModal = () => {
    setIsGetOrderModal((prev) => !prev);
  };

  const successModal = () => {
    setIsModalRegisterOpen(false);
    setIsGetOrderModal(false);
    getAllOrders();
  };

  const actionModalData = [
    {
      ButtonsText: 'Retirada pelo morador',
      OnClick: () => toggleGetOrderModal()
    },

    {
      ButtonsText: 'Informações',
      OnClick: () => toggleInfoModal()
    }
  ];

  async function handleSearch(value: string) {
    toogleLoading();
    try {
      setSearchString(value);
      const { data } = await searchOrdersRequest(value, String(currentPage), field, direction);

      const { totalOrders: totalData, totalPages: pages } = data;

      setAllData(data.data);

      const formatedData = data.data.map((item: any) => {
        return {
          id: item.id,
          recipient: item.recipient,
          sender: item.sender,
          unit: item.unit,
          block: item.block,
          createdAt: item.created_at,
          status: item.status
        };
      });

      const formatedAllData = data.data.map((item: any) => {
        return {
          recipient: item.recipient,
          sender: item.sender,
          unit: item.unit,
          block: item.block,
          createdAt: item.created_at,
          status: item.status,
          receivedBy: item.receivedBy,
          updatedAt: item.updated_at
        };
      });

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      setTotalPages(pages);
      setTotalOrders(totalData);

      if (data) {
        setRowsId(formatedRowsId);
        handleSetOrdersId(formatedRowsId);
        setOrders(formatedData);
        setAllData(formatedAllData);
      }
    } finally {
      toogleLoading();
    }
  }

  const tableHeaders = [
    'Destinatário',
    'Remetente',
    'Unidade',
    'Bloco',
    'Data de chegada',
    'Status',
    'Ações'
  ];

  const toggleRegisterModal = () => {
    if (isGetOrderModal) {
      toggleGetOrderModal();
      return;
    }
    setVariant('registrada');
    setIsModalRegisterOpen((prev) => !prev);
  };

  async function sortDataFormated(
    page: string,
    sortByField: string,
    sortByDirection: string
  ): Promise<any> {
    toogleLoading();

    try {
      const { data } = await getAllOrdersSortRequest(
        page,
        sortByField,
        sortByDirection,
        searchString
      );

      const { totalOrders: totalData, totalPages: pages } = data;

      const formatedData = data.data.map((item: any) => {
        return {
          id: item.id,
          recipient: item.recipient,
          sender: item.sender,
          unit: item.unit,
          block: item.block,
          createdAt: item.created_at,
          status: item.status
        };
      });

      return { data: { data: formatedData, totalData, pages } };
    } finally {
      toogleLoading();
    }
  }

  useEffect(() => {
    getAllOrders();
    handleSetSortFields([
      'recipient',
      'sender',
      'unit_model.name',
      'block_model.name',
      'created_at',
      'status'
    ]);
  }, [variantInfor]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    tableHeaders,
    actionModalData,
    rowsId,
    isOpenModalInfo,
    totalPages,
    totalOrders,
    handleDrawerOpen,
    changePage,
    isModalRegisterOpen,
    toggleRegisterModal,
    successModal,
    orders,
    variant,
    isSuccessfully,
    isGetOrderModal,
    toggleGetOrderModal,
    toggleInfoModal,
    indexOfRow,
    allData,
    handleSetOrderId,
    handleSearch,
    isOpenBackToLastPageModal,
    isOpenInfoModal,
    handleModalOpen,
    text,
    sortDataFormated
  };
}

export default useOrdersController;
