import { useEffect, useState } from 'react';
import {
  useActionModalStore,
  useEnterpriseStore,
  useInfoModalStore,
  useLoadModalStore,
  usePaginationStore,
  useSortItemsStore,
  useVisitorsStore
} from '../../store';
import { SearchVisitors } from '../../services/requests/visitors/Visitors.dto';
import { Errors } from '../../constants/Errors';

function useVisitorsController() {
  const [rowsId, setRowsId] = useState(['']);
  const [totalPages, setTotalPages] = useState(0);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [visitors, setVisitors] = useState([] as any);
  const [isRevoke, setIsRevoke] = useState(false);
  const [isAuthorize, setIsAuthorize] = useState(false);
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { handleSetSortFields } = useSortItemsStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const { id, handleSetEnterpriseId } = useEnterpriseStore();
  const [searchString, setSearchString] = useState('');

  const [totalVisitors, setTotalVisitors] = useState(0);
  const {
    variantInfor,
    direction,
    field,
    getAllVisitorsSortRequest,
    handleSetVisitorsId,
    searchVisitorsRequest,
    changeStatusVisitors
  } = useVisitorsStore();

  const {
    isOpenInfoModal,
    text,
    isSuccessfully,
    handleModalOpen,
    handleSetIsSuccessfully,
    handleSetText
  } = useInfoModalStore();

  const toggleConfirmRevoke = () => {
    setIsRevoke((prev) => !prev);
  };

  const toggleConfirmAuthorize = () => {
    setIsAuthorize((prev) => !prev);
  };

  async function sortPage(page: string, sortByField: string, sortByDirection: string) {
    toogleLoading();

    try {
      const { data } = await getAllVisitorsSortRequest(
        page,
        sortByField,
        sortByDirection,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      setTotalPages(data.totalPages || 0);
      setTotalVisitors(data.totalVisitors || 0);

      if (data) {
        setRowsId(formatedRowsId);
        handleSetVisitorsId(formatedRowsId);
        setVisitors(formatedData);
      }
    } finally {
      toogleLoading();
    }
  }

  async function changePage(page: number) {
    toogleLoading();

    try {
      const { data } = await getAllVisitorsSortRequest(
        String(page),
        field,
        direction,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: any) => {
        return item.id;
      });

      setTotalPages(data.totalPages || 0);
      setTotalVisitors(data.totalVisitors || 0);

      if (data) {
        setRowsId(formatedRowsId);
        handleSetVisitorsId(formatedRowsId);
        setVisitors(formatedData);
      }
    } finally {
      toogleLoading();
    }
  }

  const getAllVisitors = async () => {
    toogleLoading();

    try {
      const { data } = await getAllVisitorsSortRequest(
        String(currentPage),
        field,
        direction,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: any) => {
        return item.id;
      });

      setTotalPages(data.totalPages || 0);
      setTotalVisitors(data.totalVisitors || 0);

      if (data) {
        setRowsId(formatedRowsId);
        handleSetVisitorsId(formatedRowsId);
        setVisitors(formatedData);
      }
    } finally {
      toogleLoading();
    }
  };

  const actionModalData = [
    {
      ButtonsText: 'Autorizar',
      OnClick: () => {
        toggleConfirmAuthorize();
      }
    },

    {
      ButtonsText: 'Revogar',
      OnClick: () => {
        toggleConfirmRevoke();
      }
    }
  ];

  async function handleSearch(value: string) {
    const formatedDataForSearch: SearchVisitors = { queryText: value };
    setSearchString(value);

    const data: any = await searchVisitorsRequest(formatedDataForSearch, '1', field, direction);
    const formatedData = data.data;

    const formatedRowsId = data.data.map((item: any) => {
      return item.id;
    });

    setTotalPages(data.totalPages || 0);
    setTotalVisitors(data.totalVisitors || 0);

    if (data) {
      setRowsId(formatedRowsId);
      handleSetVisitorsId(formatedRowsId);
      setVisitors(formatedData);
    }
  }

  const successModal = () => {
    setIsSuccessModalOpen(true);
    setIsRevoke(false);
    setIsAuthorize(false);
    if (isSuccessModalOpen === true) {
      getAllVisitors();
    }
  };

  const onCompleteGrant = async () => {
    try {
      await changeStatusVisitors(id, 'autorizado');
      handleSetText('Visitante autorizado com sucesso!');
      handleSetIsSuccessfully(true);
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
    } finally {
      handleModalOpen();
      successModal();
    }
  };

  const onCompleteRevoke = async () => {
    try {
      await changeStatusVisitors(id, 'revogado');
      handleSetText('Visitante revogado com sucesso!');
      handleSetIsSuccessfully(true);
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(true);
    } finally {
      handleModalOpen();
      successModal();
    }
  };

  function setSetSortFields() {
    handleSetSortFields([
      'visitor_model.name',
      'unit_model.name',
      'block_model.name',
      'visitor_model.document',
      'visitor_model.cell_phone',
      'visitor_model.inicial_date',
      'visitor_model.type',
      'visitor_model.status'
    ]);
  }

  useEffect(() => {
    getAllVisitors();
    setSetSortFields();
  }, [variantInfor]);

  useEffect(() => {
    getAllVisitors();
  }, [isSuccessModalOpen]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  const tableHeaders = [
    'Nome do visitante',
    'Unidade',
    'Bloco',
    'Documento',
    'Celular',
    'Data da Visita',
    'Tipo de visitante',
    'Status',
    'Ações'
  ];

  return {
    tableHeaders,
    actionModalData,
    rowsId,
    totalPages,
    totalVisitors,
    handleDrawerOpen,
    changePage,
    handleSearch,
    handleSetEnterpriseId,
    visitors,
    isAuthorize,
    isOpenInfoModal,
    text,
    isSuccessfully,
    isRevoke,
    handleModalOpen,
    toggleConfirmAuthorize,
    toggleConfirmRevoke,
    onCompleteGrant,
    onCompleteRevoke,
    sortPage
  };
}

export default useVisitorsController;
