import styled from 'styled-components';

const Background = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  padding-inline: 32px;

  span {
    font-size: 15px;
    color: #7d7d7d;
  }

  .optionsBox {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-top: 16px;
  }

  @media (max-width: 1100px) {
    .optionsBox {
      flex-direction: column;
    }
  }
`;

export default Background;
