import styled from 'styled-components';

const Background = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;

  .mobilePreviewBackground {
    display: flex;
    flex-direction: column;
    width: 900px;
    background-color: #fff;
    border-radius: 12px;
    padding: 10px 20px 20px 20px;
  }
  .closeIcon {
    font-size: 30px;
    color: rgba(155, 155, 155, 0.9);
  }
  .closeButton {
    background-color: transparent;
    border: none;
    box-shadow: none;
    display: flex;
    align-items: right;
    justify-content: right;
    height: 30px;
    padding: 6px 0px;
  }
  .closeButton:hover {
    box-shadow: none;
    background-color: transparent;
    opacity: 0.9;
  }
  .mobilePreviewContainer {
    background-color: #ededed;
    width: 240px;
    height: 505px;
    color: #fff;
    border-radius: 12px;
    border: none;
    position: relative;
    overflow: hidden;
    box-shadow: -2px 5px 14px -4px rgba(0, 0, 0, 0.3);
  }
  .pageHeader {
    background: linear-gradient(90deg, #662483 -0.25%, #2c264c 100.7%);
    border-radius: 12px 12px 6px 6px;
    height: 70%;
  }
  .header {
    display: flex;
    justify-content: space-around;
    padding: 20px 2px 2px 2px;

    div {
      text-align: center;

      p {
        font-size: 10px;
      }

      p + p {
        font-size: 12px;
      }
    }
  }

  .logo {
    margin-top: -5px;
    max-width: 120px;
    max-height: 50px;

    svg {
      max-width: 100%;
      margin-top: -15px;
    }
  }

  .enterpriseLogo {
    max-width: 120px;
    max-height: 46px;
  }

  .InfoCards {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 110px;
    gap: 10px;
  }
  .pageContent {
    padding: 12px;
    align-items: center;
    flex-direction: column;
  }
  .divisor {
    text-align: center;
    color: grey;
    display: flex;
    justify-content: center;
    margin-top: -5px;

    p {
      font-size: 12px;
      text-align: center;
    }
  }

  .voteCard {
    display: flex;
    justify-content: center;
  }

  .notificationCard {
    justify-content: space-around;
    flex-direction: row;
    display: flex;
    align-items: center;

    background: #fff;
    border-radius: 12px;
    height: 40px;
    margin-top: 5px;
    p {
      font-size: 10px;
      color: #000;
    }
    svg {
      color: #099ad7;
      font-size: 12px;
    }
  }

  .alert {
    width: 16px;
    height: 16px;
  }
  .voteCard {
    margin-top: 5px;
    justify-content: space-around;
    left: 15px;
    right: 15px;
    padding: 10px 12px 10px 12px;
    bottom: 43px;
    background: red;
    border-radius: 12px;

    background: #fff;
    border-radius: 12px;
    height: 40px;
    p {
      font-size: 10px;
      color: #000;
    }
  }
  .pageBaseboard {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0px 0px 12px 12px;
    background-color: #fff;
  }
  .appCustomization {
    display: flex;
    gap: 30px;
  }
  @media (max-width: 970px) {
    .mobilePreviewBackground {
      width: 800px;
    }
    .mobilePreviewContainer {
      width: 350px;
    }
    .pageBaseboard {
      width: 100%;
    }
  }
  @media (max-width: 850px) {
    .mobilePreviewBackground {
      width: 750px;
    }
  }
  @media (max-width: 770px) {
    .mobilePreviewBackground {
      width: 650px;
    }
    .mobilePreviewContainer {
      width: 450px;
    }
  }
  @media (max-width: 700px) {
    .mobilePreviewBackground {
      width: 360px;
      background-color: transparent;
    }
    .customizationFieldsComponent {
      display: none;
    }
    .mobilePreviewContainer {
      width: 280px;
    }
    svg {
      color: #000;
    }
  }
`;

export default Background;
