/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Grid, Typography, Button } from '@mui/material';
import { useUnitsController } from './Maintenance.controller';
import {
  ActionModal,
  DeleteModal,
  CustomSearchInput,
  CustomTable,
  InfoModal
} from '../../components';
import { FinishMaintenanceModal } from './Components/FinishMaintenanceModal/FinishMaintenanceModal';
import { InProgressMaintenanceModal } from './Components/InProgressMaintenanceModal/InProgressMaintenanceModal';
import { DetailsModal } from './Components/InfoModal/detailsModal';
import Container from './Maintenance.style';
import PreviewImage from './Components/PreviewImage/PreviewImage';

const detaisIcon = require('../../assets/images/details.svg');
const editIcon = require('../../assets/images/enterpriseactionedit.svg');
const inProgressIcon = require('../../assets/images/inProgress.svg');
const finishIcon = require('../../assets/images/orderCheck.svg');
const trashIcon = require('../../assets/images/enterpriseactiontrash.svg');

const allIcons = [
  <detaisIcon.ReactComponent className="actionIcon" />,
  <editIcon.ReactComponent className="actionIcon" />,
  <inProgressIcon.ReactComponent className="actionIcon" />,
  <finishIcon.ReactComponent className="actionIcon" />,
  <trashIcon.ReactComponent className="actionIcon" />
];

export function Maintenance() {
  const {
    maintenanceTableHead,
    maintenancesIDs,
    maintenancesList,
    totalMaintenances,
    totalPages,
    isOpenDeleteModal,
    isOpenImagePreview,
    isInfoModalOpen,
    isOpenInfoModal,
    isSuccessfully,
    text,
    isFinishModalOpen,
    isMaintenanceInProgressModalOpen,
    deleteModalText,
    setMaintenanceID,
    sortMaintenance,
    handleActionModalData,
    handleDeleteModalOpen,
    handleOpenFinishModal,
    handleModalOpen,
    startMaintenance,
    handleOpenInfoModal,
    finishMaintenance,
    onSubmitDelete,
    handleOpenInProgressModal,
    handleSearch,
    changePage,
    handleDrawerOpen,
    navigateToRegisterMaintenance
  } = useUnitsController();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Manutenções
          </Typography>
          <CustomSearchInput handleSearch={handleSearch} id="maintenance_search" />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={3} alignSelf="flex-end">
          <Button
            onClick={navigateToRegisterMaintenance}
            id="maintenance_registerButton"
            className="registerButton"
            fullWidth>
            Cadastrar Manutenção
          </Button>
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            rowsId={maintenancesIDs}
            tableHead={maintenanceTableHead}
            tableData={maintenancesList || []}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalMaintenances}
            funcToChangePage={changePage}
            handleSetItemId={setMaintenanceID}
            noFilesFoundText="Não há nenhum registro de manutenção no momento."
            sortFunc={sortMaintenance}
            containId
            renderAction={(row: any) => {
              return (
                <ActionModal ActionModalData={handleActionModalData(row)} ButtonsIcon={allIcons} />
              );
            }}
          />
        </Grid>
      </Grid>

      {isOpenDeleteModal && (
        <DeleteModal
          title={deleteModalText}
          onClickDelete={onSubmitDelete}
          handleModalClose={handleDeleteModalOpen}
        />
      )}

      {isFinishModalOpen && (
        <FinishMaintenanceModal
          handleChangeStatus={finishMaintenance}
          isModalOpen={isFinishModalOpen}
          toggleModal={handleOpenFinishModal}
        />
      )}

      {isMaintenanceInProgressModalOpen && (
        <InProgressMaintenanceModal
          handleChangeStatus={startMaintenance}
          isModalOpen={isMaintenanceInProgressModalOpen}
          toggleModal={handleOpenInProgressModal}
        />
      )}

      {isInfoModalOpen && (
        <DetailsModal
          isModalOpen={isInfoModalOpen}
          toggleModal={handleOpenInfoModal}
          finishMaintenance={handleOpenFinishModal}
        />
      )}

      {isOpenInfoModal && (
        <InfoModal onClick={handleModalOpen} text={text} isSuccessfully={isSuccessfully} />
      )}

      {isOpenImagePreview && <PreviewImage />}
    </Container>
  );
}
