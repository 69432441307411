import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 113px;
  padding: 8px 22px;
  display: flex;
  align-items: center;
  background: #f4f4ff;
  border-radius: 6px;

  .leftLine {
    background-color: #662483;
    width: 3px;
    height: 100%;
    position: absolute;
  }

  .dateBox {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 15px;
  }

  .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #8b8b8b;
    margin-left: 20px;
  }

  .time {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2c2c2c;
  }

  .timeText {
    width: 57px;
  }
`;

export default Container;
