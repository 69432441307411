import styled from 'styled-components';

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;

  .container {
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    width: 512px;
    height: fit-content;
    background: #ffffff;
    border-radius: 6px;
    position: relative;
  }

  .closeModal {
    position: absolute;
    right: 8px;
    cursor: pointer;
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #2c2c2c;
    margin-bottom: 25px;
  }

  .timeInput {
    flex: 1;
    min-width: 100%;

    input {
      height: 11px;
    }

    fieldset {
      border-radius: 14px;
      border: 1px solid #8b8b8b;
    }
  }

  .buttonsContainer {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    justify-content: space-between;
  }

  .subTitle {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: #2c2c2c;
    margin-bottom: 10px;
  }

  .saveButton {
    padding: 18px 0px;
    gap: 12px;
    width: 210px;
    height: 50px;
    background: #662483;
    color: #ffffff;
    border-radius: 12px;
    text-transform: none;
    font-size: 16px;
  }

  .saveButton:hover {
    background: #662483;
    color: #ffffff;
    opacity: 0.9;
  }

  .backButton {
    padding: 18px 0px;
    gap: 12px;
    width: 210px;
    height: 50px;
    background: #f0f1f5;
    color: #2c2c2c;
    border-radius: 12px;
    text-transform: none;
    font-size: 16px;
  }

  .errorMessage {
    height: fit-content;
    color: red;
    font-size: 13px;
    margin-top: 8px;
  }
`;

export default Background;
