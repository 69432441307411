/* eslint-disable no-underscore-dangle */
import { instance } from '../../api';
import sumTotalFilesSize from '../../../helpers/sumTotalFilesSize';
import { formatData } from '../../../helpers/formatData';
import { formatFileSize } from '../../../helpers/formatFileSize';

import {
  IAllPaymentSlipDtoOutput,
  IPaymentFolderRequestData,
  ISearchPaymentsData,
  IRegisterEditPaymentFolder,
  IAllPaymentSlipFileDtoOutput,
  IPaymentSlipFileData,
  IRegisterPaymentFiles,
  IEditPaymentFiles,
  IPaymentSlipById,
  IPaymentFolderByIDRequestData,
  IPaymentFolderByID
} from './paymentSlip.dto';

const baseURLStorage = process.env.REACT_APP_STORAGE_URL;

// Folder requests

export const registerPaymentFolder = async (
  folderData: IRegisterEditPaymentFolder
): Promise<void> => {
  try {
    await instance.request({
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: 'unit-folder/create',
      baseURL: baseURLStorage,
      data: folderData
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editPaymentFolder = async (
  paymentSlipData: IRegisterEditPaymentFolder,
  id: string
): Promise<void> => {
  try {
    await instance.request({
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: `unit-folder/update/${id}`,
      baseURL: baseURLStorage,
      data: paymentSlipData
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deletePaymentFolder = async (id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'DELETE',
      url: `unit-folder/delete/${id}`,
      baseURL: baseURLStorage
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getPaymentFolderById = async (paymentSlipId?: string): Promise<IPaymentFolderByID> => {
  const id = paymentSlipId || window.location.href.split('/').pop();

  try {
    const { data }: IPaymentFolderByIDRequestData = await instance.request({
      method: 'GET',
      url: `unit-folder/get-unit-folder-by-id/${id}`,
      baseURL: baseURLStorage
    });

    const formatedData: IPaymentFolderByID = {
      id: data.id,
      name: data.name,
      unitId: data.unit.id
    };

    return formatedData;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortPaymentFolderData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IAllPaymentSlipDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: 'unit-folder/list-folders-by-enterprise',
      baseURL: baseURLStorage,
      params: {
        page,
        includeMeta: true,
        sortDirection: sortByDirection || 'DESC',
        sortBy: sortByField || 'unit_folder_model.createdAt',
        search: searchString
      }
    });

    const formatedData = data.data.map((folderData: IPaymentFolderRequestData) => {
      return {
        id: folderData.id,
        name: folderData.name,
        unitName: folderData.unit.name,
        blockName: folderData.unit.block.name,
        size: sumTotalFilesSize(folderData.receivables),
        type: 'Pasta'
      };
    });

    const totalFolders = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalItems: totalFolders, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchPaymentFolder = async (
  paymentSlipData: ISearchPaymentsData,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IAllPaymentSlipDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: 'unit-folder/list-folders-by-enterprise',
      baseURL: baseURLStorage,
      params: {
        includeMeta: true,
        search: paymentSlipData.queryText,
        page,
        sortDirection: sortByDirection || 'DESC',
        sortBy: sortByField || 'unit_folder_model.createdAt'
      }
    });

    const formatedData = data.data.map((folderData: IPaymentFolderRequestData) => {
      return {
        id: folderData.id,
        name: folderData.name,
        unitName: folderData.unit.name,
        blockName: folderData.unit.block.name,
        size: sumTotalFilesSize(folderData.receivables),
        type: 'Pasta'
      };
    });

    const totalFolders = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalItems: totalFolders, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

// Files requests

export const registerPaymentFile = async (folderData: IRegisterPaymentFiles): Promise<void> => {
  try {
    await instance.request({
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: 'receivable',
      baseURL: baseURLStorage,
      data: folderData
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editPaymentFile = async (
  paymentSlipData: IEditPaymentFiles,
  id: string
): Promise<void> => {
  try {
    await instance.request({
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: `receivable/${id}`,
      baseURL: baseURLStorage,
      data: paymentSlipData
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deletePaymentFile = async (id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'DELETE',
      url: `receivable/${id}`,
      baseURL: baseURLStorage
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const confirmPayment = async (id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'PATCH',
      url: `receivable/paid/${id}`,
      baseURL: baseURLStorage
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const unconfirmPayment = async (id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'PATCH',
      url: `receivable/pendent/${id}`,
      baseURL: baseURLStorage
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deletePaymentFileFromStore = async (id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'DELETE',
      url: `receivable/delete/${id}`,
      baseURL: baseURLStorage
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getPaymentFileById = async (paymentSlipId?: string): Promise<IPaymentSlipById> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `receivable/item/${paymentSlipId}`,
      baseURL: baseURLStorage
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortPaymentFileData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string,
  paymentFileId?: string
): Promise<IAllPaymentSlipFileDtoOutput> => {
  try {
    const id = paymentFileId || window.location.href.split('/').pop();
    const { data } = await instance.request({
      method: 'GET',
      url: `receivable/${id}`,
      baseURL: baseURLStorage,
      params: {
        page,
        includeMeta: true,
        sortDirection: sortByDirection || 'DESC',
        sortBy: sortByField || 'status',
        search: searchString
      }
    });

    const formatedData = data.data.map((paymentSlip: IPaymentSlipFileData) => {
      return {
        id: paymentSlip.id,
        fileName: paymentSlip.name,
        date: formatData(paymentSlip.date),
        size: formatFileSize(paymentSlip.size),
        url: paymentSlip.url,
        folderId: paymentSlip.unitFolder.id,
        folderName: paymentSlip.unitFolder.name,
        status: paymentSlip.status.toLowerCase() || 'pending'
      };
    });

    const totalFolders = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 1;

    return {
      data: { data: formatedData, totalItems: totalFolders, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchPaymentFile = async (
  paymentSlipData: ISearchPaymentsData
): Promise<IAllPaymentSlipFileDtoOutput> => {
  try {
    const id = window.location.href.split('/').pop();
    const { data } = await instance.request({
      method: 'GET',
      url: `receivable/${id}`,
      baseURL: baseURLStorage,
      params: {
        includeMeta: true,
        search: paymentSlipData.queryText,
        sortDirection: 'DESC',
        sortBy: 'status'
      }
    });

    const formatedData = data.data.map((paymentSlip: IPaymentSlipFileData) => {
      return {
        id: paymentSlip.id,
        fileName: paymentSlip.name,
        date: formatData(paymentSlip.date),
        size: formatFileSize(paymentSlip.size),
        url: paymentSlip.url,
        folderId: paymentSlip.unitFolder.id,
        folderName: paymentSlip.unitFolder.name,
        status: paymentSlip.status.toLowerCase() || 'pendent'
      };
    });

    const totalFolders = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 1;

    return {
      data: { data: formatedData, totalItems: totalFolders, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
