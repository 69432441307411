/* eslint-disable react/jsx-props-no-spreading */
import { Box, Modal as ModalComponent, CardMedia } from '@mui/material';

import { ModalProps } from './ModalInfo.props';
import {
  ModalContent,
  ModalTitle,
  ModalButtonArea,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalBackButton,
  ModalInfoArea,
  ModalInfoTitle,
  ModalInfoSubtitle,
  ModalInfoAreaRow
} from './ModalInfo.style';

import infoUser from '../../../assets/images/infoUser.svg';
import infoHand from '../../../assets/images/infoHand.svg';
import infoData from '../../../assets/images/infoData.svg';
import phoneIcon from '../../../assets/images/phoneIcon.svg';
import usersIcon from '../../../assets/images/usersIcon.svg';
import warningIcon from '../../../assets/images/warningIcon.svg';
import { useModalInfoController } from './ModalInfo.controller';

export function ModalInfo({
  isModalOpen,
  toggleModal,
  onClickConfirm,
  handleAprove,
  handleReprove
}: ModalProps) {
  const {
    resident,
    owner,
    date,
    name,
    description,
    phone,
    itemStatus,
    changeBackStatus,
    changeConfirmStatus,
    signedimageLostItemsUrl,
    handleClickConfirm,
    handleClickBackButton
  } = useModalInfoController(onClickConfirm, handleAprove, handleReprove, toggleModal);

  const hasSignedImage = signedimageLostItemsUrl;
  const hasConfirmationStatus = changeConfirmStatus.length > 0;
  const hasBackStatus = changeBackStatus.length > 0;
  const isWithdrawn = itemStatus === 'WITHDRAWN';
  const isWaiting = itemStatus === 'WAITING';

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <ModalContent>
        <ModalCloseButton
          onClick={toggleModal}
          style={{
            position: 'absolute',
            right: 10,
            top: 10
          }}>
          <ModalCloseButtonIcon />
        </ModalCloseButton>

        <ModalTitle>Informações Achados e Perdidos</ModalTitle>

        <ModalInfoArea>
          <Box display="flex" marginBottom="16px">
            <img src={infoUser} alt="infoPerson" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Encontrado por</ModalInfoTitle>
              <ModalInfoSubtitle>{resident}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          {(isWaiting || isWithdrawn) && owner && (
            <Box display="flex" marginBottom="16px">
              <img src={infoHand} alt="infoPerson" />

              <ModalInfoAreaRow>
                <ModalInfoTitle>{isWaiting ? 'A retirar por' : 'Retirado por'}</ModalInfoTitle>
                <ModalInfoSubtitle>{owner}</ModalInfoSubtitle>
              </ModalInfoAreaRow>
            </Box>
          )}

          <Box display="flex" marginBottom="16px">
            <img src={warningIcon} alt="infoLocation" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Objeto</ModalInfoTitle>
              <ModalInfoSubtitle>{name}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={infoData} alt="infoData" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Data</ModalInfoTitle>
              <ModalInfoSubtitle>{date}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={usersIcon} alt="infoBox" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Descrição</ModalInfoTitle>
              <ModalInfoSubtitle>{description}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={phoneIcon} alt="infoBox" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Telefone do guardião</ModalInfoTitle>
              <ModalInfoSubtitle>{phone}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          {hasSignedImage && (
            <>
              <Box>
                <ModalInfoTitle style={{ marginLeft: '10px' }}>Fotos</ModalInfoTitle>
              </Box>
              <Box className="itemImage">
                <CardMedia component="img" image={signedimageLostItemsUrl} alt="Imagem do item" />
              </Box>
            </>
          )}
        </ModalInfoArea>

        <ModalButtonArea>
          {(hasConfirmationStatus || isWithdrawn) && (
            <ModalBackButton
              style={{
                backgroundColor: !isWithdrawn ? '#662483' : '#8b8b8b',
                color: '#fff',
                width: '65%',
                height: 44,
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 500,
                marginBottom: '10px',
                textTransform: 'capitalize'
              }}
              disabled={isWithdrawn}
              onClick={handleClickConfirm}>
              {changeConfirmStatus || 'Retirado pelo Dono'}
            </ModalBackButton>
          )}
          <ModalBackButton
            style={{
              backgroundColor: '#F0F1F5',
              color: '#2C2C2C',
              width: '65%',
              height: 44,
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: 500,
              textTransform: 'capitalize'
            }}
            variant="contained"
            disableElevation
            onClick={handleClickBackButton}>
            {hasBackStatus ? changeBackStatus : 'Voltar'}
          </ModalBackButton>
        </ModalButtonArea>
      </ModalContent>
    </ModalComponent>
  );
}
