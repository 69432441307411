import styled from 'styled-components';
import { TableBody } from '@mui/material';

export const StyledTableBody = styled(TableBody)`
  .tableRow {
    transition: all ease-in-out 100ms;
    height: 20px;
  }

  .tableRow:hover {
    background: #f4f4ff;
  }

  .tableCell {
    max-width: 220px;
    min-width: 220px;
    overflow-wrap: break-word;
  }
`;
