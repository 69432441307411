import styled from 'styled-components';
import { CloseRounded } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

export const ModalContent = styled.div`
  background: #fff;
  border: none;
  outline: none;
  width: 499px;
  padding: 24px 56px;
  max-height: 830px;

  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  justify-content: center;
  align-items: center;
  z-index: 4;

  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #662483;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 10px;
  }

  .statusBox {
    padding: 8px 12px;
    gap: 10px;
    width: 124px;
    height: 31px;
    background: #662483;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .descriptionContainer {
    max-width: 250px;
    word-wrap: break-word;
    max-height: 300px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f0f0f0;
    }

    ::-webkit-scrollbar-thumb {
      background: #662483;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .statusText {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    color: #ffffff;
  }

  .fileContainer {
    margin-top: 15px;
    padding: 20px 40px;
    border: 1px dashed rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 30px;
    max-width: 339px;
    max-height: 220px;
    overflow-y: auto;
    flex-wrap: wrap;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f0f0f0;
    }

    ::-webkit-scrollbar-thumb {
      background: #662483;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    img {
      height: 80px;
      width: 140px;
      cursor: pointer;
    }
  }

  .imageContainer {
    position: relative;
  }

  .pdfContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #e9e9e9;
    cursor: pointer;
    height: 80px;
    cursor: pointer;

    svg {
      width: 140px;
      height: 35px;
    }
  }

  .imageDescription {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0px;
    font-size: 12px;
    color: #777777;
    font-weight: 600;
    background: #f5f5f5;
    width: 140px;
    padding: 1px 3px;
    border: 0.312526px solid #c4c4c4;
    border-top: none;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .itemImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 83%;
    border-radius: 13px;
    margin: 10px 0px 20px;
    img {
      border-radius: 13px;
      height: 140px;
      width: 330px;
    }
  }

  @media (max-height: 899px) {
    max-height: 730px;
  }

  @media (max-height: 740px) {
    max-height: 600px;
  }

  @media (max-height: 640px) {
    max-height: 500px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;
  }
`;

export const ModalCloseButton = styled(IconButton)``;

export const ModalCloseButtonIcon = styled(CloseRounded)``;

export const ModalTitle = styled.p`
  font-family: 'Inter';
  text-align: center;
  margin-top: 30px;
  margin-bottom: 2rem;

  font-size: 24px;
  font-weight: 600;
`;

export const ModalInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 24px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }

  ::-webkit-scrollbar-thumb {
    background: #662483;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  img {
    width: 46px;
    height: 46px;
  }
`;

export const ModalInfoAreaRow = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 12px;
`;

export const ModalInfoTitle = styled.p`
  color: #2c2c2c;

  font-size: 18px;
  font-weight: 500;
  font-family: 'Inter';
`;

interface IModalInfoSubtitleProps {
  pendingStatus?: boolean;
}

export const ModalInfoSubtitle = styled.p<IModalInfoSubtitleProps>`
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Inter';
`;

export const ModalButtonArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding-inline: 24px;
`;

export const ModalBackButton = styled(Button)``;
