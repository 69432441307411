/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */

import { Button, TableRow, TableCell } from '@mui/material';
import {
  ChromeReaderModeOutlined,
  DeleteOutline,
  EditOutlined,
  MoreVertOutlined
} from '@mui/icons-material';
import OutsideClickHandler from 'react-outside-click-handler';
import { Permissions } from '../Permissions/Permissions';
import { ActionModal } from '../../../../components';
import { ITableBodyProps } from './TableBody.props';
import { StyledTableBody } from './TableBody.styled';

export function CustomTableBody(props: ITableBodyProps) {
  const {
    tableData,
    rowsId,
    indexOfRow,
    isShowMore,
    actionModalData,
    isOpenActionModal,
    handleClickOnActionBtn,
    handleDrawerOpen,
    handleSetJobsRoleId,
    handleShowMorePermissions
  } = props;
  return (
    <StyledTableBody>
      {tableData.map((data, index) => (
        <TableRow
          key={rowsId[index]}
          className="tableRow"
          style={{
            height: isShowMore && index === indexOfRow ? 100 : 56
          }}>
          <TableCell
            key={data.name}
            className="tableCell"
            style={{
              verticalAlign: isShowMore && index === indexOfRow ? 'top' : 'inherit'
            }}>
            {data.name}
          </TableCell>
          <TableCell>
            <Permissions
              data={data}
              index={index}
              indexOfRow={indexOfRow}
              isShowMore={isShowMore}
              handleShowMorePermissions={handleShowMorePermissions}
            />
          </TableCell>
          <TableCell
            className="actions-table-cell"
            style={{
              position: 'relative',
              verticalAlign: isShowMore && index === indexOfRow ? 'top' : 'inherit'
            }}>
            <Button
              className="actions-table-button"
              id={rowsId[index]}
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                handleClickOnActionBtn(e, handleDrawerOpen, index, handleSetJobsRoleId)
              }>
              <MoreVertOutlined id={rowsId[index]} />
            </Button>

            {isOpenActionModal && Number(index) === indexOfRow && (
              <OutsideClickHandler onOutsideClick={handleDrawerOpen}>
                <ActionModal
                  ActionModalData={actionModalData}
                  ButtonsIcon={[<EditOutlined />, <ChromeReaderModeOutlined />, <DeleteOutline />]}
                />
              </OutsideClickHandler>
            )}
          </TableCell>
        </TableRow>
      ))}
    </StyledTableBody>
  );
}
