import styled from 'styled-components';

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  .Container {
    width: 1000px;
    height: 500px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg,
  path {
    cursor: pointer;
    stroke: rgba(155, 155, 155, 0.8);
    fill: rgba(155, 155, 155, 0.8);
    z-index: 100;
  }

  .imageContainer {
    width: fit-content;
    height: 100%;
    position: relative;
  }

  .closeButton {
    z-index: 2;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px 10px 5px 10px;
    border-radius: 100%;
    background-color: #fff;
    cursor: pointer;
  }

  .closeButton:hover {
    opacity: 0.9;
  }
`;

export default Background;
