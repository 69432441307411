/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-useless-escape */
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import {
  useInfoModalStore,
  useLoadModalStore,
  useUnitsStore,
  useBlocksStore
} from '../../../../store';
import { Errors } from '../../../../constants/Errors';
import { useUnitsController } from '../../Units.controller';

export const useRegisterModalController = (
  toogleModal: () => void,
  handleSetText: (text: string) => void,
  handleSuccessfully: (isSuccessfully: boolean) => void
) => {
  const { handleModalOpen } = useInfoModalStore();
  const { toogleLoading } = useLoadModalStore();
  const {
    isOpenRegisterModal,
    unitsList: unitsListOnDB,
    setOpenRegisterModal,
    registerUnitRequest
  } = useUnitsStore();
  const { blockID } = useBlocksStore();

  const { getAllData } = useUnitsController();

  const [allUnits, setAllUnits]: any = useState();
  const [unitsList, setUnitsList] = useState<string[]>();

  const schema = yup.object().shape({
    unit_name: yup
      .string()
      .matches(
        /^[\d]+([A-Za-z]+)?$/g,
        'É permitido apenas letras e números, sendo obrigatório começar com um número e sem caracteres especiais para nome da Unidade.'
      )
      .max(5, 'São permitidos apenas até 5 caracteres.')
      .required('Por favor, preencha o campo de unidade.')
      .trim()
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      unit_name: ''
    },
    resolver: yupResolver(schema)
  });

  function unitNameAlreadyExistsOnList(unit_name: string) {
    const unitAlreadyExistOnList: boolean = allUnits
      ? allUnits.some((unit: { unit_name: string }) => {
          return unit.unit_name.toLowerCase() === unit_name.toLowerCase();
        })
      : false;

    const error = unitAlreadyExistOnList;
    if (error) toast.error('Você já adicionou uma Unidade com esse nome!');
    return error;
  }

  function unitNameAlreadyExistsOnDatabase(unit_name: string) {
    const unitAlreadyExistOnDB = unitsListOnDB
      ? unitsListOnDB.some((unit: { name: string }) => {
          return unit.name.toLowerCase() === unit_name.toLowerCase();
        })
      : false;

    const error = unitAlreadyExistOnDB;
    if (error) toast.error('Já existe uma Unidade com esse nome cadastrada!');
    return error;
  }

  function handleSetUnit() {
    const { unit_name } = getValues();
    const unitName = unit_name.toUpperCase();

    if (unitNameAlreadyExistsOnList(unit_name) || unitNameAlreadyExistsOnDatabase(unit_name))
      return;

    unitsList ? setUnitsList([...unitsList, unitName]) : setUnitsList([unitName]);

    allUnits
      ? setAllUnits([{ unit_name: unitName }, ...allUnits])
      : setAllUnits([{ unit_name: unitName }]);

    setValue('unit_name', '');
  }

  function handleRemoveUnit(unit_name: string) {
    const newAllUnits = allUnits.filter(
      (block: { unit_name: string }) => block.unit_name.toLowerCase() !== unit_name.toLowerCase()
    );
    const filteredUnits = unitsList?.filter(
      (unit) => unit.toLowerCase() !== unit_name.toLowerCase()
    );
    setAllUnits(newAllUnits);
    setUnitsList(filteredUnits);
    setValue('unit_name', '');
  }

  function openCloseRegisterModal() {
    setOpenRegisterModal(!isOpenRegisterModal);
  }

  const closePartnersModal = () => {
    toogleModal();
  };

  const onSubmitRegister = async () => {
    toogleLoading();
    try {
      if (!unitsList || unitsList.length === 0) return;
      const blockIDFromURL = window.location.href.split('/').pop();
      if (unitsList) await registerUnitRequest(unitsList, blockID || blockIDFromURL!);
      handleSuccessfully(true);
      handleSetText('Unidade cadastrada com sucesso');
      closePartnersModal();
      getAllData();
    } catch (err: any) {
      handleSuccessfully(false);
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
      handleModalOpen();
    }
  };

  const submit = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onSubmitRegister();
  };

  useEffect(() => {}, []);

  return {
    control,
    errors,
    allUnits,
    handleSetUnit,
    handleRemoveUnit,
    handleSubmit,
    register,
    submit,
    openCloseRegisterModal,
    closePartnersModal
  };
};
