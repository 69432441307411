import create from 'zustand';
import {
  searchVisitors,
  sortVisitorsData,
  changeStatusVisitors,
  getVisitorInfosById
} from '../../services/requests/visitors/Visitors.request';

import { VisitorsStoreProps, FilesRequestData } from './Visitors.props';

const initialState = {
  files: [],
  id: '',
  name: '',
  type: '',
  created_at: '',
  updated_at: '',
  field: 'visitor_model.status',
  direction: 'DESC'
};

export const useVisitorsStore = create<VisitorsStoreProps>((set, get) => ({
  ...initialState,
  errorVisitor: '',
  variantInfor: '',
  idVisitor: '',
  files: [],
  name: '',

  handleSetVisitorsId: (filesId) => set((state) => ({ ...state, filesId })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),

  getAllVisitorsSortRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortVisitorsData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  },

  getVisitorInfosByIdRequest: async (visitorId) => {
    try {
      const data = await getVisitorInfosById(visitorId);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchVisitorsRequest: async (
    search,
    page,
    sortByField,
    sortByDirection
  ): Promise<FilesRequestData[]> => {
    const { data } = await searchVisitors(search, page, sortByField, sortByDirection);
    return data as unknown as FilesRequestData[];
  },

  changeStatusVisitors: async (id, status): Promise<void> => {
    await changeStatusVisitors(id, status);
  }
}));
