import { useState } from 'react';

function useHeaderLogoController() {
  const [widthState, setWidthState] = useState(window.innerWidth);

  window.addEventListener('resize', function () {
    setWidthState(window.innerWidth);
  });

  return { widthState };
}

export default useHeaderLogoController;
