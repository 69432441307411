import styled from 'styled-components';

const Container = styled.div`
  height: 160px;
  width: 200px;
  border-radius: 12px;
  display: flex;
  margin-bottom: 12px;
  box-shadow: 0px 2px 6px rgba(67, 89, 113, 0.3);

  .imageContainer {
    height: 160px;
    width: 200px;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    flex-direction: column;
    position: relative;

    img {
      border-radius: 12px 12px 0px 0px;
    }
  }

  .imageTitle {
    height: 200px;
    width: 200px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    height: 80px;
    border-radius: 12px 12px 0px 0px;

    p {
      margin-left: 12px;
      margin-top: 12px;
      font-size: 8px;
    }
  }

  .descriptionContainer {
    background-color: #ffff;
    color: #61646b;
    border-radius: 0px 0px 10px 10px;

    width: 100%;
    padding: 2px;

    p {
      font-size: 7px;
      text-align: justify;
      height: 30px;
    }
  }
`;

export default Container;
