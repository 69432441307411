import create from 'zustand';

import { PaginationStoreProps } from './Pagination.props';

const initialState = {
  currentPage: 1
};

export const usePaginationStore = create<PaginationStoreProps>((set, get) => ({
  ...initialState,

  handleSetCurrentPage: (page: number) => set((state) => ({ ...state, currentPage: page }))
}));
