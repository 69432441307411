import { ThemeProvider, CssBaseline } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import theme from './theme/Theme';
import Routes from './routes/Routes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer autoClose={3000} className="toast-container" />
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
