import styled from 'styled-components';
import { CloseRounded } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

export const ModalContent = styled.div`
  background: #fff;
  width: 499px;
  padding: 24px 56px;
  max-height: 830px;
  /* overflow-y: scroll; */

  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  justify-content: center;
  align-items: center;
  z-index: 9;

  .container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;
    padding: 25px;
    width: 100%;
    max-width: 355px;
    height: 450.5px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-left: 17px;
  }

  .unitsText {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20.8px;
    color: #2c2c2c;
  }

  @media (max-height: 899px) {
    max-height: 730px;
  }

  @media (max-height: 740px) {
    max-height: 600px;
  }

  @media (max-height: 640px) {
    max-height: 500px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;
  }
`;

export const ModalCloseButton = styled(IconButton)``;

export const ModalCloseButtonIcon = styled(CloseRounded)``;

export const ModalTitle = styled.p`
  font-family: 'Inter';
  text-align: center;
  margin-top: 30px;
  margin-bottom: 2rem;

  font-size: 24px;
  font-weight: 600;
`;

export const ModalInfoTitle = styled.p`
  color: #2c2c2c;

  font-size: 18px;
  font-weight: 500;
  font-family: 'Inter';
`;

export const ModalBackButton = styled(Button)``;
