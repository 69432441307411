/* eslint-disable no-nested-ternary */
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';

interface ErrorProps {
  error?: FieldError | undefined;
  emailValidation: string;
}

const InputContainer = styled.div<ErrorProps>`
  width: 100%;
  border-radius: 8px;
  border: ${({ error, emailValidation }) =>
    error
      ? error
        ? '1px solid #CF0606'
        : '1px solid #8b8b8b'
      : emailValidation.length > 0
        ? '1px solid #2DA10F'
        : '1px solid #8b8b8b'};

  fieldset {
    border: none;
  }

  .showpassbutton {
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .showEmailbutton {
    display: ${({ error, emailValidation }) =>
      // eslint-disable-next-line no-nested-ternary
      error
        ? error.type === 'email'
          ? 'flex'
          : 'none'
        : emailValidation.length > 0
          ? 'flex'
          : 'none'};

    color: #2da10f;
    margin-right: -8px;
  }

  .showpassbutton:hover {
    opacity: 0.9;
  }

  .customInput {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: left;
    justify-content: center;
  }

  .showOnCenter {
    width: 100%;
    height: 48px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  input {
    width: 100%;
    padding: 0px 13px;
  }

  svg {
    margin-right: 5px;
    margin-top: 5px;
  }
`;

export default InputContainer;
