import styled, { css } from 'styled-components';
import { TableCell, TableRow, tableCellClasses } from '@mui/material';

const Container = styled.div`
  border: 1px solid #e0e7ed;
  border-radius: 16px;
  min-height: 600px;
  max-height: 680px;
  overflow-y: auto;
  position: relative;

  .tableRow {
    transition: all ease-in-out 100ms;
    height: 20px;
  }

  .tableRow:hover {
    background: #f4f4ff;
  }

  .optionsContainer {
    position: relative;
    background-color: transparent;
    display: block;
  }

  th {
    text-align: center !important;
  }

  td {
    color: #6d6d6d;
    border-radius: 0px !important;
  }

  .optionsContainer {
    text-align: center !important;
  }

  .openDetailContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: 79px;
    height: 31px;
    background: #662483;
    box-shadow: 0px 2px 6px rgba(67, 89, 113, 0.3);
    border-radius: 44px;
    color: #f4f4ff;

    font-family: 'Lato';
    font-size: 14px;
    line-height: 150%;
    color: #ffffff;
    text-transform: none;

    svg {
      margin-right: -3px;
      margin-bottom: 1px;
      width: 14px;
      height: 14px;
    }
  }

  .firstItem {
    color: #2c2c2c;
  }

  .headerColumn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sortIcons {
    display: flex;
    flex-direction: column;
  }

  .iconButton {
    padding: 1px;

    svg {
      width: 11px;
      height: 11px;
    }
  }

  @media (max-height: 840px) {
    min-height: 500px;
    height: 500px;
  }
`;

export default Container;

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F9F9F9',
    color: '#000',
    borderRadius: '16px 16px 0 0'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 'none',
    borderRadius: '20px'
  }
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff'
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export const CustomIconButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  path {
    stroke: ${(props: { primaryColor: string }) => props.primaryColor};
    fill: ${(props: { primaryColor: string }) => props.primaryColor};
  }
`;

interface IProps {
  primaryColor: string;
  column: string;
}

export const ButtonColor = styled.div<IProps>`
  ${() => css`
    border: none;

    padding: 8px;
    width: fit-content;
    border-radius: 8px;
  `}

  ${({ column, primaryColor }) =>
    column === 'Status' &&
    css`
      background-color: ${primaryColor === 'Pendente' && '#FFF6A7'};
      color: ${primaryColor === 'Pendente' && '#C96000'};

      background-color: ${primaryColor === 'Em Andamento' && '#D3FFFC'};
      color: ${primaryColor === 'Em Andamento' && '#006D74'};

      background-color: ${primaryColor === 'Reaberta' && '#F4F4FF'};
      color: ${primaryColor === 'Reaberta' && '#662483'};

      background-color: ${primaryColor === 'Concluído' && '#D4FFD3'};
      color: ${primaryColor === 'Concluído' && '#00740C'};
    `}
`;
