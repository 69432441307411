import styled, { css } from 'styled-components';

const Background = styled.div`
  display: flex;
  margin: 90px 24px 0px 20px;
  padding-right: 24px;
  padding-left: 24px;

  .visitorInfosGrid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .visitorInfosBox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .center {
    justify-content: center !important;
  }

  .around {
    justify-content: space-around !important;
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`;

interface IProps {
  status: string;
}

export const ButtonColor = styled.div<IProps>`
  ${({ status }) => css`
    border: none;
    text-align: center;
    background-color: ${status === 'Autorizado' && '#D4FFD3'};
    color: ${status === 'Autorizado' && '#00740C'};
    background-color: ${status === 'Revogado' && '#FFC5C5'};
    color: ${status === 'Revogado' && '#CF0606'};
    background-color: ${status === 'Pendente' && '#D3FFFC'};
    color: ${status === 'Pendente' && '#006D74'};
    background-color: ${status === 'Expirado' && '#FFC5C5'};
    color: ${status === 'Expirado' && '#CF0606'};
    background-color: ${status === 'Cancelado' && '#FFC5C5'};
    color: ${status === 'Cancelado' && '#CF0606'};
    padding: 16px;
    border-radius: 5px;
    margin-left: 16px;
  `}
`;

export default Background;
