import styled from 'styled-components';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;
export default Background;

export const ModalContent = styled.div`
  width: 520px;
  height: min-content;
  padding: 24px 72px;

  background: #fff;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;

  .closeButton {
    position: absolute;
    align-self: flex-end;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #121212;
    margin-top: 2rem;
  }

  .subtitle {
    padding-inline: 2.2rem;
    font-size: 15px;
    color: #8b8b8b;
  }

  .fileInputContainer {
    margin-top: 2rem;

    p {
      text-align: left;
    }
  }

  .buttonArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;

    margin-top: 32px;
  }

  .errorMessage {
    min-height: 24px;
    color: red;
    font-size: 13px;
    margin-top: -8px;
  }

  @media (max-width: 630px) {
    width: 450px;
  }

  @media (max-width: 530px) {
    width: 350px;
  }

  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  width: 100%;
`;
