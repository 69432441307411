import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormRequiredFields } from './FormNewPassword.props';
import { useAuthStore } from '../../../../store';

export const useForgotPasswordController = () => {
  const { changePassword } = useAuthStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token');

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Por favor, preencher campo senha.')
      .min(8, 'A senha deve conter no mínimo 8 caracteres.')
      .max(16, 'A senha deve conter no máximo 16 caracteres.')
      .matches(/([a-z]){1,}/, 'Deve conter pelo menos uma letra minúscula.')
      .matches(/([A-Z]){1,}/, 'Deve conter pelo menos uma letra maiúscula.')
      .matches(/([0-9]){1,}/, 'Deve conter pelo menos um número.')
      .matches(
        /([!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]){1,}/,
        'Deve conter pelo menos um caractere especial.'
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais.')
      .required('Informe a confirmação de senha.')
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      password: '',
      confirmPassword: ''
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const submitForm = async (): Promise<void> => {
    const { password, confirmPassword } = getValues();

    await changePassword(password, confirmPassword, token);
    navigate('/newPasswordSuccess');
  };

  return {
    submitForm,
    handleSubmit,
    register,
    errors,
    control
  };
};
