import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import { Sidebar } from '../../components';
import useHomeRoutesController from './HomeRoutes.controller';

import {
  ControlPanelAdminOr,
  Enterprises,
  RegisterEnterprise,
  EditEnterprise,
  Employees,
  Documents,
  DocumentsFiles,
  Roles,
  Residents,
  Visitors,
  VisitorInfos,
  Orders,
  Voting,
  VotingResult,
  Accessdenied,
  RegisterArea,
  Areas,
  Reserves,
  AreaTimeInformation,
  EditArea,
  Partners,
  LostItems,
  Informatives,
  RegisterInformatives,
  Blocks,
  RegisterMaintenance,
  Units,
  Maintenance,
  EditMaintenance,
  PaymentSlip,
  PaymentSlipFiles,
  Home,
  Ocurrences,
  OcurrencesDetails
} from '../../pages';

export default function HomeRoutes() {
  const { drawerWidth, widthState } = useHomeRoutesController();

  return (
    <Box
      style={
        Number(widthState) > 700
          ? { width: `calc(100% - ${drawerWidth}px)`, marginLeft: drawerWidth }
          : { marginLeft: '2px' }
      }>
      <Sidebar />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/paneladminor" element={<ControlPanelAdminOr />} />
        <Route path="/enterprises" element={<Enterprises />} />
        <Route path="/enterprises/registerenterprise" element={<RegisterEnterprise />} />
        <Route path="/enterprises/editenterprise/:id" element={<EditEnterprise />} />
        <Route path="/enterprises/blocks" element={<Blocks />} />
        <Route path="/enterprises/blocks/:id" element={<Units />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/employees" element={<Employees />} />
        <Route path="/docs" element={<Documents />} />
        <Route path="/docs/listdocs/:id" element={<DocumentsFiles />} />
        <Route path="/employees/roles" element={<Roles />} />
        <Route path="/residents" element={<Residents />} />
        <Route path="/visitors" element={<Visitors />} />
        <Route path="/visitors/info/:id" element={<VisitorInfos />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/vote" element={<Voting />} />
        <Route path="/vote/result" element={<VotingResult />} />
        <Route path="/area" element={<Areas />} />
        <Route path="/area/register" element={<RegisterArea />} />
        <Route path="/area/edit/:id" element={<EditArea />} />
        <Route path="/area/times/:id" element={<AreaTimeInformation />} />
        <Route path="/area/reserve" element={<Reserves />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/informatives" element={<Informatives />} />
        <Route path="/informatives/register" element={<RegisterInformatives />} />
        <Route path="/accessdenied" element={<Accessdenied />} />
        <Route path="/lostitems" element={<LostItems />} />
        <Route path="/maintenance/register" element={<RegisterMaintenance />} />
        <Route path="/maintenance/edit/:id" element={<EditMaintenance />} />
        <Route path="/payments" element={<PaymentSlip />} />
        <Route path="/payments/:id" element={<PaymentSlipFiles />} />
        <Route path="/occurrences" element={<Ocurrences />} />
        <Route path="/occurrences/detail/:id" element={<OcurrencesDetails />} />
      </Routes>
    </Box>
  );
}
