/* eslint-disable @typescript-eslint/no-var-requires */
import { Grid, Typography } from '@mui/material';
import useInformativesController from './Informatives.controller';
import {
  ActionModal,
  DeleteModal,
  InfoModal,
  CustomSearchInput,
  CustomButton
} from '../../components';
import { InformativesTable } from './components/informativesTable/CustomTable';
import Container from './Informatives.style';
import { DetailsModal } from './components/InfoModal/detailsModal';
import { UnitsChoicedModal } from './components/UnitsChoiced/UnitsChoiced';
import PreviewImage from './components/PreviewImage/PreviewImage';

const enterpriseactionedit = require('../../assets/images/enterpriseactionedit.svg');
const enterpriseactionreport = require('../../assets/images/enterpriseactionreport.svg');
const enterpriseactiontrash = require('../../assets/images/enterpriseactiontrash.svg');

export function Informatives() {
  const {
    actionModalData,
    informativesDeleteMessage,
    informativesTableHeader,
    informativeId,
    informativesId,
    informativesList,
    totalPages,
    totalInformatives,
    isOpenDeleteModal,
    isOpenInfoModal,
    isSuccessfully,
    infoText,
    unitsChoicedoModalOpen,
    isInfoModalOpen,
    isOpenImagePreview,
    closeInfoModal,
    handleModalOpen,
    handleDeleteModalOpen,
    navigateToRegisterPage,
    handleSearch,
    changePage,
    sortInformativesData,
    handleSetInformativeId,
    handleDrawerOpen,
    onSubmitDelete
  } = useInformativesController();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Informativos
          </Typography>
          <CustomSearchInput id="informatives_search" handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={3} alignSelf="flex-end">
          <CustomButton
            id="informatives_register"
            onClick={navigateToRegisterPage}
            text="Criar informativo"
          />
        </Grid>
        <Grid item xs={12}>
          <InformativesTable
            rowsId={informativesId}
            tableHead={informativesTableHeader}
            tableData={informativesList || []}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalInformatives}
            funcToChangePage={changePage}
            handleSetItemId={handleSetInformativeId}
            sortFunc={sortInformativesData}
            containId
            ActionModal={
              <ActionModal
                ActionModalData={actionModalData}
                ButtonsIcon={[
                  <enterpriseactionedit.ReactComponent className="actionIcon" />,
                  <enterpriseactionreport.ReactComponent className="actionIcon" />,
                  <enterpriseactiontrash.ReactComponent className="actionIcon" />
                ]}
              />
            }
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title={informativesDeleteMessage.title}
          onClickDelete={() => onSubmitDelete(informativeId)}
          handleModalClose={handleDeleteModalOpen}
        />
      )}
      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={infoText} />
      )}

      {isOpenImagePreview && <PreviewImage />}

      <DetailsModal isModalOpen={isInfoModalOpen} toggleModal={closeInfoModal} />
      <UnitsChoicedModal isModalOpen={unitsChoicedoModalOpen} />
    </Container>
  );
}
