import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 10px;

  .profileIcon {
    background: #099ad7;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profileIconText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .userName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12.5px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .accessProfile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10.5px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #d9d9d9;
  }
`;

export default Container;
