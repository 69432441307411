import { Grid, ListItem, ListItemIcon, ListItemText, Typography, InputLabel } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import Background from './ControlPanelAdminOr.style';
import formatDate from '../../helpers/formatDate';
import NoFilesFound from '../../components/CustomTable/components/NoFilesFound/NoFilesFound';
import { AutocompleteInput, Dashboard } from './components';

import { useDashboardController } from './ControlPanelAdminOr.controller';

export function ControlPanelAdminOr() {
  const {
    // enterprise
    enterpriseList,
    selectedEnterprise,
    setSelectedEnterprise,
    inputSearchValue,
    setInputSearchValue,
    // dashboard
    dashboardData
  } = useDashboardController();

  const hasData = !!dashboardData;
  const hasEnterpriseList = enterpriseList && enterpriseList?.length > 0;
  const hasSelectedEnterprise = selectedEnterprise?.name || inputSearchValue;

  return (
    <Background>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Typography fontSize={40} fontWeight={600}>
            Painel de Controle
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} marginBottom={2}>
          <ListItem disablePadding color="#8B8B8B">
            <ListItemIcon sx={{ minWidth: '40px' }}>
              <CalendarTodayOutlined color="inherit" sx={{ fill: '#8B8B8B' }} />
            </ListItemIcon>
            <ListItemText primary={formatDate(new Date())} />
          </ListItem>
        </Grid>
        <Grid item xs={8} md={5} position="relative">
          {hasEnterpriseList && (
            <>
              <InputLabel className="selectInputLabel">
                {hasSelectedEnterprise ? '' : 'Geral'}
              </InputLabel>
              <AutocompleteInput
                options={enterpriseList || []}
                handleSearch={() => setInputSearchValue}
                setSelectedItem={setSelectedEnterprise}
              />
            </>
          )}
        </Grid>
      </Grid>

      {hasData ? (
        <Grid container marginTop={4} xs={12}>
          <Dashboard
            cardCountData={dashboardData.cardCount}
            functionalitiesData={dashboardData.funcionalities}
          />
        </Grid>
      ) : (
        <Grid container sx={{ placeContent: 'center' }}>
          <NoFilesFound text="Não há dados para serem visualizados no momento." />
        </Grid>
      )}
    </Background>
  );
}
