export function convertLinkToPt(linkName: string) {
  let translatedLink = '';

  if (linkName.match(/docs\/listdocs/gm)) return 'documentos';
  if (linkName.match(/vote\/result/gm)) return 'votacoes';
  if (linkName.match(/area/gm)) return 'votacoes';
  if (linkName.match(/enterprises\/blocks/gm)) return 'empreendimentos';
  if (linkName.match(/informatives/gm)) return 'informativos';
  if (linkName.match(/occurrences/gm)) return 'ocorrencias';
  if (linkName.match(/maintenance/gm)) return 'manutencoes';
  if (linkName.match(/payments/gm)) return 'boletos';
  if (linkName.match(/visitors/gm)) return 'visitantes';

  switch (linkName) {
    case 'informatives':
      translatedLink = 'informativos';
      break;
    case 'lostitems':
      translatedLink = 'achados/perdidos';
      break;
    case 'home':
      translatedLink = 'inicio';
      break;
    case 'employees':
      translatedLink = 'funcionarios';
      break;
    case 'enterprises':
      translatedLink = 'empreendimentos';
      break;
    case 'maintenance':
      translatedLink = 'manutencoes';
      break;
    case 'reports':
      translatedLink = 'relatorio';
      break;
    case 'orders':
      translatedLink = 'encomendas';
      break;
    case 'residents':
      translatedLink = 'moradores';
      break;
    case 'occurrences':
      translatedLink = 'ocorrencias';
      break;
    case 'visitors':
      translatedLink = 'visitantes';
      break;
    case 'partners':
      translatedLink = 'parceiros';
      break;
    case 'vote':
      translatedLink = 'votacoes';
      break;
    case 'docs':
      translatedLink = 'documentos';
      break;
    case 'area':
      translatedLink = 'areas/reservas';
      break;
    case 'employees/roles':
      translatedLink = 'funcionarios';
      break;
    case 'payments':
      translatedLink = 'boletos';
      break;
    default:
      break;
  }
  return translatedLink;
}

export function convertLinkToEn(linkName: string, isAdmin?: boolean) {
  let translatedLink = '';

  switch (linkName) {
    case 'informativos':
      translatedLink = 'informatives';
      break;
    case 'achados/perdidos':
      translatedLink = 'lostitems';
      break;
    case 'inicio':
      translatedLink = 'home';
      break;
    case 'empreendimentos':
      translatedLink = 'enterprises';
      break;
    case 'manutencoes':
      translatedLink = 'maintenance';
      break;
    case 'funcionarios':
      translatedLink = 'employees';
      break;
    case 'relatorio':
      translatedLink = 'reports';
      break;
    case 'encomendas':
      translatedLink = 'orders';
      break;
    case 'moradores':
      translatedLink = 'residents';
      break;
    case 'ocorrencias':
      translatedLink = 'occurrences';
      break;
    case 'visitantes':
      translatedLink = 'visitors';
      break;
    case 'parceiros':
      translatedLink = 'partners';
      break;
    case 'votacoes':
      translatedLink = 'vote';
      break;
    case 'documentos':
      translatedLink = 'docs';
      break;
    case 'areas/reservas':
      translatedLink = 'area';
      break;
    case 'boletos':
      translatedLink = 'payments';
      break;
    default:
      if (isAdmin) translatedLink = 'home';
      break;
  }
  return translatedLink;
}
