import styled from 'styled-components';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .Container {
    background-color: #fff;
    width: 480px;
    height: 400px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px;
    isolation: isolate;
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: #2c2c2c;
    margin-bottom: 10px;
  }

  .subTitleContainer {
    width: 80%;
    margin-bottom: 5px;
  }

  .subTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #2c2c2c;
  }

  .inputContainer {
    width: 370px;
    height: 280px;
    margin-bottom: 15px;

    .input {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .closeBtnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .closeBtn {
    cursor: pointer;
  }

  .boxContainer {
    width: 100%;
    padding-left: 33px;
    padding-right: 33px;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    gap: 15px;
  }

  @media (max-width: 510px) {
    .Container {
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }

    .closeBtnContainer {
      display: none !important;
    }
  }
`;
export default Background;
