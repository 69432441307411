/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useEnterpriseStore } from '../../store';
import { isInvalidFileName } from '../../helpers/fileNameValidator';

function useCustomFileInput(
  type: string,
  hasImageSizeValidation?: boolean,
  handleFile?: (file: File) => void,
  isTenSize?: boolean
) {
  const [fileName, setFileName] = useState('');
  const [link, setLink] = useState('');
  const [image, setImage] = useState<File>();
  const {
    handleSetPlaceholderFile,
    handleSetLogo,
    handleSetManual,
    handleSetImageName,
    handleSetImageFile,
    handleSetLogoUrl,
    setSignedLogoUrl,
    logo,
    manual,
    logoUrl: logoURL,
    manualUrl: manualURL,
    signedLogoUrl,
    imageName
  } = useEnterpriseStore();

  const handleRemoveFile = () => {
    setFileName('');
    handleSetImageName('');
    handleSetLogo('');
    handleSetLogoUrl('');
    handleSetPlaceholderFile(null);
  };

  function handleSetNameAndLink() {
    if (!hasImageSizeValidation) return;
    if (!logo && !manual && !logoURL && !manualURL) return;

    if (type === 'image' && (logo || logoURL)) {
      setFileName('Logo');
      !logo && handleSetImageName('Ver Imagem');
      setLink(logo || logoURL);
      setSignedLogoUrl(logo || logoURL);
    }

    if (type === 'pdf' && (manual || manualURL)) {
      setFileName('Manual');
      setLink(manual || manualURL);
    }
  }

  function adjustImageNameSize(name: string) {
    if (name.length > 40) {
      const splitedName = name.split('.');
      return `${splitedName[0].slice(0, 40)}...${splitedName[1]}`;
    }
    return name;
  }

  useEffect(() => {
    handleSetNameAndLink();
  }, []);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target;
    const isImage =
      event.files![0].type === 'image/jpeg' ||
      event.files![0].type === 'image/png' ||
      event.files![0].type === 'image/jpg';

    if (event.files) {
      let error = false;
      const CUSTOMURL = window.URL || window.webkitURL;

      if (event.files[0].name.length >= 50) {
        toast.error('O nome do arquivo precisa ter menos de 50 caracteres.');
        error = true;
      }

      if (
        type !== 'image' &&
        event.files[0].type !== 'image/jpeg' &&
        event.files[0].type !== 'image/png' &&
        event.files[0].type !== 'application/pdf'
      ) {
        toast.error('É permitido apenas arquivos JPEG, PNG e PDF');
        error = true;
      }

      if (
        type === 'image' &&
        event.files[0].type !== 'image/jpeg' &&
        event.files[0].type !== 'image/png'
      ) {
        toast.error('É permitido apenas arquivos JPEG e PNG');
        error = true;
      }

      if (isTenSize && event.files[0].size > 10000000) {
        toast.error('A imagem precisa ter no máximo 10MB.');
        error = true;
      }

      if (isInvalidFileName(event.files[0].name)) return;

      const img: any = new Image();
      const logoUrl = CUSTOMURL.createObjectURL(event.files[0]);
      img.src = logoUrl;
      handleSetLogo(img.src);

      if (type !== 'pdf') {
        img.onload = function whatIsHeightAndWidth() {
          if (hasImageSizeValidation && img.width > 190) {
            toast.error('A imagem precisa ter no máximo 190px de largura');
            handleRemoveFile();
            error = true;
          }

          if (Number(img.size) > 10000000) {
            toast.error(`O tamanho da imagem ${img.name} excede 10MB`);
            handleRemoveFile();
            error = true;
          }

          if (hasImageSizeValidation && img.height > 55) {
            toast.error('A imagem precisa ter no máximo 55px de altura');
            handleRemoveFile();
            error = true;
          }
        };
      }
      if (error) return;

      const file = event.files[0];
      setImage(file);

      handleFile && handleFile(file);
      setFileName(event.files[0].name);
      handleSetPlaceholderFile(event.files[0]);
      if (isImage) {
        handleSetImageFile(event.files[0]);
        handleSetImageName(event.files[0].name);
      }

      type === 'image' ? '' : handleSetManual(file);
    }
  };

  return {
    fileName,
    signedLogoUrl,
    link,
    image,
    imageName,
    handleChangeFile,
    handleRemoveFile,
    adjustImageNameSize
  };
}

export default useCustomFileInput;
