import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin: 90px 24px 0px 20px;
  padding-right: 24px;
  padding-left: 24px;

  .valueInput {
    flex: 1;
    width: 100%;
    background-color: transparent;

    input {
      height: 16px;
      margin-top: 1px;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }
  }

  .descriptionInput {
    width: 100%;

    fieldset {
      border-radius: 12px;
      border: 1px solid #8b8b8b;
    }
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .errorMessage {
    color: red;
    font-size: 12px;
  }

  .saveButton {
    padding: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 0px;
    text-transform: none;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #662483;
    border-radius: 8px;
    color: #fff;
  }

  .saveButton:hover {
    opacity: 0.9;
    color: #fff;
    background: #662483;
  }

  .backButton {
    padding: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 0px;
    text-transform: none;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f1f5;
    border-radius: 8px;
    color: #2c2c2c;
  }

  .backButton:hover {
    opacity: 0.9;
  }

  .backAndSaveContainer {
    display: flex;
    gap: 20px;
    width: 500px;
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`;

export default Container;
