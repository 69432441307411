import styled from 'styled-components';

const Container = styled.div`
  padding-right: 12px;
  padding-left: 12px;
  width: 100%;

  .timeContainer {
    display: flex;
    align-items: flex-start !important;
  }

  .timeInputContainer {
    display: flex;
    align-items: flex-start;
  }

  .firstBox {
    margin-right: 160px;
  }

  .labelText {
    margin-right: 20px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 15px;
    color: #2c2c2c;
    margin-top: 8px;
  }

  .timeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    text-transform: none;
    width: 213px;
    height: 45px;
    background: #662483;
    border-radius: 6px;
    color: #fff;
    margin-left: 40px;
  }

  .timeButton:hover {
    background: #662483;
    opacity: 0.9;
  }

  .infoNextDayText {
    font-size: 12px;
    margin-top: 2px;
    color: #662483;
  }

  .trashButton {
    margin-left: 30px;
  }

  .trashButton:hover {
    background-color: transparent;
    opacity: 0.9;
  }

  .errorMessage {
    color: red;
    max-width: 316px;
    flex-wrap: wrap;
  }

  .timeInput {
    width: 297px;

    input {
      height: 10px;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }

    fieldset:hover {
      border: 1px solid #8b8b8b;
    }
  }

  .inputOfTime {
    width: 300px;
    background-color: red;
  }

  .timeContainer {
    position: relative;
  }

  .clockIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  @media (max-width: 1490px) {
    .timeInputContainer {
      margin-right: 50px;
    }
  }

  @media (max-width: 1430px) {
    .timeInputContainer {
      margin-right: 20px;
    }
  }

  @media (max-width: 1370px) {
    padding-right: 5px;
    padding-left: 5px;
    .labelText {
      margin-right: 5px;
    }
  }

  @media (max-width: 1323px) {
    .timeButton {
      width: 140px;
    }
  }

  @media (max-width: 1182px) {
    .timeButton {
      width: 110px;
      margin-left: 20px;
      font-size: 12px;
    }
    .trashButton {
      margin-left: 12px;
    }
  }

  @media (max-width: 1154px) {
    .timeInputContainer {
      width: 265px;
    }

    .timeInput {
      width: 199px;
    }
  }

  @media (max-width: 980px) {
    .timeInput {
      width: 198px;
    }
    .timeInputContainer {
      flex-direction: column;
      justify-content: center;
      align-items: initial;
      width: 200px;
    }
    .timeButton {
      margin-top: 18px;
      margin-left: 0px;
      width: 100px;
      font-size: 11px;
    }
    .trashButton {
      margin-top: 18px;
      margin-left: -6px;
    }
  }

  @media (max-width: 822px) {
    .timeInputContainer {
      flex-direction: column;
      justify-content: center;
      align-items: initial;
      width: 150px;
    }

    .timeInput {
      width: 148px;
    }

    .timeContainer {
      min-width: 540px;
    }
  }

  @media (max-width: 700px) {
    .timeInputContainer {
      width: 200px;
    }

    .timeInput {
      width: 198px;
    }
  }

  @media (max-width: 565px) {
    .timeContainer {
      min-width: 340px;
      flex-direction: column;
      align-items: initial;
      gap: 10px;
    }
    .timeInputContainer {
      width: 100%;
    }
    .timeButton {
      width: 100%;
      font-size: 13px;
    }

    .timeInput {
      width: 100%;
    }
  }
`;

export default Container;
