import styled from 'styled-components';

const Container = styled.div`
  .titleBox {
    margin-bottom: 20px;
  }

  .customizationTitle {
    color: #1e1e1e;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  .inputContainer {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .bottomBox {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .boxButtonContainer {
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: right;
    margin-top: 1rem;

    button {
      padding: 10px;
      transition: all 0.2s ease-in-out;
      text-transform: none;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: #662483;
      color: #ffffff;

      &:hover {
        background-color: #662483;
        opacity: 0.9;
      }
    }

    .cancel {
      padding: 10px;
      text-transform: none;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: #f0f1f5;
      color: #2c2c2c;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #f0f1f5;
        opacity: 0.4;
      }
    }
  }

  .fileInputDescription {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #8b8b8b;
    margin: 5px;
  }
`;

export default Container;
