import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatData } from '../../helpers/formatData';
import {
  useActionModalStore,
  useSortItemsStore,
  useDeleteModalStore,
  useLoadModalStore,
  useUnitsStore,
  useBlocksStore,
  useInfoModalStore,
  usePaginationStore
} from '../../store';

import { Errors } from '../../constants/Errors';

import { unitsTableHead } from '../../constants/UnitsData';

export function useUnitsController() {
  const { handleSetSortFields } = useSortItemsStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { isOpenInfoModal, handleModalOpen } = useInfoModalStore();
  const { blockName, enterpriseName, setBlockName, setEnterpriseName } = useBlocksStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();

  const [isSuccessfully, setIsSuccessfully] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();

  const {
    blockID,
    unitID,
    unitName,
    unitsIDs,
    unitsList,
    page,
    field,
    direction,
    totalUnits,
    totalPages,
    isOpenRegisterModal,
    isOpenRenameModal,
    setOpenRegisterModal,
    setOpenRenameModal,
    setBlockID,
    setUnitID,
    setUnitName,
    setUnitsIDs,
    setUnitsList,
    setTotalUnits,
    setTotalPages,
    getAllSortedUnitsData,
    deleteUnitRequest,
    searchUnitRequest
  } = useUnitsStore();

  function handleOpenRegisterModal() {
    setOpenRegisterModal(!isOpenRegisterModal);
  }

  function handleOpenRenameModal() {
    setOpenRenameModal(!isOpenRenameModal);
  }

  function handleActionModalData(nameUnit: string) {
    const actionModalData = [
      {
        ButtonsText: 'Renomear Unidade',
        OnClick: () => {
          setUnitName(nameUnit);
          setOpenRenameModal(!isOpenRenameModal);
        }
      },
      {
        ButtonsText: 'Excluir Unidade',
        OnClick: () => {
          handleDeleteModalOpen();
          handleDrawerOpen();
        }
      }
    ];

    return actionModalData;
  }

  function formatRequestData(data: any[]) {
    const formatedRowsId = data.map((item: { id: string }) => item.id);

    const formatedData = data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        createdAt: formatData(String(item.createdAt))
      };
    });

    setUnitsList(formatedData);
    setUnitsIDs(formatedRowsId);
  }

  interface IPageAndCounts {
    totalPages: number;
    totalUnits: number;
  }

  function setPageAndItemCout(data: IPageAndCounts) {
    setTotalPages(data.totalPages || 1);
    setTotalUnits(data.totalUnits || 0);
  }

  async function handleSearch(value: string) {
    setSearchString(value);
    const formatedDataForSearch = { queryText: value };
    const { data } = await searchUnitRequest(
      formatedDataForSearch,
      field,
      direction,
      String(currentPage)
    );

    if (data) {
      formatRequestData(data.data);
      setPageAndItemCout(data);
    }
  }

  async function getAllData() {
    const { data } = await getAllSortedUnitsData(page, field, direction, searchString);

    if (data) {
      formatRequestData(data.data);
      setPageAndItemCout(data);
    }
  }

  async function sortDataRequest(sortPage: string, sortByField: string, sortByDirection: string) {
    const data = await getAllSortedUnitsData(
      sortPage || '1',
      sortByField || 'unit_model.name',
      sortByDirection || 'ASC',
      searchString
    );

    const { totalUnits: totalData, totalPages: pages } = data.data;

    const formatedData = data.data.data.map(
      (item: { id: string; name: string; createdAt?: string }) => {
        return {
          id: item.id,
          name: item.name,
          createdAt: formatData(String(item.createdAt))
        };
      }
    );

    return { data: { data: formatedData, totalData, pages } };
  }

  async function onSubmitDelete(id: string) {
    toogleLoading();
    try {
      await deleteUnitRequest(id);
      setIsSuccessfully(true);
      setInfoText('Unidade excluída com sucesso');
      getAllData();
    } catch (error: any) {
      setIsSuccessfully(false);
      setInfoText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
      handleModalOpen();
    }
  }

  async function changePage(pageNumber: number) {
    toogleLoading();
    try {
      const { data } = await getAllSortedUnitsData(`${pageNumber}`, field, direction, searchString);

      if (data) {
        formatRequestData(data.data);
        setPageAndItemCout(data);
      }
    } catch (err: any) {
      setInfoText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
    }
  }

  function setSetSortFields() {
    handleSetSortFields(['unit_model.name', 'unit_model.createdAt']);
  }

  function backToBlocksPage() {
    navigate('../enterprises/blocks', { replace: true });
  }

  function saveDataOnLocalStorage() {
    if (!blockName || !enterpriseName) return;

    const blockURLID = window.location.href.split('/').pop();
    const data = { blockName, enterpriseName, blockURLID };
    window.localStorage.setItem('blockEnterpriseName', JSON.stringify(data));
  }

  function getDataOnLocalStorage() {
    const restoredData = window.localStorage.getItem('blockEnterpriseName');
    if (!restoredData) return;

    const parsedData = JSON.parse(restoredData);
    const blockIDFromURL = window.location.href.split('/').pop();

    if (blockIDFromURL !== parsedData.blockURLID) return;
    setEnterpriseName(parsedData.enterpriseName);
    setBlockName(parsedData.blockName);
  }

  useEffect(() => {
    setSetSortFields();
  }, []);

  useEffect(() => {
    const blockIDFromURL = window.location.href.split('/').pop();
    if (blockIDFromURL) setBlockID(blockIDFromURL);
    saveDataOnLocalStorage();
    getDataOnLocalStorage();
  }, [blockID]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    blockName,
    enterpriseName,
    unitID,
    unitsIDs,
    unitsList,
    unitsTableHead,
    totalUnits,
    totalPages,
    isOpenDeleteModal,
    isOpenRegisterModal,
    isOpenRenameModal,
    isSuccessfully,
    infoText,
    isOpenInfoModal,
    getAllData,
    sortDataRequest,
    setUnitID,
    handleActionModalData,
    handleDeleteModalOpen,
    handleOpenRegisterModal,
    handleOpenRenameModal,
    handleModalOpen,
    setIsSuccessfully,
    setInfoText,
    onSubmitDelete,
    handleSearch,
    changePage,
    backToBlocksPage,
    handleDrawerOpen
  };
}

export default useUnitsController;
