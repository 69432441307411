import create from 'zustand';

import { SortItemsProps } from './SortItems.props';

const initialState = {
  sortUp: false,
  sortDown: false,
  sortItemId: '',
  sortFields: []
};

const useSortItemsStore = create<SortItemsProps>((set) => ({
  ...initialState,

  handleSetSortDown: () => {},
  handleSetSortUp: () => {},

  changeSortUpColor: () => set((state) => ({ ...state, sortUp: true, sortDown: false })),
  changeSortDownColor: () => set((state) => ({ ...state, sortDown: true, sortUp: false })),
  handleSetSortItemId: (itemId) => set((state) => ({ ...state, sortItemId: itemId })),
  handleSetSortFields: (fields) => set((state) => ({ ...state, sortFields: fields }))
}));

export { useSortItemsStore };
