/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Typography, Button } from '@mui/material';
import Container from './CustomPagination.style';
import useCustomPaginationController from './CustomPagination.controller';
import { CustomPaginationProps } from './CustomPagination.props';

const arrowleft = require('../../../../assets/images/arrowleft.svg');
const arrowright = require('../../../../assets/images/arrowright.svg');

function CustomPagination(props: CustomPaginationProps) {
  const { totalPages, funcToChangePage, totalItems } = props;
  const { changePage, pageNumbers, initialShowingNumber, finalShowingNumber } =
    useCustomPaginationController(totalItems, totalPages);

  return (
    <Container>
      <Box>
        <Typography color="#2C2C2C" fontSize="15px">
          {finalShowingNumber !== 0 && (
            <span>
              Mostrando de {initialShowingNumber} até {finalShowingNumber} de {totalItems} registros
            </span>
          )}
        </Typography>
      </Box>
      <Box className="changePageContainer">
        <Button
          className="changePageIcon"
          onClick={() => changePage(pageNumbers[0] - 1, totalItems, funcToChangePage)}>
          <arrowleft.ReactComponent />
        </Button>
        {pageNumbers[0] > 1 && (
          <Button
            className="changePageNumber"
            onClick={() => changePage(pageNumbers[0] - 1, totalItems, funcToChangePage)}>
            {pageNumbers[0] - 1}
          </Button>
        )}
        <Button
          className="changePageNumber currentPage"
          onClick={() => changePage(pageNumbers[0], totalItems, funcToChangePage)}>
          {pageNumbers[0]}
        </Button>
        {totalPages > pageNumbers[0] && (
          <Button
            className="changePageNumber"
            onClick={() => changePage(pageNumbers[1], totalItems, funcToChangePage)}>
            {pageNumbers[1]}
          </Button>
        )}
        {totalPages > pageNumbers[1] && pageNumbers[1] === 2 && (
          <Button
            className="changePageNumber"
            onClick={() => changePage(pageNumbers[2], totalItems, funcToChangePage)}>
            {pageNumbers[2]}
          </Button>
        )}
        {totalPages > pageNumbers[1] && (
          <>
            <Button className="changePageNumber">...</Button>
            <Button
              className="changePageLastNumber"
              onClick={() => changePage(totalPages, totalItems, funcToChangePage)}>
              {totalPages}
            </Button>
          </>
        )}
        <Button
          className="changePageIcon"
          onClick={() => changePage(pageNumbers[1], totalItems, funcToChangePage)}>
          <arrowright.ReactComponent />
        </Button>
      </Box>
    </Container>
  );
}

export default CustomPagination;
