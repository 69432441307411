import { useAreaStore } from '../../../../store';

function useDayButtonController() {
  const { handleSetAvailableDay, availableDay } = useAreaStore();

  return {
    handleSetAvailableDay,
    availableDay
  };
}

export default useDayButtonController;
