import styled from 'styled-components';

const InputContainer = styled.div`
  fieldset {
    border: none;
  }

  input {
    padding: 9.5px 14px;
  }

  .searchInput {
    border: 1px solid #8b8b8b;
    border-radius: 12px;
    width: 100%;
  }
`;

export default InputContainer;
