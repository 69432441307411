import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 25px 50px;

  .showCustomization {
    color: #6d6d6d;
    text-transform: none;
    padding: 0;
    text-decoration: underline;
  }

  .showCustomization:hover {
    text-decoration: underline;
    background-color: transparent;
    opacity: 0.9;
  }
`;

export default Container;
