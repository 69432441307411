/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Typography, TableHead, TableBody } from '@mui/material';
import { ITableDataProps } from './TableData.props';
import { StyledTable, StyledTableCell, StyledTableRow } from './TableData.style';
import { useTableData } from './TableData.controller';

export function TableData({ functionalitiesData }: ITableDataProps) {
  const { formatData } = useTableData();

  const tableData = formatData(functionalitiesData);

  return (
    <StyledTable aria-label="customized table" size="medium">
      <TableHead />
      <TableBody>
        {tableData.map((feature, i) => {
          return (
            <StyledTableRow key={i}>
              <StyledTableCell className="featTitle">{feature.title}</StyledTableCell>
              {feature.data.map((featCounts, index) => (
                <StyledTableCell key={index}>
                  <Box>
                    <Typography className="featSubtitle">{featCounts.title}</Typography>
                    <Typography className="featAmount">{featCounts.amount}</Typography>
                  </Box>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
}
