import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useOrdersStore } from '../../../store/Orders/Orders.store';
import {
  useAdminORStore,
  useBackToLastPageModalStore,
  useBlocksStore,
  useInfoModalStore,
  useLoadModalStore
} from '../../../store';

import { Errors } from '../../../constants/Errors';

export const useModalController = (
  variant: string,
  onComplete: () => void,
  toggleModal: () => void,
  isModalOpen: boolean
) => {
  const [isDelivery, setIsDelivery] = useState(true);
  const [image, setImage]: any = useState<File>();
  const { createOrderRequest } = useOrdersStore();
  const { handleBackToLastPageModalOpen } = useBackToLastPageModalStore();
  const [isButtonClickOnce, setIsButtonClickOnce] = useState(false);
  const { handleModalOpen, handleSetIsSuccessfully, handleSetText } = useInfoModalStore();
  const { getSimpleBlockListData, setEnterpriseID, blocksList, unitList, setUnitListData } =
    useBlocksStore();
  const { enterpriseIdOfManager } = useAdminORStore();
  const { toogleLoading } = useLoadModalStore();

  const [isSelectedBlock, setIsSelectedBlock] = useState('');
  const [isSelectedUnity, setIsSelectedUnity] = useState('');

  const [unitValue, setUnitValue] = useState('');
  const [blockValue, setBlockValue] = useState('');
  const [isInputValue, setIsInputValue] = useState('');
  const [isInputBlockValue, setIsInputBlockValue] = useState('');

  const schema = yup.object().shape({
    sender: yup.string().required('Informe o remetente.'),
    recipient: yup.string().required('Informe o destinatário.'),
    unit: yup.string().required('Informe a unidade.'),
    block: yup.string().required('Informe o bloco.')
  });
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      sender: '',
      recipient: '',
      unit: '',
      block: ''
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  });

  const handleCreateOrder = async () => {
    toogleLoading();
    try {
      const { sender, recipient, unit, block } = getValues();

      const formData = new FormData();

      formData.append('sender', sender);
      formData.append('recipient', recipient);
      formData.append('unitId', unit);
      formData.append('blockId', block);
      formData.append('delivery', String(isDelivery));
      if (!isDelivery && image) {
        formData.append('imagePath', image);
      } else if (!isDelivery && !image) {
        toast.error('Opss! Você não inseriu imagem.');
        return;
      }
      setIsButtonClickOnce(true);
      await createOrderRequest(formData, onComplete);

      setValue('sender', '');
      setValue('recipient', '');
      setValue('unit', '');
      setValue('block', '');
      setIsDelivery(true);
      setIsButtonClickOnce(false);
      setImage('');
      handleSetIsSuccessfully(true);
      handleSetText('Encomenda cadastrada com sucesso!');
      handleModalOpen();
    } catch (error: any) {
      setIsButtonClickOnce(false);
      const { message } = error.response.data;
      if (message.includes('O bloco') || message.includes('A unidade')) {
        toast.error(message);
        return;
      }
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    } finally {
      setIsButtonClickOnce(false);
      toogleLoading();
    }
  };

  const handleSlideButton = () => {
    setIsDelivery((prev) => !prev);
  };

  const closeVotingModal = () => {
    const { block, recipient, sender, unit } = getValues();
    setIsSelectedUnity('');
    setIsSelectedBlock('');
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    block || recipient || sender || unit ? handleBackToLastPageModalOpen() : toggleModal();
  };

  function handleSearchSelect(e: any) {
    setUnitValue('');
    setValue('unit', '');
    setIsInputValue(e);
  }

  function handleSearchBlockSelect(e: any) {
    setBlockValue('');
    setValue('block', '');
    setIsInputBlockValue(e);
  }

  useEffect(() => {
    setValue('recipient', '');
    setValue('sender', '');
    setValue('unit', '');
    setValue('block', '');
    setImage('');
  }, [isModalOpen]);

  const handleSelectBlock = async () => {
    await getSimpleBlockListData();
  };

  useEffect(() => {
    setEnterpriseID(enterpriseIdOfManager);
    handleSelectBlock();
  }, [enterpriseIdOfManager]);

  useEffect(() => {
    setUnitValue('');
    setValue('unit', '');
  }, [blockValue]);

  return {
    handleSlideButton,
    handleSearchSelect,
    handleSearchBlockSelect,
    isInputValue,
    setIsInputValue,
    isInputBlockValue,
    setIsInputBlockValue,
    isDelivery,
    register,
    unitValue,
    blockValue,
    setUnitValue,
    setBlockValue,
    control,
    isSelectedBlock,
    isSelectedUnity,
    setIsSelectedBlock,
    setIsSelectedUnity,
    errors,
    handleSubmit,
    handleCreateOrder,
    setImage,
    closeVotingModal,
    isButtonClickOnce,
    blocksList,
    unitList,
    setUnitListData
  };
};
