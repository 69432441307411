/* eslint-disable @typescript-eslint/no-var-requires */
import { Grid, Typography } from '@mui/material';
import useReservesController from './Reserves.controller';
import {
  ActionModal,
  CustomSearchInput,
  CustomTable,
  DeleteModal,
  InfoModal
} from '../../components';
import Container from './Reserves.style';
import { ReserveInformationModal } from './components/ReserveInformation/ReserveInformation';
import { JustificationModal } from './components/JustificationModal/JustificationModal';

const infoIcon = require('../../assets/images/info.svg');
const checkIcon = require('../../assets/images/checkIcon.svg');
const revokeIcon = require('../../assets/images/revokeicon.svg');
const trashIcon = require('../../assets/images/enterpriseactiontrash.svg');

export function Reserves() {
  const {
    tableHead,
    reservesId,
    isOpenDeleteModal,
    isSuccessfully,
    totalPages,
    totalReserves,
    isOpenInfoModal,
    reserveId,
    text,
    reserveList,
    sortReserves,
    handleActionModalData,
    handleDrawerOpen,
    handleDeleteModalOpen,
    handleModalOpen,
    onSubmitDelete,
    changePage,
    handleSearch,
    handleSetReserveId
  } = useReservesController();

  const actionModalIconAuthorizedRevoked = [<infoIcon.ReactComponent className="actionIcon" />];

  const actionModalIconCanceled = [
    <infoIcon.ReactComponent className="actionIcon" />,
    <trashIcon.ReactComponent className="actionIcon" />
  ];

  const actionModalIconPendent = [
    <infoIcon.ReactComponent className="actionIcon" />,
    <checkIcon.ReactComponent className="actionIcon" />,
    <revokeIcon.ReactComponent className="actionIcon" />
  ];

  function handleActionModalIcons(rowStatus: string) {
    if (rowStatus === 'Autorizado' || rowStatus === 'Revogado')
      return actionModalIconAuthorizedRevoked;

    if (rowStatus === 'Cancelado') return actionModalIconCanceled;

    return actionModalIconPendent;
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Reservas
          </Typography>
          <CustomSearchInput handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            rowsId={reservesId}
            tableHead={tableHead}
            tableData={reserveList || []}
            onClick={handleDrawerOpen}
            totalPages={totalPages}
            totalItems={totalReserves}
            funcToChangePage={changePage}
            handleSetItemId={handleSetReserveId}
            noFilesFoundText="Não há nenhum registro de reservas no momento."
            sortFunc={sortReserves}
            containId
            renderAction={(row) => {
              return (
                <ActionModal
                  ActionModalData={handleActionModalData(row.status)}
                  ButtonsIcon={handleActionModalIcons(row.status)}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Tem certeza que deseja excluir esta reserva?"
          onClickDelete={() => onSubmitDelete(reserveId)}
          handleModalClose={handleDeleteModalOpen}
        />
      )}

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}

      <ReserveInformationModal />
      <JustificationModal />
    </Container>
  );
}
