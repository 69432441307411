/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-useless-escape */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import startcase from 'lodash.startcase';
import * as yup from 'yup';
import {
  useBackToLastPageModalStore,
  useInfoModalStore,
  useResidentStore,
  useBlocksStore,
  useAdminORStore
} from '../../../../store';
import { FormRequiredFields } from './Modal.props';
import { formatResidentData } from '../../../../helpers/residentsHelpFunctions';
import { Errors } from '../../../../constants/Errors';

export const useModalController = (
  edit: boolean | undefined,
  toogleModal: () => void,
  id: string,
  isModalOpen: boolean,
  refresh: (page: number) => void
) => {
  const [residentById, setResidentById] = useState({
    id: '',
    name: '',
    phone: '',
    email: '',
    cpf: '',
    block: '',
    unitId: ''
  });

  const [isSelectedBlock, setIsSelectedBlock] = useState() as any;
  const [isSelectedUnity, setIsSelectedUnity] = useState() as any;
  const [isSelectedBlocks, setIsSelectedBlocks] = useState() as any;

  const [unitValue, setUnitValue] = useState('');
  const [blockValue, setBlockValue] = useState('');
  const [isInputValue, setIsInputValue] = useState('');
  const [isInputBlockValue, setIsInputBlockValue] = useState('');

  const { registerResidentRequest, getResidentDataRequest, editResidentRequest, setBlockList } =
    useResidentStore();
  const { enterpriseIdOfManager } = useAdminORStore();
  const { getSimpleBlockListData, setEnterpriseID, blocksList, unitList, setUnitListData } =
    useBlocksStore();
  const { handleBackToLastPageModalOpen } = useBackToLastPageModalStore();
  const { handleModalOpen, handleSetIsSuccessfully, handleSetText, isOpenInfoModal } =
    useInfoModalStore();

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g,
        'Nome inválido para morador, evite usar caracteres especiais.'
      )
      .required('Por favor, preencher campo nome.')
      .trim(),
    phone: yup
      .string()
      .matches(
        /^\(?[1-9]{2}\)? ?(?:[1-9])[0-9]{4}\-?([ .-]?)[0-9]{4}$/,
        'Por favor, digite um número válido.'
      )
      .required('Por favor, preencher campo telefone.'),
    email: yup
      .string()
      .test('must email', 'Digite um e-mail válido.', (value) => {
        if (String(value).split('.')[1] && String(value).split('.')[1]?.length <= 1) return false;
        return true;
      })
      .email('Digite um e-mail válido.')
      .required('Por favor, preencher campo e-mail.'),
    block: yup.string().required('Por favor, selecione o Bloco.'),
    unitId: yup.string().required('Por favor, selecione a Unidade.'),
    cpf: yup
      .string()
      .matches(
        /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}|\d{3}\.?\d{3}\.?\d{3}-?\d{2})$/,
        'Por favor, digite um cpf válido.'
      )
      .required('Por favor, preencher campo CPF.')
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      cpf: '',
      block: '',
      unitId: ''
    },
    resolver: yupResolver(schema)
  });

  function clearFormData() {
    setValue('block', '');
    setValue('unitId', '');
    setValue('cpf', '');
    setValue('email', '');
    setValue('name', '');
    setValue('phone', '');
  }

  async function getBlockListRequest() {
    const data = await getSimpleBlockListData(enterpriseIdOfManager);
    setBlockList(data);
  }

  const getResidentDataFromRequest = async () => {
    try {
      const { data } = await getResidentDataRequest(id);
      setValue('name', data.name);
      setValue('phone', data.phone);
      setValue('email', data.email);
      setValue('cpf', data.cpf);
      setValue('block', data.unit.block.id);
      // ts-nocheck
      setValue('unitId', data.unitId);
      setIsSelectedBlocks(data.unit.block.name as any);
      setBlockValue(data.unit.block.name);
      setIsInputBlockValue(data.unit.block.name);
      setIsSelectedUnity(data.unit.name as any);
      setUnitValue(data.unit.name);
      setIsInputValue(data.unit.name);
      setResidentById(data);
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      handleModalOpen();
      throw new Error('Invalid Credentials');
    }
  };

  const onSubmitRegister = async () => {
    try {
      const resident = getValues();
      const formatedData: any = {
        name: startcase(resident.name),
        phone: resident.phone,
        email: resident.email,
        cpf: resident.cpf,
        block: resident.block,
        unitId: resident.unitId
      };

      await registerResidentRequest(formatedData);
      toogleModal();
      handleSetIsSuccessfully(true);
      handleSetText('Morador cadastrado com sucesso!');
      handleModalOpen();
    } catch (error: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    }
    refresh(1);
  };

  window.onbeforeunload = function () {
    const { unitId, block, cpf, email, name, phone } = getValues();
    if (unitId || block || cpf || email || name || phone) {
      return true;
    }
    return null;
  };

  const onSubmitEdit = async () => {
    try {
      const resident = getValues();
      const data = {
        name: startcase(resident.name),
        phone: resident.phone,
        email: resident.email,
        cpf: resident.cpf,
        block: resident.block || '',
        unitId: resident.unitId || ''
      };
      const formatedData = formatResidentData(residentById, { ...data, id });

      await editResidentRequest(formatedData, id);
      toogleModal();
      handleSetIsSuccessfully(true);
      handleSetText('Morador Editado com sucesso!');
      handleModalOpen();
    } catch (error: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    }
    refresh(1);
  };

  const submit = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    edit ? onSubmitEdit() : onSubmitRegister();
  };

  const closeResidentModal = () => {
    const { unitId, block, cpf, email, name, phone } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    unitId || block || cpf || email || name || phone
      ? handleBackToLastPageModalOpen()
      : toogleModal();
  };

  useEffect(() => {
    getBlockListRequest();
    if (edit) getResidentDataFromRequest();
  }, []);

  const handleSelectBlock = async () => {
    await getSimpleBlockListData();
  };

  function handleSearchSelect(e: any) {
    if (!edit) {
      setUnitValue('');
      setValue('unitId', '');
    }
    setIsInputValue(e);
  }

  function handleSearchBlockSelect(e: any) {
    setBlockValue('');
    setValue('block', '');
    setIsInputBlockValue(e);
  }

  function cleanUnitValue() {
    setUnitValue('');
    setValue('unitId', '');
  }

  useEffect(() => {
    if (edit) return;
    if (!isModalOpen) clearFormData();
  }, [isOpenInfoModal]);

  useEffect(() => {
    setEnterpriseID(enterpriseIdOfManager);
    handleSelectBlock();
  }, [enterpriseIdOfManager]);

  useEffect(() => {
    if (edit) return;
    setUnitValue('');
    setValue('unitId', '');
  }, [blockValue]);

  return {
    control,
    errors,
    cleanUnitValue,
    isSelectedBlock,
    isSelectedUnity,
    residentById,
    blocksList,
    unitList,
    handleSubmit,
    register,
    getValues,
    submit,
    handleBackToLastPageModalOpen,
    closeResidentModal,
    setUnitListData,
    setIsSelectedBlock,
    isSelectedBlocks,
    handleSearchSelect,
    handleSearchBlockSelect,
    isInputValue,
    setIsInputValue,
    isInputBlockValue,
    setIsInputBlockValue,
    unitValue,
    blockValue,
    setUnitValue,
    setBlockValue
  };
};
