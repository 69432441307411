import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 90px 0px 0px 0px;

  width: 100%;
  height: calc(100vh - 90px);
  overflow-x: hidden !important;
  font-family: 'Inter', sans-serif;
`;

export const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: -1;
`;

export const Content = styled.div`
  width: 500px;
  height: auto;
  margin: auto;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  border-radius: 12px;
  background-color: #fff;
  box-shadow: 3px 5px 11px rgba(0, 0, 0, 0.15);

  .cardHeader {
    width: 100%;
    justify-self: center;
  }

  .cardTitle {
    font-size: 24px;
    font-weight: 600;
  }

  .cardText {
    font-size: 16px;
    margin-top: 8px;
  }

  .cardFooter {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
