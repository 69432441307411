import { Grid, Typography } from '@mui/material';

import useLostItemsController from './LostItems.controller';

import {
  ActionModal,
  BackToLastPageModal,
  CustomSearchInput,
  CustomTable,
  InfoModal,
  ConfirmModal
} from '../../components/index';
import { ModalInfo } from './ModalInfo/ModalInfo';

import Background from './LostItems.style';
import arrowRightUp from '../../assets/images/arrow-right-up.svg';
import infoIcon from '../../assets/images/info.svg';
import chckCircle from '../../assets/images/check-circle.svg';
import xCircle from '../../assets/images/x-circle.svg';

export function LostItems() {
  const {
    lostItemsTableHead,
    lostItemIDs,
    totalPages,
    lostItems,
    isOpenModalInfo,
    indexOfRow,
    isOpenBackToLastPageModal,
    isOpenInfoModal,
    totalItems,
    text,
    isSuccessfully,
    confirmModalOpen,
    handleActionModalData,
    successModal,
    handleDrawerOpen,
    handleSetConfirmModalOpen,
    changePage,
    executeActionFunc,
    handleAprove,
    handleReprove,
    toggleInfoModal,
    handleSetId,
    handleSearch,
    handleModalOpen,
    sortDataFormated
  } = useLostItemsController();

  const allIcons = [
    <img src={infoIcon} alt="info" />,
    <img src={chckCircle} alt="check circle" />,
    <img src={xCircle} alt="check circle" />,
    <img src={arrowRightUp} alt="right up" />
  ];

  const iconsWaintingAdm = [
    <img src={infoIcon} alt="info" />,
    <img src={chckCircle} alt="check circle" />,
    <img src={xCircle} alt="check circle" />
  ];

  const iconsWaitingOwner = [
    <img src={infoIcon} alt="info" />,
    <img src={arrowRightUp} alt="right up" />
  ];

  const iconInfo = [<img src={infoIcon} alt="info" />];

  function handleActionModalIcons(rowStatus: string) {
    const isOnlyInfoAllowed =
      rowStatus === 'RETIRADO' || rowStatus === 'REPROVADO' || rowStatus === 'PERDIDO';
    const isWitingAdmin = rowStatus === 'AGUARDANDO ADMIN';
    const isWaitingOwner = rowStatus === 'AGUARDANDO RETIRADA';

    if (isOnlyInfoAllowed) return iconInfo;

    if (isWitingAdmin) return iconsWaintingAdm;

    if (isWaitingOwner) return iconsWaitingOwner;

    return allIcons;
  }

  return (
    <Background>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Achados e Perdidos
          </Typography>
          <CustomSearchInput id="lost-search" handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            noFilesFoundText="Não há nenhum registro de objetos no momento."
            sortFunc={sortDataFormated}
            rowsId={lostItemIDs}
            tableHead={lostItemsTableHead}
            tableData={lostItems}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalItems}
            funcToChangePage={changePage}
            containId
            handleSetItemId={handleSetId}
            renderAction={(row) => {
              // eslint-disable-next-line react/jsx-no-useless-fragment
              return (
                <ActionModal
                  ActionModalData={handleActionModalData(row.status)}
                  ButtonsIcon={handleActionModalIcons(row.status)}
                />
              );
            }}
          />
        </Grid>
      </Grid>

      {isOpenModalInfo && (
        <ModalInfo
          indexOfRow={indexOfRow}
          isModalOpen={isOpenModalInfo}
          onComplete={successModal}
          toggleModal={toggleInfoModal}
          onClickConfirm={() => handleSetConfirmModalOpen(true)}
          handleAprove={handleAprove}
          handleReprove={handleReprove}
        />
      )}

      {isOpenBackToLastPageModal && (
        <BackToLastPageModal goToPage="/lostitems" handleModalClose={handleModalOpen} />
      )}

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}

      {confirmModalOpen && (
        <ConfirmModal
          haveDescription
          description="As alterações não poderão ser desfeitas."
          onClickConfirm={() => executeActionFunc()}
          title="Deseja alterar este status?"
          handleModalClose={() => handleSetConfirmModalOpen(!confirmModalOpen)}
        />
      )}
    </Background>
  );
}
