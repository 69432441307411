import { toast } from 'react-toastify';

export function isInvalidFileName(name: string) {
  const dotRegex = /\./gm;
  const dotMatches = name.match(dotRegex);
  const invalidFileName = dotMatches && dotMatches?.length > 1;

  if (invalidFileName) {
    toast.error(
      'Desculpe, o nome do arquivo que você tentou inserir contém pontos, o que não é permitido. Por favor, remova todos os pontos do nome do arquivo, exceto o que precede a extensão (por exemplo, ".txt" ou ".jpg").',
      { autoClose: 5000 }
    );
  }
  return invalidFileName;
}
