import { useEffect } from 'react';
import { Welcome } from '../Welcome/Welcome';
import { ControlPanelAdminOr } from '../ControlPanelAdminOr/ControlPanelAdminOr';
import { useRolesModalStore } from '../../store/index';

export function Home() {
  const { isAdmin } = useRolesModalStore();

  useEffect(() => {}, [isAdmin]);

  if (isAdmin) return <ControlPanelAdminOr />;
  return <Welcome />;
}
