import create from 'zustand';
import { BackToLastPageProps } from './BackToLastPage.props';

const initialState = {
  isOpenBackToLastPageModal: false,
  sidebarLink: ''
};

const useBackToLastPageModalStore = create<BackToLastPageProps>((set) => ({
  ...initialState,

  handleBackToLastPageModalOpen: () =>
    set((state) => ({ isOpenBackToLastPageModal: !state.isOpenBackToLastPageModal })),

  handleSetSidebarLink: (sidebarLink) => set((state) => ({ ...state, sidebarLink }))
}));

export { useBackToLastPageModalStore };
