import styled from 'styled-components';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;
export default Background;

export const ModalContent = styled.div`
  width: 850px;
  height: min-content;
  padding: 24px 32px;

  background: #fff;
  border-radius: 6px;
  position: relative;

  display: flex;
  flex-direction: column;

  .closeButton {
    position: absolute;
    align-self: flex-end;
  }

  .modalTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #121212;
    margin-bottom: 2rem;
    margin-top: 5px;
  }

  .inputContainer {
    display: flex;
    gap: 1rem;

    div {
      border-radius: 10px;
      margin-bottom: 5px;
    }
  }

  .inputTitle {
    font-family: 'Inter';
    font-weight: bold;
    margin-bottom: 8px;
  }

  .input {
    width: 100%;
    input {
      height: 18px;
      width: 100%;
    }
  }

  .unitInput {
    flex: 1;
    min-width: 100%;

    input {
      height: 11px;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }
  }

  .unitContainer {
    position: relative;
    width: 540px;
  }

  .contentContainer {
    max-height: 390px;
    overflow-y: scroll;
  }

  .buildingIcon {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .unitInputContainer {
    display: flex;
    gap: 20px;
  }

  .trashContainer {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 25%;
    cursor: pointer;
  }

  .trashIcon {
    height: 100%;
    width: 22px;
    svg,
    path {
      cursor: pointer;
      stroke: #662483;
      z-index: 100;
    }
  }

  .inputsRow {
    margin-bottom: 20px;
  }

  .saveButton {
    padding: 18px 0px;
    gap: 12px;
    width: 250px;
    height: 50px;
    background: #662483;
    color: #ffffff;
    border-radius: 12px;
    text-transform: none;
    font-size: 16px;
  }

  .saveButton:hover {
    background: #662483;
    color: #ffffff;
    opacity: 0.9;
  }

  .backButton {
    padding: 18px 0px;
    gap: 12px;
    width: 250px;
    height: 50px;
    background: #f0f1f5;
    color: #2c2c2c;
    border-radius: 12px;
    text-transform: none;
    font-size: 16px;
  }

  .buttonArea {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    margin-top: 32px;
  }

  .errorMessage {
    min-height: 24px;
    color: red;
    font-size: 13px;
    margin-top: -8px;
  }

  @media (max-width: 630px) {
    width: 450px;
  }

  @media (max-width: 530px) {
    width: 350px;
  }

  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  width: 100%;
`;
