import styled from 'styled-components';
import { Box } from '@mui/material';

export const Background = styled(Box)`
  display: flex;
  margin-top: 90px;
  padding-inline: 24px;
  padding-bottom: 40px;

  .header-container {
    width: 100%;
    margin-bottom: 30px;
  }

  .title {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #2c2c2c;
    margin-bottom: 8px;
  }

  .infoNextDayText {
    font-size: 12px;
    margin-top: 2px;
    color: #662483;
  }

  .subTitle {
    font-family: 'Public Sans';
    font-size: 16px;
    color: #8b8b8b;
  }

  .voltarBtn {
    padding: 14px 100px;
    margin-top: 1rem;
    margin-left: auto;
    cursor: pointer;
    color: #2c2c2c;
    background-color: #f0f1f5;
    border: none;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .date-time {
    width: 100%;
    min-height: 520px;
    margin-block: 0.8rem;
    display: flex;
    justify-content: space-between;

    .date-time-content {
      width: 49%;
    }

    @media (max-width: 1080px) {
      .date-time-content {
        width: 48%;
      }
    }

    @media (max-width: 450px) {
      .date-time-content {
        width: 100%;
      }
    }
  }
`;
