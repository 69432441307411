/* eslint-disable no-nested-ternary */
import { Box, Grid, Typography } from '@mui/material';
import NoFilesFound from '../../components/CustomTable/components/NoFilesFound/NoFilesFound';

import checksucess from '../../assets/images/checksucess.svg';
import checkerror from '../../assets/images/checkerror.svg';
import checkalert from '../../assets/images/checkalert.svg';

import Background, { ButtonColor } from './VisitorInfos.style';
import useVisitorInfosController from './VisitorInfos.controller';

export function VisitorInfos() {
  const { name, document, phone, block, unity, visitType, startedAt, endedAt, status } =
    useVisitorInfosController();

  return (
    <Background>
      <Grid container spacing={2} marginBottom={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h5" fontWeight={500}>
            Visitantes
          </Typography>
          <Grid item xs={12} marginBottom={2}>
            <Typography>Olá! Você está na tela de informações do visitante.</Typography>
          </Grid>
        </Grid>

        {name.length > 0 ? (
          <>
            <Grid item xs={12} wrap="wrap" marginBottom={2} className="visitorInfosGrid">
              <Box className="visitorInfosBox">
                <Typography marginRight={1}>Nome:</Typography>
                <Typography variant="h6" fontWeight={500}>
                  {name}
                </Typography>
              </Box>
              <Box className="visitorInfosBox">
                <Typography marginRight={1}>Documento:</Typography>
                <Typography variant="h6" fontWeight={500}>
                  {document}
                </Typography>
              </Box>
              <Box className="visitorInfosBox">
                <Typography marginRight={1}>Telefone:</Typography>
                <Typography variant="h6" fontWeight={500}>
                  {phone}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} marginBottom={6} className="visitorInfosGrid">
              <Box className="visitorInfosBox">
                <Typography marginRight={1}>Bloco:</Typography>
                <Typography variant="h6" fontWeight={500}>
                  {block}
                </Typography>
              </Box>
              <Box className="visitorInfosBox">
                <Typography marginRight={1}>Unidade:</Typography>
                <Typography variant="h6" fontWeight={500}>
                  {unity}
                </Typography>
              </Box>
              <Box className="visitorInfosBox">
                <Typography marginRight={1}>Tipo de visitante:</Typography>
                <Typography variant="h6" fontWeight={500}>
                  {visitType}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} marginBottom={12} className="visitorInfosGrid around">
              <Box className="visitorInfosBox">
                <Typography marginRight={1}>Liberado a partir de:</Typography>
                <Typography variant="h6" fontWeight={500}>
                  {startedAt}
                </Typography>
              </Box>
              <Box className="visitorInfosBox">
                <Typography marginRight={1}>até:</Typography>
                <Typography variant="h6" fontWeight={500}>
                  {endedAt}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} className="visitorInfosGrid center">
              <Box className="visitorInfosBox">
                {status === 'Autorizado' ? (
                  <img src={checksucess} alt="check sucessfuly" style={{ width: '64px' }} />
                ) : status === 'Pendente' ? (
                  <img src={checkalert} alt="alert check" style={{ width: '64px' }} />
                ) : (
                  <img src={checkerror} alt="error check" style={{ width: '64px' }} />
                )}
                <ButtonColor status={status}>
                  <Typography variant="h4" fontWeight={500}>
                    {status}
                  </Typography>
                </ButtonColor>
              </Box>
            </Grid>
          </>
        ) : (
          <NoFilesFound text="Visitante não encontrado!" />
        )}
      </Grid>
    </Background>
  );
}
