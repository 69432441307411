/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useAreaStore } from '../../../../store';
import { IReservationTimeFormRequiredFields } from './ReservationPeriod.props';

function useReservationPeriodController(weekDay: string, allTimeOfDays: [[]]) {
  const [allTimes, setAllTimes]: any = useState(allTimeOfDays || [[], [], [], [], [], [], []]);
  const { handleReservationPeriodAllWeek } = useAreaStore();

  const timeReservationSchema = yup.object().shape({
    initial_time: yup
      .string()
      .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inicial inválido.')
      .required('Por favor, preencher campo início'),
    final_time: yup
      .string()
      .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário final inválido.')
      .required('Por favor, preencher campo término.')
  });

  const areaDefaultValues = {
    initial_time: '',
    final_time: ''
  };

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<IReservationTimeFormRequiredFields>({
    defaultValues: areaDefaultValues,
    resolver: yupResolver(timeReservationSchema)
  });

  function getWeekDay() {
    let index = 0;
    switch (weekDay) {
      case 'Domingo':
        index = 0;
        break;
      case 'Segunda':
        index = 1;
        break;
      case 'Terça':
        index = 2;
        break;
      case 'Quarta':
        index = 3;
        break;
      case 'Quinta':
        index = 4;
        break;
      case 'Sexta':
        index = 5;
        break;
      case 'Sábado':
        index = 6;
        break;
      default:
        break;
    }

    return index;
  }

  function sortAllTime(times: { initial_time: string; final_time: string }[]) {
    times.sort((time1, time2) => {
      const timeA = time1.initial_time;
      const timeB = time2.initial_time;

      if (timeA < timeB) {
        return -1;
      }
      if (timeA > timeB) {
        return 1;
      }
      return 0;
    });
  }

  function setTimesOfDayRequest() {
    allTimeOfDays.forEach((day: []) => {
      if (day.length > 0) {
        handleReservationPeriodAllWeek(allTimeOfDays);
        setAllTimes(allTimeOfDays);
        // eslint-disable-next-line no-useless-return
        return;
      }
    });
  }

  function addNewTime() {
    const { final_time, initial_time } = getValues();
    const newAllTimesOfDay = [...allTimes[getWeekDay()], { initial_time, final_time }];
    const newAllTimesOfWeek = [...allTimes];
    newAllTimesOfWeek[getWeekDay()] = newAllTimesOfDay;

    sortAllTime(newAllTimesOfWeek[getWeekDay()]);
    const indexOfNewTime = newAllTimesOfDay.findIndex(
      (time) => time.initial_time === initial_time && time.final_time === final_time
    );

    if (
      newAllTimesOfDay.length > 1 &&
      newAllTimesOfDay.filter(
        (time: { initial_time: string; final_time: string }) => time.final_time < time.initial_time
      ).length > 1
    ) {
      toast.error('Já existe um período criado entre o horário inicial e final.');
      return;
    }

    if (
      newAllTimesOfDay.length > 1 &&
      indexOfNewTime === 0 &&
      newAllTimesOfDay[0].final_time >= newAllTimesOfDay[1].initial_time
    ) {
      toast.error('Já existe um período criado entre o horário inicial e final.');
      return;
    }

    if (
      newAllTimesOfDay.length > 1 &&
      indexOfNewTime === newAllTimesOfDay.length &&
      newAllTimesOfDay[indexOfNewTime].initial_time <=
        newAllTimesOfDay[indexOfNewTime - 1].final_time
    ) {
      toast.error('Já existe um período criado entre o horário inicial e final.');
      return;
    }

    if (
      indexOfNewTime !== 0 &&
      newAllTimesOfDay.length > 1 &&
      newAllTimesOfDay[indexOfNewTime].initial_time <=
        newAllTimesOfDay[indexOfNewTime - 1].final_time
    ) {
      toast.error('Já existe um período criado entre o horário inicial e final.');
      return;
    }

    if (
      indexOfNewTime !== newAllTimesOfDay.length - 1 &&
      newAllTimesOfDay.length > 1 &&
      newAllTimesOfDay[indexOfNewTime].final_time >=
        newAllTimesOfDay[indexOfNewTime + 1].initial_time
    ) {
      toast.error('Já existe um período criado entre o horário inicial e final.');
      return;
    }

    function checkForConflicts(schedule: any) {
      let nextDayEndTime = null;

      for (let i = 0; i < schedule.length; i++) {
        const events = schedule[i];

        for (let j = 0; j < events.length; j++) {
          const event = events[j];
          const initialTime = event.initial_time.split(':').map(Number);
          const finalTime = event.final_time.split(':').map(Number);

          if (
            finalTime[0] < initialTime[0] ||
            (finalTime[0] === initialTime[0] && finalTime[1] < initialTime[1])
          ) {
            nextDayEndTime = finalTime;
          } else {
            if (
              nextDayEndTime !== null &&
              (initialTime[0] < nextDayEndTime[0] ||
                (initialTime[0] === nextDayEndTime[0] && initialTime[1] <= nextDayEndTime[1]))
            ) {
              return true;
            }
            nextDayEndTime = null;
          }
        }

        if (schedule[(i + 1) % 7].length > 0) {
          const nextDayFirstEventInitialTime = schedule[(i + 1) % 7][0].initial_time
            .split(':')
            .map(Number);
          if (
            nextDayEndTime !== null &&
            (nextDayFirstEventInitialTime[0] < nextDayEndTime[0] ||
              (nextDayFirstEventInitialTime[0] === nextDayEndTime[0] &&
                nextDayFirstEventInitialTime[1] <= nextDayEndTime[1]))
          ) {
            return true;
          }
        }
        nextDayEndTime = null;
      }

      return false;
    }

    if (checkForConflicts(newAllTimesOfWeek)) {
      toast.error('Já existe um período criado entre o horário inicial e final.');
      return;
    }

    setAllTimes(newAllTimesOfWeek);
    handleReservationPeriodAllWeek(newAllTimesOfWeek);

    setValue('initial_time', '');
    setValue('final_time', '');
  }

  function removeTime(initial_time: string, final_time: string) {
    const newAllTimesOfDay = allTimes[getWeekDay()].filter(
      (time: { initial_time: string; final_time: string }) =>
        time.initial_time !== initial_time || time.final_time !== final_time
    );

    const newAllTimesOfWeek = [...allTimes];
    newAllTimesOfWeek[getWeekDay()] = newAllTimesOfDay;
    setAllTimes(newAllTimesOfWeek);
    handleReservationPeriodAllWeek(newAllTimesOfWeek);
  }

  useEffect(() => {
    sortAllTime(allTimes[0]);
  }, [allTimes]);

  useEffect(() => {
    setTimesOfDayRequest();
  }, []);

  return {
    allTimes,
    register,
    control,
    errors,
    addNewTime,
    handleSubmit,
    removeTime,
    getWeekDay
  };
}

export default useReservationPeriodController;
