/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { instance } from '../../api';

import {
  IAllInformativesDtoOutput,
  IInformativeDetailsByIdOutput,
  SearchInformative,
  IUnitsChoiced,
  IAllUnits,
  IRegisterInformativeData
} from './Informatives.dto';

import { formatData } from '../../../helpers/formatData';

function formatLocalName(localName: string) {
  let formatedLocalName;
  switch (localName) {
    case 'Block':
      formatedLocalName = 'Bloco';
      break;
    case 'Enterprise':
      formatedLocalName = 'Empreendimento';
      break;
    default:
      formatedLocalName = 'Unidade';
      break;
  }

  return formatedLocalName;
}

export const registerInformative = async (
  informativeData: IRegisterInformativeData
): Promise<void> => {
  try {
    await instance.request({
      method: 'POST',
      url: 'info-manager',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: informativeData,
      baseURL: process.env.REACT_APP_MS_NOTIFICATION
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editInformative = async (
  informativeData: IRegisterInformativeData,
  id: string
): Promise<void> => {
  const informativeId = id || window.location.href.split('/').pop();
  try {
    await instance.request({
      method: 'PUT',
      url: `info-manager/${informativeId}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: informativeData,
      baseURL: process.env.REACT_APP_MS_NOTIFICATION
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getInformativeDetailsById = async (
  InformativeId: string
): Promise<IInformativeDetailsByIdOutput> => {
  const id = InformativeId || window.location.href.split('/').pop();

  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `info-manager/${id}`,
      baseURL: process.env.REACT_APP_MS_NOTIFICATION
    });

    const formatedData = {
      id: data.data.id,
      subject: data.data.subject,
      description: data.data.description,
      blocksId: data.data.blocksId,
      unitsId: data.data.unitsId,
      unitsBlockId: data.data.unitsId ? data.data.units.blockId.id : '',
      createdBy: data.data.createdBy.name,
      createdAt: formatData(data.data.createdAt),
      local: formatLocalName(data.data.local),
      files: data.data.files
    };

    return { data: formatedData };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getUnitsOfInformativeById = async (blockId: string): Promise<IUnitsChoiced> => {
  const id = blockId || window.location.href.split('/').pop();

  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `info-manager/block-unit/${id}`,
      baseURL: process.env.REACT_APP_MS_NOTIFICATION
    });

    const formatedUnitData = data.data.units.map((unit: { name: string }) => unit.name);
    const formatedBlockData = data.data.blocks.map((block: { name: string }) => block.name);
    const formatedName = data.data.units[0] ? data.data.units[0].block.name : '';

    return {
      unit: formatedUnitData,
      block: formatedBlockData,
      blockName: formatedName
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortInformativesData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IAllInformativesDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `info-manager?page=${page}&includeMeta=true&sortDirection=${sortByDirection}&sortBy=${sortByField}&search=${searchString}`,
      baseURL: process.env.REACT_APP_MS_NOTIFICATION
    });

    const formatedData = data.data.map(
      (informative: {
        id: string;
        createdAt: string;
        subject: string;
        createdBy: { name: string };
        local: string;
        unitBlockName: string;
        blocks: { name: string }[];
      }) => {
        return {
          id: informative.id,
          createdAt: formatData(informative.createdAt),
          subject: informative.subject,
          createdBy: informative.createdBy ? informative.createdBy.name : '-',
          block:
            informative.local === 'Unit'
              ? informative.unitBlockName
              : informative.local === 'Block'
                ? informative.blocks.length === 1
                  ? informative.blocks[0].name
                  : '-'
                : '-',
          units: 'Ver'
        };
      }
    );

    const totalInformatives = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalInformatives, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteInformative = async (id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'DELETE',
      url: `info-manager/${id}`,
      baseURL: process.env.REACT_APP_MS_NOTIFICATION
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchInformative = async (
  informativesData: SearchInformative,
  sortByField: string,
  sortByDirection: string,
  page: string
): Promise<IAllInformativesDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `info-manager?search=${informativesData.queryText}&includeMeta=true&page=${page}&sortDirection=${sortByDirection}&sortBy=${sortByField}`,
      baseURL: process.env.REACT_APP_MS_NOTIFICATION
    });

    const formatedData = data.data.map(
      (informative: {
        id: string;
        createdAt: string;
        subject: string;
        createdBy: { name: string };
        local: string;
        unitBlockName: string;
        blocks: { name: string }[];
      }) => {
        return {
          id: informative.id,
          createdAt: formatData(informative.createdAt),
          subject: informative.subject,
          createdBy: informative.createdBy ? informative.createdBy.name : '-',
          block:
            informative.local === 'Unit'
              ? informative.unitBlockName
              : informative.local === 'Block'
                ? informative.blocks.length === 1
                  ? informative.blocks[0].name
                  : '-'
                : '-',
          units: 'Ver'
        };
      }
    );

    const totalInformatives = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalInformatives, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getAllUnitsOfBlock = async (blockId: string): Promise<IAllUnits[]> => {
  try {
    const { data } = await instance.get(
      `unit/manager/getAllUnits/${blockId}?limit=0&sortBy=unit_model.name`
    );

    const formatedUnitData = data.data.map((unit: { id: string; name: string }) => ({
      value: unit.id,
      label: unit.name
    }));

    return formatedUnitData;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getImageToRequest = async (pictureData: FormData, id: string): Promise<any> => {
  try {
    const data = await instance.request({
      method: 'POST',
      url: `informative/picture/${id}`,
      baseURL: process.env.REACT_APP_STORAGE_URL,
      data: pictureData
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const removeImageToRequest = async (id: string): Promise<any> => {
  try {
    const data = await instance.request({
      method: 'DELETE',
      url: `informative/delete/${id}`,
      baseURL: process.env.REACT_APP_STORAGE_URL
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
