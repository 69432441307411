import styled from 'styled-components';
import { CloseRounded } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

export const ModalContent = styled.div`
  background: #fff;

  width: 499px;
  max-height: 100%;
  padding: 24px 0;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #662483;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  justify-content: center;
  align-items: center;
  z-index: 999999999999;

  .itemImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 83%;
    border-radius: 13px;
    margin: 10px 0px 20px;
    img {
      border-radius: 13px;
      height: 140px;
      width: 330px;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    border-radius: 0px;
  }
`;

export const ModalCloseButton = styled(IconButton)`
  @media (max-width: 500px) {
    display: none !important;
  }
`;

export const ModalCloseButtonIcon = styled(CloseRounded)``;

export const ModalTitle = styled.p`
  font-family: 'Inter';
  text-align: center;
  margin-top: 30px;

  font-size: 24px;
  font-weight: 600;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const ModalInfoArea = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 74px;
  margin-top: 25px;

  img {
    width: 46px;
    height: 46px;
  }

  @media (max-width: 500px) {
    margin-left: 40px;
  }
`;

export const ModalInfoAreaRow = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 300px;
  margin-left: 12px;
`;

export const ModalInfoTitle = styled.p`
  color: #2c2c2c;

  font-size: 18px;
  font-weight: 500;
  font-family: 'Inter';
`;

interface IModalInfoSubtitleProps {
  pendingStatus?: boolean;
}

export const ModalInfoSubtitle = styled.p<IModalInfoSubtitleProps>`
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Inter';

  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 300px;
  max-height: 100px;
  flex-wrap: wrap;
  word-break: break-all;
`;

export const ModalButtonArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const ModalBackButton = styled(Button)``;
