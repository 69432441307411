import styled from 'styled-components';

const gradientSelector = {
  purple: 'linear-gradient(90deg, #662483 0%, #bd83d7 100%)',
  blue: 'linear-gradient(90deg, #099AD7 0%, #7FC0DB 100%)',
  green: 'linear-gradient(90deg, #00740C 0%, #79CD82 100%)'
};

interface Props {
  color: 'purple' | 'blue' | 'green';
}

const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 6px rgba(67, 89, 113, 0.2);
  border-radius: 4px;
  width: 100%;
  padding: 2rem;
  transition: all 0.2s ease-in-out;
  background-color: #fff;

  :hover {
    box-shadow: 0px 4px 10px rgba(67, 89, 113, 0.1);
  }

  .cardContainer {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: space-between;
  }

  .colorBar {
    height: 3px;
    background: ${({ color }) => gradientSelector[color]};
  }
`;

export default Container;
