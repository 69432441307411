/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid,
  Typography,
  TextField,
  Select,
  Box,
  Button,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel
} from '@mui/material';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { CustomFileInput } from './Components/CustomFileInput/CustomFileInput';
import useRegisterMaintenanceController from './RegisterMaintenance.controller';
import Container from './RegisterMaintenance.style';
import { Option } from './RegisterMaintenance.props';
import { BackToLastPageModal, InfoModal } from '../../components';

const closeIcon = require('../../assets/images/close.svg');

export function RegisterMaintenance() {
  const {
    control,
    isAdmin,
    errors,
    enterpriseList,
    blocksList,
    unitsList,
    maintenanceType,
    statusOptions,
    isBlockSelected,
    selectedEnterprise,
    selectedStatus,
    typeOfMaintenance,
    isOpenInfoModal,
    isOpenBackToLastPageModal,
    sidebarLink,
    isSuccessfully,
    text,
    selectedUnit,
    selectedBlock,
    isBackButtonClicked,
    blockError,
    backButonToBackListMaintenance,
    register,
    removeUnity,
    getStatusFromInput,
    getEnterpriseFromInput,
    getBlockFromInput,
    NavigateToListMaintenance,
    handleModalOpen,
    getUnitFromInput,
    getTypeOfMaintenanceFromInput,
    handleClickEnterpriseButton,
    handleClickBlockButton,
    createNewMaintenance,
    handleSubmit
  } = useRegisterMaintenanceController();

  return (
    <Container>
      <Grid container spacing={2} marginBottom={1}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5" fontWeight={500}>
            Cadastrar Manutenções
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={1}>
          <Typography className="titleInline">
            Olá! Você está na tela de cadastro das manutenções
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={isAdmin ? 3.9 : 7.8}>
          <Typography className="inputLabel">Assunto</Typography>
          <Controller
            name="name"
            control={control}
            render={() => (
              <TextField
                id="maintenance_name"
                className="valueInput"
                placeholder="Insira o assunto"
                {...register('name')}
                error={!!errors.name}
                sx={{ borderColor: '#A7A7A7', backgroundColor: '#E9E9E9', color: '#7D7D7D' }}
              />
            )}
          />
          <Typography className="errorMessage">{errors.name?.message}</Typography>
        </Grid>

        {isAdmin && (
          <Grid item xs={12} sm={12} md={isAdmin ? 3.95 : 7.8}>
            <Typography className="inputLabel">Empreendimento</Typography>
            <Box className="selectInputLabelContainer">
              <Select
                id="selectEnterprise"
                className="selectInput"
                placeholder="Selecione"
                {...register('enterprise')}
                error={!!errors.enterprise}
                displayEmpty
                value={selectedEnterprise.label}
                onChange={(e) => getEnterpriseFromInput(e.target.value)}
                sx={{
                  borderColor: '#A7A7A7',
                  backgroundColor: '#E9E9E9',
                  color: '#7D7D7D',
                  height: 50
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) return 'Selecione';
                  return selected[0].toUpperCase() + selected.substring(1).toLowerCase();
                }}>
                {enterpriseList &&
                  enterpriseList.map((option: Option) => (
                    <MenuItem key={option.value} value={option.value} id={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            <Typography className="errorMessage">{errors.enterprise?.message}</Typography>
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={3.95}>
          <Typography className="inputLabel">Status</Typography>
          <Box className="selectInputLabelContainer">
            <InputLabel className="inputLabelSelect">
              {selectedStatus ? '' : 'Selecione'}
            </InputLabel>

            <Select
              id="selectStatus"
              className="selectInput"
              {...register('status')}
              displayEmpty
              error={!!errors.status}
              onChange={(e: { target: { value: string } }) => getStatusFromInput(e.target.value)}
              sx={{
                borderColor: '#A7A7A7',
                backgroundColor: '#E9E9E9',
                color: '#7D7D7D',
                height: 50
              }}>
              {statusOptions &&
                statusOptions.map((option: Option) => (
                  <MenuItem key={option.value} value={option.value} id={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Typography className="errorMessage">{errors.status?.message}</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <Typography className="inputLabel">Tipo de Manutenção</Typography>
          <Box className="selectInputLabelContainer">
            <InputLabel className="inputLabelSelect">
              {typeOfMaintenance ? '' : 'Selecione'}
            </InputLabel>
            <Select
              id="selectMaintenance"
              className="selectInput"
              placeholder="Selecione"
              {...register('type')}
              error={!!errors.type}
              displayEmpty
              onChange={(e: { target: { value: string } }) =>
                getTypeOfMaintenanceFromInput(e.target.value)
              }
              sx={{
                borderColor: '#A7A7A7',
                backgroundColor: '#E9E9E9',
                color: '#7D7D7D',
                height: 50
              }}>
              {maintenanceType &&
                maintenanceType.map((option: Option) => (
                  <MenuItem key={option.value} value={option.value} id={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Typography className="errorMessage">{errors.type?.message}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3.4}>
          <Typography className="inputLabel">Data</Typography>
          <Controller
            name="date"
            control={control}
            render={({ field: { onChange, value: uhu } }) => (
              <MaskedInput
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                onChange={onChange}
                value={uhu}
                guide={false}
                render={(ref, props) => (
                  <TextField
                    id="maintenance_date"
                    className="valueInput deadlineInput"
                    placeholder="dd/mm/aaaa"
                    {...register('date')}
                    error={!!errors.date}
                    inputRef={ref}
                    {...props}
                  />
                )}
              />
            )}
          />
          <Typography className="errorMessage">{errors.date?.message}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3.4}>
          <Typography className="inputLabel">Horário</Typography>
          <Controller
            name="time"
            control={control}
            render={({ field: { onChange, value: timeValue } }) => (
              <MaskedInput
                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                onChange={onChange}
                value={timeValue}
                guide={false}
                render={(ref, props) => (
                  <TextField
                    id="maintenance_time"
                    className="valueInput"
                    placeholder="00:00"
                    {...register('time')}
                    error={!!errors.time}
                    inputRef={ref}
                    {...props}
                  />
                )}
              />
            )}
          />
          <Typography className="errorMessage">{errors.time?.message}</Typography>
        </Grid>
        <Grid item xs={12} marginTop={2} marginBottom={-2}>
          <Typography className="inputLabel">Local de Manutenção</Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={4.8}>
          <FormControl fullWidth>
            <RadioGroup
              className="radioInputContainer"
              defaultValue="Empreendimento"
              name="maintenanceSite">
              <FormControlLabel
                value="Empreendimento"
                control={
                  <Radio id="maintenance_enterpriseButton" onClick={handleClickEnterpriseButton} />
                }
                label="Empreendimento"
              />
              <FormControlLabel
                value="Bloco"
                control={<Radio id="maintenance_blockButton" onClick={handleClickBlockButton} />}
                label="Bloco"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {isBlockSelected && (
          <>
            <Grid item xs={12} sm={12} md={3.5}>
              <Box className="blockUnity">
                <Typography className="inputLabel">Bloco</Typography>
                <Box className="selectInputLabelContainer">
                  <Select
                    className="selectInput"
                    placeholder="Selecione"
                    displayEmpty
                    disabled={selectedEnterprise.value.length === 0 && isAdmin}
                    value={selectedBlock.label}
                    onChange={(e: { target: { value: string } }) =>
                      getBlockFromInput(e.target.value)
                    }
                    sx={{
                      borderColor: '#A7A7A7',
                      backgroundColor: '#E9E9E9',
                      color: '#7D7D7D',
                      height: 50
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) return 'Selecione';
                      return selected[0].toUpperCase() + selected.substring(1).toLowerCase();
                    }}>
                    {blocksList &&
                      blocksList.map((option: Option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              </Box>
              <Typography className="errorMessage">
                {selectedEnterprise.value.length === 0 && isAdmin
                  ? 'Selecione um empreendimento'
                  : errors.block?.message}
              </Typography>
              {blockError && <Typography className="errorMessage">{blockError}</Typography>}
            </Grid>
            <Grid item xs={12} sm={12} md={3.5}>
              <Box className="blockUnity">
                <Typography className="inputLabel">Unidade</Typography>

                <Box className="selectInputLabelContainer">
                  {selectedUnit.value.length > 0 && (
                    <Button className="closeIconContainer" onClick={removeUnity}>
                      <closeIcon.ReactComponent />
                    </Button>
                  )}
                  <Select
                    className="selectInput"
                    placeholder="Selecione"
                    displayEmpty
                    disabled={selectedBlock.value.length === 0}
                    value={selectedUnit.label}
                    onChange={(e: { target: { value: string } }) =>
                      getUnitFromInput(e.target.value)
                    }
                    sx={{
                      borderColor: '#A7A7A7',
                      backgroundColor: '#E9E9E9',
                      color: '#7D7D7D',
                      height: 50
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) return 'Selecione';
                      return selected.length > 1
                        ? selected[0].toUpperCase() + selected.substring(1).toLowerCase()
                        : selected;
                    }}>
                    {unitsList &&
                      unitsList.map((option: Option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              </Box>
              <Typography className="errorMessage">{errors.unit?.message}</Typography>
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={12} md={12}>
          <Typography className="subTitle">Descrição</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name="description"
            control={control}
            render={() => (
              <TextField
                id="maintenance_description"
                placeholder="Digite aqui"
                className="descriptionInput"
                multiline
                rows={8}
                {...register('description')}
              />
            )}
          />
          <Typography className="errorMessage">{errors.description?.message}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography className="subTitle">Arquivos</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomFileInput accept=".jpg, .png" type="image" />
          <Typography className="fileInputText">
            Arquivos permitidos (envio opcional): jpg, png.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box className="buttonContainer">
            <Box className="backAndSaveContainer">
              <Button
                onClick={handleSubmit(createNewMaintenance)}
                id="maintenance_register"
                className="saveButton">
                Salvar
              </Button>
              <Button className="backButton" onClick={backButonToBackListMaintenance}>
                Voltar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isOpenInfoModal && (
        <InfoModal
          isSuccessfully={isSuccessfully}
          onClick={isSuccessfully ? NavigateToListMaintenance : handleModalOpen}
          text={text}
        />
      )}
      {isOpenBackToLastPageModal && (
        <BackToLastPageModal
          goToPage={isBackButtonClicked ? '/maintenance' : sidebarLink || '/maintenance'}
        />
      )}
    </Container>
  );
}
