export function roleValidator(role: string) {
  const visibleRoles = [
    'manutencoes',
    'relatorios',
    'encomendas',
    'encomendas',
    'moradores',
    'ocorrencias',
    'visitantes',
    'informativos',
    'parceiros',
    'votacoes',
    'documentos',
    'achados/perdidos',
    'areas/reservas',
    'funcionarios',
    'boletos'
  ];

  return visibleRoles.includes(role) ? role : '';
}
