/* eslint-disable consistent-return */
import { format } from 'date-fns';

export function formatData(date: string) {
  if (date === 'undefined') return 'Data inválida';
  const newDate = date.match(/\d\d\d\d-\d\d-\d\d/gm) || '';
  const formatedData = newDate[0].replaceAll('-', '/');

  return format(new Date(formatedData), 'dd/MM/yyyy');
}
