/* eslint-disable react/jsx-props-no-spreading */
import { Box, Typography, Modal as ModalComponent, Button } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { useFinishMaintenanceModal } from './FinishMaintenanceModal.controller';
import { CustomFileInput } from './Components/CustomFileInput/CustomFileInput';
import { ModalProps } from './FinishMaintenanceModal.props';
import Background, { ModalContent } from './FinishMaintenanceModal.style';

export function FinishMaintenanceModal({
  isModalOpen,
  toggleModal,
  handleChangeStatus
}: ModalProps) {
  const { submit, closeRenameModal } = useFinishMaintenanceModal(toggleModal, handleChangeStatus);

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <Background>
        <Box>
          <ModalContent>
            <Box className="closeButton" onClick={closeRenameModal}>
              <CloseRounded />
            </Box>

            <Box className="header">
              <Typography className="title">Finalização da Manutenção</Typography>
              <Typography className="subtitle">
                Para indicar a finalização da manutenção, comprove com imagens.
              </Typography>
            </Box>

            <Box className="fileInputContainer">
              <CustomFileInput accept=".jpg, .png, .pdf" type="image" />
              <Typography className="subtitle">Arquivos permitidos: jpg, png e pdf.</Typography>
            </Box>

            <Box className="buttonArea">
              <Button
                id="maintenance_finish"
                style={{
                  backgroundColor: '#662483',
                  color: 'white',
                  width: '100%',
                  height: 48,
                  fontSize: '16px',
                  textTransform: 'capitalize',
                  borderRadius: 8
                }}
                variant="contained"
                disableElevation
                onClick={submit}>
                Finalizar
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#F0F1F5',
                  width: '100%',
                  height: 48,
                  fontSize: '16px',
                  textTransform: 'capitalize',
                  borderRadius: 8
                }}
                disableElevation
                onClick={closeRenameModal}>
                Voltar
              </Button>
            </Box>
          </ModalContent>
        </Box>
      </Background>
    </ModalComponent>
  );
}
