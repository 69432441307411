import { Box, Button, Typography, Link } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Container from './CustomFileInput.style';
import useCustomFileInput from './CustomFileInput.controller';
import { CustomFileInputProps } from './CustomFileInput.props';
import selectfile from '../../../../assets/images/selectfile.svg';

export function CustomFileInputPartners(props: CustomFileInputProps) {
  const { accept, type, hasImageSizeValidation = true, handleFile, isTenSize } = props;
  const { fileName, handleChangeFile, handleRemoveFile, signedimagePartnersUrl } =
    useCustomFileInput(type, hasImageSizeValidation, handleFile, isTenSize);
  return (
    <Container>
      {fileName || signedimagePartnersUrl ? (
        <Typography variant="body2" fontWeight={600} display="flex" alignItems="center">
          <Box className="selectedFile">
            <Typography variant="body2">
              {signedimagePartnersUrl ? (
                <Link
                  href={signedimagePartnersUrl}
                  target="_blank"
                  color="#0069c2"
                  className="link">
                  Visualizar
                </Link>
              ) : (
                fileName
              )}
            </Typography>
          </Box>
          <Button className="closeButton" onClick={handleRemoveFile}>
            <CloseOutlined />
          </Button>
        </Typography>
      ) : (
        <>
          <img src={selectfile} alt="select file icon" className="icon" />
          <Box flexDirection="column">
            <Typography marginBottom={0.5} variant="body2">
              Selecione um arquivo ou arraste aqui
            </Typography>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.5)">
              {isTenSize ? 'Arquivos até 10MB' : 'Arquivos até 100MB'}
            </Typography>
          </Box>
          <input
            id="fileInput"
            name="fileInput"
            type="file"
            accept={accept}
            onChange={handleChangeFile}
          />
        </>
      )}
    </Container>
  );
}
