import { useEffect, useState } from 'react';
import {
  useActionModalStore,
  useBackToLastPageModalStore,
  useInfoModalStore,
  useLoadModalStore,
  useLostItemsStore,
  usePaginationStore,
  useSortItemsStore
} from '../../store';

import { lostItemsTableHead } from '../../constants/LostItemsData';
import { Errors } from '../../constants/Errors';

function useLostItemsController() {
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [indexOfRow, setIndexOfRow] = useState(0);
  const [actionModalTypeFunction, setActionModalTypeFunction] = useState('');

  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const {
    isOpenInfoModal,
    handleModalOpen,
    text,
    handleSetText,
    handleSetIsSuccessfully,
    isSuccessfully
  } = useInfoModalStore();
  const { isOpenBackToLastPageModal } = useBackToLastPageModalStore();

  const { handleSetSortFields } = useSortItemsStore();

  const {
    id,
    field,
    direction,
    lostItemIDs,
    lostItems,
    totalLostItems,
    confirmModalOpen,
    handleSetId,
    handleSetLostItemIDs,
    handleSetLostItems,
    handleSetTotalLostItems,
    setSignedImgUrl,
    handleSetConfirmModalOpen,
    changeStatusLostItems,
    handleAproveOrReproveRequest,
    serchLostItemsRequest,
    getAllLostItemsSortRequest
  } = useLostItemsStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();

  const [searchString, setSearchString] = useState('');

  const toggleInfoModal = () => {
    lostItemIDs.map((item, index) => {
      if (item === id) {
        setIndexOfRow(index);
      }
      return index;
    });

    setIsOpenModalInfo((prev) => !prev);
  };

  function formatData(data: typeof lostItems) {
    return data.map((item: { id: string; resident: string; name?: string; status: string }) => {
      return {
        id: item.id,
        resident: item.resident,
        object: String(item.name),
        status: item.status
      };
    });
  }

  const getAllLostItems = async (page?: number) => {
    toogleLoading();

    try {
      const { data } = await getAllLostItemsSortRequest(
        String(page) !== 'undefined' ? String(page) : String(currentPage),
        field,
        direction,
        searchString
      );

      const { totalOrders: totalData, totalPages: pages } = data;

      const formatedAllData = formatData(data.data);

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      setTotalPages(pages);
      handleSetTotalLostItems(totalData);
      setTotalItems(totalData);

      if (data) {
        handleSetLostItemIDs(formatedRowsId);
        handleSetLostItems(formatedAllData);
      }
    } finally {
      toogleLoading();
    }
  };

  async function changePage(page: number) {
    toogleLoading();
    try {
      await getAllLostItems(page);
    } finally {
      toogleLoading();
    }
  }

  const successModal = () => {
    getAllLostItems();
  };

  async function sortDataFormated(page: string, sortByField: string, sortByDirection: string) {
    toogleLoading();

    try {
      const { data } = await getAllLostItemsSortRequest(
        page,
        sortByField,
        sortByDirection,
        searchString
      );
      const { totalOrders: totalData, totalPages: pages } = data;
      const formatedAllData = formatData(data.data);

      return { data: { data: formatedAllData, totalData, pages } };
    } finally {
      toogleLoading();
    }
  }

  async function handleSearch(value: string) {
    toogleLoading();
    try {
      setSearchString(value);
      const { data } = await serchLostItemsRequest(value, String(currentPage), field, direction);
      const { totalOrders: totalData, totalPages: pages } = data;
      const formatedAllData = formatData(data.data);

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      setTotalPages(pages);
      handleSetTotalLostItems(totalData);
      setTotalItems(totalData);
      if (data) {
        handleSetLostItemIDs(formatedRowsId);
        handleSetLostItems(formatedAllData);
      }
    } finally {
      toogleLoading();
    }
  }

  async function changeStatus(status: string) {
    toogleLoading();
    try {
      await changeStatusLostItems(status, id);
    } finally {
      toogleLoading();
    }
  }

  const handleWithdrawByOwner = async () => {
    try {
      await changeStatus('WITHDRAWN');
      handleSetConfirmModalOpen(false);
      handleSetText('Status do objeto foi atualizado!');
      handleSetIsSuccessfully(true);
      if (isOpenModalInfo) toggleInfoModal();
      handleModalOpen();
      getAllLostItems();
    } catch (err: any) {
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetConfirmModalOpen(false);
      handleSetIsSuccessfully(false);
      handleModalOpen();
    }
  };

  const handleAprove = async () => {
    toogleLoading();
    try {
      await handleAproveOrReproveRequest('aprove', id);
      handleSetConfirmModalOpen(false);
      handleSetText('Status do objeto foi atualizado!');
      handleSetIsSuccessfully(true);
      if (isOpenModalInfo) toggleInfoModal();
      handleModalOpen();
      getAllLostItems();
    } catch (err: any) {
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetConfirmModalOpen(false);
      handleSetIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  const handleReprove = async () => {
    toogleLoading();
    try {
      await handleAproveOrReproveRequest('reprove', id);
      handleSetConfirmModalOpen(false);
      handleSetText('Status do objeto foi atualizado!');
      handleSetIsSuccessfully(true);
      if (isOpenModalInfo) toggleInfoModal();
      handleModalOpen();
      getAllLostItems();
    } catch (err: any) {
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetConfirmModalOpen(false);
      handleSetIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  function executeActionFunc() {
    if (actionModalTypeFunction === 'approve') handleAprove();
    if (actionModalTypeFunction === 'disapprove') handleReprove();
    if (actionModalTypeFunction === 'owner') handleWithdrawByOwner();
  }

  const actionModalData = [
    {
      ButtonsText: 'Informações',
      OnClick: () => {
        toggleInfoModal();
        setSignedImgUrl('');
        setActionModalTypeFunction('owner');
      }
    },
    {
      ButtonsText: 'Aprovar no sistema',
      OnClick: () => {
        handleSetConfirmModalOpen(true);
        setActionModalTypeFunction('approve');
      }
    },
    {
      ButtonsText: 'Reprovar no sistema',
      OnClick: () => {
        handleSetConfirmModalOpen(true);
        setActionModalTypeFunction('disapprove');
      }
    },
    {
      ButtonsText: 'Retirada pelo proprietário',
      OnClick: () => {
        handleSetConfirmModalOpen(true);
        setActionModalTypeFunction('owner');
      }
    }
  ];

  const actionModalDataWaitingOwner = [
    {
      ButtonsText: 'Informações',
      OnClick: () => {
        toggleInfoModal();
        setSignedImgUrl('');
        setActionModalTypeFunction('owner');
      }
    },
    {
      ButtonsText: 'Retirada pelo proprietário',
      OnClick: () => {
        handleSetConfirmModalOpen(true);
        setActionModalTypeFunction('owner');
      }
    }
  ];

  const actionModalDataWithdrawnedOrReprovedOrLost = [
    {
      ButtonsText: 'Informações',
      OnClick: () => {
        toggleInfoModal();
        setSignedImgUrl('');
        setActionModalTypeFunction('owner');
      }
    }
  ];

  const actionModalDataWaitingAdm = [
    {
      ButtonsText: 'Informações',
      OnClick: () => {
        toggleInfoModal();
        setSignedImgUrl('');
        setActionModalTypeFunction('owner');
      }
    },
    {
      ButtonsText: 'Aprovar no sistema',
      OnClick: () => {
        handleSetConfirmModalOpen(true);
        setActionModalTypeFunction('approve');
      }
    },
    {
      ButtonsText: 'Reprovar no sistema',
      OnClick: () => {
        handleSetConfirmModalOpen(true);
        setActionModalTypeFunction('disapprove');
      }
    }
  ];

  function handleActionModalData(rowStatus: string) {
    const isOnlyInfoAllowed =
      rowStatus === 'RETIRADO' || rowStatus === 'REPROVADO' || rowStatus === 'PERDIDO';
    const isWitingAdmin = rowStatus === 'AGUARDANDO ADMIN';
    const isWaitingOwner = rowStatus === 'AGUARDANDO RETIRADA';

    if (isOnlyInfoAllowed) return actionModalDataWithdrawnedOrReprovedOrLost;

    if (isWitingAdmin) return actionModalDataWaitingAdm;

    if (isWaitingOwner) return actionModalDataWaitingOwner;

    return actionModalData;
  }

  function setLostItemIfExist() {
    if (lostItems.length <= 0) return;
    handleSetLostItemIDs(lostItemIDs);
  }

  function setSetSortFields() {
    handleSetSortFields(['resident', 'name', 'status']);
  }

  useEffect(() => {
    setLostItemIfExist();
  }, [lostItems]);

  useEffect(() => {
    getAllLostItems();
  }, [confirmModalOpen]);

  useEffect(() => {
    setSetSortFields();
  }, [totalLostItems]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    lostItemsTableHead,
    lostItems,
    lostItemIDs,
    isOpenModalInfo,
    totalPages,
    totalItems,
    indexOfRow,
    isOpenBackToLastPageModal,
    isOpenInfoModal,
    isSuccessfully,
    text,
    confirmModalOpen,
    handleActionModalData,
    handleDrawerOpen,
    handleSetConfirmModalOpen,
    changePage,
    successModal,
    handleAprove,
    handleReprove,
    toggleInfoModal,
    handleSetId,
    handleSearch,
    handleModalOpen,
    sortDataFormated,
    executeActionFunc
  };
}

export default useLostItemsController;
