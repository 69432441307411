import styled from 'styled-components';

const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
  margin-left: auto;

  .goBackEnterprises {
    background: #f0f1f5;
    border-radius: 14px;
    color: #000;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
    width: 100%;
    text-transform: none;
  }

  .goBackEnterprises:hover {
    opacity: 0.9;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export default Container;
