import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { functionalitiesToRequest, functionalities } from '../../../../constants';
import { useEnterpriseStore } from '../../../../store';

function useOwnerEnterpriseOptionsController() {
  const [functionalitiesId, setFunctionalitiesId] = useState<{ name: string }[]>([]);
  const { handleSetFunctionalities, getEnterpriseDataRequest } = useEnterpriseStore();
  const navigate = useNavigate();

  async function getFunctionalitiesId() {
    try {
      const { data } = await getEnterpriseDataRequest();
      setFunctionalitiesId(data.functionalitiesId);
    } catch {
      navigate('/enterprises');
      toast.error('Erro ao obter os dados das funcionalidades do empreendimento');
    }
  }

  useEffect(() => {
    getFunctionalitiesId();
  }, []);

  const handleSetFunctionalitiesId = (id: number) => {
    if (functionalitiesId.length === 0) {
      handleSetFunctionalities([{ name: functionalitiesToRequest[id] }]);
      setFunctionalitiesId([{ name: functionalitiesToRequest[id] }]);
      return;
    }

    let isRepeated = false;
    const newFunctionalitiesId = [...functionalitiesId];

    newFunctionalitiesId.forEach((functionality: { name: string }, index) => {
      if (functionality.name === functionalitiesToRequest[id]) {
        newFunctionalitiesId.splice(index, 1);
        isRepeated = true;
        handleSetFunctionalities(newFunctionalitiesId);
        setFunctionalitiesId(newFunctionalitiesId);
      }
    });

    if (isRepeated) return;

    newFunctionalitiesId.push({ name: functionalitiesToRequest[id] });
    handleSetFunctionalities(newFunctionalitiesId);
    setFunctionalitiesId(newFunctionalitiesId);
  };

  const requestEnterpriseData = async () => {
    try {
      const { data } = await getEnterpriseDataRequest();
      setFunctionalitiesId(data.functionalitiesId);
    } catch {
      navigate('/enterprises');
      toast.error('Erro ao obter os dados do empreendimento');
    }
  };

  useEffect(() => {
    requestEnterpriseData();
  }, []);

  const functionalitiesDisabled = [''];

  return {
    handleSetFunctionalitiesId,
    functionalities,
    functionalitiesId,
    functionalitiesDisabled
  };
}

export default useOwnerEnterpriseOptionsController;
