/* eslint-disable no-nested-ternary */
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { FormRequiredFields } from './RegisterMaintenance.props';
import {
  useLoadModalStore,
  useMaintenancesStore,
  useInfoModalStore,
  useRolesModalStore,
  useAdminORStore,
  useBackToLastPageModalStore,
  useRefreshChangedPagesStore
} from '../../store';
import { Errors } from '../../constants/Errors';
import { instance } from '../../services/api';

function useRegisterMaintenanceController() {
  const navigate = useNavigate();
  const maintenanceDefaultValues = {
    id: '',
    name: '',
    status: '',
    type: '',
    date: '',
    time: '',
    description: '',
    enterprise: ''
  };

  const { enterpriseIdOfManager } = useAdminORStore();

  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedEnterprise, setSelectedEnterprise] = useState({ value: '', label: '' });
  const [typeOfMaintenance, setTypeOfMaintenance] = useState('');
  const [selectedBlock, setSelectedBlock] = useState({ value: '', label: '', enterpriseId: '' });
  const [selectedUnit, setSelectedUnit] = useState({ value: '', label: '', block: '' });
  const [isBlockSelected, setIsBlockSelected] = useState(false);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [localType, setLocalType] = useState('Enterprise');
  const [enterpriseId, setEnterpriseId] = useState('');
  const [localId, setLocalId] = useState(enterpriseIdOfManager);
  const [blockError, setBlockError] = useState<undefined | string>(undefined);

  const { toogleLoading } = useLoadModalStore();
  const { isAdmin } = useRolesModalStore();
  const {
    enterpriseList,
    blocksList,
    unitsList,
    formatedImageToRequest,
    imagesToDelete,
    filesToGetLink,
    getImageLinkRequest,
    removeImageRequest,
    handleSetImagesToRequest,
    registerMaintenanceRequest,
    getEnterprisesList,
    getBlocksUnitsList,
    setEnterpriseList,
    setBlocksList,
    setUnitList,
    imageName,
    imageSize,
    imageType
  } = useMaintenancesStore();

  const { handleBackToLastPageModalOpen, isOpenBackToLastPageModal, sidebarLink } =
    useBackToLastPageModalStore();

  const { setChangedPage } = useRefreshChangedPagesStore();

  const {
    handleModalOpen,
    handleSetText,
    handleSetIsSuccessfully,
    isOpenInfoModal,
    isSuccessfully,
    text
  } = useInfoModalStore();

  const registerMaintenancechema = yup.object().shape({
    name: yup
      .string()
      .max(100, 'Campo assunto deve ter no máximo 100 caracteres.')
      .required('Por favor, preencher campo assunto.'),
    status: yup.string().required('Por favor, preencher campo status.'),
    type: yup.string().required('Por favor, preencher campo tipo da manutenção.'),
    date: yup
      .string()
      .test('date', 'Data inválida.', (value) => {
        if (value && value.length > 0) {
          if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value.match(/(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/((1[2-9]|[2-9][0-9])[0-9]{2})/)
              ?.length > 0
          ) {
            return true;
          }
          return false;
        }
        return true;
      })
      .notRequired(),
    time: yup
      .string()
      .test('time', 'Horário inválido.', (value) => {
        if (value && value.length > 0) {
          if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)?.length > 0
          ) {
            return true;
          }
          return false;
        }
        return true;
      })
      .notRequired(),
    description: yup.string().notRequired(),
    enterprise: yup
      .string()
      .test('enterpriseWhenIsAdmint', 'Por favor, preencha o campo empreendimento', (value) => {
        if (value && isAdmin && value.length > 0) return true;
        if (!isAdmin) return true;
        return false;
      })
      .notRequired()
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors, touchedFields }
  } = useForm<FormRequiredFields>({
    defaultValues: maintenanceDefaultValues,
    resolver: yupResolver(registerMaintenancechema)
  });

  function handleClickEnterpriseButton() {
    setIsBlockSelected(false);
    setLocalType('Enterprise');
    setLocalId(enterpriseId);
  }

  function handleClickBlockButton() {
    setIsBlockSelected(true);
    setLocalType('Block');
  }

  const maintenanceType = [
    { label: 'Preventiva', value: 'Preventive' },
    { label: 'Corretiva', value: 'Corrective' }
  ];

  const statusOptions = [
    { label: 'Pendente', value: 'Pendent' },
    { label: 'Em Andamento', value: 'InProgress' },
    { label: 'Concluída', value: 'Completed' }
  ];

  async function getEnterpriseData() {
    toogleLoading();
    try {
      const data = await getEnterprisesList();
      const formatedSelectData = data.map((enterprise) => {
        return {
          value: enterprise.id,
          label: enterprise.name
        };
      });
      setEnterpriseList(formatedSelectData);
    } finally {
      toogleLoading();
    }
  }

  function removeUnity() {
    setValue('unit', '');
    setSelectedUnit({ value: '', label: '', block: '' });
  }

  async function getBlocks() {
    toogleLoading();
    try {
      const data: any = await getBlocksUnitsList(isAdmin ? selectedEnterprise.value : '');
      const formatedBlockData = data.map((enterpriseBlocks: any) => {
        return {
          label: enterpriseBlocks.name,
          value: enterpriseBlocks.id,
          enterpriseId: enterpriseBlocks.enterpriseId
        };
      });
      setBlocksList(formatedBlockData);
    } finally {
      toogleLoading();
    }
  }

  const formatImage = async () => {
    const imagesFileToRequest = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const file of filesToGetLink) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        // eslint-disable-next-line no-await-in-loop
        const { data } = await getImageLinkRequest(formData, uuidv4());
        handleSetImagesToRequest([...formatedImageToRequest, data]);
        imagesFileToRequest.push(data);
      } catch (err: any) {
        toast.error(err.message);
      }
    }
    return imagesFileToRequest;
  };

  async function deleteImages(imagesLinkToDelete?: string[]) {
    if (imagesLinkToDelete) {
      await imagesLinkToDelete.forEach(async (imageLink) => {
        toogleLoading();
        try {
          const formatedLink =
            imageLink.length === 1
              ? imageLink[0].includes('http')
                ? imageLink[0].split('area/')[1]
                : imageLink[0]
              : imageLink.includes('http')
                ? imageLink.split('area/')[1]
                : imageLink;
          await removeImageRequest(formatedLink);
        } finally {
          toogleLoading();
        }
      });

      return;
    }
    await imagesToDelete.forEach(async (imageLink) => {
      toogleLoading();
      try {
        const formatedLink =
          imageLink.length === 1
            ? imageLink[0].includes('http')
              ? imageLink[0].split('area/')[1]
              : imageLink[0]
            : imageLink.includes('http')
              ? imageLink.split('area/')[1]
              : imageLink;
        await removeImageRequest(formatedLink);
      } finally {
        toogleLoading();
      }
    });
  }

  window.onbeforeunload = () => {
    const { date, description, status, block, enterprise, name, time, unit, type } = getValues();
    if (block || date || description || enterprise || status || name || time || type || unit) {
      setChangedPage(true);
      return true;
    }
    setChangedPage(false);
    return null;
  };

  const createNewMaintenance = async () => {
    toogleLoading();
    try {
      const { block, date, description, name, status, time, type, unit } = getValues();

      await deleteImages();

      const imagesFileLink: any = await formatImage();

      const unformatedDate = new Date(
        `${date.split('/')[1]}/${date.split('/')[0]}/${date.split('/')[2]}`
      );
      const formatedDate = new Date(
        unformatedDate.getFullYear(),
        unformatedDate.getMonth(),
        unformatedDate.getDate(),
        Number(time.split(':')[0]) - 3 || 12,
        Number(time.split(':')[1]) || 0
      );

      const files = imagesFileLink[0]
        ? [
            {
              name: imageName,
              size: imageSize,
              url: imagesFileLink[0].url,
              type: imageType
            }
          ]
        : [];

      const formatedData: any =
        description.length > 0
          ? {
              block,
              date: formatedDate,
              description,
              name,
              status,
              type,
              unit,
              local: localType,
              enterpriseId,
              localId: localId.length > 0 ? localId : enterpriseIdOfManager,
              files
            }
          : {
              block,
              date: formatedDate,
              name,
              status,
              type,
              unit,
              local: localType,
              enterpriseId,
              localId: localId.length > 0 ? localId : enterpriseIdOfManager,
              files
            };

      if (enterpriseIdOfManager && isBlockSelected && !block) {
        setBlockError('Por favor, selecione um bloco.');
        return;
      }
      setBlockError(undefined);

      await registerMaintenanceRequest(formatedData, isAdmin);
      handleSetText('Manutenção cadastrada com sucesso!');
      handleSetIsSuccessfully(true);
      handleModalOpen();
    } catch (err: any) {
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      handleModalOpen();
      setBlockError(undefined);
    } finally {
      toogleLoading();
    }
  };

  function getStatusFromInput(selected: string) {
    setValue('status', selected);
    setSelectedStatus(selected);
  }

  function getEnterpriseFromInput(selected: string) {
    setValue('enterprise', selected);
    const found: any = enterpriseList?.filter((item) => item.value === selected);
    setEnterpriseId(found[0].value);
    setLocalId(found[0].value);
    if (found) setSelectedEnterprise(found[0]);
  }

  function getTypeOfMaintenanceFromInput(selected: string) {
    setValue('type', selected);
    setTypeOfMaintenance(selected);
  }

  async function getBlockFromInput(selected: string) {
    toogleLoading();
    try {
      setValue('block', selected);
      const found: any = blocksList?.filter((item) => item.value === selected);
      setLocalId(found[0].value);
      if (found) setSelectedBlock(found[0]);
      const { data } = isAdmin
        ? await instance.get(`block/${found[0].value}?limit=0&sortBy=unit_model.name`)
        : await instance.get(
            `unit/manager/getAllUnits/${found[0].value}?limit=0&sortBy=unit_model.name`
          );
      const formatedUnits = isAdmin
        ? data.units.map((unitRequest: any) => {
            const formatedUnit = {
              label: unitRequest.name,
              value: unitRequest.id
            };
            return formatedUnit;
          })
        : data.data.map((unitRequest: any) => {
            const formatedUnit = {
              label: unitRequest.name,
              value: unitRequest.id
            };
            return formatedUnit;
          });
      setUnitList(formatedUnits);
    } finally {
      toogleLoading();
    }
  }

  function getUnitFromInput(selected: string) {
    setValue('unit', selected);
    setLocalType('Unit');
    const found: any = unitsList?.filter((item) => item.value === selected);
    setLocalId(found[0].value);
    if (found) setSelectedUnit(found[0]);
  }

  function clearFormStates() {
    handleSetImagesToRequest([]);
    setSelectedStatus('');
    setSelectedEnterprise({ value: '', label: '' });
    setTypeOfMaintenance('');
    setSelectedBlock({ value: '', label: '', enterpriseId: '' });
    setSelectedUnit({ value: '', label: '', block: '' });
  }

  function NavigateToListMaintenance() {
    handleModalOpen();
    navigate('../maintenance', { replace: true });
  }

  const backButonToBackListMaintenance = () => {
    setIsBackButtonClicked(true);
    const { block, date, description, enterprise, name, status, time, type, unit } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (block || date || description || enterprise || name || status || time || type || unit) {
      handleBackToLastPageModalOpen();
      setChangedPage(true);
      return;
    }
    setChangedPage(false);
    navigate('/maintenance', { replace: true });
  };

  function isModified() {
    const { block, date, description, enterprise, name, status, time, type, unit } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    block || date || description || enterprise || name || status || time || type || unit
      ? setChangedPage(true)
      : setChangedPage(false);
  }

  useEffect(() => {
    clearFormStates();
  }, []);

  useEffect(() => {
    isModified();
  }, [touchedFields]);

  useEffect(() => {
    getEnterpriseData();
    getBlocks();
  }, [selectedEnterprise.value && isBlockSelected, localId, enterpriseIdOfManager]);

  return {
    control,
    isAdmin,
    errors,
    enterpriseList,
    blocksList,
    unitsList,
    maintenanceType,
    statusOptions,
    isBlockSelected,
    selectedEnterprise,
    selectedStatus,
    typeOfMaintenance,
    selectedUnit,
    selectedBlock,
    isOpenInfoModal,
    isSuccessfully,
    text,
    isOpenBackToLastPageModal,
    sidebarLink,
    isBackButtonClicked,
    blockError,
    backButonToBackListMaintenance,
    register,
    removeUnity,
    NavigateToListMaintenance,
    handleModalOpen,
    getStatusFromInput,
    getEnterpriseFromInput,
    getBlockFromInput,
    getUnitFromInput,
    getTypeOfMaintenanceFromInput,
    handleClickEnterpriseButton,
    handleClickBlockButton,
    createNewMaintenance,
    handleSubmit
  };
}

export default useRegisterMaintenanceController;
