import { useEffect, useState } from 'react';
import { useLoadModalStore, useDashboardStore } from '../../store';

export const useDashboardController = () => {
  const { toogleLoading } = useLoadModalStore();
  const {
    // enterprise data
    enterpriseList,
    selectedEnterprise,
    setSelectedEnterprise,
    getEnterpriseListRequest,
    // dashboard data
    dashboardData,
    // requests
    getGeneralDashboardDataRequest,
    getDashboardDataByEnterpriseRequest
  } = useDashboardStore();

  const [inputSearchValue, setInputSearchValue] = useState(null);
  const isGeneralDashboard = !selectedEnterprise || selectedEnterprise?.name === 'Geral';

  async function getEnterpriseList() {
    toogleLoading();
    try {
      await getEnterpriseListRequest();
    } finally {
      toogleLoading();
    }
  }

  async function getDashboardData() {
    toogleLoading();
    try {
      if (isGeneralDashboard) {
        await getGeneralDashboardDataRequest();
      } else {
        const { id } = selectedEnterprise;
        await getDashboardDataByEnterpriseRequest(id);
      }
    } finally {
      toogleLoading();
    }
  }

  useEffect(() => {
    getEnterpriseList();
  }, []);

  useEffect(() => {
    getDashboardData();
  }, [selectedEnterprise]);

  return {
    // enterprise
    enterpriseList,
    selectedEnterprise,
    setSelectedEnterprise,
    inputSearchValue,
    setInputSearchValue,
    // dashboard
    dashboardData
  };
};
