import useInfoCardControler from './InfoCard.controller';
import Container from './InfoCard.style';

function InfoCard() {
  const { imageLink } = useInfoCardControler();
  return (
    <Container>
      <div className="imageContainer">
        <div className="imageTitle">
          <p>Piscina fechada no final de semana.</p>
        </div>
        <img src={imageLink} style={{ width: '100%', height: '100%' }} alt="order" />
        <div className="descriptionContainer">
          <p>
            Prezados, venho por meio desta informar que a piscina se encontrará fechada para
            manutenção...
          </p>
        </div>
      </div>
    </Container>
  );
}

export default InfoCard;
