import styled from 'styled-components';

const Container = styled.div`
  .dayButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7.70519px;
    width: 136px;
    height: 45px;
    background: #ffffff;
    border: 0.770519px solid #2c2c2c;
    border-radius: 4.62312px;
    cursor: pointer;
    transition: all ease-in 100ms;
  }

  .dayButtonContainer:hover {
    background: rgba(155, 155, 155, 0.05);
  }

  .dayButtonSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7.70519px;
    width: 136px;
    height: 45px;
    border: 0.770519px solid #2c2c2c;
    border-radius: 4.62312px;
    cursor: pointer;
    transition: all ease-in 100ms;
    background: #662483;
    color: #ffffff;

    p {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      color: #fff;
    }
  }

  p {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    color: #2c2c2c;
  }
`;

export default Container;
