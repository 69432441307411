import { Typography, Grid } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  width: 419px;
  height: 590px;

  .buttonPDF {
    width: 48px;
    height: 36px;
    padding: 12px 16px;

    display: grid;
    place-content: center;

    background: #ebdcf1;
    border-radius: 6px;
    border: 0;
    cursor: pointer;

    svg {
      color: #662483;
    }
  }

  .borderButtom {
    padding: 2rem;
    border-bottom: 2px solid #f0f0f0;
    width: 100%;

    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }

  .pieChart {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .hasNotVote {
    height: 300px;
    display: grid;
    place-content: center;
  }
`;
export const Subtitle = styled(Typography)`
  word-wrap: break-word;
  word-break: break-all;
  width: 100%;
`;

export const GraphResults = styled(Grid)`
  padding: 1rem 2rem;
`;
