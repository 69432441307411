export function formatRoles(role: string) {
  let roleName;

  switch (role) {
    case 'manutencoes':
      roleName = 'Manutenções';
      break;
    case 'relatorios':
      roleName = 'Relatórios';
      break;
    case 'encomendas':
      roleName = 'Encomendas';
      break;
    case 'moradores':
      roleName = 'Moradores';
      break;
    case 'ocorrencias':
      roleName = 'Ocorrências';
      break;
    case 'visitantes':
      roleName = 'Visitantes';
      break;
    case 'informativos':
      roleName = 'Informativos';
      break;
    case 'parceiros':
      roleName = 'Parceiros';
      break;
    case 'votacoes':
      roleName = 'Votações';
      break;
    case 'documentos':
      roleName = 'Documentos';
      break;
    case 'achados/perdidos':
      roleName = 'Achados e Perdidos';
      break;
    case 'areas/reservas':
      roleName = 'Áreas e Reservas';
      break;
    case 'funcionarios':
      roleName = 'Funcionários';
      break;
    case 'boletos':
      roleName = 'Boletos';
      break;
    default:
      roleName = '';
      break;
  }
  return roleName;
}
