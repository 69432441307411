import create from 'zustand';
import { getOwnerManual } from '../../services/requests/welcomeScreen/welcomeScreen.request';
import { WelcomeScreenProps } from './WelcomeScreen.props';

const initialState = {
  ownerManualURL: null
};

export const useWelcomeScreenStore = create<WelcomeScreenProps>((set) => ({
  ...initialState,

  getOwnerManualRequest: async () => {
    try {
      const { manualUrl } = await getOwnerManual();
      if (manualUrl) {
        set({ ownerManualURL: manualUrl });
      } else {
        set({ ownerManualURL: null });
      }
      return manualUrl;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));
