/* eslint-disable @typescript-eslint/no-var-requires */
import { Grid, Typography } from '@mui/material';
import useInformativesController from './Ocurrences.controller';
import { CustomSearchInput } from '../../components';
import { OcurrencesTable } from './components/OcurrencesTable/CustomTable';
import Container from './Ocurrences.style';

export function Ocurrences() {
  const {
    ocurrencesTableHeader,
    totalPages,
    totalOcurrences,
    ocurrencesId,
    ocurrencesList,
    handleDrawerOpen,
    changePage,
    handleSearch,
    sortAllData,
    handleSetOcurrenceId
  } = useInformativesController();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Ocorrências
          </Typography>
          <CustomSearchInput handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12}>
          <OcurrencesTable
            rowsId={ocurrencesId}
            tableHead={ocurrencesTableHeader}
            tableData={ocurrencesList}
            onClick={() => handleDrawerOpen()}
            totalPages={totalPages}
            totalItems={totalOcurrences}
            funcToChangePage={changePage}
            handleSetItemId={handleSetOcurrenceId}
            sortFunc={sortAllData}
            containId
          />
        </Grid>
      </Grid>
    </Container>
  );
}
