/* eslint-disable react/no-array-index-key */
import { Box, Button, Typography } from '@mui/material';
import { Fragment } from 'react';
import { CloseOutlined } from '@mui/icons-material';
import { ProgressBar as ProgressBarComponent } from '../ProgressBar/ProgressBar';
import Container from './CustomFileInput.style';
import useCustomFileInput from './CustomFileInput.controller';
import { CustomFileInputProps } from './CustomFileInput.props';
import selectfile from '../../../assets/images/selectfile.svg';

export function CustomFileInput(props: CustomFileInputProps) {
  const { accept, foldId, isProgress, setSuccess, setText, toggleSuccessModal } = props;
  const { handleChangeFile, handleRemoveFile, files, getFileFinalized } = useCustomFileInput(
    foldId,
    setSuccess,
    setText,
    toggleSuccessModal
  );

  return (
    <Container>
      <Box className="title">
        {files.map((file: File, index: number) => (
          <Fragment key={`${file}, ${index}`}>
            <Box className="selectedFile">
              <Box>
                <p className="fileName">
                  {file.name}({file.size} bytes )
                </p>
              </Box>
              <Button className="closeButton" onClick={() => handleRemoveFile(file.name)}>
                <CloseOutlined />
              </Button>
            </Box>

            {isProgress[index] !== '0' && isProgress[index] !== '100' && (
              <div className="containerBar">
                <ProgressBarComponent isProgress={isProgress[index]} />
              </div>
            )}

            {getFileFinalized()[index] && file.name === getFileFinalized()[index].fileName && (
              <div className="containerBar">
                <ProgressBarComponent isProgress="100" />
              </div>
            )}
          </Fragment>
        ))}
      </Box>

      <Box flexDirection="column" className="fileBox" display={files.length > 0 ? 'none' : 'flex'}>
        <Box className="fileImageIcon">
          <img src={selectfile} alt="select file icon" className="icon" />
        </Box>
        <>
          <Typography marginBottom={0.5} variant="body2">
            Selecione um arquivo ou arraste aqui
          </Typography>
          <Typography variant="body2" color="rgba(0, 0, 0, 0.5)">
            Arquivos até 100MB
          </Typography>
        </>
      </Box>
      <input
        id="fileInput"
        name="fileInput"
        multiple
        type="file"
        accept={accept}
        onChange={handleChangeFile}
      />
    </Container>
  );
}
