import SplitScreenProps from './SplitScreen.props';

import Container, { Panel } from './SplitScreen.style';

export function SplitScreen(props: SplitScreenProps) {
  const { Left, Right, leftWeight, rightWeight } = props;
  return (
    <Container>
      <Panel weight={leftWeight}>
        <Left />
      </Panel>
      <Panel weight={rightWeight}>
        <Right />
      </Panel>
    </Container>
  );
}
