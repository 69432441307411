import { Grid, Typography } from '@mui/material';
import { EditOutlined, DeleteOutline } from '@mui/icons-material';
import Background from './Documents.style';

import {
  CustomSearchInput,
  ActionModal,
  DeleteModal,
  CustomButton,
  BackToLastPageModal,
  InfoModal,
  CustomTable
} from '../../components/index';
import { Modal } from './Modal/Modal';

import useDocumentsController from './Documents.controller';

export function Documents() {
  const {
    isModalRegisterOpen,
    toggleRegisterModal,
    isModalEditOpen,
    toggleEditModal,
    successModal,
    isOpenDeleteModal,
    actionModalData,
    isSuccessfully,
    isOpenBackToLastPageModal,
    closeModal,
    handleModalOpen,
    isOpenInfoModal,
    handleDeleteModalOpen,
    handleDrawerOpen,
    sortDocumentsData,
    deleteFolderOfRow,
    changePage,
    goToFilesPage,
    handleSearch,
    handleSetFolderId,
    variant,
    tableHeaders,
    rowsId,
    totalPages,
    totalFolders,
    folders,
    text
  } = useDocumentsController();

  return (
    <Background>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} lg={8.7}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Documentos
          </Typography>
          <CustomSearchInput id="doc-search" handleSearch={handleSearch} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3.3} alignSelf="flex-end">
          <CustomButton id="doc-new-folder" onClick={toggleRegisterModal} text="Nova Pasta" />
        </Grid>
        <Grid item md={1} lg={1.5} className="divider" />

        <Grid item xs={12}>
          <CustomTable
            sortFunc={sortDocumentsData}
            rowsId={rowsId}
            tableHead={tableHeaders}
            tableData={folders}
            onClick={handleDrawerOpen}
            totalPages={totalPages}
            totalItems={totalFolders}
            funcToChangePage={changePage}
            handleSetItemId={handleSetFolderId}
            funcToClickLine={goToFilesPage}
            isClickLine
            containId
            ActionModal={
              <ActionModal
                ActionModalData={actionModalData}
                ButtonsIcon={[
                  <EditOutlined style={{ zIndex: 3 }} />,
                  <DeleteOutline style={{ zIndex: 3 }} />
                ]}
              />
            }
            noFilesFoundText="Não há nenhum registro de documentos no momento."
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Tem certeza que deseja excluir esta pasta?"
          onClickDelete={deleteFolderOfRow}
          handleModalClose={handleDeleteModalOpen}
          isInfoModalOpen
        />
      )}

      <Modal
        variant={variant}
        isModalOpen={isModalRegisterOpen}
        toggleModal={toggleRegisterModal}
        errorModal={() => {}}
        title="Digite o nome da pasta"
        onComplete={successModal}
      />

      <Modal
        variant={variant}
        isModalOpen={isModalEditOpen}
        toggleModal={toggleEditModal}
        errorModal={() => {}}
        title="Renomear pasta"
        onComplete={successModal}
      />

      {isOpenBackToLastPageModal && (
        <BackToLastPageModal goToPage="/docs" handleModalClose={closeModal} />
      )}

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}
    </Background>
  );
}
