import { SlideButtonProps } from './SlideButton.props';
import { Container, Slide } from './SlideButton.style';

export const SlideButton = ({ onClick, isDelivery }: SlideButtonProps) => {
  return (
    <Container type="button" onClick={onClick}>
      <span
        id="ocurrence_yesButton"
        style={{
          zIndex: 1,
          marginLeft: 36,
          fontFamily: 'Mulish',
          fontWeight: 700,
          fontSize: 16,
          color: '#2C2C2C'
        }}>
        Sim
      </span>
      <span
        id="ocurrence_notButton"
        style={{
          zIndex: 1,
          marginRight: 36,
          fontFamily: 'Mulish',
          fontWeight: 700,
          fontSize: 16,
          color: '#2C2C2C'
        }}>
        Não
      </span>
      <Slide isDelivery={isDelivery} />
    </Container>
  );
};
