import { instance } from '../../api';

import {
  IChangePasswordDtoInput,
  ILoginDtoInput,
  ILoginDtoOutput,
  IResetPasswordDtoInput
} from './auth.dto';

export const login = async ({ email, password }: ILoginDtoInput): Promise<ILoginDtoOutput> => {
  try {
    const { data } = await instance.post('login', { email, password });

    return {
      accessToken: data.access_token,
      refreshToken: data.refresh_token
    };
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const resetPassword = async ({ email }: IResetPasswordDtoInput): Promise<void> => {
  await instance.post('reset', {
    email
  });
};

export const changePassword = async ({
  password,
  confirmPassword,
  token
}: IChangePasswordDtoInput): Promise<void> => {
  try {
    await instance.post(`changePassword/${token}`, {
      password,
      passwordConfirmation: confirmPassword
    });
  } catch (error) {
    throw new Error('Invalid Credentials');
  }
};

export const changeWebPassword = async (email: string): Promise<void> => {
  try {
    await instance.post('changeWebPassword', {
      email
    });
  } catch (error) {
    throw new Error('Invalid Credentials');
  }
};
