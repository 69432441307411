function getCurrentSidebarLink(sidebarClickedName: string) {
  let pathname;

  const currentPath =
    window.location.pathname.match(/enterprises/gm) ||
    window.location.pathname.match(/home/gm) ||
    window.location.pathname.match(/maintenance/gm) ||
    window.location.pathname.match(/reports/gm) ||
    window.location.pathname.match(/orders/gm) ||
    window.location.pathname.match(/residents/gm) ||
    window.location.pathname.match(/employees/gm) ||
    window.location.pathname.match(/occurrences/gm) ||
    window.location.pathname.match(/visitors/gm) ||
    window.location.pathname.match(/informatives/gm) ||
    window.location.pathname.match(/partners/gm) ||
    window.location.pathname.match(/vote/gm) ||
    window.location.pathname.match(/docs/gm) ||
    window.location.pathname.match(/lostitems/gm) ||
    window.location.pathname.match(/area/gm) ||
    window.location.pathname.match(/payments/gm) ||
    '';

  switch (sidebarClickedName) {
    case 'Início':
      pathname = 'home';
      break;
    case 'Empreendimentos':
      pathname = 'enterprises';
      break;
    case 'Manutenções':
      pathname = 'maintenance';
      break;
    case 'Relatórios':
      pathname = 'reports';
      break;
    case 'Encomendas':
      pathname = 'orders';
      break;
    case 'Moradores':
      pathname = 'residents';
      break;
    case 'Ocorrências':
      pathname = 'occurrences';
      break;
    case 'Visitantes':
      pathname = 'visitors';
      break;
    case 'Informativos':
      pathname = 'informatives';
      break;
    case 'Parceiros':
      pathname = 'partners';
      break;
    case 'Votações':
      pathname = 'vote';
      break;
    case 'Documentos':
      pathname = 'docs';
      break;
    case 'Achados e perdidos':
      pathname = 'lostitems';
      break;
    case 'Áreas/Reservas':
      pathname = 'area';
      break;
    case 'Funcionários':
      pathname = 'employees';
      break;
    case 'Administradores':
      pathname = 'employees';
      break;
    case 'Boletos':
      pathname = 'payments';
      break;
    default:
      break;
  }

  return currentPath[0] === pathname;
}
export default getCurrentSidebarLink;
