import create from 'zustand';
import {
  deleteBlock,
  searchBlocks,
  sortBlocksData,
  editBlock,
  registerBlock,
  getPdfToRequest,
  removePdfToRequest,
  getBlockByID,
  getSimpleBlockList
} from '../../services/requests/Blocks/Blocks.request';
import { IBlocksStoreProps } from './Blocks.props';

const initialState = {
  enterpriseID: '',
  enterpriseName: '',
  selectedEnterprise: undefined,
  blockName: '',
  blockID: '',
  blocksIDs: [],
  blocksList: [],
  blockList: [],
  blockByID: undefined,
  isOpenModal: false,
  isOpenRegisterModal: false,
  isOpenRenameModal: false,
  isSuccessfully: false,
  successMessage: '',
  page: '1',
  field: 'block_model.name',
  direction: 'ASC',
  unitList: [],
  totalBlocks: 0,
  totalPages: 1
};

export const useBlocksStore = create<IBlocksStoreProps>((set, get) => ({
  ...initialState,

  setEnterpriseID: (enterpriseID) => set((state) => ({ ...state, enterpriseID })),
  setSelectedEnterprise: (selectedEnterprise) => set((state) => ({ ...state, selectedEnterprise })),
  setEnterpriseName: (enterpriseName) => set((state) => ({ ...state, enterpriseName })),
  setBlockName: (blockName) => set((state) => ({ ...state, blockName })),
  setBlockID: (blockID) => set((state) => ({ ...state, blockID })),
  setBlocksIDs: (blocksIDs) => set((state) => ({ ...state, blocksIDs })),
  setBlocksList: (blocksList) => set((state) => ({ ...state, blocksList })),
  setBlockList: (blockList) => set((state) => ({ ...state, blockList })),
  setTotalBlocks: (totalBlocks) => set((state) => ({ ...state, totalBlocks })),
  setTotalPages: (totalPages) => set((state) => ({ ...state, totalPages })),
  setBlockByID: (blockByID) => set((state) => ({ ...state, blockByID })),
  setOpenModal: (isOpenModal) => set((state) => ({ ...state, isOpenModal })),
  setOpenRegisterModal: (isOpenRegisterModal) =>
    set((state) => ({ ...state, isOpenRegisterModal })),
  setOpenRenameModal: (isOpenRenameModal) => set((state) => ({ ...state, isOpenRenameModal })),
  setIsSuccessfully: (isSuccessfully) => set((state) => ({ ...state, isSuccessfully })),
  setSuccessMessage: (successMessage) => set((state) => ({ ...state, successMessage })),
  handleSetPage: (page) => set((state) => ({ ...state, page })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),

  getAllSortBlocksDataRequest: async (
    page,
    sortByField,
    sortByDirection,
    searchString,
    enterpriseID
  ) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortBlocksData(
        page,
        sortByField,
        sortByDirection,
        String(enterpriseID),
        searchString
      );
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getSimpleBlockListData: async () => {
    try {
      const { enterpriseID } = get();
      const blocks = await getSimpleBlockList(enterpriseID);

      set({ blocksList: blocks });
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  setUnitListData: async (unitList: string) => {
    const { blocksList } = get();
    const newBlocksList = blocksList.filter((block: { value: string }) => block.value === unitList);

    function ordenarPorLabel(array: { label: number }[]) {
      array.sort((a: { label: number }, b: { label: number }) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      return array;
    }

    if (newBlocksList[0]?.units) {
      set({ unitList: ordenarPorLabel(newBlocksList[0].units) });
    }
  },

  getBlockByIDRequest: async (blockID) => {
    try {
      const data = await getBlockByID(blockID);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  deleteBlockRequest: async (id) => {
    try {
      await deleteBlock(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchBlockRequest: async (searchParam, sortByField, sortByDirection, page) => {
    try {
      const { enterpriseID } = get();
      const data = await searchBlocks(
        searchParam,
        enterpriseID,
        sortByField,
        sortByDirection,
        page
      );
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  editBlockRequest: async (blockName, blockId) => {
    try {
      await editBlock(blockName, blockId);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  registerBlockRequest: async (blocks, enterpriseId) => {
    try {
      await registerBlock(blocks, enterpriseId);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  getPdfLinkRequest: async (pdfData, id) => {
    try {
      const { data } = await getPdfToRequest(pdfData, id);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  removePdfRequest: async (id) => {
    try {
      await removePdfToRequest(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));
