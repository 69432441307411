import React from 'react';
import { Box } from '@mui/material';
import CollapsibleSetContainer from './CollapsibleSet.style';
import useCollapsibleSetController from './CollapsibleSet.controller';
import MenusIcon from '../../../../assets/images/menusIcon.svg';
import PlusIcon from '../../../../assets/images/plusIcon.svg';

export function CollapsibleSet() {
  const { toggleItem, openIndex } = useCollapsibleSetController();
  const items = [
    {
      title: 'Como saber se a Hauzy funciona para o meu condomínio?',
      content:
        'Nosso app foi pensado para facilitar o dia a dia de moradores, síndicos e administradores. Se você quer transformar a interação do seu condomínio de forma prática e eficiente, a Hauzy certamente funciona para o seu condomínio! '
    },
    {
      title: 'Qual o valor do aplicativo?',
      content: 'Temos planos a partir de R$99.90 mensais.'
    },
    {
      title: 'Os planos da Hauzy possuem fidelidade?',
      content:
        'Nossos planos não têm fidelidade e as cobranças são mensais, podendo ser cancelado a qualquer momento. '
    },
    {
      title: 'A Hauzy é uma administradora de condomínios?',
      content:
        'Não somos uma administradora. Somos uma ferramenta para facilitar a administração do condomínio e simplificar o dia a dia de moradores, síndicos e funcionários.'
    },
    {
      title: 'Posso usar a Hauzy junto ao meu sistema de controle financeiro?',
      content:
        'Sim! Entre em contato conosco para estudarmos a possibilidade de acordo com o seu sistema, não apenas o financeiro, mas qualquer outro sistema já em uso no seu condomínio. '
    }
  ];
  return (
    <CollapsibleSetContainer>
      {items.map((item, index) => (
        <Box className="collapsibleItem" key={item.title}>
          <Box className="collapsibleHeader" onClick={() => toggleItem(index)}>
            <img
              src={openIndex === index ? `${MenusIcon}` : `${PlusIcon}`}
              alt="plus or menus icons"
            />{' '}
            {item.title}
          </Box>
          {openIndex === index && <Box className="collapsibleContent">{item.content}</Box>}
        </Box>
      ))}
    </CollapsibleSetContainer>
  );
}
