import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { formatError } from '../../../helpers/formatError';
import { useBackToLastPageModalStore, useOrdersStore } from '../../../store';

export const useModalController = (onComplete: () => void, toggleModal: () => void) => {
  const { confirmOrderCodeRequest, id } = useOrdersStore();

  const { handleBackToLastPageModalOpen, isOpenBackToLastPageModal } =
    useBackToLastPageModalStore();

  const schema = yup.object().shape({
    code: yup
      .string()
      .matches(/^[A-Za-z0-9]*$/, 'O código deve conter apenas letras e números.')
      .required('Informe o código.')
  });

  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      code: ''
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  });

  const handleConfirmOrderCode = async () => {
    try {
      const { code } = getValues();

      await confirmOrderCodeRequest(code, id);

      setValue('code', '');
      onComplete();
    } catch (error: any) {
      const message = formatError(error);
      if (message.includes('Código não encontrado')) {
        toast.error('Código de confirmação inválido');
      } else {
        toast.error('Ocorreu um erro ao confirmar o código de encomenda.');
      }
    }
  };

  const handleCloseModal = () => {
    toggleModal();
    setValue('code', '');
  };

  const closeVotingModal = () => {
    const { code } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    code ? handleBackToLastPageModalOpen() : handleCloseModal();

    if (!isOpenBackToLastPageModal) setValue('code', '');
  };

  return {
    handleConfirmOrderCode,
    register,
    control,
    errors,
    handleSubmit,
    closeVotingModal
  };
};
