import create from 'zustand';
import { ReserveProps } from './Reserves.props';

import {
  sortReservesData,
  changeStatusReserve,
  deleteReserve,
  searchReserve,
  findOneReserve
} from '../../services/requests/reserves/Reserves.request';

const initialState = {
  reserveId: '',
  reservesId: [],
  reserveList: [],
  isOpenJustificationModal: false,
  isOpenReserveInformationModal: false,
  status: '',
  page: '1',
  field: 'resident_model.name',
  direction: 'ASC'
};

const useReserveStore = create<ReserveProps>((set, get) => ({
  ...initialState,

  handleSetStatus: (status) => set((state) => ({ ...state, status })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  handleSetPage: (page) => set((state) => ({ ...state, page })),
  handleSetReserveId: (reserveId) => set((state) => ({ ...state, reserveId })),
  handleSetReservesId: (reservesId) => set((state) => ({ ...state, reservesId })),
  getReserveById: async () => {
    try {
      const { reserveId } = get();
      const { data } = await findOneReserve(reserveId);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  getAllSortReservesDataRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortReservesData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  changeStatusReserves: async (id, status, justification = ''): Promise<void> => {
    await changeStatusReserve(id, status, justification);
  },
  deleteReserveRequest: async (id) => {
    try {
      await deleteReserve(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  handleSetReserveList: (reserveList) => set((state) => ({ ...state, reserveList })),
  handleRemoveReserve: (reserveId) =>
    set((state) => ({
      ...state,
      reserveList: state.reserveList.filter((reserve) => reserve.id !== reserveId),
      reservesId: state.reservesId.filter((id) => id !== reserveId)
    })),
  searchReserveRequest: async (reserveData, sortByField, sortByDirection, page) => {
    try {
      const data = await searchReserve(reserveData, sortByField, sortByDirection, page);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  handleReserveInformationModalOpen: () =>
    set((state) => ({ isOpenReserveInformationModal: !state.isOpenReserveInformationModal })),
  handleJustificationModalOpen: () =>
    set((state) => ({ isOpenJustificationModal: !state.isOpenJustificationModal }))
}));

export { useReserveStore };
