import * as yup from 'yup';

interface EnterpriseByIdAllData {
  id: string;
  incorporator: string;
  status: string;
  unit: string;
  name: string;
  propertyType: string;
  city: string;
  state: string;
  CEP: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  country: string;
  logo: any;
  manual: any;
  adminEmail: string;
  functionalitiesId: any;
}

export const registerEnterpriseSchema = yup.object().shape({
  incorporator: yup.string().required('Por favor, preencher campo incorporadora.'),
  status: yup.string().required('Por favor, preencher campo status.'),
  unit: yup
    .number()
    .typeError('Campo "Unidade" precisa ser um número.')
    .required('Por favor, preencher campo unidade.'),
  name: yup.string().required('Por favor, preencher campo nome do empreendimento.'),
  propertyType: yup.string().required('Por favor, preencher campo tipo de imóvel.'),
  CEP: yup
    .string()
    .min(9, 'Por favor, preencher campo CEP com 8 dígitos.')
    .max(9, 'Por favor, preencher campo CEP com 8 dígitos.')
    .required('Por favor, preencher campo CEP.'),
  city: yup.string().required('Por favor, preencher campo cidade.'),
  state: yup.string().required('Por favor, preencher campo estado.'),
  street: yup.string().required('Por favor, preencher campo rua.'),
  district: yup.string().required('Por favor, preencher campo bairro.'),
  number: yup
    .number()
    .typeError('Campo "Número" precisa ser um número.')
    .required('Por favor, preencher campo número.'),
  adminEmail: yup
    .string()
    .email('Email do administrador inválido.')
    .required('Por favor, preencher campo email do administrador.')
});

export function formatDataEnterprise(
  original: EnterpriseByIdAllData,
  modified: EnterpriseByIdAllData
) {
  const {
    id,
    incorporator,
    status,
    unit,
    name,
    propertyType,
    city,
    state,
    CEP,
    street,
    number,
    complement,
    district,
    country,
    functionalitiesId,
    logo,
    adminEmail,
    manual
  } = original;

  const formatedData = {
    id,
    incorporator: modified.incorporator ? modified.incorporator : incorporator,
    status: modified.status ? modified.status : status,
    unit: modified.unit ? modified.unit : unit,
    name: modified.name ? modified.name : name,
    propertyType: modified.propertyType ? modified.propertyType : propertyType,
    city: modified.city ? modified.city : city,
    state: modified.state ? modified.state : state,
    CEP: modified.CEP ? modified.CEP : CEP,
    street: modified.street ? modified.street : street,
    number: modified.number ? modified.number : number,
    complement: modified.complement ? modified.complement : complement,
    district: modified.district ? modified.district : district,
    country: modified.country ? modified.country : country,
    logo: modified.logo ? modified.logo : logo,
    manual: modified.manual ? modified.manual : '',
    adminEmail: modified.adminEmail ? modified.adminEmail : adminEmail,
    functionalitiesId:
      modified.functionalitiesId.length > 0 ? modified.functionalitiesId : functionalitiesId
  };

  return formatedData;
}

export const getStatus = (statusValue: string) => {
  let statusId = -1;
  switch (statusValue) {
    case 'inativo':
      statusId = 0;
      break;
    case 'em_construcao':
      statusId = 1;
      break;
    case 'concluido':
      statusId = 2;
      break;
    default:
      statusId = 3;
      break;
  }
  return statusId;
};

export const formatStatus = (statusValue: string) => {
  let statusPtText = '';
  switch (statusValue) {
    case 'inativo':
      statusPtText = 'Inativo';
      break;
    case 'em_construcao':
      statusPtText = 'Em construção';
      break;
    case 'concluido':
      statusPtText = 'Concluído';
      break;
    default:
      statusPtText = statusValue;
      break;
  }
  return statusPtText;
};
