import { useEffect, useState } from 'react';
import {
  useActionModalStore,
  useSortItemsStore,
  useDeleteModalStore,
  useLoadModalStore,
  useInfoModalStore,
  usePartnersStore,
  usePreviewImageModalStore
} from '../../store';

import { Errors } from '../../constants/Errors';

import { partnersTableHead } from '../../constants/PartnersData';

export function usePartnersController() {
  const { handleSetSortFields } = useSortItemsStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading, isOpenLoad } = useLoadModalStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { isOpenInfoModal, handleModalOpen } = useInfoModalStore();
  const {
    isOpenModal,
    field,
    page,
    partnerId,
    partnersId,
    partnersList,
    direction,
    setOpenModal,
    handleSetPage,
    getAllSortPartnersDataRequest,
    deletePartnerRequest,
    handleSetPartnerList,
    handleSetPartnerId,
    searchPartnersRequest,
    handleSetPartnersId,
    handleInformationModalOpen
  } = usePartnersStore();

  const { isOpenImagePreview } = usePreviewImageModalStore();

  const [totalPartners, setTotalPartners] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [isSuccessfully, setIsSuccessfully] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [searchString, setSearchString] = useState('');

  async function handleEdit() {
    setIsEdit(true);
    setOpenModal(!isOpenModal);
  }

  const actionModalData = [
    {
      ButtonsText: 'Detalhes',
      OnClick: () => {
        handleInformationModalOpen();
      }
    },
    {
      ButtonsText: 'Editar',
      OnClick: () => {
        handleEdit();
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        handleDeleteModalOpen();
        handleDrawerOpen();
        setIsSuccessfully(true);
        setInfoText('Parceiro excluído com sucesso');
      }
    }
  ];

  async function sortPartnersData(
    selectedPage: string,
    selectedField: string,
    selectedDirection: string
  ) {
    const data = await getAllSortPartnersDataRequest(
      selectedPage,
      selectedField,
      selectedDirection,
      searchString
    );
    return data;
  }

  const handleRegisterPartnersModal = () => {
    setIsEdit(false);
    setOpenModal(!isOpenModal);
  };

  async function handleSearch(value: string) {
    setSearchString(value);
    const formatedDataForSearch = { queryText: value };
    const { data } = await searchPartnersRequest(formatedDataForSearch, page, field, direction);
    const formatedData = data.data;
    const formatedRowsId = data.data.map((item) => {
      return item.id;
    });

    if (data) {
      handleSetPartnerList(formatedData);
      handleSetPartnersId(formatedRowsId);
      setTotalPages(data.totalPages || 1);
      setTotalPartners(data.totalPartners || 0);
    }
  }

  async function getAllData() {
    const data = await getAllSortPartnersDataRequest(page, field, direction, searchString);
    const formatedRowsId = data.data.data.map((item) => item.id);
    handleSetPartnersId(formatedRowsId);
    handleSetPartnerList(data.data.data);
    setTotalPages(data.data.totalPages || 1);
    setTotalPartners(data.data.totalPartners || 0);
  }

  async function onSubmitDelete(id: string) {
    toogleLoading();
    try {
      await deletePartnerRequest(id);
    } catch (error: any) {
      setInfoText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
      setIsSuccessfully(true);
    } finally {
      getAllData();
      toogleLoading();
      handleModalOpen();
    }
  }

  async function changePage(pageNumber: number) {
    toogleLoading();
    try {
      const { data } = await getAllSortPartnersDataRequest(
        `${pageNumber}`,
        field,
        direction,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item) => {
        return item.id;
      });

      if (data) {
        handleSetPage(String(pageNumber));
        handleSetPartnerList(formatedData);
        handleSetPartnersId(formatedRowsId);
      }
    } catch (err: any) {
      setInfoText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
    }
  }

  function setSetSortFields() {
    handleSetSortFields(['name', 'segment', 'CNPJ', 'tel']);
  }

  function setPartnersIfExist() {
    if (partnersList.length <= 0) return;
    handleSetPartnersId(partnersId);
  }

  useEffect(() => {
    if (isOpenInfoModal || isEdit) return;
    getAllData();
    setSetSortFields();
  }, []);

  useEffect(() => {
    setPartnersIfExist();
  }, [partnersList]);

  return {
    actionModalData,
    isOpenInfoModal,
    partnerId,
    isOpenDeleteModal,
    isSuccessfully,
    infoText,
    partnersId,
    totalPartners,
    totalPages,
    isEdit,
    isOpenModal,
    partnersList,
    partnersTableHead,
    isOpenImagePreview,
    sortPartnersData,
    handleDrawerOpen,
    handleSearch,
    changePage,
    setIsSuccessfully,
    setInfoText,
    handleSetPartnerId,
    handleRegisterPartnersModal,
    onSubmitDelete,
    handleModalOpen,
    handleDeleteModalOpen,
    getAllData
  };
}

export default usePartnersController;
