/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-useless-escape */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useBlocksStore, useLoadModalStore, useInfoModalStore } from '../../../../store';
import { IRegisterBlockModalFormRequiredFields } from '../RegisterBlockModal/RegisterBlockModal.props';
import { Errors } from '../../../../constants/Errors';

import { useBlocksController } from '../../Blocks.controller';

function useRenameBlockModalController(name: string, id: string) {
  const {
    blockID,
    isOpenRenameModal,
    enterpriseID,
    setOpenRenameModal,
    editBlockRequest,
    setIsSuccessfully,
    setSuccessMessage
  } = useBlocksStore();
  const { toogleLoading } = useLoadModalStore();
  const { handleModalOpen } = useInfoModalStore();

  const { getAllData } = useBlocksController();

  const blockDefaultValues = {
    block_name: name
  };

  const blockReservationSchema = yup.object().shape({
    block_name: yup
      .string()
      .matches(
        /^[a-zA-Z]*$/g,
        'São permitidos apenas letras, sem caracteres especiais e sem espaços.'
      )
      .max(20, 'São permitidos apenas 20 caracteres.')
      .required('Por favor, preencha o nome do bloco.')
      .trim()
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors }
  } = useForm<IRegisterBlockModalFormRequiredFields>({
    defaultValues: blockDefaultValues,
    resolver: yupResolver(blockReservationSchema)
  });

  function closeModal() {
    setOpenRenameModal(false);
  }

  async function renameBlock() {
    const { block_name } = getValues();
    toogleLoading();
    try {
      await editBlockRequest(block_name, id || blockID);
      closeModal();
      handleModalOpen();
      setIsSuccessfully(true);
      setSuccessMessage('Bloco renomeado com sucesso!');
      getAllData(enterpriseID);
    } catch (error: any) {
      closeModal();
      handleModalOpen();
      setIsSuccessfully(false);
      setSuccessMessage(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
    }
  }

  return {
    isOpenRenameModal,
    closeModal,
    renameBlock,
    register,
    control,
    errors,
    handleSubmit
  };
}

export default useRenameBlockModalController;
