import { createTheme } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 300,
      sm: 900,
      md: 1100,
      lg: 1500,
      xl: 1800
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#000',
      contrastText: '#000'
    },
    background: {
      default: '#fafafb'
    }
  },
  typography: {
    fontFamily: '"Inter"'
  }
});

export default theme;
