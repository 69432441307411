import create from 'zustand';
import { CustomPopoverInitialStateStore } from './CustomPopover.props';

const initialState = {
  elementTarget: null
};

const useCustomPopoverStore = create<CustomPopoverInitialStateStore>((set, get) => ({
  ...initialState,

  setElementTarget: (elementTarget) => set((state) => ({ ...state, elementTarget }))
}));

export { useCustomPopoverStore };
