import { Grid, Typography } from '@mui/material';
import { ChromeReaderModeOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Background } from './Employees.style';
import {
  CustomButton,
  CustomTable,
  ActionModal,
  CustomSearchInput,
  BackToLastPageModal,
  InfoModal,
  ConfirmModal
} from '../../components/index';
import { useEmployeesController } from './Employees.controller';
import { Modal } from './components/Modal/Modal';

export function Employees() {
  const {
    tableHeaders,
    variant,
    isEdit,
    rowsId,
    isAdmin,
    tableData,
    totalPages,
    totalEmployees,
    isModalConfirmOpen,
    isModalEditOpen,
    isOpenBackToLastPageModal,
    isModalRegisterOpen,
    actionModalData,
    text,
    isOpenInfoModal,
    isSuccessfully,
    handleSetText,
    handleSetIsSuccessfully,
    handleModalOpen,
    closeModal,
    toggleEditModal,
    toggleRegisterModal,
    handleDrawerOpen,
    handleSetEmployeeId,
    changePage,
    successModal,
    onSubmitDelete,
    toggleConfirmModal,
    sortFuncEmployee,
    handleNavigateRoles,
    getRequestEmployeesData,
    handleSearch
  } = useEmployeesController();

  return (
    <Background>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Typography variant="h5" fontWeight={500}>
            {isAdmin ? <p>Administradores</p> : <p>Funcionários</p>}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} />

        {!isAdmin && (
          <>
            <Grid item xs={12} sm={12} md={5} lg={7}>
              <CustomSearchInput id="employee_search" handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={2.5}>
              <CustomButton onClick={handleNavigateRoles} text="Cadastrar cargo" />
            </Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={2.5}>
              <CustomButton
                id="employee_register"
                onClick={toggleRegisterModal}
                text="Cadastrar funcionário"
              />
            </Grid>
          </>
        )}

        {isAdmin && (
          <>
            <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
              <CustomSearchInput handleSearch={handleSearch} id="input-search" />
            </Grid>
            <Grid item xs={12} sm={12} md={2.5} lg={2.5}>
              <CustomButton
                id="admin-register"
                onClick={toggleRegisterModal}
                text="Cadastrar administrador"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <CustomTable
            handleSetItemId={handleSetEmployeeId}
            totalPages={totalPages}
            totalItems={totalEmployees}
            funcToChangePage={changePage}
            tableHead={tableHeaders}
            tableData={tableData}
            onClick={handleDrawerOpen}
            rowsId={rowsId}
            containId
            noFilesFoundText={
              isAdmin
                ? 'Não há nenhum registro de administradores no momento.'
                : 'Não há nenhum registro de funcionários no momento.'
            }
            sortFunc={sortFuncEmployee}
            ActionModal={
              <ActionModal
                ActionModalData={actionModalData}
                ButtonsIcon={[<EditOutlined />, <ChromeReaderModeOutlined />, <DeleteOutline />]}
              />
            }
          />
        </Grid>
      </Grid>

      <Modal
        variant={variant}
        isModalOpen={isModalRegisterOpen}
        toggleModal={toggleRegisterModal}
        edit={isEdit}
        title={isAdmin ? 'Cadastrar administrador' : 'Cadastrar funcionário'}
        onComplete={successModal}
        toggleSuccessModalOpen={handleModalOpen}
        setTitle={handleSetText}
        setSuccess={handleSetIsSuccessfully}
        updateListRequest={getRequestEmployeesData}
      />

      <Modal
        variant={variant}
        isModalOpen={isModalEditOpen}
        toggleModal={toggleEditModal}
        edit={isEdit}
        title={isAdmin ? 'Editar administrador' : 'Editar funcionário'}
        onComplete={successModal}
        toggleSuccessModalOpen={handleModalOpen}
        setTitle={handleSetText}
        setSuccess={handleSetIsSuccessfully}
        updateListRequest={getRequestEmployeesData}
      />

      {isModalConfirmOpen && (
        <ConfirmModal
          title={
            isAdmin
              ? `Tem certeza que deseja \n excluir este administrador? Esta ação \n não poderá
        ser desfeita.`
              : `Tem certeza que deseja \n excluir este funcionário? Esta ação \n não poderá
        ser desfeita.`
          }
          handleModalClose={toggleConfirmModal}
          onClickConfirm={onSubmitDelete}
        />
      )}

      {isOpenBackToLastPageModal && (
        <BackToLastPageModal goToPage="/employees" handleModalClose={closeModal} />
      )}

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}
    </Background>
  );
}
