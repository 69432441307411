interface ErrorProps {
  name?: string;
  user?: string;
  email?: string;
  id?: string;
  block?: number;
  blockName?: string;
  unitName?: string;
  apartmentNumber?: number;
  cpf?: string;
  role?: string;
  functionality?: string;
  permission?: string;
  day?: string;
  limit?: string;
  status?: string;
}

export const Errors = ({
  name = '',
  cpf = '',
  email = '',
  role = '',
  user = '',
  apartmentNumber = 0,
  block = 0,
  blockName = '',
  unitName = '',
  functionality = '',
  id = '',
  permission = '',
  day = '',
  limit = '',
  status = ''
}: ErrorProps) => {
  return {
    AR1: 'Máximo de fotos excedido.',
    AR2: 'Limite de reserva não fornecido.',
    AR3: 'A capacidade máxima deve ser maior que zero.',
    AR5: 'A capacidade máxima deve ser maior que as reservas simultâneas.',
    AR6: 'O nome da área já existe.',
    AR7: 'O valor do local deve ser maior que zero.',
    AR8: 'Gerente não encontrado.',
    AR9: 'Limite de centavos de valor de locação.',
    AR10: 'O valor do local deve ser inteiro.',
    AR11: 'A área não pode ter aprovação imediata se tiver um valor de locação.',
    AR12: 'A duração do cronograma deve ser 7.',
    AR13: `A programação do dia ${day} deve ter ${limit} itens.`,
    AR14: 'O intervalo de tempo não deve se sobrepor.',
    AR15: 'O fim do intervalo de tempo deve ser maior que o início.',
    AR16: 'Área não encontrada.',
    AR17: 'Excedeu o tempo máximo de pagamento.',
    AR18: 'Limite de caracteres excedido para o nome da área.',
    AR19: 'A área deve ter aprovação imediata se o valor do local for zero.',
    AR20: 'Área indisponível para reserva.',
    AR21: 'O nome da imagem já existe.',
    AR22: 'O nome da imagem não pode estar vazio.',
    AR23: 'O nome da imagem está duplicado.',
    AR24: 'O usuário já fez uma reserva para este horário.',
    AR25: 'Reserva não encontrada.',
    AR26: 'O prazo de pagamento expirou.',
    AR27: 'Excedeu o período de cancelamento.',
    AR28: 'Já existe reserva para esse dia e hora.',
    AR29: 'Não foi possível trocar o status para autorizado.',
    AR30: 'Não é possível fazer uma reserva que já passou.',
    AR31: 'Ocorreu um erro ao criar uma área.',
    AR32: 'Número máximo de imagens excedido.',
    AR33: 'Alguns dos horários das reservas não estão disponíveis.',
    AR34: 'Atingiu a quantidade de reservas simultâneas.',
    AR35: 'Prazo de pagamento deve ser maior que zero.',

    AP1: 'Item não encontrado.',
    AP2: 'Erro na criação do Item.',
    AP3: 'Erro na atualização do Item.',
    AP4: 'Data inválida.',
    AP5: 'Um usuário deve reivindicar o item antes de retirar.',
    AP6: 'O item já foi aprovado.',
    AP7: 'Não foi possível reprovar o item.',

    BL1: 'Bloco não encontrado',
    BL2: 'Apague as unidades antes de deletar o bloco',
    BL3: 'Há outro Bloco com o mesmo nome.\n Tente novamente.',

    CO1: 'A rua é um campo obrigatório.',
    CO2: 'O bairro é um campo obrigatório.',
    CO3: 'A cidade é um campo obrigatório.',
    CO4: 'O estado é um campo obrigatório.',
    CO5: 'O CEP é um campo obrigatório.',
    CO6: 'O número é um campo obrigatório.',
    CO7: 'A cor primária é um campo obrigatório.',
    CO8: 'A cor secundária é um campo obrigatório.',
    CO9: 'Um mesmo condomínio com esse nome já está cadastrado.',
    CO10: 'Cores inválidas.',
    CO11: 'Cor primária inválida.',
    CO12: 'Cor secundária inválida.',
    CO13: 'Erro na criação do Condomínio.',
    CO14: 'Condomínio não Existe.',
    CO15: 'Erro na atualização do Condomínio.',
    CO16: 'Email já está em uso.',
    CO17: 'O email já está cadastrado.',
    CO18: 'Email informado precisa estar cadastrado como Administrador para ser associado a empreendimento.',
    CO19: 'filteredBy inválido.',
    CO20: 'Este email de administrador já está sendo usado por um empreendimento.',
    CO21: 'Email inválido.',
    CO22: 'Token inválido.',
    CO23: 'Caracteres especiais não são permitidos.',
    CO24: 'As senhas são diferentes.',
    CO25: 'É necessário ao menos uma letra maiúscula.',
    CO26: 'É necessário ao menos uma letra minúscula.',
    CO27: 'É necessário ao menos uma número.',
    CO28: 'É necessário ao menos seis caracteres e no máximo 256 caracteres.',
    CO29: 'É necessário ao menos um caractere especial.',
    CO30: 'A imagem não deve estar vazia.',
    CO31: 'Erro na criação da entidade.',
    CO32: 'Não encontrado.',
    CO33: 'Cadastro de email de administrador não encontrado.',
    CO34: 'Caracteres especiais não são permitidos.',
    CO35: 'Empreendimento com nome já cadastrado.',
    CO36: 'Funcionalidades inválidas.',
    CO38: 'Funcionalidades inválidas.',
    CO39: 'Funcionalidade inválida.',
    CO40: 'Algo deu errado.',
    CO41: 'O email já está sendo utilizado.',
    CO42: 'Data inválida.',
    CO43: 'Formato de data inválido.',
    CO44: 'Tipo de arquivo inválido para o arquivo.',
    CO45: 'Tipo de arquivo inválido.',
    CO46: 'Formato de corpo de requisição inválido',
    CO47: 'O status da enterprise precisa ser completo para poder logar',
    CO48: 'Não é possível atualizar empreendimento, email de manager não cadastrado.',
    CO49: 'Status não pôde ser alterado, pois ja possui o valor deste status',

    GE1: 'Ops, alguma coisa deu errado.',

    IN3: 'É necessário preencher o campo “assunto”',
    IN4: 'Assunto inválido',
    IN5: 'Assunto muito curto, é necessário ao menos 3 caracteres',
    IN6: 'Assunto muito longo, é permitido até 100 caracteres',
    IN7: 'Assunto inválido, é permitido apenas letras e números, sem caracteres especiais',
    IN8: 'É necessário preencher a descrição',
    IN9: 'Descrição muito curta, é necessário pelo menos 3 caracteres',
    IN10: 'Descrição muito longa, é permitido até 1000 caracteres',
    IN11: 'Descrição é inválida, caracteres permitidos: @#áàâãéêíóôõúüç€R$a-zA-Z0-9',
    IN15: 'Tamanho do arquivo inválido',
    IN16: 'Tipo de arquivo inválido',
    IN17: 'Nome do arquivo inválido',
    IN18: 'Caminho do arquivo inválido',
    IN19: 'Arquivo muito grande',
    IN23: 'Informação não encontrada',
    IN24: 'Informação não encontrada',
    IN25: 'Unidade não encontrada',
    IN26: 'Limite de tamanho de arquivo',
    IN27: 'Morador não encontrado',

    MA1: 'Manutenção não encontrada.',
    MA2: 'Data inválida.',
    MA3: 'Não é possível atualizar o status ou excluir uma manutenção com status "CONCLUÍDO".',
    MA4: 'Não é possível iniciar uma manutenção com status "EM ANDAMENTO".',
    MA5: 'Não é possível finalizar uma manutenção com status "CONCLUÍDO".',
    MA6: 'Você não tem permissão para atualizar esta manutenção',
    MA7: 'Não é possível definir o status "EM ANDAMENTO" para manutenção em unidade.',
    MA9: 'Não é possível criar uma manutenção com o status concluído para uma data futura.',

    NO1: 'Notificação não encontrada!.',
    NO2: 'Entidade com esse id não foi encontrada.',
    NO3: 'Erro criando a entidade.',
    NO4: 'Não encontrado.',

    PA1: 'Parceiro já existe.',
    PA2: 'Parceiro não encontrado.',

    RE1: 'Uma pasta já foi criada para esta unidade',
    RE2: 'O nome da pasta já está em uso',
    RE3: 'Esta pasta não pode ser excluída porque há documentos. Exclua-os para continuar',
    RE4: 'Nome da pasta já usado em outra unidade',
    RE5: 'A pasta não pertence a uma unidade do empreendimento',
    RE6: 'Pasta não encontrada',
    RE7: 'Unidade não encontrada',

    OC1: 'Só é possível excluir uma ocorrência com o status de concluído e depois de 7 dias com esse status.',
    OC2: 'Você não tem permissão para deletar essa ocorrência',
    OC3: 'Você pode iniciar essa ocorrência.',
    OC4: 'Você não pode finalizar essa ocorrência.',
    OC5: 'Você pode reabrir essa ocorrência.',
    OC6: 'Você não pode enviar uma mensagem para uma ocorrência concluída.',

    ST1: 'A pasta já existe.',
    ST2: 'Pasta não encontrada.',
    ST3: 'O arquivo já existe.',
    ST4: ' O Arquivo não Existe.',
    ST5: 'Erro na atualização do arquivo.',
    ST6: 'Esta pasta não pode ser excluída, pois possui documentos. Exclua os arquivos para poder excluir a pasta',
    ST7: 'Erro no envio da imagem',

    UN1: 'Unidade não encontrado',
    UN2: 'Apague os residentes antes de deletar a unidade',
    UN3: 'Há outra Unidade com o mesmo nome.\n Tente novamente.',

    US1: 'Cargo não encontrado.',
    US2: 'Não é possível trocar o cargo de um administrador.',
    US3: 'Erro na criação de um admin.',
    US4: 'Erro na atualização de um admin.',
    US5: 'Empregado não encontrado.',
    US6: 'Não é possível excluir o administrador, pois está vinculado a um empreendimento.',
    US7: `O bloco ${block} e/ou unidade ${apartmentNumber} não foram encontrados(a).`,
    US8: `O ${user} com o id ${id} não foi encontrado(a).`,
    US9: `O ${user} com o email ${email} não foi encontrado(a).`,
    US10: `O email ${email} não foi encontrado.`,
    US11: `O email ${email} já está em uso.`,
    US12: `O cpf ${cpf} já está em uso.`,
    US13: `A funcionalidade ${functionality} já existe.`,
    US14: `A funcionalidade ${permission} não existe.`,
    US15: `O cargo ${role} já existe.`,
    US16: `O cargo ${role} possui as mesmas funcionalidades.`,
    US17: 'Não é possível excluir o cargo, pois está vinculado a um funcionário.',
    US18: 'Residente não encontrado.',
    US19: 'Cargo Não Existe.',
    US20: 'O bloco ou a unidade não foram encontrados',

    VI1: 'Data inicial inválida.',
    VI2: 'Data final inválida.',
    VI3: 'A data final deve ser maior que a data inicial.',
    VI4: 'Visitante não encontrado.',
    VI5: 'Erro na criação do visitantes.',
    VI6: 'Erro na atualização do visitante.',
    VI7: 'Convites tem o período máximo de 1 ano.',

    VO1: 'Votação ainda não foi encerrada.',
    VO2: 'Não há resultado para votações.',
    VO3: 'Votação não pode ser criada, pois o id do empreendimento não foi informado.',
    VO4: 'Votação não pode ser criada, pois a votação não pode ser criada com status diferente de: Não Iniciada.',
    VO5: 'Votação não pode ser criada, pois a votação está com data final atrasada.',
    VO6: 'Votação não pode ser excluída, pois só é possível excluir votações não iniciadas ou encerradas.',
    VO7: 'Votação não pode ser encerrada, pois a votação não está aberta.',
    VO8: 'É possível iniciar uma votação apenas se estiver com o status "NÃO INICIADA".',
    VO9: 'Votação não pode ser iniciada, pois a votação só pode ser iniciada com pelo menos um dia de antecedência.',
    VO10: 'Votação não pode ser atualizada, pois só é possível alterar votações não iniciadas.',
    VO11: 'Votação não pode ser atualizada, pois a votação está com data final atrasada.',
    VO12: 'Votação não disponível para voto Votação não pertence a sua empresa.',
    VO13: 'Votação não disponível para voto Opção de voto inválida.',
    VO14: 'Votação não disponível para voto.',
    VO15: 'Votação não disponível para voto Só é computado um voto por unidade.',
    VO16: 'Votação não disponível para voto Só pode votar em votação aberta.',
    VO17: 'Votação encerrada!.',
    VO18: 'Votação ainda não foi encerrada.',
    VO19: 'Votação não deve conter opções vazias.',
    VO20: 'Votação deve conter duas ou mais opções.',
    VO21: 'Opções de voto devem ter nomes únicos.',
    VO22: 'Erro ao buscar manager.',
    VO23: 'Votação não encontrada.',
    VO24: `Não encontramos manager com email ${email}.`,
    VO25: 'Data inválida.',
    VO26: 'Erro na criação da entidade.',
    VO27: `Entidade com id ${id} não encontrada.`,
    VO28: 'Email informado precisa estar cadastrado como Administrador para ser associado a empreendimento.',
    VO29: 'Não encontrado.'
  };
};
