import { useState } from 'react';
import { useEnterpriseStore } from '../../../../store';
import { functionalities, functionalitiesToRequest } from '../../../../constants';

function useOwnerEnterpriseOptionsController() {
  const [functionalitiesId, setFunctionalitiesId] = useState<{ name: string }[]>([]);
  const { handleSetFunctionalities } = useEnterpriseStore();

  const handleSetFunctionalitiesId = (id: number) => {
    if (functionalitiesId.length === 0) {
      handleSetFunctionalities([{ name: functionalitiesToRequest[id] }]);
      setFunctionalitiesId([{ name: functionalitiesToRequest[id] }]);
      return;
    }

    let isRepeated = false;
    const newFunctionalitiesId = [...functionalitiesId];

    newFunctionalitiesId.forEach((functionality: { name: string }, index) => {
      if (functionality.name === functionalitiesToRequest[id]) {
        newFunctionalitiesId.splice(index, 1);
        isRepeated = true;
        handleSetFunctionalities(newFunctionalitiesId);
        setFunctionalitiesId(newFunctionalitiesId);
      }
    });

    if (isRepeated) return;

    newFunctionalitiesId.push({ name: functionalitiesToRequest[id] });
    handleSetFunctionalities(newFunctionalitiesId);
    setFunctionalitiesId(newFunctionalitiesId);
  };

  const functionalitiesDisabled = [''];

  return {
    handleSetFunctionalitiesId,
    functionalities,
    functionalitiesDisabled
  };
}

export default useOwnerEnterpriseOptionsController;
