import create from 'zustand';
import { NotificationStoreProps } from './Notification.props';
import {
  getAllNotifications,
  markViewedNotifications,
  getNextPageNotifications
} from '../../services/requests/notification/Notification.request';

const initialState = {
  notificationsList: [],
  isOpenNotificationModal: false,
  haveNewNotification: false,

  hasNextPage: false,
  hasPrevPage: false,
  nextPage: null,
  prevPage: null
};

const useNotificationStore = create<NotificationStoreProps>((set, get) => ({
  ...initialState,

  handleSetNotificationsList: (notificationsList) =>
    set((state) => ({ ...state, notificationsList })),
  handleNotificationModalOpen: (isOpenNotificationModal) =>
    set((state) => ({ ...state, isOpenNotificationModal })),
  setHaveNewNotification: (haveNewNotification) =>
    set((state) => ({ ...state, haveNewNotification })),

  setHasNextPage: (hasNextPage) => set((state) => ({ ...state, hasNextPage })),
  setHasPrevPage: (hasPrevPage) => set((state) => ({ ...state, hasPrevPage })),
  setNextPage: (nextPage) => set((state) => ({ ...state, nextPage })),
  setPrevPage: (prevPage) => set((state) => ({ ...state, prevPage })),

  getAllNotificationsRequest: async () => {
    const { setHasNextPage, setHasPrevPage, setNextPage, setPrevPage } = get();
    try {
      const { data } = await getAllNotifications();
      setHasNextPage(data.metaData.hasNext);
      setHasPrevPage(data.metaData.hasPrev);
      setNextPage(data.metaData.nextPage);
      setPrevPage(data.metaData.prevPage);
      return data;
    } catch (error: any) {
      throw new Error(error.message || 'Ops, algo de errado aconteceu, tente novamente em breve.');
    }
  },

  changeNotificationPageRequest: async (page) => {
    const { setHasNextPage, setHasPrevPage, setNextPage, setPrevPage } = get();
    try {
      const { data } = await getNextPageNotifications(page);
      setHasNextPage(data.metaData.hasNext);
      setHasPrevPage(data.metaData.hasPrev);
      setNextPage(data.metaData.nextPage);
      setPrevPage(data.metaData.prevPage);
      return data;
    } catch (error: any) {
      throw new Error(error.message || 'Ops, algo de errado aconteceu, tente novamente em breve.');
    }
  },

  markAsViewedNotificationsRequest: async () => {
    try {
      await markViewedNotifications();
    } catch (error: any) {
      throw new Error(error.message || 'Ops, algo de errado aconteceu, tente novamente em breve.');
    }
  }
}));

export { useNotificationStore };
