/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useActionModalStore,
  useSortItemsStore,
  useDeleteModalStore,
  useLoadModalStore,
  useMaintenancesStore,
  useRolesModalStore,
  useInfoModalStore,
  usePreviewImageModalStore,
  useRefreshChangedPagesStore,
  usePaginationStore
} from '../../store';

import { Errors } from '../../constants/Errors';

export function useUnitsController() {
  const navigate = useNavigate();
  const { handleSetSortFields } = useSortItemsStore();
  const { handleDrawerOpen } = useActionModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { isAdmin } = useRolesModalStore();
  const {
    handleModalOpen,
    handleSetIsSuccessfully,
    handleSetText,
    isOpenInfoModal,
    isSuccessfully,
    text
  } = useInfoModalStore();
  const { isOpenImagePreview } = usePreviewImageModalStore();
  const { setChangedPage, isPageChanged } = useRefreshChangedPagesStore();

  const [totalMaintenances, setTotalMaintenances] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [deleteModalText, setDeleteModalText] = useState('');
  const [searchString, setSearchString] = useState('');

  const {
    maintenanceID,
    maintenancesIDs,
    maintenancesList,
    isInfoModalOpen,
    isFinishModalOpen,
    isMaintenanceInProgressModalOpen,
    page,
    field,
    direction,
    formatedImageToRequest,
    imageName,
    imageSize,
    imageType,
    setIsInfoModalOpen,
    setIsFinishModalOpen,
    deleteMaintenanceRequest,
    setIsMaintenanceInProgressModalOpen,
    setMaintenanceID,
    setMaintenancesIDs,
    setMaintenancesList,
    searchMaintenanceRequest,
    getAllSortMaintenanceDataRequest,
    changeStatusMaintenance,
    getIdForTokenRequest
  } = useMaintenancesStore();

  function handleOpenInfoModal() {
    setIsInfoModalOpen(!isInfoModalOpen);
  }

  function handleOpenFinishModal() {
    setIsFinishModalOpen(!isFinishModalOpen);
  }

  function handleOpenInProgressModal() {
    setIsMaintenanceInProgressModalOpen(!isMaintenanceInProgressModalOpen);
  }

  async function startMaintenance(date: Date) {
    toogleLoading();
    try {
      const formatedDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1,
        20,
        59
      ).toISOString();

      await changeStatusMaintenance(maintenanceID, 'start', isAdmin, {
        date: formatedDate
      });

      handleSetText('Manutenção foi iniciada com sucesso!');
      handleSetIsSuccessfully(true);
      handleModalOpen();
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[String(error.message).trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  async function getAllData() {
    toogleLoading();
    try {
      const { data }: any = await getAllSortMaintenanceDataRequest(
        page,
        field,
        direction,
        isAdmin,
        searchString
      );
      if (data) {
        const formatedData = data.data;
        const formatedRowsId = data.data.map((item: { id: string }) => item.id);
        setMaintenancesList(formatedData);
        setMaintenancesIDs(formatedRowsId);
        setTotalPages(data.totalPages || 1);
        setTotalMaintenances(data.totalMaintenances || 0);
      }
    } finally {
      toogleLoading();
    }
  }

  async function finishMaintenance() {
    toogleLoading();
    try {
      const files = formatedImageToRequest[0]
        ? [
            {
              name: imageName,
              size: imageSize,
              url: formatedImageToRequest[formatedImageToRequest.length - 1].url,
              type: imageType
            }
          ]
        : [];

      await changeStatusMaintenance(maintenanceID, 'finish', isAdmin, {
        files
      });

      handleSetText('Manutenção foi concluída com sucesso!');
      handleSetIsSuccessfully(true);
      handleModalOpen();
      getAllData();
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  function handleActionModalData(row: any) {
    const isDone = row.status === 'CONCLUÍDO';

    const actionModalData = [
      {
        ButtonsText: 'Detalhes',
        OnClick: () => {
          handleOpenInfoModal();
        }
      },
      {
        ButtonsText: 'Editar',
        OnClick: () => {
          if (isDone)
            return toast.error('Não é possível editar uma manutenção com status "CONCLUÍDO"');
          navigate(`/maintenance/edit/${maintenanceID}`);
        }
      },
      {
        ButtonsText: 'Em andamento',
        OnClick: () => {
          if (isDone)
            return toast.error(
              'Não é possível alterar o status de uma manutenção com status "CONCLUÍDO"'
            );
          handleOpenInProgressModal();
        }
      },
      {
        ButtonsText: 'Finalizar',
        OnClick: () => {
          if (isDone)
            return toast.error('Não é possível finalizar uma manutenção com status "CONCLUÍDO"');
          setDeleteModalText('Tem certeza que deseja finalizar esta manutenção?');
          handleOpenFinishModal();
        }
      },
      {
        ButtonsText: 'Excluir',
        OnClick: () => {
          if (isDone)
            return toast.error('Não é possível excluir uma manutenção com status "CONCLUÍDO"');
          setDeleteModalText('Tem certeza que deseja excluir esta manutenção?');
          handleDeleteModalOpen();
        }
      }
    ];

    return actionModalData;
  }

  async function handleSearch(value: string) {
    setSearchString(value);
    const formatedDataForSearch = { queryText: value };
    const { data } = await searchMaintenanceRequest(
      formatedDataForSearch,
      isAdmin,
      field,
      direction,
      page
    );
    if (data) {
      const formatedData = data.data;
      const formatedRowsId = data.data.map((item: any) => item.id);
      setMaintenancesList(formatedData);
      setMaintenancesIDs(formatedRowsId);
      setTotalPages(data.totalPages || 1);
      setTotalMaintenances(data.totalMaintenances || 0);
    }
  }

  async function onSubmitDelete() {
    toogleLoading();
    try {
      await deleteMaintenanceRequest(maintenanceID, isAdmin);
      handleSetText('Manutenção excluída com sucesso!');
      handleSetIsSuccessfully(true);
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
    } finally {
      getAllData();
      toogleLoading();
      handleModalOpen();
    }
  }

  async function sortMaintenance(page: string, field: string, direction: string) {
    const data: any = await getAllSortMaintenanceDataRequest(
      page,
      field,
      direction,
      isAdmin,
      searchString
    );
    return data;
  }

  async function changePage(pageNumber: number) {
    toogleLoading();
    try {
      const { data } = await getAllSortMaintenanceDataRequest(
        `${pageNumber}`,
        field,
        direction,
        isAdmin,
        searchString
      );
      if (data) {
        const formatedData = data.data;
        const formatedRowsId = data.data.map((item: any) => item.id);
        setMaintenancesList(formatedData);
        setMaintenancesIDs(formatedRowsId);
        setTotalPages(data.totalPages || 1);
        setTotalMaintenances(data.totalMaintenances || 0);
      }
    } catch (err: any) {
      handleSetText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  function setSetSortFields() {
    handleSetSortFields(
      isAdmin
        ? [
            'enterprise_model.name',
            'maintenance_model.type',
            'block_model.name',
            'maintenance_model.name',
            'unit_model.name',
            'maintenance_model.date',
            'maintenance_model.status'
          ]
        : [
            'maintenance_model.type',
            'block_model.name',
            'maintenance_model.name',
            'unit_model.name',
            'maintenance_model.date',
            'maintenance_model.status'
          ]
    );
  }

  const maintenanceTableHead = isAdmin
    ? [
        'Empreendimento',
        'Tipo de Manutenção',
        'Bloco',
        'Assunto',
        'Unidade',
        'Data da Manutenção',
        'Status',
        'Ações'
      ]
    : [
        'Tipo de Manutenção',
        'Bloco',
        'Assunto',
        'Unidade',
        'Data da Manutenção',
        'Status',
        'Ações'
      ];

  function navigateToRegisterMaintenance() {
    navigate('/maintenance/register');
  }

  function setMaintenanceIfExist() {
    if (isPageChanged) setChangedPage(false);
    if (maintenancesList.length <= 0) return;
    setMaintenancesIDs(maintenancesIDs);
  }

  async function formatIdIfFromEmail() {
    const token = window.location.search.split('?token=')[1];
    if (!token || token.length === 0) return;
    toogleLoading();
    try {
      const { data } = await getIdForTokenRequest(token);
      setMaintenanceID(data.id);
      navigate(`maintenance/edit/${data.id}`, { replace: true });
    } finally {
      toogleLoading();
    }
  }

  useEffect(() => {
    formatIdIfFromEmail();
  }, []);

  useEffect(() => {
    setMaintenanceIfExist();
  }, [maintenancesList]);

  useEffect(() => {
    getAllData();
    setSetSortFields();
  }, [isAdmin, text]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    maintenanceTableHead,
    maintenancesIDs,
    maintenancesList,
    totalMaintenances,
    isOpenInfoModal,
    isSuccessfully,
    text,
    totalPages,
    isOpenDeleteModal,
    isInfoModalOpen,
    isOpenImagePreview,
    isFinishModalOpen,
    isMaintenanceInProgressModalOpen,
    deleteModalText,
    sortMaintenance,
    setMaintenanceID,
    finishMaintenance,
    handleActionModalData,
    handleDeleteModalOpen,
    handleOpenInfoModal,
    handleModalOpen,
    handleOpenFinishModal,
    handleOpenInProgressModal,
    startMaintenance,
    onSubmitDelete,
    handleSearch,
    changePage,
    handleDrawerOpen,
    navigateToRegisterMaintenance
  };
}

export default useUnitsController;
