import { SplitScreen, HalfPageLogo } from '../../components';
import { FormNewPassword } from './components/FormNewPassword/FormNewPassword';

import Background from './NewPassword.style';

export const NewPassword = () => {
  return (
    <Background>
      <SplitScreen Left={FormNewPassword} Right={HalfPageLogo} leftWeight={0.5} rightWeight={0.5} />
    </Background>
  );
};
