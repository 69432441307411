import { Grid } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Chart from 'react-apexcharts';
import { Container, Subtitle, GraphResults } from './Pie.style';
import PieProps from './Pie.props';

export function ChartPie({ div2PDF, votingDetails, residents }: PieProps) {
  const options = Object.values(votingDetails.optionDetails).map((value) => value.option);
  const voteCount = Object.values(votingDetails.optionDetails).map((value) => value.voteCount);
  const isNotVote = voteCount.filter((count: number) => count !== 0);

  const answerText = votingDetails.votingCount === 1 ? 'resposta' : 'respostas';
  const residentText = residents === 1 ? 'morador' : 'moradores';

  return (
    <Container className="containerVoting">
      <Grid className="borderButtom">
        <div>
          <Subtitle fontSize={16} fontWeight={600} marginBottom={2}>
            {votingDetails.subject}
          </Subtitle>
          <Subtitle fontSize={16} fontWeight={400}>
            {votingDetails.votingCount} {answerText} de {residents} {residentText}
          </Subtitle>
        </div>
        <button className="buttonPDF" type="button" onClick={div2PDF}>
          <SaveAltIcon />
        </button>
      </Grid>

      <GraphResults>
        {!(isNotVote.length !== 0) ? (
          <div className="hasNotVote">
            <p>Não possuí dados para essa votação.</p>
          </div>
        ) : (
          <Chart
            type="pie"
            height={350}
            series={voteCount}
            options={{
              labels: options,
              stroke: {
                width: 0
              },
              legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left',
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial',
                offsetX: 0,
                offsetY: 0
              }
            }}
          />
        )}
      </GraphResults>
    </Container>
  );
}
