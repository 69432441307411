import { Box, Typography, Button } from '@mui/material';

import Background, { ButtonArea, CloseButton } from './ConfirmModal.style';
import { ConfirmModalProps } from './ConfirmModal.props';
import closeIcon from '../../assets/images/close.svg';
import alert from '../../assets/images/alert.svg';

export function ConfirmModal(props: ConfirmModalProps) {
  const { title, onClickConfirm, handleModalClose, description, haveDescription } = props;

  return (
    <Background>
      <Box className="modalContainer">
        <CloseButton style={{ margin: -10 }} onClick={handleModalClose}>
          <img src={closeIcon} alt="delete" className="close" />
        </CloseButton>
        <img src={alert} alt="alert" />
        <Typography variant="h5" fontWeight={700} textAlign="center">
          {title}
        </Typography>
        {haveDescription && (
          <Typography
            color="#8B8B8B"
            align="center"
            fontSize="18px"
            marginTop="-5px"
            marginBottom={2}>
            {description}
          </Typography>
        )}
        <ButtonArea>
          <Button id="visitors_confirmButton" onClick={onClickConfirm} className="confirmButton">
            Confirmar
          </Button>
          <Button id="visitors_cancelButton" onClick={handleModalClose} className="cancelButton">
            Cancelar
          </Button>
        </ButtonArea>
      </Box>
    </Background>
  );
}
