import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  useAdminORStore,
  useAuthStore,
  useProfileModalStore,
  useRolesModalStore
} from '../../../../../../store';
import { changeWebPassword } from '../../../../../../services/requests/auth/auth.request';

function useProfileModalController() {
  const { name, occupation, email } = useAdminORStore();
  const { isAdmin } = useRolesModalStore();
  const { handleProfileModalOpen, isOpenProfileModal } = useProfileModalStore();
  const [loading, setLoading] = useState(false);
  const { logout } = useAuthStore();
  const navigate = useNavigate();

  function handleLogout() {
    handleProfileModalOpen();
    logout();
    navigate('/login');
    window.location.reload();
  }

  function closeActionModalOnClickAway() {
    if (isOpenProfileModal) {
      handleProfileModalOpen();
    }
  }

  const handleChangePassword = async () => {
    if (!loading) {
      try {
        setLoading(() => true);
        await changeWebPassword(email);
        toast.success('Enviamos um link de alteração de senha para o seu e-mail');
      } catch {
        toast.error('Não foi possível enviar um link para alteração da senha para o seu e-mail');
      } finally {
        setTimeout(() => {
          setLoading(() => false);
        }, 30000);
      }
    } else {
      toast.error('Para enivar novamente aguarde 30 segundos');
    }
  };

  return {
    name,
    occupation,
    loading,
    isAdmin,
    handleLogout,
    handleChangePassword,
    closeActionModalOnClickAway
  };
}

export default useProfileModalController;
