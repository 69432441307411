/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-var-requires */
import html2canvas from 'html2canvas';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getVotingResult } from '../../../services/requests/voting/Voting.request';
import { getAllResidentsData } from '../../../services/requests/residents/Residents.request';

const pdfConverter = require('jspdf');

function useVotingResultController() {
  const [votingResultDetail, setVotingResultDetail] = useState([]) as any;
  const navigation = useNavigate();

  const [residents, setResidents] = useState(0);
  const div2PDF = (index: number) => {
    const input = document.getElementsByClassName('div2PDF')[index] as HTMLElement;

    html2canvas(input, { scrollY: window.pageYOffset * -1 }).then((canvas: any) => {
      const img = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new pdfConverter('l', 'pt');

      pdf.addImage(img, 'JPEG', 210, 60, input.offsetWidth, input.offsetHeight);
      pdf.save(` votacao${new Date()}.pdf`);
    });
  };

  const getVoltinResultRequest = async () => {
    try {
      const data = await getVotingResult();
      setVotingResultDetail(data);
    } catch {
      throw new Error('Invalid Credentials');
    }
  };

  const getAllResidents = async () => {
    try {
      const { data } = await getAllResidentsData(1);
      setResidents(data.totalResidents);
    } catch {
      throw new Error('Invalid Credentials');
    }
  };

  useEffect(() => {
    getVoltinResultRequest();
    getAllResidents();
  }, []);

  return {
    div2PDF,
    navigation,
    residents,
    votingResultDetail
  };
}

export default useVotingResultController;
