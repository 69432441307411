/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  Button,
  TextField
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { Container, RoleInputArea, CustomIconButton } from './Roles.style';

import { ConfirmModal, CustomSearchInput, InfoModal } from '../../components';
import { useRoleController } from './Roles.controller';
import CustomPagination from '../../components/CustomTable/components/CustomPagination/CustomPagination';
import { Checkbox } from './components/Checkbox/Checkbox';
import { CustomTableBody } from './components/TableBody/TableBody';

const sortupicon = require('../../assets/images/sortupicon.svg');
const sortdownicon = require('../../assets/images/sortdownicon.svg');

export function Roles() {
  const {
    indexOfRow,
    control,
    register,
    handleSubmit,
    errors,
    onSubmit,
    tableData,
    rowsId,
    handleShowMorePermissions,
    actionModalData,
    isOpenActionModal,
    handleClickOnActionBtn,
    handleDrawerOpen,
    handleSetJobsRoleId,
    onSubmitDelete,
    isEdit,
    totalPages,
    totalRoles,
    changePage,
    titleModal,
    functionalitiesId,
    handleSearch,
    handleSetFunctionalitiesId,
    isShowMore,
    text,
    isOpenInfoModal,
    handleModalOpen,
    handleClickSortButton,
    changeSortDownColor,
    changeSortUpColor,
    isSuccessfully,
    sortDown,
    sortItemId,
    sortUp,
    isModalEditOpen,
    sortRoles,
    isModalDeleteOpen,
    toggleDeleteModal,
    toggleConfirmEditModal
  } = useRoleController();

  return (
    <Container>
      <Typography className="title">{isEdit ? 'Editar cargo' : 'Cadastrar cargo'}</Typography>
      <Typography className="subTitle">
        Olá! Você está na tela de {isEdit ? 'edição ' : 'cadastro'} de cargos.
      </Typography>

      <Typography className="rolePlaceholder">Cargo</Typography>
      <RoleInputArea>
        <Controller
          name="name"
          control={control}
          render={() => (
            <Box style={{ width: '100%' }}>
              <TextField
                id="role_name"
                className="roleInput"
                placeholder="Escreva aqui"
                {...register('name')}
                error={!!errors.name}
              />
              {errors.name && <p className="errorMessage">{errors.name.message}</p>}
            </Box>
          )}
        />

        <Button
          id="role_registerButton"
          type="submit"
          className="createRoleBtn"
          onClick={handleSubmit(onSubmit)}>
          {isEdit ? 'Salvar' : 'Incluir'}
        </Button>
      </RoleInputArea>

      <Typography className="availableRoles">Funções disponíveis:</Typography>

      <Checkbox
        functionalitiesSelected={functionalitiesId}
        handleGetFunctionalities={handleSetFunctionalitiesId}
      />

      <Typography className="rolesList">Listagem de cargos</Typography>

      <CustomSearchInput
        id="roles_search"
        searchText="Pesquisar cargos"
        handleSearch={handleSearch}
      />

      <Box className="tableContainer">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="header">
                <Box className="headerColumn">
                  <Typography>Cargo</Typography>
                  <Box display="flex" className="sortIcons">
                    <CustomIconButton
                      primaryColor={sortUp && sortItemId === 'Cargo' ? '#333333' : '#D8D3D3'}
                      onClick={() =>
                        handleClickSortButton(changeSortUpColor, 'Cargo', sortRoles, 'ASC', 0)
                      }>
                      <sortupicon.ReactComponent style={{ stroke: 'red', fill: 'red' }} />
                    </CustomIconButton>
                    <CustomIconButton
                      primaryColor={sortDown && sortItemId === 'Cargo' ? '#333333' : '#D8D3D3'}
                      onClick={() =>
                        handleClickSortButton(changeSortDownColor, 'Cargo', sortRoles, 'DESC', 0)
                      }>
                      <sortdownicon.ReactComponent />
                    </CustomIconButton>
                  </Box>
                </Box>
              </TableCell>
              <TableCell className="header">Funções</TableCell>
              <TableCell className="header">Ações</TableCell>
            </TableRow>
          </TableHead>
          {tableData[0] && tableData[0].name && (
            <CustomTableBody
              tableData={tableData}
              indexOfRow={indexOfRow}
              isShowMore={isShowMore}
              actionModalData={actionModalData}
              handleClickOnActionBtn={handleClickOnActionBtn}
              handleDrawerOpen={handleDrawerOpen}
              handleSetJobsRoleId={handleSetJobsRoleId}
              handleShowMorePermissions={handleShowMorePermissions}
              isOpenActionModal={isOpenActionModal}
              rowsId={rowsId}
            />
          )}
        </Table>
      </Box>

      <CustomPagination
        totalItems={totalRoles}
        totalPages={totalPages}
        funcToChangePage={changePage}
      />

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={text} />
      )}

      {isModalEditOpen ||
        (isModalDeleteOpen && (
          <ConfirmModal
            title={titleModal}
            onClickConfirm={isModalEditOpen ? handleSubmit(onSubmit) : onSubmitDelete}
            handleModalClose={isModalEditOpen ? toggleConfirmEditModal : toggleDeleteModal}
          />
        ))}
    </Container>
  );
}
