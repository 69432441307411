/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Typography,
  Modal as ModalComponent,
  Button,
  InputLabel,
  TextField
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { CloseRounded } from '@mui/icons-material';
import { useInProgressMaintenanceModal } from './InProgressMaintenanceModal.controller';
import { ModalProps } from './InProgressMaintenanceModal.props';
import Background, { ModalContent } from './InProgressMaintenanceModal.style';

export function InProgressMaintenanceModal({
  isModalOpen,
  toggleModal,
  handleChangeStatus
}: ModalProps) {
  const { control, errors, register, submit, handleSubmit, closeRenameModal } =
    useInProgressMaintenanceModal(toggleModal, handleChangeStatus);

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <Background>
        <Box>
          <ModalContent>
            <Box className="closeButton" onClick={closeRenameModal}>
              <CloseRounded />
            </Box>

            <Box className="header">
              <Typography className="title">Manutenção em Andamento</Typography>
            </Box>

            <Box className="inputContainer">
              <InputLabel className="inputLabel">Insira a data da manutenção</InputLabel>
              <Controller
                name="maintenance_date"
                control={control}
                render={() => (
                  <TextField
                    id="maintenance_date"
                    className="input"
                    fullWidth
                    placeholder="dd/mm/aaaa"
                    type="date"
                    {...register('maintenance_date')}
                    error={!!errors.maintenance_date}
                  />
                )}
              />
              {errors.maintenance_date && (
                <p className="errorMessage">{errors.maintenance_date?.message}</p>
              )}
            </Box>

            <Box className="buttonArea">
              <Button
                style={{
                  backgroundColor: '#662483',
                  color: 'white',
                  width: '100%',
                  height: 48,
                  fontSize: '16px',
                  textTransform: 'capitalize',
                  borderRadius: 8
                }}
                id="startMaintenance"
                variant="contained"
                disableElevation
                onClick={handleSubmit(submit)}>
                Salvar
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#F0F1F5',
                  width: '100%',
                  height: 48,
                  fontSize: '16px',
                  textTransform: 'capitalize',
                  borderRadius: 8
                }}
                disableElevation
                onClick={closeRenameModal}>
                Voltar
              </Button>
            </Box>
          </ModalContent>
        </Box>
      </Background>
    </ModalComponent>
  );
}
