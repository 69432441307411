/* eslint-disable jsx-a11y/anchor-is-valid */
import OutsideClickHandler from 'react-outside-click-handler';
import { Box, Typography, Button } from '@mui/material';
import Container from './NotificationsModal.style';
import useNotificationModalController from './NotificationsModal.controller';
import { INotificationsData } from './NotificationsModal.props';

export function NotificationsModal(props: INotificationsData) {
  const { data } = props;
  const {
    closeActionModalOnClickAway,
    changeToNextPage,
    changeToPrevPage,
    navigateToPage,
    hasNextPage,
    hasPrevPage
  } = useNotificationModalController();
  const hasNotification = data.length > 0;
  const hasNoPrevPag = !hasPrevPage;
  const hasNoNextPage = !hasNextPage;

  return (
    <OutsideClickHandler onOutsideClick={closeActionModalOnClickAway}>
      <Container>
        {hasNotification &&
          data.map((notification) => (
            <Box
              key={notification.id}
              onClick={() => navigateToPage(notification)}
              className={
                notification.newNotification
                  ? 'notificationData newNotification'
                  : 'notificationData oldNotification'
              }>
              <Typography className="title">{notification.title}</Typography>
              <Typography>{notification.description}</Typography>
            </Box>
          ))}

        {hasNotification && (hasNextPage || hasPrevPage) && (
          <Box display="flex">
            <Button
              variant="text"
              size="small"
              className="showMore"
              disabled={hasNoPrevPag}
              onClick={changeToPrevPage}>
              Voltar
            </Button>
            <Button
              variant="text"
              size="small"
              className="showMore"
              disabled={hasNoNextPage}
              onClick={changeToNextPage}>
              Mostrar mais
            </Button>
          </Box>
        )}

        {!hasNotification && (
          <Box className="notificationData noData">
            <Typography color="#6D6D6D">Não há notificações disponíveis.</Typography>
          </Box>
        )}
      </Container>
    </OutsideClickHandler>
  );
}
