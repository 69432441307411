import { Autocomplete, TextField } from '@mui/material';
import { useEffect } from 'react';
import Container from './Autocomplete.style';

export default function AutocompleteInput({
  id = 'teste',
  options,
  onChange,
  setIsSelectedBlock,
  setUnitListData,
  setIsSelected,
  isDisabled = false,
  getValue,
  setValue,
  handleSearch,
  isInputValue,
  cleanUnitValue,
  isBlock
}: any) {
  useEffect(() => {
    setValue(setIsSelected);
  }, [setIsSelected]);

  return (
    <Container id={id}>
      <Autocomplete
        disablePortal
        disabled={isDisabled}
        id={`${id} combo-box-demo`}
        options={options}
        sx={{ width: '100%', background: !isDisabled ? 'transparent' : 'rgba(122,122,122,0.3)' }}
        value={getValue}
        inputValue={isInputValue}
        noOptionsText="nenhum resultado encontrado"
        onInputChange={(e, value) => handleSearch(value)}
        onChange={(event, newValue) => {
          onChange(newValue.value);
          setValue(newValue.label);
          setIsSelectedBlock(newValue.value);
          setUnitListData(newValue.value);
          if (isBlock) cleanUnitValue();
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} />}
      />
    </Container>
  );
}
