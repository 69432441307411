import { useInformativesStore, usePreviewImageModalStore } from '../../../../store';

function usePreviewImageController() {
  const { image } = useInformativesStore();
  const { handleImagePreviewOpen } = usePreviewImageModalStore();

  return { image, handleImagePreviewOpen };
}

export default usePreviewImageController;
