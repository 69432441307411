import { useEffect, useState } from 'react';
import {
  useActionModalStore,
  useDeleteModalStore,
  useInfoModalStore,
  useLoadModalStore,
  usePaginationStore,
  useReserveStore,
  useSortItemsStore
} from '../../store';
import { Errors } from '../../constants/Errors';

function useReservesController() {
  const [totalReserves, setTotalReserves] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchString, setSearchString] = useState('');

  const { handleSetSortFields } = useSortItemsStore();
  const {
    getAllSortReservesDataRequest,
    direction,
    field,
    reserveId,
    reservesId,
    reserveList,
    page,
    status,
    handleSetStatus,
    handleSetPage,
    handleSetReserveId,
    deleteReserveRequest,
    searchReserveRequest,
    handleSetReservesId,
    handleRemoveReserve,
    handleReserveInformationModalOpen,
    changeStatusReserves,
    handleSetReserveList,
    handleJustificationModalOpen
  } = useReserveStore();
  const { isOpenDeleteModal, handleDeleteModalOpen } = useDeleteModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { handleDrawerOpen } = useActionModalStore();
  const {
    isOpenInfoModal,
    handleModalOpen,
    handleSetIsSuccessfully,
    handleSetText,
    isSuccessfully,
    text
  } = useInfoModalStore();
  const { currentPage, handleSetCurrentPage } = usePaginationStore();

  async function authorizeOrRevoke(newStatus: string) {
    await changeStatusReserves(reserveId, newStatus);
  }

  async function changeStatus(changedStatus: string) {
    toogleLoading();
    try {
      handleSetStatus(changedStatus);
      await authorizeOrRevoke(changedStatus);
      handleSetIsSuccessfully(true);
      handleSetText('Reserva autorizada com sucesso!');
      handleModalOpen();
    } catch (error: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    } finally {
      handleSetStatus('');
      toogleLoading();
    }
  }

  const actionModalDataAuthorizedRevoked = [
    {
      ButtonsText: 'Informações',
      OnClick: () => {
        handleReserveInformationModalOpen();
      }
    }
  ];

  const actionModalDataCanceled = [
    {
      ButtonsText: 'Informações',
      OnClick: () => {
        handleReserveInformationModalOpen();
      }
    },
    {
      ButtonsText: 'Excluir',
      OnClick: () => {
        handleDeleteModalOpen();
        handleDrawerOpen();
        handleSetIsSuccessfully(true);
        handleSetText('Reserva excluida com sucesso!');
      }
    }
  ];

  const actionModalDataPendent = [
    {
      ButtonsText: 'Informações',
      OnClick: () => {
        handleReserveInformationModalOpen();
      }
    },
    {
      ButtonsText: 'Autorizar',
      OnClick: () => {
        changeStatus('AUTHORIZED');
      }
    },
    {
      ButtonsText: 'Revogar',
      OnClick: () => {
        handleJustificationModalOpen();
      }
    }
  ];

  function handleActionModalData(rowStatus: string) {
    if (rowStatus === 'Autorizado' || rowStatus === 'Revogado')
      return actionModalDataAuthorizedRevoked;

    if (rowStatus === 'Cancelado') return actionModalDataCanceled;

    return actionModalDataPendent;
  }

  const tableHead = [
    'Morador',
    'Unidade',
    'Bloco',
    'Data de Utilização',
    'Horários de Utilização',
    'Data de Solicitação',
    'Área',
    'Status',
    'Ações'
  ];

  async function getAllData() {
    toogleLoading();
    try {
      const { data } = await getAllSortReservesDataRequest(page, field, direction, searchString);
      const formatedData = data.data;
      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      setTotalReserves(data.totalReserves);
      setTotalPages(data.totalPages);

      handleSetReservesId(formatedRowsId);
      handleSetReserveList(formatedData);
    } finally {
      toogleLoading();
    }
  }

  function setSetSortFields() {
    handleSetSortFields([
      'resident_model.name',
      'unit_model.name',
      'block_model.name',
      'date',
      'schedule_model.start_time',
      'reservation_model.created_at',
      'area_model.area_name',
      'reservation_model.status'
    ]);
  }

  async function changePage(pageNumber: number) {
    toogleLoading();

    try {
      const { data } = await getAllSortReservesDataRequest(
        String(pageNumber),
        field,
        direction,
        searchString
      );

      const formatedData = data.data;

      const formatedRowsId = data.data.map((item: { id: string }) => {
        return item.id;
      });

      handleSetPage(String(pageNumber));

      setTotalPages(data.totalPages || 0);
      setTotalReserves(data.totalReserves || 0);

      if (data) {
        handleSetReservesId(formatedRowsId);
        handleSetReserveList(formatedData);
      }
    } finally {
      toogleLoading();
    }
  }

  async function onSubmitDelete(id: string) {
    toogleLoading();
    try {
      await deleteReserveRequest(id);
      handleRemoveReserve(id);
      handleSetIsSuccessfully(true);
      handleSetText('Reserva excluída com sucesso!');
      handleModalOpen();
    } catch (error: any) {
      handleSetIsSuccessfully(false);
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  async function handleSearch(value: string) {
    setSearchString(value);
    const formatedDataForSearch = { queryText: value };
    const { data } = await searchReserveRequest(
      formatedDataForSearch,
      field,
      direction,
      String(currentPage)
    );
    const formatedData = data.data;
    const formatedRowsId = data.data.map((item) => {
      return item.id;
    });

    if (data) {
      handleSetReserveList(formatedData);
      handleSetReservesId(formatedRowsId);
      setTotalPages(data.totalPages || 1);
      setTotalReserves(data.totalReserves || 1);
    }
  }

  function setReserveIfExist() {
    if (reserveList.length <= 0) return;
    handleSetReservesId(reservesId);
  }

  async function sortReserves(newPage: string, sortByField: string, sortByDirection: string) {
    const data = await getAllSortReservesDataRequest(
      newPage,
      sortByField,
      sortByDirection,
      searchString
    );

    return data;
  }

  useEffect(() => {
    getAllData();
    setSetSortFields();
  }, [status]);

  useEffect(() => {
    setReserveIfExist();
  }, [reserveList, status]);

  useEffect(() => {
    handleSetCurrentPage(currentPage);
    changePage(currentPage);
  }, [currentPage]);

  return {
    tableHead,
    isOpenDeleteModal,
    totalReserves,
    isOpenInfoModal,
    totalPages,
    isSuccessfully,
    text,
    reservesId,
    reserveId,
    reserveList,
    sortReserves,
    handleActionModalData,
    handleSetReserveId,
    changePage,
    handleModalOpen,
    handleDrawerOpen,
    handleDeleteModalOpen,
    handleSearch,
    onSubmitDelete
  };
}

export default useReservesController;
