/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-useless-escape */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Params } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { toast } from 'react-toastify';
import { useFilesStore } from '../../../store/Files/Files.store';
import { instance } from '../../../services/api';
import { useInfoModalStore, usePaymentSlipStore, useLoadModalStore } from '../../../store';
import { Errors } from '../../../constants/Errors';

import { FormRequiredFields } from './Modal.props';

export const useModalController = (
  foldId: Readonly<Params<string>>,
  getAllFiles: () => void,
  setIsModalRegisterOpen: (value: boolean) => void,
  onComplete: () => void,
  setText: (text: string) => void,
  setSuccess: (isSuccess: boolean) => void,
  toggleModal: () => void
) => {
  const { setFiles, setFileFinalized } = useFilesStore();
  const { toogleLoading } = useLoadModalStore();
  const { handleSetText, handleModalOpen, isOpenInfoModal } = useInfoModalStore();
  const { setFile, file, registerPaymentSlipFileRequest } = usePaymentSlipStore();
  const [isProgress, setProgress] = useState('0');
  const [isDisable, setIsDisable] = useState(false);
  const [fileErrorMsg, setFileErrorMsg] = useState<string | undefined>(undefined);

  const schema = yup.object().shape({
    date: yup.string().required('Por favor, preencha a data.'),
    paymentValue: yup
      .string()
      .required('Por favor, preencha o valor')
      .test('price', 'O valor precisa ser menor ou igual a R$ 50.000,00.', (value) => {
        if (value) {
          const numbers = value.replace(/[^\d]/g, '');
          if (Number(numbers) > 5000000) {
            return false;
          }
          if (Number(numbers) <= 5000000) {
            return true;
          }
          return true;
        }
        return true;
      }),
    url: yup.string()
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      date: '',
      paymentValue: '',
      url: ''
    },
    resolver: yupResolver(schema)
  });

  function clearFields() {
    setValue('date', '');
    setValue('paymentValue', '');
    setFile(undefined);
    setFileErrorMsg(undefined);
  }

  const formatPrice = (price: string) => {
    return Number(price.match(/\d+/g)?.join(''));
  };

  const uploadFile = async (paymentFile: any): Promise<any> => {
    try {
      setIsDisable(true);
      const formData = new FormData();
      formData.append('file', paymentFile);

      const data = await instance.request({
        method: 'POST',
        url: `receivable/picture/${uuidv4()}`,
        baseURL: process.env.REACT_APP_STORAGE_URL,
        headers: { 'Content-Type': 'multipart/form-data' },
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        data: formData,
        onUploadProgress: (event) => {
          const progress: number = Math.round((event.loaded * 100) / event.total);
          setProgress(progress.toString());
          if (progress === 100) {
            setFileFinalized(progress.toString(), paymentFile.name);
          }
        }
      });

      return data;
    } catch (error: any) {
      setText(
        (Errors as any)({})[error.response.data.errorCode] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      setSuccess(false);
      setIsDisable(false);
    }
  };

  const createFileRequest = async () => {
    const promises: any = [];

    promises.push(
      new Promise((resolve, reject) => {
        uploadFile(file)
          .then(async (response) => {
            const { id } = foldId;
            const { date, paymentValue } = getValues();

            const dataToSend = {
              name: file.name,
              url: response.data.data.url,
              date: new Date(date).toISOString(),
              price: formatPrice(paymentValue),
              size: file.size,
              folderId: id!
            };

            toogleLoading();
            await registerPaymentSlipFileRequest(dataToSend);

            resolve(response);
            setSuccess(true);
            setText('Arquivo adicionado com sucesso!');
            setIsDisable(false);
            clearFields();
          })
          .catch((error) => {
            reject(error);
          });
      })
    );

    if (promises.length === 0) {
      setIsModalRegisterOpen(false);
      handleSetText('Nenhum arquivo foi selecionado');
      return handleModalOpen();
    }
    setFiles();
    return Promise.all(promises);
  };

  const sendRequest = async () => {
    const { date } = getValues();
    const biggerThan100Years = Number(date.split('-')[0]) >= 2123;

    if (!file) {
      setFileErrorMsg('Por favor adicione o boleto');
      return;
    }

    if (biggerThan100Years) {
      toast.error('Por favor, insira um ano válido.');
      return;
    }

    setIsDisable(true);
    const promise = await createFileRequest();
    if (promise) {
      toogleLoading();
      onComplete();
      clearFields();
      getAllFiles();
    }
    setIsDisable(false);
  };

  function closeModal() {
    clearFields();
    toggleModal();
  }

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    }
  };

  useEffect(() => {
    setIsDisable(false);
    if (file) setFileErrorMsg(undefined);
  }, [isOpenInfoModal, file]);

  return {
    sendRequest,
    fileErrorMsg,
    currencyConfig,
    isProgress,
    isDisable,
    control,
    register,
    handleSubmit,
    errors,
    closeModal
  };
};
