/* eslint-disable no-underscore-dangle */
import { instance } from '../../api';

import {
  IEnterpriseByIdAllDataDtoOutput,
  EnterpriseRequestData,
  IAllEnterpriseDtoOutput,
  SearchEnterprises,
  IEnterpriseListForSelectInput
} from './Enterprise.dto';

export const registerEnterprise = async (enterpriseData: FormData): Promise<void> => {
  try {
    await instance.post('enterprise/', enterpriseData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchEnterprises = async (
  enterprisesData: SearchEnterprises
): Promise<IAllEnterpriseDtoOutput> => {
  try {
    const { data } = await instance.get(
      `enterprise/?search=${enterprisesData.queryText}&filterFields=id,status,name,city,state,street,number,type,unit&includeMeta=true&sortBy=name`
    );

    const enterpriseData = data.enterprises.data;

    const totalEnterprises = data.enterprises.metaData ? data.enterprises.metaData.count : 0;
    const totalPages = data.enterprises.metaData ? data.enterprises.metaData.pages : 0;

    const formatedData = enterpriseData.map((enterprise: EnterpriseRequestData) => {
      return {
        id: enterprise.id,
        name: enterprise.name,
        location: `${enterprise.city}, ${enterprise.state} `,
        address: `${enterprise.street}, ${enterprise.number}`,
        propertyType: enterprise.type,
        status: enterprise.status,
        unit: enterprise.unit
      };
    });

    return {
      data: { data: formatedData, totalEnterprises, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortEnterpriseData = async (
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IAllEnterpriseDtoOutput> => {
  try {
    const { data } = await instance.get(
      `enterprise/?page=${page}&includeMeta=true&filterFields=id,status,name,city,state,street,number,type,unit&sortDirection=${sortByDirection}&sortBy=${sortByField}`
    );
    const { enterprises } = data;
    const formatedData = enterprises.data.map((enterprise: EnterpriseRequestData) => {
      return {
        id: enterprise.id,
        name: enterprise.name,
        location: `${enterprise.city}, ${enterprise.state} `,
        address: `${enterprise.street}, ${enterprise.number}`,
        propertyType: enterprise.type,
        status: enterprise.status,
        unit: enterprise.unit
      };
    });

    const totalEnterprises = data.enterprises.metaData ? data.enterprises.metaData.count : 0;
    const totalPages = data.enterprises.metaData ? data.enterprises.metaData.pages : 0;

    return {
      data: { data: formatedData, totalEnterprises, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getEnterpriseNameIdList = async (): Promise<IEnterpriseListForSelectInput[]> => {
  try {
    const { data } = await instance.get(
      'enterprise/?filterFields=id,name&sortDirection=ASC&sortBy=name&limit=0'
    );
    const { enterprises } = data;
    const formatedData: IEnterpriseListForSelectInput[] = enterprises.data.map(
      (enterprise: { id: string; name: string }) => {
        return {
          value: enterprise.id,
          label: enterprise.name
        };
      }
    );

    return formatedData;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getEnterpriseData = async (): Promise<IEnterpriseByIdAllDataDtoOutput> => {
  const id = window.location.href.split('/').pop();

  try {
    const { data } = await instance.get(`enterprise/${id}`);
    const responseData = data.data;
    const formatedData = {
      id: responseData.id,
      incorporator: responseData.incorporator,
      unit: responseData.unit,
      name: responseData.name,
      adminEmail: responseData.email,
      propertyType: responseData.type,
      status: responseData.status,
      city: responseData.address.city,
      state: responseData.address.state,
      CEP: responseData.address.CEP,
      street: responseData.address.street,
      number: responseData.address.number,
      complement: responseData.address.complement,
      district: responseData.address.neighborhood,
      country: responseData.address.country,
      functionalitiesId: responseData.functionalities,
      colors: responseData.colors,
      logo: responseData.logoUrl,
      manual: responseData.manualUrl
    };
    return {
      data: formatedData
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editEnterprise = async (enterpriseData: FormData): Promise<void> => {
  const id = window.location.href.split('/').pop();

  try {
    await instance.patch(`enterprise/${id}`, enterpriseData);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteEnterprise = async (id: string): Promise<void> => {
  try {
    await instance.delete(`enterprise/${id}`, {
      headers: {
        'Content-type': 'application/json;charset=utf-8'
      }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getAWSImageUrl = async (imageID: string) => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `getImage/${imageID}`,
      baseURL: process.env.REACT_APP_STORAGE_URL
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
