/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Modal as ModalComponent, Typography, Button, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useJustificationModalController } from './JustificationModal.controller';
import Background from './JustificationModal.styled';

const closeIcon = require('../../../../assets/images/close.svg');

export function JustificationModal() {
  const {
    isOpenJustificationModal,
    control,
    handleSubmit,
    register,
    handleJustificationModalOpen,
    submitJustification,
    handleCloseJustificationModal
  } = useJustificationModalController();

  return (
    <ModalComponent open={isOpenJustificationModal} onClose={handleJustificationModalOpen}>
      <Background>
        <Box className="Container">
          <Box className="closeBtnContainer" onClick={handleCloseJustificationModal}>
            <closeIcon.ReactComponent className="closeBtn" />
          </Box>
          <Box>
            <Typography className="title">Justificativa</Typography>
          </Box>
          <Box className="subTitleContainer">
            <Typography className="subTitle">Qual o motivo da revogação?</Typography>
          </Box>
          <Box className="inputContainer">
            <Controller
              name="justification"
              control={control}
              render={() => (
                <TextField
                  id="reserves_justification"
                  placeholder="Digite aqui"
                  className="input"
                  multiline
                  rows={8}
                  {...register('justification')}
                />
              )}
            />
          </Box>

          <Box className="boxContainer">
            <Box className="buttonContainer">
              <Button
                style={{
                  backgroundColor: '#F0F1F5',
                  color: '#2C2C2C',
                  width: '50%',
                  height: 44,
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 500,
                  textTransform: 'capitalize'
                }}
                variant="contained"
                disableElevation
                onClick={handleCloseJustificationModal}>
                Voltar
              </Button>
              <Button
                id="reserves_revokeButton"
                style={{
                  backgroundColor: '#662483',
                  color: '#fff',
                  width: '50%',
                  height: 44,
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 500,
                  textTransform: 'capitalize'
                }}
                variant="contained"
                disableElevation
                onClick={handleSubmit(submitJustification)}>
                Revogar
              </Button>
            </Box>
          </Box>
        </Box>
      </Background>
    </ModalComponent>
  );
}
