import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 16px 40px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;

  .icon {
    color: rgba(0, 0, 0, 0.5);
  }

  #fileInput {
    cursor: pointer;
    color: transparent;
    position: absolute;
    z-index: 100;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  #fileInput::file-selector-button {
    display: none;
  }

  .closeButton {
    margin-top: -15px;
    color: #646464;
  }

  .closeButton:hover {
    background-color: transparent;
    opacity: 0.9;
  }

  .link {
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: none;
  }
`;

export default Container;
