import styled from 'styled-components';

export const Background = styled.div`
  display: flex;
  margin: 90px 24px 0px 20px;
  padding-right: 24px;
  padding-left: 24px;

  @media (max-width: 1100px) {
    .divider {
      display: none;
    }
  }

  @media (max-width: 700px) {
    padding: 0;
  }
`;
