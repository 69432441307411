import styled from 'styled-components';

const FunctionalitiesContainer = styled.div`
  svg {
    color: #bec8e0;
  }

  .on {
    opacity: 0.3;
  }

  @media (max-width: 1638px) {
    .functionalitiesBox {
      span {
        font-size: 13.4px;
      }
    }
  }

  @media (max-width: 400px) {
    span {
      font-size: 12.5px;
    }
  }
`;

export default FunctionalitiesContainer;
