import create from 'zustand';
import { IVotingByIdAllDataDtoOutput } from '../../services/requests/voting/Voting.dto';
import {
  createVoting,
  searchVoting,
  editVoting,
  getVotingDataById,
  deleteVoting,
  closeVoting,
  openVoting,
  sortVotingData
} from '../../services/requests/voting/Voting.request';

import { VotingStoreProps } from './Voting.props';

const initialState = {
  files: [],
  id: '',
  name: '',
  type: '',
  created_at: '',
  updated_at: '',
  status: '',
  field: 'created_at',
  direction: 'DESC'
};

export const useVotingStore = create<VotingStoreProps>((set, get) => ({
  ...initialState,
  errorVoting: '',
  variantInfor: '',
  idVoting: '',
  files: [],
  name: '',

  getVotingName: () => {
    const { name } = get();
    return name;
  },
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),

  handleSetVotingId: (filesId) => set((state) => ({ ...state, filesId })),

  editVoteRequest: async (id, data) => {
    try {
      await editVoting(id, data);
    } catch (error: any) {
      const { message } = error;
      throw new Error(message);
    }
  },

  getVotingDataByIdRequest: async (id) => {
    const data = await getVotingDataById(id);
    return data;
  },

  createVoteRequest: async (data) => {
    try {
      await createVoting(data);
    } catch (error: any) {
      const { message } = error;
      throw new Error(message);
    }
  },

  searchVotingRequest: async (
    search,
    page,
    sortByField,
    sortByDirection
  ): Promise<IVotingByIdAllDataDtoOutput> => {
    const { data } = await searchVoting(search, sortByField, sortByDirection, page);
    return { data };
  },
  setStatus: (status: string) => set((state) => ({ ...state, status })),
  deleteVotingRequest: async (id) => {
    try {
      await deleteVoting(id);
    } catch (error: any) {
      const { message } = error;
      throw new Error(message);
    }
  },
  closeVotingRequest: async (id) => {
    try {
      await closeVoting(id);
    } catch (error: any) {
      const { message } = error;
      throw new Error(message);
    }
  },
  openVotingRequest: async (id) => {
    try {
      await openVoting(id);
    } catch (error: any) {
      const { message } = error;
      throw new Error(message);
    }
  },
  getAllSortVotingDataRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortVotingData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      const { message } = error;
      throw new Error(message);
    }
  }
}));
