/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { EditOutlined, DeleteOutline } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import {
  ActionModal,
  BackToLastPageModal,
  CustomButton,
  CustomSearchInput,
  CustomTable,
  DeleteModal,
  InfoModal
} from '../../components';
import { Modal } from './components/Modal/Modal';
import Container from './Residents.style';
import useResidentsController from './Residents.controller';

const sendIcon = require('../../assets/images/sendIcon.svg');

export function Residents() {
  const {
    residentsId,
    tableData,
    totalPages,
    totalResidents,
    tableHeaders,
    isOpenDeleteModal,
    isOpenInfoModal,
    deleteResidentsData,
    isOpenResidentModal,
    infoText,
    isEdit,
    residentId,
    isSuccessfully,
    actionModalData,
    isOpenBackToLastPageModal,
    sortResidentsData,
    changePage,
    getResidentsData,
    handleSearch,
    handleModalOpen,
    handleDeleteModalOpen,
    handleSetResidentId,
    handleDrawerOpen,
    deleteResidentOfRow,
    handleRegisterResidentModal,
    handleResidentModalOpen
  } = useResidentsController();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} lg={9.5}>
          <Typography variant="h5" fontWeight={500} marginBottom={5}>
            Moradores
          </Typography>
          <CustomSearchInput id="resident-search" handleSearch={handleSearch} />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={2.5} alignSelf="flex-end">
          <CustomButton
            id="residents-register"
            onClick={handleRegisterResidentModal}
            text="Cadastrar morador"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            rowsId={residentsId}
            tableHead={tableHeaders}
            tableData={tableData || []}
            onClick={handleDrawerOpen}
            totalPages={totalPages}
            totalItems={totalResidents}
            funcToChangePage={changePage}
            handleSetItemId={handleSetResidentId}
            containId
            noFilesFoundText="Não há nenhum registro de moradores no momento."
            sortFunc={sortResidentsData}
            ActionModal={
              <ActionModal
                ActionModalData={actionModalData}
                ButtonsIcon={[
                  <EditOutlined />,
                  <sendIcon.ReactComponent style={{ width: '18px', height: '20px' }} />,
                  <DeleteOutline />
                ]}
              />
            }
          />
        </Grid>
      </Grid>
      {isOpenDeleteModal && (
        <DeleteModal
          title={deleteResidentsData.title}
          onClickDelete={() => deleteResidentOfRow(residentId)}
          handleModalClose={handleDeleteModalOpen}
        />
      )}

      {isOpenResidentModal && (
        <Modal
          isModalOpen
          toggleModal={handleRegisterResidentModal}
          title={isEdit ? 'Editar morador' : 'Cadastrar Morador'}
          edit={isEdit}
          residentId={residentId}
          refresh={() => getResidentsData}
        />
      )}

      {isOpenInfoModal && (
        <InfoModal isSuccessfully={isSuccessfully} onClick={handleModalOpen} text={infoText} />
      )}
      {isOpenBackToLastPageModal && (
        <BackToLastPageModal goToPage="/residents" handleModalClose={handleResidentModalOpen} />
      )}
    </Container>
  );
}
