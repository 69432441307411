/* eslint-disable @typescript-eslint/no-unused-expressions */
import create from 'zustand';

import { toast } from 'react-toastify';
import { LoginStates, LoginStoreProps } from './Auth.props';

import {
  login as loginRequest,
  resetPassword as resetPasswordRequest,
  changePassword as changePasswordRequest
} from '../../services/requests/auth/auth.request';

const initialState: LoginStates = {
  email: '',
  password: '',
  accessToken: '',
  refreshToken: ''
};

export const useAuthStore = create<LoginStoreProps>((set) => ({
  ...initialState,

  login: async (email: string, password: string) => {
    set({ email, password });
    try {
      const { accessToken, refreshToken } = await loginRequest({ email, password });

      set({
        accessToken,
        refreshToken
      });
      localStorage.setItem('token', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      return;
    } catch (error: any) {
      toast.error(
        String(error).includes('status need to be completed')
          ? 'Empreendimento inativo!'
          : 'Credenciais inválidas!'
      );
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    set({ accessToken: '', refreshToken: '' });
  },

  resetPassword: async (email: string, toggleModal: () => void) => {
    try {
      await resetPasswordRequest({ email });

      toggleModal();
    } catch (error) {
      toast.error('Email não cadastrado');
    }
  },

  changePassword: async (password: string, confirmPassword: string, token: string | null) => {
    try {
      await changePasswordRequest({ password, confirmPassword, token });

      return;
    } catch (error) {
      toast.error('Erro ao resetar senha');
    }
  }
}));
