import minilogoheader from '../../../../assets/images/minilogoheader.png';
import useHeaderLogoController from './HeaderLogo.controller';

import Container from './HeaderLogo.style';

function HeaderLogo() {
  const { widthState } = useHeaderLogoController();
  return (
    <Container style={{ display: Number(widthState) < 700 ? 'none' : 'flex' }}>
      <img src={minilogoheader} alt="mini logo" />
    </Container>
  );
}

export default HeaderLogo;
