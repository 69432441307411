import { Button, IconButton } from '@mui/material';
import styled from 'styled-components';

const Background = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 116px;
  .subtitle {
    margin-top: 8px;
  }
  .loginContainer {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 400px;
  }
  .logoAndLoginContainer {
    box-shadow: 3px 5px 11px rgba(67, 89, 113, 0.3);
    border-radius: 20px;
    padding: 30px;
  }
  .linkContainer {
    margin-bottom: 80px;
  }
  .inputErrorContainer {
    margin-bottom: 20px;
  }
  .errorMessage {
    margin-top: 5px;
    font-size: 15px;
    color: red;
  }
  @media (max-width: 850px) {
    width: 100vw;
  }
  @media (max-width: 500px) {
    .loginContainer {
      width: 300px;
    }
  }
`;

export default Background;

export const ModalContent = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 20px 50px 40px;
  border-radius: 16px;
`;

export const ModalTitle = styled.p`
  font-family: 'Inter';
  margin-bottom: 13px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #2c2c2c;
`;

export const ModalSubtitle = styled.p`
  font-family: 'Inter';
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #656565;
`;

export const ModalBackButton = styled(Button)``;

export const ModalBackButtonText = styled.p`
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  color: white;
`;

export const ModalCloseButton = styled(IconButton)``;
