/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Typography } from '@mui/material';
import { FreeTimeScheduleProps } from './FreeTimeSchedule.props';
import Container from './FreeTimeSchedule.style';

export function FreeTimeSchedule(props: FreeTimeScheduleProps) {
  const { final_time, initial_time } = props;
  return (
    <Container>
      <Box className="dateBox">
        <span className="leftLine" />
        <Typography className="text">
          <span className="timeText">Início</span>
          <span className="time">{initial_time}</span>
        </Typography>
        <Typography className="text">
          <span className="timeText">Término</span>
          <span className="time">{final_time}</span>
        </Typography>
      </Box>
    </Container>
  );
}
