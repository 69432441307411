/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Button, Grid, Typography, IconButton, TextField } from '@mui/material';
import MaskedInput from 'react-text-mask';
import { Controller } from 'react-hook-form';
import Container from './ReservationPeriod.styled';
import useReservationPeriodController from './ReservationPeriod.controller';
import { IReservationPeriodProps } from './ReservationPeriod.props';

const trash = require('../../../../assets/images/trash2.svg');
const clock = require('../../../../assets/images/clockicon.svg');

export function ReservationPeriod(reservationProps: IReservationPeriodProps) {
  const { weekDay, allTimeOfDays, allWeekDays } = reservationProps;
  const { allTimes, control, errors, getWeekDay, register, addNewTime, handleSubmit, removeTime } =
    useReservationPeriodController(weekDay, allTimeOfDays);

  return (
    <Container>
      <Grid item xs={12} marginTop={2.5} />
      <Grid item xs={12} marginTop={2.5}>
        <Typography className="midTitle">Períodos para locar o ambiente</Typography>
      </Grid>
      <Grid item xs={12} marginTop={2.5}>
        <Box className="timeContainer">
          <Box className="timeInputContainer firstBox">
            <Typography className="labelText">Início</Typography>
            <Box>
              <Controller
                name="initial_time"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaskedInput
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    onChange={onChange}
                    value={value}
                    render={(ref, props) => (
                      <Box className="timeContainer">
                        <TextField
                          id="area-start-input"
                          className="timeInput"
                          placeholder="09:00"
                          {...register('initial_time')}
                          error={!!errors.initial_time}
                          inputRef={ref}
                          {...props}
                        />
                        <clock.ReactComponent className="clockIcon" />
                      </Box>
                    )}
                  />
                )}
              />
              <Typography className="errorMessage">{errors.initial_time?.message}</Typography>
            </Box>
          </Box>
          <Box className="timeInputContainer">
            <Typography className="labelText">Término</Typography>
            <Box>
              <Controller
                name="final_time"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaskedInput
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    onChange={onChange}
                    value={value}
                    render={(ref, props) => (
                      <Box className="timeContainer">
                        <TextField
                          id="area-end-input"
                          className="timeInput"
                          placeholder="21:00"
                          {...register('final_time')}
                          error={!!errors.final_time}
                          inputRef={ref}
                          {...props}
                        />
                        <clock.ReactComponent className="clockIcon" />
                      </Box>
                    )}
                  />
                )}
              />
              <Typography className="errorMessage">{errors.final_time?.message}</Typography>
            </Box>
          </Box>
          <Button id="area-include-btn" onClick={handleSubmit(addNewTime)} className="timeButton">
            Incluir horários
          </Button>
        </Box>
      </Grid>
      {allTimes.length > 0 &&
        allTimes[getWeekDay()] &&
        allTimes[getWeekDay()].map(
          (
            { initial_time, final_time }: { initial_time: string; final_time: string },
            index: number
          ) => (
            <Grid item xs={12} marginTop={2.5} key={`${initial_time}:${final_time}`}>
              <Box className="timeContainer">
                <Box className="timeInputContainer firstBox">
                  <Typography className="labelText">Início</Typography>
                  <MaskedInput
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    className="timeInput"
                    render={(ref, props) => (
                      <Box className="timeContainer">
                        <TextField
                          disabled
                          value={initial_time}
                          className="timeInput"
                          placeholder={initial_time}
                          inputRef={ref}
                          {...props}
                        />
                        <clock.ReactComponent className="clockIcon" />
                      </Box>
                    )}
                  />
                </Box>
                <Box className="timeInputContainer">
                  <Typography className="labelText">Término</Typography>
                  <MaskedInput
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    className="timeInput"
                    render={(ref, props) => (
                      <Box>
                        <Box className="timeContainer">
                          <TextField
                            disabled
                            value={final_time}
                            className="timeInput"
                            placeholder={final_time}
                            inputRef={ref}
                            {...props}
                          />
                          <clock.ReactComponent className="clockIcon" />
                        </Box>
                        {initial_time > final_time && (
                          <Typography className="infoNextDayText">
                            Este horário refere-se ao próximo dia:{' '}
                            {allWeekDays[getWeekDay() + 1] || 'Domingo'}.
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Box>
                <Box>
                  <IconButton
                    id={String(index)}
                    onClick={() => removeTime(initial_time, final_time)}
                    className="trashButton">
                    <trash.ReactComponent />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          )
        )}
    </Container>
  );
}
