import create from 'zustand';
import { EnterpriseProps } from './Enterprise.props';

import {
  registerEnterprise,
  editEnterprise,
  getEnterpriseData,
  sortEnterpriseData,
  searchEnterprises,
  deleteEnterprise,
  getEnterpriseNameIdList
} from '../../services/requests/enterprise/Enterprise.request';

const initialState = {
  id: '',
  colors: { primary: '', secondary: '' },
  enterpriseList: [],
  enterprisesId: [],
  functionalities: [],
  district: '',
  city: '',
  state: '',
  street: '',
  cep: '',
  direction: 'ASC',
  field: 'name',
  placeholderFile: '',
  imageFile: undefined,
  logoUrl: '',
  signedLogoUrl: '',
  logo: '',
  imageName: '',
  manual: '',
  manualName: '',
  manualUrl: '',
  signedManualUrl: '',
  placeholderManual: null
};

const useEnterpriseStore = create<EnterpriseProps>((set, get) => ({
  ...initialState,

  handleSetEnterpriseId: (id) => set((state) => ({ ...state, id })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),
  handleSetEnterprisesId: (enterprisesId) => set((state) => ({ ...state, enterprisesId })),
  handleSetEnterpriseList: (enterpriseList) => set((state) => ({ ...state, enterpriseList })),
  handleRemoveOneEnterprise: (id) =>
    set((state) => ({
      ...state,
      enterpriseList: state.enterpriseList.filter((enterprise) => enterprise.id !== id),
      enterprisesId: state.enterprisesId.filter((enterpriseId) => enterpriseId !== id)
    })),
  handleSetColors: (colors) => set((state) => ({ ...state, colors })),
  handleSetFunctionalities: (functionalities) => set((state) => ({ ...state, functionalities })),
  handleSetAddress: (address) =>
    set((state) => ({
      ...state,
      district: address.district,
      city: address.city,
      state: address.state,
      street: address.street
    })),
  handleSetCep: (cep) => set((state) => ({ ...state, cep })),
  handleSetPlaceholderFile: (placeholderFile) => set((state) => ({ ...state, placeholderFile })),
  handleSetPlaceholderManual: (placeholderManual) =>
    set((state) => ({ ...state, placeholderManual })),
  handleSetLogo: (logo) => set((state) => ({ ...state, logo })),
  handleSetImageFile: (imageFile) => set((state) => ({ ...state, imageFile })),
  handleSetImageName: (imageName) => set((state) => ({ ...state, imageName })),
  handleSetManualName: (manualName) => set((state) => ({ ...state, manualName })),
  handleSetManual: (manual) => set((state) => ({ ...state, manual })),
  handleSetLogoUrl: (logoUrl) => set({ logoUrl }),
  handleSetManualUrl: (manualUrl) => set({ manualUrl }),

  registerEnterpriseRequest: async (enterpriseData): Promise<void> => {
    try {
      await registerEnterprise(enterpriseData);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  editEnterpriseRequest: async (enterpriseData): Promise<void> => {
    try {
      await editEnterprise(enterpriseData);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getEnterpriseDataRequest: async () => {
    const data = await getEnterpriseData();

    return data;
  },

  getEnterpriseNameIdListRequest: async () => {
    try {
      const data = await getEnterpriseNameIdList();
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllSortEnterpriseDataRequest: async (page, sortByField, sortByDirection) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortEnterpriseData(page, sortByField, sortByDirection);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchEnterprisesRequest: async (enterprisesData) => {
    try {
      const data = await searchEnterprises(enterprisesData);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  deleteEnterpriseRequest: async (id) => {
    try {
      await deleteEnterprise(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  setSignedLogoUrl: (signedLogoUrl) => set((state) => ({ ...state, signedLogoUrl })),

  setSignedManualUrl: (signedManualUrl) => set((state) => ({ ...state, signedManualUrl }))
}));

export { useEnterpriseStore };
