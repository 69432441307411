/* eslint-disable no-underscore-dangle */
import { instance } from '../../api';

const notificationsBaseURL = process.env.REACT_APP_MS_NOTIFICATION;

export const getAllNotifications = async (): Promise<any> => {
  try {
    const data = await instance.request({
      method: 'GET',
      url: 'notifications-manager/list-notifications',
      baseURL: notificationsBaseURL,
      params: {
        sortBy: 'notifications.createdAt',
        sortDirection: 'DESC',
        page: 1
      }
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getNextPageNotifications = async (page: number): Promise<any> => {
  try {
    const data = await instance.request({
      method: 'GET',
      url: 'notifications-manager/list-notifications',
      baseURL: notificationsBaseURL,
      params: {
        sortBy: 'notifications.createdAt',
        sortDirection: 'DESC',
        page
      }
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const markViewedNotifications = async (): Promise<void> => {
  try {
    await instance.request({
      method: 'GET',
      url: 'notifications-manager/mark-viewed-notifications',
      baseURL: notificationsBaseURL
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
