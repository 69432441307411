/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { Params } from 'react-router-dom';

import { useFilesStore } from '../../../store/Files/Files.store';
import { instance } from '../../../services/api';
import { useInfoModalStore } from '../../../store';
import { Errors } from '../../../constants/Errors';

export const useModalController = (
  foldId: Readonly<Params<string>>,
  getAllFolders: () => void,
  setIsModalRegisterOpen: (value: boolean) => void,
  onComplete: () => void,
  setText: (text: string) => void,
  setSuccess: (isSuccess: boolean) => void
) => {
  const { getFiles, setFiles, setFileFinalized } = useFilesStore();
  const { handleSetText, handleModalOpen, isOpenInfoModal } = useInfoModalStore();
  const [isProgress, setProgress] = useState(['0', '0', '0', '0', '0', '0', '0', '0', '0', '0']);
  const [isNameFile, setNameFile] = useState('');
  const [isDisable, setIsDisable] = useState(false);

  const uploadFile = async (file: any, index: number): Promise<void> => {
    try {
      const { id } = foldId;
      const formData = new FormData();
      formData.append('file', file);

      return await instance.request({
        method: 'PUT',
        url: `/folder/${id}`,
        baseURL: process.env.REACT_APP_STORAGE_URL,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        onUploadProgress: (event) => {
          const progress: number = Math.round((event.loaded * 100) / event.total);
          setIsDisable(true);

          isProgress[index] = progress.toString();
          setNameFile(file.name);
          if (progress === 100) {
            setNameFile('');
            setFileFinalized(progress.toString(), file.name);
          }
          setSuccess(true);
          setText('Arquivo adicionado com sucesso!');
        }
      });
    } catch (error: any) {
      setText(
        (Errors as any)({})[error.response.data.errorCode] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      setSuccess(false);
    } finally {
      setIsDisable(false);
    }
  };

  const createFileRequest = async () => {
    const promises: any = [];

    const files = await getFiles();

    [...files].forEach((file, index) => {
      promises.push(
        new Promise((resolve, reject) => {
          uploadFile(file, index)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
      );
    });

    if (promises.length === 0) {
      setIsModalRegisterOpen(false);

      handleSetText('Nenhum arquivo foi selecionado');

      return handleModalOpen();
    }

    setFiles();
    return Promise.all(promises);
  };

  const sendRequest = async () => {
    setIsDisable(true);
    const promise = await createFileRequest();

    if (promise) {
      onComplete();
    }
  };

  useEffect(() => {
    setIsDisable(false);
  }, [isOpenInfoModal]);

  return {
    sendRequest,
    isProgress,
    isNameFile,
    isDisable
  };
};
