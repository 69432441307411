/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Typography } from '@mui/material';
import Container from './CustomFileInput.style';
import useCustomFileInput from './CustomFileInput.controller';
import { CustomFileInputProps } from './CustomFileInput.props';

const imageIcon = require('../../../../assets/images/imageicon.svg');
const pdfIcon = require('../../../../assets/images/pdfIcon.svg');
const downloadIcon = require('../../../../assets/images/download.svg');

export function CustomFileInput(props: CustomFileInputProps) {
  const { type, link, handleClickOnImage } = props;
  const { files, isImage } = useCustomFileInput(type, link);

  const getElement = (button: any) => {
    if (button === 'fileInput') {
      const fileElem = document.getElementById(button);
      const newFileBtn = document.getElementById('newFileBtn');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newFileBtn.onclick = () => fileElem?.click();
    }
  };

  return (
    <Container>
      {link.length > 0 && link.length === isImage.length && (
        <Box
          id="newFileBtn"
          className="previewImageInput"
          onClick={(e) => {
            e.stopPropagation();
            e.isDefaultPrevented();
            getElement('fileInput');
          }}>
          {files && (
            <Box
              style={{ zIndex: 100 }}
              className="imageContainer"
              key={String(files[0].src)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.isDefaultPrevented();
                getElement('trash');
              }}>
              {link &&
                link.map((file, index) => (
                  <>
                    {!isImage[index] ? (
                      <Box>
                        <Box className="pdfContainer">
                          <pdfIcon.ReactComponent />
                        </Box>
                        <Box className="infoImage">
                          <imageIcon.ReactComponent className="imageIcon" />
                          <Box className="infoTextContainer">
                            <Typography className="imageText">{`${file.name.slice(
                              0,
                              12
                            )}...`}</Typography>

                            <a
                              target="_blank"
                              href={file.src}
                              rel="noreferrer"
                              onClick={() => {
                                // @ts-ignore
                                window.open(file.src, '_blank').focus();
                              }}>
                              <downloadIcon.ReactComponent />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Box className="previewImage" onClick={() => handleClickOnImage(file.src)}>
                          <img src={file.src} alt="ocurrence pic" />
                        </Box>
                        <Box className="infoImage">
                          <imageIcon.ReactComponent className="imageIcon" />
                          <Box>
                            <Typography className="imageText">{`${file.name.slice(
                              0,
                              12
                            )}...`}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </>
                ))}
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
}
