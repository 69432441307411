import { useEffect, useState } from 'react';

import { usePaginationStore } from '../../../../store';

function useCustomPaginationController(totalItems?: number, totalPages?: number) {
  const { handleSetCurrentPage } = usePaginationStore();

  const [pageNumbers, setPageNumbers] = useState([1, 2, 3]);
  const [initialShowingNumber, setInitialShowingNumber] = useState(1);
  const [finalShowingNumber, setFinalShowingNumber] = useState(0);

  function getPage(page: number) {
    setPageNumbers([page, page + 1, page + 2]);
    if (page === 1) {
      setInitialShowingNumber(1);
      return;
    }
    setInitialShowingNumber((page - 1) * 10);
  }

  function setInitialAndLastPage(page: number, enterprises: number) {
    const correctPage = Number(totalPages) > page ? page : Number(totalPages);
    if (Number(totalPages) !== 0 && Number(totalPages) < page) {
      const correctPageNumbers = [Number(totalPages), Number(totalPages), Number(totalPages)];

      setPageNumbers(correctPageNumbers);
    }

    if (correctPage === 1) {
      setInitialShowingNumber(1);
      setFinalShowingNumber(enterprises > 10 ? 10 : enterprises);
      return;
    }
    setInitialShowingNumber((correctPage - 1) * 10);
    setFinalShowingNumber(correctPage * 10 > enterprises ? enterprises : correctPage * 10);
  }

  async function changePage(
    page: number,
    totalEnterprises: number,
    funcToChangePage: (page: number) => void
  ) {
    if (page < 1 || page > Number(totalPages)) return;

    funcToChangePage(page);
    setInitialAndLastPage(page, totalEnterprises);
    getPage(page);
  }

  useEffect(() => {
    setInitialAndLastPage(pageNumbers[0], Number(totalItems));
  }, [totalItems, totalPages]);

  useEffect(() => {
    handleSetCurrentPage(pageNumbers[0]);
  }, [pageNumbers]);

  return {
    changePage,
    pageNumbers,
    initialShowingNumber,
    finalShowingNumber
  };
}

export default useCustomPaginationController;
