/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-useless-escape */
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useBlocksStore, useLoadModalStore, useInfoModalStore } from '../../../../store';
import { IRegisterBlockModalFormRequiredFields } from './RegisterBlockModal.props';
import { Errors } from '../../../../constants/Errors';

import { useBlocksController } from '../../Blocks.controller';

function useRegisterBlockModalController() {
  const [allBlocks, setAllBlocks] = useState<{ block_name: string }[]>([]);
  const [blocksList, setBlocksList] = useState<string[]>();

  const {
    isOpenRegisterModal,
    enterpriseID,
    blocksList: existingBlocksList,
    setOpenRegisterModal,
    registerBlockRequest,
    setIsSuccessfully,
    setSuccessMessage
  } = useBlocksStore();
  const { toogleLoading } = useLoadModalStore();
  const { handleModalOpen } = useInfoModalStore();

  const { getAllData } = useBlocksController();

  const blockDefaultValues = {
    block_name: ''
  };

  const blockReservationSchema = yup.object().shape({
    block_name: yup
      .string()
      .matches(
        /^[a-zA-Z]*$/g,
        'São permitidos apenas letras, sem caracteres especiais e sem espaços'
      )
      .max(20, 'São permitidos apenas 20 caracteres.')
      .required('Por favor, preencha o  nome do bloco.')
      .trim()
  });

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<IRegisterBlockModalFormRequiredFields>({
    defaultValues: blockDefaultValues,
    resolver: yupResolver(blockReservationSchema)
  });

  function validateBlock(block_name: string) {
    let error = false;

    if (existingBlocksList) {
      existingBlocksList.forEach((block: { blockName: string }) => {
        if (block.blockName.toLowerCase() === block_name.toLowerCase()) {
          error = true;
          toast.error(`Já existe um bloco com o nome '${block.blockName}'`);
        }
      });
    }

    if (allBlocks)
      allBlocks.forEach((block: { block_name: string }) => {
        if (block.block_name.toLowerCase() === block_name.toLowerCase()) {
          error = true;
          toast.error('Já existe um bloco com esse nome!');
        }
      });

    return error;
  }

  function handleSetBlock() {
    const { block_name } = getValues();

    if (validateBlock(block_name)) return;

    blocksList ? setBlocksList([...blocksList, block_name]) : setBlocksList([block_name]);

    allBlocks ? setAllBlocks([{ block_name }, ...allBlocks]) : setAllBlocks([{ block_name }]);
    setValue('block_name', '');
  }

  function handleRemoveBlock(block_name: string) {
    const newAllBlocks = allBlocks.filter(
      (block: { block_name: string }) => block.block_name !== block_name
    );
    const filteredBlocks = blocksList?.filter((block) => block !== block_name);
    setBlocksList(filteredBlocks);
    setAllBlocks(newAllBlocks);
    setValue('block_name', '');
  }

  function openRegisterModal() {
    setOpenRegisterModal(!isOpenRegisterModal);
  }

  async function submit() {
    toogleLoading();
    try {
      if (!blocksList || blocksList.length === 0) return;
      await registerBlockRequest(blocksList, enterpriseID);
      openRegisterModal();
      handleModalOpen();
      setIsSuccessfully(true);
      setSuccessMessage('Blocos criados com sucesso!');
      getAllData(enterpriseID);
    } catch (err: any) {
      handleModalOpen();
      setIsSuccessfully(false);
      setSuccessMessage(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
    }
  }

  return {
    isOpenRegisterModal,
    openRegisterModal,
    register,
    control,
    errors,
    allBlocks,
    handleSetBlock,
    handleSubmit,
    handleRemoveBlock,
    submit
  };
}

export default useRegisterBlockModalController;
