/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormRequiredFields } from '../PaymentSlipFiles.props';
import { Errors } from '../../../constants/Errors';
import {
  useBackToLastPageModalStore,
  usePaymentSlipStore,
  useInfoModalStore,
  useLoadModalStore
} from '../../../store';

export const useModalController = (
  isModalOpen: boolean,
  onComplete: () => void,
  getAllFiles: () => void,
  errorModal: () => void,
  toggleModal: () => void
) => {
  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[ a-zA-Z0-9-_.\u00f1\u00d1]*$/g,
        'Não é permitido usar caracteres especiais em nome de arquivo.'
      )
      .required('Por favor, preencher nome da pasta.')
      .trim()
  });

  const { handleModalOpen, handleSetText, handleSetIsSuccessfully } = useInfoModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { handleBackToLastPageModalOpen } = useBackToLastPageModalStore();
  const { fileId, file, setFile, editPaymentSlipFileRequest, getPaymentSlipFileById } =
    usePaymentSlipStore();

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  });

  const getFileByID = async () => {
    toogleLoading();
    try {
      const data = await getPaymentSlipFileById(fileId!);
      setFile(data);
      setValue('name', data.name);
    } catch (error: any) {
      errorModal();
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
    }
  };

  const sendRequest = async () => {
    const { name } = getValues();
    toogleLoading();
    try {
      const updatedFile = {
        name,
        url: file.url,
        date: file.date,
        price: file.price,
        size: file.size
      };

      await editPaymentSlipFileRequest(updatedFile, file.id);

      handleSetIsSuccessfully(true);
      handleSetText('Arquivo renomeado como sucesso.');
      toggleModal();
      handleModalOpen();
      getAllFiles();
      setValue('name', '');
      setFile(undefined);
    } catch (error: any) {
      errorModal();
      handleSetText(
        (Errors as any)({})[error.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
    } finally {
      toogleLoading();
    }
  };

  useEffect(() => {
    if (!isModalOpen) return;
    setValue('name', '');
    getFileByID();
  }, [isModalOpen]);

  const closeDocumentModal = () => {
    const { name } = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    name ? handleBackToLastPageModalOpen() : toggleModal();
  };

  return {
    register,
    handleSubmit,
    control,
    errors,
    sendRequest,
    closeDocumentModal
  };
};
