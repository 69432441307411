import styled from 'styled-components';
import { Table, TableRow, TableCell, tableCellClasses } from '@mui/material';

export const StyledTable = styled(Table)`
  color: #2c2c2c;
  margin-top: 2rem;
  min-width: 800px;

  .featTitle {
    text-transform: uppercase;
    font-weight: 600;
  }

  .featSubtitle {
    text-transform: capitalize;
    font-weight: 600;
  }

  .featAmount {
    color: #6d6d6d;
  }
`;

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 'none'
  }
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F4F4FF'
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
