/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-array-index-key */
import { Table, TableHead, TableRow, TableBody, Box, Button, Typography } from '@mui/material';
import OutsideClickHandler from 'react-outside-click-handler';
import React from 'react';
import CustomTableProps from './CustomTable.props';
import Container, {
  CustomIconButton,
  StyledTableCell,
  StyledTableRow,
  ButtonColor
} from './CustomTable.style';
import { useCustomTable } from './CustomTable.controller';
import NoFilesFound from '../NoFilesFound/NoFilesFound';
import CustomPagination from '../../../../components/CustomTable/components/CustomPagination/CustomPagination';
import getLengthObj from '../../../../helpers/getLengthObj';

const sortupicon = require('../../../../assets/images/sortupicon.svg');
const sortdownicon = require('../../../../assets/images/sortdownicon.svg');
const mailIcon = require('../../../../assets/images/mailIcon.svg');

export function OcurrencesTable(props: CustomTableProps) {
  const {
    tableHead,
    tableData,
    ActionModal,
    rowsId,
    totalPages,
    containId,
    totalItems,
    onClick,
    funcToChangePage,
    handleSetItemId,
    sortFunc
  } = props;
  const {
    isOpenActionModal,
    goToDetailsPage,
    handleClickOnActionBtn,
    indexOfRow,
    sortUp,
    openUnitsChoicedModal,
    sortDown,
    sortItemId,
    changeSortDownColor,
    changeSortUpColor,
    handleClickSortButton,
    formateRowsId,
    formatedTableData,
    closeActionModalOnClickAway
  } = useCustomTable(tableData, rowsId);

  return (
    <OutsideClickHandler onOutsideClick={closeActionModalOnClickAway}>
      <Container onClick={closeActionModalOnClickAway}>
        <Table stickyHeader sx={{ minWidth: 860 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableHead.map((head, index) => (
                <StyledTableCell key={head}>
                  <Box className="headerColumn">
                    <p>{head}</p>
                    <Box
                      display={head === 'Ações' ? 'none !important' : 'flex'}
                      className="sortIcons">
                      <CustomIconButton
                        primaryColor={sortUp && head === sortItemId ? '#333333' : '#D8D3D3'}
                        onClick={() =>
                          handleClickSortButton(changeSortUpColor, head, sortFunc, 'ASC', index)
                        }>
                        <sortupicon.ReactComponent style={{ stroke: 'red', fill: 'red' }} />
                      </CustomIconButton>
                      <CustomIconButton
                        primaryColor={sortDown && head === sortItemId ? '#333333' : '#D8D3D3'}
                        onClick={() =>
                          handleClickSortButton(changeSortDownColor, head, sortFunc, 'DESC', index)
                        }>
                        <sortdownicon.ReactComponent />
                      </CustomIconButton>
                    </Box>
                  </Box>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {formatedTableData.map((data, index) => (
              <StyledTableRow key={formateRowsId[index]} className="tableRow">
                {Object.values(data).map((value, indexValue) => (
                  <React.Fragment key={indexValue}>
                    {!containId && (
                      <StyledTableCell className={indexValue === 0 ? 'firstItem' : ''}>
                        <ButtonColor primaryColor={value} column={tableHead[indexValue - 1]}>
                          {value}
                        </ButtonColor>
                      </StyledTableCell>
                    )}

                    {containId && indexValue !== 0 && indexValue !== getLengthObj(data) - 1 && (
                      <StyledTableCell className={indexValue === 1 ? 'firstItem' : ''}>
                        <ButtonColor primaryColor={value} column={tableHead[indexValue - 1]}>
                          {value}
                        </ButtonColor>
                      </StyledTableCell>
                    )}

                    {indexValue === getLengthObj(data) - 1 && (
                      <StyledTableCell className={indexValue === 1 ? 'firstItem' : ''}>
                        <ButtonColor
                          primaryColor={value}
                          column={tableHead[indexValue - 1]}
                          onClick={(e) => openUnitsChoicedModal(e, handleSetItemId)}>
                          {value}
                        </ButtonColor>
                      </StyledTableCell>
                    )}

                    {indexValue === getLengthObj(data) - 1 && (
                      <StyledTableCell id={formateRowsId[index]} className="optionsContainer">
                        <Button
                          id={formateRowsId[index]}
                          onClick={(e: React.MouseEvent<HTMLElement>) =>
                            handleClickOnActionBtn(e, onClick, index, handleSetItemId)
                          }>
                          <Box
                            className="openDetailContainer"
                            id={formateRowsId[index]}
                            onClick={() => goToDetailsPage(formateRowsId[index])}>
                            <mailIcon.ReactComponent />
                            <Typography>Abrir</Typography>
                          </Box>
                        </Button>
                        {isOpenActionModal && Number(index) === indexOfRow && ActionModal}
                      </StyledTableCell>
                    )}
                  </React.Fragment>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {!formatedTableData[0]?.subject && (
          <NoFilesFound text="Não há nenhum registro de ocorrências no momento." />
        )}
      </Container>
      <CustomPagination
        totalPages={totalPages}
        totalItems={totalItems}
        funcToChangePage={funcToChangePage}
      />
    </OutsideClickHandler>
  );
}
