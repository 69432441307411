/* eslint-disable react/jsx-props-no-spreading */
import { Modal as ModalComponent, Box, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import AutocompleteInput from '../../../../components/Autocomplete/Autocomplete';
import { useModalController } from './Modal.controller';
import { ModalProps } from './Modal.props';
import {
  ModalBackButton,
  ModalButtonArea,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalContent,
  ModalInput,
  ModalInputArea,
  ModalInputTitle,
  ModalNextButton,
  ModalTitle
} from './Modal.style';

export function Modal({ isModalOpen, toggleModal, title, edit, getAllData }: ModalProps) {
  const {
    closeModal,
    control,
    errors,
    isSelectedBlock,
    register,
    submit,
    handleSubmit,
    blocksList,
    unitList,
    setUnitListData,
    setIsSelectedBlock,
    handleSearchSelect,
    handleSearchBlockSelect,
    isInputValue,
    setIsInputValue,
    isInputBlockValue,
    setIsInputBlockValue,
    unitValue,
    blockValue,
    setUnitValue,
    setBlockValue,
    cleanUnitValue
  } = useModalController(edit, toggleModal, getAllData);

  return (
    <ModalComponent open={isModalOpen} onClose={closeModal}>
      <ModalContent>
        <ModalCloseButton onClick={closeModal}>
          <ModalCloseButtonIcon />
        </ModalCloseButton>

        <ModalTitle>{title}</ModalTitle>

        <ModalInputArea>
          <ModalInputTitle>Nome da pasta</ModalInputTitle>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ModalInput
                id="pay-input-name"
                style={{ width: '100%' }}
                placeholder="Digite o nome da pasta"
                {...register('name')}
                error={!!errors.name}
                onChange={onChange}
                value={value ? value[0].toUpperCase() + value.substring(1) : value}
              />
            )}
          />
          {errors.name && <p className="errorMessage">{errors.name.message}</p>}
        </ModalInputArea>

        {!edit && (
          <Box className="blockAndUnityContainer">
            <ModalInputArea>
              <ModalInputTitle>Escolha o Bloco</ModalInputTitle>
              <Box className="selectInputLabelContainer">
                <Controller
                  name="block"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <InputLabel className="selectInputLabel">
                        {value || isInputBlockValue ? '' : 'Selecione'}
                      </InputLabel>
                      <AutocompleteInput
                        id="pay-select-block"
                        options={blocksList}
                        value={value}
                        getValue={blockValue}
                        setValue={setBlockValue}
                        isInputValue={isInputBlockValue}
                        setIsInputValue={setIsInputBlockValue}
                        handleSearch={handleSearchBlockSelect}
                        onChange={onChange}
                        isBlock
                        cleanUnitValue={cleanUnitValue}
                        setIsSelectedBlock={setIsSelectedBlock}
                        setUnitListData={setUnitListData}
                      />
                    </>
                  )}
                />
              </Box>
              {errors.block && <p className="errorMessage">{errors.block.message}</p>}
            </ModalInputArea>
            <ModalInputArea>
              <ModalInputTitle>Escolha a Unidade</ModalInputTitle>
              <Box className="selectInputLabelContainer">
                <Controller
                  name="unit"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <InputLabel className="selectInputLabel">
                        {value || isInputValue ? '' : 'Selecione'}
                      </InputLabel>
                      <AutocompleteInput
                        id="pay-select-unit"
                        options={unitList}
                        value={value}
                        getValue={unitValue}
                        setValue={setUnitValue}
                        isInputValue={isInputValue}
                        setIsInputValue={setIsInputValue}
                        handleSearch={handleSearchSelect}
                        isDisabled={!(edit || isSelectedBlock)}
                        onChange={onChange}
                        setIsSelectedBlock={setIsSelectedBlock}
                        setUnitListData={setUnitListData}
                      />
                    </>
                  )}
                />
              </Box>
              {errors.unit && <p className="errorMessage">{errors.unit.message}</p>}
            </ModalInputArea>
          </Box>
        )}

        <ModalButtonArea>
          <ModalNextButton
            id="pay-save-edit-btn"
            style={{
              backgroundColor: '#662483',
              color: 'white',
              width: '100%',
              height: 55,
              fontSize: '16px',
              marginBottom: '10px',
              textTransform: 'capitalize'
            }}
            variant="contained"
            disableElevation
            onClick={handleSubmit(submit)}>
            {edit ? 'Editar' : 'Salvar'}
          </ModalNextButton>
          <ModalBackButton
            variant="contained"
            style={{
              backgroundColor: '#F0F1F5',
              width: '100%',
              fontSize: '16px',
              height: 55,
              textTransform: 'capitalize'
            }}
            disableElevation
            onClick={closeModal}>
            Cancelar
          </ModalBackButton>
        </ModalButtonArea>
      </ModalContent>
    </ModalComponent>
  );
}
