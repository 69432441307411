/* eslint-disable react/jsx-props-no-spreading */
import { Box, Modal as ModalComponent } from '@mui/material';

import { ModalProps } from './ModalInfo.props';
import {
  ModalContent,
  ModalTitle,
  ModalButtonArea,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalBackButton,
  ModalInfoArea,
  ModalInfoTitle,
  ModalInfoSubtitle,
  ModalInfoAreaRow
} from './ModalInfo.style';

import infoPerson from '../../../assets/images/infoPerson.svg';
import infoLocation from '../../../assets/images/infoLocation.svg';
import infoData from '../../../assets/images/infoData.svg';
import infoBox from '../../../assets/images/infoBox.svg';
import { useModalInfoController } from './ModalInfo.controller';

export function ModalInfo({ isModalOpen, toggleModal, tableOrders, indexOfRow }: ModalProps) {
  const { orders } = useModalInfoController(tableOrders, indexOfRow);

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <ModalContent>
        <ModalCloseButton
          onClick={toggleModal}
          style={{
            position: 'absolute',
            right: 10,
            top: 10
          }}>
          <ModalCloseButtonIcon />
        </ModalCloseButton>

        <ModalTitle>Informações de Encomenda</ModalTitle>

        <ModalInfoArea>
          <Box display="flex" marginBottom="16px">
            <img src={infoPerson} alt="infoPerson" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Retirado por</ModalInfoTitle>
              <ModalInfoSubtitle pendingStatus={orders.status !== 'Retirada pelo morador'}>
                {orders.status === 'Aguardando retirada'
                  ? 'PENDENTE'
                  : tableOrders[indexOfRow].receivedBy}
              </ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={infoLocation} alt="infoLocation" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Unidade e Bloco</ModalInfoTitle>
              <ModalInfoSubtitle>{`Unidade: ${orders.unit}, Bloco: ${orders.block}`}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={infoData} alt="infoData" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>
                {orders.status === 'Retirada pelo morador'
                  ? 'Data de Retirada'
                  : 'Data de Recebimeto'}
              </ModalInfoTitle>
              <ModalInfoSubtitle>{orders.updatedAt}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={infoBox} alt="infoBox" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Remetente</ModalInfoTitle>
              <ModalInfoSubtitle>{orders.sender}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>
        </ModalInfoArea>

        <ModalButtonArea>
          <ModalBackButton
            id="orders_backButton"
            style={{
              backgroundColor: '#F0F1F5',
              color: '#2C2C2C',
              width: '50%',
              height: 44,
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: 500,
              textTransform: 'capitalize'
            }}
            variant="contained"
            disableElevation
            onClick={toggleModal}>
            Voltar
          </ModalBackButton>
        </ModalButtonArea>
      </ModalContent>
    </ModalComponent>
  );
}
