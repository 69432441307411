import { Grid, Input, InputAdornment, Box } from '@mui/material';
import useCustomPreviewInputController from './CustomPreviewInput.controller';

import Container from './CustomPreviewInput.style';

export function CustomPreviewInput() {
  const { firstColor, secondColor, handleSetFirstColor, handleSetSecondColor, colors } =
    useCustomPreviewInputController();
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6} marginBottom={1}>
          <Input
            id="enterprise_firstColor"
            className="customMobileColorsInput"
            value={firstColor}
            onChange={handleSetFirstColor}
            placeholder={colors.primary || '#00000'}
            disableUnderline
            fullWidth
            startAdornment={
              <InputAdornment style={{ paddingBottom: 6 }} position="start">
                <Box className="previewColor" style={{ backgroundColor: firstColor }} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Input
            id="enterprise_secondColor"
            className="customMobileColorsInput"
            value={secondColor}
            onChange={handleSetSecondColor}
            placeholder={colors.secondary || '#00000'}
            disableUnderline
            fullWidth
            startAdornment={
              <InputAdornment style={{ paddingBottom: 6 }} position="start">
                <Box className="previewColor" style={{ backgroundColor: secondColor }} />
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
}
