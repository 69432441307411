import { instance } from '../../api';

import {
  IAllLostItemsDataDtoOutput,
  IAllItemLostDataById,
  LostItemsRequestData
} from './LostItems.dto';

const formatStatus = (status: string) => {
  let newStatus = '';
  switch (status) {
    case 'PENDENT':
      newStatus = 'AGUARDANDO ADMIN';
      break;
    case 'WAITING':
      newStatus = 'AGUARDANDO RETIRADA';
      break;
    case 'WITHDRAWN':
      newStatus = 'RETIRADO';
      break;
    case 'FOUND':
      newStatus = 'PERDIDO';
      break;
    case 'REPROVED':
      newStatus = 'REPROVADO';
      break;
    default:
      break;
  }

  return newStatus;
};

export const sortOrdersData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IAllLostItemsDataDtoOutput> => {
  try {
    const data = await instance.request({
      method: 'GET',
      url: `lostItem/getAll?page=${page}&includeMeta=true&sortDirection=${
        sortByDirection || 'ASC'
      }&sortBy=${sortByField}&search=${searchString}`,
      baseURL: process.env.REACT_APP_MS_LOSTFOUND
    });

    const lostItems = data.data.data.map((itemData: LostItemsRequestData) => ({
      ...itemData,
      status: formatStatus(itemData.status)
    }));

    const totalOrders = data.data.metaData ? data.data.metaData.count : 0;
    const totalPages = data.data.metaData ? data.data.metaData.pages : 0;

    return {
      data: {
        data: lostItems,
        totalOrders,
        totalPages
      }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchLostItems = async (
  query: string,
  page: string,
  sortByField: string,
  sortByDirection: string
): Promise<IAllLostItemsDataDtoOutput> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: 'lostItem/getAll',
      baseURL: process.env.REACT_APP_MS_LOSTFOUND,
      params: {
        includeMeta: true,
        search: query,
        sortDirection: sortByDirection || 'ASC',
        sortBy: sortByField,
        page
      }
    });

    const lostItems = data.data.map((itemData: { status: string }) => ({
      ...itemData,
      status: formatStatus(itemData.status)
    }));

    const totalOrders = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: {
        data: lostItems,
        totalOrders,
        totalPages
      }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getLostItemsDetailsById = async (id: string): Promise<IAllItemLostDataById> => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `lostItem/${id}`,
      baseURL: process.env.REACT_APP_MS_LOSTFOUND
    });

    return data.data;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const changeStatus = async (status: string, id: string): Promise<void> => {
  try {
    await instance.request({
      method: 'PATCH',
      url: `lostItem/changeStatus/${id}`,
      baseURL: process.env.REACT_APP_MS_LOSTFOUND,
      data: { status }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const aproveOrReproveRequest = async (
  status: 'aprove' | 'reprove',
  id: string
): Promise<void> => {
  try {
    const action = status.toLowerCase();
    await instance.request({
      method: 'PATCH',
      url: `lostItem/${action}/${id}`,
      baseURL: process.env.REACT_APP_MS_LOSTFOUND
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getAWSImageUrl = async (imageID: string) => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `getImage/${imageID}`,
      baseURL: process.env.REACT_APP_STORAGE_URL
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};
