/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Modal as ModalComponent, Typography } from '@mui/material';

import { ModalProps } from './detailsModal.props';
import {
  ModalContent,
  ModalTitle,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalInfoArea,
  ModalInfoTitle,
  ModalInfoSubtitle,
  ModalInfoAreaRow
} from './detailsModal.style';

import { useDetailsModalController } from './detailsModal.controller';

import sendTo from '../../../../assets/images/sendTo.svg';
import maintenanceTypeIcon from '../../../../assets/images/warningIcon.svg';
import createdByIcon from '../../../../assets/images/infoCreatedBy.svg';
import infoData from '../../../../assets/images/infoData.svg';
import descriptionIcon from '../../../../assets/images/descriptionIcon.svg';

const downloadIcon = require('../../../../assets/images/download.svg');
const pdfIcon = require('../../../../assets/images/pdfIcon.svg');

export function DetailsModal({ isModalOpen, toggleModal }: ModalProps) {
  const { file, date, fileType, createdBy, name, sendBy, description, getImageToPreview } =
    useDetailsModalController();

  return (
    <ModalComponent open={isModalOpen} onClose={toggleModal}>
      <ModalContent>
        <ModalCloseButton
          onClick={toggleModal}
          style={{
            position: 'absolute',
            right: 10,
            top: 10
          }}>
          <ModalCloseButtonIcon />
        </ModalCloseButton>

        <ModalTitle>Informações de Informativo</ModalTitle>

        <ModalInfoArea>
          <Box display="flex" marginBottom="16px">
            <img src={maintenanceTypeIcon} alt="infoLocation" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Assunto</ModalInfoTitle>
              <ModalInfoSubtitle>{name}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={createdByIcon} alt="infoData" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Criada por</ModalInfoTitle>
              <ModalInfoSubtitle>{createdBy}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={sendTo} alt="infoBox" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Enviado para</ModalInfoTitle>
              <ModalInfoSubtitle>
                <Box className="statusBox">
                  <Typography className="statusText">{sendBy}</Typography>
                </Box>
              </ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={infoData} alt="infoBox" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Data</ModalInfoTitle>
              <ModalInfoSubtitle>{date}</ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          <Box display="flex" marginBottom="16px">
            <img src={descriptionIcon} alt="infoData" />

            <ModalInfoAreaRow>
              <ModalInfoTitle>Descrição</ModalInfoTitle>
              <ModalInfoSubtitle>
                <Box className="descriptionContainer">{description}</Box>
              </ModalInfoSubtitle>
            </ModalInfoAreaRow>
          </Box>

          {file.length > 0 && (
            <>
              <Box>
                <ModalInfoTitle>Arquivos</ModalInfoTitle>
                <Box className="fileContainer">
                  {file.map((imageOrPdf: { url: string }, index) => (
                    <>
                      {fileType[index] !== 'pdf' ? (
                        <Box
                          className="imageContainer"
                          onClick={() => getImageToPreview(imageOrPdf.url)}>
                          <img src={imageOrPdf.url} alt="arquivo" />
                          <Box className="imageDescription">Imagem</Box>
                        </Box>
                      ) : (
                        <Box className="pdfContainer">
                          <pdfIcon.ReactComponent />
                          <Box className="imageDescription">
                            Arquivo
                            <a target="_blank" href={imageOrPdf.url} rel="noreferrer">
                              <downloadIcon.ReactComponent />
                            </a>
                          </Box>
                        </Box>
                      )}
                    </>
                  ))}
                </Box>
              </Box>
              <Box className="itemImage" />
            </>
          )}
        </ModalInfoArea>
      </ModalContent>
    </ModalComponent>
  );
}
