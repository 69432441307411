/* eslint-disable no-underscore-dangle */
import { instance } from '../../api';

import {
  IAllPartnersDtoOutput,
  IOutputAllPartnersDataRequest,
  SearchPartner,
  IOutputPartnerByIDData
} from './Partners.dto';

export const sortPartnersData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IAllPartnersDtoOutput> => {
  try {
    const { data } = await instance.get(
      `partners/partner-list?page=${page}&sortDirection=${sortByDirection}&sortBy=${
        sortByField || 'name'
      }&search=${searchString}`
    );
    const formatedData = data.data.map((partners: IOutputAllPartnersDataRequest) => {
      return {
        id: partners.id || '',
        name: partners.name || '',
        segment: partners.segment || '',
        CNPJ: partners.CNPJ || '',
        contact: partners.cel || partners.tel || ''
      };
    });

    const totalPartners = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalPartners, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editPartners = async (partnerData: FormData, id: string): Promise<void> => {
  const partnersId = id || window.location.href.split('/').pop();

  try {
    await instance.put(`partners/partner-edit/${partnersId}`, partnerData);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const registerPartners = async (partnersData: FormData): Promise<void> => {
  try {
    await instance.post('partners/partner-create', partnersData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deletePartner = async (id: string): Promise<void> => {
  try {
    await instance.delete(`partners/partner-delete/${id}`);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const findOnePartner = async (id: string): Promise<IOutputPartnerByIDData> => {
  try {
    const { data } = await instance.get(`partners/partner-details/${id}?includeMeta=true`);

    const formatedData = {
      id: data.id || '',
      name: data.name || '',
      email: data.email || '',
      CNPJ: data.CNPJ || '',
      cel: data.cel || '',
      tel: data.tel || '',
      sector: data.sector || '',
      segment: data.segment || '',
      file: data.file || '',
      logoUrl: data.logoUrl || '',
      googlePlay: data.googlePlay || '',
      appleStore: data.appleStore || '',
      website: data.website || ''
    };
    return formatedData;
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchPartners = async (
  partnersData: SearchPartner,
  sortByField: string,
  sortByDirection: string,
  page: string
): Promise<IAllPartnersDtoOutput> => {
  try {
    const { data } = await instance.get(
      `partners/partner-list?search=${partnersData.queryText}&sortDirection=${sortByDirection}&includeMeta=true&sortBy=${sortByField}&page=${page}`
    );

    const formatedData = data.data.map((partner: IOutputAllPartnersDataRequest) => {
      return {
        id: partner.id || '',
        name: partner.name || '',
        segment: partner.segment || '',
        CNPJ: partner.CNPJ || '',
        contact: partner.cel || partner.tel || ''
      };
    });

    const totalPartners = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return {
      data: { data: formatedData, totalPartners, totalPages }
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getAWSImageUrl = async (imageID: string) => {
  try {
    const { data } = await instance.request({
      method: 'GET',
      url: `getImage/${imageID}`,
      baseURL: process.env.REACT_APP_STORAGE_URL
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};
