/* eslint-disable no-restricted-syntax */
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { FormRequiredFields, FormatedData } from './EditEnterprise.props';

import {
  usePreviewMobileModalStore,
  useEnterpriseStore,
  useBackToLastPageModalStore,
  useInfoModalStore,
  useLoadModalStore,
  useRefreshChangedPagesStore
} from '../../store';

import { getAWSImageUrl } from '../../services/requests/enterprise/Enterprise.request';

import formatCEP from '../../helpers/formatCEP';
import { formatImageUrl } from '../../helpers/formatImageUrl';
import { getStatus, formatDataEnterprise } from '../../helpers/enterprisesHelpFunctions';
import { choiceType, status, enterpriseWithoutValues } from '../../constants';
import { Errors } from '../../constants/Errors';

function useEditEnterpriseController() {
  const navigate = useNavigate();

  const [enterpriseOfId, setEnterpriseOfId] = useState(enterpriseWithoutValues);
  const [infoText, setInfoText] = useState('');
  const [isSuccessfully, setIsSuccessfully] = useState(false);
  const [enterpriseName, setEnterpriseName] = useState(enterpriseOfId.name);

  const { isOpenBackToLastPageModal } = useBackToLastPageModalStore();
  const { toogleLoading } = useLoadModalStore();
  const { handleModalOpen, isOpenInfoModal } = useInfoModalStore();
  const { isOpenMobilePreview } = usePreviewMobileModalStore();
  const { setChangedPage, isPageChanged } = useRefreshChangedPagesStore();

  const {
    handleSetColors,
    functionalities,
    colors,
    cep,
    city,
    district,
    state,
    street,
    logo,
    imageFile,
    logoUrl,
    manualUrl,
    placeholderManual,
    getEnterpriseDataRequest,
    handleSetLogo,
    handleSetManual,
    editEnterpriseRequest,
    handleSetLogoUrl,
    handleSetManualUrl,
    setSignedLogoUrl,
    setSignedManualUrl,
    handleSetManualName,
    handleSetPlaceholderManual
  } = useEnterpriseStore();

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormRequiredFields>({
    defaultValues: {
      id: '',
      incorporator: '',
      unit: '',
      name: '',
      adminEmail: '',
      status: enterpriseOfId.status,
      propertyType: enterpriseOfId.propertyType,
      number: '',
      complement: '',
      CEP: '',
      street: '',
      city: '',
      state: '',
      district: '',
      country: '',
      functionalitiesId: enterpriseOfId.functionalitiesId,
      colors: enterpriseOfId.colors
    }
  });

  function setAddressValues() {
    setValue('city', city);
    setValue('street', street);
    setValue('state', state);
    setValue('district', district);
    setValue('CEP', formatCEP(cep));
  }

  function handleSetEnterpriseName(name: string) {
    setEnterpriseName(name);
  }

  function setEnterpriseData(data: typeof enterpriseWithoutValues) {
    if (!data) return;
    setValue('incorporator', data.incorporator);
    setValue('unit', data.unit);
    setValue('name', data.name);
    setValue('CEP', data.CEP);
    setValue('city', data.city);
    setValue('state', data.state);
    setValue('street', data.street);
    setValue('district', data.district);
    setValue('number', data.number);
    setValue('adminEmail', data.adminEmail);
  }

  const requestEnterpriseData = async () => {
    toogleLoading();
    try {
      const { data } = await getEnterpriseDataRequest();
      setEnterpriseOfId(data);
      handleSetLogoUrl(data.logo);
      handleSetManualUrl(data.manual);
      handleSetColors(data.colors);
      setEnterpriseData(data);
    } catch {
      navigate('/enterprises');
      toast.error('Erro ao obter os dados do empreendimento');
    } finally {
      toogleLoading();
    }
  };

  function resetLogoAndManual() {
    setSignedLogoUrl('');
    handleSetLogo('');
    handleSetLogoUrl('');
    handleSetManual('');
    handleSetManualUrl('');
    handleSetManualName('');
    setSignedManualUrl('');
    handleSetPlaceholderManual(null);
  }

  async function getSignedManualUrl() {
    const { url } = await getAWSImageUrl(formatImageUrl(manualUrl));
    setSignedManualUrl(url);
  }

  useEffect(() => {
    resetLogoAndManual();
  }, []);

  useEffect(() => {
    setAddressValues();
    if (isPageChanged) setChangedPage(false);
  }, [city, street, state, district, cep]);

  useEffect(() => {
    requestEnterpriseData();
    if (manualUrl) getSignedManualUrl();
    setEnterpriseName(enterpriseOfId.name);
  }, [enterpriseOfId.name]);

  function NavigateToListEnterprises() {
    handleModalOpen();
    navigate('/enterprises', { replace: true });
  }

  function formatFormData(formatedData: FormatedData) {
    const address = {
      street: formatedData.street,
      number: formatedData.number,
      complement: formatedData.complement,
      city: formatedData.city,
      state: formatedData.state,
      country: formatedData.country,
      CEP: formatedData.CEP,
      neighborhood: formatedData.district
    };

    const dataToRequest = new FormData();
    dataToRequest.append('name', formatedData.name);
    dataToRequest.append('email', formatedData.adminEmail);
    dataToRequest.append('incorporator', formatedData.incorporator);
    dataToRequest.append('status', formatedData.status);
    dataToRequest.append('unit', formatedData.unit);
    dataToRequest.append('type', formatedData.propertyType);
    dataToRequest.append('address', JSON.stringify(address));
    dataToRequest.append('colors', JSON.stringify(colors));
    dataToRequest.append('functionalities', JSON.stringify(formatedData.functionalitiesId));

    if (logo) {
      dataToRequest.append('logo', imageFile || formatedData.logo);
    } else if (logoUrl.length > 0) {
      dataToRequest.append('logoUrl', logoUrl);
    } else {
      dataToRequest.append('logo', '');
      dataToRequest.append('logoUrl', '');
    }

    if (formatedData.manual) {
      dataToRequest.append('manual', formatedData.manual);
    } else if (manualUrl) {
      dataToRequest.append('manualUrl', manualUrl);
    } else {
      dataToRequest.append('manualUrl', '');
    }

    return dataToRequest;
  }

  const handleEditEnterprise = async () => {
    toogleLoading();
    const enterpriseData = getValues();
    const formatedCep = cep.replace('-', '');
    const formatedData = formatDataEnterprise(enterpriseOfId, {
      ...enterpriseData,
      functionalitiesId:
        functionalities.length <= 0 ? enterpriseOfId.functionalitiesId : functionalities,
      CEP: formatedCep || enterpriseOfId.CEP,
      logo,
      manual: placeholderManual || ''
    });

    const dataToRequest = formatFormData(formatedData);

    try {
      await editEnterpriseRequest(dataToRequest);
      setIsSuccessfully(true);
      setInfoText('Empreendimento editado com sucesso!');
      handleModalOpen();
    } catch (err: any) {
      if (String(err.message).includes('Failed to decode')) {
        setIsSuccessfully(true);
        setInfoText('Empreendimento editado com sucesso!');
        handleModalOpen();
        return;
      }
      setInfoText(
        (Errors as any)({})[err.message] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      setIsSuccessfully(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  };

  return {
    choiceType,
    control,
    errors,
    status,
    enterpriseOfId,
    isOpenMobilePreview,
    isOpenBackToLastPageModal,
    enterpriseName,
    isSuccessfully,
    isOpenInfoModal,
    infoText,
    register,
    handleSubmit,
    handleEditEnterprise,
    getStatus,
    handleSetEnterpriseName,
    NavigateToListEnterprises,
    handleModalOpen
  };
}

export default useEditEnterpriseController;
