import { filterRoles } from '../../../helpers/filterRoles';
import { FormRequiredFields } from '@/pages/Roles/Roles.props';
import { instance } from '../../api';
import {
  IRoleByIdAllDataDtoOutput,
  IRoleDtoOutput,
  RoleRequestData,
  SearchRoles
} from './Roles.dto';

const dataFormater = (data: RoleRequestData) => {
  return {
    id: data.id,
    userId: data.userId,
    name: data.name,
    permissions: filterRoles(data.permissions),
    createdAt: data.createdAt
  };
};

export const createRole = async (rolesData: FormRequiredFields): Promise<void> => {
  try {
    await instance.post('role', {
      userId: String(Math.random()),
      name: rolesData.name,
      permissions: rolesData.permissions,
      createdAt: new Date().toISOString(),
      isActive: true
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getAllRoleData = async (page: number): Promise<IRoleByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `role?filterFields=id,name,permissions&includeMeta=true&page=${page}&limit=0`
    );

    const totalRoles = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: data.data, totalRoles, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const getRolesData = async (id: string): Promise<IRoleDtoOutput> => {
  try {
    const { data } = await instance.get(`role/${id}`);

    const formatedData = {
      data: dataFormater(data.data)
    };

    return {
      data: formatedData
    };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const editRoles = async (rolesData: Partial<RoleRequestData>): Promise<void> => {
  try {
    await instance.put(`role/${rolesData.id}`, {
      userId: '1',
      name: rolesData.name,
      permissions: rolesData.permissions,
      createdAt: new Date().getTime().toString(),
      isActive: true
    });
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const deleteRole = async (id: string): Promise<void> => {
  try {
    await instance.delete(`role/${id}`);
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const searchRoles = async (
  rolesData: SearchRoles,
  sortByField: string,
  sortByDirection: string,
  page: string
): Promise<IRoleByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `role?search=${rolesData.queryText}&filterFields=id,name,permissions&includeMeta=true&sortDirection=${sortByDirection}&sortBy=${sortByField}&page=${page}`
    );

    const formatedData = data.data.map((item: RoleRequestData) => {
      return dataFormater(item);
    });

    const totalRoles = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: formatedData, totalRoles, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};

export const sortRolesData = async (
  page: string,
  sortByField: string,
  sortByDirection: string,
  searchString: string
): Promise<IRoleByIdAllDataDtoOutput> => {
  try {
    const { data } = await instance.get(
      `role?filterFields=id,name,permissions&includeMeta=true&page=${page}&sortDirection=${sortByDirection}&sortBy=${
        sortByField || 'name'
      }&search=${searchString}`
    );

    const formatedData = data.data.map((item: RoleRequestData) => {
      return dataFormater(item);
    });

    const totalRoles = data.metaData ? data.metaData.count : 0;
    const totalPages = data.metaData ? data.metaData.pages : 0;

    return { data: { data: formatedData, totalRoles, totalPages } };
  } catch (error: any) {
    throw new Error(error.response.data.errorCode);
  }
};
