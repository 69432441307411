/* eslint-disable jsx-a11y/anchor-is-valid */
import OutsideClickHandler from 'react-outside-click-handler';
import { Box, Typography, Button } from '@mui/material';
import Container from './ProfileModal.style';

import useProfileModalController from './ProfileModal.controller';

export function ProfileModal() {
  const {
    name,
    occupation,
    loading,
    isAdmin,
    handleLogout,
    handleChangePassword,
    closeActionModalOnClickAway
  } = useProfileModalController();
  return (
    <OutsideClickHandler onOutsideClick={closeActionModalOnClickAway}>
      <Container>
        <Box className="profileData">
          <Typography fontSize="18px">{name}</Typography>
          <Typography fontSize="18px" color="#6D6D6D">
            {occupation}
          </Typography>
        </Box>
        <Box className="profileButtons">
          <Button
            onClick={handleChangePassword}
            className="changePass"
            disabled={loading || isAdmin}>
            Alterar senha
          </Button>
          <Button id="profileModalExitButton" onClick={handleLogout}>
            Sair
          </Button>
        </Box>
      </Container>
    </OutsideClickHandler>
  );
}
