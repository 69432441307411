/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-return */
import { useEffect, useState } from 'react';
import { formatData } from '../../../../helpers/formatData';
import {
  useMaintenancesStore,
  usePreviewImageModalStore,
  useRolesModalStore
} from '../../../../store';

export const useDetailsModalController = (
  toggleModal: () => void,
  finishMaintenance: () => void
) => {
  const { getMaintenanceDetailsByIdRequest, maintenanceID, setImageToPreview } =
    useMaintenancesStore();
  const { handleImagePreviewOpen } = usePreviewImageModalStore();
  const { isAdmin } = useRolesModalStore();

  const [enterprise, setEnterprise] = useState('');
  const [type, setType] = useState('');
  const [createdBy, setCreatedBy]: any = useState('');
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState('');
  const [fileType, setFileType] = useState('');

  function formatType(requestType: string) {
    let formatedType = '';
    switch (requestType) {
      case 'Corrective':
        formatedType = 'Corretiva';
        break;
      case 'Preventive':
        formatedType = 'Preventiva';
        break;
      default:
        break;
    }
    return formatedType;
  }

  function formatStatus(requestStatus: string) {
    let formatedStatus = '';
    switch (requestStatus) {
      case 'Pendent':
        formatedStatus = 'PENDENTE';
        break;
      case 'InProgress':
        formatedStatus = 'EM ANDAMENTO';
        break;
      case 'Late':
        formatedStatus = 'ATRASADO';
        break;
      case 'Filed':
        formatedStatus = 'ATRASADO';
        break;
      case 'Completed':
        formatedStatus = 'CONCLUÍDO';
        break;

      default:
        break;
    }
    return formatedStatus;
  }

  async function getDetailsOfMaintenance() {
    const { data } = await getMaintenanceDetailsByIdRequest(maintenanceID, isAdmin);
    setFile(data.files[0]._url);
    setFileType(data.files && data.files[0]._url.includes('pdf') ? 'pdf' : 'image');
    setEnterprise(data.enterprise.name);
    setType(formatType(data.type));
    setCreatedBy(data.manager || '-');
    if (data.resident && data.resident.name) setCreatedBy(data.resident.name);
    setDate(data.date ? formatData(data.date) : '-');
    setStatus(formatStatus(data.status));
    setDescription(data.description);
  }

  function handleClickImage() {
    if (fileType !== 'image') return;
    setImageToPreview(file);
    handleImagePreviewOpen();
  }

  function handleClickConfirm() {
    toggleModal();
    finishMaintenance();
  }

  function handleClickBackButton() {
    toggleModal();
  }

  useEffect(() => {
    getDetailsOfMaintenance();
  }, []);

  return {
    handleClickConfirm,
    handleClickBackButton,
    handleClickImage,
    enterprise,
    fileType,
    type,
    file,
    createdBy,
    date,
    status,
    description
  };
};
