/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Box, Typography } from '@mui/material';
import Container from './CustomFileInput.style';
import useCustomFileInput from './CustomFileInput.controller';
import { CustomFileInputProps } from './CustomFileInput.props';
import selectfile from '../../../../assets/images/selectfile.svg';

const imageIcon = require('../../../../assets/images/imageicon.svg');
const filesTrash = require('../../../../assets/images/filesTrash.svg');
const pdfIcon = require('../../../../assets/images/pdfIcon.svg');

export function CustomFileInput(props: CustomFileInputProps) {
  const { accept, type, link } = props;
  const { handleChangeFile, files, handleRemoveFile, isPdf } = useCustomFileInput(type, link);

  const getElement = (button: any) => {
    if (button === 'fileInput') {
      const fileElem = document.getElementById(button);
      const newFileBtn = document.getElementById('newFileBtn');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newFileBtn.onclick = () => fileElem?.click();
    }
  };

  return (
    <Container>
      {files.length > 0 && files[0].src !== '' ? (
        <Box
          id="newFileBtn"
          className="previewImageInput"
          onClick={(e) => {
            e.stopPropagation();
            e.isDefaultPrevented();
            getElement('fileInput');
          }}>
          {files && (
            <Box
              style={{ zIndex: 100 }}
              className="imageContainer"
              key={String(files[0].src)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.isDefaultPrevented();
                getElement('trash');
              }}>
              {!isPdf && !files[0].src.includes('.pdf') ? (
                <Box className="previewImage">
                  <img src={files[0].src} alt="delete" />
                </Box>
              ) : (
                <Box className="pdfContainer">
                  <pdfIcon.ReactComponent />
                </Box>
              )}
              <Box className="infoImage">
                <imageIcon.ReactComponent className="imageIcon" />
                <Typography className="imageText">{`${files[0].name.slice(0, 12)}...`}</Typography>
                <Box
                  className="trashContainer"
                  id="trash"
                  onClick={() => handleRemoveFile(files[0].name)}>
                  <filesTrash.ReactComponent className="imageIcon" />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <>
          <img src={selectfile} alt="select file icon" className="icon" />
          <Box flexDirection="column">
            <Typography marginBottom={0.5} variant="body2">
              Selecione um arquivo ou arraste aqui
            </Typography>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.5)">
              Cada arquivo deve ter no máximo 10MB
            </Typography>
          </Box>
          <input
            id="fileInput"
            name="fileInput"
            type="file"
            accept={accept}
            onChange={handleChangeFile}
          />
        </>
      )}
    </Container>
  );
}
