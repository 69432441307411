import create from 'zustand';
import {
  deleteEmployees,
  editEmployeesAdmin,
  editEmployeesManager,
  registerEmployeesAdmin,
  registerEmployeesManager,
  deleteAdmin,
  searchManager,
  searchEmployees,
  sortEmployeesData,
  sortManagerData,
  getAdminData,
  getEmployeesData
} from '../../services/requests/employees/Employees.request';
import { EmployeesStoreProps } from './Employees.props';

const initialState = {
  employeesId: [],
  employeesList: [],
  id: '',
  name: '',
  phone: '',
  email: '',
  cpf: '',
  field: 'created_at',
  direction: 'DESC',
  isModalRegisterOpen: false,
  isModalEditOpen: false
};

export const useEmployeesStore = create<EmployeesStoreProps>((set, get) => ({
  ...initialState,

  handleSetEmployeeId: (id) => set((state) => ({ ...state, id })),
  handleSetEmployeesId: (employeesId) => set((state) => ({ ...state, employeesId })),
  handleSetEmployeesList: (employeesList) => set((state) => ({ ...state, employeesList })),
  handleSetSortByField: (field) => set((state) => ({ ...state, field })),
  handleSetSortByDirection: (direction) => set((state) => ({ ...state, direction })),

  setIsModalEditOpen: (isModalEditOpen?) => set((state) => ({ ...state, isModalEditOpen })),
  setIsModalRegisterOpen: (isModalRegisterOpen?) =>
    set((state) => ({ ...state, isModalRegisterOpen })),

  // Request feita pelo admin or

  registerEmployeesAdminRequest: async (employeesData, onComplete) => {
    try {
      await registerEmployeesAdmin(employeesData);

      onComplete();
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  // Request feita pelo manager

  registerEmployeesManagerRequest: async (employeesData, onComplete) => {
    try {
      await registerEmployeesManager(employeesData);

      onComplete();
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllAdminSortDataRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortManagerData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAllEmployeeSortDataRequest: async (page, sortByField, sortByDirection, searchString) => {
    try {
      const { handleSetSortByField, handleSetSortByDirection } = get();
      handleSetSortByField(sortByField);
      handleSetSortByDirection(sortByDirection);

      const data = await sortEmployeesData(page, sortByField, sortByDirection, searchString);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  // Request feita pelo admin or

  editEmployeesAdminRequest: async (employeesData, onComplete) => {
    try {
      await editEmployeesAdmin(employeesData);

      onComplete();
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  // Request feita pelo manager

  editEmployeesManagerRequest: async (employeesData, onComplete) => {
    try {
      await editEmployeesManager(employeesData);

      onComplete();
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  deleteEmployeesRequest: async (id) => {
    try {
      await deleteEmployees(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  deleteAdminRequest: async (id) => {
    try {
      await deleteAdmin(id);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchManagerRequest: async (employeesData, sortByField, sortByDirection, page) => {
    try {
      const data = await searchManager(employeesData, page, sortByField, sortByDirection);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  searchEmployeesRequest: async (employeesData, sortByField, sortByDirection, page) => {
    try {
      const data = await searchEmployees(employeesData, page, sortByField, sortByDirection);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getAdminDataRequest: async (id) => {
    try {
      const data = await getAdminData(id);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  getEmployeesDataRequest: async (id) => {
    try {
      const data = await getEmployeesData(id);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}));
