/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { useFilesStore } from '../../../store/Files/Files.store';
import { usePaymentSlipStore } from '../../../store';
import { isInvalidFileName } from '../../../helpers/fileNameValidator';

function useCustomFileInput() {
  const { getFileFinalized } = useFilesStore();
  const { setFile, file } = usePaymentSlipStore();

  const handleRemoveFile = () => {
    setFile(undefined);
  };

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = e.target as any;
    if (isInvalidFileName(event.files[0].name)) return;

    setFile(event.files[0]);
  };

  useEffect(() => {}, [file]);

  return {
    handleChangeFile,
    handleRemoveFile,
    getFileFinalized,
    file
  };
}

export default useCustomFileInput;
