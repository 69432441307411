import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

interface Props {
  isImmediateApproval?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-block: 90px;
  padding-left: 32px;
  padding-right: 44px;
  padding-bottom: 44px;

  .midTitle {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.13px;
    color: #2c2c2c;
    margin-bottom: 1rem;
  }

  .inputLabel {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    max-width: 200px;
    font-size: 14px;
    color: #2c2c2c;
    margin-bottom: 8px;
  }

  .toogleContainer {
    display: flex;
    gap: 50px;
  }

  .limitReservation {
    margin-top: 1.5rem;
  }

  .popoverContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .infoPopover {
    font-size: 14px;
    width: 22px;
    min-width: 22px;
    height: 22px;
    min-height: 22px;
    border-radius: 20px;
    display: grid;
    place-content: center;
    background-color: #662483;
    color: #fff;
  }

  .selectLabel {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    color: #2c2c2c;
  }

  .deadlineInput {
    input {
      text-align: center;
      padding-left: 5px;
    }
  }

  .onoff > label {
    position: relative;
    display: block;
    height: 21px;
    width: 44px;
    background: #898989;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .onoff > label:after {
    position: absolute;
    left: 2px;
    top: 2px;
    display: block;
    width: 17px;
    height: 17px;
    border-radius: 100px;
    background: #fff;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    content: '';
    transition: all 0.3s ease;
  }
  .onoff > label:active:after {
    transform: scale(1.15, 0.85);
  }
  .onoff > input:checked ~ label {
    background: #00a707;
  }
  .onoff > input:checked ~ label:after {
    left: 25px;
    background: #fff;
  }
  .onoff > input:disabled ~ label {
    background: #d5d5d5;
    pointer-events: none;
  }
  .onoff > input:disabled ~ label:after {
    background: #bcbdbc;
  }

  .onoff > input {
    display: none;
  }

  .timeContainer {
    display: flex;
    align-items: center;
  }

  .inputCountContainer {
    position: relative;
  }

  .plus {
    position: absolute;
    top: 16px;
    right: 14px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    z-index: 2;
  }

  .less {
    position: absolute;
    top: 16px;
    left: 14px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    z-index: 2;
  }

  .labelText {
    margin-right: 20px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 15px;
    color: #2c2c2c;
  }

  .buttonsContainer {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: right;
  }

  .buttonContainer {
    width: 250px;
  }

  .backButton {
    padding: 14px;
    transition: all 0.2s ease-in-out;
    border-radius: 0px;
    text-transform: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f1f5;
    border-radius: 8px;
    color: #2c2c2c;
  }

  .backButton:hover {
    opacity: 0.9;
  }

  .buttontext {
    margin-top: 3px;
  }

  .cancelInputLabel {
    position: absolute;
    top: 13px;
    left: 15px;
  }

  .cancellationContainer {
    position: relative;
  }

  .errorMessage {
    color: red;
    font-size: 11px;
    margin-top: 5px;
  }

  .priceContainer {
    position: relative;

    input {
      margin-right: 40px;
    }
  }

  .priceLabel {
    position: absolute;
    top: 13px;
    left: 10px;
    opacity: ${({ isImmediateApproval }) => (isImmediateApproval ? 0.4 : 1)};
  }

  .valueInput {
    flex: 1;
    width: 100%;

    input {
      height: 16px;
      margin-top: 1px;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #8b8b8b;
    }
  }

  .limitByUnitPopover {
    margin-bottom: -8px;
  }

  .priceInput {
    border-radius: 8px;
    border: 1px solid #8b8b8b;
    padding-left: 16px;
    font-size: 14px;
    height: 49px;
    font-family: 'Inter';
    background-color: transparent;
    &:focus {
      outline: 2px solid #000;
    }
  }

  @media (max-width: 970px) {
    .toogleContainer {
      flex-direction: column;
    }
  }
`;

export const Header = styled(Box)`
  width: 100%;
  margin-bottom: 50px;

  .title {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #2c2c2c;
  }

  .subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #8b8b8b;
  }
`;

export const AreaInfoFields = styled(Box).attrs({})`
  width: 100%;
  position: relative;
`;

export const InputsRow = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  gap: '2rem',
  marginBottom: '1.5rem'
})`
  .bigInput {
    flex: 1;
    min-width: 360px;
  }

  .cancelingRow {
    min-width: 320px;
    max-width: 650px;
  }
`;

export const SmallInputsContainer = styled(Box).attrs({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '2rem'
})<Props>`
  max-width: 370px;

  .smallInput {
    width: 100%;

    svg {
      opacity: ${({ isImmediateApproval }) => (isImmediateApproval ? 0.4 : 1)};
      cursor: ${({ isImmediateApproval }) => (isImmediateApproval ? 'default' : 'pointer')};
    }
  }
`;

export const ToggleContainer = styled(Box).attrs({
  width: '100%',
  marginTop: '1rem',
  display: 'flex',
  alignItems: 'flex-end',
  gap: '2rem'
})`
  .toggleBox {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

export const AvailableDays = styled(Box).attrs({
  marginTop: '2rem',
  marginBottom: '0.5rem'
})``;

export const DaysOfWeekButtonsContainer = styled(Box).attrs({
  display: 'flex',
  gap: '0.5rem',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between'
})``;

export const LocalPhotos = styled(Box).attrs({
  marginTop: '2rem'
})``;

export const Footer = styled(Box).attrs({
  marginTop: '3rem'
})``;

export const ErrorMessage = styled(Typography).attrs({
  color: 'red',
  fontSize: '12px',
  marginTop: '4px',
  height: '2rem'
})``;
