import { useBackToLastPageModalStore } from '../../../../store';

function useEnterpriseButtonsController() {
  const { handleBackToLastPageModalOpen } = useBackToLastPageModalStore();

  return {
    handleBackToLastPageModalOpen
  };
}

export default useEnterpriseButtonsController;
