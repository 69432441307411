import { useOcurrencesStore, useLoadModalStore, useInfoModalStore } from '../../../../store';
import { Errors } from '../../../../constants/Errors';

function useActionModalController(setIsBackToLastPage: (isBack: boolean) => void) {
  const { handleOpenActionDetailsModal, changeStatusOcurrencesRequest } = useOcurrencesStore();
  const { toogleLoading } = useLoadModalStore();
  const { handleModalOpen, handleSetIsSuccessfully, handleSetText } = useInfoModalStore();

  function closeModal() {
    handleOpenActionDetailsModal(false);
  }

  async function handleSetInProgressOcurrence() {
    toogleLoading();
    try {
      await changeStatusOcurrencesRequest('start', window.location.pathname.split('detail/')[1]);
      handleSetText('Ocorrência iniciada com sucesso!');
      handleSetIsSuccessfully(true);
      setIsBackToLastPage(true);
      handleModalOpen();
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      setIsBackToLastPage(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  async function handleSetFinishOcurrence() {
    toogleLoading();
    try {
      await changeStatusOcurrencesRequest('finish', window.location.pathname.split('detail/')[1]);
      handleSetText('Ocorrência finalizada com sucesso!');
      handleSetIsSuccessfully(true);
      setIsBackToLastPage(true);
      handleModalOpen();
    } catch (error: any) {
      handleSetText(
        (Errors as any)({})[error.message.trim()] ||
          'Um erro estranho ocorreu, tente novamente em alguns instantes.'
      );
      handleSetIsSuccessfully(false);
      setIsBackToLastPage(false);
      handleModalOpen();
    } finally {
      toogleLoading();
    }
  }

  return { closeModal, handleSetInProgressOcurrence, handleSetFinishOcurrence };
}

export default useActionModalController;
