import styled from 'styled-components';
import { CloseRounded } from '@mui/icons-material';
import { Button, IconButton, TextField, Typography } from '@mui/material';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  background: #fff;

  display: flex;
  flex-direction: column;
  width: 685px;

  padding: 24px 32px 48px;
  border-radius: 16px;

  .formContainer {
    .inputContainer {
      display: flex;
      gap: 8px;
      margin-bottom: 16px;
    }
  }

  .errorMessage {
    color: red;
    font-size: 13px;
    margin-top: 8px;
  }

  .valueInput {
    flex: 1;
    width: 100%;

    input {
      height: 16px;
      margin-top: 1px;
    }

    fieldset {
      border-radius: 8px;
      border: 1px solid #c4c4c4;
    }
  }

  .priceInput {
    border-radius: 8px;
    border: 1px solid #c4c4c4;
    padding-left: 16px;
    font-size: 16px;
    height: 56px;
    font-family: 'Inter';
    color: #2c2c2c;
    background-color: transparent;
    &:focus {
      border: 2px solid #000;
    }
  }
`;

export const ModalTitle = styled.p`
  font-family: 'Public Sans';
  margin-bottom: 32px;
  margin-top: -32px;
  text-align: left;
  font-size: 22px;
  font-weight: 600;
`;

export const ModalInputTitle = styled(Typography).attrs({
  fontFamily: 'Inter',
  fontSize: '15px',
  fontWeight: 600,
  marginBottom: '8px'
})``;

export const ModalInput = styled(TextField).attrs({
  mode: 'outlined'
})`
  width: 100%;
  height: 48px;

  & + p {
    font-family: 'Inter';
    color: #ff0000;
  }
`;

export const ModalInputArea = styled.section`
  margin-bottom: 12px;
  width: 100%;
  height: auto;
  div {
    border-radius: 10px;
    margin-bottom: 8px;
  }
`;

export const ModalCloseButton = styled(IconButton)`
  align-self: flex-end;
`;

export const ModalCloseButtonIcon = styled(CloseRounded)``;

export const ModalButtonArea = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
`;

export const ModalNextButton = styled(Button)``;

export const ModalBackButton = styled(Button)``;
