import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  button {
    background: #f1f7ff;
    border-radius: 8px;
    padding: 4px 10px;
  }

  button:hover {
    background: #f1f7ff;
    opacity: 0.9;
  }

  .changePageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .changePageNumber {
    min-width: 27px;
  }

  .changePageIcon {
    min-width: 45px;
    height: 32.5px;
  }

  .changePageLastNumber {
    min-width: 50px;
  }

  .currentPage {
    background-color: #4b4b4b;
    color: #fff;
  }

  .currentPage:hover {
    background-color: #4b4b4b;
    color: #fff;
  }

  @media (max-width: 600px) {
    .changePageNumber,
    .changePageLastNumber {
      display: none;
    }
  }
`;

export default Container;
